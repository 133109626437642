import 'sweetalert2/src/sweetalert2.scss';

import * as SiteEventActions from 'actions/siteEvents';
import axios from 'axios';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { listenedtrackStatistic$, uploadedTrack$ } from 'selectors/siteEvents';
import { catchedErrorNotification } from 'utils/error';

export const sendEvent = (type: 'listen' | 'upload' | 'upload_all', playlist_id?: number, name_in_base?: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(SiteEventActions.setSiteEventsPending());

    const param = () => {
      if (type === 'listen') {
        return listenedtrackStatistic$(getState());
      } else if (type === 'upload') {
        return uploadedTrack$(getState());
      } else if (type === 'upload_all') {
        return playlist_id;
      } else {
        return '';
      }
    };

    const url = () => {
      if (type === 'listen') {
        return `${Api.ListenEvent}`;
      } else if (type === 'upload') {
        return `${Api.UploadEvent}`;
      } else if (type === 'upload_all') {
        return `${Api.PlaylistUploadEvent}`;
      } else {
        return '';
      }
    };

    const parameter = playlist_id ? { playlist_id: param() } : { name_in_base: name_in_base ? name_in_base : param() };

    axios
      .post(url(), {
        ...parameter,
      })
      .then(() => {
        dispatch(SiteEventActions.seSiteEventSendSuccess());
      })
      .catch(error => {
        dispatch(SiteEventActions.setSiteEventsError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
