import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import * as CreatorPlaylistAction from 'actions/application';
import pause from 'assets/img/pause-button.svg';
import playBtn from 'assets/img/play-button.svg';
import cx from 'classnames';
import Popup from 'components/popup/popup';
import { IAppTrack } from 'interfaces/IApp';
import { INewTrack } from 'interfaces/INewTrack';
// eslint-disable-next-line
import React, { useEffect, useRef, useMemo, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';

import style from './playlistTrack.module.scss';

interface IProps {
  onClick: (title: string, name: string, id: number | null, rating: number) => void;
  track: INewTrack | IAppTrack;
  currentPlayedTrackId: null | number;
  isPlayerPlay: boolean;
  isNeedToScroollToTrackInRightPanel: boolean;
  onDelete: (id: number) => void;
}
export const PlaylistTrack = ({
  track,
  onClick,
  onDelete,
  currentPlayedTrackId,
  isPlayerPlay,
  isNeedToScroollToTrackInRightPanel,
}: IProps) => {
  const userType = useSelector(userType$);
  const dispatch = useDispatch();
  const handleClick = () => {
    onClick(track.title!, '', track.track_id, 0);
  };

  const onApply = () => {
    onDelete(track.track_id!);
  };

  const isPlay = useMemo(() => {
    return track.track_id === currentPlayedTrackId && isPlayerPlay;
  }, [track, currentPlayedTrackId, isPlayerPlay]);

  const blockCX = cx(style.root, {
    // eslint-disable-next-line
    [style.selected]: currentPlayedTrackId == track.track_id,
  });

  const onDeleteClick = () => {
    dispatch(CreatorPlaylistAction.changeUnsaved(true));
    onApply();
  };

  const trackRef = useRef(null) as RefObject<HTMLDivElement> | null;
  useEffect(() => {
    // eslint-disable-next-line
    if (currentPlayedTrackId == track.track_id && !isNeedToScroollToTrackInRightPanel) {
      if (trackRef?.current) {
        trackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentPlayedTrackId, track, isNeedToScroollToTrackInRightPanel, trackRef]);
  return (
    <div className={blockCX} ref={trackRef}>
      <button className={style.btnSelect} onClick={handleClick}>
        {isPlay ? <img className={style.btnImg} src={pause} alt="pause" /> : <img className={style.btnImg} src={playBtn} alt="play" />}
      </button>
      <div className={style.title}>{track.title}</div>
      {userType !== 'creator' && (
        <Popup
          btnClassName={style.btnDelete}
          buttonText={<DeleteForeverRoundedIcon />}
          modalContent={
            <div className="d-flex align-center" style={{ marginBottom: 10, height: 100 }}>
              <div>Are you sure You want to delete track from playlist?</div>
            </div>
          }
          onApply={onApply}
        />
      )}

      {userType === 'creator' && <DeleteForeverRoundedIcon onClick={onDeleteClick} className={style.btnDelete} />}
    </div>
  );
};
