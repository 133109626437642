import * as TrackInfoActions from 'actions/trackInfo';
import Popup from 'components/popup/popup';
import { UploadNewTrackContent } from 'containers/uploadNewTrack/uploadNewTrackContent';
import * as NewTrackCreators from 'creators/newTracks';
import { INewTrack, INewTrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { userName$ } from 'selectors/auth';
import { trackId$ } from 'selectors/newTrack';
import { allLoadedTracks$ } from 'selectors/newTrack';
import { isOpenEditNewTrackPopup$ } from 'selectors/trackInfo';

import styles from './style.module.scss';

interface IStateProps {
  isOpenEditNewTrackPopup: boolean;
  trackId: null | number;
  allLoadedTracks: INewTrack[];
  userName: string | null;
}

interface IDispatchProps {
  setInfoAboutTrack: (track_id: number, track: Partial<INewTrackType>, isEdit?: boolean) => void;
  setOpenUploadPopup: (val: boolean) => void;
  deleteTrackById: (id: number, deleteNotLoaded?: boolean, needToNotChangeAmount?: boolean) => void;
}

interface OwnProps {}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const NewTrackPopup = ({
  isOpenEditNewTrackPopup,
  trackId,
  allLoadedTracks,
  userName,
  setInfoAboutTrack,
  setOpenUploadPopup,
  deleteTrackById,
}: IProps) => {
  const onClosePopup = () => {
    setOpenUploadPopup(false);
  };

  const onClosePopupBtn = () => {
    setOpenUploadPopup(false);
    // need to delete track, when user close popup
    deleteTrackById(0, true, true);
  };

  return (
    <Popup
      disableBackdropClick
      isHideButtons
      isHideMainButton
      isOpen={isOpenEditNewTrackPopup}
      btnClassName={styles.popupBtn}
      buttonText={<div className="d-none">''</div>}
      modalContent={
        <UploadNewTrackContent
          setInfoAboutTrack={setInfoAboutTrack}
          trackId={trackId}
          onClosePopup={onClosePopup}
          onClosePopupBtn={onClosePopupBtn}
          allLoadedTracks={allLoadedTracks}
          userName={userName}
        />
      }
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    trackId: trackId$(state),
    isOpenEditNewTrackPopup: isOpenEditNewTrackPopup$(state),
    allLoadedTracks: allLoadedTracks$(state),
    userName: userName$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setInfoAboutTrack: dispatchProps.setInfoAboutTrack,
    setOpenUploadPopup: dispatchProps.setOpenUploadPopup,
    deleteTrackById: dispatchProps.deleteTrackById,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setOpenUploadPopup: TrackInfoActions.setOpenUploadPopup,
    setInfoAboutTrack: NewTrackCreators.setInfoAboutTrack,
    deleteTrackById: NewTrackCreators.deleteTrackById,
  },
  mergeProps
)(memo(NewTrackPopup));
