import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { ICompany } from 'interfaces/state/CompaniesState';
// eslint-disable-next-line
import React from 'react';

import style from './style.module.scss';

interface IStateProps {}

interface IDispatchProps {}

interface OwnProps {
  companies: ICompany[];
  selectedCompanyId: number;
  selectedCompany: string;
  setSelectCompanyId: (id: number) => void;
  setSelectCompany: (name: string) => void;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

export const ChangeCompanyPopup = ({ selectedCompanyId, selectedCompany, companies, setSelectCompanyId, setSelectCompany }: IProps) => {
  const handleCompanyChange = (company: ISelectItem) => {
    setSelectCompanyId(company.value as number);
    setSelectCompany(company.label);
  };

  const selectCompanyValue = { value: String(selectedCompanyId), label: selectedCompany } as ISelectItem;
  const companyList = () => companies.map(company => ({ label: company.name, value: company.id })) as ISelectItem[];

  return (
    <section className={style.root}>
      <div className={style.title}>Choose a company</div>
      <MaterialSelect
        width={300}
        value={selectCompanyValue}
        placeholder="Choose a company"
        selectItems={companyList()}
        isAllValueNeed
        className="pl-drop"
        onChangeAllValue={handleCompanyChange}
      />
    </section>
  );
};
