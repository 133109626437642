import * as TeamInvites from 'actions/teamInvite';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const fetchTeamInviteList = (isManager?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(TeamInvites.setTeamInvitePending());

    const url = isManager ? Api.ManagerInvites : Api.MusicianInvites;
    axios
      .post(url)
      .then(response => {
        dispatch(TeamInvites.setTeamInviteList(response.data.result));
      })
      .catch(error => {
        dispatch(TeamInvites.setTeamInviteError());

        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};

export const sendInviteFromTrack = (team_id: number, track_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(TeamInvites.setTeamInvitePending());

    axios
      .post(Api.InviteFromTrack, { team_id, track_id })
      .then(() => {
        dispatch(TeamInvites.sendedInviteSuccessfully());
        successNotification('Team invite was sent successfully', 8000);
      })
      .catch(error => {
        dispatch(TeamInvites.setTeamInviteError());

        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};

export const acceptInviteStatus = (invite_id: number) => {
  return (dispatch: (action: Action) => void) => {
    // dispatch(TeamInvites.setTeamInvitePending());

    const data = { status: 'accepted', invite_id };

    axios
      .post(Api.UpdateTeamInviteStatus, data)
      .then(() => {
        dispatch(TeamInvites.updateTeamInvite(invite_id));
        setTimeout(() => dispatch(TeamInvites.deleteInviteTeam(invite_id)), 1000);
        successNotification('Congratulations! You have accepted the invitation to the team', 8000);
      })
      .catch(error => {
        dispatch(TeamInvites.setTeamInviteError());

        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};
