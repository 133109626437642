import * as Const from '../constants';
import State from '../interfaces/state/SharedPlaylistState';

const initState: State = {
  list: [],
  isLoading: false,
  isError: false,
  currentIndex: null,
};

export default function sharedPlaylist(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SHARED_PLAYLIST_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.SHARED_PLAYLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SHARED_PLAYLIST_RECEIVE:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SHARED_PLAYLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.SHARED_PLAYLIST_ID:
      return {
        ...state,
        currentIndex: action.payload,
      };

    case Const.FILTER_SHARED_PLAYLIST_BY_ID:
      return {
        ...state,
        list: [...state.list].filter(pl => pl.playlist_id !== action.payload),
      };

    default:
      return state;
  }
}
