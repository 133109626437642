import * as Const from '../constants/index';
import State from '../interfaces/state/CompanyJobsState';

const initState: State = {
  jobs: [],
  isError: false,
  isLoading: false,
  sortedBy: 'date_time',
  orderOfJobs: null,
  sortType: 'desc',
  currentNewId: null,
  selectedJobsType: 'open',
};

export default function companyJobs(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.COMPANY_JOBS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.COMPANY_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.isPagination ? [...state.jobs, ...action.payload] : action.payload,
        isError: false,
        isLoading: false,
      };

    case Const.COMPANY_JOBS_PENDING:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };

    case Const.COMPANY_JOBS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.COMPANY_JOBS_SORTING:
      return {
        ...state,
        sortedBy: action.payload,
      };

    case Const.COMPANY_JOBS_ORDER:
      return {
        ...state,
        orderOfJobs: action.payload,
      };

    case Const.COMPANY_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload,
      };

    case Const.COMPANY_SUCCESS_ADDED:
      return {
        ...state,
        jobs: [action.payload, ...state.jobs],
      };

    case Const.COMPANY_CHANGE_STATUS:
      return {
        ...state,
        jobs: state.jobs.map(job => (job.id === action.id ? { ...job, status: action.payload } : job)),
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_NEW_ID:
      return {
        ...state,
        currentNewId: action.payload,
      };

    case Const.COMPANY_UPDATE_JOB:
      const newJobs = [...state.jobs];
      const ind = newJobs.findIndex(job => job.id === action.payload.id);
      if (ind < 0) return state;
      newJobs[ind] = { ...newJobs[ind], ...action.payload };

      return {
        ...state,
        jobs: newJobs,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_UPDATE_JOB_AVATAR:
      return {
        ...state,
        jobs: state.jobs.map(job => (job.id === action.id ? { ...job, ...{ avatar_url: action.avatar } } : job)),
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_UPDATE_JOB_AVATAR_BY_ID:
      const jobsCopy = [...state.jobs];
      const index = jobsCopy.findIndex(job => job.id === action.job_id);
      if (index < 0) return state;
      jobsCopy[index].avatar_url = action.url;

      return {
        ...state,
        jobs: jobsCopy,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_UPDATE_JOB_COMPANY:
      return {
        ...state,
        jobs: state.jobs.map(job => (job.id === action.id ? { ...job, ...{ company: action.company } } : job)),
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_JOB_SET_PEND_OFF:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_JOB_SELECT_TYPE:
      return {
        ...state,
        selectedJobsType: action.payload,
      };

    case Const.COMPANY_JOB_INIT_FROM_FETCH:
      return {
        ...initState,
        selectedJobsType: state.selectedJobsType,
      };

    default:
      return state;
  }
}
