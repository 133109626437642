import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import cx from 'classnames';
import { MaterialSelect } from 'components/materialSelect/materialSelect';
import Popup from 'components/popup/popup';
import * as InviteCreators from 'creators/invite';
import { Iinvite } from 'interfaces/state/InviteState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { created$, isLoading$, received$ } from 'selectors/invite';

import style from './style.module.scss';

const inviteStatuses = [
  { label: 'Accept', value: 'accepted' },
  { label: 'Reject', value: 'canceled' },
];

interface IStateProps {
  isLoading: boolean;
  created: Iinvite[];
  received: Iinvite[];
}

interface IDispatchProps {
  fetchReceivedInvites: () => void;
  fetchCreatedInvites: () => void;
  sendNewInviteStatus: (id: number, status: string) => void;
  deleteInviteById: (id: number, isCreated: boolean) => void;
}

interface OwnProps {}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}
const InviteList = ({
  received,
  created,
  fetchReceivedInvites,
  fetchCreatedInvites,
  sendNewInviteStatus,
  deleteInviteById,
  isLoading,
}: IProps) => {
  useEffect(() => {
    if (isLoading) return;
    fetchReceivedInvites();
    fetchCreatedInvites();
  }, []);

  const [isOpenDelete, setOpenDelete] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [isShowCreated, setShowCreated] = useState(false);

  const onCreatedClick = () => {
    setShowCreated(true);
    fetchCreatedInvites();
  };
  const onReceivedClick = () => {
    setShowCreated(false);
    fetchReceivedInvites();
  };

  const CretedBtnCX = cx(style.controlBtn, {
    [style.selectedBtn]: isShowCreated,
  });

  const ReceivedBtnCX = cx(`${style.controlBtn} m-l-a`, {
    [style.selectedBtn]: !isShowCreated,
  });

  const numberOfInvitation = () => {
    if (isShowCreated) {
      return created.length;
    } else {
      return received.length;
    }
  };

  return (
    <div className={style.inviteList}>
      <div className={style.title}>{`Company invitations - ${numberOfInvitation()}`}</div>
      <div className={style.controls}>
        <button className={ReceivedBtnCX} onClick={onReceivedClick}>
          Received
        </button>
        <button className={CretedBtnCX} onClick={onCreatedClick}>
          Created
        </button>
      </div>
      <div className={style.tableHeader}>
        <div className={style.userName}>User name</div>
        <div className={style.companyName}>Company</div>
        <div className={style.status}>Status</div>
        <div className={style.controls}>Controls</div>
      </div>
      <div className={style.table}>
        {isShowCreated &&
          created.map(invite => {
            return (
              <div key={invite.id} className={style.tableItem} style={{ overflow: 'visible' }}>
                <div className={style.nameInvite}>{invite.user_name}</div>
                <div className={style.companyInvite}>{invite.company_name}</div>
                <div className={style.statusInvite}>{invite.status}</div>
                <div className={style.controls} style={{ overflow: 'visible' }}>
                  <Popup
                    onOpen={() => setOpenDelete(true)}
                    onDecline={() => setOpenDelete(false)}
                    isOpen={isOpenDelete && currentId === invite.id}
                    btnClassName={style.btn}
                    onApply={() => deleteInviteById(invite.id, true)}
                    isVisibleOverflow
                    buttonText={
                      <Tooltip title="Delete Invite">
                        <div className={style.button} onClick={() => setCurrentId(invite.id)}>
                          <DeleteForeverIcon />
                        </div>
                      </Tooltip>
                    }
                    applyBtnText={'DELETE'}
                    modalContent={
                      <div className="d-flex flex-column">
                        <div className={style.label}>{`Are you sure that you want to delete Invite to '${invite.user_name}'`}</div>
                      </div>
                    }
                  />
                </div>
              </div>
            );
          })}
        {!isShowCreated &&
          received.map(invite => {
            return (
              <div key={invite.id} className={style.tableItem} style={{ overflow: 'visible' }}>
                <div className={style.nameInvite}>{invite.user_name}</div>
                <div className={style.companyInvite}>{invite.company_name}</div>
                <div className={style.statusInvite}>{invite.status}</div>
                <div className={style.controls} style={{ overflow: 'visible' }}>
                  <div className="d-flex flex-column" style={{ overflow: 'visible' }}>
                    <span className={style.fakePlaceholder}>Change invite status</span>
                    <MaterialSelect
                      value={{ label: invite.status, value: invite.status }}
                      placeholder="Change Invite Status"
                      onChange={(value: string | number) => sendNewInviteStatus(invite.id, value as string)}
                      selectItems={inviteStatuses}
                      className={style.inviteSelect}
                      width={120}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    isLoading: isLoading$(state),
    created: created$(state),
    received: received$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchCreatedInvites: dispatchProps.fetchCreatedInvites,
    fetchReceivedInvites: dispatchProps.fetchReceivedInvites,
    sendNewInviteStatus: dispatchProps.sendNewInviteStatus,
    deleteInviteById: dispatchProps.deleteInviteById,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCreatedInvites: InviteCreators.fetchCreatedInvites,
    fetchReceivedInvites: InviteCreators.fetchReceivedInvites,
    sendNewInviteStatus: InviteCreators.sendNewInviteStatus,
    deleteInviteById: InviteCreators.deleteInviteById,
  },
  mergeProps
)(memo(InviteList));
