import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// eslint-disable-next-line
import React from 'react';

import styles from './style.module.scss';

interface IHeader {
  title: string;
  order: string;
}

interface IProps {
  headers: IHeader[];
  sortedBy: string;
  sortType: string;
  setMusicianJobsOrder: (ord: 'asc' | 'desc') => void;
  setMusicianJobsSorting: (sort: string) => void;
}

export const CompanyHeader = ({ headers, sortedBy, sortType, setMusicianJobsOrder, setMusicianJobsSorting }: IProps) => {
  const onClick = (order: string) => {
    if (sortType === 'asc') {
      setMusicianJobsSorting(order);
      setMusicianJobsOrder('desc');
    } else {
      setMusicianJobsSorting(order);
      setMusicianJobsOrder('asc');
    }
  };

  const onHeaderItemClick = ({ order }: IHeader) => () => {
    onClick(order);
  };

  return (
    <div className={styles.root}>
      {headers.map((header, index) => {
        return (
          <div key={index} className={styles.item} onClick={onHeaderItemClick(header)}>
            <div key={index}>{header.title}</div>
            {sortType === 'asc' && sortedBy === header.order && sortedBy !== '' ? <ArrowDownwardIcon className="sort-arrow" /> : null}

            {sortType === 'desc' && sortedBy === header.order && sortedBy !== '' ? <ArrowUpwardIcon className="sort-arrow" /> : null}
          </div>
        );
      })}
    </div>
  );
};
