// eslint-disable-next-line
import React from 'react';

import style from './codeOfConduct.module.scss';

interface IProps {
  onSubmit: () => void;
  onBackClick: () => void;
}

export const CodeOfConduct = ({ onSubmit, onBackClick }: IProps) => {
  return (
    <section className={style.root}>
      <div className={style.wrapper}>
        <h1 className={style.head}>SMASHHAUS CODE OF CONDUCT</h1>
        <p className={style.text}>
          We believe in diversity, inclusion, and accountability and we are excited to welcome everyone to the Site. SmashHaus has and
          maintains the right to restrict access to the Site for any individual or entity at any time in Smashhaus’ sole discretion. To
          ensure that all participants feel safe, respected, and valued, SmashHaus requires that users treat each other with respect,
          consideration, and abide by SmashHaus’ code of conduct. The following types of behavior are unacceptable on the Site and will be
          grounds for immediate removal from the Site and prohibition of any or all further use of the Site, without remuneration.
        </p>
        <h3 className={style.subhead}>Prohibited Online Behaviors</h3>
        <div className={style.container}>
          <span className={style.subText}>Consent violations.</span>
          <div className={style.text}>
            Any content/interactions/expressions that is of a sexual, violent, graphic, and/or racist nature is considered a violation of
            consent. Content, interactions or expressions that may violate consent can be in the form of, but are not limited to: video,
            image, audio, chat, conduct, gesture, symbol, and/or discussion. Consent violations also include any depictions, incitement, or
            conduct involving non-consensual sexual acts, as well as doxxing, blackmail, and re-sharing of SmashHaus content (all of which
            are strictly prohibited). Any of the aforementioned behavior that occurs without the express consent of the involved
            participants and/or SmashHaus will be a violation and may result in your immediate removal from the Site and/or prohibition from
            further use.
          </div>
        </div>

        <div className={style.container}>
          <span className={style.subText}>Bullying.</span>
          <div className={style.text}>
            Bullying is defined as any content, interactions, expressions that intimidates, abuses, menaces, or threatens any participants
            and, or is intended to exclude, silence, shame, or degrade a participant (or group) and, or incites others to do so.
          </div>
        </div>

        <div className={style.container}>
          <span className={style.subText}>Stalking/Harassment.</span>
          <div className={style.text}>
            If someone asks you to leave them alone or stop communicating with them, continued engagement with them, including following
            them, is deemed stalking and, or harassment. Any content, interactions, or expressions consisting of verbal assaults, threats of
            violence or harm, degrading or shaming speech, humiliation, and/or repeated unwanted contact are considered stalking and, or
            harassment.
          </div>
        </div>

        <div className={style.container}>
          <span className={style.subText}>Hate Speech.</span>
          <div className={style.text}>
            Hate speech is defined as any content, interactions, or expressions that consist of attacks and, or incitement of attacks
            against an individual or group of people based on race, ethnicity, national origin, gender, gender expression, religion, sexual
            orientation, immigration status, disability, health status, and/or any identifying characteristic, and is considered a violation
            of our code of conduct. Additionally, hate speech includes slurs and the incitement of hatred or violence against the
            aforementioned groups. Hate speech also includes, but is not limited to, praise and glorification of terrorism, organized crime,
            hate groups, and, or dangerous individuals or groups.
          </div>
        </div>

        <div className={style.containerNote}>
          <span className={style.subText}>NOTE:</span>
          <div className={style.text}>
            If you want to discuss hate speech for educational, historical, satirical, or artistic purpose, or manner with the spirit of
            challenging it or raising awareness, you must clearly declare your intention surrounding that content, speech or expression
            PRIOR to starting or engaging in it.
          </div>
        </div>

        <div className={style.container}>
          <span className={style.subText}>Deliberate Derailment of an Online Event or Conversation.</span>
          <div className={style.text}>
            Any content that is unwelcome and intentionally controversial, contrary, repetitively off-topic, deliberately instigates anger
            or unwanted arguments, and/or any other content or conduct with the purpose of derailment or interruption of any online event,
            stream, or conversation, is a violation of our code of conduct.
          </div>
        </div>

        <div className={style.container}>
          <span className={style.subText}>Endangering Self or Others.</span>
          <div className={style.text}>
            Behavior that endangers one’s self or others includes, but is not limited to inciting, glorifying, instructing, and/or teaching
            self-harm or harm to others in any way.
          </div>
        </div>

        <div className={style.container}>
          <div className={style.subText}>SmashHause Adult Content Policy</div>
        </div>
        <div className={style.text}>
          Adult content (“18+”) is defined as any explicit sexual content and/or nudity for erotic purposes. All participants agree to:
        </div>
        <h3 className={style.adultHead}>Self Monitor:</h3>
        <ul className={style.list}>
          <li>
            <span className={style.bold}>Minors in your Home. </span>
            <div className={style.text}>
              Just like at any physical event, minors are the responsibility of their guardians at all times. All participants with minors
              in their household are expected to monitor their minors while accessing the Site to ensure that they are not accessing or
              showing adult content.
            </div>
          </li>
          <li>
            <span className={style.bold}>Alternate Platforms. </span>
            <div className={style.text}>
              18+ content may occur in participant linked platforms where minors are not systemically blocked, such as Zoom, Facebook Live,
              or Twitch. All participants are required to uphold the Terms of Service for those platforms as well as monitor their homes and
              streams to ensure minors do not access 18+ content.
            </div>
          </li>
        </ul>

        <div className={style.container}>
          <span className={style.subText}>Repercussions.</span>
          <div className={style.text}>
            Participants in violation may be blocked, restricted, or otherwise limited from usage of or access to the Site, at the sole
            discretion of Smashhaus. Transgressions that violate federal, state, or local laws will be escalated to the appropriate
            authorities.
          </div>
        </div>

        <div className={style.agreeContainer}>
          <span className={style.subText}>Agreement to Follow Code of Conduct:</span>
          <div className={style.text}>
            I have read the foregoing, understand it, and agree to abide and uphold the above Code of Conduct and Policies during my
            participation in the Site.
          </div>
        </div>
        <div className={style.buttons}>
          <button className={style.btnBack} onClick={onBackClick}>
            {'Back'}
          </button>
          <button className={style.btn} onClick={onSubmit}>
            Agree
          </button>
        </div>
      </div>
    </section>
  );
};
