import * as CurrentSubscrActions from 'actions/currentSubscription';
import * as UnsubscribeActions from 'actions/unsubscribe';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

import { fetchCurrentSubscription } from './currentSubscription';

// import { createCheckoutSession } from './subscriptionList';

export const unsubscribe = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(UnsubscribeActions.setUnsubscrLoading());
    axios
      .post(Api.Unsubscribe)
      .then(_response => {
        dispatch(UnsubscribeActions.setUnsubscrSuccess());
        dispatch(CurrentSubscrActions.clearPriceId());
        dispatch(fetchCurrentSubscription(true) as any);
      })
      .catch(error => {
        dispatch(UnsubscribeActions.setUnsubscrError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const buyNewPlan = (product_id: string, period: boolean) => {
  return (dispatch: (action: Action) => void) => {
    axios
      .post(Api.UpdateStripeSubscription, {
        product_id,
        periodicity: period ? 'year' : 'month',
      })
      .then(_response => {
        successNotification('Your subscription was successfully updated!');
        dispatch(fetchCurrentSubscription() as any);
      })
      .catch(error => {
        dispatch(UnsubscribeActions.setUnsubscrError());
        console.error(error);
        catchedErrorNotification(error);
      });
    // dispatch(createCheckoutSession(stripe_product_id, period, stripe_year_price_id, stripe_month_price_id, stripe_product_title) as any);
  };
};
