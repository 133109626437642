import * as Const from '../constants';
import State from '../interfaces/state/TrackInformationState';

const initState: State = {
  format: null,
  file_name: null,
};

export default function trackInformation(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.TRACK_INFORMATION_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.TRACK_INFORMATION_FORMAT:
      return {
        ...state,
        format: action.payload,
      };

    case Const.TRACK_INFORMATION_FILE_NAME:
      return {
        ...state,
        file_name: action.payload,
      };

    default:
      return state;
  }
}
