import * as H from 'history';

export const findGetParameter = (parameterName: string, history?: H.History) => {
  let result = null;
  let tmp = [];
  let items = history ? history.location.search.substr(1).split('&') : window.location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};
