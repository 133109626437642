import * as SubscActons from 'actions/subscriptionList';
import Popup from 'components/popup/popup';
import * as UnsubscribeCreators from 'creators/unsubscribe';
import CurrentSubscriptionState from 'interfaces/state/CurrentSubscriptionState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React from 'react';
import { connect } from 'react-redux';
import { is_active_subscription$, product_title$, status$ } from 'selectors/currentSubscription';
import { selectedSubscriptionId$ } from 'selectors/subscriptionList';

import style from './unsubscribe.module.scss';

interface IDispatchProps {
  setSubscrListId: (id: null | string) => void;
  unsubscribe: () => void;
}

interface IStateProps {
  selectedSubscriptionId: null | string;
  is_active_subscription: boolean;
  product_title: CurrentSubscriptionState['product_title'];
  status: CurrentSubscriptionState['status'];
}

interface OwnProps {}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const UnsubscribeComponent = ({
  selectedSubscriptionId,
  setSubscrListId,
  unsubscribe,
  is_active_subscription,
  product_title,
  status,
}: IProps) => {
  const onClick = () => {
    setSubscrListId(null);
    unsubscribe();
  };

  return (
    <div className={style.root}>
      {(!!selectedSubscriptionId && product_title !== 'Free' && status === 'active') ||
      (is_active_subscription && product_title !== 'Free' && status === 'active') ? (
        <Popup
          onApply={onClick}
          btnClassName={style.btn}
          buttonText={'Cancel subscription'}
          modalContent={
            <div className={style.popup}>
              <div className={style.head}>Cancel Subscription</div>
              <div className={style.text}>Are you sure you want to cancel your subscription?</div>
            </div>
          }
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedSubscriptionId: selectedSubscriptionId$(state),
    is_active_subscription: is_active_subscription$(state),
    product_title: product_title$(state),
    status: status$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setSubscrListId: dispatchProps.setSubscrListId,
    unsubscribe: dispatchProps.unsubscribe,
    ...props,
  };
};

export const Unsubscribe = connect(
  mapStateToProps,
  {
    setSubscrListId: SubscActons.setSubscrListId,
    unsubscribe: UnsubscribeCreators.unsubscribe,
  },
  mergeProps
)(UnsubscribeComponent);
