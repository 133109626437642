import { ITeam } from 'interfaces/state/TeamState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setTeamInit = (): Action => ({
  type: Const.TEAM_INIT,
});

export const setTeamPending = (): Action => ({
  type: Const.TEAM_PENDING,
});

export const setTeamError = (): Action => ({
  type: Const.TEAM_ERROR,
});

export const setTeamList = (payload: ITeam[]) => ({
  type: Const.TEAM_LIST,
  payload,
});

export const createTeam = (payload: ITeam) => ({
  type: Const.TEAM_CREATE,
  payload,
});

export const deleteTeam = (id: number) => ({
  type: Const.TEAM_DELETE,
  id,
});
