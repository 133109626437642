import { loadStripe } from '@stripe/stripe-js/pure';
import * as PaymentMethodActions from 'actions/paymentMethod';
import * as SubscriptionListActions from 'actions/subscriptionList';
import axios from 'axios';
import { STRIPE_PUBLIC_KEY_DEV, STRIPE_PUBLIC_KEY_PROD } from 'constants/index';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { paymentMethodIds$ } from 'selectors/paymentMethod';
import { envDetector } from 'utils/env';
import { catchedErrorNotification } from 'utils/error';

import { buyNewPlan } from './unsubscribe';

export const fetchSubscriptionList = (id?: string | null) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SubscriptionListActions.setSubscrListPending());

    const data = id ? { id } : {};
    axios
      .post(`${Api.SubscriptionList}`, data)
      .then(response => {
        dispatch(SubscriptionListActions.setSubscrList(response.data.result));
      })
      .catch(error => {
        dispatch(SubscriptionListActions.setSubscrListError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const createCheckoutSession = (
  stripe_product_id: string,
  period: boolean,
  stripe_year_price_id: string,
  stripe_month_price_id: string,
  stripe_product_title: string,
  isUpdate?: boolean
) => {
  return (_dispatch: (action: Action) => void) => {
    const price_id = period ? { stripe_year_price_id } : { stripe_month_price_id };
    axios
      .post(Api.CreateCheckoutSession, {
        stripe_product_id,
        ...price_id,
        stripe_product_title,
      })
      .then(async response => {
        const stripe = await loadStripe(envDetector() ? STRIPE_PUBLIC_KEY_PROD : STRIPE_PUBLIC_KEY_DEV);

        if (isUpdate) {
        } else {
          stripe!.redirectToCheckout({
            sessionId: response.data.result.id,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const selectPlan = (
  stripe_product_id: string,
  period: boolean,
  stripe_year_price_id: string,
  stripe_month_price_id: string,
  stripe_product_title: string
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(PaymentMethodActions.pMethodLoading());

    if (paymentMethodIds$(getState()).length) {
      dispatch(buyNewPlan(stripe_product_id, period) as any);
    } else {
      dispatch(createCheckoutSession(stripe_product_id, period, stripe_year_price_id, stripe_month_price_id, stripe_product_title) as any);
    }
  };
};
