import * as Const from '../constants';
import State from '../interfaces/state/SiteEventsState';

const initState: State = {
  uploadedTrack: null,
  listenedTrack: null,
  listenedtrackStatistic: null,
  isLoading: false,
  isError: false,
};

export default function siteEvents(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SITE_EVENTS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.SITE_EVENT_UPLOAD:
      return {
        ...state,
        uploadedTrack: action.payload,
      };

    case Const.SITE_EVENT_LISTENED:
      return {
        ...state,
        listenedTrack: action.payload,
      };

    case Const.SITE_EVENT_SEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SITE_EVENT_LOADING:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };

    case Const.SITE_EVENT_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.SITE_EVENT_LISTENED_FOR_STATISTIC:
      return {
        ...state,
        listenedtrackStatistic: action.payload,
      };

    default:
      return state;
  }
}
