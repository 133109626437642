import { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
import { orderBy } from 'lodash-es';
import { createSelector } from 'reselect';

import { userName$ } from './auth';

export const companies = (state: State) => state.companies.list;
export const companies$ = createSelector([companies], company => company);
export const orederedCompanies$ = (state: State) =>
  orderBy(companies$(state), (item: ICompany) =>
    item.members.find(member => member.role_name === 'owner' && member.user_name === userName$(state))
  );
export const currentCompany$ = (state: State) => state.companies.currentCompany;
export const isLoading$ = (state: State) => state.companies.isLoading;
export const isError$ = (state: State) => state.companies.isError;
