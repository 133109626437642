import 'sweetalert2/src/sweetalert2.scss';

import * as StatsActions from 'actions/stats';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const getStats = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(StatsActions.setStatsPending());

    axios
      .post(`${Api.GetStats}`)
      .then(response => {
        dispatch(StatsActions.fetchStats(response.data.result));
      })
      .catch(error => {
        dispatch(StatsActions.setStatsError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
