import * as RatingCreators from 'creators/rating';
import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useEffect, useState } from 'react';
import EventListener from 'react-event-listener';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';
import { isSwitchOffManagerEventListener$ } from 'selectors/managerEventListener';
import { current_job_id$ } from 'selectors/musicianJobs';
import { currentId$ } from 'selectors/newTrack';
import { rating$ } from 'selectors/rating';

import styles from './audioInformation.module.scss';

interface IStateProps {
  currentId: number;
  isSwitchOffManagerEventListener: boolean;
  current_job: number | null;
  rating: number | null;
}

interface OwnProps {
  title: string | null;
  isNeedRating: boolean;
  currtrackId: number | null;
  tracks: INewTrack[];
  currIndex: number;
}

interface IDispatchProps {
  setRate: (id: number, rate: number, job_id: number) => void;
}

interface IProps extends IDispatchProps, OwnProps, IStateProps {}

const AudioInformation = ({
  setRate,
  title,
  currentId,
  isNeedRating,
  currtrackId,
  rating,
  tracks,
  isSwitchOffManagerEventListener,
  current_job,
}: IProps) => {
  const [rateLocal, setRateLocal] = useState(0 as number | null);
  // eslint-disable-next-line
  useEffect(() => setRateLocal(rating), [rating]);

  const idSelector = currtrackId ? currtrackId : currentId;

  const setRating = (value: number) => {
    setRate(idSelector, value, current_job!);
  };

  const handleRating = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'Digit0':
      case 'Numpad0':
        setRating(10);
        break;

      case 'Digit1':
      case 'Numpad1':
        setRating(1);
        break;

      case 'Digit2':
      case 'Numpad2':
        setRating(2);
        break;

      case 'Digit3':
      case 'Numpad3':
        setRating(3);
        break;

      case 'Digit4':
      case 'Numpad4':
        setRating(4);
        break;

      case 'Digit5':
      case 'Numpad5':
        setRating(5);
        break;

      case 'Digit6':
      case 'Numpad6':
        setRating(6);
        break;

      case 'Digit7':
      case 'Numpad7':
        setRating(7);
        break;

      case 'Digit8':
      case 'Numpad8':
        setRating(8);
        break;

      case 'Digit9':
      case 'Numpad9':
        setRating(9);
        break;

      default:
        break;
    }
  };

  const setStarRating = (rate: number) => {
    setRate(idSelector, rate, current_job!);
  };

  const onKeyDown = (e: KeyboardEvent) => handleRating(e);

  return (
    <>
      {isNeedRating && tracks.length && !isSwitchOffManagerEventListener ? <EventListener target="window" onKeyDown={onKeyDown} /> : null}
      <section className={styles.root}>
        <div className={styles.content}>
          <div className={styles.info}>
            <div className={styles.title}>{title}</div>
          </div>
          {isNeedRating ? (
            <StarRatingComponent
              name="rate1"
              starCount={10}
              value={rateLocal ? rateLocal : 0}
              onStarClick={setStarRating}
              emptyStarColor="#EEF2F4"
            />
          ) : null}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentId: currentId$(state),
    isSwitchOffManagerEventListener: isSwitchOffManagerEventListener$(state),
    current_job: current_job_id$(state),
    rating: rating$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setRate: dispatchProps.setRate,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setRate: RatingCreators.setRate,
  },
  mergeProps
)(memo(AudioInformation));
