import axios from 'axios';
import cx from 'classnames';
import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { successNotification } from 'components/successNotification/successNotification';
import { fetchPlaylistBg } from 'creators/shareablePlaylistInfo';
import { Api } from 'enums/api';
// eslint-disable-next-line
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { background$, owner$, playlist_id$ } from 'selectors/shareablePlaylistInfo';
import { catchedErrorNotification } from 'utils/error';

import style from './header.module.scss';

const maxSize = 5350000;
const accept = ['.png', '.jpg', 'jpeg'];

const Header = memo(() => {
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const background = useSelector(background$);
  const id = useSelector(playlist_id$);
  const owner = useSelector(owner$);
  const dispatch = useDispatch();

  const loadNewBg = async (file: File) => {
    let formData = new FormData();
    formData.append('background', file![0]);
    formData.append('id', `${id}`);
    formData.append('default', JSON.stringify(false));
    setLoading(true);

    await axios
      .post(Api.UploadPlaylistBackground, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: p => {
          setPercentage((p.loaded / p.total) * 100);
        },
      })
      .then(() => {
        successNotification('Playlist background loaded successfully!');
        dispatch(fetchPlaylistBg());
      })
      .catch(err => catchedErrorNotification(err));
    setLoading(false);
  };

  const handleSendBgImage = async (file: File[] | File) => {
    loadNewBg(file as File);
  };

  const onDropReject = () => {
    errorNotification('Maximum upload size is 5Mb and image must be a .png, .jpg, .jpeg');
  };

  const imageCX = cx(style.logo, {
    [style.background]: background,
  });

  return (
    <div className={style.root}>
      <div className={style.headerWrapepeFilterBg}>
        <div
          className={imageCX}
          style={
            background
              ? {
                  background: `no-repeat url(${background})`,
                }
              : {}
          }
        ></div>
      </div>
      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.playlistName}>
            {owner ? (
              <div className={style.dropzone}>
                <div className={style.text}>{`Upload background ${percentage > 0 ? `(${percentage.toFixed(1)}% loaded)` : ''}`}</div>

                <Dropzone
                  onAccept={handleSendBgImage}
                  onDropReject={onDropReject}
                  maxSize={maxSize}
                  accept={accept}
                  disabled={isLoading}
                  text={isLoading ? <div>Image processing</div> : undefined}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;
