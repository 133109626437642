import { SubscriptionListItem } from 'interfaces/state/SubscriptionList';
import { Action } from 'redux';

import * as Const from '../constants';

export const setSubscrListInit = (): Action => {
  return {
    type: Const.SUBS_LIST_INIT,
  };
};

export const setSubscrListPending = (): Action => {
  return {
    type: Const.SUBS_LIST_PENDING,
  };
};

export const setSubscrListError = (): Action => {
  return {
    type: Const.SUBS_LIST_ERROR,
  };
};

export const setSubscrList = (payload: SubscriptionListItem[]) => {
  return {
    type: Const.SUBS_LIST_SUCCESS,
    payload,
  };
};

export const setSubscrListId = (payload: string | null) => {
  return {
    type: Const.SUBS_LIST_ID,
    payload,
  };
};

export const setSubsPeriod = (payload: boolean) => {
  return {
    type: Const.SUBS_LIST_PERIOD,
    payload,
  };
};
