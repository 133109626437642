import * as Const from '../constants';
import State from '../interfaces/state/TrackInfoState';

const initState: State = {
  title: '',
  type: 'music',
  description: '',
  tag: '',
  writer: '',
  publisher: '',
  isShowExpandPanel: false,
  isOpenEditNewTrackPopup: false,
};

export default function trackInfo(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.TRACK_INFO_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.TRACK_INFO_TITLE:
      return {
        ...state,
        title: action.payload,
      };

    case Const.TRACK_INFO_TYPE:
      return {
        ...state,
        type: action.payload,
      };

    case Const.TRACK_INFO_DESCR:
      return {
        ...state,
        description: action.payload,
      };

    case Const.TRACK_INFO_TAG:
      return {
        ...state,
        tag: action.payload,
      };

    case Const.TRACK_INFO_WRITER:
      return {
        ...state,
        writer: action.payload,
      };

    case Const.TRACK_INFO_PUBLISHER:
      return {
        ...state,
        publisher: action.payload,
      };

    case Const.TRACK_INFO_SHOW_EXPANDPANEL:
      return {
        ...state,
        isShowExpandPanel: action.payload,
      };

    case Const.SET_OPEN_EDI_NEW_TRACK_POPUP:
      return {
        ...state,
        isOpenEditNewTrackPopup: action.payload,
      };

    default:
      return state;
  }
}
