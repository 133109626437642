// eslint-disable-next-line
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { billing$ } from 'selectors/billing';

import style from './plan.module.scss';

interface IPlanProps {
  value?: number | string | JSX.Element;
  title?: string;
}

const PlanItem = ({ value, title }: IPlanProps) => {
  return (
    <div className={style.container}>
      <div className={style.key}>{title}</div>
      <div className={style.value}>{value}</div>
    </div>
  );
};

export const Plan = () => {
  const billing = useSelector(billing$);
  const history = useHistory();

  const name = useMemo(() => {
    return billing?.product_title;
  }, [billing]);

  const interval = useMemo(() => {
    return billing?.interval;
  }, [billing]);

  const price = useMemo(() => {
    return billing?.price;
  }, [billing]);

  const isActive = useMemo(() => {
    return billing?.is_active_subscription ? 'Yes' : 'No';
  }, [billing]);

  const storageAmount = useMemo(() => {
    return billing?.storage_amount;
  }, [billing]);

  const storageUsed = useMemo(() => {
    return billing?.storage_info?.used_mbytes;
  }, [billing]);

  const handleGoToPricing = useCallback(() => {
    history.push('/pricing');
  }, [history]);

  return (
    <main className={style.root}>
      <PlanItem value={<NavLink to={'/pricing'}>{name}</NavLink>} title={'Plan name: '} />
      <PlanItem value={interval} title={'Payment interval:'} />
      <PlanItem value={`${price} $`} title={'Price:'} />
      <PlanItem value={isActive} title={'Active subscription:'} />
      <PlanItem value={storageAmount} title={'Storage amount (Mb):'} />
      <PlanItem value={storageUsed} title={'Used storage (Mb):'} />
      <button className={style.pricingBtn} onClick={handleGoToPricing}>
        Go to Pricing
      </button>
    </main>
  );
};
