import 'sweetalert2/src/sweetalert2.scss';

import * as AuthActions from 'actions/auth';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { userType$ } from 'selectors/auth';
import { catchedErrorNotification } from 'utils/error';

import * as AffiliateActions from '../actions/affiliate';

export const sendAffiliateEmail = (email: string, company_id?: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(AffiliateActions.setLoadingSendAffiliate());

    const data = userType$(getState()) === 'manager' ? { email, company_id } : { email };
    axios
      .post(`${Api.CreateAffiliate}`, data)
      .then(response => {
        dispatch(AffiliateActions.getSuccessAffiliate(response.data.result.affiliate_url));
        dispatch(AffiliateActions.setSuccessSendAffiliate());
        dispatch(AuthActions.setAffiliate(true));
        successNotification('Congratulations!');
      })
      .catch(error => {
        dispatch(AffiliateActions.setErrorSendAffiliate());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getAffiliateData = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AffiliateActions.setAffiliateLoading());

    axios
      .post(Api.GetAffiliate)
      .then(response => {
        if (response.data.result) {
          dispatch(AffiliateActions.getSuccessAffiliate(response.data.result.affiliate_dashboard_url));
        }
      })
      .catch(error => {
        dispatch(AffiliateActions.setAffiliateFail());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
