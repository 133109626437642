import 'sweetalert2/src/sweetalert2.scss';

import * as SharedPlayerActions from 'actions/sharedPlayer';
import * as SharedPlaylistTracksActions from 'actions/sharedPlaylistTracks';
import axios from 'axios';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { successNotification } from 'components/successNotification/successNotification';
import * as SiteEventCreators from 'creators/siteEvents';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { playlist_id$ } from 'selectors/shareablePlaylistInfo';
import { uploadLinkHandler } from 'utils';
import { catchedErrorNotification } from 'utils/error';

export const fetchSharedPlaylistTracks = (playlist_id: number, isMusician?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SharedPlaylistTracksActions.setSharedPlaylistTracksPending());

    const url = isMusician ? Api.SharePlaylistMusicianTracks : Api.SharePlaylistTracks;

    const data = isMusician ? { application_id: playlist_id } : { playlist_id };
    axios
      .post(url, data)
      .then(res => {
        dispatch(SharedPlaylistTracksActions.setSharedPlaylistTracksReceive(res.data.result));
      })
      .catch(error => {
        dispatch(SharedPlaylistTracksActions.setSharedPlaylistTracksError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const downloadAllSharedPlaylistTracks = (link: string, type: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    if (link) {
      axios({
        url: link,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Disposition': `attachment; filename="${encodeURI(`Smashhaus_playlist_zip${Date.now()}.${type}`)}"`,
        },
        onDownloadProgress: p => {
          const total = Number(((p.loaded / p.total) * 100).toFixed(1));

          dispatch(SharedPlayerActions.setSharedPlayerProgressDownload(0, total));
          if (p.loaded / p.total === 1) {
            successNotification('The archive was successfully uploaded', 8000);
          }
        },
      })
        .then(response => {
          dispatch(SharedPlaylistTracksActions.setSharedPlaylistTracksSuccess());
          dispatch(SharedPlaylistTracksActions.setShPlaylistTrZipLoaded());
          const playlistId = playlist_id$(getState());
          if (playlistId) {
            dispatch(SiteEventCreators.sendEvent('upload_all', playlistId) as any);
          }
          uploadLinkHandler(`Smashhaus_playlist_zip${Date.now()}.${type}`, response.data);
        })
        .catch(error => {
          dispatch(SharedPlaylistTracksActions.setSharedPlaylistTracksError());
          dispatch(SharedPlaylistTracksActions.setShPlaylistTrZipLoaded());

          errorNotification(error);
        });
    }
  };
};
