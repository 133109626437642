import 'sweetalert2/src/sweetalert2.scss';

import * as AdminActions from 'actions/admin';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { IUser } from 'interfaces/IUser';
import State from 'interfaces/state/State';
import moment from 'moment';
import { Action } from 'redux';
import { currentAdminById$, currentCreatorById$, currentManagerById$ } from 'selectors/admin';
import { DATE_TIME_FORMAT, uploadLinkHandler } from 'utils';
import { catchedErrorNotification } from 'utils/error';

export const handleChangeUserData = (email: string, patch: Partial<IUser>, userType: 'creator' | 'admin' | 'manager') => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const creator = currentCreatorById$(getState())(email);
    const admin = currentAdminById$(getState())(email);
    const manager = currentManagerById$(getState())(email);

    const user = {
      creator,
      admin,
      manager,
    };

    let {
      avatar = user![userType]!.avatar,
      user_rating = user![userType]!.user_rating,
      is_disabled = user![userType]!.is_disabled,
      is_confirmed_email = user![userType]!.is_confirmed_email,
      user_name = user![userType]!.user_name,
      stat = user![userType]!.stat,
      subscription = user![userType]!.subscription,
      is_approved = user![userType]!.is_approved,
      link_1 = user![userType]!.link_1,
      link_2 = user![userType]!.link_2,
      created = user![userType]!.created,
    } = patch;

    dispatch(
      AdminActions.changeUserData(userType, {
        email,
        avatar,
        user_rating,
        is_disabled,
        is_confirmed_email,
        user_name,
        stat,
        subscription,
        is_approved,
        link_1,
        link_2,
        created,
      })
    );
  };
};

export const fetchUsersForAdmin = (
  limit: number,
  offset: number,
  user_type: 'creator' | 'admin' | 'manager',
  sort_by?: 'email' | 'date',
  sort_direction?: 'up' | 'down',
  on_hold?: boolean
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AdminActions.setAdminPending());

    const data = sort_by
      ? { limit, offset, user_type, sort_by, sort_direction, on_hold: !!on_hold }
      : { limit, offset, user_type, on_hold: !!on_hold };
    axios
      .post(`${Api.GetUsersForAdminPage}`, data)
      .then(response => {
        const users = response.data.result;

        if (user_type === 'admin') {
          offset === 0 ? dispatch(AdminActions.getAdminSuccess([], users, [])) : dispatch(AdminActions.getAdminSuccessNext([], users, []));
        } else if (user_type === 'creator') {
          offset === 0 ? dispatch(AdminActions.getAdminSuccess([], [], users)) : dispatch(AdminActions.getAdminSuccessNext([], [], users));
        } else {
          offset === 0 ? dispatch(AdminActions.getAdminSuccess(users, [], [])) : dispatch(AdminActions.getAdminSuccessNext(users, [], []));
        }
      })
      .catch(error => {
        dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const searchUsers = (user_info: string, user_type: 'creator' | 'admin' | 'manager') => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AdminActions.setAdminPending());

    axios
      .post(`${Api.SearchUsers}`, { user_info, user_type })
      .then(response => {
        const users = response.data.result;

        if (user_type === 'admin') {
          dispatch(AdminActions.getAdminSuccess([], users, []));
        } else if (user_type === 'creator') {
          dispatch(AdminActions.getAdminSuccess([], [], users));
        } else {
          dispatch(AdminActions.getAdminSuccess(users, [], []));
        }
      })
      .catch(error => {
        dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteUserFromAdmin = (email: string, type: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AdminActions.setAdminPending());

    const userType = type.slice(0, -1);

    axios
      .post(`${Api.DeleteUserFromAdminPage}`, {
        user_to_delete: email,
      })
      .then(() => {
        dispatch(
          AdminActions.deleteUserFromAdminPage(
            email,
            type as 'managers' | 'admins' | 'creators',
            userType as 'admin' | 'creator' | 'manager'
          )
        );
        successNotification(`User with email ${email} was successfully deleted!`);
      })
      .catch(error => {
        dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const handleBlockUserFromAdmin = (email: string, type: string, isBlock: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AdminActions.setAdminPending());

    let param;
    let url;
    const userType = type.slice(0, -1);

    if (isBlock) {
      param = {
        user_to_disable: email,
      };
      url = `${Api.BlockUser}`;
    } else {
      param = {
        user_to_turn: email,
      };
      url = `${Api.UnBlockUser}`;
    }
    axios
      .post(url, param)
      .then(() => {
        // dispatch(AdminActions.handleBlockUserFromAdminPage(email, type, isBlock));
        dispatch(
          handleChangeUserData(
            email,
            { is_disabled: isBlock as IUser['is_disabled'] } as Partial<IUser>,
            userType as 'creator' | 'admin' | 'manager'
          ) as any
        );

        if (isBlock) {
          successNotification(`User '${email}' was blocked successfully`);
        } else {
          successNotification(`User '${email}' was unlock successfully`);
        }
      })
      .catch(error => {
        dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const sendToHold = (email: string, on_hold: boolean, type: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AdminActions.setAdminPending());

    axios
      .post(Api.HoldUser, {
        email,
        on_hold,
      })
      .then(() => {
        const userType = type.slice(0, -1);

        dispatch(
          AdminActions.deleteUserFromAdminPage(
            email,
            type as 'managers' | 'admins' | 'creators',
            userType as 'admin' | 'creator' | 'manager'
          )
        );

        if (on_hold) {
          successNotification(`User '${email}' was added to hold list`);
        } else {
          successNotification(`User '${email}' was removed from hold list`);
        }
      })
      .catch(error => {
        dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const handleChangeSubcriptionTypeFromAdmin = (email: string, subscription: string, type: string) => {
  return (dispatch: (action: Action) => void) => {
    const userType = type.slice(0, -1);

    axios
      .post(Api.UpdateSubscription, {
        email,
        type: subscription,
      })
      .then(() => {
        dispatch(
          handleChangeUserData(
            email,
            { subscription: subscription as IUser['subscription'] },
            userType as 'creator' | 'admin' | 'manager'
          ) as any
        );
        successNotification('Subscription was changed successfully!');
      })
      .catch(error => {
        // dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const confirmUserEmail = (user_to_confirm: string, type: string) => {
  return (dispatch: (action: Action) => void) => {
    const userType = type.slice(0, -1);

    axios
      .post(Api.ConfirmUserEmail, {
        user_to_confirm,
      })
      .then(() => {
        dispatch(
          handleChangeUserData(
            user_to_confirm,
            { is_confirmed_email: true as IUser['is_confirmed_email'] },
            userType as 'creator' | 'admin' | 'manager'
          ) as any
        );
        successNotification(`Email - ${user_to_confirm} was successfully confirmed!`, 6000);
      })
      .catch(error => {
        // dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const udpateUserRating = (email: string, new_rating: number, type: 'creators' | 'admins' | 'managers') => {
  return (dispatch: (action: Action) => void) => {
    const userType = type.slice(0, -1);
    axios
      .post(Api.UpdateUserRating, {
        email,
        new_rating,
      })
      .then(() => {
        dispatch(
          handleChangeUserData(
            email,
            { user_rating: new_rating as IUser['user_rating'] },
            userType as 'creator' | 'admin' | 'manager'
          ) as any
        );
        successNotification('User rating was updated successfully!');
      })
      .catch(error => {
        // dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updateHiddenSubscription = (email: string, subscription: 'Starter-Free' | 'Premium-Free' | 'Free', type: string) => {
  return (dispatch: (action: Action) => void) => {
    const userType = type.slice(0, -1);

    axios
      .post(Api.UpdateHiddenSubscription, {
        email,
        subscription,
      })
      .then(() => {
        dispatch(
          handleChangeUserData(
            email,
            { subscription: subscription as 'starter-free' | 'premium-free' | 'free' },
            userType as 'creator' | 'admin' | 'manager'
          ) as any
        );
        successNotification('Subscription was changed successfully!');
      })
      .catch(error => {
        // dispatch(AdminActions.setAdminFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchAllUsersInCSV = (loader: (v: boolean, loadType: null | 'tracks' | 'users') => void, isLoadAllTracks?: boolean) => {
  return (_dispatch: (action: Action) => void) => {
    loader(true, isLoadAllTracks ? 'tracks' : 'users');

    const url = Api.GetAllUsersCSV;
    axios
      .post(url)
      .then(response => {
        loader(false, null);
        const date = moment().format(DATE_TIME_FORMAT);
        const text = isLoadAllTracks ? `Tracks ${date}.csv` : `Users ${date}.csv`;

        if (response?.data?.result) {
          axios({
            url: response?.data?.result,
            method: 'GET',
            responseType: 'blob',
            headers: {
              'Content-Disposition': `attachment; filename="${encodeURI(text)}"`,
            },
            onDownloadProgress: p => {
              const total = Number(((p.loaded / p.total) * 100).toFixed(1));
              console.log(total);

              if (p.loaded / p.total === 1) {
                successNotification(`Downloaded file`, 8000);
              }
            },
          }).then(r => {
            uploadLinkHandler(text, r.data);
          });
        }
      })
      .catch(error => {
        loader(false, null);

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
