import { Action } from 'redux';

import * as Const from '../constants';

export const getCurrentTab = (payload: number) => {
  return {
    type: Const.TAB_RECEIVE,
    payload,
  };
};

export const setTabInit = (): Action => {
  return {
    type: Const.TAB_INIT,
  };
};
