import 'sweetalert2/src/sweetalert2.scss';

import * as SharedPlaylistLinksActions from 'actions/shareablePlaylistLinks';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const fetchSharedPlaylistLinksById = (playlist_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SharedPlaylistLinksActions.setShareablePlaylistLinksPending());

    axios
      .post(`${Api.ShareablePlaylistLink}`, {
        playlist_id,
      })
      .then(res => {
        dispatch(SharedPlaylistLinksActions.setShareablePlaylistLinksReceive(res.data.result));
      })
      .catch(error => {
        dispatch(SharedPlaylistLinksActions.setShareablePlaylistLinksError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export interface ILinkData {
  playlist_id: number | null;
  allowed_all_tracks: boolean;
  allowed_single_track: boolean;
}
export const createShareablePlaylistLink = (linkData: ILinkData) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SharedPlaylistLinksActions.setShareablePlaylistLinksPending());

    axios
      .post(`${Api.CreateShareableLink}`, {
        playlist_id: linkData.playlist_id,
        allowed_all_tracks: linkData.allowed_all_tracks,
        allowed_single_track: linkData.allowed_single_track,
      })
      .then(() => {
        dispatch(SharedPlaylistLinksActions.setShareablePlaylistLinksSuccess());
        dispatch(fetchSharedPlaylistLinksById(linkData.playlist_id!) as any);
      })
      .catch(error => {
        dispatch(SharedPlaylistLinksActions.setShareablePlaylistLinksError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
