import './style.scss';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowDown } from 'assets/img/arrow-select.svg';
import cx from 'classnames';
// eslint-disable-next-line
import React, { ChangeEvent, useCallback } from 'react';

import style from './select.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    select: {
      color: '#343B4C',
      fontWeight: 'bold',
    },
    input: {
      backgroundColor: theme.palette.background.paper,
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
);

interface IComplicOptions {
  label: string;
  value: string | number;
}

interface IProps {
  value: string | number;
  onChange: (event: string) => void;
  placeholder: string;
  options?: string[];
  width?: number | string;
  isComplicated?: boolean;
  complicatedOptions?: IComplicOptions[];
  disabled?: boolean;
  name: string;
  minWidth?: number;
  className?: string;
}

export const SelectInternal = ({
  value,
  onChange,
  placeholder,
  options,
  width,
  isComplicated,
  complicatedOptions,
  disabled,
  name,
  minWidth,
  className,
}: IProps) => {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const SelectIconCX = cx(style.icon, {
    [style.open]: isOpen,
  });

  return (
    <FormControl className={`${classes.formControl} ${className}`} style={{ width: width, minWidth: minWidth }}>
      <InputLabel className={classes.input} htmlFor="material-select_sm">
        {placeholder}
      </InputLabel>
      <Select
        disabled={disabled}
        className={classes.select}
        native
        value={value}
        onChange={handleChange}
        IconComponent={() => <ArrowDown className={SelectIconCX} />}
        inputProps={{
          name,
          id: 'material-select_sm',
        }}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <option key={'index'} style={{ display: 'none' }}></option>
        {!!isComplicated
          ? complicatedOptions!.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))
          : null}

        {!isComplicated
          ? options!.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))
          : null}
      </Select>
    </FormControl>
  );
};
