import { Tooltip } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import InsertPhotoRoundedIcon from '@material-ui/icons/InsertPhotoRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import axios from 'axios';
import cx from 'classnames';
import { errorNotification } from 'components/errorNotification/errorNotification';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { ICompanyInfo } from 'pages/companyInfo';
// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';
import { emailValidation } from 'utils';
import { catchedErrorNotification } from 'utils/error';

import { ImageUploader } from '../imageUploader';
import style from './content.module.scss';

const uploadItems = [
  {
    id: 0,
    title: 'Upload avatar',
    subtitle: '(Square proportions are recommended)',
    url: Api.UpdateCompanyAvatar,
    successText: 'Company logo loaded successfully!',
    appendParam: 'logo',
    maxSize: 1070000,
  },
  {
    id: 1,
    title: 'Upload bg for sign-up page',
    subtitle: '(No more than 5 MB)',
    url: Api.UpdateCompanyBg,
    successText: 'Sign-up page background loaded successfully!',
    appendParam: 'background',
    maxSize: 5350000,
  },
  {
    id: 2,
    title: 'Upload search icon',
    subtitle: '(No more than 1 MB)',
    url: Api.UpdateCompanySearchIcon,
    successText: 'Search icon loaded successfully!',
    appendParam: 'logo_for_job_type',
    maxSize: 1070000,
  },
  {
    id: 3,
    title: 'Upload login page background image',
    subtitle: '(No more than 5 MB)',
    url: Api.UpdateCompanyLoginImage,
    successText: 'Login page bg loaded successfully!',
    appendParam: 'login_background',
    maxSize: 5350000,
  },
];

interface IProps {
  company: ICompanyInfo | null;
  isLoading: boolean;
  updateVerify: () => void;
  setLoading: (v: boolean) => void;
  fetchCompanyInfo: () => void;
}

export const Content = ({ company, updateVerify, isLoading, setLoading, fetchCompanyInfo }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState(0);

  const toggleOpenPopup = () => {
    setOpen(prev => !prev);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const toggleVerificationClick = () => {
    updateVerify();
  };

  const handleSendEmail = async () => {
    setLoading(true);
    if (email?.length) {
      await axios
        .post(Api.CreateAffiliate, { email, company_id: company?.id })
        .then(res => res.data.result.affiliate_url)
        .catch(err => catchedErrorNotification(err));
      await fetchCompanyInfo();
    } else {
      errorNotification('You need to enter email!');
    }
    setLoading(false);
    setOpen(false);
  };

  const handleJobClick = () => {
    setTab(0);
  };

  const handleSettingsClick = () => {
    setTab(2);
  };

  const handleImagesClick = () => {
    setTab(1);
  };

  const onCopy = (text?: string | null) => {
    if (navigator && text) {
      navigator.clipboard.writeText(text);
      successNotification(`Link was copied successfully!`);
    }
  };

  const handleCopySignUpBg = () => {
    onCopy(company?.background_for_sign_up);
  };

  const handleCopyLoginBg = () => {
    onCopy(company?.login_background);
  };

  const handleCopyLogoForJObType = () => {
    onCopy(company?.logo_for_job_type);
  };

  const handleCopyLogo = () => {
    onCopy(company?.logo);
  };

  const IconCX = cx(style.icon, {
    [style.disabled]: !emailValidation(email),
  });

  const JobsHeadCX = cx(style.headItem, {
    [style.selected]: tab === 0,
  });
  const ImagesHeadCX = cx(style.headItem, {
    [style.selected]: tab === 1,
  });
  const SettingsHeadCX = cx(style.headItem, {
    [style.selected]: tab === 2,
  });

  const JobsHeaderCX = cx(style.jobsHeader, {
    [style.extraPadding]: company?.jobs?.length && company?.jobs?.length > 12,
  });

  return (
    <div className={style.root}>
      <div className={style.topContent}>
        <div className={style.avatarContainer}>
          {company?.logo ? (
            <img className={style.avatar} alt="logo" src={company?.logo} />
          ) : (
            <div className={style.defaultAvatar}>
              <InsertPhotoRoundedIcon className={style.defaultIcon} />
            </div>
          )}
        </div>
        <div className={style.container}>
          <div className={style.label}>Affiliate information</div>
          <div className={style.infoItem}>
            <div className={style.left}>Url: </div>
            <div className={style.right}>
              {company?.affiliate_url ? (
                company?.affiliate_url
              ) : (
                <button className={style.addUrlButton} onClick={toggleOpenPopup}>
                  +
                </button>
              )}
            </div>
          </div>
          <div className={style.infoItem}>
            <div className={style.left}>Dashboard url: </div>
            <div className={style.right}>{company?.affiliate_dashboard_url}</div>
          </div>
          <div className={style.infoItem}>
            <div className={style.left}>Email: </div>
            <div className={style.right}>{company?.affiliate_email}</div>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.label}>Company information</div>
          <div className={style.infoItem}>
            <div className={style.left}>Name: </div>
            <div className={style.right}>{company?.name}</div>
          </div>
          <div className={style.infoItem}>
            <div className={style.left}>Description: </div>
            <div className={style.right}>{company?.description}</div>
          </div>
          <div className="d-flex align-center flex-wrap">
            <div className={style.infoItem}>
              <div className={style.left}>Owner email: </div>
              <div className={style.right}>{company?.owner_email}</div>
            </div>
            <div className={`${style.infoItem} m-l-10`}>
              <div className={style.left}>Owner name: </div>
              <div className={style.right}>{company?.owner_name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.middleContent}>
        <div className={style.verificationContainer}>
          <div className={style.verification}>Verification status:</div>
          <div className={style.status}>{company?.verified ? 'Verified' : 'Not verified'}</div>
          <button className={style.btn} onClick={toggleVerificationClick} disabled={isLoading && !emailValidation(email)}>
            Toggle Verification
          </button>
        </div>
      </div>
      <div className={style.bottomContent}>
        <div className={style.bottomHeader}>
          <div className={JobsHeadCX} onClick={handleJobClick}>
            Jobs
          </div>
          <div className={ImagesHeadCX} onClick={handleImagesClick}>
            Images
          </div>
          <div className={SettingsHeadCX} onClick={handleSettingsClick}>
            Settings
          </div>
        </div>

        <div className={style.mainContent}>
          {tab === 0 && <div className={style.jobsHead}>{`All company jobs (${company?.jobs?.length || 0})`}</div>}
          {tab === 0 && (
            <div className={JobsHeaderCX}>
              <div>Title</div>
              <div>Type</div>
              <div>Status</div>
              <div>Shared tracks</div>
              <div>Playlist tracks</div>
              <div>Applied tracks</div>
              <div>Min rating</div>
              <div>Team job</div>
            </div>
          )}

          {tab === 0 ? (
            <div className={style.scrolledContent}>
              {company?.jobs?.map(job => {
                return (
                  <div key={job.id} className={style.jobContainer}>
                    <div className={style.jobTitle}>{job.title}</div>
                    <div className={style.jobRowItem}>{job.type}</div>
                    <div className={style.jobRowItem}>{job.status}</div>
                    <div className={style.jobRowItem}>{job.shared_tracks}</div>
                    <div className={style.jobRowItem}>{job.playlist_tracks}</div>
                    <div className={style.jobRowItem}>{job.applied_tracks}</div>
                    <div className={style.jobRowItem}>{job.min_rating}</div>
                    <div className={style.jobRowItem}>{JSON.stringify(job.is_team_job)}</div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {tab === 1 ? (
            <div className={style.imagesContainer}>
              <div className={style.imageHead}>All company images (sign up bg, login bg, logo for job type, company logo )</div>
              <div className={style.imagesWrapper}>
                {company?.background_for_sign_up && (
                  <div className={style.imageItemWrapper}>
                    <div className={style.text}>Sign up bg</div>
                    <Tooltip title={navigator ? 'Copy to clipboard link' : ''}>
                      <LinkRoundedIcon className={style.copyLink} onClick={handleCopySignUpBg} />
                    </Tooltip>

                    <img src={company?.background_for_sign_up} className={style.imageItem} alt="sign up" />
                  </div>
                )}

                {company?.login_background && (
                  <div className={style.imageItemWrapper}>
                    <div className={style.text}>Login bg</div>
                    <Tooltip title={navigator ? 'Copy to clipboard link' : ''}>
                      <LinkRoundedIcon className={style.copyLink} onClick={handleCopyLoginBg} />
                    </Tooltip>

                    <img src={company?.login_background} className={style.imageItem} alt="login" />
                  </div>
                )}

                {company?.logo_for_job_type && (
                  <div className={style.imageItemWrapper}>
                    <div className={style.text}>Job type logo</div>
                    <Tooltip title={navigator ? 'Copy to clipboard link' : ''}>
                      <LinkRoundedIcon className={style.copyLink} onClick={handleCopyLogoForJObType} />
                    </Tooltip>

                    <img src={company?.logo_for_job_type} className={style.imageItem} alt="job type logo" />
                  </div>
                )}

                {company?.logo && (
                  <div className={style.imageItemWrapper}>
                    <div className={style.text}>Company logo</div>
                    <Tooltip title={navigator ? 'Copy to clipboard link' : ''}>
                      <LinkRoundedIcon className={style.copyLink} onClick={handleCopyLogo} />
                    </Tooltip>

                    <img src={company?.logo} className={style.imageItem} alt="logo" />
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {tab === 2 && (
            <div className={style.uploaderContainer}>
              <div className={style.imageHead}>Upload company images (sign up bg, login bg, logo for job type, company logo )</div>
              <div className={style.uploadContent}>
                {uploadItems.map(item => {
                  return <ImageUploader key={item.id} {...item} fetchCompanyInfo={fetchCompanyInfo} company_id={company?.id!} />;
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Popup
        isOpen={isOpen}
        btnClassName="mus-track-edit"
        onDecline={toggleOpenPopup}
        isHideApplyButton
        isHideMainButton
        modalContent={
          <div className={style.popupContainer}>
            <div className={style.popupHead}>For more information you need to add affiliate email</div>
            <div className={style.emailContainer}>
              <input
                className={style.input}
                value={email}
                onChange={handleEmailChange}
                placeholder={'Enter affiliate email'}
                disabled={isLoading}
              />
              <button className={style.btnSend} onClick={handleSendEmail}>
                <CheckCircleRoundedIcon className={IconCX} />
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};
