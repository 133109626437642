import * as SharedPlayerActions from 'actions/sharedPlayer';
import * as SharedPlayerCreators from 'creators/sharedPlayer';
import * as SiteEventCreator from 'creators/siteEvents';
import { INewTrack } from 'interfaces/INewTrack';
import { IDownloadProgress, IPlayerURLById } from 'interfaces/state/SharedPlayerState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { allowed_single_track$ } from 'selectors/shareablePlaylistInfo';
import { currentTrack$, currentURLByID$, downloadProgressByID$, isPlayingTrack$ } from 'selectors/sharedPlayer';
import { currentIndex$, list$ } from 'selectors/sharedPlaylistTracks';

import { Track } from '../track';
import style from './trackList.module.scss';

interface IStateProps {
  list: INewTrack[];
  currentIndex: number | null;
  allowed_single_track: boolean;
  downloadProgressByID: IDownloadProgress;
  currentURLByID: IPlayerURLById;
  currentTrack: INewTrack | null;
  isPlayingTrack: boolean | null;
}

interface OwnProps {}
interface IDispatchProps {
  downloadSharedTrack: (track_id: number, title: string) => void;
  setSharedPlayerReceive: (track: INewTrack) => void;
  fetchTrackUrl: (track_id: number) => void;
  setPlayAll: (isPlayAll: boolean) => void;
  sendEvent: (type: 'listen' | 'upload' | 'upload_all', playlist_id?: number, n?: string) => void;
}
interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const TrackList = ({
  list,
  currentIndex,
  allowed_single_track,
  downloadProgressByID,
  currentURLByID,
  currentTrack,
  isPlayingTrack,
  downloadSharedTrack,
  setSharedPlayerReceive,
  fetchTrackUrl,
  setPlayAll,
  sendEvent,
}: IProps) => {
  const onDownloadTrackById = (id: number, title: string, name_in_base: string) => {
    downloadSharedTrack(id, title);
    sendEvent('upload', undefined, name_in_base);
  };
  const onPlayTrack = (track: INewTrack) => {
    setSharedPlayerReceive(track);
    fetchTrackUrl(track.track_id!);
    setPlayAll(false);
    sendEvent('listen', undefined, track.name_in_base!);
  };

  return (
    <div className={style.root}>
      {list.map((track, index) => {
        return (
          <Track
            key={index}
            index={index}
            currentIndex={currentIndex}
            track={track}
            isHideDownload={!allowed_single_track}
            onDownload={onDownloadTrackById}
            onPlayTrack={onPlayTrack}
            downloadProgressByID={downloadProgressByID}
            currentURLByID={currentURLByID}
            currentTrack={currentTrack}
            isPlayingTrack={isPlayingTrack}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    currentIndex: currentIndex$(state),
    list: list$(state),
    allowed_single_track: allowed_single_track$(state),
    downloadProgressByID: downloadProgressByID$(state),
    currentURLByID: currentURLByID$(state),
    currentTrack: currentTrack$(state),
    isPlayingTrack: isPlayingTrack$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    downloadSharedTrack: dispatchProps.downloadSharedTrack,
    setSharedPlayerReceive: dispatchProps.setSharedPlayerReceive,
    fetchTrackUrl: dispatchProps.fetchTrackUrl,
    setPlayAll: dispatchProps.setPlayAll,
    sendEvent: dispatchProps.sendEvent,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    downloadSharedTrack: SharedPlayerCreators.downloadSharedTrack,
    setSharedPlayerReceive: SharedPlayerActions.setSharedPlayerReceive,
    fetchTrackUrl: SharedPlayerCreators.fetchTrackUrl,
    setPlayAll: SharedPlayerActions.setPlayAll,
    sendEvent: SiteEventCreator.sendEvent,
  },
  mergeProps
)(memo(TrackList));
