import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { errorNotification } from 'components/errorNotification/errorNotification';
import Input from 'components/input/input';
import Popup from 'components/popup/popup';
// eslint-disable-next-line
import React, { useState, ChangeEvent } from 'react';

import style from './createPlaylist.module.scss';

interface IProps {
  onCreate: (name: string) => void;
  isButton?: boolean;
  isBlocked?: boolean;
}
export const CreatePlaylistPopover = ({ onCreate, isBlocked }: IProps) => {
  const [newPlaylistName, setPlName] = useState('');

  const onApplyCreatePlaylist = () => {
    if (newPlaylistName) {
      onCreate(newPlaylistName);
    } else {
      errorNotification('Please enter new name to playlist');
    }
  };

  const onPlaylistNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlName(e.target.value);
  };

  return (
    <Popup
      isOpen={isBlocked ? false : undefined}
      onApply={onApplyCreatePlaylist}
      onDecline={() => ''}
      btnClassName="btn-del-playlist"
      buttonText={
        <Tooltip title="Create a playlist">
          <AddIcon className="share-option-icon" style={{ height: 21 }} />
        </Tooltip>
      }
      modalContent={
        <div style={{ height: 200 }} className="d-flex align-center flex-column">
          <div className="playlist-title-text">Create new playlist</div>
          <Input
            styleClass={style.input}
            type="text"
            value={newPlaylistName}
            onChange={onPlaylistNameChange}
            placeholder={'Playlist name'}
            isRequired
          />
        </div>
      }
    />
  );
};
