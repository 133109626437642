import { LoginErrorPopup } from 'containers/loginErrorPopup';
import NoAuthAdmin from 'modules/auth/noAuthAdmin';
import NoAuthorized from 'modules/auth/noAuthorized';
import SignUp from 'modules/auth/signUp';
import ConfirmEmail from 'pages/confirmEmail/confirmEmail';
import Help from 'pages/help/help';
import InvitePage from 'pages/invitePage';
import { NotFound } from 'pages/notFound/notFound';
import { PrivacyPolicy } from 'pages/privacyPolicy';
import ResetPassword from 'pages/resetPassword/resetPassword';
import { SharedPlaylist } from 'pages/sharedPlaylist';
import { TechnicalWorks } from 'pages/technicalWorks';
// eslint-disable-next-line
import React, { ReactNode, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { isOpenTechnicalPage$ } from 'selectors/auth';
import { CustomRoute } from 'utils/addRoute';
import { publicRoute } from 'utils/routes';

export const PublicHandler = memo(() => {
  const isOpenTechnicalPage = useSelector(isOpenTechnicalPage$);
  const history = useHistory();

  useEffect(() => {
    if (isOpenTechnicalPage) {
      history.push(publicRoute.technicalWork);
    } else {
      if (history.location.pathname === publicRoute.technicalWork) {
        history.push(publicRoute.main);
      }
    }
  }, [isOpenTechnicalPage, history]);

  const renderPublicRoutes = useCallback((): ReactNode => {
    return (
      <>
        <LoginErrorPopup />
        <Switch>
          <Route exact path={publicRoute.technicalWork}>
            <TechnicalWorks />
          </Route>
          <CustomRoute path={publicRoute.admin} component={<NoAuthAdmin />} />
          <CustomRoute path={publicRoute.signUp} component={<SignUp />} />
          <CustomRoute path={publicRoute.registerFromCompanyInvite} component={<InvitePage />} />
          <Route exact path={publicRoute.confirm}>
            <ConfirmEmail />
          </Route>
          <Route exact path={publicRoute.confirmChangeEmail}>
            <ConfirmEmail isChange />
          </Route>
          <Route exact path={publicRoute.confirmNewEmail}>
            <ConfirmEmail isNew />
          </Route>
          <CustomRoute path={publicRoute.resetPass} component={<ResetPassword />} />
          <CustomRoute path={publicRoute.help} component={<Help />} />
          <CustomRoute path={publicRoute.shareablePlaylist} component={<SharedPlaylist />} />
          <CustomRoute path={publicRoute.shareableApplication} component={<SharedPlaylist isMusicianPlaylist />} />
          <CustomRoute path={publicRoute.privacyPolicy} component={<PrivacyPolicy />} />
          <CustomRoute exact path={publicRoute.main} component={<NoAuthorized />} />
          <Route exact path="*">
            <NotFound />
          </Route>
        </Switch>
      </>
    );
  }, []);

  return <>{renderPublicRoutes()}</>;
});
