import * as Const from '../constants';
import State from '../interfaces/state/shareablePlaylistInfoState';

const initState: State = {
  allowed_all_tracks: false,
  allowed_single_track: false,
  playlist_id: null,
  link_id: null,
  isLoading: false,
  isError: false,
  background: null,
  owner: false,
};

export default function shareablePlaylistInfo(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SH_PLAYLIST_INFO_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.SH_PLAYLIST_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SH_PLAYLIST_INFO_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SH_PLAYLIST_INFO_RECEIVE:
      return {
        ...state,
        allowed_all_tracks: action.payload.allowed_all_tracks,
        allowed_single_track: action.payload.allowed_single_track,
        playlist_id: action.payload?.playlist_id || action.payload?.application_id,
        link_id: action.payload.link_id,
        background: action.payload?.background,
        owner: action.payload?.owner,
        isLoading: false,
        isError: false,
      };

    case Const.SH_PLAYLIST_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.SH_PLAYLIST_BG:
      return {
        ...state,
        isLoading: false,
        background: action.payload,
      };

    default:
      return state;
  }
}
