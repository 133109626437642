// eslint-disable-next-line
import React from 'react';

export const PlaylistDescription = () => {
  return (
    <div className="playlist-desc-container d-flex align-center m-t-5">
      <div className="playlist-desc-track">Play track</div>
      <div className="playlist-desc-title">Track title</div>
      <div className="playlist-desc-control">Remove</div>
    </div>
  );
};
