import * as DownlActions from 'actions/downloadTrack';
import * as NewTracksAction from 'actions/newTracks';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import * as SiteEventsCreators from 'creators/siteEvents';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { formatBytes } from 'utils/formatBytes';

export const downloadTrack = (linkUrl: string | null, title: string, track_id: number) => {
  return async (dispatch: (action: Action) => void) => {
    dispatch(DownlActions.setDownloadTrackPending());

    if (!linkUrl) {
      axios
        .post(`${Api.GetTrackURL}`, {
          track_id,
        })
        .then(response => {
          dispatch(downloadTrack(response.data.result.url, title, track_id) as any);
        })
        .catch(() => {
          dispatch(DownlActions.setDownloadTrackFail());
        });
    }
    if (linkUrl) {
      axios({
        url: linkUrl!,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Disposition': `attachment; filename="${encodeURI(title)}.mp3"`,
        },
        onDownloadProgress: p => {
          dispatch(NewTracksAction.setDownloadInformation(p.loaded, p.total, track_id));
          if (p.loaded / p.total === 1) {
            successNotification(`Downloaded track ${title}  (${formatBytes(p.total)})`, 8000);
          }
        },
      })
        .then(response => {
          dispatch(DownlActions.getDownloadTrackSuccess());
          dispatch(SiteEventsCreators.sendEvent('upload') as any);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${title}.mp3`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          dispatch(DownlActions.setDownloadTrackFail());
        });
    }
  };
};
