import * as CreatorPlaylistActions from 'actions/application';
import * as NewTrackActions from 'actions/newTracks';
import { Loader } from 'components/loader/loader';
import { PlaylistTrack } from 'components/playistTrack';
import { PlaylistPopoverContent } from 'components/playlist/playlistPopoverContent';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import * as CreatorPlaylists from 'creators/application';
import * as NewTrackCreators from 'creators/newTracks';
import IApp from 'interfaces/IApp';
import { INewTrack } from 'interfaces/INewTrack';
import AppState from 'interfaces/state/AppState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { appById$, appIds$, hasUnsavedPlaylist$, isLoading$, playlist_id$ } from 'selectors/applications';
import { currentPlayedTrackId$, isPlay$ } from 'selectors/musicianPlayer';
import { filterBy$, tracks_for_playlist$ } from 'selectors/newTrack';
import { isNeedToScroollToTrackInRightPanel$ } from 'selectors/song';

import style from './playlistContent.module.scss';

interface IStateProps {
  appById: AppState['appById'];
  playlist_id: AppState['playlist_id'];
  currentPlayedTrackId: null | number;
  isPlayerPlay: boolean;
  tracks_for_playlist: INewTrack[];
  isNeedToScroollToTrackInRightPanel: boolean;
}
interface OwnProps {
  createProcessing: boolean;
  setCreateProcessing: (v: boolean) => void;
}
interface IDispatchProps {
  onPlayerPlayClick: (title: string, name: string, id: number | null, rating: number, isCreator?: boolean) => void;
  createPlaylist: (tracks_id: IApp['tracks'], job_id?: number, description?: string) => void;
  getNewTracksForPlaylist: (tr: INewTrack[] | null) => void;
  delTrackFromCreatorApp: (id: number, track_id: number) => void;
  updatePlaylist: (tracks: IApp['tracks'], id: number, track_id: number, isCreator?: boolean, tracks_?: IApp['tracks']) => void;
  setPlaylistId: (id: number | null) => void;
}

interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const PlaylistContentInternal = ({
  appById,
  playlist_id,
  currentPlayedTrackId,
  isPlayerPlay,
  tracks_for_playlist,
  isNeedToScroollToTrackInRightPanel,
  onPlayerPlayClick,
  createPlaylist,
  getNewTracksForPlaylist,
  delTrackFromCreatorApp,
  updatePlaylist,
  setPlaylistId,
  setCreateProcessing,
}: IProps) => {
  const memoTracks = useMemo(() => {
    if (playlist_id) {
      return appById[playlist_id]?.tracks ? appById[playlist_id].tracks : [];
    } else {
      return [];
    }
  }, [playlist_id, appById]);

  const [savedPlaylistId, setSavedPlaylistId] = useState<null | number>(null);

  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [playlistName, setPlaylistName] = useState('' as number | string);
  const hasUnsavedPlaylist = useSelector(hasUnsavedPlaylist$);
  const filterBy = useSelector(filterBy$);
  const appIds = useSelector(appIds$);
  const isLoading = useSelector(isLoading$);

  useEffect(() => {
    if (playlist_id) {
      setSavedPlaylistId(playlist_id);
    }
  }, [playlist_id]);

  const onClick = (title: string, name: string, id: number | null, rating: number) => {
    onPlayerPlayClick(title, name, id, rating, true);
  };

  const handleDeleteTrackFromPlaylist = (track_id: number) => {
    const newTrackList = tracks_for_playlist.filter(tr => tr.track_id !== track_id);
    getNewTracksForPlaylist(newTrackList);
    if (playlist_id) {
      delTrackFromCreatorApp(playlist_id, track_id);
    }
  };

  const handleSaveClick = () => {
    const tracks = tracks_for_playlist.map(tr => ({ track_id: tr.track_id, title: tr.title }));

    if (!playlist_id) {
      setOpen(true);
    } else {
      updatePlaylist(tracks as IApp['tracks'], playlist_id, 0, true, tracks! as IApp['tracks']);
      successNotification('Updated successfully!');
      dispatch(CreatorPlaylistActions.changeUnsaved(false));
    }
  };

  const handleAddNewPlaylist = useCallback(() => {
    setSavedPlaylistId(playlist_id);
    setPlaylistId(null);
    getNewTracksForPlaylist([]);
    dispatch(CreatorPlaylistActions.changeUnsaved(true));
    setCreateProcessing(true);
    // eslint-disable-next-line
  }, [playlist_id]);

  const handleCreatePlaylist = useCallback(() => {
    const tracks = tracks_for_playlist.map(tr => ({
      track_id: tr.track_id,
      title: tr.title,
    }));
    if (!playlist_id) {
      createPlaylist(tracks as IApp['tracks'], undefined, playlistName as string);
    }
    setOpen(false);
    setPlaylistName('');
    dispatch(CreatorPlaylistActions.changeUnsaved(false));
    // eslint-disable-next-line
  }, [playlist_id, tracks_for_playlist, playlistName]);

  const handleDecline = useCallback(() => {
    setOpen(false);
    setPlaylistName('');
  }, []);

  const handleCancel = () => {
    if (savedPlaylistId) {
      if (appIds?.length) {
        dispatch(CreatorPlaylists.fetchTracksToPlaylistById(savedPlaylistId));
        setPlaylistId(savedPlaylistId);
      } else {
        getNewTracksForPlaylist([]);
      }
    } else {
      getNewTracksForPlaylist([]);
      dispatch(NewTrackCreators.fetchTracks(filterBy, true, 80, 0, false, true));
    }

    setCreateProcessing(false);
    dispatch(CreatorPlaylistActions.changeUnsaved(false));
  };

  const showMessage = useMemo(() => {
    return !playlist_id && !tracks_for_playlist.length && !memoTracks.length;
  }, [playlist_id, tracks_for_playlist, memoTracks]);

  return (
    <div className={style.root}>
      <div className={style.tracks}>
        {isLoading && (
          <div className={style.loading}>
            <Loader />
          </div>
        )}
        {playlist_id && appById[playlist_id]?.tracks
          ? appById[playlist_id]?.tracks.map(track => {
              return (
                <PlaylistTrack
                  key={track.track_id}
                  track={track}
                  onClick={onClick}
                  onDelete={handleDeleteTrackFromPlaylist}
                  isPlayerPlay={isPlayerPlay}
                  currentPlayedTrackId={currentPlayedTrackId}
                  isNeedToScroollToTrackInRightPanel={isNeedToScroollToTrackInRightPanel}
                />
              );
            })
          : null}
        {!playlist_id &&
          tracks_for_playlist.map(track => {
            return (
              <PlaylistTrack
                key={track.track_id!}
                track={track}
                onClick={onClick}
                onDelete={handleDeleteTrackFromPlaylist}
                isPlayerPlay={isPlayerPlay}
                currentPlayedTrackId={currentPlayedTrackId}
                isNeedToScroollToTrackInRightPanel={isNeedToScroollToTrackInRightPanel}
              />
            );
          })}
        {showMessage ? (
          <div className={style.emptyMessage}>
            Just add tracks (click "+" button on the track) and click to the "Save playlist" button. After that, you can create a link and
            share the playlist
          </div>
        ) : null}
      </div>
      <div className={style.buttons}>
        {hasUnsavedPlaylist && (
          <button className={style.cancelBtn} onClick={handleCancel}>
            Cancel
          </button>
        )}

        {!!hasUnsavedPlaylist && (
          <button className={style.saveBtn} onClick={handleSaveClick}>
            {!playlist_id ? 'Create playlist' : 'Save changes'}
          </button>
        )}

        {!!playlist_id && !!appById[playlist_id]?.tracks?.length ? (
          <button className={style.clearBtn} onClick={handleAddNewPlaylist}>
            Add new playlist
          </button>
        ) : null}
      </div>

      <Popup
        onApply={handleCreatePlaylist}
        btnClassName="btn-del-playlist"
        isOpen={isOpen}
        isApplyDisabled={(playlistName as string).length === 0}
        onDecline={handleDecline}
        isHideMainButton
        buttonText={''}
        modalContent={<PlaylistPopoverContent value={playlistName as string} onChange={setPlaylistName} />}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    playlist_id: playlist_id$(state),
    appById: appById$(state),
    currentPlayedTrackId: currentPlayedTrackId$(state),
    isPlayerPlay: isPlay$(state),
    tracks_for_playlist: tracks_for_playlist$(state),
    isNeedToScroollToTrackInRightPanel: isNeedToScroollToTrackInRightPanel$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    onPlayerPlayClick: dispatchProps.onPlayerPlayClick,
    createPlaylist: dispatchProps.createPlaylist,
    getNewTracksForPlaylist: dispatchProps.getNewTracksForPlaylist,
    delTrackFromCreatorApp: dispatchProps.delTrackFromCreatorApp,
    setPlaylistId: dispatchProps.setPlaylistId,
    updatePlaylist: dispatchProps.updatePlaylist,
    ...props,
  };
};

export const PlaylistContent = connect(
  mapStateToProps,
  {
    onPlayerPlayClick: NewTrackCreators.onPlayerPlayClick,
    createPlaylist: CreatorPlaylists.createPlaylist,
    updatePlaylist: CreatorPlaylists.updatePlaylist,
    getNewTracksForPlaylist: NewTrackActions.getNewTracksForPlaylist,
    delTrackFromCreatorApp: CreatorPlaylistActions.delTrackFromCreatorApp,
    setPlaylistId: CreatorPlaylistActions.setPlaylistId,
  },
  mergeProps
)(memo(PlaylistContentInternal));
