import State from 'interfaces/state/State';

export const isLoading$ = (state: State): boolean => state.currentSubscription.isLoading;
export const isError$ = (state: State): boolean => state.currentSubscription.isError;
export const interval$ = (state: State) => state.currentSubscription.interval;
export const stripe_month_price_id$ = (state: State) => state.currentSubscription.stripe_month_price_id;
export const stripe_year_price_id$ = (state: State) => state.currentSubscription.stripe_year_price_id;
export const is_active_subscription$ = (state: State) => state.currentSubscription.is_active_subscription;
export const product_title$ = (state: State) => state.currentSubscription.product_title;
export const storage_info$ = (state: State) => state.currentSubscription.storage_info;
export const current_period_end$ = (state: State) => state.currentSubscription.current_period_end;
export const current_period_start$ = (state: State) => state.currentSubscription.current_period_start;
export const currentSubscription$ = (state: State) => state.currentSubscription.currentSubscription;
export const status$ = (state: State) => state.currentSubscription.status;
