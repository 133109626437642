import Slider, { Mark } from '@material-ui/core/Slider';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line
import React, { ChangeEvent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 100 + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(3),
    },
  })
);

function valuetext(value: number, _index: number) {
  return `${value}°C`;
}

interface IProps {
  from: number;
  to: number;
  onChange: (event: ChangeEvent<{}>, value: number | number[]) => void;
  max: number;
  min: number;
  value?: number | number[];
  step?: number;
  marks?: boolean | Mark[];
  disabled?: boolean;
  onChangeCommitted?: (event: ChangeEvent<{}>, value: number | number[]) => void;
  withTwoSliders?: boolean;
  color?: 'primary' | 'secondary';
  displayLabel?: 'on' | 'off' | 'auto';
}

const SliderComponent = withStyles({
  valueLabel: {
    left: 'calc(-50% + 1px)',
    top: -19,
    width: 25,
    height: 20,
    overflow: 'hidden',
    textAlign: 'center',
    lineHeight: 2,
    fontSize: 11,
    borderRadius: '3px',

    background: '#04040499',
    '& *': {
      background: 'none',
      width: 25,
      height: 20,
      borderRadius: '3px',
      color: '#fff',
      transform: 'rotate(0deg)',
    },
    circle: {
      background: 'red',
    },
  },
})(Slider);

export const SliderMaterial = ({
  to,
  onChange,
  onChangeCommitted,
  max,
  step,
  min,
  marks,
  disabled,
  withTwoSliders,
  from,
  color,
  displayLabel,
}: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SliderComponent
        disabled={disabled}
        marks={marks}
        valueLabelDisplay={displayLabel ? displayLabel : 'auto'}
        step={step}
        max={max}
        min={min}
        color={color}
        aria-label="custom"
        aria-labelledby="range-slider"
        value={withTwoSliders ? [from, to] : to}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        getAriaValueText={valuetext}
      />
    </div>
  );
};
