import { Tooltip } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { ReactComponent as AExpressIcon } from 'assets/img/americanExpress.svg';
import { ReactComponent as MCIcon } from 'assets/img/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/img/visa.svg';
import { IconRoundButton } from 'components/iconRoundButton';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import { deletePaymentMethod, selectDefaultPayment } from 'creators/billing';
import { IPaymentMethod } from 'interfaces/state/BillingState';
// eslint-disable-next-line
import React, { useCallback, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading$ } from 'selectors/billing';

import style from './paymentItem.module.scss';

interface IProps {
  payment: IPaymentMethod;
}

export const PaymentItem = ({ payment }: IProps) => {
  const dispatch = useDispatch();
  const brand = useMemo(() => payment.brand, [payment]);
  const country = useMemo(() => payment.country, [payment]);
  const year = useMemo(() => payment.exp_year, [payment]);
  const month = useMemo(() => payment.exp_month, [payment]);
  const last4 = useMemo(() => payment.last4, [payment]);
  const type = useMemo(() => payment.type, [payment]);
  const isDefault = useMemo(() => payment?.default, [payment]);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const isLoading = useSelector(isLoading$);

  const renderPaymentIcon = useCallback(() => {
    if (brand === 'visa') {
      return <VisaIcon className={style.visaIcon} />;
    } else if (brand === 'mastercard' || brand === 'mc') {
      return <MCIcon className={style.visaIcon} />;
    } else if (brand === 'american_express' || brand === 'americanExpress') {
      return <AExpressIcon className={style.visaIcon} />;
    } else {
      return '';
    }
  }, [brand]);

  const handleMakeDefault = useCallback(() => {
    if (isLoading) {
      successNotification('Loading. Please wait');
    } else {
      dispatch(selectDefaultPayment(payment.payment_method_id));
    }
    // eslint-disable-next-line
  }, [isLoading, payment]);

  const handleDelete = useCallback(() => {
    dispatch(deletePaymentMethod(payment.payment_method_id));
    setOpenPopup(false);
    // eslint-disable-next-line
  }, []);

  const onClick = useCallback(() => {
    setOpenPopup(true);
    // eslint-disable-next-line
  }, []);

  const onDecline = useCallback(() => {
    setOpenPopup(false);
  }, []);

  return (
    <div className={style.root}>
      <div className={style.column}>{renderPaymentIcon()}</div>
      <div className={style.column}>
        <Tooltip title={country}>
          <span>
            <ReactCountryFlag countryCode={country} svg style={{ fontSize: '18px' }} />
          </span>
        </Tooltip>
      </div>
      <div className={style.column}>
        <div>{month}</div>
        <div>/</div>
        <div>{year}</div>
      </div>
      <div className={style.column}>{last4}</div>
      <div className={style.column}>{type}</div>
      {isDefault && (
        <div className={style.column} style={{ width: 112 }}>
          <Tooltip title="Default selected for payment">
            <span>
              <DoneAllIcon className={style.defaultIcon} style={{ fill: 'green' }} />
            </span>
          </Tooltip>
        </div>
      )}

      {!isDefault && (
        <div className={style.action} onClick={handleMakeDefault}>
          <DoneAllIcon className={style.defaultIcon} style={{ fill: 'white' }} />
          <span>Make default</span>
        </div>
      )}

      <Popup
        onApply={handleDelete}
        isOpen={isOpenPopup && !isLoading}
        onDecline={onDecline}
        btnClassName="btn-del-playlist"
        buttonText={
          <IconRoundButton
            size="small"
            icon={<DeleteRoundedIcon />}
            tooltipText={isLoading ? 'Loading. Please wait' : 'Delete payment method'}
            onClick={onClick}
            className={style.deleteBtn}
          />
        }
        modalContent={
          <div style={{ height: 'auto' }} className="d-flex align-center flex-column p-b-10">
            <div className="playlist-title-text">Confirmation of removal</div>
            <div className={style.popupText}>Are you sure you want to delete current "Payment method"?</div>
            <div className={style.popupText}>This is permanently delete your "Payment method" from our database.</div>

            {isDefault && (
              <div className={style.popupText}>
                <strong>This is your default payment method.</strong> If you delete it the subscription will be canceled at the end of the
                paid period. Or you will need to choose another default payment method.
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};
