import { getCurrentTab } from 'actions/tab';
import cx from 'classnames';
import Aside from 'components/aside/aside';
import InfoPanel from 'components/infoPanel/infoPanel';
import NewTrackPopup from 'components/newTrackPopup';
import { SwitchTabs } from 'containers/switchTabs/switchTabs';
import { WebsocketHandler } from 'containers/websocketHandler';
import SignUp from 'modules/auth/signUp';
import { Affiliate } from 'pages/affiliate';
import { AnswerPayment } from 'pages/answerPayment';
import ConfirmEmail from 'pages/confirmEmail/confirmEmail';
import InvitePage from 'pages/invitePage/index';
import { BillingPage } from 'pages/musician/billing';
import { PlaylistPage } from 'pages/musician/newPlaylist';
// import MusicianJobs from 'pages/musician/jobList';
import { PlaylistsPage } from 'pages/musician/playlists';
import MusicianStatistics from 'pages/musician/statistics';
import MusicianTracks from 'pages/musician/trackList';
import { NotFound } from 'pages/notFound/notFound';
import { Pricing } from 'pages/pricing';
import { PrivacyPolicy } from 'pages/privacyPolicy';
import { ProfilePage } from 'pages/profile';
import ResetPassword from 'pages/resetPassword/resetPassword';
import { RoutePage } from 'pages/routePage/routePage';
import { SharedPlaylist } from 'pages/sharedPlaylist';
import { TeamPage } from 'pages/team';
import { TechnicalWorks } from 'pages/technicalWorks';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
// import { currentTab$ } from 'selectors/tab';
import { isNeedToRedirectOnUnknownRoute } from 'utils';
import { CustomRoute } from 'utils/addRoute';
import { privateRoute, publicRoute } from 'utils/routes';

import style from './creator.module.scss';

const allRoutes = [
  publicRoute.confirm,
  publicRoute.confirm,
  privateRoute.creatorPrefix + privateRoute.creator.statistic,
  privateRoute.creatorPrefix + privateRoute.creator.trackList,
  publicRoute.registerFromCompanyInvite,
  publicRoute.registerFromCompanyInvite,
  publicRoute.shareablePlaylist,
  publicRoute.shareableApplication,
  privateRoute.pricing,
  privateRoute.creatorPrefix + privateRoute.creator.jobList,
  privateRoute.creatorPrefix + privateRoute.creator.playlist,
  privateRoute.affiliate,
  privateRoute.team,
  publicRoute.signUp,
  publicRoute.privacyPolicy,
  privateRoute.billing,
  privateRoute.profile,
  publicRoute.confirmChangeEmail,
  publicRoute.confirmNewEmail,
  privateRoute.successCheckout,
  privateRoute.pricing,
  privateRoute.main,
  publicRoute.resetPass,
];
const CreatorModuleComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isShowMusicianInfo = useMemo(() => {
    const routes = [
      '/',
      '/creator/statistic',
      '/creator/track/list',
      '/pricing',
      '/affiliate',
      '/team',
      '/creator/job/list',
      '/privacy-policy',
      '/creator/playlist',
      '/billing',
      '/profile',
      '/technical-works',
    ];
    return routes.includes(history.location.pathname);
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const handleChangeTab = useCallback(() => {
    if (history.location.pathname === '/creator/statistic' || history.location.pathname === '/') {
      dispatch(getCurrentTab(0));
    } else if (history.location.pathname === '/creator/job/list') {
      dispatch(getCurrentTab(1));
    } else if (history.location.pathname === '/creator/track/list') {
      dispatch(getCurrentTab(2));
    } else if (history.location.pathname === '/affiliate') {
      dispatch(getCurrentTab(3));
    } else if (history.location.pathname === '/team') {
      dispatch(getCurrentTab(4));
    } else if (history.location.pathname === '/pricing') {
      dispatch(getCurrentTab(5));
    } else if (history.location.pathname === '/creator/playlist') {
      dispatch(getCurrentTab(6));
    }

    if (isNeedToRedirectOnUnknownRoute(allRoutes, history.location.pathname)) {
      history.push('/404');
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  // eslint-disable-next-line
  useEffect(() => handleChangeTab(), [handleChangeTab]);

  const mainCXCreator = cx({
    [style.main]: isShowMusicianInfo,
    [style.mainFullWidth]: !isShowMusicianInfo,
  });

  return (
    <WebsocketHandler>
      <div className="d-flex">
        {isShowMusicianInfo && <Aside tabs={<SwitchTabs />} />}
        <main className={mainCXCreator}>
          {isShowMusicianInfo && <InfoPanel isShowPopupsButtons={false} />}
          <Switch>
            <CustomRoute path={publicRoute.confirm} component={<RoutePage component={<ConfirmEmail />} />} />

            <CustomRoute
              path={privateRoute.creatorPrefix + privateRoute.creator.statistic}
              component={<RoutePage component={<MusicianStatistics />} />}
            />
            {/* <CustomRoute path={'/creator/job/list'} component={<RoutePage component={<MusicianJobs />} />} /> */}
            <CustomRoute
              path={privateRoute.creatorPrefix + privateRoute.creator.trackList}
              component={<RoutePage component={<MusicianTracks />} />}
            />
            <CustomRoute path={publicRoute.registerFromCompanyInvite} component={<InvitePage />} />
            <CustomRoute path={publicRoute.shareablePlaylist} component={<SharedPlaylist />} />
            <CustomRoute path={publicRoute.shareableApplication} component={<SharedPlaylist isMusicianPlaylist />} />
            <CustomRoute path={privateRoute.pricing} component={<Pricing />} />
            <CustomRoute path={privateRoute.creatorPrefix + privateRoute.creator.jobList} component={<PlaylistsPage />} />
            <CustomRoute path={privateRoute.creatorPrefix + privateRoute.creator.playlist} component={<PlaylistPage />} />
            <CustomRoute path={privateRoute.affiliate} component={<Affiliate />} />
            <CustomRoute path={privateRoute.team} component={<TeamPage isCreator />} />
            <CustomRoute path={publicRoute.signUp} component={<SignUp />} />
            <CustomRoute path={publicRoute.privacyPolicy} component={<PrivacyPolicy />} />
            <CustomRoute path={privateRoute.billing} component={<BillingPage />} />
            <CustomRoute path={privateRoute.profile} component={<ProfilePage />} />
            <CustomRoute path={publicRoute.confirmChangeEmail} component={<ConfirmEmail isChange />} />
            <CustomRoute path={publicRoute.confirmNewEmail} component={<ConfirmEmail isNew />} />
            <CustomRoute path={privateRoute.successCheckout} component={<AnswerPayment />} />
            <CustomRoute path={publicRoute.resetPass} component={<ResetPassword />} />

            <Route path={privateRoute.cancelChekout}>
              <Redirect to={privateRoute.pricing} />
            </Route>
            <CustomRoute exact path={publicRoute.technicalWork} component={<TechnicalWorks />} />
            <Route path="/404">
              <NotFound />
            </Route>
            <CustomRoute exact={false} path={privateRoute.main} component={<RoutePage component={<MusicianStatistics />} />} />
          </Switch>
          <NewTrackPopup />
        </main>
      </div>
    </WebsocketHandler>
  );
};

export const CreatorModule = memo(CreatorModuleComponent);
