import { INewTrack } from 'interfaces/INewTrack';
import { Action } from 'redux';

import * as Const from '../constants';

export const setSharedPlaylistTracksInit = (): Action => {
  return {
    type: Const.SHARED_PLAYLIST_TRACKS_INIT,
  };
};

export const setSharedPlaylistTracksSuccess = (): Action => {
  return {
    type: Const.SHARED_PLAYLIST_TRACKS_SUCCESS,
  };
};

export const setSharedPlaylistTracksReceive = (payload: INewTrack[]) => {
  return {
    type: Const.SHARED_PLAYLIST_TRACKS_RECEIVE,
    payload,
  };
};

export const setSharedPlaylistTracksError = (): Action => {
  return {
    type: Const.SHARED_PLAYLIST_TRACKS_ERROR,
  };
};

export const setSharedPlaylistTracksId = (payload: number | null) => {
  return {
    type: Const.SHARED_PLAYLIST_TRACKS_ID,
    payload,
  };
};

export const setSharedPlaylistTracksPending = () => {
  return {
    type: Const.SHARED_PLAYLIST_TRACKS_PENDING,
  };
};

export const setShPlaylistTrZipLoading = () => {
  return {
    type: Const.SHARED_PLAYLIST_DOWNLOAD_ZIP,
  };
};

export const setShPlaylistTrZipLoaded = () => {
  return {
    type: Const.SHARED_PLAYLIST_DOWNLOAD_ZIP_LOADED,
  };
};
