import { MobileMessage } from 'components/mobileMessage';
import { PrivateHandler } from 'containers/privatHandler';
import { PublicHandler } from 'containers/publicHandler';
import { getInfo } from 'creators/auth';
// eslint-disable-next-line
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { isAuth$ } from 'selectors/auth';
import { detectMob } from 'utils';

export const DeviceHandler = memo(() => {
  const isAuth = useSelector(isAuth$);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/sign-up')) dispatch(getInfo());
    // eslint-disable-next-line
  }, []);

  const handleMobileDevices = useMemo(() => {
    if ((detectMob() && location.pathname === '/sign-up') || (detectMob() && location.pathname === '/confirm-new-email') || !detectMob()) {
      return isAuth ? (
        <PrivateHandler />
      ) : (
        <Router>
          <PublicHandler />
        </Router>
      );
    } else {
      return <MobileMessage />;
    }
    // eslint-disable-next-line
  }, [isAuth, location]);

  return <>{handleMobileDevices}</>;
});
