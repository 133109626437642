import './style.scss';

import * as NewTrackActions from 'actions/newTracks';
import * as SongActions from 'actions/song';
import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import ManagerTrackControllHeader from 'components/managerTrackControllHeader/managerTrackControllHeader';
import ManagerTrackControlls from 'components/managerTrackControlls/managerTrackControlls';
import * as TrackCreators from 'creators/newTracks';
import * as TeamCreators from 'creators/team';
import { INewTrack, INewTrackType, TrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
import { ITrackWriter } from 'interfaces/state/TrackInfoState';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useSelector } from 'react-redux';
import { current_job_id$ } from 'selectors/musicianJobs';
import {
  all$,
  amount$,
  currentPlaylistId$,
  isLoading$,
  orderedTracks$,
  selected_tracks$,
  share_type$,
  submittedSongs$,
} from 'selectors/newTrack';
import { current_name$ } from 'selectors/playlist';
import { isPlay$ } from 'selectors/song';
import { isLoading$ as isLoadingTeam$ } from 'selectors/team';

import TrackItem from './trackItem';
import style from './trackPanel.module.scss';

interface OwnProps {
  isAdmin: boolean;
  isCreator?: boolean;
  type?: TrackType;
  setType?: (t: TrackType) => void;
  setSearch?: (search: string) => void;
  setNotNeedToUpdate?: (v: boolean) => void;
}
interface IStateProps {
  selected_tracks: number[] | null;
  current_name: string;
  isPlay: boolean;
  orderedTracks: INewTrack[];
  currentJobId: number | null;
  amount: number;
  share_type: 'auto_forwarding' | 'playlist_sharing' | null;
  currentSharedPlaylistId: number | null;
  allTracks: INewTrackType[];
  isLoading: boolean;
  isLoadingTeam: boolean;
}

interface IDispatchProps {
  setCurrentTrackInfo: (title: string, id: number, writer: ITrackWriter[], duration: number) => void;
  setCurrSongUrl: (name: string) => void;
  setSongTime: (id: number) => void;
  fetchTeamList: () => void;
  getAppliedTrack: (id: number, limit?: number, offset?: number, withoutRating?: boolean, isQualityFilter?: boolean) => void;
  getSharedTracks: (playlist_id: number, from?: number, to?: number, notNeedToInit?: boolean, limit?: number, offset?: number) => void;
  fetchTracks: (type: TrackType, preload?: boolean, limit?: number, offset?: number) => void;
}

interface IProps extends OwnProps, IDispatchProps, IStateProps {}

const TrackPanel = ({
  selected_tracks,
  current_name,
  isPlay,
  orderedTracks,
  isAdmin,
  currentJobId,
  amount,
  share_type,
  isCreator,
  allTracks,
  type,
  isLoading,
  setType,
  getSharedTracks,
  setCurrSongUrl,
  setSongTime,
  setCurrentTrackInfo,
  fetchTeamList,
  getAppliedTrack,
  fetchTracks,
  currentSharedPlaylistId,
  isLoadingTeam,
  setSearch,
  setNotNeedToUpdate,
}: IProps) => {
  const submittedSongs = useSelector(submittedSongs$);
  const fetchSharedPlaylistTracks = useCallback(() => {
    if (currentJobId && orderedTracks.length < submittedSongs) {
      getAppliedTrack(currentJobId, 50, orderedTracks.length, undefined, true);
    }
    // eslint-disable-next-line
  }, [currentJobId, orderedTracks.length, submittedSongs]);

  const fetchAutoforwardingPlaylistTracks = useCallback(() => {
    if (currentSharedPlaylistId && amount > orderedTracks.length) {
      getSharedTracks(currentSharedPlaylistId, undefined, undefined, true, 50, orderedTracks.length);
    }
    // eslint-disable-next-line
  }, [orderedTracks.length, amount, currentSharedPlaylistId]);

  const fetchNextTracks = () => {
    if (isAdmin) {
      fetchSharedPlaylistTracks();
    } else {
      if (isCreator) {
        fetchTracks(type!, false, 80, allTracks.length);
      } else {
        if (share_type === 'playlist_sharing') {
          fetchAutoforwardingPlaylistTracks();
        } else if (share_type === 'auto_forwarding') {
          fetchSharedPlaylistTracks();
        }
      }
    }
  };

  useEffect(() => {
    if (!isCreator && !isLoadingTeam) fetchTeamList();
  }, [isCreator]);

  const containerCX = cx('track-panel-container', {
    isadmin: isAdmin,
    iscreator: isCreator,
    ismanager: !isAdmin && !isCreator,
  });

  const memoTracks = useMemo(() => {
    return isCreator ? allTracks : orderedTracks;
  }, [isCreator, allTracks, orderedTracks]);

  const tracksLength = useMemo(() => (isCreator ? allTracks.length : orderedTracks.length), [isCreator, allTracks, orderedTracks]);

  // useEffect(() => {
  //   if (tracksLength && isCreator && currentPlaylistId && appById) {
  //     const currentPlaylist = appById[currentPlaylistId];
  //     const tracks = currentPlaylist?.tracks;
  //     dispatch(NewTrackActions.setSelectedTracks(tracks || []));
  //   }
  // }, [tracksLength, isCreator, currentPlaylistId, dispatch, appById]);

  return (
    <section className={containerCX}>
      <ManagerTrackControlls
        title="Music submissions"
        subtitle="Add tracks from this list to playlist to submit them for companies"
        controlText="Quality filter"
        isAdmin={isAdmin}
        type={type!}
        setType={setType!}
        isCreator={isCreator}
        fetchTracks={fetchTracks}
        setSearch={setSearch}
        setNotNeedToUpdate={setNotNeedToUpdate}
      />
      <ManagerTrackControllHeader isCreator={isCreator} />
      <div className="track-item-wrap" id={'scrollableDiv'}>
        <InfiniteScroll
          dataLength={tracksLength}
          next={fetchNextTracks}
          hasMore={true}
          height={'calc(100vh - 450px)'}
          className={'infinite-scroll-wrapper'}
          loader={<div />}
          endMessage={
            <>
              {!isCreator && !orderedTracks.length ? (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>Nothing was found</b>
                </p>
              ) : null}
              {!isCreator && !!orderedTracks.length ? (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>---</b>
                </p>
              ) : null}
            </>
          }
          scrollableTarget="scrollableDiv"
          style={{ position: 'relative' }}
        >
          <>
            {isLoading && (
              <div className={style.loading}>
                <Loader />
              </div>
            )}
            {memoTracks.map((track: INewTrack, index: number) => {
              return (
                <TrackItem
                  key={index}
                  index={index}
                  isPlay={isPlay}
                  setCurrSongUrl={setCurrSongUrl}
                  setSongTime={setSongTime}
                  current_name={current_name}
                  selected_tracks={selected_tracks}
                  track={track}
                  setCurrentTrackInfo={setCurrentTrackInfo}
                  isCreator={isCreator}
                />
              );
            })}
          </>
        </InfiniteScroll>
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selected_tracks: selected_tracks$(state),
    current_name: current_name$(state),
    isPlay: isPlay$(state),
    orderedTracks: orderedTracks$(state),
    currentJobId: current_job_id$(state),
    amount: amount$(state),
    share_type: share_type$(state),
    allTracks: all$(state),
    isLoading: isLoading$(state),
    currentSharedPlaylistId: currentPlaylistId$(state),
    isLoadingTeam: isLoadingTeam$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setCurrentTrackInfo: dispatchProps.setCurrentTrackInfo,
    setCurrSongUrl: dispatchProps.setCurrSongUrl,
    setSongTime: dispatchProps.setSongTime,
    fetchTeamList: dispatchProps.fetchTeamList,
    getAppliedTrack: dispatchProps.getAppliedTrack,
    getSharedTracks: dispatchProps.getSharedTracks,
    fetchTracks: dispatchProps.fetchTracks,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setCurrentTrackInfo: NewTrackActions.setCurrentTrackInfo,
    setCurrSongUrl: SongActions.setCurrSongUrl,
    setSongTime: SongActions.setSongTime,
    fetchTeamList: TeamCreators.fetchTeamList,
    getAppliedTrack: TrackCreators.getAppliedTrack,
    getSharedTracks: TrackCreators.getSharedTracks,
    fetchTracks: TrackCreators.fetchTracks,
  },
  mergeProps
)(memo(TrackPanel));
