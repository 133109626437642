import './style.scss';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import LinkOffRoundedIcon from '@material-ui/icons/LinkOffRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import * as ManagerEventListenerActions from 'actions/managerRatingEventListener';
import * as PlaylistActions from 'actions/playlist';
import cx from 'classnames';
import { ISelectItem } from 'components/materialSelect/materialSelect';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import { SelectInternal } from 'components/select/select';
import { successNotification } from 'components/successNotification/successNotification';
import { SwitchMaterial } from 'components/switch';
import * as DownloadFileCreators from 'creators/downloadFile';
import * as NewTracksCreators from 'creators/newTracks';
import * as PlaylistCreators from 'creators/playlist';
import { ILinkData, createShareablePlaylistLink, fetchSharedPlaylistLinksById } from 'creators/shareablePlaylistLinks';
import { sharePlaylist, sharePlaylistToMember } from 'creators/sharePlaylist';
import { INewTrack } from 'interfaces/INewTrack';
import { IPlaylist } from 'interfaces/IPlaylist';
import { ICompany } from 'interfaces/state/CompaniesState';
import { ShareableLink } from 'interfaces/state/shareablePlaylistLinksState';
import State from 'interfaces/state/State';
import orderBy from 'lodash-es/orderBy';
// eslint-disable-next-line
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isAuth$, isLoading$ } from 'selectors/auth';
import { currentCompany$ } from 'selectors/companies';
import { isError$ as isErrorDownloadFile$, isLoading$ as isLoadingFile$ } from 'selectors/downloadFile';
import { current_job_id$ } from 'selectors/musicianJobs';
import { tracks$, tracks_for_playlist$ } from 'selectors/newTrack';
import { currentPlaylist$, playlist$, playlist_id$ } from 'selectors/playlist';
import { isLoading$ as isLoadingLinks$, list$ } from 'selectors/shareablePlaylistLinks';

import PlaylistContent from './playlistContent';
import { PlaylistDescription } from './playlistDescription';
import { PlaylistLinks } from './playlistLinks';
import { PlaylistPopoverContent } from './playlistPopoverContent';
import { SharePlaylistPopupContent } from './sharePlaylistPopupContent';
import { Loader } from 'components/loader/loader';

type InitShareablePlaylist = {
  playlist_id: null | number;
  allowed_all_tracks: boolean;
  allowed_single_track: boolean;
};

const initShareablePlaylistInfo: InitShareablePlaylist = {
  playlist_id: null,
  allowed_all_tracks: true,
  allowed_single_track: true,
};

interface IStateProps {
  playlist: IPlaylist[];
  current_job: number | null;
  tracks_for_playlist: INewTrack[];
  isAuth: boolean;
  isLoading: boolean;
  playlist_id: number | null;
  currentPlaylist: number | null;
  currentCompany: ICompany | null;
  list: ShareableLink[];
  isLoadingLinks: boolean;
  tracks: INewTrack[];
  isLoadingFile: boolean;
  isErrorDownloadFile: boolean;
}

interface IDispatchProps {
  sharePlaylist: (id: number) => void;
  deletePlaylist: (id: number) => void;
  getPlaylistTracks: (id: number, limit: number, offset: number) => void;
  setCurrentPlaylistId: (id: number) => void;
  createPlaylist: (tracks_id: number[], job_id: number, name: string) => void;
  setCurrPlaylistNum: (id: number | null) => void;
  setManagerEventListenerOn: () => void;
  setManagerEventListenerInit: () => void;
  sharePlaylistToMember: (playlist_id: number, member_to: number) => void;
  createShareablePlaylistLink: (linkData: ILinkData) => void;
  downloadFile: (id: number, title: string) => void;
}

interface OwnProps {
  isAdmin?: boolean;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const Playlist = ({
  sharePlaylist,
  deletePlaylist,
  getPlaylistTracks,
  setCurrentPlaylistId,
  createPlaylist,
  setCurrPlaylistNum,
  setManagerEventListenerOn,
  setManagerEventListenerInit,
  sharePlaylistToMember,
  createShareablePlaylistLink,
  downloadFile,
  current_job,
  tracks_for_playlist,
  playlist,
  playlist_id,
  currentPlaylist,
  currentCompany,
  isAdmin,
  list,
  isLoadingLinks,
  tracks,
  isErrorDownloadFile,
  isLoadingFile,
}: IProps) => {
  const [inputValue, setInputValue] = useState('' as number | string);
  const [optionsAnchor, setOptionsAnchor] = useState<HTMLButtonElement | null>(null);
  const [linkAnchor, setLinkAnchor] = useState<HTMLButtonElement | null>(null);
  const [selectedForShare, setShareLabel] = useState('Whole company');
  const [shareValue, setShareValue] = useState('Whole company' as string | number);
  const [currentPlaylistValue, setCurrentPlaylistValue] = useState<ISelectItem>({ value: '', label: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPlaylist !== null && !isNaN(currentPlaylist) && playlist?.length) {
      const newPlaylists = orderBy(playlist, 'playlist_id', 'desc');
      const result = {
        label: newPlaylists[currentPlaylist]?.playlist_name!,
        value: newPlaylists[currentPlaylist]?.playlist_id!,
      };
      setCurrentPlaylistValue(result);
      if (!result?.value) {
        setCurrentPlaylistValue({ value: '', label: '' });
      }
    } else {
      setCurrentPlaylistValue({ value: '', label: '' });
    }
  }, [currentPlaylist, playlist]);

  const handleCloseOptions = () => {
    setOptionsAnchor(null);
    setManagerEventListenerInit();
  };

  const handleCloseLink = () => {
    setLinkAnchor(null);
    setManagerEventListenerInit();
  };

  const [shareablePlaylistInfo, setShareablePlaylist] = useState(initShareablePlaylistInfo);

  useEffect(() => setShareablePlaylist({ ...initShareablePlaylistInfo, playlist_id }), [playlist_id, setShareablePlaylist]);

  const isOpenLink = Boolean(linkAnchor);
  const linkId = isOpenLink ? 'popover' : undefined;

  const isOpenOptions = Boolean(optionsAnchor);
  const optionsId = isOpenOptions ? 'simple-popover' : undefined;

  const playlists = useMemo(() => {
    if (playlist) {
      return orderBy(playlist, 'playlist_id', 'desc').map((item, index) => ({ label: item.playlist_name, value: item.playlist_id, index }));
    } else {
      return [];
    }
  }, [playlist]);

  const onClick = () => {
    if (selectedForShare === 'Whole company') {
      if (playlist_id !== null) sharePlaylist(Number(playlist_id));
      handleCloseOptions();
    } else {
      if (playlist_id !== null) sharePlaylistToMember(Number(playlist_id), Number(shareValue));
      handleCloseOptions();
    }
  };

  const onChange = (item: number | string) => {
    getPlaylistTracks(Number(item), 100, 0);
    setCurrentPlaylistId(Number(item));

    const index = orderBy(playlist, 'playlist_id', 'desc').findIndex(pl => +pl.playlist_id === +item);
    setCurrPlaylistNum(index);
  };

  const handleDelete = () => {
    handleCloseOptions();
    if (playlist_id) {
      deletePlaylist(playlist_id);
    }
  };

  const handleCreatePlaylist = () => {
    createPlaylist([], current_job!, inputValue as string);
    setInputValue('');
    handleCloseOptions();
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setManagerEventListenerOn();
    } else {
      setManagerEventListenerInit();
    }
  };

  const handleOptionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsAnchor(event.currentTarget);
  };

  const handleLinkClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (playlist_id) {
      dispatch(fetchSharedPlaylistLinksById(playlist_id));
      setLinkAnchor(event.currentTarget);
    }
  };

  const handleGenerateLink = () => {
    if (playlist_id) {
      createShareablePlaylistLink(shareablePlaylistInfo);
    }
  };

  const handleAllowDownloadTrack = () => {
    setShareablePlaylist({ ...shareablePlaylistInfo, allowed_single_track: !shareablePlaylistInfo.allowed_single_track });
  };

  const handleAllowDownloadAllTracks = () => {
    setShareablePlaylist({ ...shareablePlaylistInfo, allowed_all_tracks: !shareablePlaylistInfo.allowed_all_tracks });
  };

  const downloadCX = cx('download-csv-playlist', {
    'disabled-downl': !playlist_id || !tracks_for_playlist.length,
    'error-download-file': isErrorDownloadFile,
    'loading-download-file': isLoadingFile,
  });

  const handleDownloadMetadataClick = () => {
    if (playlist_id && tracks_for_playlist.length) {
      downloadFile(playlist_id!, `Company ${currentCompany?.name}, playlist - ${playlist[currentPlaylist!].playlist_name}`);
    }

    if (!tracks_for_playlist.length) {
      successNotification('There are no tracks in this playlist');
    }
  };

  const containerCX = cx('playlist-container', {
    isAdmin,
  });

  return (
    <section className={containerCX}>
      <div className="playlist-controls d-flex flex-column justify-around">
        <div className="d-flex flex-column">
          <div className="d-flex align-center" style={{ marginTop: '-3px' }}>
            <span className="playlist-title m-r-a">Playlist</span>
            <Tooltip title="Share playlist">
              <span>
                <IconButton size="small" aria-describedby={linkId} color="default" onClick={handleLinkClick}>
                  {playlist_id ? <LinkRoundedIcon /> : <LinkOffRoundedIcon />}
                </IconButton>
              </span>
            </Tooltip>
            {isAdmin && (
              <Tooltip title="Download playlist metadata">
                <span>
                  <IconButton size="small" aria-describedby={linkId} color="default" onClick={handleDownloadMetadataClick}>
                    <GetAppRoundedIcon className={downloadCX} />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            <Tooltip title="Playlist options">
              <span>
                <IconButton className="more_icon" size="small" aria-describedby={optionsId} color="default" onClick={handleOptionsClick}>
                  <MoreVertRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          {/* <span className="playlist-subtitle">Select moderated music</span> */}
        </div>
        <div className="d-flex align-center">
          <SelectInternal
            className="playlist-select-submission"
            name="upload-select"
            value={currentPlaylistValue?.value}
            onChange={onChange}
            complicatedOptions={playlists}
            isComplicated
            placeholder={'Select moderated music'}
            width={'100%'}
          />
        </div>
      </div>
      <PlaylistDescription />
      <PlaylistContent options={tracks_for_playlist} />
      <div className="d-flex align-center justify-around">
        <Popover
          id={optionsId}
          open={isOpenOptions}
          anchorEl={optionsAnchor}
          onClose={handleCloseOptions}
          verticalAnchor="bottom"
          horizontalAnchor="center"
          verticalTransform="top"
          horizontalTransform="left"
          content={
            <div className="d-flex flex-column playlist-options">
              <Popup
                disabled={!playlist_id || !tracks_for_playlist}
                onApply={onClick}
                isVisibleOverflow
                btnClassName="btn-del-playlist"
                buttonText={
                  <Tooltip title="Share a playlist with ...">
                    <div className="d-flex align-center">
                      <ShareRoundedIcon className="share-option-icon" />
                      <span className="playlist-options-item">Share a playlist with ...</span>
                    </div>
                  </Tooltip>
                }
                modalContent={
                  <SharePlaylistPopupContent
                    isAdmin={isAdmin}
                    currentCompany={currentCompany}
                    shareLabel={selectedForShare}
                    setShareValue={setShareValue}
                    shareValue={shareValue}
                    setShareLabel={setShareLabel}
                    onOpenChange={onOpenChange}
                  />
                }
              />
              <Popup
                disabled={!current_job || !tracks.length}
                onApply={handleCreatePlaylist}
                btnClassName="btn-del-playlist"
                isApplyDisabled={(inputValue as string).length === 0}
                onOpen={setManagerEventListenerOn}
                buttonText={
                  <Tooltip title="Create a playlist">
                    <div className="d-flex align-center">
                      <PlaylistAddIcon className="share-option-icon" />
                      <span className="playlist-options-item">Create a playlist</span>
                    </div>
                  </Tooltip>
                }
                modalContent={<PlaylistPopoverContent value={inputValue as string} onChange={setInputValue} />}
              />
              <Popup
                disabled={!playlist_id}
                onApply={handleDelete}
                btnClassName="btn-del-playlist"
                buttonText={
                  <Tooltip title="Delete a playlist">
                    <div className="d-flex playlist-option align-center">
                      <DeleteForeverRoundedIcon className="share-option-icon" style={{ height: 21 }} />
                      <span className="playlist-options-item">Delete a playlist</span>
                    </div>
                  </Tooltip>
                }
                modalContent={
                  <div style={{ height: 100 }} className="d-flex align-center flex-column">
                    <div className="playlist-title-text">Delete this playlist</div>
                    <div>Are you sure that you want to delete current playlist?</div>
                  </div>
                }
              />
            </div>
          }
        />
      </div>
      <div>
        <Popover
          id={linkId}
          open={isOpenLink}
          anchorEl={linkAnchor}
          onClose={handleCloseLink}
          verticalAnchor="bottom"
          horizontalAnchor="center"
          verticalTransform="top"
          horizontalTransform="left"
          content={
            <div className="d-flex flex-column playlist-link-container">
              {isLoadingLinks && (
                <div className={'playlist-link-loading'}>
                  <Loader />
                </div>
              )}
              <div className="playlist-link-title">Generate link to share</div>
              <div className="playlist-link-item">
                <SwitchMaterial
                  onChange={handleAllowDownloadAllTracks}
                  checked={shareablePlaylistInfo.allowed_all_tracks}
                  label={<div className="playlist-link-label label-1">Allow downloading all tracks</div>}
                />
              </div>
              <div className="playlist-link-item">
                <SwitchMaterial
                  onChange={handleAllowDownloadTrack}
                  checked={shareablePlaylistInfo.allowed_single_track}
                  label={<div className="playlist-link-label label-2">Allow downloading tracks separately</div>}
                />
              </div>
              <div>
                <button className="playlist-link-btn" onClick={handleGenerateLink} disabled={isLoadingLinks}>
                  Create new link
                </button>
              </div>
              <PlaylistLinks list={list} />
            </div>
          }
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    current_job: current_job_id$(state),
    tracks_for_playlist: tracks_for_playlist$(state),
    isAuth: isAuth$(state),
    isLoading: isLoading$(state),
    playlist: playlist$(state),
    playlist_id: playlist_id$(state),
    currentPlaylist: currentPlaylist$(state),
    currentCompany: currentCompany$(state),
    list: list$(state),
    isLoadingLinks: isLoadingLinks$(state),
    tracks: tracks$(state),
    isLoadingFile: isLoadingFile$(state),
    isErrorDownloadFile: isErrorDownloadFile$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    createPlaylist: dispatchProps.createPlaylist,
    sharePlaylist: dispatchProps.sharePlaylist,
    deletePlaylist: dispatchProps.deletePlaylist,
    getPlaylistTracks: dispatchProps.getPlaylistTracks,
    setCurrentPlaylistId: dispatchProps.setCurrentPlaylistId,
    setCurrPlaylistNum: dispatchProps.setCurrPlaylistNum,
    setManagerEventListenerOn: dispatchProps.setManagerEventListenerOn,
    setManagerEventListenerInit: dispatchProps.setManagerEventListenerInit,
    sharePlaylistToMember: dispatchProps.sharePlaylistToMember,
    createShareablePlaylistLink: dispatchProps.createShareablePlaylistLink,
    downloadFile: dispatchProps.downloadFile,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    createPlaylist: PlaylistCreators.createPlaylist,
    deletePlaylist: PlaylistCreators.deletePlaylist,
    getPlaylistTracks: NewTracksCreators.getPlaylistTracks,
    sharePlaylist,
    setCurrentPlaylistId: PlaylistActions.setCurrentPlaylistId,
    setCurrPlaylistNum: PlaylistActions.setCurrPlaylistNum,
    setManagerEventListenerOn: ManagerEventListenerActions.setManagerEventListenerOn,
    setManagerEventListenerInit: ManagerEventListenerActions.setManagerEventListenerInit,
    createShareablePlaylistLink,
    sharePlaylistToMember,
    downloadFile: DownloadFileCreators.downloadFile,
  },
  mergeProps
)(Playlist);
