import JobList from 'containers/jobList';
import * as CompaniesCreators from 'creators/companies';
import * as CompanyCreators from 'creators/companyJob';
import * as MusicianJobsCreators from 'creators/musicianJobs';
import { IMusicianJob } from 'interfaces/IMusicianJob';
import { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { PureComponent, memo } from 'react';
import { connect } from 'react-redux';
import { userName$ } from 'selectors/auth';
import { companies$ } from 'selectors/companies';
import { isLoading$ as isLoadingJobs$ } from 'selectors/companyJob';
import { allJobs$, isError$, isLoading$ as isLoadingManagerJobs$ } from 'selectors/musicianJobs';

import styles from './style.module.scss';

interface IStateProps {
  userName: string | null;
  isLoadingJobs: boolean;
  allJobs: IMusicianJob[];
  isLoadingManagerJobs: boolean;
  isError: boolean;
  companies: ICompany[];
}

interface IDispatchProps {
  fetchCompanyJobs: (status: string, company_id: number, o?: number, l?: number) => void;
  fetchAllMusicianJobs: (status: string, offset?: number, limit?: number) => void;
  fetchCompanyList: () => void;
}

interface OwnProps {
  isAdmin?: boolean;
}
interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const buyers = ['Direct Licensor / Buyer', 'Reseller', 'Service provider', 'Contractor'];

class AdminJobList extends PureComponent<IProps> {
  state = {
    isOpen: false,
    step: 1,
    budgetJ: '',
    titleJ: '',
    descrJ: '',
    buyer_type: buyers[0],
    access_to: 10,
    access_from: 0,
    selectedCompany: '',
    selectedCompanyId: 0,
  };

  public async componentDidMount() {
    this.handleStep(1);
    try {
      await this.fetchInitJobs();
    } catch (error) {
      console.error(error);
    }
    if (!this.props.isAdmin) {
      this.props.fetchCompanyList();
    }
  }

  // eslint-disable-next-line
  private fetchInitJobs = () => {
    const { fetchAllMusicianJobs, fetchCompanyJobs, isLoadingManagerJobs, isAdmin, companies, isLoadingJobs } = this.props;

    if (isAdmin) {
      if (isLoadingManagerJobs) return;

      fetchAllMusicianJobs('open', 0, 20);
    } else {
      if (isLoadingJobs) return;
      if (companies.length) {
        fetchCompanyJobs('all', companies[0].id, 0, 20);
      }
    }
  };

  public componentDidUpdate(prevProps: IStateProps & IDispatchProps) {
    if (prevProps.userName && prevProps.userName !== this.props.userName) {
      this.fetchInitJobs();
    }

    if (prevProps.isError) this.handleOpen(false);
  }

  private handleOpen = (isOpen: boolean) => {
    this.setState(() => ({
      isOpen: isOpen,
      step: 1,
      budgetJ: '',
      descrJ: '',
      titleJ: '',
      buyer_type: buyers[0],
      access_to: 10,
      access_from: 0,
      selectedCompany: '',
      selectedCompanyId: 0,
    }));
  };

  private handleStep = (step: number) => this.setState({ step });

  public render() {
    const { isAdmin, allJobs, isLoadingManagerJobs } = this.props;
    return (
      <section className={`${styles.root} show-07-animation`}>
        <JobList isAdmin={isAdmin} items={allJobs} isLoadingItems={isLoadingManagerJobs} />
      </section>
    );
  }
}

const mapStateToProps = (state: State): IStateProps => {
  return {
    userName: userName$(state),
    isLoadingJobs: isLoadingJobs$(state),
    allJobs: allJobs$(state),
    isLoadingManagerJobs: isLoadingManagerJobs$(state),
    isError: isError$(state),
    companies: companies$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchCompanyJobs: dispatchProps.fetchCompanyJobs,
    fetchAllMusicianJobs: dispatchProps.fetchAllMusicianJobs,
    fetchCompanyList: dispatchProps.fetchCompanyList,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCompanyJobs: CompanyCreators.fetchCompanyJobs,
    fetchAllMusicianJobs: MusicianJobsCreators.fetchAllMusicianJobs,
    fetchCompanyList: CompaniesCreators.fetchCompanyList,
  },
  mergeProps
)(memo(AdminJobList));
