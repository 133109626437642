import axios from 'axios';
import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import { Api } from 'enums/api';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { usePrevious } from 'utils';
import { catchedErrorNotification } from 'utils/error';
import { findGetParameter } from 'utils/findGetParameter';

import style from './companyInfo.module.scss';
import { Content } from './components/content';
import { Header } from './components/header';

interface ICompanyInfoJob {
  id: number;
  title: string;
  status: string;
  type: string;
  min_rating: number;
  is_team_job: boolean;
  applied_tracks: number;
  playlist_tracks: number;
  shared_tracks: number;
}

export interface ICompanyInfo {
  id: number;
  name: string;
  description: string;
  owner_id: number;
  owner_name: string;
  owner_email: string;
  verified: boolean;
  affiliate_url: string | null;
  affiliate_dashboard_url: string | null;
  affiliate_email: string | null;
  logo: string | null;
  logo_for_job_type: string | null;
  background_for_sign_up: string | null;
  login_background?: string | null;
  jobs: ICompanyInfoJob[];
}

export const CompanyInfoPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [company, setCompany] = useState<ICompanyInfo | null>(null);
  const [id, setId] = useState<string | null>(null);
  const prevId = usePrevious(id);

  const fetchCompanyInfo = async () => {
    const res = await axios
      .post(Api.GetCompanyInfo, { company_id: id })
      .then(res => res.data.result)
      .catch(e => {
        setError(true);
        catchedErrorNotification(e);
      });
    setCompany(res);
    setLoading(false);
  };

  const updateVerify = async () => {
    setLoading(true);

    await axios
      .post(Api.VerifyCompany, { company_id: Number(id), verified: !company?.verified })
      .then(() => fetchCompanyInfo())
      .catch(err => catchedErrorNotification(err));

    setLoading(false);
  };

  useEffect(() => {
    const idParameter = findGetParameter('id');

    if (idParameter) {
      setId(idParameter);
    }
  }, []);

  useEffect(() => {
    if (id !== prevId && id) {
      fetchCompanyInfo();
    }
    // eslint-disable-next-line
  }, [id, prevId]);

  const ErrorCX = cx(style.error, {
    [style.hidden]: !error,
  });

  return (
    <div className={style.root}>
      <Header name={company?.name} />
      <div className={ErrorCX}>An error occurred while loading company information</div>
      <Content
        company={company}
        updateVerify={updateVerify}
        isLoading={isLoading}
        setLoading={setLoading}
        fetchCompanyInfo={fetchCompanyInfo}
      />
      {isLoading && (
        <div className={style.loading}>
          <Loader />
        </div>
      )}
    </div>
  );
};
