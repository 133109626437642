import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// eslint-disable-next-line
import React, { ChangeEvent, useMemo, useCallback } from 'react';

interface IProps {
  value: string | number | null;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string | JSX.Element;
  name: string;
  color?: 'default' | 'secondary' | 'primary';
  disabled?: boolean;
  className?: string;
}

export const CheckboxMaterial = ({ value, checked, onChange, label, name, color, disabled, className }: IProps) => {
  const memoChecked = useMemo(() => checked, [checked]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <FormControlLabel
      className={className}
      disabled={disabled}
      control={<Checkbox value={value} color={color} checked={memoChecked} onChange={handleChange} name={name} />}
      label={label}
    />
  );
};
