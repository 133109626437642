import { IMusicianJob } from 'interfaces/IMusicianJob';
import MusicianJobsState from 'interfaces/state/MusicianJobsState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setCompanyJobsInit = (): Action => {
  return {
    type: Const.MUSICIAN_JOBS_INIT,
  };
};

export const getSuccessMusicianJobs = (payload: IMusicianJob[]) => {
  return {
    type: Const.MUSICIAN_JOBS_SUCCESS,
    payload,
  };
};

export const getAllMusicianJobs = (payload: { all_jobs: IMusicianJob[]; team_jobs: IMusicianJob[] }) => {
  return {
    type: Const.ALL_MUSICIAN_JOBS_SUCCESS,
    payload,
  };
};

export const getSuccessMusicianJobsPagination = (payload: { all_jobs: IMusicianJob[]; team_jobs: IMusicianJob[] }) => {
  return {
    type: Const.MUSICIAN_JOBS_PAGINATION,
    payload,
  };
};

export const getSuccessAllMusicianJobs = (payload: { all_jobs: IMusicianJob[]; team_jobs: IMusicianJob[] }) => {
  return {
    type: Const.MUSICIAN_ALL_JOBS_SUCCESS,
    payload,
  };
};

export const setMusicianJobsFail = (): Action => {
  return {
    type: Const.MUSICIAN_JOBS_ERROR,
  };
};

export const setMusicianJobsPending = (): Action => {
  return {
    type: Const.MUSICIAN_JOBS_PENDING,
  };
};

export const setMusicianJobsSorting = (payload: string) => {
  return {
    type: Const.MUSICIAN_JOBS_SORTING,
    payload,
  };
};

export const setMusicianJobsOrder = (payload: MusicianJobsState['sortType']) => {
  return {
    type: Const.MUSICIAN_JOBS_SORT_TYPE,
    payload,
  };
};

export const setCurrentJobId = (payload: number | null) => {
  return {
    type: Const.CURR_JOB_ID,
    payload,
  };
};

export const setLoadedMusicianJobs = (): Action => {
  return {
    type: Const.MUSICIAN_JOBS_LOADED,
  };
};

export const setCurrJobIndex = (payload: number | null) => {
  return {
    type: Const.MUSICIAN_CURR_JOB_INDEX,
    payload,
  };
};

export const setMusicianAllJobAvatarUrl = (payload: string, id: number) => {
  return {
    type: Const.MUSICIAN_ALL_JOBS_SET_AVATAR_BY_ID,
    payload,
    id,
  };
};

export const setMusicianJobAvatarUrl = (payload: string, id: number) => {
  return {
    type: Const.MUSICIAN_JOBS_SET_AVATAR_BY_ID,
    payload,
    id,
  };
};

export const setNewMusicianJobStatus = (payload: string, id: number) => {
  return {
    type: Const.MUSICIAN_CHANGE_STATUS,
    payload,
    id,
  };
};

export const updateCompanyName = (id: number, company: string) => {
  return {
    type: Const.COMPANY_UPDATE_JOB_COMPANY_NAME,
    id,
    company,
  };
};

export const updateJobShareType = (id: number, share_type: 'auto_forwarding' | 'playlist_sharing') => {
  return {
    type: Const.MUSICIAN_JOB_CHANGE_SHARE_TYPE,
    id,
    share_type,
  };
};

export const addnewJobToAllJobs = (payload: Partial<IMusicianJob>) => {
  return {
    type: Const.MUSICIAN_ADD_NEW_JOB_TO_ALL_JOBS,
    payload,
  };
};

export const changeAllowedTrackAmount = (payload: number, id: number) => {
  return {
    type: Const.MUSICIAN_CHANGE_ALLOW_TRACKS_NUM,
    payload,
    id,
  };
};

export const changeOneJob = (payload: IMusicianJob) => {
  return {
    type: Const.CHANGE_ONE_JOB_INFO,
    payload,
  };
};

export const setCurrentMusicianJob = (payload: IMusicianJob | null) => {
  return {
    type: Const.SET_CURRENT_MUSICIAN_JOB,
    payload,
  };
};

export const setJobSearch = (payload: string) => {
  return {
    type: Const.JOB_SEARCH,
    payload,
  };
};

export const setJobTypes = (payload: string[]) => {
  return {
    type: Const.JOB_TYPES,
    payload,
  };
};

export const setJobStatus = (payload: string) => {
  return {
    type: Const.JOB_STATUS,
    payload,
  };
};

export const setJobTeamType = (payload: boolean) => {
  return {
    type: Const.JOB_TEAM_TYPE,
    payload,
  };
};

export const setJobCounter = (payload: number) => {
  return {
    type: Const.JOB_COUNTER,
    payload,
  };
};

export const setJobTeamsCounter = (payload: number) => {
  return {
    type: Const.JOB_TEAM_COUNTER,
    payload,
  };
};
