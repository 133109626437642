import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import { InputPopup } from 'components/InputPopup';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import { Textarea } from 'components/textArea';
import * as CompaniesCreators from 'creators/companies';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { isLoading$ } from 'selectors/companies';

import style from './style.module.scss';

interface INewCompany {
  name: string;
  description: string;
}

interface IStateProps {
  isLoading: boolean;
}

interface OwnProps {
  onClosePopover: () => void;
}
interface IDispatchProps {
  createCompany: (payload: INewCompany) => void;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const CreateCompanyPopup = ({ createCompany, onClosePopover }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const newCompany: INewCompany = {
    name: '',
    description: '',
  };
  const [company, setCompany] = useState(newCompany);

  const handleOpen = (value: boolean) => {
    setOpen(value);
    setCompany(newCompany);
  };

  const handleSetCompanyValue = (key: keyof INewCompany, value: string) => setCompany({ ...company, [key]: value });

  const handleCreate = () => {
    if (!company.name) {
      successNotification('Fill in the input field Name');
      return;
    }
    if (!company.description) {
      successNotification('Fill in the input field Description');
      return;
    }

    createCompany(company);
    setCompany(newCompany);
    setOpen(false);
    onClosePopover();
  };

  const onOpen = () => {
    handleOpen(true);
  };

  const onClose = () => {
    handleOpen(false);
    onClosePopover();
  };

  return (
    <Popup
      onOpen={onOpen}
      onDecline={onClose}
      isOpen={isOpen}
      btnClassName={style.btnContainer}
      onApply={handleCreate}
      isVisibleOverflow
      buttonText={
        <div className={style.button}>
          <PeopleAltRoundedIcon className={style.icon} />
          <span className={style.btnContent}>Company</span>
        </div>
      }
      applyBtnText={'CREATE COMPANY'}
      modalContent={
        <div className="d-flex flex-column">
          <div className={style.label}>Create new Company</div>
          <InputPopup
            type="text"
            title="Name"
            onChange={value => handleSetCompanyValue('name', value as string)}
            value={company.name}
            maxLength={40}
          />
          <div className={style.textarea}>
            <Textarea
              title="Description"
              onChange={value => handleSetCompanyValue('description', value as string)}
              value={company.description}
              maxLength={400}
            />
          </div>
        </div>
      }
    />
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    createCompany: dispatchProps.createCompany,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    createCompany: CompaniesCreators.createCompany,
  },
  mergeProps
)(memo(CreateCompanyPopup));
