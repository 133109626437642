import * as Const from '../constants';
import State from '../interfaces/state/MusicianPlayerState';

const initState: State = {
  titlePlayer: '',
  descriptionPlayer: '',
  name_in_basePlayer: '',
  id: null,
  url: null,
  isPlay: false,
  isPause: false,
  isPlayFromMusicSubmissions: false,
  isPlayFromPlaylist: false,
};

export default function musicianPlayer(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.MUS_PLAYER_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.MUS_PLAYER_TITLE:
      return {
        ...state,
        titlePlayer: action.payload,
      };

    case Const.MUS_PLAYER_DESCR:
      return {
        ...state,
        descriptionPlayer: action.payload,
      };

    case Const.MUS_PLAYER_NAME_IN_BASE:
      return {
        ...state,
        name_in_basePlayer: action.payload,
      };

    case Const.MUS_PLAYER_ID:
      return {
        ...state,
        id: action.payload,
      };

    case Const.MUS_PLAYER_URL:
      return {
        ...state,
        url: action.payload,
      };

    case Const.MUS_PLAYER_SET_PLAY:
      return {
        ...state,
        isPlay: action.payload,
      };

    case Const.MUS_PLAYER_SET_PAUSE:
      return {
        ...state,
        isPause: action.payload,
      };

    case Const.SET_PLAY_FROM_MUSIC_SUBMISSIONS:
      return {
        ...state,
        isPlayFromMusicSubmissions: true,
        isPlayFromPlaylist: false,
      };

    case Const.SET_PLAY_FROM_PLAYLIST:
      return {
        ...state,
        isPlayFromMusicSubmissions: false,
        isPlayFromPlaylist: true,
      };

    default:
      return state;
  }
}
