import './style.scss';

import State from 'interfaces/state/State';
import SubscriptionListState from 'interfaces/state/SubscriptionList';
import Carousel from 'nuka-carousel';
// eslint-disable-next-line
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { subscriptionList$ } from 'selectors/subscriptionList';

import { Plan } from '../plan';
import style from './planList.module.scss';

interface IDispatchProps {}
interface IStateProps {
  subscriptionList: SubscriptionListState['list'];
}
interface OwnProps {
  onOpenPopup: (id: string, title: string) => void;
  isAnnually: boolean;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const PlanListComponent = ({ subscriptionList, onOpenPopup, isAnnually }: IProps) => {
  const [index, setIndex] = useState(0);

  const onNext = () => {
    if (index === subscriptionList.length - 1) {
      setIndex(0);
    } else {
      setIndex(prev => prev + 1);
    }
  };

  const onPrev = () => {
    if (index !== 0) {
      setIndex(prev => prev - 1);
    }
  };

  return (
    <div className={style.root}>
      <button onClick={onPrev} className={style.prev}>
        {'<'}
      </button>
      <button onClick={onNext} className={style.next}>
        {'>'}
      </button>
      <Carousel
        heightMode="max"
        scrollMode="remainder"
        cellSpacing={10}
        height={'580px'}
        width={`1100px`}
        slideWidth={'330px'}
        framePadding={'0 0 0 30px'}
        initialSlideHeight={600}
        slideIndex={index}
        withoutControls
      >
        {subscriptionList.map(plan => {
          return <Plan key={plan.product_id} plan={plan} handleSelectNewPlan={onOpenPopup} isAnnually={isAnnually as any} />;
        })}
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    subscriptionList: subscriptionList$(state),
  };
};
export const PlanList = connect(mapStateToProps)(PlanListComponent);
