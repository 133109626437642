import * as Const from '../constants';
import State from '../interfaces/state/StatisticsState';

const initState: State = {
  isLoading: false,
  isError: false,
  users: 0,
  open_jobs: 0,
  closed_jobs: 0,
  visitors_diagram_for_month: [],
  songs_submitted_for_all_time: 0,
  songs_submitted_diagram_for_month: [],
  active_users: [],
  log_in_statistic: [],
  uploaded_songs: 0,
};

export default function statistics(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.STATISTICS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.STATISTICS_SUCCESS:
      const {
        users,
        open_jobs,
        closed_jobs,
        visitors_diagram_for_month,
        songs_submitted_for_all_time,
        songs_submitted_diagram_for_month,
        active_users,
        log_in_statistic,
        uploaded_songs,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        users,
        open_jobs,
        closed_jobs,
        visitors_diagram_for_month,
        songs_submitted_for_all_time,
        songs_submitted_diagram_for_month,
        active_users,
        log_in_statistic,
        uploaded_songs,
      };

    case Const.STATISTICS_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.STATISTICS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
}
