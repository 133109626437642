import 'sweetalert2/src/sweetalert2.scss';

import * as ConfirmActions from 'actions/confirm';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const confirmEmail = (email: string, token: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ConfirmActions.setConfirmPending());
    axios
      .post(Api.ConfirmEmails, {
        token: token.replace(/\s+/g, ''),
        email: email.replace(/\s+/g, ''),
      })
      .then(response => {
        dispatch(ConfirmActions.setConfirmSuccess());
        console.info(response);
      })
      .catch(error => {
        dispatch(ConfirmActions.setConfirmFail());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getRegisterEmail = (token: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ConfirmActions.setConfirmPending());
    axios
      .post(Api.GetRegisteredEmail, {
        token: token.replace(/\s+/g, ''),
      })
      .then(response => {
        dispatch(ConfirmActions.setConfirmEmail(response.data.result.email));
        console.info(response);
      })
      .catch(error => {
        dispatch(ConfirmActions.setConfirmFail());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
