import { Tooltip } from '@material-ui/core';
import FiberNewRoundedIcon from '@material-ui/icons/FiberNewRounded';
import * as TabActions from 'actions/tab';
import classNames from 'classnames/bind';
import { ITab } from 'containers/switchTabs/switchTabs';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { currentTab$ } from 'selectors/tab';

import styles from './tab.module.scss';

interface IDispatchProps {
  getCurrentTab: (t: number) => void;
}

interface IStateProps {
  currentTab: number;
}

interface OwnProps {
  tab: ITab;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const Tab = ({ tab, currentTab, getCurrentTab }: IProps) => {
  const { icon, index, classStyle, title, hide, route, isNew } = tab;

  const tabClassNames = classNames(classStyle, {
    [`${classStyle}-sel`]: index === currentTab,
  });

  const spanClassNames = classNames('d-flex align-center justify-center', {
    'selected-wrap-tab': index === currentTab && !title,
    'wrap-tab': index !== currentTab && !title,
    [styles.notSelectWrapTitle]: index !== currentTab && title,
    [styles.selectedWrapTitle]: index === currentTab,
  });

  const titleClassName = classNames('d-flex align-center', {
    [styles.selectedTitle]: index === currentTab,
    [styles.notSelectTitle]: index !== currentTab && title,
  });

  const iconClassName = classNames('d-flex align-center', {
    [styles.selectedTitle]: index === currentTab,
    [styles.notSelectTitle]: index !== currentTab && title,
  });

  const onClick = () => {
    if (currentTab === index) return;
    getCurrentTab(index);
  };

  const tabContent = () => {
    if (title && !icon) {
      return (
        <NavLink className={titleClassName} to={route} onClick={onClick}>
          {title}
        </NavLink>
      );
    } else if ((title && icon) || (!title && icon)) {
      return (
        <Tooltip title={title}>
          <NavLink className={iconClassName} to={route} onClick={onClick}>
            {icon}
            {isNew && (
              <span className={styles.iconNew}>
                <FiberNewRoundedIcon />
              </span>
            )}
          </NavLink>
        </Tooltip>
      );
    } else {
      return <NavLink className={tabClassNames} to={route} onClick={onClick} />;
    }
  };

  return <>{!hide ? <span className={spanClassNames}>{tabContent()}</span> : null}</>;
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    currentTab: currentTab$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    getCurrentTab: dispatchProps.getCurrentTab,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    getCurrentTab: TabActions.getCurrentTab,
  },
  mergeProps
)(memo(Tab));
