import 'sweetalert2/src/sweetalert2.scss';

import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { ICompanyJob } from 'interfaces/ICompanyJob';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { currentCompany$ } from 'selectors/companies';
import { jobs$ } from 'selectors/companyJob';
import { selectedTypes$ } from 'selectors/musicianJobs';
import { catchedErrorNotification } from 'utils/error';

import * as CompanyActions from '../actions/companyJobs';
import * as MusicianActions from '../actions/musicianJobs';

export const fetchCompanyJobs = (status: string, company_id: number, offset?: number, limit?: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompanyActions.setCompanyJobsInitFromFetch());
    dispatch(CompanyActions.setCompanyJobsPending());
    const type = selectedTypes$(getState());
    axios
      .post(`${Api.GetJobs}`, {
        status: status,
        company_id,
        offset,
        limit,
        type,
      })
      .then(response => {
        dispatch(CompanyActions.getSuccessCompanyJobs(response.data.result?.all_jobs, !!offset));
        dispatch(MusicianActions.setJobCounter(response.data.result?.number_of_jobs));
        dispatch(MusicianActions.setJobTeamsCounter(response.data.result?.number_of_team_jobs));
      })
      .catch(error => {
        dispatch(CompanyActions.setCompanyJobsFail());

        console.error(error);
      });
  };
};

export const updateCompanyStatus = (isManager: boolean, status: string, id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(CompanyActions.setCompanyJobsPending());

    axios
      .post(`${Api.UpdateStatus}`, {
        status: status,
        id: id,
      })
      .then(() => {
        if (isManager) {
          dispatch(MusicianActions.setNewMusicianJobStatus(status, id));
        } else {
          dispatch(CompanyActions.setNewCompanyJobStatus(status, id));
        }
        successNotification(`Status changed to ${status}`);
      })
      .catch(error => {
        dispatch(CompanyActions.setCompanyJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updateJob = (job: Partial<ICompanyJob>) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompanyActions.setCompanyJobsPending());

    axios
      .post(Api.UpdateJob, {
        ...job,
      })
      .then(() => {
        dispatch(CompanyActions.setCompanyUpdatedJob(job));
        successNotification(`Job was updated successfully`);
        const currentCompany = currentCompany$(getState());
        if (job.company_id !== currentCompany?.id) {
          const jobs = jobs$(getState());
          const newJobs = jobs.filter(j => j.id !== job.id);
          dispatch(CompanyActions.getSuccessCompanyJobs(newJobs));
        }
      })
      .catch(error => {
        dispatch(CompanyActions.setCompanyJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const handleChangeJobOwner = (job_id: number, company_id: number, company: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(CompanyActions.setCompanyJobsPending());
    axios
      .post(`${Api.ChangeOwnerOfJob}`, {
        job_id,
        company_id,
      })
      .then(() => {
        dispatch(CompanyActions.setNewCompanyOwner(job_id, company));
        dispatch(MusicianActions.updateCompanyName(job_id, company));
        successNotification(`Job was updated successfully`);
      })
      .catch(error => {
        dispatch(CompanyActions.setCompanyJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updateShareType = (job_id: number, share_type: 'auto_forwarding' | 'playlist_sharing') => {
  return (dispatch: (action: Action) => void) => {
    dispatch(CompanyActions.setCompanyJobsPending());
    axios
      .post(Api.UpdateShareType, {
        job_id,
        share_type,
      })
      .then(_res => {
        dispatch(CompanyActions.setPendindOffCompanyJobs());
        dispatch(MusicianActions.updateJobShareType(job_id, share_type));
        successNotification(`Share type for this job has been successfully changed to ${share_type}!`, 6000);
      })
      .catch(error => {
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
