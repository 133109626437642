import * as PlaylistActions from 'actions/application';
import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import { ISelectItem } from 'components/materialSelect/materialSelect';
import * as PlaylistCreators from 'creators/application';
import IApp from 'interfaces/IApp';
import { IMusicianJob } from 'interfaces/IMusicianJob';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { current_playlist$, isLoading$, job_id$, playlists$ } from 'selectors/applications';

import { ShareableLinks } from '../shareableLinks';
import { TAB } from './constants';
import style from './playlist.module.scss';
import { PlaylistsTab } from './playlistsTab';
import { TrackListTab } from './trackListTab';

interface IStateProps {
  job_id: number | null;
  playlists: IApp[];
  current_playlist: IApp | null;
  isLoading: boolean;
}

interface IDispatchProps {
  fetchApplicationsByJobId: (job_id: number) => void;
  createPlaylist: (tracks_ids: IApp['tracks'], job_id: number, description: string) => void;
  deletePlaylistById: (id: number) => void;
  setPlaylistId: (id: number) => void;
  setCurrPlaylist: (pl: IApp | null) => void;
  fetchShareableLinks: (id: number) => void;
  createShareableLink: (application_id: number, allowed_all_tracks: boolean, allowed_single_track: boolean) => void;
  submitUpdatePlaylist: () => void;
}

interface OwnProps {
  job: IMusicianJob;
  isOpen: boolean;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const PlaylistComponent = ({
  fetchApplicationsByJobId,
  createPlaylist,
  deletePlaylistById,
  setPlaylistId,
  setCurrPlaylist,
  fetchShareableLinks,
  createShareableLink,
  submitUpdatePlaylist,
  job,
  playlists,
  isOpen,
  isLoading,
  current_playlist,
}: IProps) => {
  const memoOpen = useMemo(() => isOpen, [isOpen]);
  const [selectedTab, setTab] = useState(TAB.TRACKS);
  const [selectedPlaylist, setPlaylist] = useState({ label: '', value: '' } as ISelectItem);
  const [selectList, setSelectList] = useState([] as ISelectItem[]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTab === TAB.PLAYLISTS && memoOpen) {
      fetchApplicationsByJobId(job.id);
    } else if (selectedTab === TAB.LINKS && memoOpen) {
      if (current_playlist) fetchShareableLinks(current_playlist.id);
    }
    // eslint-disable-next-line
  }, [selectedTab, memoOpen]);

  useEffect(() => {
    if (current_playlist && memoOpen) {
      setPlaylist({ label: current_playlist.description, value: current_playlist.id });
    } else {
      setPlaylist({ label: '', value: '' });
    }
  }, [playlists, setPlaylist, memoOpen, current_playlist]);

  useEffect(() => {
    if (playlists.length && memoOpen) {
      const list = playlists.map(pl => ({ label: pl.description, value: `${pl.id}` }));
      setSelectList(list as ISelectItem[]);
    }
  }, [playlists, memoOpen]);

  const BtnTracksCX = cx(style.btn, {
    [style.selected]: selectedTab === TAB.TRACKS,
  });

  // const BtnPlaylistCX = cx(style.btn, {
  //   [style.selected]: selectedTab === TAB.PLAYLISTS,
  // });

  // const BtnOptionsCX = cx(style.btn, {
  //   [style.selected]: selectedTab === TAB.LINKS,
  // });

  const onTracksClick = () => {
    setTab(TAB.TRACKS);
  };

  // const onPlaylistClick = () => {
  //   setTab(TAB.PLAYLISTS);
  // };

  // const onOptionsClick = () => {
  //   setTab(TAB.LINKS);
  // };

  const onChange = (pl: ISelectItem) => {
    setPlaylist(pl);
    setPlaylistId(Number(pl.value));
    const index = playlists.findIndex(playl => playl.id === Number(pl.value));
    if (index < 0) return;
    const searchedPlaylist = playlists[index];
    setCurrPlaylist(searchedPlaylist);
  };

  const onCreate = (name: string) => {
    createPlaylist([], job.id, name);
  };

  const handleDelete = () => {
    if (current_playlist) {
      deletePlaylistById(current_playlist.id);
    }
  };

  const onCreateLink = (isAllowAll: boolean, singleAllow: boolean) => {
    if (current_playlist) {
      createShareableLink(current_playlist.id, isAllowAll, singleAllow);
    }
  };

  const EmptyBtnCX = cx(style.emptyCreatePlaylistBtn, {
    [style.disabled]: job.is_blocked,
  });

  const onSubmit = () => {
    dispatch(PlaylistActions.setCurrInitPlaylist(current_playlist));
    submitUpdatePlaylist();
  };

  return (
    <div className={style.root}>
      <header className={style.header}>
        <button className={BtnTracksCX} onClick={onTracksClick}>
          Tracks
        </button>
        {/* <button className={BtnPlaylistCX} onClick={onPlaylistClick}>
          Playlists
        </button>
        <button className={BtnOptionsCX} onClick={onOptionsClick}>
          Links
        </button> */}
      </header>

      <div className={style.body}>
        {isLoading && (
          <div className={style.loader}>
            <Loader />
          </div>
        )}

        {selectedTab === TAB.TRACKS && <TrackListTab />}

        {selectedTab === TAB.LINKS && <ShareableLinks onCreateLink={onCreateLink} />}
        {selectedTab === TAB.PLAYLISTS && !isLoading && (
          <PlaylistsTab
            selectedPlaylist={selectedPlaylist}
            selectList={selectList}
            playlists={playlists}
            current_playlist={current_playlist}
            EmptyBtnCX={EmptyBtnCX}
            job={job}
            onDelete={handleDelete}
            onChange={onChange}
            onCreate={onCreate}
            submitUpdatePlaylist={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    job_id: job_id$(state),
    playlists: playlists$(state),
    current_playlist: current_playlist$(state),
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchApplicationsByJobId: dispatchProps.fetchApplicationsByJobId,
    createPlaylist: dispatchProps.createPlaylist,
    deletePlaylistById: dispatchProps.deletePlaylistById,
    setPlaylistId: dispatchProps.setPlaylistId,
    setCurrPlaylist: dispatchProps.setCurrPlaylist,
    fetchShareableLinks: dispatchProps.fetchShareableLinks,
    createShareableLink: dispatchProps.createShareableLink,
    submitUpdatePlaylist: dispatchProps.submitUpdatePlaylist,
    ...props,
  };
};

export const Playlist = connect(
  mapStateToProps,
  {
    fetchApplicationsByJobId: PlaylistCreators.fetchApplicationsByJobId,
    createPlaylist: PlaylistCreators.createPlaylist,
    deletePlaylistById: PlaylistCreators.deletePlaylistById,
    setPlaylistId: PlaylistActions.setPlaylistId,
    setCurrPlaylist: PlaylistActions.setCurrPlaylist,
    fetchShareableLinks: PlaylistCreators.fetchShareableLinks,
    createShareableLink: PlaylistCreators.createShareableLink,
    submitUpdatePlaylist: PlaylistCreators.submitUpdatePlaylist,
  },
  mergeProps
)(PlaylistComponent);
