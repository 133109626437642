import State from 'interfaces/state/State';
import { SubscriptionName } from 'interfaces/state/SubscriptionList';
import { createSelector } from 'reselect';

export const isLoading$ = (state: State): boolean => state.auth.isLoading;
export const isError$ = (state: State): boolean => state.auth.isError;
export const isAuthS$ = (state: State): boolean => state.auth.isAuth;
export const isAuth$ = (state: State): boolean => state.auth.isAuth;
export const userType = (state: State): null | string => state.auth.userType;
export const userEmail$ = (state: State): null | string => state.auth.userEmail;
export const userType$ = createSelector([userType], userType => userType);

export const userName$ = (state: State) => state.auth.userName;
export const subscription$ = (state: State): SubscriptionName => state.auth.subscription;
export const trialCounter$ = (state: State): number | null => state.auth.trialcounter;
export const isAffiliate$ = (state: State): boolean => state.auth.isAffiliate;
export const isCheckedEmail$ = (state: State): boolean | null => state.auth.isCheckedEmail;
export const companyAvatar$ = (state: State): string | null => state.auth.companyAvatar;
export const loginBg$ = (state: State): string | null => state.auth.loginBg;
export const companyId$ = (state: State): number | null => state.auth.companyId;
export const is_first_500$ = (state: State): boolean => state.auth.is_first_500;
export const sid$ = (state: State): string | null => state.auth.sid;
export const isOpenCouponPopup$ = (state: State): boolean => state.auth.isOpenCouponPopup;
export const isOpenTechnicalPage$ = (state: State): boolean => state.auth.isOpenTechnicalPage;
export const errorText$ = (state: State): string | null => state.auth.errorText;
