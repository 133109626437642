import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import * as NewTrackActions from 'actions/newTracks';
import pause from 'assets/img/pause-button.svg';
import playBtn from 'assets/img/play-button.svg';
import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import Popup from 'components/popup/popup';
import * as NewTrackCreators from 'creators/newTracks';
import * as PlaylistCreators from 'creators/playlist';
import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useDispatch, useSelector } from 'react-redux';
import { currentPlayedTrackId$, isPlay$ } from 'selectors/musicianPlayer';
import { isLoading$ as isLoadingPlaylist$, isLoadingTracks$, playlist_id$ } from 'selectors/playlist';

import styles from './playlistContent.module.scss';

interface IStateProps {
  playlist_id: number | null;
  currentPlayedTrackId: null | number;
  isPlayerPlay: boolean;
}

interface IDispatchProps {
  updatePlaylist: (playlist_id: number, track_id: number[] | [], id: number, isDelete: boolean) => void;
  delTrackFromPLaylist: (id: number) => void;
  deleteTrackId: (payload: number) => void;
  onPlayerPlayClick: (title: string, name: string, id: number | null, rating: number) => void;
}

interface OwnProps {
  options: INewTrack[];
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const PlaylistContent = ({
  options,
  playlist_id,
  currentPlayedTrackId,
  isPlayerPlay,
  updatePlaylist,
  delTrackFromPLaylist,
  deleteTrackId,
  onPlayerPlayClick,
}: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingPlaylist$);
  const isLoadingTracks = useSelector(isLoadingTracks$);
  const onApply = (id: number) => {
    if (!options || options.length === 0) return;
    deleteTrackId(id);
    delTrackFromPLaylist(id);
    const playlistIds = options.filter(tr => tr.track_id !== id).map(track => track.track_id!);

    updatePlaylist(playlist_id!, playlistIds, id, true);
  };

  const onClick = (title: string, name: string, id: number | null, rating: number) => () => {
    onPlayerPlayClick(title, name, id, rating);
    dispatch(NewTrackActions.setCurrentTrackInfo(title, id!, [], rating));
  };

  const fetchNextTracksForPlaylist = useCallback(() => {
    if (playlist_id && options?.length >= 99) {
      dispatch(NewTrackCreators.getPlaylistTracks(playlist_id!, 100, options.length));
    }
  }, [playlist_id, dispatch, options]);

  return (
    <div className={styles.root} id="scrollTarget">
      {(isLoading || isLoadingTracks) && (
        <div className={styles.loading}>
          <Loader />
          <div className={styles.title}>{isLoading ? 'Loading playlist' : 'Loading tracks'}</div>
        </div>
      )}
      {options ? (
        options?.length ? (
          <InfiniteScroll
            dataLength={options.length}
            className={styles.infinityWrapper}
            next={fetchNextTracksForPlaylist}
            hasMore={true}
            loader={<div />}
            endMessage={<></>}
            scrollableTarget="scrollTarget"
          >
            {options.map(option => {
              return (
                <div
                  key={option.track_id!}
                  className={cx(styles.item, {
                    [styles.played]: option.track_id === currentPlayedTrackId,
                  })}
                >
                  <button
                    className={styles.btnSelect}
                    onClick={onClick(option.title!, option.name_in_base!, option.track_id, option.rating!)}
                  >
                    {option.track_id === currentPlayedTrackId && isPlayerPlay ? (
                      <img className={styles.btnImg} src={pause} alt="play control" />
                    ) : (
                      <img className={styles.btnImg} src={playBtn} alt="play control" loading="lazy" />
                    )}
                  </button>
                  <div className={styles.title}>{option.title ? option.title : null}</div>
                  <Popup
                    btnClassName={styles.btnDelete}
                    buttonText={<DeleteForeverRoundedIcon />}
                    modalContent={
                      <div className="d-flex align-center" style={{ marginBottom: 10, height: 100 }}>
                        <div>Are you sure You want to delete track from playlist?</div>
                      </div>
                    }
                    onApply={() => onApply(option.track_id!)}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        ) : (
          <div className={styles.emptyNewPlaylist}>Add first track to playlist</div>
        )
      ) : (
        <div>Empty playlist</div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentPlayedTrackId: currentPlayedTrackId$(state),
    playlist_id: playlist_id$(state),
    isPlayerPlay: isPlay$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    updatePlaylist: dispatchProps.updatePlaylist,
    delTrackFromPLaylist: dispatchProps.delTrackFromPLaylist,
    deleteTrackId: dispatchProps.deleteTrackId,
    onPlayerPlayClick: dispatchProps.onPlayerPlayClick,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    updatePlaylist: PlaylistCreators.updatePlaylist,
    delTrackFromPLaylist: NewTrackActions.delTrackFromPLaylist,
    deleteTrackId: NewTrackActions.deleteTrackId,
    onPlayerPlayClick: NewTrackCreators.onPlayerPlayClick,
  },
  mergeProps
)(PlaylistContent);
