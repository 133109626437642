import { Action } from 'redux';

import * as Const from '../constants';

export const setPaymentInit = (): Action => {
  return {
    type: Const.PAYMENT_INIT,
  };
};

export const setPaymentPending = (): Action => {
  return {
    type: Const.PAYMENT_PENDING,
  };
};

export const setPaymentError = (): Action => {
  return {
    type: Const.PAYMENT_ERROR,
  };
};

export const setPayment = (payload: string | null) => {
  return {
    type: Const.PAYMENT_SUCCESS,
    payload,
  };
};

export const setPaymentErrText = (payload: string | null) => {
  return {
    type: Const.PAYMENT_SET_TEXT,
    payload,
  };
};
