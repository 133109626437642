import * as Const from '../constants';
import State from '../interfaces/state/UnsubscribeState';

const initState: State = {
  isLoading: false,
  isError: false,
};

export default function unsubscribe(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.UNSUBSCRIBE_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.UNSUBSCRIBE_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.UNSUBSCRIBE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
}
