import { INewTrack } from 'interfaces/INewTrack';
import { Action } from 'redux';

import * as Const from '../constants';

export const setSharedPlayerInit = (): Action => {
  return {
    type: Const.SHARED_PLAYER_INIT,
  };
};

export const setSharedPlayerSuccess = (): Action => {
  return {
    type: Const.SHARED_PLAYER_SUCCESS,
  };
};

export const setSharedPlayerReceive = (payload: INewTrack | null) => {
  return {
    type: Const.SHARED_PLAYER_RECEIVE,
    payload,
  };
};

export const setSharedPlayerError = (): Action => {
  return {
    type: Const.SHARED_PLAYER_ERROR,
  };
};

export const setSharedPlayerUrl = (id: number, isPlay: boolean, url: string | null) => {
  return {
    type: Const.SHARED_PLAYER_URL,
    id,
    isPlay,
    url,
  };
};

export const setSharedPlayerPending = () => {
  return {
    type: Const.SHARED_PLAYER_PENDING,
  };
};

export const setSharedPlayerProgressDownload = (id: number, value: number) => {
  return {
    type: Const.SHARED_PLAYER_PROGRESS,
    id,
    value,
  };
};

export const setPlayAll = (payload: boolean) => {
  return {
    type: Const.SHARED_PLAYER_PLAY_ALL,
    payload,
  };
};

export const setSharedPlayerPlay = (payload: boolean) => {
  return {
    type: Const.SHARED_PLAYER_PLAY_TRACK,
    payload,
  };
};
