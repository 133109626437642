import './trackItem.scss';

import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import StarRoundedIcon from '@material-ui/icons/StarRateRounded';
import * as CreatorPlaylistAction from 'actions/application';
import * as MusicianPlayerActions from 'actions/musicianPlayer';
import * as NewTracksActions from 'actions/newTracks';
import * as NewTrackActions from 'actions/newTracks';
import * as PlaylistActions from 'actions/playlist';
import * as RateActions from 'actions/rating';
import * as SiteEventsActions from 'actions/siteEvents';
import * as SongActions from 'actions/song';
import first500Badge from 'assets/img/badge.png';
import premiumFree from 'assets/img/diamond.svg';
import downloadImg from 'assets/img/download.svg';
import not_sel from 'assets/img/not_sel.svg';
import pause from 'assets/img/pause-button.svg';
import starterFree from 'assets/img/pie-chart.svg';
import play from 'assets/img/play-button.svg';
import premium from 'assets/img/premium.svg';
import starter from 'assets/img/pro.svg';
import sel from 'assets/img/sel.svg';
import unlimited from 'assets/img/verified.svg';
import classNames from 'classnames/bind';
import { MusicianTrackPopoverContent } from 'components/musicianTrackItem/musicianTrackPopoverContent';
import { Popover } from 'components/popover/popover';
import ProgressBar from 'components/progressBar/progressBar';
import { successNotification } from 'components/successNotification/successNotification';
import * as MusicianPlayerCreators from 'creators/musicianPlayer';
import { highlightTrack } from 'creators/newTracks';
import * as PlaylistCreators from 'creators/playlist';
import * as SiteEventCreators from 'creators/siteEvents';
import { INewTrack } from 'interfaces/INewTrack';
import { IPlaylist } from 'interfaces/IPlaylist';
import State from 'interfaces/state/State';
import { ITrackWriter } from 'interfaces/state/TrackInfoState';
// eslint-disable-next-line
import React, { MouseEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EventListener from 'react-event-listener';
import { connect, useDispatch } from 'react-redux';
import { playlist_id$ as creatorPlaylistId$ } from 'selectors/applications';
import { userType$ } from 'selectors/auth';
import { currentPlayedTrackId$, isPlay$ } from 'selectors/musicianPlayer';
import { downloadProgress$, orderedTracks$, tracks_for_playlist$ } from 'selectors/newTrack';
import { playlist$, playlist_id$ } from 'selectors/playlist';
import { currIndex$, isNeedToScroollToTrackInRightPanel$ } from 'selectors/song';
import { shortEnglishHumanizer } from 'utils/shortEngHuminizerTime';

import { TrackInvite } from './trackInvite';

interface OwnProps {
  setCurrentTrackInfo: (title: string, id: number, writer: ITrackWriter[], duration: number) => void;
  setCurrSongUrl: (name: string) => void;
  setSongTime: (id: number) => void;
  track: INewTrack;
  selected_tracks: number[] | null;
  current_name: string;
  isPlay: boolean;
  index: number;
  isCreator?: boolean;
}

interface IStateProps {
  playlist_id: number | null;
  playlist: IPlaylist[];
  currIndex: number | null;
  orderedTracks: INewTrack[];
  downloadProgress: { [key: string]: { loaded: number; total: number } };
  currentPlayedTrackId: null | number;
  isPlayerPlay: boolean;
  creatorPlaylistId: number | null;
  tracks_for_playlist: INewTrack[] | null;
  isNeedToScroollToTrackInRightPanel: boolean;
  userType: string | null;
}

interface IDispatchProps {
  updatePlaylist: (id: number, tracks: number[], track_id: number, isDelete: boolean) => void;
  getRatingReceive: (rate: number) => void;
  setCurrSongIndex: (a: number) => void;
  setIsPlayFromTrackPanel: (is: boolean) => void;
  setUploadSiteEvent: (event: string | null) => void;
  setEventListened: (value: string) => void;
  setEventListenedForStatistic: (value: string) => void;
  sendEvent: (event: 'upload' | 'listen') => void;
  getTrackUrl: (id: number, isNeedToClickPause: boolean, title?: string) => void;
  setID: (id: number | null) => void;
  setMusicianURL: (val: string) => void;
  setPlayAfterSRCChanged: (autoPlayAfterSrcChanged: boolean) => void;
  setMuted: (isMuted: boolean) => void;
  changePlayFromMusicSubmissions: () => void;
  addNewTrackToPlaylist: (track: INewTrack) => void;
  addTrackFromCreatorApp: (id: number, tr: { title: string; track_id: string; isNew?: boolean }) => void;
  delTrackFromCreatorApp: (id: number, track_id: number) => void;
  getNewTracksForPlaylist: (tr: INewTrack[] | null) => void;
  scrollToTrack: (scroll: boolean) => void;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const TrackItem = ({
  selected_tracks,
  playlist_id,
  currIndex,
  orderedTracks,
  track,
  downloadProgress,
  isPlayerPlay,
  currentPlayedTrackId,
  index,
  isCreator,
  creatorPlaylistId,
  tracks_for_playlist,
  isNeedToScroollToTrackInRightPanel,
  userType,
  setCurrentTrackInfo,
  updatePlaylist,
  getRatingReceive,
  setCurrSongIndex,
  setIsPlayFromTrackPanel,
  setUploadSiteEvent,
  setEventListened,
  setEventListenedForStatistic,
  sendEvent,
  getTrackUrl,
  setID,
  setMusicianURL,
  setPlayAfterSRCChanged,
  setMuted,
  changePlayFromMusicSubmissions,
  addNewTrackToPlaylist,
  addTrackFromCreatorApp,
  delTrackFromCreatorApp,
  getNewTracksForPlaylist,
  scrollToTrack,
}: IProps) => {
  const [track_ids, setTrack_ids] = useState(null as number[] | null);
  const [isBlockClicked, setBlock] = useState(false);
  const [playCount, setCount] = useState(0);
  const dispatch = useDispatch();

  const setCurrent = useCallback(() => {
    let playButton = document.querySelector('.rhap_play-pause-button') as HTMLElement;
    setMuted(true);
    changePlayFromMusicSubmissions();
    setPlayAfterSRCChanged(true);
    scrollToTrack(false);

    if (track.track_id !== currentPlayedTrackId) {
      setMusicianURL('');
      getTrackUrl(track.track_id!, false);
      if (userType !== 'creator') {
        dispatch(highlightTrack(track!.track_id!));
      }
    }

    setEventListened(track.name_in_base!);
    getRatingReceive(0);
    if (isBlockClicked) return;
    setCurrSongIndex(index);
    setIsPlayFromTrackPanel(true);
    setCurrentTrackInfo(track.title!, track.track_id!, track.writers, track.track_duration);
    setID(track.track_id);
    getRatingReceive(track.rating || 0);

    if (!isCreator) {
      setEventListenedForStatistic(track.name_in_base!);
      sendEvent('listen');
    }
    if (playButton) {
      playButton.click();
    }
    // eslint-disable-next-line
  }, [track, isCreator, currentPlayedTrackId, isBlockClicked, userType]);

  useEffect(() => setTrack_ids(selected_tracks as number[] | null), [selected_tracks]);

  const addTrackToPlaylist = useCallback(() => {
    if (isCreator) {
      addNewTrackToPlaylist(track);
      if (creatorPlaylistId) {
        addTrackFromCreatorApp(creatorPlaylistId, { track_id: `${track.track_id!}` as string, title: track.title!, isNew: true });
      }
    } else {
      if (!playlist_id) {
        successNotification('Create or select playlist');
        return;
      }

      updatePlaylist(playlist_id!, [...(selected_tracks ? selected_tracks : [track.track_id!]!), track.track_id!], track.track_id!, false);
    }
    // eslint-disable-next-line
  }, [isCreator, playlist_id, selected_tracks, track, creatorPlaylistId]);

  const deleteTrackFromPlaylist = async () => {
    if (!isCreator) {
      if (!playlist_id) {
        successNotification('Create or select playlist');
        return;
      }

      const playlist_tracks = [...selected_tracks].filter(id => id !== track.track_id);
      await updatePlaylist(playlist_id!, playlist_tracks, track.track_id!, true);
    } else {
      if (creatorPlaylistId) {
        delTrackFromCreatorApp(creatorPlaylistId, track.track_id!);
      }
      dispatch(CreatorPlaylistAction.changeUnsaved(true));
      let newTracks: INewTrack[] = [];
      if (tracks_for_playlist?.length) {
        // eslint-disable-next-line
        newTracks = tracks_for_playlist?.filter(tr => tr.track_id != track.track_id);
      }
      getNewTracksForPlaylist(newTracks);
    }
  };

  const download = () => {
    setBlock(true);
    setUploadSiteEvent(track.name_in_base!);
    getTrackUrl(track.track_id!, false, track.title!);
    setBlock(false);
  };

  const containerClassNames = classNames('manager-track-container', {
    'manager-track-container-n': index !== currIndex,
    'manager-track-container-s': index === currIndex,
    'track-was-listened': track?.listened,
  });

  const onKeyDown = (e: KeyboardEvent) => {
    onArrowDown(e);
    onArrowUp(e);
  };

  const onArrowDown = (e: KeyboardEvent) => {
    if (currIndex === null) return;
    if (e.code === 'ArrowDown' && currIndex < orderedTracks.length - 1 && index === currIndex) {
      setCurrSongIndex(currIndex + 1);
      getRatingReceive(0);
    } else {
      return;
    }
  };

  const onArrowUp = (e: KeyboardEvent) => {
    if (currIndex === null) return;

    if (e.code === 'ArrowUp' && currIndex > 0 && index === currIndex) {
      setCurrSongIndex(currIndex - 1);
      getRatingReceive(0);
    } else {
      return;
    }
  };

  const renderSelected = () => {
    if (track_ids && track_ids.length) {
      return track_ids.includes(track.track_id!) ? (
        <div className="icon-track-n-wrapper" onClick={deleteTrackFromPlaylist}>
          <img src={sel} className="icon-track" alt="selected" loading="lazy" />
        </div>
      ) : (
        <div className="icon-track-n-wrapper" onClick={addTrackToPlaylist}>
          <img src={not_sel} className="icon-track-n" alt="not_selected" loading="lazy" />
        </div>
      );
    } else {
      return (
        <div className="icon-track-n-wrapper" onClick={addTrackToPlaylist}>
          <img src={not_sel} className="icon-track-n" alt="not_selected" loading="lazy" />
        </div>
      );
    }
  };

  const subscriptions = () => {
    const icon = {
      unlimited,
      starter,
      premium,
      free: '',
      'starter-free': starterFree,
      'premium-free': premiumFree,
    };

    const unnececarySubscriptionTypes = ['free'];

    if (!track.stripe_subscription) {
      return null;
    }
    if (unnececarySubscriptionTypes.includes(track.stripe_subscription!.toLowerCase())) return null;

    return (
      <div className={'track-subscription'}>
        <Tooltip title={`${track.stripe_subscription?.toUpperCase()} user`}>
          <img src={icon[track.stripe_subscription!.toLowerCase()]} alt="subscription" className="track-subscription-icon" loading="lazy" />
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    if (index !== currIndex && playCount !== 0) {
      setCount(0);
    }
    // eslint-disable-next-line
  }, [index, currIndex, setCount]);

  const renderLoader = () => {
    const defaultIcon = <img src={downloadImg} onClick={download} style={{ cursor: 'pointer' }} alt="download" loading="lazy" />;
    if (downloadProgress && downloadProgress?.[`${track.track_id!}`]) {
      const loadingAmount = downloadProgress?.[`${track.track_id!}`].loaded / downloadProgress?.[`${track.track_id!}`].total;
      if (loadingAmount !== 1) {
        return (
          <div className={'m-l-10'} style={{ position: 'relative', left: 7 }}>
            <ProgressBar percentage={Number((loadingAmount * 100).toFixed())} width={30} height={30} />
          </div>
        );
      } else {
        return defaultIcon;
      }
    } else {
      return defaultIcon;
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const trackRef = useRef(null) as RefObject<HTMLDivElement> | null;

  useEffect(() => {
    if (index === currIndex && isNeedToScroollToTrackInRightPanel) {
      if (trackRef?.current) {
        trackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    // eslint-disable-next-line
  }, [index, currIndex, trackRef]);

  const artistName = useMemo(() => {
    if (userType === 'admin') {
      return `${track.artist_name} (${track.user_name})`;
    } else {
      return `${track.artist_name}`;
    }
  }, [userType, track]);

  return (
    <>
      {!isCreator && <EventListener target={document} onKeyDown={onKeyDown} />}
      <div className={containerClassNames} ref={trackRef}>
        <div className="manager-track-selector d-flex align-center">{renderSelected()}</div>
        {isPlayerPlay && index === currIndex ? (
          <img src={pause} alt="play-button" className="manager-track-play" onClick={setCurrent} />
        ) : (
          <img src={play} alt="play-button" className="manager-track-play" onClick={setCurrent} />
        )}
        <div className="manager-track-title align-center d-flex">
          <div className="manager-tr-t" title={track!.title!}>
            {track.title}
          </div>
          <span className="">{subscriptions()}</span>
          <span className="is_first_500">
            {track?.is_first_500 && (
              <Tooltip title="This user is one of the first 500 who bought a paid subscription">
                <img src={first500Badge} className="is_first_500_badge" alt={'User badge'} />
              </Tooltip>
            )}
          </span>
        </div>
        <div
          className="manager-track-duration align-center"
          title={track.track_duration ? shortEnglishHumanizer(track.track_duration * 1000) : '0 sec'}
        >
          {track.track_duration ? shortEnglishHumanizer(track.track_duration * 1000) : '0 sec'}
        </div>
        <div className="manager-track-author align-center" title={artistName}>
          {artistName}
        </div>

        <div className="d-flex align-center">
          {!isCreator && (
            <div className="manager-track-rating align-center">
              <StarRoundedIcon className="star-icon" />
              {track.rating}
            </div>
          )}

          {!isCreator && (
            <div className="manager-track-invite">
              <TrackInvite track_id={track.track_id! as number} />
            </div>
          )}
          <>
            <IconButton className="add_btn-play" size="small" aria-describedby={id} color="primary" onClick={e => handleClick(e)}>
              <MoreHorizRoundedIcon style={{ color: 'orange' }} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              verticalAnchor="bottom"
              horizontalAnchor="center"
              verticalTransform="top"
              horizontalTransform="center"
              content={<MusicianTrackPopoverContent track={track} />}
            />
          </>
          <div className="manager-track-download align-center">{renderLoader()}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    playlist_id: playlist_id$(state),
    playlist: playlist$(state),
    currIndex: currIndex$(state),
    orderedTracks: orderedTracks$(state),
    downloadProgress: downloadProgress$(state),
    currentPlayedTrackId: currentPlayedTrackId$(state),
    isPlayerPlay: isPlay$(state),
    creatorPlaylistId: creatorPlaylistId$(state),
    tracks_for_playlist: tracks_for_playlist$(state),
    isNeedToScroollToTrackInRightPanel: isNeedToScroollToTrackInRightPanel$(state),
    userType: userType$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    updatePlaylist: dispatchProps.updatePlaylist,
    getRatingReceive: dispatchProps.getRatingReceive,
    setCurrSongIndex: dispatchProps.setCurrSongIndex,
    setIsPlayFromTrackPanel: dispatchProps.setIsPlayFromTrackPanel,
    setUploadSiteEvent: dispatchProps.setUploadSiteEvent,
    setEventListened: dispatchProps.setEventListened,
    setEventListenedForStatistic: dispatchProps.setEventListenedForStatistic,
    sendEvent: dispatchProps.sendEvent,
    getTrackUrl: dispatchProps.getTrackUrl,
    setID: dispatchProps.setID,
    setMusicianURL: dispatchProps.setMusicianURL,
    setPlayAfterSRCChanged: dispatchProps.setPlayAfterSRCChanged,
    setMuted: dispatchProps.setMuted,
    changePlayFromMusicSubmissions: dispatchProps.changePlayFromMusicSubmissions,
    addNewTrackToPlaylist: dispatchProps.addNewTrackToPlaylist,
    addTrackFromCreatorApp: dispatchProps.addTrackFromCreatorApp,
    delTrackFromCreatorApp: dispatchProps.delTrackFromCreatorApp,
    getNewTracksForPlaylist: dispatchProps.getNewTracksForPlaylist,
    scrollToTrack: dispatchProps.scrollToTrack,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    updatePlaylist: PlaylistCreators.updatePlaylist,
    getRatingReceive: RateActions.getRatingReceive,
    setCurrSongIndex: SongActions.setCurrSongIndex,
    setIsPlayFromTrackPanel: PlaylistActions.setIsPlayFromTrackPanel,
    setUploadSiteEvent: SiteEventsActions.setUploadSiteEvent,
    setEventListened: SiteEventsActions.setEventListened,
    setEventListenedForStatistic: SiteEventsActions.setEventListenedForStatistic,
    sendEvent: SiteEventCreators.sendEvent,
    getTrackUrl: MusicianPlayerCreators.getTrackUrl,
    setID: MusicianPlayerActions.setMusicianPlayerID,
    setMusicianURL: MusicianPlayerActions.setMusicianURL,
    setPlayAfterSRCChanged: SongActions.setPlayAfterSRCChanged,
    setMuted: SongActions.setMuted,
    changePlayFromMusicSubmissions: MusicianPlayerActions.changePlayFromMusicSubmissions,
    addNewTrackToPlaylist: NewTracksActions.addNewTrackToPlaylist,
    addTrackFromCreatorApp: CreatorPlaylistAction.addTrackFromCreatorApp,
    delTrackFromCreatorApp: CreatorPlaylistAction.delTrackFromCreatorApp,
    getNewTracksForPlaylist: NewTrackActions.getNewTracksForPlaylist,
    scrollToTrack: SongActions.scrollToTrack,
  },
  mergeProps
)(TrackItem);
