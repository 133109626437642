import * as SubsListActions from 'actions/subscriptionList';
import cx from 'classnames';
// eslint-disable-next-line
import React from 'react';
import { useDispatch } from 'react-redux';

import style from './priceSwitch.module.scss';

interface IDispatchProps {}
interface IStateProps {}
interface OwnProps {
  checked: boolean;
  setChecked: (v: boolean | ((prevVar: boolean) => boolean)) => void;
}

interface IProps extends IDispatchProps, OwnProps, IStateProps {}
export const PriceSwitch = ({ /*interval*/ checked, setChecked }: IProps) => {
  // const [checked, setChecked] = useState(false);
  // useEffect(() => setChecked(interval === 'year'), [interval]);
  const dispatch = useDispatch();

  const onChange = () =>
    setChecked(prevChecked => {
      dispatch(SubsListActions.setSubsPeriod(!prevChecked));
      return !prevChecked;
    });

  const leftLabelClick = () => {
    setChecked(false);
    dispatch(SubsListActions.setSubsPeriod(false));
  };

  const rightLabelClick = () => {
    setChecked(true);
    dispatch(SubsListActions.setSubsPeriod(true));
  };

  const ringCX = cx(style.switch, {
    [style.checked]: checked,
    [style.notChecked]: !checked,
  });

  const leftLabelCX = cx(style.switchLabelLeft, {
    [style.selected]: !checked,
    [style.notSelected]: checked,
  });

  const rightLabelCX = cx(style.switchLabelRight, {
    [style.selected]: checked,
    [style.notSelected]: !checked,
  });

  return (
    <div className={style.root}>
      <div className={style.swithLabelContainer}>
        <div className={leftLabelCX} onClick={leftLabelClick}>
          Monthly
        </div>
        <div className={ringCX} onClick={onChange}>
          <div className={style.switchRing} />
        </div>
        <div className={rightLabelCX} onClick={rightLabelClick}>
          Annually
          <span className={style.switchShare}>(save 10%)</span>
        </div>
      </div>
    </div>
  );
};
