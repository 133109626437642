import 'sweetalert2/src/sweetalert2.scss';

import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

import * as InviteActions from '../actions/invite';

export const fetchReceivedInvites = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(InviteActions.setInviteLoading());

    axios
      .post(`${Api.GetReceivedInvite}`)
      .then(resp => {
        dispatch(InviteActions.fetchReceivedInviteList(resp.data.result));
      })
      .catch(error => {
        dispatch(InviteActions.setInviteError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchCreatedInvites = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(InviteActions.setInviteLoading());

    axios
      .post(`${Api.GetCreatedInvite}`)
      .then(resp => {
        dispatch(InviteActions.fetchCreatedInviteList(resp.data.result));
      })
      .catch(error => {
        dispatch(InviteActions.setInviteError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const sendNewInviteStatus = (invite_id: number, status: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(InviteActions.setInviteLoading());

    axios
      .post(`${Api.UpdateInviteStatus}`, {
        invite_id,
        status,
      })
      .then(resp => {
        console.info(resp);
        dispatch(InviteActions.updateReceivedInviteStatus(invite_id, status));
        if (status.toLowerCase() === 'accepted') {
          successNotification('Successfully accepted!');
        } else {
          successNotification('You declined the invitation!');
        }
      })
      .catch(error => {
        dispatch(InviteActions.setInviteError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteInviteById = (invite_id: number, isCreated: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(InviteActions.setInviteLoading());

    axios
      .post(`${Api.DeleteInviteById}`, {
        invite_id,
      })
      .then(resp => {
        console.info(resp);
        if (isCreated) {
          dispatch(InviteActions.deleteCreatedInviteById(invite_id));
          dispatch(InviteActions.setInviteSuccess());
        } else {
          dispatch(InviteActions.deleteReceivedInviteById(invite_id));
          dispatch(InviteActions.setInviteSuccess());
        }
      })
      .catch(error => {
        dispatch(InviteActions.setInviteError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
