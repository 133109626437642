import { Action } from 'redux';

import * as Const from '../constants';

export const setCurrSubscrInit = (): Action => {
  return {
    type: Const.CURR_SUBSCR_INIT,
  };
};

interface ICurrentSubscrParams {
  current_period_end: string;
  current_period_start: string;
  interval: 'year' | 'month';
  is_active_subscription: boolean;
  product_title: string;
  stripe_customer_id: string;
  stripe_month_price_id: string;
  stripe_product_id: string;
  stripe_subscription_id: string;
  stripe_year_price_id: string;
  update_date: string;
  storage_info: {
    all_mbytes: number | string;
    used_mbytes: number;
  };
  currentSubscription: string;
}

export const getCurrSubscrSuccess = (payload: ICurrentSubscrParams) => {
  return {
    type: Const.CURR_SUBSCR_SUCCESS,
    payload,
  };
};

export const setCurrSubscrFail = (): Action => {
  return {
    type: Const.CURR_SUBSCR_ERROR,
  };
};

export const setCurrSubscrPending = (): Action => {
  return {
    type: Const.CURR_SUBSCR_PENDING,
  };
};

export const clearPriceId = (): Action => {
  return {
    type: Const.CURR_SUBSCR_CLEAR_PRICE_ID,
  };
};
