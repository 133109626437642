import 'sweetalert2/src/sweetalert2.scss';

import * as AuthActions from 'actions/auth';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import * as Const from 'constants/index';
import * as AuthCreators from 'creators/auth';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { isAuth$ } from 'selectors/auth';
import { catchedErrorNotification } from 'utils/error';

import * as SignUpActions from '../actions/signUp';
import { email$ } from '../selectors/signUp';

declare global {
  interface Window {
    LeadDyno: any;
  }
}

export const signUp = (
  pass: string,
  user_name: string,
  email: string,
  role: 'creator' | 'admin' | 'manager',
  link_1: string,
  link_2: string | undefined = '',
  company_id?: number
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(SignUpActions.setSignUpPending());

    const redirect_url = `${window.location.origin}/confirm-new-email/?token={token}`;

    axios
      .post(`${Api.SignUp}`, {
        pass,
        user_name,
        email,
        role,
        link_1,
        link_2,
        redirect_url,
        company_id,
      })
      .then(response => {
        if (isAuth$(getState())) {
          setTimeout(() => dispatch(AuthCreators.onLogout() as any), 5500);
        }

        const background = response?.data?.result?.login_background;
        if (background) {
          dispatch(AuthCreators.setLoginPageBackground(background as string) as any);
        }
        dispatch(SignUpActions.fetchSignUpSuccess());
        dispatch(SignUpActions.redirectToLogin());
        dispatch(AuthActions.setCheckedEmail(null));
        successNotification(`Congratulations! Letter with confirmation will be sent to ${email$(getState())}. Check your email`, 8000);
        setTimeout(() => dispatch(SignUpActions.redirectSetDefaultVal()), 6000);
        setTimeout(() => dispatch(SignUpActions.setSignUpInit()), 6000);
        /* eslint-disable */
        if (window?.LeadDyno) {
          const LeadDyno = window?.LeadDyno;
          LeadDyno.key = Const.LEAD_DYNO_PUBIC_KEY;
          LeadDyno.recordLead(email);
        }
      })
      .catch(error => {
        dispatch(SignUpActions.setSignUpError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
