export let ENV = '';

if (process.env.NODE_ENV === 'production' && window.location.host.includes('dev')) {
  ENV = 'dev.';
} else if (process.env.NODE_ENV === 'development' && window.location.host.includes('localhost')) {
  ENV = 'dev.';
} else {
  ENV = '';
}

export const envDetector = () => {
  if (
    window.location.hostname.includes('dev') ||
    window.location.hostname.includes('stage') ||
    window.location.hostname.includes('localhost')
  ) {
    return false;
  } else {
    return true;
  }
};

export const envWSDetector = () => {
  if (window.location.hostname.includes('dev') || window.location.hostname.includes('localhost')) {
    return 'dev.';
  } else if (window.location.hostname.includes('stage')) {
    return 'stage.';
  } else {
    return '';
  }
};
