import { ReactComponent as ArrowFWD } from 'assets/img/arrow-fwd.svg';
// eslint-disable-next-line
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import style from './header.module.scss';

const HeaderComponent = ({ history }: RouteComponentProps) => {
  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <div className={style.root}>
      <div className={style.headerContainer}>
        <button className={style.btn} onClick={handleBackClick}>
          <ArrowFWD className={style.icon} />
          <span className={style.btnText}>Back</span>
        </button>
        <div className={style.mainHeaderContent}>
          <h1 className={style.head}>Pricing</h1>
          <h2 className={style.subHead}>You can update or downgrade at any time.</h2>
        </div>
      </div>
    </div>
  );
};

export const Header = withRouter(HeaderComponent);
