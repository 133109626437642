import { IPlaylist } from 'interfaces/IPlaylist';
import { Action } from 'redux';

import * as Const from '../constants';

export const getPlaylistReceive = (payload: IPlaylist[]) => {
  return {
    type: Const.PLAYLIST_RECEIVE,
    payload,
  };
};

export const setPlaylistInit = (): Action => {
  return {
    type: Const.PLAYLIST_INIT,
  };
};

export const setPlaylistSuccess = (): Action => {
  return {
    type: Const.PLAYLIST_SUCCESS,
  };
};

export const setPlaylistFail = (): Action => {
  return {
    type: Const.PLAYLIST_ERROR,
  };
};

export const setPlaylistPending = (): Action => {
  return {
    type: Const.PLAYLIST_PENDING,
  };
};

export const setCurrPlaylistName = (payload: string) => {
  return {
    type: Const.CURR_PLAYLIST_NAME,
    payload,
  };
};

export const setCurrentPlaylistId = (payload: number | null) => {
  return {
    type: Const.SET_PLAYLIST_ID,
    payload,
  };
};

export const setPlaylistLoaded = () => {
  return {
    type: Const.PLAYLIST_LOADED,
  };
};

export const setIsPlayFromTrackPanel = (payload: boolean) => {
  return {
    type: Const.PLAY_FROM_TRACK_PANEL,
    payload,
  };
};

export const setPlNameInBase = (payload: string) => {
  return {
    type: Const.PLAYLIST_NAME_IN_BASE,
    payload,
  };
};

export const setTilteInBase = (payload: string) => {
  return {
    type: Const.PLAYLIST_TITLE_IN_BASE,
    payload,
  };
};

export const setWriterInBase = (payload: string) => {
  return {
    type: Const.PLAYLIST_WRITER_IN_BASE,
    payload,
  };
};

export const setCurrPlaylistNum = (payload: number | null) => {
  return {
    type: Const.CURRENT_PLAYLIST_NUM,
    payload,
  };
};

export const setInitPlaylistItems = (): Action => {
  return {
    type: Const.INIT_PLAYLIST,
  };
};

export const filterPlaylistById = (payload: number) => {
  return {
    type: Const.DEL_PLAYLIST,
    payload,
  };
};

export const setAutoForwarding = (payload: boolean) => {
  return {
    type: Const.PLAYLIST_AUTOFORWARDING,
    payload,
  };
};

export const addNewPlaylist = (payload: IPlaylist) => {
  return {
    type: Const.PLAYLIST_ADD_NEW_ITEM,
    payload,
  };
};

export const setSharedPlaylistID = (payload: number | null) => {
  return {
    type: Const.SHARED_ID,
    payload,
  };
};

export const setSharedPlaylistIndex = (payload: number | null) => {
  return {
    type: Const.SHARED_INDEX,
    payload,
  };
};

export const setPlaylistAmount = (payload: number | null) => {
  return {
    type: Const.PLAYLIST_AMOUNT,
    payload,
  };
};

export const setPlaylistLoadingTracks = (payload: boolean) => {
  return {
    type: Const.PLAYLIST_LOADING_TRACKS,
    payload,
  };
};
