import AudioPanel from 'components/audioPanel/audioPanel';
import Playlist from 'components/playlist/playlist';
import TrackPanel from 'components/trackPanel/trackPanel';
import JobPanel from 'containers/jobPanel/jobPanel';
// eslint-disable-next-line
import React, { PureComponent } from 'react';

interface IStateProps {}

interface IDispatchProps {}

interface OwnProps {
  isHideCompanySelect?: boolean;
  isAdmin: boolean;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

class CompanyActiveJobs extends PureComponent<IProps> {
  public render() {
    const { isHideCompanySelect, isAdmin } = this.props;

    return (
      <div className="manager-content d-flex flex-column show-07-animation">
        <JobPanel isHideCompanySelect={isHideCompanySelect} isAdmin={isAdmin} />
        <AudioPanel className={'manager_audiopanel_container'} isNeedRating isFullWidth />
        <div className="d-flex manager-panels">
          <Playlist />
          <TrackPanel isAdmin={isAdmin} />
        </div>
      </div>
    );
  }
}

export default CompanyActiveJobs;
