import cx from 'classnames';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { SwitchMaterial } from 'components/switch';
import { UserTable } from 'containers/userTable';
import * as AdminCreators from 'creators/admin';
import { IUser } from 'interfaces/IUser';
import State from 'interfaces/state/State';
import UpdateApproveState from 'interfaces/state/UpdateApproveState';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { admin$, admins$, creator$, creators$, isLoading$ as isLoadingUserCategory$, manager$, managers$ } from 'selectors/admin';
import { isAuth$, isLoading$ as isAuthLoading$ } from 'selectors/auth';
import { approve$ } from 'selectors/updateApprove';

import style from './userList.module.scss';
import { UserSearch } from './userSearch';

interface IStateProps {
  isAuthLoading: boolean;
  isAuth: boolean;
  admins: string[];
  creators: string[];
  managers: string[];
  approve: UpdateApproveState['approve'];
  creator: { [key: string]: IUser | null } | null;
  manager: { [key: string]: IUser | null } | null;
  admin: { [key: string]: IUser | null } | null;
  isLoadingUserCategory: boolean;
}

interface IDispatchProps {
  fetchUsersForAdmin: (
    limit: number,
    offset: number,
    user_type: 'creator' | 'admin' | 'manager',
    sort_by?: 'date' | 'email',
    sort_direction?: 'up' | 'down',
    on_hold?: boolean
  ) => void;
}

interface OwnProps {}
interface IProps extends OwnProps, IStateProps, IDispatchProps {}

const UserList = ({ admins, creators, managers, creator, manager, admin, approve, isLoadingUserCategory, fetchUsersForAdmin }: IProps) => {
  const [userType, setUserType] = useState('creators' as 'managers' | 'admins' | 'creators');
  const [type, setType] = useState('creator' as 'manager' | 'admin' | 'creator');
  const [search, setSearch] = useState('');
  const [direction, setDirection] = useState('down' as 'down' | 'up');
  const [sortingBy, setSortingBy] = useState({ label: 'Default', value: '' } as ISelectItem);
  const [isOnHold, setOnHold] = useState(false);

  useEffect(() => {
    // fetchUsersForAdmin(20, 0, 'creator');
    // eslint-disable-next-line
  }, []);

  const ButtonManagerCX = cx(style.btn, {
    [style.active]: userType === 'managers',
  });

  const ButtonCreatorsCX = cx(style.btn, {
    [style.active]: userType === 'creators',
  });

  const ButtonAdminsCX = cx(style.btn, {
    [style.active]: userType === 'admins',
  });

  const handleClickRole = useCallback(
    (role: 'manager' | 'admin' | 'creator') => {
      type RoleType = 'managers' | 'admins' | 'creators';
      setUserType((role + 's') as RoleType);
      fetchUsersForAdmin(20, 0, role, sortingBy.value as 'date' | 'email' | undefined, direction, isOnHold);
      setType(role);
    },
    [direction, sortingBy.value, isOnHold, fetchUsersForAdmin]
  );

  const onManagerClick = useCallback(() => {
    handleClickRole('manager');
    // eslint-disable-next-line
  }, [handleClickRole]);

  const onCreatorClick = useCallback(() => {
    handleClickRole('creator');
    // eslint-disable-next-line
  }, [handleClickRole]);

  const onAdminClick = useCallback(() => {
    handleClickRole('admin');
    // eslint-disable-next-line
  }, [handleClickRole]);

  const handleUpdateData = (v: string) => {
    const dates = ['dateUp', 'dateDown'];
    const emails = ['emailUp', 'emailDown'];
    const handleDirection = (str: string, key: string) => {
      const dir = str.replace(key, '').toLowerCase() as 'up' | 'down';
      setSortingBy(pr => ({ ...pr, value: key }));
      setDirection(dir);
      fetchUsersForAdmin(20, 0, type, key as 'date' | 'email' | undefined, dir, isOnHold);

      return key;
    };
    const sortValue = (val: string) => {
      if (dates.includes(val as string)) {
        return handleDirection(val, 'date');
      } else if (emails.includes(val)) {
        return handleDirection(val, 'email');
      } else {
        return handleDirection(val, '');
      }
    };

    sortValue(v);
  };

  const onChange = (sort: ISelectItem) => {
    setSortingBy(sort);

    const value = sort.value as string;

    handleUpdateData(value);
  };

  const sortByList = useMemo(
    () => [
      { label: 'Default', value: '' },
      { label: 'Date of creation ↑', value: 'dateUp' },
      { label: 'Date of creation ↓', value: 'dateDown' },
      { label: 'Email ↑', value: 'emailUp' },
      { label: 'Email ↓', value: 'emailDown' },
    ],
    []
  );
  const handleChangeHoldUsers = () => {
    fetchUsersForAdmin(20, 0, type, sortingBy.value as 'date' | 'email' | undefined, direction, !isOnHold);
    setOnHold(prev => !prev);
  };

  return (
    <section className={`${style.root} show-07-animation`}>
      <div className={style.header}>
        <div className={style.switch}>
          <SwitchMaterial
            onChange={handleChangeHoldUsers}
            checked={isOnHold}
            label={<div className="playlist-link-label label-1">Show hold user list</div>}
          />
        </div>
        <div className={style.tabs}>
          <button className={ButtonCreatorsCX} onClick={onCreatorClick}>
            Creators
          </button>
          <button className={ButtonAdminsCX} onClick={onAdminClick}>
            Admins
          </button>
          <button className={ButtonManagerCX} onClick={onManagerClick}>
            Managers
          </button>
        </div>
        <div className={style.headerContent}>
          <UserSearch userType={type} value={search} onChange={setSearch} sortby={sortingBy.value as 'date' | 'email' | undefined} />
          <div className="d-flex flex-column pos-rel m-l-20">
            <MaterialSelect
              value={sortingBy}
              placeholder="Sort by"
              selectItems={sortByList}
              className={style.select}
              isAllValueNeed
              onChangeAllValue={onChange}
              width={160}
            />
          </div>
        </div>
      </div>
      <div className={style.content}>
        {userType === 'creators' && (
          <UserTable
            type={userType}
            adminType={'creator'}
            items={creators}
            title="Creators (Musicians)"
            approve={approve}
            itemsByEmail={creator}
            isLoading={isLoadingUserCategory}
            fetchUsersForAdmin={fetchUsersForAdmin}
            isOnHold={isOnHold}
            search={search}
            sortBy={sortingBy.value as 'date' | 'email' | undefined}
            direction={direction}
          />
        )}

        {userType === 'admins' && (
          <UserTable
            type={userType}
            adminType={'admin'}
            items={admins}
            title="Admins"
            itemsByEmail={admin}
            isLoading={isLoadingUserCategory}
            fetchUsersForAdmin={fetchUsersForAdmin}
            isOnHold={isOnHold}
            search={search}
            sortBy={sortingBy.value as 'date' | 'email' | undefined}
            direction={direction}
          />
        )}
        {userType === 'managers' && (
          <UserTable
            type={userType}
            adminType={'manager'}
            items={managers}
            title="Managers"
            itemsByEmail={manager}
            isLoading={isLoadingUserCategory}
            fetchUsersForAdmin={fetchUsersForAdmin}
            isOnHold={isOnHold}
            search={search}
            sortBy={sortingBy.value as 'date' | 'email' | undefined}
            direction={direction}
          />
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isAuthLoading: isAuthLoading$(state),
    isLoadingUserCategory: isLoadingUserCategory$(state),
    isAuth: isAuth$(state),
    admins: admins$(state),
    creators: creators$(state),
    managers: managers$(state),
    approve: approve$(state),
    creator: creator$(state),
    manager: manager$(state),
    admin: admin$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchUsersForAdmin: dispatchProps.fetchUsersForAdmin,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchUsersForAdmin: AdminCreators.fetchUsersForAdmin,
  },
  mergeProps
)(memo(UserList));
