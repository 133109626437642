import { IconButton, Tooltip } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import LinkOffRoundedIcon from '@material-ui/icons/LinkOffRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import PhotoSizeSelectActualRoundedIcon from '@material-ui/icons/PhotoSizeSelectActualRounded';
import * as PlaylistActions from 'actions/application';
import { changeUnsaved } from 'actions/application';
import * as NewTrackActions from 'actions/newTracks';
import axios from 'axios';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import { ShareableLink } from 'components/shareableLink';
import { successNotification } from 'components/successNotification/successNotification';
import { SwitchMaterial } from 'components/switch';
import * as CreatorPlaylists from 'creators/application';
import { fetchPlaylistBg } from 'creators/shareablePlaylistInfo';
import { Api } from 'enums/api';
import { INewTrack, INewTrackType } from 'interfaces/INewTrack';
import AppState from 'interfaces/state/AppState';
import State from 'interfaces/state/State';
import { orderBy } from 'lodash-es';
// eslint-disable-next-line
import React, { MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect, useDispatch, useSelector } from 'react-redux';
import { appById$, appIds$, hasUnsavedPlaylist$, isLoading$, playlist_id$, shareableLinks$ } from 'selectors/applications';
import { all$, tracks_for_playlist$ } from 'selectors/newTrack';
import { isLoading$ as isLoadingTracks$ } from 'selectors/newTrack';
import { currentTab$ } from 'selectors/tab';
import { usePrevious } from 'utils';
import { catchedErrorNotification } from 'utils/error';

import { Background } from './background';
import style from './playlistControlls.module.scss';

type InitShareablePlaylist = {
  playlist_id: null | number;
  allowed_all_tracks: boolean;
  allowed_single_track: boolean;
};

const initShareablePlaylistInfo: InitShareablePlaylist = {
  playlist_id: null,
  allowed_all_tracks: true,
  allowed_single_track: true,
};

interface IStateProps {
  appIds: AppState['appIds'];
  appById: AppState['appById'];
  playlist_id: AppState['playlist_id'];
  allTracks: INewTrackType[];
  shareableLinks: AppState['shareableLinks'];
  isLoadingLinks: boolean;
}

interface IDispatchProps {
  fetchCreatorPlaylists: () => void;
  setPlaylistId: (id: number | null) => void;
  deletePlaylistById: (id: number) => void;
  createShareableLink: (id: number, allowed_all_tracks: boolean, allowed_single_track: boolean) => void;
  getNewTracksForPlaylist: (tr: INewTrack[] | null) => void;
  fetchTracksToPlaylistById: (id: number) => void;
  deleteCreatorApplication: (id: number) => void;
  fetchShareableLinks: (id: number) => void;
}
interface OwnProps {
  createProcessing: boolean;
  setCreateProcessing: (v: boolean) => void;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const PlaylistControllsInternal = ({
  fetchCreatorPlaylists,
  setPlaylistId,
  deletePlaylistById,
  createShareableLink,
  getNewTracksForPlaylist,
  fetchTracksToPlaylistById,
  deleteCreatorApplication,
  fetchShareableLinks,
  playlist_id,
  appById,
  appIds,
  shareableLinks,
  isLoadingLinks,
}: // createProcessing,
IProps) => {
  const tab = useSelector(currentTab$);
  const prevTab = usePrevious(tab);
  // eslint-disable-next-line
  useEffect(() => {
    if (tab !== prevTab) {
      fetchCreatorPlaylists();
    }
  }, [prevTab, tab, fetchCreatorPlaylists]);

  const [linkAnchor, setLinkAnchor] = useState<HTMLButtonElement | null>(null);
  const [linkOptions, setLinkOptions] = useState<HTMLButtonElement | null>(null);
  const [shareablePlaylistInfo, setShareablePlaylist] = useState(initShareablePlaylistInfo);
  const [playlistValue, setPlaylistValue] = useState({ label: '', value: '' } as ISelectItem);
  const tracks_for_playlist = useSelector(tracks_for_playlist$);
  const isLoadingTracks = useSelector(isLoadingTracks$);
  const hasUnsavedPlaylist = useSelector(hasUnsavedPlaylist$);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const id = useSelector(playlist_id$);
  const [percentage, setPercentage] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const dispatch = useDispatch();
  const prevPlaylistId = usePrevious(playlist_id);

  const isOpenLink = Boolean(linkAnchor);
  const isOpenOptions = Boolean(linkOptions);
  const linkId = isOpenLink ? 'popover' : undefined;
  const optionsId = isOpenOptions ? 'options' : undefined;

  const handleCloseLink = () => {
    setLinkAnchor(null);
  };

  const handleCloseOptions = () => {
    setLinkOptions(null);
  };

  const handleLinkClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!playlist_id) return;
    setLinkAnchor(event.currentTarget);
  };
  const handleOptionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!playlist_id) return;
    setLinkOptions(event.currentTarget);
  };

  const playlists = () => {
    if (appIds.length) {
      return orderBy(appIds, 'id', 'desc').map(id => ({ label: appById[id].description, value: appById[id].id }));
    } else {
      return [];
    }
  };

  const onChange = useCallback(
    (id: string | number) => {
      setPlaylistValue({ label: appById[Number(id)]?.description, value: appById[Number(id)]?.id });
      setPlaylistId(Number(id));
      if (id && playlist_id !== id) {
        fetchTracksToPlaylistById(Number(id)!);
        fetchShareableLinks(Number(id));
      }
    },
    // eslint-disable-next-line
    [appById, playlist_id]
  );

  const handleDelete = () => {
    if (playlist_id) {
      deletePlaylistById(playlist_id);
      deleteCreatorApplication(playlist_id);
      const filteredIds = appIds?.filter(id => Number(id) !== playlist_id!);
      if (filteredIds?.length) {
        const currentPlaylistId = Number(filteredIds[0]);
        fetchTracksToPlaylistById(currentPlaylistId);
        setPlaylistId(currentPlaylistId);

        setPlaylistValue({ label: appById[currentPlaylistId]?.description, value: appById[currentPlaylistId]?.id });
      } else {
        setPlaylistValue({ label: '', value: '' });
      }
    }
    getNewTracksForPlaylist([]);
  };

  useEffect(() => {
    if (!playlist_id && prevPlaylistId !== playlist_id && playlistValue.label) {
      setPlaylistValue({ label: '', value: '' });
    }
    // eslint-disable-next-line
  }, [playlist_id, prevPlaylistId, playlistValue.value]);

  useEffect(() => setShareablePlaylist({ ...initShareablePlaylistInfo, playlist_id }), [playlist_id, setShareablePlaylist]);

  const handleAllowDownloadAllTracks = () => {
    setShareablePlaylist({ ...shareablePlaylistInfo, allowed_all_tracks: !shareablePlaylistInfo.allowed_all_tracks });
  };

  const handleAllowDownloadTrack = () => {
    setShareablePlaylist({ ...shareablePlaylistInfo, allowed_single_track: !shareablePlaylistInfo.allowed_single_track });
  };

  const handleGenerateLink = () => {
    if (shareablePlaylistInfo.playlist_id) {
      createShareableLink(
        shareablePlaylistInfo.playlist_id!,
        shareablePlaylistInfo.allowed_all_tracks,
        shareablePlaylistInfo.allowed_single_track
      );
    }
  };

  const handleOpenPopup = useCallback(() => {
    if (playlist_id) fetchShareableLinks(playlist_id);
    // eslint-disable-next-line
  }, [playlist_id]);

  const isUnsaved = useMemo(() => {
    if (playlist_id) {
      if (appById[playlist_id]?.tracks?.length) {
        const havesomeNewTrack = appById[playlist_id]?.tracks.some(tr => tr?.isNew);
        return havesomeNewTrack || hasUnsavedPlaylist;
      } else {
        return false;
      }
    } else if (hasUnsavedPlaylist) {
      return true;
    } else {
      return !!tracks_for_playlist.length;
    }
  }, [playlist_id, appById, tracks_for_playlist, hasUnsavedPlaylist]);

  useEffect(() => {
    if (playlist_id !== prevPlaylistId && playlist_id && appById && !playlistValue?.label) {
      if (appById[playlist_id]?.id) {
        setPlaylistValue({ label: appById[playlist_id]?.description, value: appById[playlist_id]?.id });
      }
    }
  }, [playlist_id, appById, playlistValue, prevPlaylistId]);

  useEffect(() => {
    if (isUnsaved && !isLoadingTracks) {
      dispatch(changeUnsaved(true));
    }
    // eslint-disable-next-line
  }, [isUnsaved, isLoadingTracks]);

  // useEffect(() => {
  //   if ((!appIds?.length && playlistValue.label) || (createProcessing && playlistValue.label)) {
  //     setPlaylistValue({ label: '', value: '' });
  //   }
  //   // eslint-disable-next-line
  // }, [appIds, createProcessing, playlistValue.label]);

  //  eslint-disable-next-line
  const handleUploadBg = async () => {
    if (!file) {
      errorNotification('Please upload file');
      setOpen(false);
    } else {
      let formData = new FormData();
      formData.append('background', file![0]);
      formData.append('id', `${playlist_id}`);
      formData.append('default', JSON.stringify(checked));

      setLoading(true);
      await axios
        .post(Api.UploadPlaylistBackground, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: p => {
            setPercentage((p.loaded / p.total) * 100);
          },
        })
        .then(() => {
          successNotification('Playlist background loaded successfully!');
          dispatch(fetchPlaylistBg(id!));
        })
        .catch(err => catchedErrorNotification(err));
      setLoading(false);
      setOpen(false);
    }
    handleCloseOptions();
  };

  const handleOpenBgPopup = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
    handleCloseOptions();
  };

  return (
    <div className="playlist-controls d-flex flex-column justify-around">
      <div className="d-flex flex-column">
        <div className="d-flex align-center" style={{ marginTop: '-3px' }}>
          <span className="playlist-title m-r-a">Playlist</span>
          {isUnsaved && !isLoadingTracks && <div className={style.unsaved}>unsaved</div>}
          <Tooltip title="Share playlist">
            <span></span>
          </Tooltip>

          <Tooltip title="Playlist options">
            <span>
              <IconButton size="small" aria-describedby={optionsId} color="default" onClick={handleOptionsClick}>
                <MoreVertRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Popover
            id={optionsId}
            open={isOpenOptions}
            anchorEl={linkOptions}
            onClose={handleCloseOptions}
            verticalAnchor="bottom"
            horizontalAnchor="center"
            verticalTransform="top"
            horizontalTransform="left"
            content={
              <div className={style.options}>
                <div className={style.option}>
                  <IconButton
                    size="small"
                    aria-describedby={linkId}
                    color="default"
                    onClick={handleLinkClick}
                    disableRipple
                    className={style.sharedOption}
                  >
                    <div className={style.icon}>
                      {playlist_id ? (
                        <LinkRoundedIcon className={style.optionsIcon} />
                      ) : (
                        <LinkOffRoundedIcon className={style.optionsIcon} />
                      )}
                    </div>
                    <div className={style.label}>Share</div>
                  </IconButton>
                </div>

                <Popup
                  isOpen={isOpen}
                  onOpen={handleOpenBgPopup}
                  disabled={!playlist_id}
                  btnClassName={style.deleteBtn}
                  onApply={handleUploadBg}
                  onDecline={handleClosePopup}
                  cancelBtnText={'CANCEL'}
                  buttonText={
                    <div className={style.option}>
                      <div className={style.icon}>
                        <PhotoSizeSelectActualRoundedIcon className={style.optionsIcon} />
                      </div>
                      <div className={style.label}>Set Background</div>
                    </div>
                  }
                  modalContent={
                    <Background setFile={setFile} isLoading={isLoading} checked={checked} setChecked={setChecked} percentage={percentage} />
                  }
                />

                <Popup
                  disabled={!playlist_id}
                  onApply={handleDelete}
                  btnClassName={style.deleteBtn}
                  buttonText={
                    <div className={style.option}>
                      <div className={style.icon}>
                        <DeleteForeverRoundedIcon className={style.optionsIcon} />
                      </div>
                      <div className={style.label}>Delete</div>
                    </div>
                  }
                  modalContent={
                    <div style={{ height: 100 }} className="d-flex align-center flex-column">
                      <div className="playlist-title-text">Delete this playlist</div>
                      <div>Are you sure that you want to delete current playlist?</div>
                    </div>
                  }
                />
              </div>
            }
          />
        </div>
        <span className="playlist-subtitle">Create / Select your playlist </span>
      </div>
      <div className="d-flex align-center">
        <MaterialSelect
          value={playlistValue}
          placeholder="Select Playlist"
          selectItems={playlists()}
          onChange={onChange}
          className="pl-drop"
        />
      </div>
      <div>
        <Popover
          id={linkId}
          open={isOpenLink}
          anchorEl={linkAnchor}
          onClose={handleCloseLink}
          onEnter={handleOpenPopup}
          verticalAnchor="bottom"
          horizontalAnchor="center"
          verticalTransform="top"
          horizontalTransform="left"
          content={
            <div className="d-flex flex-column playlist-link-container">
              <div className="playlist-link-title">Generate link to share</div>
              <div className="playlist-link-item">
                <SwitchMaterial
                  onChange={handleAllowDownloadAllTracks}
                  checked={shareablePlaylistInfo.allowed_all_tracks}
                  label={<div className="playlist-link-label label-1">Allow downloading all tracks</div>}
                />
              </div>
              <div className="playlist-link-item">
                <SwitchMaterial
                  onChange={handleAllowDownloadTrack}
                  checked={shareablePlaylistInfo.allowed_single_track}
                  label={<div className="playlist-link-label label-2">Allow downloading tracks separately</div>}
                />
              </div>
              <div>
                <button className="playlist-link-btn" onClick={handleGenerateLink} disabled={isLoadingLinks}>
                  Create new link
                </button>
              </div>
              <Scrollbars className={style.links}>
                {shareableLinks?.map(link => {
                  return <ShareableLink key={link.link_id} link={link} />;
                })}
              </Scrollbars>
              {!shareableLinks?.length && <p className={style.emptyContent}>Your links to the playlist will be here</p>}
            </div>
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    appIds: appIds$(state),
    appById: appById$(state),
    playlist_id: playlist_id$(state),
    allTracks: all$(state),
    shareableLinks: shareableLinks$(state),
    isLoadingLinks: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchCreatorPlaylists: dispatchProps.fetchCreatorPlaylists,
    setPlaylistId: dispatchProps.setPlaylistId,
    deletePlaylistById: dispatchProps.deletePlaylistById,
    createShareableLink: dispatchProps.createShareableLink,
    getNewTracksForPlaylist: dispatchProps.getNewTracksForPlaylist,
    fetchTracksToPlaylistById: dispatchProps.fetchTracksToPlaylistById,
    deleteCreatorApplication: dispatchProps.deleteCreatorApplication,
    fetchShareableLinks: dispatchProps.fetchShareableLinks,
    ...props,
  };
};

export const PlaylistControlls = connect(
  mapStateToProps,
  {
    fetchCreatorPlaylists: CreatorPlaylists.fetchCreatorPlaylists,
    setPlaylistId: PlaylistActions.setPlaylistId,
    deletePlaylistById: CreatorPlaylists.deletePlaylistById,
    createShareableLink: CreatorPlaylists.createShareableLink,
    getNewTracksForPlaylist: NewTrackActions.getNewTracksForPlaylist,
    fetchTracksToPlaylistById: CreatorPlaylists.fetchTracksToPlaylistById,
    deleteCreatorApplication: PlaylistActions.deleteCreatorApplication,
    fetchShareableLinks: CreatorPlaylists.fetchShareableLinks,
  },
  mergeProps
)(memo(PlaylistControllsInternal));
