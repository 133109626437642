import AddBoxIcon from '@material-ui/icons/AddBox';
import { redirectForAddNewPayment } from 'creators/billing';
// eslint-disable-next-line
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { billing$ } from 'selectors/billing';

import { Paymentheader } from '../paymentHeader';
import { PaymentItem } from '../paymentItem';
import style from './payments.module.scss';

export const Payments = () => {
  const billing = useSelector(billing$);
  const dispatch = useDispatch();
  const memoBilling = useMemo(() => billing, [billing]);

  const onClick = useCallback(() => {
    dispatch(redirectForAddNewPayment());
    // eslint-disable-next-line
  }, []);
  return (
    <div className={style.root}>
      <div className={style.head}>
        <h3 className={style.title}>Payment methods</h3>
        <button className={style.addNew} onClick={onClick}>
          <AddBoxIcon style={{ fontSize: 15 }} />
          <span>Add new</span>
        </button>
      </div>
      <Paymentheader />
      <div className={style.list}>
        {memoBilling?.payment_methods?.map(paymentMethod => {
          return <PaymentItem key={paymentMethod.payment_method_id} payment={paymentMethod} />;
        })}
      </div>
    </div>
  );
};
