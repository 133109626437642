import { setMusPlayerPlay } from 'actions/musicianPlayer';
import * as NewTrackActions from 'actions/newTracks';
import * as SongActions from 'actions/song';
import * as UploadActions from 'actions/uploadTrack';
import cx from 'classnames';
import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { LinearLoader } from 'components/linearLoader/linearLoader';
import { Loader } from 'components/loader/loader';
import { TrackTypeTabs } from 'components/trackTypeTabs';
import { SearchTracks } from 'containers/searchTracks';
import * as CurrentSubscriptionCreators from 'creators/currentSubscription';
import * as MusicianTracksCreators from 'creators/newTracks';
import * as UploadCreators from 'creators/uploadTrack';
import { INewTrack, TrackType } from 'interfaces/INewTrack';
import CurrentSubscriptionState from 'interfaces/state/CurrentSubscriptionState';
import State from 'interfaces/state/State';
import moment from 'moment';
// eslint-disable-next-line
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  currentSubscription$,
  current_period_end$,
  current_period_start$,
  is_active_subscription$,
  storage_info$,
} from 'selectors/currentSubscription';
import { currentURL$ } from 'selectors/musicianPlayer';
import { all$, filterBy$, isLoading$ as isLoadingTracks$ } from 'selectors/newTrack';
import { isLoading$, progress$ } from 'selectors/uploadTrack';
import { DATE_FORMAT_SHORT, SUPPORTED_FORMATS } from 'utils';
import { getDuration } from 'utils/audio';
import { formatBytes } from 'utils/formatBytes';

import { MusicianPlayer } from '../musicianPlayer';
import { TrackItem } from '../trackItem';
import style from './trackList.module.scss';

interface IStateProps {
  all: INewTrack[];
  currentURL: string | null;
  progress: number;
  isLoading: boolean;
  current_period_start: string;
  current_period_end: string;
  currentSubscription: string;
  is_active_subscription: boolean;
  storage_info: CurrentSubscriptionState['storage_info'];
  filterBy: TrackType;
  isLoadingTracks: boolean;
}
interface OwnProps {}
interface IDispatchProps {
  fetchTracks: (m: TrackType, preload?: boolean, limit?: number, offset?: number) => void;
  setTrackDuration: (dur: number | null) => void;
  setFileSizeOfTrack: (size: number) => void;
  uploadTrack: (file: File) => void;
  searchTracks: (title: string, type: TrackType) => void;
  setPlayAfterSRCChanged: (d: boolean) => void;
  fetchCurrentSubscription: () => void;
  setfilterBy: (variant: TrackType) => void;
}

interface IProps extends IStateProps, OwnProps, IDispatchProps {}
const TrackListComponent = ({
  fetchTracks,
  setTrackDuration,
  setFileSizeOfTrack,
  uploadTrack,
  // searchTracks,
  setPlayAfterSRCChanged,
  fetchCurrentSubscription,
  setfilterBy,
  all,
  currentURL,
  progress,
  isLoading,
  current_period_end,
  current_period_start,
  currentSubscription,
  is_active_subscription,
  storage_info,
  filterBy,
  isLoadingTracks,
}: IProps) => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setfilterBy('all');
    return () => setfilterBy('all');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCurrentSubscription();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPlayAfterSRCChanged(false);
    return () => setPlayAfterSRCChanged(false);
    // eslint-disable-next-line
  }, []);

  const onSearch = useCallback((type: 'music' | 'sfx' | 'audio' | 'all') => {
    // searchTracks(search, type);
    setfilterBy(type);
    dispatch(NewTrackActions.setCurrentTrackId(0));
    dispatch(setMusPlayerPlay(false));
  }, []);

  const onMusicClick = useCallback(() => {
    onSearch('music');
  }, [onSearch]);

  const onAllClick = useCallback(() => {
    onSearch('all');
  }, [onSearch]);

  const onSFXClick = useCallback(() => {
    onSearch('sfx');
  }, [onSearch]);

  const onAudioClick = useCallback(() => {
    onSearch('audio');
  }, [onSearch]);

  const handleUpload = (file: File | File[]) => {
    onChange(file[0]);
  };

  let objectURL = null;

  const onChange = (file: File) => {
    setTrackDuration(0);

    if (!SUPPORTED_FORMATS.includes(file.type)) {
      errorNotification('Unsupported format');
      return;
    }
    setFileSizeOfTrack(file.size);

    uploadTrack(file);
    if (URL) {
      objectURL = URL.createObjectURL(file);
      getDuration(objectURL, duration => {
        setTrackDuration(Math.floor(duration));
      });
    } else if (webkitURL) {
      objectURL = webkitURL.createObjectURL(file);
      getDuration(objectURL, duration => {
        setTrackDuration(Math.floor(duration));
      });
    } else if ('URL' in window) {
      objectURL = window.URL.createObjectURL(file);
      getDuration(objectURL, duration => {
        setTrackDuration(Math.floor(duration));
      });
    } else {
      console.error('The application cannot determine the duration of the track');
      errorNotification('The application cannot determine the duration of the track');
    }
  };

  const onSearchChange = (val: string) => {
    setSearch(val);
    dispatch(NewTrackActions.setCurrentTrackId(0));
    dispatch(setMusPlayerPlay(false));
  };

  const fetchNextTracks = () => {
    if (!search) fetchTracks(filterBy, false, 80, all.length);
  };

  const usedSpace = () => storage_info.used_mbytes as number;
  const usedStorageValue = () => formatBytes(storage_info.used_mbytes * 1024 * 1024);
  const allStorageValue = () => {
    if (typeof storage_info.all_mbytes === 'string') {
      return storage_info.all_mbytes;
    } else {
      return formatBytes(storage_info.all_mbytes * 1024 * 1024);
    }
  };

  const formatStart = () => moment(current_period_start).format(DATE_FORMAT_SHORT);
  const formatEnd = () => moment(current_period_end).format(DATE_FORMAT_SHORT);

  const startDate = () => (formatStart() === 'Invalid date' ? null : '(' + formatStart());
  const endDate = () => (formatEnd() === 'Invalid date' ? null : '- ' + formatEnd() + ')');

  const active = () =>
    is_active_subscription ? (
      '(Active)'
    ) : (
      <NavLink to="/pricing" className={style.linkNotActive}>
        Not active
      </NavLink>
    );

  const subsCX = cx(style.storageItem, {
    [style.notActive]: !is_active_subscription && usedSpace() > 200,
  });

  return (
    <div className={style.root}>
      <TrackTypeTabs
        onAllClick={onAllClick}
        onMusicClick={onMusicClick}
        onSFXClick={onSFXClick}
        onAudioClick={onAudioClick}
        type={filterBy}
      />
      {is_active_subscription || (!is_active_subscription && usedSpace() < 200) ? (
        <div className={style.dropzone}>
          <Dropzone onAccept={handleUpload} accept={SUPPORTED_FORMATS} className={style.dropzoneItem} />
          {isLoading && (
            <div className={style.loaderDropzone}>
              <LinearLoader completed={progress} />
            </div>
          )}
        </div>
      ) : null}
      <div className={style.subscription}>
        <div className={subsCX}>
          Your subscription is <span className={style.bold}>{`${currentSubscription}`}</span> <span className={style.bold}>{active()}</span>
          {startDate()} {endDate()}
        </div>
        <div className={style.storageItemUsed}>
          Storage used <span className={style.bold}>{usedStorageValue()}</span> of{' '}
          <span className={style.boldRight}>{allStorageValue()}</span>
        </div>
      </div>
      <div className={style.search}>
        <SearchTracks type={filterBy} search={search} onSearchChange={onSearchChange} />
      </div>
      <div className={style.body} id="scrollableDiv">
        {isLoadingTracks && (
          <div className={style.loader}>
            <Loader />
          </div>
        )}
        <InfiniteScroll
          dataLength={all.length}
          next={fetchNextTracks}
          hasMore={true}
          height={'calc(100vh - 400px)'}
          loader={<div />}
          endMessage={
            <>
              {!all.length && (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>Nothing was found</b>
                </p>
              )}
              {!!all.length && (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>---</b>
                </p>
              )}
            </>
          }
          scrollableTarget="scrollableDiv"
        >
          {all.map((track, index) => {
            return <TrackItem track={track} key={track.track_id!} index={index} />;
          })}
        </InfiniteScroll>
      </div>
      <div className={style.player}>
        <MusicianPlayer currentUrl={currentURL || ''} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    all: all$(state),
    currentURL: currentURL$(state),
    progress: progress$(state),
    isLoading: isLoading$(state),
    current_period_end: current_period_end$(state),
    current_period_start: current_period_start$(state),
    currentSubscription: currentSubscription$(state),
    is_active_subscription: is_active_subscription$(state),
    storage_info: storage_info$(state),
    filterBy: filterBy$(state),
    isLoadingTracks: isLoadingTracks$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchTracks: dispatchProps.fetchTracks,
    uploadTrack: dispatchProps.uploadTrack,
    setTrackDuration: dispatchProps.setTrackDuration,
    setFileSizeOfTrack: dispatchProps.setFileSizeOfTrack,
    searchTracks: dispatchProps.searchTracks,
    setPlayAfterSRCChanged: dispatchProps.setPlayAfterSRCChanged,
    fetchCurrentSubscription: dispatchProps.fetchCurrentSubscription,
    setfilterBy: dispatchProps.setfilterBy,
    ...props,
  };
};

export const TrackList = connect(
  mapStateToProps,
  {
    fetchTracks: MusicianTracksCreators.fetchTracks,
    uploadTrack: UploadCreators.uploadTrack,
    setTrackDuration: UploadActions.setTrackDuration,
    setFileSizeOfTrack: UploadActions.setFileSizeOfTrack,
    searchTracks: MusicianTracksCreators.searchTracks,
    setPlayAfterSRCChanged: SongActions.setPlayAfterSRCChanged,
    fetchCurrentSubscription: CurrentSubscriptionCreators.fetchCurrentSubscription,
    setfilterBy: NewTrackActions.setfilterBy,
  },
  mergeProps
)(TrackListComponent);
