import defaultImage from 'assets/img/collaboration.png';
// eslint-disable-next-line
import React from 'react';

interface IProps {
  withTwoNums?: boolean;
  first: number;
  second?: number;
  title: string;
  subTitle?: string;
  image?: string;
}

const StatisticDetails = ({ image, withTwoNums, first, second, title, subTitle }: IProps) => {
  return (
    <div className="stat-detail-container d-flex justify-center align-center flex-column">
      <div className="stat-detail-title">{title}</div>
      <div className="stat-detail-info d-flex align-center">
        <img className="stat-detail-image" src={image ? image : defaultImage} alt="collaboration" loading="lazy" />
        {withTwoNums ? (
          <div className="d-flex align-center stat-detail-with">
            <span className="stat-detail-first">{first}</span>
            <span className="stat-detail-suffix">of</span>
            <span className="stat-detail-second">{second}</span>
          </div>
        ) : (
          <div className="d-flex align-center flex-column align-start stat-detail-with">
            <span className="stat-detail-first">{first}</span>
            <span className="stat-detail-sub">{subTitle}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticDetails;
