import { getCurrentTab } from 'actions/tab';
import cx from 'classnames';
import Aside from 'components/aside/aside';
import InfoPanel from 'components/infoPanel/infoPanel';
import { SwitchTabs } from 'containers/switchTabs/switchTabs';
import { WebsocketHandler } from 'containers/websocketHandler';
import SignUp from 'modules/auth/signUp';
import { Affiliate } from 'pages/affiliate';
import ConfirmEmail from 'pages/confirmEmail/confirmEmail';
import InvitePage from 'pages/invitePage/index';
import CompanyActiveJobs from 'pages/manager/companyActiveJobs';
import CompanyList from 'pages/manager/companyList';
import InviteList from 'pages/manager/inviteList';
import AllJobs from 'pages/manager/jobList';
import { NotFound } from 'pages/notFound/notFound';
import { PrivacyPolicy } from 'pages/privacyPolicy';
import { ProfilePage } from 'pages/profile';
import ResetPassword from 'pages/resetPassword/resetPassword';
import { RoutePage } from 'pages/routePage/routePage';
import { SharedPlaylist } from 'pages/sharedPlaylist';
import { TeamPage } from 'pages/team';
import { TechnicalWorks } from 'pages/technicalWorks';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { currentTab$ } from 'selectors/tab';
import { isNeedToRedirectOnUnknownRoute } from 'utils';
import { CustomRoute } from 'utils/addRoute';
import { privateRoute, publicRoute } from 'utils/routes';

import style from './manager.module.scss';

const allRoutes = [
  publicRoute.confirm,
  privateRoute.managerPrefix + privateRoute.manager.jobList,
  privateRoute.managerPrefix + privateRoute.manager.companyList,
  privateRoute.managerPrefix + privateRoute.manager.activeJobs,
  privateRoute.managerPrefix + privateRoute.manager.inviteList,
  publicRoute.registerFromCompanyInvite,
  publicRoute.shareablePlaylist,
  publicRoute.shareableApplication,
  privateRoute.team,
  publicRoute.confirmChangeEmail,
  publicRoute.confirmNewEmail,
  privateRoute.affiliate,
  publicRoute.signUp,
  publicRoute.privacyPolicy,
  privateRoute.profile,
  privateRoute.main,
  publicRoute.resetPass,
];

const ManagerModuleComponent = () => {
  const currentTab = useSelector(currentTab$);
  const dispatch = useDispatch();
  const history = useHistory();

  const isShowManagerInfo = useCallback(() => {
    const routes = [
      '/',
      '/manager/job/list',
      '/manager/company/list',
      '/manager/jobs/active',
      '/manager/invite/list',
      '/affiliate',
      '/team',
      '/privacy-policy',
      '/profile',
      '/technical-works',
    ];

    return routes.includes(history.location.pathname);

    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    if (isNeedToRedirectOnUnknownRoute(allRoutes, history.location.pathname)) {
      history.push('/404');
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const handleChangeTab = useCallback(() => {
    if (history.location.pathname === '/manager/job/list' || history.location.pathname === '/') {
      dispatch(getCurrentTab(0));
    } else if (history.location.pathname === '/manager/jobs/active') {
      dispatch(getCurrentTab(1));
    } else if (history.location.pathname === '/manager/company/list') {
      dispatch(getCurrentTab(2));
    } else if (history.location.pathname === '/manager/invite/list') {
      dispatch(getCurrentTab(3));
    } else if (history.location.pathname === '/affiliate') {
      dispatch(getCurrentTab(4));
    } else if (history.location.pathname === '/team') {
      dispatch(getCurrentTab(5));
    }

    // eslint-disable-next-line
  }, [history.location.pathname]);

  // eslint-disable-next-line
  useEffect(() => handleChangeTab(), [getCurrentTab, history.location.pathname]);

  const mainCXCompany = cx({
    [style.main]: isShowManagerInfo() === true,
    [style.mainFullWidth]: isShowManagerInfo() === false,
  });

  return (
    <WebsocketHandler>
      <div className="d-flex">
        {isShowManagerInfo() && <Aside tabs={<SwitchTabs />} />}
        <main className={mainCXCompany}>
          {isShowManagerInfo() && <InfoPanel isShowPopupsButtons={currentTab === 0 || currentTab === 2 || currentTab === 5} />}

          <Switch>
            <CustomRoute path={publicRoute.confirm} component={<ConfirmEmail />} />
            <CustomRoute
              path={privateRoute.managerPrefix + privateRoute.manager.jobList}
              component={<RoutePage component={<AllJobs />} />}
            />
            <CustomRoute
              path={privateRoute.managerPrefix + privateRoute.manager.companyList}
              component={<RoutePage component={<CompanyList />} />}
            />
            <CustomRoute
              path={privateRoute.managerPrefix + privateRoute.manager.activeJobs}
              component={<RoutePage component={<CompanyActiveJobs isHideCompanySelect isAdmin={false} />} />}
            />
            <CustomRoute
              path={privateRoute.managerPrefix + privateRoute.manager.inviteList}
              component={<RoutePage component={<InviteList />} />}
            />
            <CustomRoute path={publicRoute.registerFromCompanyInvite} component={<InvitePage />} />
            <CustomRoute path={publicRoute.shareablePlaylist} component={<SharedPlaylist />} />
            <CustomRoute path={publicRoute.shareableApplication} component={<SharedPlaylist isMusicianPlaylist />} />
            <CustomRoute path={privateRoute.team} component={<TeamPage />} />
            <Route exact path={publicRoute.confirmChangeEmail}>
              <ConfirmEmail isChange />
            </Route>
            <Route exact path={publicRoute.confirmNewEmail}>
              <ConfirmEmail isNew />
            </Route>
            <CustomRoute path={privateRoute.affiliate} component={<Affiliate isCompany />} />
            <CustomRoute path={publicRoute.signUp} component={<SignUp />} />
            <CustomRoute path={publicRoute.privacyPolicy} component={<PrivacyPolicy />} />
            <CustomRoute path={privateRoute.profile} component={<ProfilePage />} />
            <CustomRoute path={publicRoute.resetPass} component={<ResetPassword />} />
            <CustomRoute exact={false} path={privateRoute.main} component={<RoutePage component={<AllJobs />} />} />
            <CustomRoute exact path={publicRoute.technicalWork} component={<TechnicalWorks />} />
            <Route path="/404">
              <NotFound />
            </Route>
          </Switch>
        </main>
      </div>
    </WebsocketHandler>
  );
};

export const ManagerModule = memo(ManagerModuleComponent);
