import 'sweetalert2/src/sweetalert2.scss';

import * as SharedPlayerActions from 'actions/sharedPlayer';
import axios from 'axios';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const downloadSharedTrack = (track_id: number, title: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SharedPlayerActions.setSharedPlayerPending());
    axios
      .post(`${Api.GetTrackURL}`, {
        track_id,
      })
      .then(response => {
        if (response.data.result.url) {
          axios({
            url: response.data.result.url!,
            method: 'GET',
            responseType: 'blob',
            // withCredentials: true,
            // withCredentials: false,
            // headers: {

            //   'Access-Control-Allow-Origin': 'https://smash.haus',
            //   'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
            // },
            headers: {
              'Content-Disposition': `attachment; filename="${encodeURI(title)}.mp3"`,
              // 'Access-Control-Allow-Origin': '*',
              // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            },
            onDownloadProgress: p => {
              const total = Number(((p.loaded / p.total) * 100).toFixed(1));

              dispatch(SharedPlayerActions.setSharedPlayerProgressDownload(track_id, total));
              if (p.loaded / p.total === 1) {
                successNotification(`Downloaded track ${title}`, 8000);
              }
            },
          })
            .then(response => {
              dispatch(SharedPlayerActions.setSharedPlayerSuccess());
              // dispatch(SiteEventsCreators.sendEvent('upload') as any);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${title}.mp3`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            })
            .catch(() => {
              dispatch(SharedPlayerActions.setSharedPlayerError());
            });
        } else {
          errorNotification('There is no url in the server response');
        }
      });
  };
};

export const fetchTrackUrl = (track_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SharedPlayerActions.setSharedPlayerPending());

    axios
      .post(`${Api.GetTrackURL}`, {
        track_id,
      })
      .then(response => {
        if (response.data.result.url) {
          dispatch(SharedPlayerActions.setSharedPlayerUrl(track_id, true, response.data.result.url));
        } else {
          errorNotification('There is no url in the server response');
        }
      })
      .catch(error => catchedErrorNotification(error));
  };
};
