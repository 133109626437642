import './style.scss';

import { MusicianPlayer } from 'components/musicianPlayer/musicianPlayer';
import MusicianTrackItem from 'components/musicianTrackItem/musicianTrackItem';
import Upload from 'components/upload/upload';
import UploadNewTrack from 'containers/uploadNewTrack/uploadNewTrack';
import * as CurrentSubscrCreators from 'creators/currentSubscription';
import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import { currentURL$, titlePlayer$ } from 'selectors/musicianPlayer';
import { allLoadedTracks$ } from 'selectors/newTrack';
import { autoPlayAfterSrcChange$ } from 'selectors/song';

interface IStateProps {
  allLoadedTracks: INewTrack[];
  titlePlayer: string;
  currentURL: string | null;
  autoPlayAfterSrcChange: boolean;
}

interface IDispatchProps {
  fetchCurrentSubscription: () => void;
}

interface OwnProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const MusicianTracks = ({ allLoadedTracks, titlePlayer, currentURL, autoPlayAfterSrcChange, fetchCurrentSubscription }: IProps) => {
  const [isShowUpload, setShowUpload] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // eslint-disable-next-line
  useEffect(() => fetchCurrentSubscription(), []);

  return (
    <section
      style={{
        position: 'relative',
      }}
      className="musician-content d-flex flex-column show-animation"
    >
      <UploadNewTrack />
      <MusicianTrackItem
        current={currentIndex}
        ind={0}
        setCurrent={setCurrentIndex}
        tracks={allLoadedTracks}
        title="Upload either 'Music', 'Sound Design', or 'Dialog'"
        subtitle="Music = Songs, Cues/SOUND DESIGN - Sounds, Sound FX, Hits, Impacts, Swells.../DIALOG = Human Voice"
      />
      {isShowUpload && <Upload setShowUpload={setShowUpload} />}

      <MusicianPlayer title={titlePlayer} currentURL={currentURL} autoPlayAfterSrcChange={autoPlayAfterSrcChange} />
    </section>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    allLoadedTracks: allLoadedTracks$(state),
    titlePlayer: titlePlayer$(state),
    currentURL: currentURL$(state),
    autoPlayAfterSrcChange: autoPlayAfterSrcChange$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchCurrentSubscription: dispatchProps.fetchCurrentSubscription,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCurrentSubscription: CurrentSubscrCreators.fetchCurrentSubscription,
  },
  mergeProps
)(memo(MusicianTracks));
