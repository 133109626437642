import * as AffiliateCreators from 'creators/affiliate';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isAffiliate$ } from 'selectors/auth';

import { DashboardLink } from '../dashboardLink';
import { PaypalEmail } from '../paypalEmail';
import style from './body.module.scss';

interface IStateProps {
  isAffiliate: boolean;
}

interface IDispatchProps {
  getAffiliateData: () => void;
}
interface OwnProps {}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const BodyComponent = ({ isAffiliate, getAffiliateData }: IProps) => {
  useEffect(() => {
    if (isAffiliate) {
      getAffiliateData();
    }
  }, [isAffiliate, getAffiliateData]);

  return <div className={style.root}>{isAffiliate ? <DashboardLink /> : <PaypalEmail />}</div>;
};

const mapStateToProps = (state: State) => {
  return {
    isAffiliate: isAffiliate$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    getAffiliateData: dispatchProps.getAffiliateData,
    ...props,
  };
};

export const Body = connect(
  mapStateToProps,
  {
    getAffiliateData: AffiliateCreators.getAffiliateData,
  },
  mergeProps
)(BodyComponent);
