// eslint-disable-next-line
import React from 'react';

import style from './affiliate.module.scss';
import { Body } from './components/body';
import { Header } from './components/header';

interface IProps {
  isCompany?: boolean;
}
export const Affiliate = ({ isCompany }: IProps) => {
  return (
    <div className={style.root}>
      <Header isCompany={isCompany} />
      <Body />
    </div>
  );
};
