import 'sweetalert2/src/sweetalert2.scss';

import * as ReceivePassActions from 'actions/receivePass';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const receivePass = (email: string, token: string, password: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ReceivePassActions.setReceivePassPending());
    const tokenWithoutSpace = token.replace(/\s/g, '');
    const emailWithoutSpace = email.replace(/\s/g, '');

    axios
      .post(Api.ReceivePass, {
        token: tokenWithoutSpace,
        email: emailWithoutSpace,
        pass: password,
      })
      .then(response => {
        dispatch(ReceivePassActions.setReceivePassSuccess());
        dispatch(ReceivePassActions.setIsSuccessReceivePass(true));
        console.info(response);
      })
      .catch(error => {
        dispatch(ReceivePassActions.setReceivePassFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
