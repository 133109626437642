import axios from 'axios';
import cx from 'classnames';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { fetchStatistics } from 'creators/statistics';
import { Api } from 'enums/api';
import moment from 'moment';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeUsers$, isLoading$, logInStatistic$, songsSubmittedDiagramMonthly$, visitorDiagramMonthly$ } from 'selectors/statistics';
import { getMaxOfArray } from 'utils';

import { ChartItem } from '../chart';
import { ChartButtons } from '../chartButtons';
import style from './charts.module.scss';

const ChartsItem = () => {
  const [currId, setCurrId] = useState(0);
  const [visitors, setVisitors] = useState([] as any[]);
  const [submittedSongs, setSubmittedSongs] = useState([] as any[]);
  const [activity, setActivity] = useState([] as any[]);
  const [logins, setLogins] = useState([] as any[]);
  const activeUsers = useSelector(activeUsers$);
  const logInStatistic = useSelector(logInStatistic$);
  const isLoading = useSelector(isLoading$);
  const visitorDiagramMonthly = useSelector(visitorDiagramMonthly$);
  const songsSubmittedDiagramMonthly = useSelector(songsSubmittedDiagramMonthly$);
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState<ISelectItem | undefined>();
  const [month, setMonth] = useState<number | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [availableDates, setAvailableDates] = useState<{ year: number; month: number }[]>([]);
  const [isDatesLoading, setDatesLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState<ISelectItem[]>([]);

  const fetchAvailableDates = async () => {
    setDatesLoading(true);
    await axios
      .post(Api.GetChartDates)
      .then(resp => setAvailableDates(resp.data.result))
      .catch(err => console.error(err));
    setDatesLoading(false);
  };

  useEffect(() => {
    if (availableDates.length && month === null && year === null) {
      const options = availableDates.map((d, i) => ({
        label: moment(`${d.month}-01-${d.year}`).format('MMM YYYY'),
        value: i,
      }));
      if (options?.length) {
        setSelectOptions(options);
        setCurrentDate({ label: options[0].label, value: 0 });
        setMonth(availableDates[0].month);
        setYear(availableDates[0].year);
      }
    }
  }, [availableDates, month, year]);

  useEffect(() => {
    fetchAvailableDates();
  }, []);

  useEffect(() => {
    if (month !== null && year !== null) {
      dispatch(fetchStatistics(year, month));
    }
    // eslint-disable-next-line
  }, [month, year]);

  useEffect(() => {
    const addedPart = ['Date', 'Visitors'];
    if (visitorDiagramMonthly?.length && currId === 0) {
      setVisitors([addedPart, ...visitorDiagramMonthly]);
    } else {
      setVisitors([addedPart]);
    }

    return () => setVisitors([]);
  }, [visitorDiagramMonthly, currId]);

  useEffect(() => {
    const addedPart = ['Date', 'Submitted Songs'];
    if (songsSubmittedDiagramMonthly?.length && currId === 1) {
      setSubmittedSongs([addedPart, ...songsSubmittedDiagramMonthly]);
    } else {
      setSubmittedSongs([addedPart, ['2022-03-15', 0]]);
    }
    return () => setSubmittedSongs([]);
  }, [songsSubmittedDiagramMonthly, currId]);

  useEffect(() => {
    const addedPart = ['Date', 'User activity'];
    if (activeUsers?.length && currId === 2) {
      setActivity([addedPart, ...activeUsers]);
    } else {
      setActivity([addedPart, ['2022-03-15', 0]]);
    }
    return () => setActivity([]);
  }, [activeUsers, currId]);

  useEffect(() => {
    const addedPart = ['Date', 'Logins'];
    if (logInStatistic?.length && currId === 3) {
      setLogins([addedPart, ...logInStatistic]);
    } else {
      setLogins([addedPart, ['2022-03-15', 0]]);
    }
    return () => setLogins([]);
  }, [logInStatistic, currId]);

  const handleChangeDate = (value: ISelectItem) => {
    setCurrentDate(value);
    setMonth(availableDates[value.value].month);
    setYear(availableDates[value.value].year);
  };

  const visitorTicks = useCallback(() => {
    if (!visitorDiagramMonthly.length) {
      return;
    } else {
      const visitorCounts = visitorDiagramMonthly.map((visitorData: number[]) => visitorData[1]);

      const maxVisitorNumber = getMaxOfArray(visitorCounts);

      const result = [...Array(maxVisitorNumber + 5).keys()];
      return result?.length > 10
        ? result.filter(function (_, i) {
            return (i + 1) % 2;
          })
        : result.filter(function (_, i) {
            return (i + 1) % 4 && (i + 1) % 3;
          });
    }
  }, [visitorDiagramMonthly]);

  const activityTicks = useCallback(() => {
    if (!activeUsers.length) {
      return;
    } else {
      const activityCounts = activeUsers.map((active: number[]) => active[1]);
      const maxActivityNumber = getMaxOfArray(activityCounts);
      const result = [...Array(maxActivityNumber + 5).keys()];
      return result?.length > 10
        ? result.filter(function (_, i) {
            return (i + 1) % 2;
          })
        : result.filter(function (_, i) {
            return (i + 1) % 4 && (i + 1) % 3;
          });
    }
  }, [activeUsers]);

  const loginTicks = useCallback(() => {
    if (!logInStatistic.length) {
      return;
    } else {
      const loginCounts = logInStatistic.map((active: number[]) => active[1]);
      const maxLoginNumber = getMaxOfArray(loginCounts);
      const result = [...Array(maxLoginNumber + 5).keys()];
      return result?.length > 10
        ? result.filter(function (_, i) {
            return (i + 1) % 2;
          })
        : result.filter(function (_, i) {
            return (i + 1) % 4 && (i + 1) % 3;
          });
    }
  }, [logInStatistic]);

  const songsTicks = useCallback(() => {
    if (!songsSubmittedDiagramMonthly.length) {
      return;
    } else {
      const songsCounts = songsSubmittedDiagramMonthly.map((visitorData: number[]) => visitorData[1]);

      const maxSongsNumber = getMaxOfArray(songsCounts);

      const result = [...Array(maxSongsNumber + 5).keys()];
      return result?.length > 10
        ? result.filter(function (_, i) {
            return (i + 1) % 4;
          })
        : result.filter(function (_, i) {
            return (i + 1) % 2;
          });
    }
  }, [songsSubmittedDiagramMonthly]);

  const chartsById = () => {
    return {
      0: (
        <ChartItem
          data={visitors}
          title={`Visitors per month (${moment(`${month}-01-${year}`).format('MMM YYYY')})`}
          isShowData={currId === 0}
          vAxisTitle={'Visitors'}
          hAxisTitle={'Date (number of the day in the month)'}
          ticks={visitorTicks()}
          year={`${moment(`${month}-01-${year}`).format('YYYY')}`}
          month={`${moment(`${month}-01-${year}`).format('MMM')}`}
        />
      ),
      1: (
        <ChartItem
          data={submittedSongs}
          title={`Song submitted per month (${moment(`${month}-01-${year}`).format('MMM YYYY')})`}
          isShowData={currId === 1}
          vAxisTitle={'Songs submitted'}
          hAxisTitle={'Date (number of the day in the month)'}
          ticks={songsTicks()}
          year={`${moment(`${month}-01-${year}`).format('YYYY')}`}
          month={`${moment(`${month}-01-${year}`).format('MMM')}`}
        />
      ),

      2: (
        <ChartItem
          data={activity}
          title={`User activity per month (${moment(`${month}-01-${year}`).format('MMM YYYY')})`}
          isShowData={currId === 2}
          vAxisTitle={'User activity'}
          hAxisTitle={'Date (number of the day in the month)'}
          ticks={activityTicks()}
          year={`${moment(`${month}-01-${year}`).format('YYYY')}`}
          month={`${moment(`${month}-01-${year}`).format('MMM')}`}
        />
      ),
      3: (
        <ChartItem
          data={logins}
          title={`User logins per month (${moment(`${month}-01-${year}`).format('MMM YYYY')})`}
          isShowData={currId === 3}
          vAxisTitle={'User logins'}
          hAxisTitle={'Date (number of the day in the month)'}
          ticks={loginTicks()}
          year={`${moment(`${month}-01-${year}`).format('YYYY')}`}
          month={`${moment(`${month}-01-${year}`).format('MMM')}`}
        />
      ),
    };
  };

  const chartsCX = cx(style.charts, {
    [style.hidden]: isLoading,
  });

  const loaderCX = cx(style.loader, {
    [style.hidden]: !isLoading,
  });

  return (
    <div className={style.root}>
      <h2 className={style.head}>Charts</h2>
      <div className={'d-flex align-center'}>
        <MaterialSelect
          value={currentDate}
          placeholder="Select Period"
          selectItems={selectOptions}
          onChangeAllValue={handleChangeDate}
          isAllValueNeed
          className={style.select}
          width={'auto'}
          isLoading={isDatesLoading}
        />
        <ChartButtons currentBtnId={currId} setCurrentId={setCurrId} month={month} year={year} />
      </div>
      <div className={chartsCX}>{chartsById()[currId]}</div>
      <div className={loaderCX}>Loading charts. Please wait!</div>
    </div>
  );
};

export const Charts = memo(ChartsItem);
