import { IAppTrack } from 'interfaces/IApp';
import { INewTrack } from 'interfaces/INewTrack';
import unique from 'lodash-es/uniq';

import * as Const from '../constants/index';
import State from '../interfaces/state/NewTracksState';

const initState: State = {
  all: [],
  tracks: [],
  isLoading: false,
  isError: false,
  currentTitle: '',
  currentId: 0,
  currentWriter: '',
  currentDuration: 0,
  orderBy: 'rating',
  sortType: 'asc',
  track_id: null,
  selected_tracks: [],
  tracks_for_playlist: [],
  currName: '',
  currDescr: '',
  currTags: '',
  filterBy: 'all',
  downloadProgress: {},
  currentPlaylistId: null,
  share_type: null,
  amount: 0,
  isNewTracksAppeared: false,
  submittedSongs: 0,
};

export default function newTracks(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.NEW_TRACKS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
        currentPlaylistId: state.currentPlaylistId,
        share_type: state.share_type,
        orderBy: state.orderBy,
        sortType: state.sortType,
      };

    case Const.NEW_TRACKS_Q_FILTER_INIT:
      return {
        ...state,
        all: [],
        tracks: [],
      };

    case Const.NEW_TRACKS_SUCCESS:
      return {
        ...state,
        tracks: action.payload,
        isError: false,
        isLoading: false,
      };

    case Const.NEW_TRACKS_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };

    case Const.NEW_TRACKS_SUBMITTED_SONGS:
      return {
        ...state,
        submittedSongs: action.payload,
      };

    case Const.NEW_TRACKS_ALL_ADD_LIST:
      return {
        ...state,
        all: [...state.all, ...action.payload],
        isError: false,
        isLoading: false,
      };

    case Const.NEW_TRACKS_ALL_ADD:
      return {
        ...state,
        all: action.payload,
        isError: false,
        isLoading: false,
      };

    case Const.NEW_TRACKS_PENDING:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };

    case Const.NEW_TRACKS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.NEW_TRACKS_SUCCESS_WITH_LIMIT:
      return {
        ...state,
        tracks: [...state.tracks, ...action.payload],
      };

    case Const.EDIT_AND_SAVE_NEW_ITEM:
      if (action.isEdit) {
        const trackIndex = state.all.findIndex(item => item.track_id === action.payload.track_id);

        if (trackIndex >= 0) {
          const alltracks = [...state.all];
          alltracks[trackIndex] = { ...alltracks[trackIndex], ...action.payload };
          return {
            ...state,
            all: alltracks,
          };
        } else {
          return { ...state };
        }
      } else {
        return {
          ...state,
          all: [action.payload, ...state.all],
        };
      }

    case Const.DELETE_TRACK_FROM_USER_TRACKLIST:
      return {
        ...state,
        all: state.all.filter(track => track.track_id !== action.payload),
      };

    case Const.TRACKS_CHANGE_ITEM:
      const ind = state.tracks.findIndex(track => track.track_id === action.payload.track_id);
      if (ind < 0) {
        return { ...state };
      } else {
        const list = [...state.tracks];
        list[ind] = action.payload;
        return {
          ...state,
          tracks: list,
        };
      }

    case Const.CURRENT_TRACK_INFO:
      return {
        ...state,
        currentTitle: action.title,
        currentId: action.id,
        currentDuration: action.duration,
      };

    case Const.TRACK_CURRENT_ID:
      return {
        ...state,
        currentId: action.payload,
      };

    case Const.CURRENT_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
      };

    case Const.CURRENT_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload,
      };

    case Const.FETCH_TRACK_ID:
      return {
        ...state,
        track_id: action.payload,
      };

    case Const.SELECT_TRACKS:
      return {
        ...state,
        selected_tracks: state.selected_tracks
          ? unique([...state.selected_tracks, action.payload]).sort((a: number, b: number) => a - b)
          : unique([action.payload]).sort((a: number, b: number) => a - b),
      };

    case Const.DEL_SELECT_TRACKS:
      return {
        ...state,
        selected_tracks: state.selected_tracks
          ? unique(state.selected_tracks.filter(track => track !== action.payload)).sort((a: number, b: number) => a - b)
          : null,
      };

    case Const.NEW_TRACKS_FOR_PLAYLIST:
      const payload = action.payload;
      if (payload && payload?.length) {
        const ids: number[] | null = action.payload.map((track: INewTrack) => track.track_id);
        return {
          ...state,
          tracks_for_playlist: action.payload,
          selected_tracks: unique(ids).sort((a: number, b: number) => a - b),
        };
      } else {
        return {
          ...state,
          tracks_for_playlist: [],
          selected_tracks: [],
        };
      }

    case Const.NEW_TRACKS_CURR_NAME:
      return {
        ...state,
        currName: action.payload,
      };

    case Const.NEW_TRACKS_CURR_DESC:
      return {
        ...state,
        currDescr: action.payload,
      };

    case Const.NEW_TRACKS_CURR_TAGS:
      return {
        ...state,
        currTags: action.payload,
      };

    case Const.NEW_TRACKS_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.DEFAULT_SELECTED_TRACKS:
      return {
        ...state,
        selected_tracks: [],
      };

    case Const.ADD_TRACK_FOR_PLAYLIST:
      const selectedId: number = action.payload.track_id;

      return {
        ...state,
        tracks_for_playlist: [action.payload, ...state.tracks_for_playlist],
        selected_tracks: unique([selectedId, ...state.selected_tracks]).sort((a: number, b: number) => a - b),
      };

    case Const.DEL_TRACK_FROM_PLAYLIST:
      const tracks = state.tracks_for_playlist.filter(track => track.track_id !== action.payload);
      const selectedTrackIds = tracks.map((track: INewTrack) => track.track_id!);
      return {
        ...state,
        tracks_for_playlist: tracks,
        selected_tracks: unique(selectedTrackIds).sort((a: number, b: number) => a - b),
      };

    case Const.DELETE_ALL_TRACKS_FROM_PLAYLIST:
      return {
        ...state,
        tracks_for_playlist: [],
        selected_tracks: [],
      };

    case Const.CHANGE_CURRENT_INDEX_RATING:
      let newTracks = [...state.tracks];
      let index = newTracks.findIndex(item => item.track_id === action.id);
      if (index < 0) {
        return { ...state };
      }
      newTracks[index] = { ...state.tracks[index], rating: action.payload };
      return {
        ...state,
        tracks: newTracks,
      };

    case Const.DELETE_TRACK_FROM_MUSICIAN: {
      return {
        ...state,
        all: state.all.filter(item => item.track_id !== action.id),
      };
    }

    case Const.EDIT_TRACK_FROM_MUSICIAN: {
      const editedTrack = { ...state[action.trackType as 'music' | 'sfx' | 'audio'][action.index], [action.key]: action.value };
      const tracks = [...state[action.trackType]];
      tracks[action.index] = editedTrack;
      return {
        ...state,
        [action.trackType]: tracks,
      };
    }

    case Const.DELETE_TRACKS_FROM_ALL_TRACKS: {
      return {
        ...state,
        all: [],
      };
    }

    case Const.SET_NEW_TRACK_FILTER_BY: {
      return {
        ...state,
        filterBy: action.payload,
      };
    }

    case Const.SET_DOWNLOAD_PROGRESS:
      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [action.id]: {
            loaded: action.loaded,
            total: action.total,
          },
        },
      };

    case Const.SET_CURRENT_PLAYLIST_ID_FOR_SHARED:
      return {
        ...state,
        currentPlaylistId: action.payload,
      };

    case Const.SHARE_TYPE:
      return {
        ...state,
        share_type: action.payload,
      };

    case Const.CHANGE_PLAYLIST_TRACK_RATING:
      const playlistIndex = state.tracks_for_playlist.findIndex(track => track.track_id === action.id);
      if (playlistIndex < 0) {
        return { ...state };
      } else {
        const newPlaylist = [...state.tracks_for_playlist];
        newPlaylist[playlistIndex].rating = action.payload;
        return {
          ...state,
          tracks_for_playlist: newPlaylist,
        };
      }

    case Const.NEW_TRACKS_APPEARED:
      return {
        ...state,
        isNewTracksAppeared: action.payload,
      };

    case Const.EDIT_TRACK:
      const newTrackList = [...state.tracks];
      const trackIndex = newTrackList.findIndex(track => track.track_id === action.payload.track_id);

      if (trackIndex < 0) {
        return { ...state };
      }

      newTrackList[trackIndex][action.payload.key] = action.payload.value;

      return {
        ...state,
        tracks: newTrackList,
      };

    case Const.CREATOR_PLAYLIST_TRACKS:
      const playlistTracks = action.payload;
      const playlistTracksIds = playlistTracks.map((track: IAppTrack) => track.track_id);
      const allFilteredTracks = [...state.all].filter((track: INewTrack) => playlistTracksIds.includes(track.track_id!));

      return {
        ...state,
        selected_tracks: playlistTracksIds.sort((a: number, b: number) => a - b),
        tracks_for_playlist: allFilteredTracks,
      };

    case Const.CREATOR_SET_SELECTED_TRACKS:
      return {
        ...state,
        selected_tracks: action.payload,
      };

    default:
      return state;
  }
}
