import 'sweetalert2/src/sweetalert2.scss';

import * as NewTrackActions from 'actions/newTracks';
import * as TrackInfoActions from 'actions/trackInfo';
import * as TrackInformationActions from 'actions/trackInformation';
import * as UploadTrackAction from 'actions/uploadTrack';
import axios from 'axios';
import * as CurrentSubscrCreators from 'creators/currentSubscription';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import * as musicMetadata from 'music-metadata-browser';
import { Action } from 'redux';
import { format$ } from 'selectors/trackInformation';
import { catchedErrorNotification } from 'utils/error';

export const uploadTrack = (file: File) => {
  if (!file) return;

  return (dispatch: (action: Action) => void, getState: () => State) => {
    let track_duration = 0;
    musicMetadata.parseBlob(file).then(metadata => {
      // metadata has all the metadata found in the blob or file
      dispatch(TrackInformationActions.setTrackInformationFormat(metadata));
      track_duration = Number(metadata.format.duration?.toFixed(0));
    });
    dispatch(NewTrackActions.fetchTrackId(null));
    dispatch(UploadTrackAction.setUploadTrackPending());
    dispatch(TrackInformationActions.setTrackInformationFileName(file.name));
    let formData = new FormData();
    formData.append('inputfile', file);

    axios
      .post(`${Api.UploadTrack}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: p => {
          console.log((p.loaded / p.total) * 100);
          dispatch(UploadTrackAction.setProgress((p.loaded / p.total) * 100));
        },
      })
      .then(response => {
        const newTrackId = response.data.result.track_id;
        if (newTrackId) {
          dispatch(NewTrackActions.fetchTrackId(newTrackId));

          axios.post(`${Api.SetTrackInfo}`, {
            artist_name: 'Unknown',
            track_id: newTrackId,
            title: 'Unknown',
            type: 'music',
            description: 'Unknown',
            tags: '#empty',
            writers: [],
            publishers: [],
            track_duration: track_duration,
            file_size: file.size,
            file_name: file.name,
            format: format$(getState())?.format,
            master_owners: [],
          });
        }
        dispatch(TrackInfoActions.setOpenUploadPopup(true));
        dispatch(TrackInfoActions.setTarckInfoShowPanel(true));
        dispatch(UploadTrackAction.setUploadTrackSuccess());
        dispatch(UploadTrackAction.setProgress(0));
      })
      .then(() => {
        dispatch(CurrentSubscrCreators.fetchCurrentSubscription() as any);
      })
      .catch(error => {
        console.error(error);
        dispatch(UploadTrackAction.setUploadTrackFail());
        dispatch(UploadTrackAction.setProgress(0));

        catchedErrorNotification(error);
      });
  };
};
