import * as Const from '../constants';
import State from '../interfaces/state/SussessPaymentState';

const initState: State = {
  isLoading: false,
  isError: false,
  portal_session_id: null,
  errorText: null,
};

export default function successPayment(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.PAYMENT_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.PAYMENT_SUCCESS:
      return {
        ...state,
        portal_session_id: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.PAYMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.PAYMENT_SET_TEXT:
      return {
        ...state,
        errorText: action.payload,
      };

    default:
      return state;
  }
}
