import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import cx from 'classnames';
import { successNotification } from 'components/successNotification/successNotification';
import { ShareableLink } from 'interfaces/state/shareablePlaylistLinksState';
// eslint-disable-next-line
import React, { ClipboardEvent, useState } from 'react';

import style from './playlistLinks.module.scss';

interface OwnProps {
  list: ShareableLink[];
}

interface IProps extends OwnProps {}

export const PlaylistLinks = ({ list }: IProps) => {
  const [isCopied, setCopied] = useState(false);
  const [currentId, setCurrentId] = useState(null as number | null);

  const handleCopyToClipboard = (e: ClipboardEvent<HTMLDivElement>, text: string) => {
    e.preventDefault();
    e.clipboardData.setData('text/plain', text);
  };

  const onCopy = (link: ShareableLink) => {
    if (navigator) {
      setCurrentId(link.id);
      navigator.clipboard.writeText(link.link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      successNotification(`Link ${link.link} was copied successfully!`);
    }
  };

  return (
    <div className={style.root}>
      {list.map(link => {
        return (
          <div className={style.link} key={link.id}>
            <div
              style={{ cursor: 'pointer' }}
              className={style.iconContainer}
              onCopy={(e: ClipboardEvent<HTMLDivElement>) => handleCopyToClipboard(e, link.link)}
              onClick={() => onCopy(link)}
            >
              <Tooltip title={navigator ? 'Copy to clipboard link' : ''}>
                <span className={style.iconWrapper}>
                  <LinkRoundedIcon
                    className={cx(style.icon, {
                      [style.iconSelected]: isCopied && currentId === link.id,
                    })}
                  />
                </span>
              </Tooltip>
            </div>
            <input type="text" className={style.input} value={link.link} />
            {link.allowed_all_tracks ? (
              <Tooltip title="Allowed to download all tracks">
                <span style={{ marginLeft: 10 }}>
                  <div className={style.iconContainer}>
                    <CheckCircleRoundedIcon className={style.iconSelected} />
                  </div>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Not allowed to download all tracks">
                <span style={{ marginLeft: 10 }}>
                  <div className={style.iconContainer}>
                    <CheckCircleRoundedIcon className={style.iconDisabled} />
                  </div>
                </span>
              </Tooltip>
            )}
            {link.allowed_single_track ? (
              <Tooltip title="Allowed to download separately tracks">
                <span>
                  <div className={style.iconContainer}>
                    <CheckCircleRoundedIcon className={style.iconSelected} />
                  </div>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Not allowed to download separately tracks">
                <span>
                  <div className={style.iconContainer}>
                    <CheckCircleRoundedIcon className={style.iconDisabled} />
                  </div>
                </span>
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};
