import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { currentTrack$ } from 'selectors/sharedPlayer';

import style from './audioInformation.module.scss';

interface IStateProps {
  currentTrack: INewTrack | null;
}
interface OwnProps {}
interface IDispatchProps {}
interface IProps extends OwnProps, IDispatchProps, IStateProps {}

const AudioInformation = ({ currentTrack }: IProps) => {
  return (
    <div className={style.root}>
      <div className={style.title} title={currentTrack?.title ? currentTrack!.title : ''}>
        {currentTrack?.title}
      </div>
      {currentTrack?.description ? <div className={style.dash}>-</div> : null}
      <div className={style.description} title={currentTrack?.description ? currentTrack!.description : ''}>
        {currentTrack?.description}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    currentTrack: currentTrack$(state),
  };
};

export default connect(mapStateToProps)(memo(AudioInformation));
