import * as Const from '../constants';
import State from '../interfaces/state/RatingState';

const initState: State = {
  rating: 0,
  fromPulled: null,
  from: 0,
  to: 10,
  track_id: null,
  isLoading: false,
  isError: false,
};

export default function rating(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.RATING_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.RATING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.RATING_RECEIVE:
      return {
        ...state,
        rating: action.payload,
      };

    case Const.RATING_TRACK_RECEIVE_SUCCESS:
      return {
        ...state,
        track_id: action.payload,
      };

    case Const.RATING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.RATING_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.RATING_FR_TO:
      return {
        ...state,
        from: action.from,
        to: action.to,
      };

    case Const.RATING_PULLED:
      return {
        ...state,
        fromPulled: action.payload,
      };

    default:
      return state;
  }
}
