import State from 'interfaces/state/State';

export const isLoading$ = (state: State) => state.statistics.isLoading;
export const isError$ = (state: State) => state.statistics.isError;
export const closedJobs$ = (state: State) => state.statistics.closed_jobs;
export const openJobs$ = (state: State) => state.statistics.open_jobs;
export const songsSubmittedDiagramMonthly$ = (state: State) => state.statistics.songs_submitted_diagram_for_month;
export const visitorDiagramMonthly$ = (state: State) => state.statistics.visitors_diagram_for_month;
export const songsSubmittedAllTime$ = (state: State) => state.statistics.songs_submitted_for_all_time;
export const users$ = (state: State) => state.statistics.users;
export const activeUsers$ = (state: State) => state.statistics.active_users;
export const logInStatistic$ = (state: State) => state.statistics.log_in_statistic;
export const uploadedSongs$ = (state: State) => state.statistics.uploaded_songs;
