import { setCouponPopup } from 'actions/auth';
import cx from 'classnames';
import { CheckboxMaterial } from 'components/checkboxMaterial/checkboxMaterial';
import { InputPopup } from 'components/InputPopup';
import { Loader } from 'components/loader/loader';
import Popup from 'components/popup/popup';
import { updateSubscription } from 'creators/billing';
// eslint-disable-next-line
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isOpenCouponPopup$, subscription$ } from 'selectors/auth';
import { isLoading$ } from 'selectors/billing';
import { interval$, is_active_subscription$, stripe_month_price_id$, stripe_year_price_id$ } from 'selectors/currentSubscription';
import { selectedSubscriptionId$ } from 'selectors/subscriptionList';

import { Header } from './components/header';
import { PlanList } from './components/planList';
import { PriceSwitch } from './components/priceSwitch';
import SubscriptionList from './components/subscriptionList';
import { Unsubscribe } from './components/unsubscribe';
import style from './pricing.module.scss';

export const Pricing = () => {
  const [checked, setChecked] = useState(false);
  const [coupone, setCoupone] = useState('');
  const [productId, setProductId] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const isOpenCouponPopup = useSelector(isOpenCouponPopup$);

  const selectedSubscriptionId = useSelector(selectedSubscriptionId$);
  const subscription = useSelector(subscription$);
  const is_active_subscription = useSelector(is_active_subscription$);
  const interval = useSelector(interval$);
  const stripeMonthPriceId = useSelector(stripe_month_price_id$);
  const stripeYearPriceId = useSelector(stripe_year_price_id$);
  const isLoading = useSelector(isLoading$);
  const [isAnnually, setPlanType] = useState<boolean>(false);

  useEffect(() => {
    if (selectedSubscriptionId) setProductId(selectedSubscriptionId);
  }, [selectedSubscriptionId]);

  useEffect(() => {
    if (is_active_subscription) {
      if (interval === 'month') {
        setProductId(stripeMonthPriceId);
      } else {
        setProductId(stripeYearPriceId);
      }
    }
  }, [is_active_subscription, interval, stripeMonthPriceId, stripeYearPriceId]);
  useEffect(() => {
    if (subscription) setProductTitle(subscription);
  }, [subscription]);

  const dispatch = useDispatch();

  const toggleShow = useCallback(() => {
    setChecked(prevChecked => !prevChecked);
  }, []);

  const handleClosePopup = () => {
    if (isLoading) return;
    dispatch(setCouponPopup(false));
    setProductId('');
  };

  const handleChangeCoupone = useCallback(
    (val: string | number) => {
      setCoupone(val as string);
    },
    [setCoupone]
  );

  const labelCX = cx(style.labelCbx, {
    [style.selected]: checked,
  });

  const hanleApply = () => {
    dispatch(updateSubscription(productId, checked && coupone.length ? coupone : undefined));
  };

  const onOpenPopup = useCallback(
    (id: string, _title: string) => {
      dispatch(setCouponPopup(true));
      setProductId(id);
    },
    [productId]
  );

  return (
    <div className={style.root}>
      <div className={style.content}>
        <Header />
        <SubscriptionList />
        <PriceSwitch checked={isAnnually as any} setChecked={setPlanType as any} />
        <PlanList onOpenPopup={onOpenPopup} isAnnually={isAnnually} />
        <Unsubscribe />
        <Popup
          onDecline={handleClosePopup}
          isApplyDisabled={isLoading}
          isOpen={isOpenCouponPopup}
          btnClassName={style.btn}
          onApply={hanleApply}
          isVisibleOverflow
          isHideMainButton
          buttonText={''}
          applyBtnText={'BUY NEW PLAN'}
          modalContent={
            <div className={style.popup}>
              {isLoading && <div className={style.loading}>{<Loader size={15} />}</div>}
              <div className={style.head}>Buying a new plan</div>
              <div className={style.label}>{`Are you sure that you want to buy new subscription plan?`}</div>
              <div className={style.text}>
                {'Your current plan is'}
                <span className={style.product}>{productTitle}</span>
              </div>

              <div className={style.coupon}>
                <div className={style.checkbox}>
                  <CheckboxMaterial
                    value={'teams'}
                    checked={checked}
                    onChange={toggleShow}
                    className="avail-mus-team-check"
                    name={'check'}
                    color="primary"
                    disabled={isLoading}
                  />
                  <div className={labelCX}>I have a coupon</div>
                </div>
                <div className={style.inputContainer}>
                  {checked && (
                    <InputPopup
                      type="text"
                      title="Coupone"
                      onChange={handleChangeCoupone}
                      value={coupone}
                      maxLength={100}
                      disabled={!checked || isLoading}
                      className={style.input}
                    />
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
