import 'sweetalert2/src/sweetalert2.scss';

import { setConfirmToken } from 'actions/confirm';
import * as CurrentSubscriptionActions from 'actions/currentSubscription';
// import * as CofirmActions from 'actions/confirm';
import * as TabActions from 'actions/tab';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { companyId, loginBg } from 'interceptors';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';
import { publicRoute } from 'utils/routes';

import * as AuthActions from '../actions/auth';
import { getRegisterEmail } from './confrirm';

export const logIn = (email: string, pass: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AuthActions.initAllApplication());
    dispatch(AuthActions.setAuthLoading());

    axios
      .post(`${Api.LogIn}`, {
        email,
        pass,
      })
      .then(response => {
        const background = response?.data?.result?.login_background;
        if (background) {
          dispatch(setLoginPageBackground(background as string) as any);
        }
        const sid = response?.data?.result?.sid;
        dispatch(AuthActions.setSID(sid));

        dispatch(AuthActions.setIs500UserPayed(response.data.result.is_first_500));
        dispatch(AuthActions.getSuccessAuth());
        dispatch(AuthActions.getUserName(response.data.result.user_name));
        dispatch(AuthActions.getUserType(response.data.result.role));
        dispatch(AuthActions.getUserEmail(response.data.result.user_email));
        dispatch(AuthActions.getCompanyAvatar(response.data.result.logo));
        dispatch(TabActions.setTabInit());
        if (response.data?.result?.subscription?.is_active_subscription) {
          dispatch(
            CurrentSubscriptionActions.getCurrSubscrSuccess({
              ...response.data.result.subscription,
              currentSubscription: response.data.result.subscription?.product_title,
            })
          );
          dispatch(AuthActions.getUserSubscription(response.data.result.subscription?.product_title));
        } else {
          const subscription = {
            ...response.data.result.subscription,
            product_title: 'Free',
            currentSubscription: response.data.result.subscription?.product_title,
          };
          dispatch(AuthActions.getUserSubscription('Free'));
          dispatch(CurrentSubscriptionActions.getCurrSubscrSuccess(subscription));
        }

        dispatch(AuthActions.setAffiliate(response.data.result.is_affiliate));
        successNotification('Welcome to SmashHaus');
      })
      .catch(error => {
        dispatch(AuthActions.setAuthFail());
        console.error(error);
        dispatch(AuthActions.setErrorText(error.response.data.error));
        catchedErrorNotification(error, 10000);
      });
  };
};

export const onLogout = (token?: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AuthActions.initAllApplication());

    axios
      .post(`${Api.LogOut}`)
      .then(response => {
        if (token) {
          dispatch(setConfirmToken(token));
          dispatch(getRegisterEmail(token) as any);
        }
        const company_id = response?.data?.result?.company_id;
        if (company_id) {
          dispatch(AuthActions.setCompanyId(company_id));
          companyId(company_id);
        }

        const background = response?.data?.result?.login_background;
        if (background) {
          dispatch(setLoginPageBackground(background as string) as any);
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(AuthActions.setAuthFail());
        catchedErrorNotification(error);
      });
  };
};

export const setLoginPageBackground = (background: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AuthActions.setLoginBg(background));
    loginBg(background);
  };
};

export const checkEmail = (email: string) => {
  return (dispatch: (action: Action) => void) => {
    axios
      .post(`${Api.CheckEmail}`, { email })
      .then(() => {
        dispatch(AuthActions.setCheckedEmail(true));
      })
      .catch(error => {
        console.error(error);
        dispatch(AuthActions.setCheckedEmail(false));
        catchedErrorNotification(error);
      });
  };
};

export const getInfo = () => {
  return (dispatch: (action: Action) => void) => {
    // dispatch(AuthActions.initAllApplication());
    dispatch(AuthActions.setAuthLoading());
    axios
      .post(Api.GetInfo)
      .then(response => {
        const background = response?.data?.result?.login_background;
        if (background) {
          dispatch(setLoginPageBackground(background as string) as any);
        }

        const sid = response?.data?.result?.sid;
        dispatch(AuthActions.setSID(sid));

        if (response.data.result?.is_logged_in) {
          dispatch(AuthActions.getSuccessAuth());
          dispatch(AuthActions.getUserName(response.data.result.user_name));
          dispatch(AuthActions.getUserType(response.data.result.role));
          dispatch(AuthActions.getUserEmail(response.data.result.user_email));
          dispatch(AuthActions.getCompanyAvatar(response.data.result.logo));
          dispatch(AuthActions.setIs500UserPayed(response.data.result.is_first_500));
          dispatch(AuthActions.setTrialCounter(response.data.result.trialcounter));

          if (response.data?.result?.subscription?.is_active_subscription) {
            dispatch(
              CurrentSubscriptionActions.getCurrSubscrSuccess({
                ...response.data.result.subscription,
                currentSubscription: response.data.result.subscription?.product_title,
              })
            );
            dispatch(AuthActions.getUserSubscription(response.data.result.subscription?.product_title));
          } else {
            const subscription = {
              ...response.data.result.subscription,
              product_title: 'Free',
              currentSubscription: response.data.result.subscription?.product_title,
            };
            dispatch(AuthActions.getUserSubscription('Free'));
            dispatch(CurrentSubscriptionActions.getCurrSubscrSuccess(subscription));
          }

          dispatch(AuthActions.setAffiliate(response.data.result.is_affiliate));
        } else {
          dispatch(AuthActions.setAuthInit());
        }

        if (window.location.pathname === publicRoute.technicalWork) {
          dispatch(AuthActions.openTechnicalPage(false));
        }
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 404 || error.response.status === 502) {
          dispatch(AuthActions.openTechnicalPage(true));
        }
        dispatch(AuthActions.setCheckedEmail(false));
        catchedErrorNotification(error);
      });
  };
};
