import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import * as NewTracksCreators from 'creators/newTracks';
import { TrackType } from 'interfaces/INewTrack';
// eslint-disable-next-line
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import style from './searchTracks.module.scss';

interface IStateProps {}
interface OwnProps {
  type: TrackType;
  search: string;
  isNotNeedToUpdate?: boolean;
  onSearchChange: (val: string) => void;
  setNotNeedToUpdate?: (isNotNeedToUpdate: boolean) => void;
}
interface IDispatchProps {
  searchTracks: (title: string, type: TrackType) => void;
}

interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const SearchTracksComponent = ({ type, searchTracks, onSearchChange, search, isNotNeedToUpdate, setNotNeedToUpdate }: IProps) => {
  const dispatch = useDispatch();
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value);
    setNotNeedToUpdate && setNotNeedToUpdate(false);
  };

  const handleSearch = useCallback(() => {
    dispatch(NewTracksCreators.fetchTracks(type, true, 100, 0, true));
  }, [dispatch, type]);

  useEffect(() => {
    if (isNotNeedToUpdate) return;
    const timeOutId = setTimeout(() => {
      searchTracks(search, type);
    }, 600);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [search, searchTracks, type, isNotNeedToUpdate]);

  return (
    <div className={style.root}>
      <input value={search} onChange={onChange} className={style.input} placeholder="Search..." />
      <SearchRoundedIcon className={style.searchIcon} onClick={handleSearch} />
    </div>
  );
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    searchTracks: dispatchProps.searchTracks,
    ...props,
  };
};

export const SearchTracks = connect(
  null,
  {
    searchTracks: NewTracksCreators.searchTracks,
  },
  mergeProps
)(SearchTracksComponent);
