// eslint-disable-next-line
import React, { useState, memo } from 'react';

import { JobsWithPlaylist } from './components/jobsWithPlaylist';
import { TrackList } from './components/trackList';
import style from './playlists.module.scss';

const PlaylistsComponent = () => {
  const [jobStatus, setJobStatus] = useState('open');

  return (
    <section className={style.root}>
      <div className={style.content}>
        <div className={style.leftSide}>
          <JobsWithPlaylist jobStatus={jobStatus} setJobStatus={setJobStatus} />
        </div>
        <div className={style.rightSide}>
          <TrackList />
        </div>
      </div>
    </section>
  );
};

export const PlaylistsPage = memo(PlaylistsComponent);
