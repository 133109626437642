import GetAppIcon from '@material-ui/icons/GetApp';
import { ReactComponent as PauseIcon } from 'assets/img/pause-button.svg';
import { ReactComponent as PlayIcon } from 'assets/img/play-button.svg';
import { INewTrack } from 'interfaces/INewTrack';
import { IDownloadProgress, IPlayerURLById } from 'interfaces/state/SharedPlayerState';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';

import style from './track.module.scss';

interface IProps {
  track: INewTrack;
  currentIndex: number | null;
  index: number;
  isHideDownload: boolean;
  onDownload: (track_id: number, title: string, n: string) => void;
  onPlayTrack: (track: INewTrack) => void;
  downloadProgressByID: IDownloadProgress;
  currentURLByID: IPlayerURLById;
  currentTrack: INewTrack | null;
  isPlayingTrack: boolean | null;
}
export const Track = ({
  track,
  onDownload,
  onPlayTrack,
  isHideDownload,
  downloadProgressByID,
  currentURLByID,
  currentTrack,
  isPlayingTrack,
}: IProps) => {
  const handleDownload = () => onDownload(track.track_id!, track.title!, track.name_in_base!);

  const [isPlay, setPlay] = useState(false);
  const [playCount, setPlayCount] = useState(0);

  const handlePlay = () => {
    setPlay(!isPlay);
    setPlayCount(playCount + 1);
    if (playCount > 0) {
      let playButton = document.querySelector('.rhap_play-pause-button') as HTMLElement;
      if (playButton) {
        playButton.click();
      }
    } else {
      if (playCount === 0) onPlayTrack(track);
    }
  };

  const isDisableDownload = () => {
    if (downloadProgressByID[track.track_id!]) {
      if (downloadProgressByID[track.track_id!] > 0 && downloadProgressByID[track.track_id!] < 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      currentURLByID &&
      track.track_id &&
      currentURLByID[track.track_id!] &&
      currentURLByID[track.track_id!].url &&
      currentURLByID[track.track_id!].isPlay
    ) {
      setPlay(true);
    } else {
      setPlay(false);
      setPlayCount(0);
    }
  }, [currentURLByID, track]);

  useEffect(() => {
    if (isPlayingTrack && currentTrack && currentTrack.track_id === track.track_id) {
      setPlay(true);
    } else {
      setPlay(false);
    }
    // eslint-disable-next-line
  }, [isPlayingTrack]);

  return (
    <div className={style.root}>
      <div className={style.wrapper}>
        <button className={style.play} onClick={handlePlay}>
          {isPlay ? <PauseIcon className={style.icon} /> : <PlayIcon className={style.icon} />}
        </button>
        <div className={style.textContainer}>
          <div className={style.title} title={track.title!}>
            {track.title}
          </div>
          <div className={style.subTitle} title={track.description!}>
            {track.description}
          </div>
        </div>
        <div className={style.progress}>{downloadProgressByID[track.track_id!] ? `${downloadProgressByID[track.track_id!]} %` : null}</div>
        {!isHideDownload && (
          <button className={style.downloadBtn} onClick={handleDownload} disabled={isDisableDownload()}>
            <GetAppIcon className={style.icon} />
          </button>
        )}
      </div>
    </div>
  );
};
