import GetAppIcon from '@material-ui/icons/GetApp';
import cx from 'classnames';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { downloadAllSharedPlaylistTracks } from 'creators/sharedPlaylistTracks';
import { WSApi } from 'enums/wsApi';
import { IDownloadProgress } from 'interfaces/state/SharedPlayerState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import useWebSocket /* ReadyState */ from 'react-use-websocket';
import { allowed_all_tracks$, link_id$ } from 'selectors/shareablePlaylistInfo';
import { downloadProgressByID$ } from 'selectors/sharedPlayer';
import { isLoadingZip$ } from 'selectors/sharedPlaylistTracks';
import { envWSDetector } from 'utils/env';
import { firstCapitalLetter } from 'utils/firstCapitalLetter';

import style from './controlls.module.scss';

interface IStateProps {
  allowed_all_tracks: boolean;
  link_id: number | null;
  downloadProgressByID: IDownloadProgress;
  isLoadingZip: boolean;
}
interface IDispatchProps {
  downloadAllSharedPlaylistTracks: (link: string, type: string) => void;
}
interface OwnProps {
  isMusicianPlaylist?: boolean;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const wsUrls = [WSApi.DownloadAppZip, WSApi.DownloadPlaylistZip];
const Controlls = ({ allowed_all_tracks, downloadAllSharedPlaylistTracks, link_id, downloadProgressByID, isMusicianPlaylist }: IProps) => {
  const [socketUrl] = useState(`wss://${envWSDetector()}smash.haus/ws`);
  const [isLoading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null as null | string);

  const { sendMessage, lastMessage } = useWebSocket(socketUrl, { share: true });

  useEffect(() => {
    if (lastMessage) {
      const answer = JSON.parse(lastMessage?.data);
      setLoading(false);
      if (answer?.action && wsUrls.includes(answer?.action)) {
        if (answer?.error) {
          errorNotification(firstCapitalLetter(answer.error.replace(/_|-|}|{/g, ' ')));
        } else {
          downloadAllSharedPlaylistTracks(answer?.response?.download_url, selectedType!);
        }
      }
    }
    // eslint-disable-next-line
  }, [lastMessage]);

  const handlenClick = (type: '7z' | 'zip' | 'scp') => () => {
    if (link_id) {
      setSelectedType(type);
      setLoading(true);
      if (isMusicianPlaylist) {
        sendMessage(JSON.stringify({ action: 'application.download_shareable_zip', link_id, type }));
      } else {
        sendMessage(JSON.stringify({ action: 'playlist.download_shareable_zip', link_id, type }));
      }
    }
  };

  const isDisabled = downloadProgressByID[0] > 0 && downloadProgressByID[0] < 100;

  const loadingText = useMemo(() => {
    if ((!isLoading && !downloadProgressByID[0]) || (!isLoading && downloadProgressByID[0] === 100)) {
      setSelectedType(null);
      return '';
    } else if (isLoading) {
      return 'Loading...(We are preparing an archive)';
    } else if (downloadProgressByID[0] > 0 && downloadProgressByID[0] < 100) {
      return `Progress ${downloadProgressByID[0]} %`;
    } else {
      return 'Please wait';
    }
  }, [isLoading, downloadProgressByID]);

  const BtnCX = (type: string) =>
    cx(style.downloadBtn, {
      [style.selected]: selectedType === type,
    });

  return (
    <div className={style.root}>
      {allowed_all_tracks && (
        <div className={style.btns}>
          <button
            className={BtnCX('7z')}
            disabled={!allowed_all_tracks || isDisabled || isLoading || !link_id}
            onClick={handlenClick('7z')}
          >
            <span className={style.text}>{'7z'}</span>
            <GetAppIcon className={style.icon} />
          </button>

          <button
            className={BtnCX('zip')}
            disabled={!allowed_all_tracks || isDisabled || isLoading || !link_id}
            onClick={handlenClick('zip')}
          >
            <span className={style.text}>{'zip'}</span>
            <GetAppIcon className={style.icon} />
          </button>
          <button
            className={BtnCX('scp')}
            disabled={!allowed_all_tracks || isDisabled || isLoading || !link_id}
            onClick={handlenClick('scp')}
          >
            <span className={style.text}>{'scp(mac)'}</span>
            <GetAppIcon className={style.icon} />
          </button>
        </div>
      )}
      <div className={style.downloadingText}>{loadingText}</div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    allowed_all_tracks: allowed_all_tracks$(state),
    link_id: link_id$(state),
    downloadProgressByID: downloadProgressByID$(state),
    isLoadingZip: isLoadingZip$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    downloadAllSharedPlaylistTracks: dispatchProps.downloadAllSharedPlaylistTracks,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    downloadAllSharedPlaylistTracks,
  },
  mergeProps
)(memo(Controlls));
