import { IAudioMetadata } from 'music-metadata-browser';
import { Action } from 'redux';

import * as Const from '../constants';

export const setTrackInformationInit = (): Action => {
  return {
    type: Const.TRACK_INFORMATION_INIT,
  };
};

export const setTrackInformationFormat = (payload: IAudioMetadata) => {
  return {
    type: Const.TRACK_INFORMATION_FORMAT,
    payload,
  };
};

export const setTrackInformationFileName = (payload: string) => {
  return {
    type: Const.TRACK_INFORMATION_FILE_NAME,
    payload,
  };
};
