import { keyBy } from 'lodash-es';

import * as Const from '../constants';
import State, { PaymentMethod } from '../interfaces/state/PaymentMethodState';

const initState: State = {
  ids: [],
  methodsById: {},
  isLoading: false,
  isError: false,
};

export default function paymentMethod(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.PM_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.PM_SUCCESS:
      if (action.payload === null) {
        return {
          ...initState,
        };
      } else {
        return {
          ...state,
          ids: action.payload.map((pm: PaymentMethod) => pm.payment_method_id),
          methodsById: keyBy(action.payload, 'payment_method_id'),
          isLoading: false,
          isError: false,
        };
      }

    case Const.PM_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.PM_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
