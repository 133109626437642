import * as UpdateApproveActions from 'actions/updateApprove';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';
import { handleChangeUserData } from './admin';
import { IUser } from 'interfaces/IUser';

export const updateApprove = (user_to_approve: string, is_approved: boolean, type: string) => {
  return (dispatch: (action: Action) => void) => {
    const isLoading = true;
    const userType = type.slice(0, -1);

    dispatch(UpdateApproveActions.getUpdateApproveLoading(user_to_approve, is_approved, isLoading));
    axios
      .post(Api.UpdateApprove, {
        user_to_approve,
        is_approved,
      })
      .then(_response => {
        const isLoading = false;
        dispatch(UpdateApproveActions.setUpdateApproveSuccess(user_to_approve, is_approved, isLoading));
        dispatch(handleChangeUserData(user_to_approve,{ is_approved: is_approved as IUser['is_approved']}, userType as 'creator' | 'admin' | 'manager') as any);

      })
      .catch(error => {
        const isLoading = false;
        dispatch(UpdateApproveActions.setUpdateApproveError(user_to_approve, !is_approved, isLoading));
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
