import { ModalProps } from '@material-ui/core';
import { default as PopoverMaterial, PopoverPosition } from '@material-ui/core/Popover';
// eslint-disable-next-line
import React from 'react';

interface IProps {
  id?: string;
  open: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  onClose: ModalProps['onClose'];
  verticalAnchor: number | 'top' | 'center' | 'bottom';
  horizontalAnchor: number | 'center' | 'left' | 'right';
  verticalTransform: number | 'top' | 'center' | 'bottom';
  horizontalTransform: number | 'center' | 'left' | 'right';
  content: JSX.Element;
  anchorPosition?: PopoverPosition;
  onEnter?: () => void;
}

export const Popover = ({
  id,
  open,
  anchorEl,
  onClose,
  verticalAnchor,
  horizontalAnchor,
  verticalTransform,
  horizontalTransform,
  content,
  onEnter,
}: IProps) => {
  return (
    <PopoverMaterial
      id={id}
      open={open}
      anchorEl={anchorEl}
      onEnter={onEnter}
      onClose={onClose}
      anchorOrigin={{
        vertical: verticalAnchor,
        horizontal: horizontalAnchor,
      }}
      transformOrigin={{
        vertical: verticalTransform,
        horizontal: horizontalTransform,
      }}
    >
      {content}
    </PopoverMaterial>
  );
};
