import React from 'react';

import style from './technicalWorks.module.scss';

export const TechnicalWorks = () => {
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <div className={style.root}>
      <main className={style.main}>
        <h4>Thank you for being patient. We are doing some work on the site and will be back shortly</h4>
        <h1>Sorry, we`re doing some technical work on the site</h1>
        <h3>
          Please try again later or{' '}
          <span className={style.link} onClick={handleClick}>
            reload page
          </span>
        </h3>
      </main>
    </div>
  );
};
