import { IUser } from 'interfaces/IUser';
import { Action } from 'redux';

import * as Const from '../constants';

export const setAdminInit = (): Action => {
  return {
    type: Const.ADMIN_INIT,
  };
};

export const getAdminSuccess = (managers: IUser[] = [], admins: IUser[] = [], creators: IUser[] = []) => {
  return {
    type: Const.ADMIN_SUCCESS,
    managers,
    admins,
    creators,
  };
};

export const getAdminSuccessNext = (managers: IUser[] = [], admins: IUser[] = [], creators: IUser[] = []) => {
  return {
    type: Const.ADMIN_SUCCESS_NEXT,
    managers,
    admins,
    creators,
  };
};

export const setAdminFail = (): Action => {
  return {
    type: Const.ADMIN_ERROR,
  };
};

export const setAdminPending = (): Action => {
  return {
    type: Const.ADMIN_PENDING,
  };
};

export const setCurrentUser = (payload: number | null) => {
  return {
    type: Const.ADMIN_CURRENT_USER,
    payload,
  };
};

export const deleteUserFromAdminPage = (
  email: string,
  userType: 'managers' | 'admins' | 'creators',
  typeOf: 'admin' | 'creator' | 'manager'
) => {
  return {
    type: Const.ADMIN_DELETE_USER,
    userType,
    email,
    typeOf,
  };
};

export const changeUserData = (userType: 'admin' | 'manager' | 'creator', user: IUser) => {
  return {
    type: Const.ADMIN_CHANGE_USER,
    userType,
    user,
  };
};
