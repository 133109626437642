import { Action } from 'redux';
import * as Const from '../constants';

export const setShareInit = (): Action => {
  return {
    type: Const.SHARE_INIT,
  };
};

export const setShareSuccess = (): Action => {
  return {
    type: Const.SHARE_SUCCESS,
  };
};

export const setShareFail = (): Action => {
  return {
    type: Const.SHARE_ERROR,
  };
};

export const setSharePending = (): Action => {
  return {
    type: Const.SHARE_PENDING,
  };
};
