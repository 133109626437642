import * as Const from '../constants';
import State from '../interfaces/state/SharedPlaylistTracksState';

const initState: State = {
  list: [],
  isLoading: false,
  isError: false,
  isLoadingZip: false,
  currentIndex: null,
};

export default function sharedPlaylistTracks(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SHARED_PLAYLIST_TRACKS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.SHARED_PLAYLIST_TRACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SHARED_PLAYLIST_TRACKS_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SHARED_PLAYLIST_TRACKS_RECEIVE:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SHARED_PLAYLIST_TRACKS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.SHARED_PLAYLIST_TRACKS_ID:
      return {
        ...state,
        currentIndex: action.payload,
      };

    case Const.SHARED_PLAYLIST_DOWNLOAD_ZIP:
      return {
        ...state,
        isLoadingZip: true,
      };

    case Const.SHARED_PLAYLIST_DOWNLOAD_ZIP_LOADED:
      return {
        ...state,
        isLoadingZip: false,
      };

    default:
      return state;
  }
}
