import { Tooltip } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import { setJobTeamType, setJobStatus as setStatus } from 'actions/musicianJobs';
import cx from 'classnames';
import { searchJobs } from 'creators/musicianJobs';
// eslint-disable-next-line
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading$, jobsCounter$, jobsTeamCounter$ } from 'selectors/musicianJobs';

import { Search } from '../search';
import style from './jobsHeader.module.scss';

interface IStateProps {}
interface OWnProps {
  setTeamJobs: (isTeamJobs: boolean) => void;
  isTeamJobs: boolean;
  jobStatus: string;
  setJobStatus: (t: string) => void;
}
interface IDispatchProps {}
interface IProps extends IStateProps, OWnProps, IDispatchProps {}

export const JobsHeader = ({ isTeamJobs, setTeamJobs, jobStatus, setJobStatus }: IProps) => {
  const isLoading = useSelector(isLoading$);
  const countOfJobs = useSelector(jobsCounter$);
  const countOfTeamsJobs = useSelector(jobsTeamCounter$);
  const dispatch = useDispatch();

  const onChange = () => {
    setTeamJobs(!isTeamJobs);
    dispatch(setJobTeamType(!isTeamJobs));
  };

  const handleChangeJobStatusFilter = useCallback(
    (status: string) => {
      if (isLoading) return;
      setJobStatus(status);
      dispatch(setStatus(status));
      dispatch(searchJobs(0, 100));
    },
    [isLoading, setJobStatus, dispatch]
  );

  const onAllClick = useCallback(() => {
    handleChangeJobStatusFilter('all');
  }, [handleChangeJobStatusFilter]);

  const onOpenClick = useCallback(() => {
    handleChangeJobStatusFilter('open');
  }, [handleChangeJobStatusFilter]);

  const onArchivedClick = useCallback(() => {
    handleChangeJobStatusFilter('archived');
  }, [handleChangeJobStatusFilter]);

  const onClosedClick = useCallback(() => {
    handleChangeJobStatusFilter('closed');
  }, [handleChangeJobStatusFilter]);

  const allBtnCX = cx(style.btn, {
    [style.selected]: jobStatus === 'all',
    [style.disabled]: isLoading,
    [style.hidden]: true,
  });

  const openBtnCX = cx(style.btn, {
    [style.selected]: jobStatus === 'open',
    [style.disabled]: isLoading,
  });

  const closedBtnCX = cx(style.btn, {
    [style.selected]: jobStatus === 'closed',
    [style.disabled]: isLoading,
    [style.hidden]: true,
  });

  const archivedBtnCX = cx(style.btn, {
    [style.selected]: jobStatus === 'archived',
    [style.disabled]: isLoading,
    [style.hidden]: true,
  });

  const CheckedCX = cx(style.label, {
    [style.selected]: isTeamJobs,
  });

  return (
    <header className={style.root}>
      <div className={style.search}>
        <Search />
      </div>
      <div className={style.subhead}>
        <div className={style.title}>
          {isTeamJobs ? 'Teams jobs ' : 'Jobs '} - <span className={style.number}>{isTeamJobs ? countOfTeamsJobs : countOfJobs}</span>
        </div>
        <button className={allBtnCX} onClick={onAllClick}>
          ALL
        </button>
        <button className={openBtnCX} onClick={onOpenClick}>
          OPEN
        </button>
        <button className={closedBtnCX} onClick={onClosedClick}>
          CLOSED
        </button>
        <button className={archivedBtnCX} onClick={onArchivedClick}>
          ARCHIVED
        </button>

        <div className={style.label}>
          <Tooltip title={isTeamJobs ? 'Show all jobs' : 'Show teams jobs'}>
            <GroupIcon className={CheckedCX} onClick={onChange} />
          </Tooltip>
        </div>
      </div>
    </header>
  );
};
