import { Tooltip } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { setJobSearch, setJobTypes } from 'actions/musicianJobs';
import axios from 'axios';
import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import { searchJobs } from 'creators/musicianJobs';
import { Api } from 'enums/api';
// eslint-disable-next-line
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading$, search$, selectedTypes$, status$ } from 'selectors/musicianJobs';
import { usePrevious } from 'utils';

import style from './search.module.scss';

export interface IJobType {
  name: string;
  url: string | null;
}

interface IJobFilterProps {
  item: IJobType;
  onTrigger: (type: string) => void;
}

export const JobTypeFilter = ({ item, onTrigger }: IJobFilterProps) => {
  const isLoading = useSelector(isLoading$);
  const selectedTypes = useSelector(selectedTypes$);

  const RootCX = cx(style.filter, {
    [style.selected]: selectedTypes.includes(item.name),
    [style.disabled]: isLoading,
  });

  const ImageCX = cx(style.image, {
    [style.selected]: selectedTypes.includes(item.name),
    [style.disabled]: isLoading,
  });

  const name = useMemo(() => item.name, [item.name]);

  // eslint-disable-next-line
  const handleClick = useCallback(() => onTrigger(item.name), [item.name, onTrigger]);
  return item?.url ? (
    <Tooltip title={`Powered by ${item.name}`}>
      <img className={ImageCX} src={item.url} onClick={handleClick} alt={item.name} />
    </Tooltip>
  ) : (
    <div className={RootCX} onClick={handleClick}>
      {name}
    </div>
  );
};

export const Search = () => {
  const dispatch = useDispatch();
  const search = useSelector(search$);
  const prevSearch = usePrevious(search);
  const status = useSelector(status$);
  const selectedTypes = useSelector(selectedTypes$);
  const isLoading = useSelector(isLoading$);

  const [jobTypeList, setJobTypeList] = useState([] as IJobType[]);
  const [isLoadingTypes, setLoadingTypes] = useState(false);

  const fetchJobTypes = async () => {
    setLoadingTypes(true);
    const res = await axios
      .post(Api.GetJobTypes)
      .then(res => res.data.result)
      .catch(err => console.log(err));
    setJobTypeList(res);
    setLoadingTypes(false);
  };
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setJobSearch(e.target.value));
    },
    [dispatch]
  );

  useEffect(() => {
    try {
      fetchJobTypes();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if ((selectedTypes.length && search && prevSearch !== search) || (selectedTypes.length && prevSearch && prevSearch !== search)) {
        dispatch(searchJobs(0, 100));
      }
    }, 600);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [search, searchJobs, status, selectedTypes, prevSearch]);

  const handleClear = useCallback(() => {
    dispatch(setJobSearch(''));
    dispatch(searchJobs(0, 100));
    // eslint-disable-next-line
  }, []);

  const handleSearch = useCallback(() => {
    dispatch(searchJobs(0, 100));
    // eslint-disable-next-line
  }, [search, status, selectedTypes]);

  const renderClose = () => {
    return isLoading ? (
      <div className={style.loader}>
        <Loader size={15} />
      </div>
    ) : (
      <CloseRoundedIcon className={style.clearIcon} onClick={handleClear} />
    );
  };

  const handleJobTypeClick = useCallback(
    (type: string) => {
      if (isLoading || isLoadingTypes) return;
      if (type === 'All') {
        dispatch(setJobTypes(['All']));
      } else {
        if (selectedTypes.includes(type)) {
          const newSelectedTypes = selectedTypes.filter(t => t !== type);
          dispatch(setJobTypes(newSelectedTypes));
        } else {
          const newSelectedTypes = [...selectedTypes, type].filter(t => t !== 'All');
          dispatch(setJobTypes(newSelectedTypes));
        }
      }
      dispatch(searchJobs(0, 100));
    },
    [isLoading, isLoadingTypes, dispatch, selectedTypes]
  );

  return (
    <div className={style.root}>
      <div className={style.searchContainer}>
        <SearchRoundedIcon className={style.searchIcon} onClick={handleSearch} />
        <input type="text" value={search} onChange={onChange} className={style.input} placeholder={'Search'} />
        {renderClose()}
      </div>
      <div className={style.typesContainer}>
        {jobTypeList?.map(jobType => {
          return <JobTypeFilter item={jobType} onTrigger={handleJobTypeClick} />;
        })}
        {isLoadingTypes && (
          <div className={style.loadingContainer}>
            <span className={style.loadingText}>Loading job types...</span>
            <Loader />
          </div>
        )}
        <Tooltip title={'Job type filter'}>
          <HelpOutlineRoundedIcon className={style.faqIcon} />
        </Tooltip>
      </div>
    </div>
  );
};
