import { Iinvite } from 'interfaces/state/InviteState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setInviteInit = (): Action => {
  return {
    type: Const.INVITE_INIT,
  };
};

export const fetchCreatedInviteList = (payload: Iinvite[]) => {
  return {
    type: Const.INVITE_CREATED_LOADED,
    payload,
  };
};

export const fetchReceivedInviteList = (payload: Iinvite[]) => {
  return {
    type: Const.INVITE_RECEIVED_LOADED,
    payload,
  };
};

export const addNewCreatedInvite = (payload: Iinvite) => {
  return {
    type: Const.INVITE_CREATED_ADD_NEW,
    payload,
  };
};

export const addNewReceivedInvite = (payload: Iinvite) => {
  return {
    type: Const.INVITE_RECEIVED_ADD_NEW,
    payload,
  };
};

export const setInviteError = (): Action => {
  return {
    type: Const.INVITE_ERROR,
  };
};

export const setInviteLoading = (): Action => {
  return {
    type: Const.INVITE_PENDING,
  };
};

export const updateReceivedInviteStatus = (id: number, status: string) => {
  return {
    type: Const.UPDATE_INVITE_STATUS,
    id,
    status,
  };
};

export const setInviteSuccess = (): Action => {
  return {
    type: Const.INVITE_SUCCESS,
  };
};

export const deleteReceivedInviteById = (id: number) => {
  return {
    type: Const.DELETE_RECEIVED_INVITE,
    id,
  };
};

export const deleteCreatedInviteById = (id: number) => {
  return {
    type: Const.DELETE_CREATED_INVITE,
    id,
  };
};
