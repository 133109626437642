import cx from 'classnames';
// eslint-disable-next-line
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { formatBytes } from 'utils/formatBytes';

import styles from './dropzone.module.scss';

interface IProps {
  onAccept: (file: File[] | File) => void;
  onDropReject?: () => void;
  accept?: string[];
  maxSize?: number;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  text?: JSX.Element;
}

export const Dropzone = ({ onAccept, onDropReject, accept, maxSize, multiple, disabled, className, text }: IProps) => {
  // eslint-disable-next-line
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);

  const onDrop = useCallback(
    acceptedFiles => {
      // Do something with the files
      if (!acceptedFiles.length) {
        return;
      } else {
        setFileName(acceptedFiles[0].name);
        setFileSize(acceptedFiles[0].size);
        onAccept(acceptedFiles);
      }
      // eslint-disable-next-line
    },
    [onAccept]
  );

  const onDropRejected = () => {
    if (onDropReject) onDropReject();
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    multiple,
    accept,
    maxSize,
    disabled,
  });

  const renderTitle = () => {
    if (isDragActive) {
      return <p>Drop the file here ...</p>;
    } else if (!isDragActive && !fileName) {
      return (
        <p className="d-flex flex-column align-center justify-center" style={{ width: '100%' }}>
          <b>Choose a file</b>
          or drag here.
        </p>
      );
    } else if (!isDragActive && fileName) {
      return (
        <p className="d-flex flex-column align-center justify-center" style={{ width: '100%' }}>
          {`${fileName} (${formatBytes(fileSize)})`}
        </p>
      );
    } else {
      return null;
    }
  };

  const RootCX = cx(`${styles.root} ${className}`, { [styles.disabled]: disabled });

  return (
    <div {...getRootProps()} className={RootCX}>
      <input {...getInputProps()} />
      {text ? text : renderTitle()}
    </div>
  );
};
