import { Action } from 'redux';

import * as Const from '../constants';

export const setAccountInit = (): Action => {
  return {
    type: Const.ACC_INIT,
  };
};

export const setAccount = (): Action => {
  return {
    type: Const.ACC_SUCCESS,
  };
};

export const setAccountError = (): Action => {
  return {
    type: Const.ACC_ERROR,
  };
};

export const setAccountLoading = (): Action => {
  return {
    type: Const.ACC_PENDING,
  };
};
