import 'sweetalert2/src/sweetalert2.scss';

import * as StatisticsActions from 'actions/statistics';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const fetchStatistics = (year: number, month: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(StatisticsActions.setStatPending());

    axios
      .post(Api.GetStatistics, { year, month })
      .then(response => {
        dispatch(StatisticsActions.getStat(response.data.result));
      })
      .catch(error => {
        dispatch(StatisticsActions.setStatError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
