import * as Const from '../constants';
import State from '../interfaces/state/NewJobAvatar';

const initState: State = {
  avatar: '',
  fileId: '',
  isLoading: false,
  isError: false,
};

export default function newJobAvatar(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.NEW_JOB_AVATAR_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.NEW_JOB_AVATAR_SUCCESS:
      return {
        ...state,
        avatar: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.NEW_JOB_AVATAR_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.NEW_JOB_AVATAR_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.NEW_JOB_AVATAR_FILE_ID:
      return {
        ...state,
        fileId: action.payload,
      };

    case Const.NEW_JOB_AVATAR_SUCCESS_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return state;
  }
}
