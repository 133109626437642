import './index.scss';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// eslint-disable-next-line
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import pkg from '../package.json';
import App from './App';
import store from './store';

const isProd = () => {
  if (window.location.href.includes('dev')) {
    return 'Development';
  } else if (window.location.href.includes('localhost')) {
    return 'Localhost';
  } else {
    return 'Production';
  }
};

Sentry.init({
  dsn: 'https://a65b32dcd42c4ca0bb36adf1501d0120@o577986.ingest.sentry.io/5733899',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: isProd() === 'Production' ? 1.0 : 0,
  release: pkg.version,
  environment: isProd(),
  denyUrls: ['https://localhost:3000/'],
});

const rootEl = document.getElementById('root');
console.log('version', pkg.version, isProd());
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl
);
