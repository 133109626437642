// eslint-disable-next-line
import { useCallback } from 'react';
import { useEffect, useRef } from 'react';

export const emailValidation = (email: string) => {
  return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
};

export function isValidHttpUrl(string: string) {
  // eslint-disable-next-line
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return res !== null;
}

export const isAbsoluteUrl = (url: string) => /^[a-z][a-z0-9+.-]*:/.test(url);

export const DATE_FORMAT = 'MMM DD YYYY';
export const DATE_FORMAT_SHORT = 'MM.DD.YYYY';
export const DATE_TIME_FORMAT = 'MMM DD YYYY HH:mm:ss';
export const SUPPORTED_FORMATS = ['audio/mpeg'];

export const proInputItems = [
  'ASCAP',
  'BMI',
  'SESAC',
  'SADAIC',
  'APRA',
  'AKM',
  'SABAM',
  'ABRAMUS',
  'Musicautor',
  'SOCAN',
  'SCD',
  'SAYCO',
  'HDS',
  'OSA',
  'KODA',
  'EAUTHORS',
  'TEOSTO',
  'SACEM',
  'GEMA',
  'Autodia',
  'CASH',
  'Artisjus',
  'STEF',
  'IPRS',
  'IMRO',
  'ACUM',
  'SIAE',
  'JASRAC',
  'LATGA-A',
  'MACP',
  'SACM',
  'BUMA',
  'APRA',
  'TONO',
  'ZAIKS',
  'SPA',
  'ZAMP',
  'RAO',
  'COMPASS',
  'SAMRO',
  'KOMCA',
  'KOSCAP',
  'SGAE',
  'STIM',
  'SUISA',
  'COTT',
  'MESAM',
  'PRS',
  'AGADU',
  'MSG',
];

export const trackTypes = ['music', 'sfx', 'audio'];

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const uploadLinkHandler = (title: string, link: string) => {
  const url = window.URL.createObjectURL(new Blob([link]));
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', `${title}`);
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export function detectMob() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export function getMaxOfArray(numArray: number[]) {
  return Math.max.apply(null, numArray);
}

export const isNeedToRedirectOnUnknownRoute = (routes: string[], pathname: string) => {
  const res = routes.includes(pathname) || routes.includes(pathname.replace(/.$/, ''));
  return !res;
};
