// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoading$ } from 'selectors/statistics';

import style from './information.module.scss';

interface IProps {
  value: number;
  name: string;
}
export const InformationItem = ({ name, value }: IProps) => {
  const isLoading = useSelector(isLoading$);

  return (
    <div className={style.root}>
      <div className={style.name}>{name}</div>
      <div className={style.value}>{isLoading ? 'Loading...' : value}</div>
    </div>
  );
};
