import State from 'interfaces/state/State';

export const titlePlayer$ = (state: State) => state.musicianPlayer.titlePlayer;
export const descriptionPlayer$ = (state: State) => state.musicianPlayer.descriptionPlayer;
export const name_in_basePlayer$ = (state: State) => state.musicianPlayer.name_in_basePlayer;
export const currentURL$ = (state: State) => state.musicianPlayer.url;
export const currentPlayedTrackId$ = (state: State) => state.musicianPlayer.id;
export const isPlay$ = (state: State) => state.musicianPlayer.isPlay;
export const isPause$ = (state: State) => state.musicianPlayer.isPause;
export const isPlayFromMusicSubmissions$ = (state: State) => state.musicianPlayer.isPlayFromMusicSubmissions;
export const isPlayFromPlaylist$ = (state: State) => state.musicianPlayer.isPlayFromPlaylist;
