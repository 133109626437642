import { Action } from 'redux';

import * as Const from '../constants';

export const setUpdateApproveInit = (): Action => {
  return {
    type: Const.UPDATE_APPROVE_INIT,
  };
};

export const getUpdateApproveLoading = (user_to_approve: string, is_approved: boolean, isLoading: boolean) => {
  return {
    type: Const.UPDATE_APPROVE_PENDING,
    user_to_approve,
    is_approved,
    isLoading,
  };
};

export const setUpdateApproveSuccess = (user_to_approve: string, is_approved: boolean, isLoading: boolean) => {
  return {
    type: Const.UPDATE_APPROVE_SUCCESS,
    user_to_approve,
    is_approved,
    isLoading,
  };
};

export const setUpdateApproveError = (user_to_approve: string, is_approved: boolean, isLoading: boolean) => {
  return {
    type: Const.UPDATE_APPROVE_ERROR,
    user_to_approve,
    is_approved,
    isLoading,
  };
};
