import axios from 'axios';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { Api } from 'enums/api';
import { ICompany } from 'interfaces/state/CompaniesState';
// eslint-disable-next-line
import React, { memo, useEffect, useState } from 'react';

interface IProps {
  setCompanyId: (v: number) => void;
}
export const Companies = memo(({ setCompanyId }: IProps) => {
  const [companies, setCompanies] = useState([] as ISelectItem[]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState({} as ISelectItem);

  const fetchCompanies = async () => {
    setLoading(true);
    const res = await axios
      .post(Api.GetCompanies)
      .then(res => res.data.result)
      .catch(err => console.error(err));
    try {
      if (res?.length) {
        const newRes = res.map((company: ICompany) => ({ value: company.id, label: company.name }));
        setCompanies(newRes);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleChange = (val: ISelectItem) => {
    setValue(val);
    setCompanyId(val.value as number);
  };
  return (
    <MaterialSelect
      value={value}
      placeholder="Choose a company"
      selectItems={companies}
      isAllValueNeed
      onChangeAllValue={handleChange}
      className={''}
      isLoading={isLoading}
      width={250}
    />
  );
});
