// eslint-disable-next-line
import React from 'react';

import style from './header.module.scss';

interface IProps {
  isCompany?: boolean;
}
export const Header = ({ isCompany }: IProps) => {
  const percent = isCompany ? '' : '5';
  return (
    <div className={style.root}>
      <h2 className={style.head}>Earn monthly income for recommending SmashHaus to other top quality music creators?</h2>
      <h3 className={style.text}>
        {`All you do is share the link and when they login, you will be attached to their account as the one that referred them and you will
        be paid monthly ${percent}% of all money they spend on the site.`}
      </h3>
    </div>
  );
};
