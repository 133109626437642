import * as Const from '../constants/index';
import State from '../interfaces/state/CreateAppState';

const initState: State = {
  tracks_id: [],
  job_id: null,
  description: '',
  isLoading: false,
  isError: false,
};

export default function createApplication(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.APP_CR_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.APP_CR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.APP_CR_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.APP_CR_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.FILTER_APP_TRACK_ID:
      return {
        ...state,
        tracks_id: [...state.tracks_id].filter(item => item !== action.payload),
      };

    case Const.ADD_APP_TRACK_ID:
      return {
        ...state,
        tracks_id: [...state.tracks_id, action.payload],
      };

    case Const.APP_JOB_ID:
      return {
        ...state,
        job_id: action.payload,
      };

    case Const.APP_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };

    case Const.APP_INIT_CREATE:
      return {
        ...state,
        tracks_id: [],
        job_id: null,
        description: '',
      };

    default:
      return state;
  }
}
