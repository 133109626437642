import 'sweetalert2/src/sweetalert2.scss';

import { setMusicianPlayerID } from 'actions/musicianPlayer';
import * as NewTrackActions from 'actions/newTracks';
import * as PlaylistActions from 'actions/playlist';
import { setShareablePlaylistLinksInit } from 'actions/shareablePlaylistLinks';
import * as SharedPlaylistActions from 'actions/sharedPlaylist';
import { setCurrSongIndex } from 'actions/song';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { USER_ADMIN } from 'constants/index';
import * as NewTrackCreators from 'creators/newTracks';
import * as NewTracksCreators from 'creators/newTracks';
import { getPlaylistTracks } from 'creators/newTracks';
import { Api } from 'enums/api';
import { IPlaylist } from 'interfaces/IPlaylist';
import State from 'interfaces/state/State';
import orderBy from 'lodash-es/orderBy';
import moment from 'moment';
import { Action } from 'redux';
import { userName$, userType$ } from 'selectors/auth';
import { currentJobIndex$, jobs$ } from 'selectors/musicianJobs';
import { selected_tracks$, tracks$ } from 'selectors/newTrack';
import { currentPlaylist$, playlist$, playlist_id$ } from 'selectors/playlist';
import { sharedPlaylists$ } from 'selectors/sharedPlaylist';
import { catchedErrorNotification } from 'utils/error';

import { fetchSharedPlaylistLinksById } from './shareablePlaylistLinks';

export const getPlaylist = (job_id: number, isNotReceivePlaylistTracks?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(PlaylistActions.setPlaylistPending());
    dispatch(setCurrSongIndex(null));
    dispatch(setMusicianPlayerID(null));
    axios
      .post(`${Api.GetPlaylist}`, {
        job_id,
      })
      .then(response => {
        if (!isNotReceivePlaylistTracks) {
          dispatch(PlaylistActions.getPlaylistReceive(response.data.result));
        }
        // dispatch(NewTrackActions.getNewTracksForPlaylist(response.data.result));

        if (response.data.result.length > 0) {
          const playlists = orderBy(response.data.result, 'playlist_id', 'desc');
          const playlist_id = playlists[0]!.playlist_id;
          if (!isNotReceivePlaylistTracks && playlist_id) {
            dispatch(NewTracksCreators.getPlaylistTracks(playlist_id as number, 100, 0) as any);
            dispatch(PlaylistActions.setCurrPlaylistNum(0));
            dispatch(PlaylistActions.setCurrentPlaylistId(playlist_id as number));
          } else if (!playlist_id) {
            dispatch(PlaylistActions.setCurrentPlaylistId(playlist_id as number));
          }
          dispatch(fetchSharedPlaylistLinksById(playlist_id as number) as any);
          if (currentPlaylist$(getState()) === null && playlist$(getState())?.length) {
            dispatch(PlaylistActions.setCurrPlaylistNum(0));
          }
        } else {
          dispatch(NewTrackActions.getNewTracksForPlaylist(null));
        }
        dispatch(PlaylistActions.setPlaylistLoaded());
      })
      .catch(error => {
        dispatch(PlaylistActions.setPlaylistFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const createPlaylist = (tracks_id: number[], job_id: number, playlist_name: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(PlaylistActions.setPlaylistPending());
    dispatch(setShareablePlaylistLinksInit());

    axios
      .post(`${Api.CreatePlaylist}`, {
        tracks_id,
        job_id,
        playlist_name,
      })
      .then(response => {
        const jobs = jobs$(getState());
        const currentJobIndex = currentJobIndex$(getState());
        dispatch(NewTrackActions.delAlltracksFromPLaylist());
        dispatch(PlaylistActions.setCurrPlaylistNum(0));
        dispatch(PlaylistActions.setPlaylistSuccess());
        dispatch(PlaylistActions.setCurrentPlaylistId(response.data.result.playlist_id));

        const playlist_id = response.data.result.playlist_id as number;
        const newPlaylist = {
          date_time: moment().format('YYYY-MM-DDThh:mm:ss.000000Z'),
          playlist_id,
          job_id,
          playlist_name,
          tracks_id: [],
          user_name: userName$(getState())!,
        };
        dispatch(PlaylistActions.addNewPlaylist(newPlaylist as IPlaylist));
        if (playlist_id$(getState()) !== playlist_id) dispatch(fetchSharedPlaylistLinksById(playlist_id as number) as any);

        if (userType$(getState()) === 'manager' && currentJobIndex !== null && jobs?.[currentJobIndex]?.share_type === 'playlist_sharing') {
          const sharedPlaylistItems = [newPlaylist, ...sharedPlaylists$(getState())];
          dispatch(SharedPlaylistActions.setSharedPlaylistReceive(sharedPlaylistItems as IPlaylist[]));
        }
      })
      .then(() => {
        dispatch(PlaylistActions.setCurrPlaylistNum(0));
      })
      .catch(error => {
        dispatch(PlaylistActions.setPlaylistFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deletePlaylist = (playlist_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(PlaylistActions.setPlaylistPending());

    axios
      .post(`${Api.DeletePlaylist}`, {
        playlist_id,
      })
      .then(() => {
        dispatch(PlaylistActions.filterPlaylistById(playlist_id));
        dispatch(SharedPlaylistActions.filterSharedPlById(playlist_id));
        dispatch(NewTrackActions.delAlltracksFromPLaylist());
        dispatch(PlaylistActions.setPlaylistSuccess());

        if (playlist$(getState()).length) {
          dispatch(PlaylistActions.setCurrPlaylistNum(0));
          dispatch(PlaylistActions.setCurrentPlaylistId(playlist$(getState())[0].playlist_id));
          dispatch(getPlaylistTracks(playlist$(getState())[0].playlist_id as number, 100, 0) as any);
        } else {
          dispatch(PlaylistActions.setCurrPlaylistNum(null));
          dispatch(PlaylistActions.setCurrentPlaylistId(null));
        }

        if (sharedPlaylists$(getState()).length) {
          dispatch(SharedPlaylistActions.setSharedPlaylistId(0));
        } else {
          dispatch(SharedPlaylistActions.setSharedPlaylistId(null));
        }

        const currJobIndex = currentJobIndex$(getState());
        const jobs = jobs$(getState());
        if (currJobIndex !== null && jobs[currJobIndex].share_type === 'playlist_sharing' && userType$(getState()) !== USER_ADMIN) {
          const sharedPlaylistId = playlist_id$(getState());
          if (sharedPlaylistId) {
            dispatch(NewTrackCreators.getSharedTracks(sharedPlaylistId!, undefined, undefined, true) as any);
          }
        }
      })
      .catch(error => {
        dispatch(PlaylistActions.setPlaylistFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updatePlaylist = (playlist_id: number, tracks_id: number[] | [], track_id: number, isDelete: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(PlaylistActions.setPlaylistPending());
    dispatch(PlaylistActions.setPlaylistAmount(tracks_id.length));

    axios
      .post(`${Api.UpdatePlaylist}`, {
        playlist_id,
        tracks_id,
      })
      .then(response => {
        dispatch(PlaylistActions.setPlaylistSuccess());
        if (isDelete) {
          dispatch(NewTrackActions.delTrackFromPLaylist(track_id));
          successNotification('Track was successfully removed from the playlist!');
          return;
        }
        const selected_tracks = selected_tracks$(getState());

        const hasSelectedTrackInPlaylist = selected_tracks?.length ? selected_tracks!.includes(track_id) : null;
        if (!hasSelectedTrackInPlaylist) {
          dispatch(NewTrackActions.selectTrackId(track_id));
        }

        const currIndex = tracks$(getState()).findIndex(item => item.track_id! === track_id);
        dispatch(NewTrackActions.addNewTrackToPlaylist(tracks$(getState())[currIndex]));
        console.info(response);
      })
      .catch(error => {
        dispatch(PlaylistActions.setPlaylistFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
