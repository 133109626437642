import React from 'react';

import style from './notApprovedContent.module.scss';

export const NotApprovedContent = () => {
  return (
    <div className={style.root}>
      <h4 className={style.title}>Thank you for applying!</h4>
      <div className={style.subtitle}>
        Our staff will review your work ASAP. This could take some time so please be patient with us. Once your have been reviewed, we will
        contact you!
      </div>
      <div className={style.body}>
        <span>We appreciate your patience,</span>
        <span>SmashHaus</span>
      </div>
      <footer className={style.footer}>
        <nav className={style.link}>
          <a href="https://www.smashhaus.com/" target="_blank" rel="noopener noreferrer">
            www.smashhaus.com
          </a>
        </nav>
        <div className={style.surge}>
          ***SmashHaus powers tech solutions for leading media companies and creators in Hollywood and around the globe.
        </div>
      </footer>
    </div>
  );
};
