import * as Const from '../constants';
import State from '../interfaces/state/StatsState';

const initState: State = {
  isLoading: false,
  isError: false,
  active_jobs: 0,
  active_jobs_all: 0,
  tracks: [],
};

export default function stats(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.STATS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        active_jobs: action.payload.active_jobs,
        active_jobs_all: action.payload.active_jobs_all,
        tracks: action.payload.tracks,
      };

    case Const.STATS_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.STATS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
}
