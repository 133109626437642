import * as ShareablePlaylistInfoCreators from 'creators/shareablePlaylistInfo';
// eslint-disable-next-line
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { token$ } from 'selectors/receivePass';
import { usePrevious } from 'utils';

interface IStateProps {}
interface IDispatchProps {}
interface OwnProps {
  isMusicianPlaylist?: boolean;
}
interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const TokenHandler = ({ isMusicianPlaylist }: IProps) => {
  const token = useSelector(token$);
  const dispatch = useDispatch();
  const prevToken = usePrevious(token);
  useEffect(() => {
    if (token && token !== prevToken && prevToken === '') {
      dispatch(ShareablePlaylistInfoCreators.fetchSheablePlaylistInfo(token, isMusicianPlaylist));
    }
    // eslint-disable-next-line
  }, [token, prevToken]);
  return <div />;
};

export default memo(TokenHandler);
