import { IMusicianJob } from 'interfaces/IMusicianJob';
import State from 'interfaces/state/State';
import orderBy from 'lodash-es/orderBy';
import { createSelector } from 'reselect';

import { selectedJobsType$ } from './companyJob';

interface IDropdown {
  title: string;
  value: string;
}

export const jobs$ = (state: State): IMusicianJob[] => state.musicianJobs.jobs;
export const jobsForDropdown$ = (state: State): IDropdown[] =>
  state.musicianJobs.jobs ? state.musicianJobs.jobs.map(job => ({ title: job.title, value: job.id.toString() })) : [];
export const isLoading$ = (state: State): boolean => state.musicianJobs.isLoading;
export const isError$ = (state: State): boolean => state.musicianJobs.isError;
export const allJobsWithoutMemoize$ = (state: State): IMusicianJob[] => state.musicianJobs.allJobs;
export const sortType$ = (state: State) => state.musicianJobs.sortType;
export const sortedBy = (state: State) => state.musicianJobs.sortedBy;
export const sortedBy$ = createSelector([sortedBy], sortedArr => sortedArr);
export const allJobs$ = (state: State) => state.musicianJobs.allJobs;
export const current_job_id$ = (state: State) => state.musicianJobs.current_job;
export const currentJobIndex$ = (state: State) => state.musicianJobs.currentJobIndex;
export const sortedAllJobs$ = (state: State) => {
  return orderBy(allJobs$(state), ['date_time', sortedBy$(state)], sortType$(state) as 'asc' | 'desc');
};

export const teamJobs = (state: State) => state.musicianJobs.teamJobs;
export const teamJobs$ = createSelector([teamJobs, sortedBy$, sortType$], (jobs, sortedBy, sortType) =>
  orderBy(jobs, sortedBy, sortType as 'asc' | 'desc')
);

export const filteredAllJobs$ = createSelector(
  allJobs$,
  sortedBy$,
  sortType$,
  selectedJobsType$,
  (allJobs, sortedBy, sortType, selectedJobsType) => {
    return orderBy(allJobs, ['date_time', sortedBy], sortType as 'asc' | 'desc').filter(job => {
      if (selectedJobsType === 'all') {
        return job;
      } else {
        return job.status === selectedJobsType;
      }
    });
  }
);

export const job$ = (state: State) => state.musicianJobs.job;
export const search$ = (state: State) => state.musicianJobs.search;
export const selectedTypes$ = (state: State) => state.musicianJobs.selectedTypes;
export const status$ = (state: State) => state.musicianJobs.status;
export const isTeamJob$ = (state: State) => state.musicianJobs.isTeamJob;
export const jobsCounter$ = (state: State) => state.musicianJobs.jobsCounter;
export const jobsTeamCounter$ = (state: State) => state.musicianJobs.jobsTeamCounter;
