// eslint-disable-next-line
import React, { useState } from 'react';

import { Header } from './components/header';
import { HeaderTitle } from './components/headerTitle';
import { InviteList } from './components/inviteList';
import { InviteTitle } from './components/inviteTitle';
import { TeamList } from './components/teamList';
import style from './team.module.scss';

interface OwnProps {
  isCreator?: boolean;
}
interface IProps extends OwnProps {}
const TeamPageComponent = ({ isCreator }: IProps) => {
  const [isHideTeams, setHideTeams] = useState(false);
  return (
    <div className={style.root}>
      <div className={style.content}>
        <Header setHideTeams={setHideTeams} isHideTeams={isHideTeams} isCreator={isCreator} />
        {!isHideTeams && <HeaderTitle />}
        {!isHideTeams && <TeamList isCreator={isCreator} />}

        {isHideTeams && <InviteTitle />}
        {isHideTeams && <InviteList isCreator={isCreator} />}
      </div>
    </div>
  );
};

export const TeamPage = TeamPageComponent;
