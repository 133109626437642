import StatsState from 'interfaces/state/StatsState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setStatsInit = (): Action => {
  return {
    type: Const.STATS_INIT,
  };
};

export const setStatsPending = (): Action => {
  return {
    type: Const.STATS_PENDING,
  };
};

export const setStatsError = (): Action => {
  return {
    type: Const.STATS_ERROR,
  };
};

interface IStatsResponse {
  active_jobs: StatsState['active_jobs'];
  active_jobs_all: StatsState['active_jobs_all'];
  tracks: StatsState['tracks'];
}

export const fetchStats = (payload: IStatsResponse) => {
  return {
    type: Const.STATS_SUCCESS,
    payload,
  };
};
