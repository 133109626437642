// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';

import style from './headerTitle.module.scss';

const headers = ['Name', 'Members', 'Actions'];
const creatorHeaders = ['Name', 'Actions'];

export const HeaderTitle = () => {
  const userType = useSelector(userType$);

  const list = userType === 'creator' ? creatorHeaders : headers;

  return (
    <div className={style.root}>
      {list.map(header => (
        <div className={style.headerIteam} key={header}>
          {header}
        </div>
      ))}
    </div>
  );
};
