import State from 'interfaces/state/State';
import { SubscriptionListItem } from 'interfaces/state/SubscriptionList';
import { createSelector } from 'reselect';

export const isError$ = (state: State): boolean => state.subscriptionList.isError;
export const isLoading$ = (state: State): boolean => state.subscriptionList.isLoading;
export const subscriptionList = (state: State): SubscriptionListItem[] => state.subscriptionList.list;
export const subscriptionList$ = createSelector([subscriptionList], list => list);
export const period$ = (state: State): boolean => state.subscriptionList.period;
export const selectedSubscriptionId$ = (state: State): string | null => state.subscriptionList.selectedSubscriptionId;
