import 'sweetalert2/src/sweetalert2.scss';

import * as AuthActions from 'actions/auth';
import * as RegisterFromInviteActions from 'actions/registerFromInvite';
import axios from 'axios';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { token$ } from 'selectors/confirm';
import { catchedErrorNotification } from 'utils/error';

export const registerFromInvite = (user_name: string, pass: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(AuthActions.setAuthLoading());
    dispatch(RegisterFromInviteActions.setRegisterFromInviteLoading());
    const token = token$(getState());
    axios
      .post(`${Api.RegisterFromInvite}`, {
        user_name,
        pass,
        token,
      })
      .then(() => {
        dispatch(AuthActions.authExit());
        dispatch(RegisterFromInviteActions.setRegisterFromInviteSuccess());
        dispatch(RegisterFromInviteActions.setRegisterFromInviteSuccessValue(true));
      })
      .catch(error => {
        console.error(error);
        dispatch(AuthActions.setAuthFail());
        dispatch(RegisterFromInviteActions.setRegisterFromInviteFail());
        catchedErrorNotification(error);
      });
  };
};
