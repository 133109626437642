import * as MusicianPlayerActions from 'actions/musicianPlayer';
import AudioPanel from 'components/audioPanel/audioPanel';
import { CreatorPlaylist } from 'components/creatorPlaylist';
import { SearchTracksWrapper } from 'components/searchTracks';
import TrackPanel from 'components/trackPanel/trackPanel';
import UploadNewTrack from 'containers/uploadNewTrack/uploadNewTrack';
import * as CurrentSubscriptionCreators from 'creators/currentSubscription';
import * as TrackCreators from 'creators/newTracks';
import { TrackType } from 'interfaces/INewTrack';
// eslint-disable-next-line
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { subscription$ } from 'selectors/auth';

import style from './newPlaylist.module.scss';

const premuimSubscriptions = ['premium', 'unlimited', 'premium-free'];
interface IStateProps {}
interface IDispatchProps {
  fetchCurrentSubscription: () => void;
  fetchTracks: (type: TrackType, preload?: boolean, limit?: number, offset?: number) => void;
  setMusicianPlayerInit: () => void;
}
interface OwnProps {}
interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const PlaylistPageInternal = ({ fetchCurrentSubscription, fetchTracks, setMusicianPlayerInit }: IProps) => {
  const [type, setType] = useState('all' as TrackType);
  const [search, setSearch] = useState('');
  const [isNotNeedToUpdate, setNotNeedToUpdate] = useState(false);

  const subscription = useSelector(subscription$);

  useEffect(() => {
    fetchTracks('all', true, 50, 0);
    fetchCurrentSubscription();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      setMusicianPlayerInit();
    };
  });

  const isFreeUser = useMemo(() => {
    return !premuimSubscriptions?.includes(subscription?.toLowerCase());
  }, [subscription]);

  const handleSetTrackType = (t: TrackType) => {
    setType(t);
  };

  const renderCustomPlaylist = useCallback(() => {
    return (
      <>
        <UploadNewTrack />
        <AudioPanel className={'manager_audiopanel_container'} isNeedRating={false} isFullWidth isCreator />
        <div className={style.container}>
          <CreatorPlaylist />
          <div className={style.tracks}>
            <SearchTracksWrapper
              type={type}
              search={search}
              setSearch={setSearch}
              isNotNeedToUpdate={isNotNeedToUpdate}
              setNotNeedToUpdate={setNotNeedToUpdate}
            />
            <TrackPanel
              isAdmin={false}
              isCreator
              type={type}
              setType={handleSetTrackType}
              setSearch={setSearch}
              setNotNeedToUpdate={setNotNeedToUpdate}
            />
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [subscription, type, handleSetTrackType, search]);

  const renderNotice = useCallback(() => {
    return (
      <div className={style.notice}>
        <p className={style.text}>
          Features for creating a custom playlist are available only for prepaid plans (<NavLink to="/pricing">Premium</NavLink> or{' '}
          <NavLink to="/pricing">Unlimited</NavLink>).
        </p>
        <p className={style.text}>
          In order to be able to create a custom playlist, you need to buy <NavLink to="/pricing">Premium</NavLink> or{' '}
          <NavLink to="/pricing">Unlimited</NavLink> plan
        </p>
      </div>
    );
    // eslint-disable-next-line
  }, [subscription]);

  const renderPlaylistOptions = useCallback(() => {
    return <>{isFreeUser ? renderNotice() : renderCustomPlaylist()}</>;
    // eslint-disable-next-line
  }, [isFreeUser, type, search]);

  return <div className={style.root}>{renderPlaylistOptions()}</div>;
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchCurrentSubscription: dispatchProps.fetchCurrentSubscription,
    fetchTracks: dispatchProps.fetchTracks,
    setMusicianPlayerInit: dispatchProps.setMusicianPlayerInit,
    ...props,
  };
};

export const PlaylistPage = connect(
  null,
  {
    fetchCurrentSubscription: CurrentSubscriptionCreators.fetchCurrentSubscription,
    fetchTracks: TrackCreators.fetchTracks,
    setMusicianPlayerInit: MusicianPlayerActions.setMusicianPlayerInit,
  },
  mergeProps
)(PlaylistPageInternal);
