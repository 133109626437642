import { PlaylistContent } from 'components/playlistContent';
import { PlaylistControlls } from 'components/playlistControlls';
// eslint-disable-next-line
import React, { useState } from 'react';

import style from './creatorPlaylist.module.scss';

const CreatorPlaylistInternal = () => {
  const [createProcessing, setCreateProcessing] = useState(false);
  return (
    <div className={style.root}>
      <PlaylistControlls createProcessing={createProcessing} setCreateProcessing={setCreateProcessing} />
      <PlaylistContent createProcessing={createProcessing} setCreateProcessing={setCreateProcessing} />
    </div>
  );
};

export const CreatorPlaylist = CreatorPlaylistInternal;
