import { IAppTrack } from 'interfaces/IApp';
import { INewTrack, TrackType } from 'interfaces/INewTrack';
import { ITrackWriter } from 'interfaces/state/TrackInfoState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setNewTracksInit = (): Action => {
  return {
    type: Const.NEW_TRACKS_INIT,
  };
};

export const setNewTracksQFilterInit = (): Action => {
  return {
    type: Const.NEW_TRACKS_Q_FILTER_INIT,
  };
};

export const getNewTracksSuccess = (payload: INewTrack[]) => {
  return {
    type: Const.NEW_TRACKS_SUCCESS,
    payload,
  };
};

export const getNewTrackWithLimit = (payload: INewTrack[]) => {
  return {
    type: Const.NEW_TRACKS_SUCCESS_WITH_LIMIT,
    payload,
  };
};

export const changeTrack = (payload: INewTrack) => {
  return {
    type: Const.TRACKS_CHANGE_ITEM,
    payload,
  };
};

export const addNewMusicTracks = (payload: INewTrack[]) => {
  return {
    type: Const.NEW_TRACKS_MUSIC_ADD_LIST,
    payload,
  };
};

export const getAllTracks = (payload: INewTrack[]) => {
  return {
    type: Const.NEW_TRACKS_ALL_ADD,
    payload,
  };
};

export const addNewToAllTracks = (payload: INewTrack[]) => {
  return {
    type: Const.NEW_TRACKS_ALL_ADD_LIST,
    payload,
  };
};

export const setNewTracksFail = (): Action => {
  return {
    type: Const.NEW_TRACKS_ERROR,
  };
};

export const setNewTracksPending = (): Action => {
  return {
    type: Const.NEW_TRACKS_PENDING,
  };
};

export const editNewTrackAndSave = (payload: Partial<INewTrack>, isEdit?: boolean) => {
  return {
    type: Const.EDIT_AND_SAVE_NEW_ITEM,
    payload,
    isEdit,
  };
};

export const setCurrentTrackInfo = (title: string, id: number, writer: ITrackWriter[], duration: number) => {
  return {
    type: Const.CURRENT_TRACK_INFO,
    title,
    id,
    writer,
    duration,
  };
};

export const setCurrentOrderBy = (payload: string) => {
  return {
    type: Const.CURRENT_ORDER_BY,
    payload,
  };
};

export const setCurrentSortType = (payload: string) => {
  return {
    type: Const.CURRENT_SORT_TYPE,
    payload,
  };
};

export const fetchTrackId = (payload: number | null) => {
  return {
    type: Const.FETCH_TRACK_ID,
    payload,
  };
};

export const selectTrackId = (payload: number) => {
  return {
    type: Const.SELECT_TRACKS,
    payload,
  };
};

export const deleteTrackId = (payload: number) => {
  return {
    type: Const.DEL_SELECT_TRACKS,
    payload,
  };
};

export const getNewTracksForPlaylist = (payload: INewTrack[] | null, fromPlaylist?: boolean) => {
  return {
    type: Const.NEW_TRACKS_FOR_PLAYLIST,
    payload,
    fromPlaylist,
  };
};

export const addNewTrackToPlaylist = (payload: INewTrack) => {
  return {
    type: Const.ADD_TRACK_FOR_PLAYLIST,
    payload,
  };
};

export const setCurrName = (payload: string) => {
  return {
    type: Const.NEW_TRACKS_CURR_NAME,
    payload,
  };
};

export const setCurrDesc = (payload: string) => {
  return {
    type: Const.NEW_TRACKS_CURR_DESC,
    payload,
  };
};

export const setCurrTags = (payload: string) => {
  return {
    type: Const.NEW_TRACKS_CURR_TAGS,
    payload,
  };
};

export const setNewTracksLoaded = (): Action => {
  return {
    type: Const.NEW_TRACKS_LOADED,
  };
};

export const setSelectedTracksToDefault = (): Action => {
  return {
    type: Const.DEFAULT_SELECTED_TRACKS,
  };
};

export const delAlltracksFromPLaylist = (): Action => {
  return {
    type: Const.DELETE_ALL_TRACKS_FROM_PLAYLIST,
  };
};

export const delTrackFromPLaylist = (payload: number) => {
  return {
    type: Const.DEL_TRACK_FROM_PLAYLIST,
    payload,
  };
};

export const setTrackItemRating = (id: number, payload: number) => {
  return {
    type: Const.CHANGE_CURRENT_INDEX_RATING,
    id,
    payload,
  };
};

export const deleteTrack = (id: number) => {
  return {
    type: Const.DELETE_TRACK_FROM_MUSICIAN,
    id,
  };
};

export const delTracksFromAllTracks = (): Action => {
  return {
    type: Const.DELETE_TRACKS_FROM_ALL_TRACKS,
  };
};

export const setfilterBy = (payload: TrackType) => {
  return {
    type: Const.SET_NEW_TRACK_FILTER_BY,
    payload,
  };
};

export const editTrack = (trackType: 'music' | 'sfx' | 'audio', index: number, value: string, key: string) => {
  return {
    type: Const.EDIT_TRACK_FROM_MUSICIAN,
    trackType,
    index,
    value,
    key,
  };
};

export const setCurrentTrackId = (payload: number) => {
  return {
    type: Const.TRACK_CURRENT_ID,
    payload,
  };
};

export const setDownloadInformation = (loaded: number, total: number, id: number | null) => {
  return {
    type: Const.SET_DOWNLOAD_PROGRESS,
    loaded,
    total,
    id,
  };
};

export const setCurrPlaylistId = (payload: null | number) => {
  return {
    type: Const.SET_CURRENT_PLAYLIST_ID_FOR_SHARED,
    payload,
  };
};

export const setShareType = (payload: 'playlist_sharing' | 'auto_forwarding' | null) => {
  return {
    type: Const.SHARE_TYPE,
    payload,
  };
};

export const setPlaylistTrackRating = (id: number, payload: number) => {
  return {
    type: Const.CHANGE_PLAYLIST_TRACK_RATING,
    payload,
    id,
  };
};

export const setTracksAmount = (payload: number) => {
  return {
    type: Const.NEW_TRACKS_AMOUNT,
    payload,
  };
};

export const setSubmittedSongs = (payload: number) => {
  return {
    type: Const.NEW_TRACKS_SUBMITTED_SONGS,
    payload,
  };
};

export const deleteTrackFromUsertrackList = (payload: INewTrack['track_id']) => {
  return {
    type: Const.DELETE_TRACK_FROM_USER_TRACKLIST,
    payload,
  };
};

export const updateNewTrackAppeared = (payload: boolean) => {
  return {
    type: Const.NEW_TRACKS_APPEARED,
    payload,
  };
};

export interface IEditTrackPayload {
  track_id: number;
  value: string | number | boolean;
  key: keyof INewTrack;
}

export const editTrackById = (payload: IEditTrackPayload) => {
  return {
    type: Const.EDIT_TRACK,
    payload,
  };
};

export const setSelectedTracksFromPlaylist = (payload: IAppTrack[]) => {
  return {
    type: Const.CREATOR_PLAYLIST_TRACKS,
    payload,
  };
};

export const setSelectedTracks = (payload: any) => {
  return {
    type: Const.CREATOR_SET_SELECTED_TRACKS,
    payload,
  };
};
