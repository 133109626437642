import { Action } from 'redux';

import * as Const from '../constants';

export const setAvatarInit = (): Action => {
  return {
    type: Const.AVATAR_INIT,
  };
};

export const getSuccessAvatar = (payload: string) => {
  return {
    type: Const.AVATAR_LOADED,
    payload,
  };
};

export const setAvatarFail = (): Action => {
  return {
    type: Const.AVATAR_ERROR,
  };
};

export const setAvatarLoading = (): Action => {
  return {
    type: Const.AVATAR_PENDING,
  };
};

export const setSuccessLoadAvatar = (): Action => {
  return {
    type: Const.AVATAR_SUCCESS,
  };
};
