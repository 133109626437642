import AudiotrackRoundedIcon from '@material-ui/icons/AudiotrackRounded';
import { InputPopup } from 'components/InputPopup';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import * as TeamCreators from 'creators/team';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { isLoading$ } from 'selectors/team';

import style from './createTeamPopup.module.scss';

interface IDispatchProps {
  createTeamByName: (name: string) => void;
}
interface IStateProps {
  isLoading: boolean;
}
interface OwnProps {
  onClosePopover: () => void;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const CreateTeamPopupComponent = ({ onClosePopover, createTeamByName }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState('');

  const handleOpen = (value: boolean) => {
    setOpen(value);
  };

  const handleSetTeamName = (value: string | number) => setName(value as string);

  const handleCreate = () => {
    if (!name) {
      successNotification('Fill in the input field Name');
      return;
    }

    createTeamByName(name);
    setOpen(false);
  };

  const onOpen = () => {
    handleOpen(true);
  };

  const onClose = () => {
    handleOpen(false);
    onClosePopover();
  };

  return (
    <Popup
      onOpen={onOpen}
      onDecline={onClose}
      isOpen={isOpen}
      btnClassName={style.btnContainer}
      onApply={handleCreate}
      isVisibleOverflow
      buttonText={
        <div className={style.button}>
          <AudiotrackRoundedIcon className={style.icon} />
          <span className={style.btnContent}>Team</span>
        </div>
      }
      applyBtnText={'CREATE TEAM'}
      modalContent={
        <div className="d-flex flex-column">
          <div className={style.label}>Set new team name</div>
          <InputPopup type="text" title="Name" onChange={handleSetTeamName} value={name} maxLength={400} />
        </div>
      }
    />
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    createTeamByName: dispatchProps.createTeamByName,
    ...props,
  };
};

export const CreateTeamPopup = connect(
  mapStateToProps,
  {
    createTeamByName: TeamCreators.createTeamByName,
  },
  mergeProps
)(memo(CreateTeamPopupComponent));
