import Input from 'components/input/input';
// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';

import styles from './style.module.scss';

interface IProps {
  value: string | number;
  title: string;
  onChange: (e: string | number) => void;
  type: 'text' | 'number' | 'radio' | 'range' | 'password' | 'button';
  maxLength: number;
  className?: string;
  disabled?: boolean;
  isError?: boolean;
}

export const InputPopup = ({ value, title, onChange, type, maxLength, className, disabled, isError }: IProps) => {
  const [error, setError] = useState('');

  const handleInputClick = () => setError('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === maxLength) {
      setError(`Can be max ${maxLength} characters long`);
    } else {
      setError('');
      if (type === 'number') {
        const positiveNumber = Math.abs(Number(e.target.value));
        if (positiveNumber === 0) {
          onChange('');
        } else {
          onChange(positiveNumber);
        }
      } else {
        onChange(e.target.value);
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <Input
        containerStyle={{ width: '100%' }}
        style={{ width: '100%' }}
        styleClass={`${styles.input} ${className ? className : ''}`}
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={''}
        onClick={handleInputClick}
        error={error}
        maxLength={maxLength}
        disabled={disabled}
        isError={isError}
      />
    </div>
  );
};
