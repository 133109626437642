import * as Const from '../constants';
import State from '../interfaces/state/ConfirmState';

const initState: State = {
  email: '',
  token: '',
  isLoading: false,
  isError: false,
  isConfirmed: null,
};

export default function confirm(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.CONFIRM_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.CONFIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isConfirmed: true,
      };

    case Const.CONFIRM_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isConfirmed: false,
      };

    case Const.CONFIRM_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isConfirmed: null,
      };

    case Const.CONFIRM_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case Const.CONFIRM_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
}
