import * as RegisterFromInviteActions from 'actions/registerFromInvite';
import classNames from 'classnames';
import Button from 'components/button/button';
import Input from 'components/input/input';
import * as Const from 'constants/index';
import * as AuthCreators from 'creators/auth';
import * as ConfirmCreators from 'creators/confrirm';
import * as RegisterFromInviteCreators from 'creators/registerFromInvite';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, FormEvent, memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { isAuth$, isLoading$ } from 'selectors/auth';
import { email$, isError$, token$ } from 'selectors/confirm';
import { isSuccess$ } from 'selectors/registerFromInvite';
import { usePrevious } from 'utils';

import style from './style.module.scss';

interface IStateProps {
  token: string;
  isSuccess: boolean;
  isAuth: boolean;
  email: string;
  isError: boolean;
  isLoading: boolean;
}

interface IDispatchProps {
  registerFromInvite: (user_name: string, pass: string) => void;
  setRegisterFromInviteInit: () => void;
  onLogout: (token?: string) => void;
  getRegisterEmail: (token: string) => void;
}

interface OwnProps {}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const Form = ({
  token,
  registerFromInvite,
  isSuccess,
  setRegisterFromInviteInit,
  onLogout,
  getRegisterEmail,
  isAuth,
  email,
  isError,
  isLoading,
}: IProps) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [isNameError, setNameError] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordError, setPasswordError] = useState('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [timer, setTimer] = useState(5);
  const prevEmail = usePrevious(email);
  const prevIsAuth = usePrevious(isAuth);
  const prevLoading = usePrevious(isLoading);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 30) {
      setNameError('User name can be max 30 characters long');
      return;
    } else {
      setNameError('');
      setName(e.target.value);
    }
  };

  useCallback(() => {
    if (isSuccess) {
      history.replace('/');
      setRegisterFromInviteInit();
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const redirectSetter = () => {
    let newTime: NodeJS.Timeout;
    if (isSuccess) {
      if (timer === -1) return;
      newTime = setInterval(() => setTimer(prevTime => prevTime - 1), 1000);
    }
    return () => clearInterval(newTime);
  };

  useEffect(() => {
    if (isAuth && token) {
      onLogout(token);
    }
    // eslint-disable-next-line
  }, [isAuth, token]);

  // eslint-disable-next-line
  useEffect(() => redirectSetter(), [timer, isSuccess]);
  useEffect(() => {
    if (!isAuth && token && !email && prevEmail === '' && !isLoading && prevLoading) {
      if (prevIsAuth) return;
      getRegisterEmail(token);
    }
    // eslint-disable-next-line
  }, [isAuth, token, email, prevEmail, isLoading, prevLoading]);

  const handleNameClick = () => setNameError('');
  const inputCX = classNames(style.input, 'inp-primary');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 300) {
      setPasswordError('User name can be max 300 characters long');
      return;
    } else {
      setPasswordError('');
      setPassword(e.target.value);
    }
  };

  const handlePasswordClick = () => setPasswordError('');
  const handleSetShowPass = () => setIsShowPass(!isShowPass);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name) {
      setNameError(Const.REQUIRED_FIELD);
    }
    if (!password) {
      setPasswordError(Const.REQUIRED_FIELD);
    }

    if (name && password && token) {
      registerFromInvite(name, password);
    }
  };
  return (
    <>
      {isSuccess ? (
        <div className={style.redirectPage}>
          <div>We redirect you to Login page after {timer} seconds...</div>
          {timer === 0 ? <Redirect to={'/'} /> : null}
        </div>
      ) : null}
      {token ? (
        <form className={style.form} onSubmit={onSubmit}>
          <h1 className={style.title}>Register an account to gain access</h1>
          <div className={style.email}>{`Email: ${email}`}</div>
          <Input
            styleClass={inputCX}
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder={'User name'}
            onClick={handleNameClick}
            error={isNameError}
            maxLength={30}
          />
          <Input
            styleClass={inputCX}
            type={isShowPass ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            placeholder={'Password'}
            onClick={handlePasswordClick}
            error={isPasswordError}
            isShowPass={isShowPass}
            setShowPass={handleSetShowPass}
            isInputPass
            maxLength={300}
          />
          <Button styleClass="btn-primary" value="SUBMIT" onClick={() => onSubmit} />
        </form>
      ) : (
        isError && (
          <section className={style.errorContainer}>
            An error occurred during registration.
            <span
              style={{
                marginLeft: 10,
              }}
            >
              Click <NavLink to="/help"> here</NavLink>
            </span>
          </section>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    token: token$(state),
    isSuccess: isSuccess$(state),
    isAuth: isAuth$(state),
    email: email$(state),
    isError: isError$(state),
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    registerFromInvite: dispatchProps.registerFromInvite,
    setRegisterFromInviteInit: dispatchProps.setRegisterFromInviteInit,
    onLogout: dispatchProps.onLogout,
    getRegisterEmail: dispatchProps.getRegisterEmail,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    registerFromInvite: RegisterFromInviteCreators.registerFromInvite,
    setRegisterFromInviteInit: RegisterFromInviteActions.setRegisterFromInviteInit,
    onLogout: AuthCreators.onLogout,
    getRegisterEmail: ConfirmCreators.getRegisterEmail,
  },
  mergeProps
)(memo(Form));
