import * as Const from '../constants/index';
import State from '../interfaces/state/DownloadState';

const initState: State = {
  isLoading: false,
  isError: false,
};

export default function downloadTrack(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.DOWNL_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.DOWNL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.DOWNL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.DOWNL_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    default:
      return state;
  }
}
