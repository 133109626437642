export const loginBg = (value: string) => {
  localStorage.setItem('login-background', value);
};

export const getLoginBg = (): string | null => {
  return localStorage.getItem('login-background') || null;
};

export const companyId = (value: string) => {
  localStorage.setItem('company_id', value);
};

export const getCompanyId = (): string | null => {
  return localStorage.getItem('company_id') || null;
};
