import { Badge, withStyles } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { IconRoundButton } from 'components/iconRoundButton';
import Popup from 'components/popup/popup';
import { USER_ADMIN } from 'constants/index';
import JobPopup from 'containers/createNewJobPopup';
import * as TeamCreators from 'creators/team';
import AuthState from 'interfaces/state/AuthState';
import State from 'interfaces/state/State';
import { ITeam } from 'interfaces/state/TeamState';
// eslint-disable-next-line
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { userType$ } from 'selectors/auth';

import { TeamMembersItemPopup } from '../teamMembersItemPopup';
import style from './team.module.scss';

const StyledBadge = withStyles(() => ({
  badge: {
    right: 0,
    top: 17,
    border: `2px solid white`,
    background: '#3f51b5',
    width: 20,
    height: 20,
    color: 'white',
    minWidth: 20,
    fontSize: 9,
  },
}))(Badge);

interface IStateProps {
  userType: AuthState['userType'];
}
interface IDispatchProps {
  deleteTeamById: (id: number) => void;
}
interface OwnProps {
  team: ITeam;
  isCreator?: boolean;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const TeamComponent = ({ team, deleteTeamById, userType, isCreator }: IProps) => {
  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [isOpen, setOpen] = useState(false);
  const onDelete = () => deleteTeamById(team.id);

  const [isOpenMembersPopup, setOpenMembersPopup] = useState(false);

  const onOpenMembersPopup = () => setOpenMembersPopup(true);
  const onCloseMembersPopup = () => setOpenMembersPopup(false);
  return (
    <div className={style.root}>
      <div className={style.name}>{team.name}</div>
      <div className={style.members}>
        {userType !== 'creator' && (
          <Popup
            key={team.id}
            onOpen={onOpenMembersPopup}
            onDecline={onCloseMembersPopup}
            isOpen={isOpenMembersPopup}
            btnClassName={style.btnContainer}
            onApply={onCloseMembersPopup}
            isVisibleOverflow
            isHideCancelButton
            buttonText={
              <IconRoundButton
                icon={
                  <StyledBadge badgeContent={team.members.length || '0'}>
                    <PersonRoundedIcon className={style.actionIcon} />
                  </StyledBadge>
                }
                tooltipText="List of members"
                className="avatar_icon"
                style={{ padding: 0 }}
              />
            }
            applyBtnText={'OK'}
            modalContent={<TeamMembersItemPopup members={team.members} />}
          />
        )}
      </div>
      <div className={style.actions}>
        {!isCreator && (
          <>
            <JobPopup isSelectedTeam isSHAdmin={userType === USER_ADMIN} onClosePopover={() => ''} teams_id={[team.id]} />
            <Popup
              onOpen={onOpen}
              onDecline={onClose}
              isOpen={isOpen}
              btnClassName={style.btnContainer}
              onApply={onDelete}
              isVisibleOverflow
              buttonText={
                <IconRoundButton
                  icon={
                    <div className={style.button}>
                      <DeleteForeverRoundedIcon color="action" className={style.actionIcon} />
                    </div>
                  }
                  tooltipText="Delete Team"
                  className="avatar_icon"
                  style={{ padding: 0 }}
                />
              }
              applyBtnText={'DELETE TEAM'}
              modalContent={
                <div className="d-flex flex-column">
                  <h5 className={style.deleteHead}>Delete team "{team.name}"</h5>
                  <div className={style.deleteText}>Are you sure you want to delete it? If you delete it will be removed forever</div>
                </div>
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userType: userType$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    deleteTeamById: dispatchProps.deleteTeamById,
    ...props,
  };
};

export const Team = connect(
  mapStateToProps,
  {
    deleteTeamById: TeamCreators.deleteTeamById,
  },
  mergeProps
)(TeamComponent);
