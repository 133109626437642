import { CheckboxMaterial } from 'components/checkboxMaterial/checkboxMaterial';
import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { Loader } from 'components/loader/loader';
import { fetchPlaylistBg } from 'creators/shareablePlaylistInfo';
// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playlist_id$ } from 'selectors/applications';
import { background$ } from 'selectors/shareablePlaylistInfo';

import style from './background.module.scss';

const maxSize = 5350000;
const accept = ['.png', '.jpg', 'jpeg'];

interface IProps {
  checked: boolean;
  isLoading: boolean;
  percentage: number;
  setFile: (file: File | null) => void;
  setChecked: (v: boolean) => void;
}

export const Background = ({ setFile, isLoading, checked, setChecked, percentage }: IProps) => {
  const dispatch = useDispatch();
  const id = useSelector(playlist_id$);
  const background = useSelector(background$);

  useEffect(() => {
    if (id) {
      dispatch(fetchPlaylistBg(id!));
    }
    // eslint-disable-next-line
  }, [id]);

  const handleSendBgImage = async (file: File[] | File) => {
    loadNewBg(file as File);
  };

  const onDropReject = () => {
    errorNotification('Maximum upload size is 5Mb and image must be a .png, .jpg, .jpeg');
  };

  const loadNewBg = async (f: File) => {
    setFile(f);
  };

  const onChange = () => {
    setChecked(!checked);
  };

  return (
    <div className={style.root}>
      {isLoading && (
        <div className={style.loading}>
          <Loader />
        </div>
      )}
      <div className={style.title}>Background for playlist</div>
      <div className={style.content}>
        <div className={style.subtitle}>You can upload background for this playlist or do it default for all your playlists</div>
        <CheckboxMaterial
          value={'checked'}
          checked={!!checked}
          onChange={onChange}
          className="music-track-check"
          name={'checked'}
          color="primary"
          label="Make this background default for all playlists"
          disabled={isLoading}
        />
        <div className={style.imageContent}>
          <div className={style.dropzone}>
            <div className={style.text}>{`Upload background ${percentage > 0 ? `(${percentage.toFixed(1)}% loaded)` : ''}`}</div>

            <Dropzone
              onAccept={handleSendBgImage}
              onDropReject={onDropReject}
              maxSize={maxSize}
              accept={accept}
              disabled={isLoading}
              text={isLoading ? <div>Image processing</div> : undefined}
            />
          </div>
          {!!background && (
            <div className={style.backgroundWrapper}>
              <span className={style.bgTitle}>Bg for this playlist or for all playlists</span>
              <div
                className={style.bgContent}
                style={background ? { background: `no-repeat url(${background})`, backgroundSize: 'contain' } : {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
