import { Tooltip } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import cx from 'classnames';
// eslint-disable-next-line
import React from 'react';

import style from './linkMark.module.scss';

interface ILInkMarkProps {
  value: boolean;
  positiveTitle: string;
  negativeTitle: string;
}

export const LinkMark = ({ value, positiveTitle, negativeTitle }: ILInkMarkProps) => {
  const IconCX = cx({
    [style.selected]: value,
    [style.notSelected]: !value,
  });

  return (
    <Tooltip title={value ? positiveTitle : negativeTitle}>
      <span className={style.container}>
        <CheckCircleRoundedIcon className={IconCX} />
      </span>
    </Tooltip>
  );
};
