import * as Const from '../constants/index';
import State from '../interfaces/state/CompaniesState';

const initState: State = {
  list: [],
  currentCompany: null,
  isLoading: false,
  isError: false,
};

export default function companies(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.COMPANIES_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.COMPANIES_LOADED:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANIES_ADD_NEW:
      const companies = [...state.list];
      const newCompanies = [action.payload, ...companies];
      return {
        ...state,
        list: newCompanies,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANIES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.COMPANIES_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.COMPANIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANIES_CURRENT:
      return {
        ...state,
        currentCompany: action.payload,
      };

    case Const.COMPANIES_DELETE_MEMBER:
      const companiesList = [...state.list];
      const currentCompanyIndex = companiesList.findIndex(company => company.id === action.company_id);
      if (currentCompanyIndex < 0) return state;
      const filteredMembers = companiesList[currentCompanyIndex!].members.filter(member => member.id !== action.member_id);
      companiesList[currentCompanyIndex].members = filteredMembers;

      return {
        ...state,
        list: companiesList,
      };

    default:
      return state;
  }
}
