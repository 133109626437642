import 'react-circular-progressbar/dist/styles.css';

// eslint-disable-next-line
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

interface IProps {
  percentage: number;
  height: number;
  width: number;
}

const ProgressBar = ({ percentage, width, height }: IProps) => {
  return (
    <div style={{ width: width, height: height }}>
      <CircularProgressbar
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: '#EEF2F4',
          trailColor: '#343B4C',
          textColor: '#343B4C',
        })}
        counterClockwise
        strokeWidth={20}
        value={percentage}
        text={`${percentage}%`}
      />
    </div>
  );
};

export default ProgressBar;
