import { Action } from 'redux';

import * as Const from '../constants';

export const setSubscrLimitInit = (): Action => {
  return {
    type: Const.SUBS_LIMIT_INIT,
  };
};

export const setSubscrLimitPending = (): Action => {
  return {
    type: Const.SUBS_LIMIT_PENDING,
  };
};

export const setSubscrLimitError = (): Action => {
  return {
    type: Const.SUBS_LIMIT_ERROR,
  };
};

export const setSubscrLimit = (payload: string[]) => {
  return {
    type: Const.SUBS_LIMIT_SUCCESS,
    payload,
  };
};
