import './style.scss';

import Modal from '@material-ui/core/Modal';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line
import React, { FC, useState } from 'react';

const getModalStyle = (isVisibleOverflow: boolean | undefined) => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: isVisibleOverflow ? 'visible' : 'auto',
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

interface IProps {
  onOpen?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onApply?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDecline?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isHideMainButton?: boolean;
  buttonText?: string | JSX.Element | FC;
  modalContent: JSX.Element;
  btnClassName?: string;
  disabled?: boolean;
  isApplyDisabled?: boolean;
  applyBtnText?: string;
  cancelBtnText?: string;
  isOpen?: boolean;
  isHideButtons?: boolean;
  disableBackdropClick?: boolean;
  isVisibleOverflow?: boolean;
  isHideApplyButton?: boolean;
  isHideCancelButton?: boolean;
}

const Popup = ({
  onOpen,
  disabled = false,
  isApplyDisabled = false,
  buttonText,
  modalContent,
  onApply,
  onDecline,
  btnClassName,
  applyBtnText,
  cancelBtnText,
  isOpen,
  isHideButtons,
  disableBackdropClick,
  isVisibleOverflow,
  isHideApplyButton,
  isHideCancelButton,
  isHideMainButton,
}: IProps) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle(isVisibleOverflow));
  const [open, setModalOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setModalOpen(true);

    if (onOpen) onOpen(e);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onApply) {
      onApply(e);
      setModalOpen(false);
    }
  };

  const hadleDecline = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setModalOpen(false);
    if (onDecline) onDecline(e);
  };

  const isClose = () => {
    if (isOpen === false || isOpen) {
      return isOpen;
    } else {
      return open;
    }
  };

  return (
    <>
      {!isHideMainButton && (
        <button
          disabled={disabled}
          type="button"
          className={btnClassName ? btnClassName : 'btn-primary btn-track ml-auto btn-edit'}
          onClick={e => handleOpen(e)}
        >
          {buttonText ? buttonText : 'Open'}
        </button>
      )}
      <Modal
        disableBackdropClick={disableBackdropClick}
        keepMounted={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isClose()}
        onClose={handleClose}
        style={{ overflow: isVisibleOverflow ? 'visible' : 'auto' }}
        BackdropProps={{
          style: { backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 0 },
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            {modalContent}
            {!isHideButtons ? (
              <div className="d-flex justify-center">
                {!isHideApplyButton && (
                  <button disabled={isApplyDisabled} className="btn-upload-left btn-upload" onClick={e => handleApply(e)}>
                    {applyBtnText ? applyBtnText : 'APPLY'}
                  </button>
                )}
                {!isHideCancelButton && (
                  <button className="btn-upload-right btn-upload" onClick={e => hadleDecline(e)}>
                    {cancelBtnText ? cancelBtnText : 'CANCEL'}
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </>
      </Modal>
    </>
  );
};

export default Popup;
