import './style.scss';

import { setCurrInitPlaylist } from 'actions/application';
import cx from 'classnames';
import { ExpansionPanelInternal } from 'components/ExpansionPanelInternal/ExpansionPanelInternal';
import Popup from 'components/popup/popup';
import { updateJobById } from 'creators/musicianJobs';
import IApp from 'interfaces/IApp';
import { IMusicianJob } from 'interfaces/IMusicianJob';
import { SubscriptionName } from 'interfaces/state/SubscriptionList';
// eslint-disable-next-line
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'utils';

import { JobContent } from '../JobContent';
import { JobHead } from '../jobHead';
import style from './jobWithPlaylistItem.module.scss';

interface IDispatchProps {}
interface IStateProps {}
interface OwnProps {
  job: IMusicianJob;
  current_job: number | null;
  subscription: SubscriptionName;
  setCurrentJobId: (payload: number | null) => void;
  setCurrPlaylist: (playlist: IApp | null) => void;
  setCurrentMusicianJob: (payload: IMusicianJob | null) => void;
}
interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const JobWithPlaylistItemInternal = ({
  job,
  current_job,
  subscription,
  setCurrentJobId,
  setCurrPlaylist,
  setCurrentMusicianJob,
}: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenPopup, setOpenPopup] = useState(false as undefined | boolean);
  const history = useHistory();
  const memoJob = useMemo(() => job, [job]);
  const memoCurrJob = useMemo(() => current_job, [current_job]);
  const dispatch = useDispatch();
  const isDisabledJob = () => {
    return !memoJob.tracks_allowed_amount || memoJob.is_exceeded_by_job_limit || memoJob.is_blocked;
  };

  const isDisabledJobBySubscriptionLimit = () => {
    return memoJob.is_exceeded_by_subscription_limit || (!memoJob.tracks_allowed_amount && subscription?.toLowerCase() === 'free');
  };

  const onOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(updateJobById(memoJob.id));
      setOpen(isOpen);
      if (isOpen) {
        setCurrentJobId(memoJob.id);
        setCurrentMusicianJob(memoJob);
      } else {
        setCurrentJobId(null);
        setCurrPlaylist(null);
        dispatch(setCurrInitPlaylist(null));
        setCurrentMusicianJob(null);
      }
    },
    // eslint-disable-next-line
    [memoJob]
  );

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const onDeclinePopup = () => {
    setOpenPopup(false);
  };

  const onApplyPopup = () => {
    history.push('/pricing');
  };

  const isBlockedJobText = () => {
    if (Array.isArray(memoJob.available_for_plans)) {
      const plans = memoJob.available_for_plans.map(plan => ' ' + plan);
      return `Your Subscription plan must be one of ${plans}!`;
    } else if (typeof memoJob.available_for_plans === 'string') {
      return `Your Subscription plan must be ${memoJob.available_for_plans}!`;
    } else {
      return null;
    }
  };

  const memoOpen = useMemo(() => {
    return isOpen && memoCurrJob === memoJob.id;
  }, [isOpen, memoCurrJob, memoJob]);
  const prevMemoOpen = usePrevious(memoOpen);

  useEffect(() => {
    if (prevMemoOpen && !memoOpen) {
      dispatch(updateJobById(memoJob.id, true));
    }
  }, [memoJob, prevMemoOpen, memoOpen, dispatch]);

  const jobCX = cx(style.job, {
    [style.open]: memoJob.status === 'open',
    [style.closed]: memoJob.status === 'closed',
    [style.deleted]: memoJob.status === 'deleted',
    [style.archived]: memoJob.status === 'archived',
    [style.disabledWithLimit]: isDisabledJob() && !isDisabledJobBySubscriptionLimit(),
    [style.limit]: isDisabledJobBySubscriptionLimit(),
  });

  return (
    <div className={style.root}>
      <ExpansionPanelInternal
        head={<JobHead job={memoJob} />}
        open={memoOpen}
        setOpen={onOpen}
        content={memoOpen && <JobContent job={memoJob} isOpen={memoOpen} />}
        summaryStyle={{ margin: 0 }}
        style={{ width: 'calc(100% - 15px)', boxShadow: 'none', border: '2px solid #46464617' }}
        className={jobCX}
      />

      {(!!isDisabledJobBySubscriptionLimit() && memoJob.status === 'open') || (memoJob.is_blocked && memoJob.status === 'open') ? (
        <Popup
          onOpen={handleOpenPopup}
          onApply={onApplyPopup}
          applyBtnText={'Upgrade plan'}
          isOpen={isOpenPopup}
          btnClassName={style.btnApply}
          onDecline={onDeclinePopup}
          buttonText={'Upgrade plan'}
          modalContent={
            <div className={'music-plan-container'}>
              <div className={'music-plan-head'}>Your current plan is limited</div>
              <div className={'music-plan-text'}>Buy a new plan for the best experience</div>
              {memoJob.is_blocked && <div className={'music-plan-text'}>{isBlockedJobText()}</div>}
            </div>
          }
        />
      ) : null}
    </div>
  );
};

export const JobWithPlaylistItem = JobWithPlaylistItemInternal;
