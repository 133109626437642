import { Tooltip } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import * as NewJobAvatarActions from 'actions/newJobAvatar';
import cx from 'classnames';
import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { IconRoundButton } from 'components/iconRoundButton';
import { InputPopup } from 'components/InputPopup';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import { SliderMaterial } from 'components/sliderMaterial/sliderMaterial';
import { Textarea } from 'components/textArea';
import * as CompaniesCreators from 'creators/companies';
import { updateJob } from 'creators/companyJob';
import * as MusicianJobsCreators from 'creators/musicianJobs';
import { BuyerType } from 'creators/musicianJobs';
import * as NewJobAvatarCreators from 'creators/newJobAvatar';
import * as SubscriptionLimitCreators from 'creators/subscriptionLimit';
import { ICompanyJob } from 'interfaces/ICompanyJob';
import { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';
import { companies$, currentCompany$, isLoading$ } from 'selectors/companies';
import { selectedJobsType$ } from 'selectors/companyJob';
import { isLoading$ as isLoadingNewJob$ } from 'selectors/musicianJobs';
import { avatar$ } from 'selectors/newJobAvatar';
import { subscrLimits$ } from 'selectors/subscriptionLimit';
import { usePrevious } from 'utils';

import { CreateNewJobPopoverContent } from './createNewJobPopoverContent';
import { JobTypesSelect } from './jobTypes';
import styles from './style.module.scss';

const buyers = ['Direct Licensor / Buyer', 'Reseller', 'Hiring agent', 'Opportunity only'];

interface IDispatchProps {
  createNewJob: (newJob: Partial<ICompanyJob>, setNewStep: (step: number) => void) => void;
  fetchAllMusicianJobs: (status: string, o?: number, l?: number) => void;
  setNewJobAvatarFileId: (file: File) => void;
  fetchCompanyList: () => void;
  fetchAllCompanies: (isAdmin: boolean) => void;
  setNewJobAvatarInit: () => void;
  fetchSubscriptionLimit: () => void;
}

interface IStateProps {
  companies: ICompany[];
  avatar: string;
  currentCompany: ICompany | null;
  selectedJobsType: 'all' | 'deleted' | 'closed' | 'archived' | 'open';
  subscrLimits: string[];
}

interface OwnProps {
  isSHAdmin: boolean;
  onClosePopover: () => void;
  isSelectedTeam?: boolean;
  teams_id?: number[];
  isEdit?: boolean;
  job?: ICompanyJob;
}

const initialState: Partial<ICompanyJob> = {
  budget: '',
  title: '',
  description: '',
  buyer_type: buyers[0] as BuyerType,
  access_to: 10,
  access_from: 0,
  company_id: 0,
  tracks_per_user_limit: 10,
  subscription_limitation: 'All',
  link_1: '',
  link_2: '',
  type: 'Other',
};

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const JobPopup = ({
  createNewJob,
  setNewJobAvatarFileId,
  fetchCompanyList,
  fetchAllCompanies,
  setNewJobAvatarInit,
  onClosePopover,
  fetchSubscriptionLimit,
  companies,
  isSHAdmin,
  avatar,
  currentCompany,
  isSelectedTeam,
  teams_id,
  subscrLimits,
  isEdit,
  job,
}: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userType = useSelector(userType$);
  const isLoading = useSelector(isLoading$);
  const isPreviosLoading = usePrevious(isLoading);
  const isLoadingNewJob = useSelector(isLoadingNewJob$);
  const [selectCompanyValue, setSelectedCompany] = useState({ label: '', value: '' } as ISelectItem);
  const [hasError, setHasError] = useState(false);

  const [state, setState] = useState(initialState as Partial<ICompanyJob>);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [step, setStep] = useState(1);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (isEdit) {
      if (!Number(job?.access_to)) {
        setState({ ...state, ...job, access_to: 10 });
      } else if (!Number(job?.access_from)) {
        setState({ ...state, ...job, access_from: 0 });
      } else {
        setState({ ...state, ...job });
      }
    }
    // eslint-disable-next-line
  }, [isEdit, job]);

  useEffect(() => {
    if (isEdit && companies?.length) {
      const company = companies?.find(c => c.name === job?.company);

      if (company?.id) {
        setState(prev => ({ ...prev, company_id: company?.id }));
      }
    }
  }, [isEdit, companies, job]);

  const handleOpenPopup = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpen = (isOpen: boolean) => setOpen(isOpen);

  const setInitialState = () => {
    setState(initialState);
    handleOpen(false);
    setNewJobAvatarInit();
  };

  const handleCreate = async () => {
    if (step === 1) {
      if (!state.budget || !state.title || !state.description || !state.company) {
        errorNotification('Fill in the empty fields');
        setHasError(true);
        return;
      }
      setHasError(false);

      const newJob = {
        ...state,
        ...{ teams_id: teams_id ? teams_id : undefined },
        tracks_per_user_limit: state.type === 'DashRadio' || state.type === 'Opportunity Only' ? 1000 : state.tracks_per_user_limit,
      };

      if (!isEdit) {
        createNewJob(newJob! as Partial<ICompanyJob>, handleStep);
        setStep(1);
      } else {
        dispatch(updateJob({ ...job, ...state, avatar_url: job?.avatar_url } as Partial<ICompanyJob>));
        setOpen(false);
        setStep(1);
      }
    } else {
      handleOpen(false);
      setInitialState();
      onClosePopover();
      setStep(1);
    }
  };

  const handleStep = (step: number) => setStep(step);

  const setNewJobValue = (
    key: 'budget' | 'title' | 'description' | 'tracks_per_user_limit' | 'link_1' | 'link_2',
    value: string | number
  ) => {
    if (key === 'tracks_per_user_limit' && value > 1000) return;
    setState({ ...state, [key]: value });
  };

  const buyersList = () => buyers.map((buyer: string) => ({ label: buyer.replace(/_|-/g, ' '), value: buyer })) as ISelectItem[];
  const subscrList = () => subscrLimits.map((subscr: string) => ({ label: subscr, value: subscr })) as ISelectItem[];
  const companyList = () => companies.map(company => ({ label: company.name, value: company.id })) as ISelectItem[];
  const handleBuyerChange = (val: string | number) => setState({ ...state, buyer_type: val as MusicianJobsCreators.BuyerType });
  const handleTypeChange = (val: string | number) => setState({ ...state, type: val as MusicianJobsCreators.BuyerType });
  const handleSubscrChange = (val: string | number) => setState({ ...state, subscription_limitation: val as string });

  const handleCompanyChange = useCallback(
    (val: ISelectItem) => {
      setState({ ...state, company_id: Number(val.value) as number, company: val.label });
      setSelectedCompany({ value: Number(val.value), label: val.label } as ISelectItem);
    },
    [state]
  );

  const selectBuyerValue = { value: state.buyer_type!, label: state!.buyer_type!.replace(/_|-/g, ' ') };
  const selectType = { value: state.type!, label: state.type! };
  const subscrValue = { value: state.subscription_limitation!, label: state.subscription_limitation! };

  useEffect(() => {
    if (isOpen && isEdit && !!job?.company && currentCompany?.id) {
      setSelectedCompany({ value: Number(currentCompany?.id), label: job?.company } as ISelectItem);
    }
  }, [isEdit, isOpen, job, currentCompany]);

  const handleSendAvatarImage = async (file: File[] | File) => {
    setNewJobAvatarFileId(file![0]);
  };

  const onDropReject = () => {
    errorNotification('Maximum upload size is 1Mb and image must be a .png, .jpg, .jpeg');
  };

  const accept = ['.png', '.jpg', 'jpeg'];
  const maxSize = 1070000;

  useEffect(() => {
    if (isSHAdmin && !companies?.length && !isLoading) {
      if (!isPreviosLoading && !companies.length) {
        fetchAllCompanies(isSHAdmin);
      } else {
        fetchAllCompanies(isSHAdmin);
      }
    } else if (!isSHAdmin && !companies?.length && !isLoading) {
      if (!isPreviosLoading && !companies.length) {
        return;
      } else {
        fetchCompanyList();
      }
    }
    // eslint-disable-next-line
  }, [isSHAdmin, companies, isLoading]);

  useEffect(() => {
    if (isOpen) fetchSubscriptionLimit();
    // eslint-disable-next-line
  }, [isOpen]);

  const onOpenPopup = () => {
    handleOpen(true);
    setNewJobAvatarInit();
  };

  const handleChange = (_e: ChangeEvent<{}>, rating: number | number[]) => {
    setState({ ...state, access_to: 10, access_from: rating as number });
  };

  const onDecline = () => {
    if (!isEdit) {
      setInitialState();
    }
    setOpen(false);
    onClosePopover();
    setStep(1);
  };

  const BtnCX = cx({
    [styles.newJobBtn]: !isEdit,
    [styles.btnPopup]: isEdit,
    [styles.withoutBorder]: isSelectedTeam,
  });

  const applyText = useMemo(() => {
    if (isEdit) {
      return 'APPLY';
    } else {
      return isLoadingNewJob ? 'LOADING' : step === 1 ? 'NEXT' : 'CREATE';
    }
  }, [step, isEdit, isLoadingNewJob]);

  const renderBtnContent = useMemo(() => {
    return !isEdit ? (
      <div className={styles.btnContent}>
        {!isSelectedTeam ? (
          <WorkRoundedIcon className={styles.icon} />
        ) : (
          <Tooltip title={'Create a new job from this team'}>
            <WorkRoundedIcon className={styles.icon} />
          </Tooltip>
        )}
        {!isSelectedTeam && <span className={styles.title}>Job</span>}
      </div>
    ) : (
      <IconRoundButton tooltipText="Edit job" icon={<EditRoundedIcon />} size="small" />
    );
  }, [isEdit, isSelectedTeam]);

  return (
    <Popup
      onOpen={onOpenPopup}
      onDecline={onDecline}
      isOpen={isOpen}
      btnClassName={BtnCX}
      onApply={handleCreate}
      isVisibleOverflow
      buttonText={renderBtnContent}
      applyBtnText={applyText}
      isApplyDisabled={isLoadingNewJob}
      modalContent={
        <>
          {isLoadingNewJob && <div className={styles.loader}>We are creating a new job. Please wait</div>}
          {isOpen && !isLoadingNewJob && (
            <div className={styles.wrap}>
              <div className={styles.wrapItem}>
                <div className={styles.header}>{isEdit ? `Edit a Job ${job?.title}` : `Create a new Job ${step}/2`}</div>
              </div>
              <div className={styles.content}>
                {step === 1 ? (
                  <>
                    <InputPopup
                      type="text"
                      title="Title"
                      onChange={e => setNewJobValue('title', e)}
                      value={state.title!}
                      maxLength={50}
                      isError={hasError && !state.title}
                    />
                    <Textarea
                      title="Description"
                      onChange={e => setNewJobValue('description', e)}
                      value={state.description!}
                      maxLength={2000}
                      isError={hasError && !state.description}
                    />
                    <InputPopup
                      type="text"
                      title="Budget"
                      onChange={e => setNewJobValue('budget', e)}
                      value={state.budget!}
                      maxLength={300}
                      isError={hasError && !state.budget}
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex align-center ">
                        <div className="d-flex flex-column m-r-5">
                          <span className={styles.fakePlaceholder}>Job type</span>
                          <JobTypesSelect value={selectType!} setType={handleTypeChange} />
                        </div>

                        <div className="d-flex flex-column">
                          <span className={styles.fakePlaceholder}>What is your role in this job</span>
                          <MaterialSelect
                            value={selectBuyerValue}
                            placeholder="Select Buyer type"
                            selectItems={buyersList()}
                            onChange={handleBuyerChange}
                            className={styles.selectBuyer}
                            width={270}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-center m-t-5">
                        <div className="d-flex flex-column">
                          <span className={styles.fakePlaceholder}>Choose a company</span>
                          <MaterialSelect
                            value={selectCompanyValue}
                            placeholder="Choose a company"
                            selectItems={companyList()}
                            className={styles.selectCompany}
                            isAllValueNeed
                            onChangeAllValue={handleCompanyChange}
                            isError={hasError && !state.company_id}
                          />
                        </div>
                        {state.type! === 'DashRadio' ||
                          (state.type === 'Opportunity Only' ? null : (
                            <div className={`d-flex flex-column ${styles.limit}`}>
                              <InputPopup
                                type="number"
                                title="Limit submissions per user to"
                                onChange={e => setNewJobValue('tracks_per_user_limit', e)}
                                value={state.tracks_per_user_limit!}
                                maxLength={50}
                                className={styles.limitPer}
                                isError={hasError && !state.tracks_per_user_limit}
                              />
                            </div>
                          ))}
                        <div className="d-flex flex-column">
                          <span className={styles.fakePlaceholder_two}>
                            Limiting users by quality rating
                            <IconRoundButton
                              icon={<HelpOutlineRoundedIcon className={styles.faqIcon} />}
                              onClick={handleOpenPopup}
                              tooltipText="Help"
                            />
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              verticalAnchor="bottom"
                              horizontalAnchor="center"
                              verticalTransform="top"
                              horizontalTransform="center"
                              content={<CreateNewJobPopoverContent />}
                            />
                          </span>
                          <div className={styles.rating}>
                            <SliderMaterial
                              from={state.access_from!}
                              to={state.access_from!}
                              onChange={handleChange}
                              max={10}
                              min={0}
                              step={1}
                              displayLabel={'on'}
                            />
                          </div>
                        </div>
                      </div>

                      <InputPopup
                        type="text"
                        title="Example Link"
                        onChange={e => setNewJobValue('link_1', e)}
                        value={state.link_1!}
                        maxLength={200}
                        isError={hasError && !state.link_1}
                      />
                      <InputPopup
                        type="text"
                        title="Example Link"
                        onChange={e => setNewJobValue('link_2', e)}
                        value={state.link_2!}
                        maxLength={200}
                      />

                      {userType !== 'manager' && (
                        <div className="d-flex align-center m-t-5">
                          <div className="d-flex flex-column">
                            <span className={styles.fakePlaceholder}>For subscription</span>
                            <MaterialSelect
                              value={subscrValue}
                              placeholder="For subscription"
                              selectItems={subscrList()}
                              onChange={handleSubscrChange}
                              className={styles.selectBuyer}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                {step === 2 && !isEdit ? (
                  <div className={styles.avatarContent}>
                    <h2 className={styles.title}>Upload Job avatar</h2>
                    <p className={styles.text}>You can upload an avatar with an aspect ratio of 1 to 1 (Example W: 100px | H: 100px) </p>
                    <div className={styles.avatarWrapper}>
                      {!!avatar ? (
                        <img src={avatar} className={styles.avatar} loading="lazy" alt="avatar" />
                      ) : (
                        <Dropzone onAccept={handleSendAvatarImage} onDropReject={onDropReject} maxSize={maxSize} accept={accept} />
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    avatar: avatar$(state),
    companies: companies$(state),
    currentCompany: currentCompany$(state),
    selectedJobsType: selectedJobsType$(state),
    subscrLimits: subscrLimits$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    createNewJob: dispatchProps.createNewJob,
    fetchAllMusicianJobs: dispatchProps.fetchAllMusicianJobs,
    setNewJobAvatarFileId: dispatchProps.setNewJobAvatarFileId,
    fetchCompanyList: dispatchProps.fetchCompanyList,
    fetchAllCompanies: dispatchProps.fetchAllCompanies,
    setNewJobAvatarInit: dispatchProps.setNewJobAvatarInit,
    fetchSubscriptionLimit: dispatchProps.fetchSubscriptionLimit,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    createNewJob: MusicianJobsCreators.createNewJob,
    fetchAllMusicianJobs: MusicianJobsCreators.fetchAllMusicianJobs,
    setNewJobAvatarFileId: NewJobAvatarCreators.setNewJobAvatarFileId,
    fetchCompanyList: CompaniesCreators.fetchCompanyList,
    fetchAllCompanies: CompaniesCreators.fetchAllCompanies,
    setNewJobAvatarInit: NewJobAvatarActions.setNewJobAvatarInit,
    fetchSubscriptionLimit: SubscriptionLimitCreators.fetchSubscriptionLimit,
  },
  mergeProps
)(memo(JobPopup));
