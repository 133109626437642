import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import cx from 'classnames';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { IconRoundButton } from 'components/iconRoundButton';
import { SelectInternal } from 'components/select/select';
import { successNotification } from 'components/successNotification/successNotification';
import { IMasterOwner, INewTrackType } from 'interfaces/INewTrack';
import { ITrackPublisher, ITrackWriter } from 'interfaces/state/TrackInfoState';
// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import NumericInput from 'react-numeric-input';
import { proInputItems, trackTypes } from 'utils';

import styles from './musicianEditTrackContent.module.scss';

interface IIemInputProps {
  value: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  title: string;
  disabled?: boolean;
  width?: number;
  type: 'text' | 'number';
  maxLength?: number;
  error?: boolean;
  errorText?: string;
}

const ItemInput = ({ value, onChange, title, disabled, width, type, maxLength, error, errorText }: IIemInputProps) => {
  const [isError, setError] = useState(false);
  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (maxLength) {
      if (e.target.value?.length > maxLength) {
        setError(true);
        return;
      } else {
        setError(false);
        onChange && onChange(e);
      }
    } else {
      onChange && onChange(e);
    }
  };

  const inputWrapperCX = cx(styles.itemInput, {
    [styles.itemInputError]: isError || error,
  });

  return (
    <div className={inputWrapperCX}>
      <span className="upload-sub-title">{title}</span>
      <input
        style={width ? { width } : undefined}
        disabled={disabled}
        className="upload-sub-inp"
        type={type ? type : 'text'}
        value={value || ''}
        min={type === 'number' ? 0 : undefined}
        max={type === 'number' ? 100 : undefined}
        step={type === 'number' ? 0.1 : undefined}
        onChange={handleChangeInputValue}
      />
      {isError && <div className={styles.errorText}>{`Error: max character limit is ${maxLength}`}</div>}
      {error && errorText ? <div className={styles.errorText}>{errorText}</div> : null}
    </div>
  );
};

interface INumberProps {
  value: string | number;
  onChange?: (value: number) => void;
  title: string;
}

const NumberInput = ({ value, onChange, title }: INumberProps) => {
  return (
    <div className={styles.itemInput}>
      <span className="upload-sub-title">{title}</span>
      <NumericInput
        step={0.1}
        min={0}
        max={100}
        value={value}
        precision={2}
        onChange={onChange}
        // format={format}
        strict
        className={styles.numericInput}
      />
    </div>
  );
};

interface IProps {
  setInfoAboutTrack: (track_id: number, track: Partial<INewTrackType>, isEdit?: boolean) => void;
  onClosePopup: () => void;
  track: Partial<INewTrackType>;
  index: number;
  track_duration?: number;
}

export const MusicianEditTrackContent = ({ setInfoAboutTrack, onClosePopup, track, track_duration }: IProps) => {
  const emptyWriter = {
    first_name: '',
    last_name: '',
    percentage: '0.00',
    pro: '',
    ipi: '',
  };

  const emptyPublisher = {
    name: '',
    percentage: '0.00',
    pro: '',
    ipi: '',
  };

  const emptyMasterOwner: IMasterOwner = {
    name: '',
    percentage: '0.00',
  };

  const emptyTrack: INewTrackType = {
    artist_name: '',
    name_in_base: '',
    title: '',
    type: '',
    description: '',
    tags: '',
    writers: [emptyWriter],
    publishers: [emptyPublisher],
    master_owners: [emptyMasterOwner],
    track_id: null,
    track_duration: 0,
    file_size: 0,
    rating: null,
    track_type: 'music',
    subscription: 'free',
    formats: [],
    plays_count: 0,
    download_count: 0,
    share_count: 0,
    user_name: '',
  };

  const [localTrack, setLocalTrack] = useState(emptyTrack);
  const [checked, setChecked] = useState(false);

  useEffect(() => setLocalTrack(track as any), [track]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalTrack({ ...localTrack, title: e.target.value });
  };

  const onDescrChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalTrack({ ...localTrack, description: e.target.value });
  };

  const onArtistNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalTrack({ ...localTrack, artist_name: e.target.value });
  };

  const onTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalTrack({ ...localTrack, tags: e.target.value });
  };

  const handleApply = () => onSubAddClick();

  const onSubAddClick = () => {
    if (localTrack.title && localTrack.type && localTrack.description && localTrack.tags && localTrack.artist_name) {
      if (!track.track_id) return;

      const trk = track_duration ? { ...localTrack, track_duration } : localTrack;
      setInfoAboutTrack(track.track_id!, trk, true);
      onClosePopup();
      setChecked(false);
    } else {
      setChecked(true);
      errorNotification('You must fill all the input fields!');
    }
  };

  const handleClose = () => {
    onClosePopup();
  };

  const onAddNewWriter = () => {
    if (localTrack.writers?.length === 10) {
      successNotification('Maximum number of writers is 10');
      return;
    }
    setLocalTrack({ ...localTrack, writers: [...localTrack.writers, emptyWriter] });
  };

  const onAddNewPublisher = () => {
    if (localTrack.publishers?.length === 10) {
      successNotification('Maximum number of publishers is 10');
      return;
    }
    setLocalTrack({ ...localTrack, publishers: [...localTrack.publishers, emptyPublisher] });
  };

  const onAddNewOwner = () => {
    if (localTrack.master_owners?.length === 10) {
      successNotification('Maximum number of master owners is 10');
      return;
    }
    setLocalTrack({ ...localTrack, master_owners: [...localTrack.master_owners, emptyMasterOwner] });
  };

  const setInfoAboutWriter = (index: number, value: string | number, key: string) => {
    const writers = [...localTrack.writers];
    writers[index][key] = value;

    setLocalTrack(prevState => ({ ...prevState, writers: writers }));
  };

  const setInfoAboutPublisher = (index: number, value: string | number, key: string) => {
    const publishers = [...localTrack.publishers];
    publishers[index][key] = value;

    setLocalTrack(prevState => ({ ...prevState, publishers }));
  };

  const setInfoAboutMasterOwner = (index: number, value: string | number, key: string) => {
    const master_owners = [...localTrack.master_owners];
    master_owners[index][key] = value;

    setLocalTrack(prevState => ({ ...prevState, master_owners }));
  };

  const deleteWriter = (index: number) => {
    const writers = [...localTrack.writers];
    const newWriters = writers.filter((_writer, i) => i !== index);

    setLocalTrack(prevState => ({ ...prevState, writers: newWriters }));
  };

  const deletePublisher = (index: number) => {
    const publishers = [...localTrack.publishers];
    const newPublishers = publishers.filter((_publisher, i) => i !== index);

    setLocalTrack(prevState => ({ ...prevState, publishers: newPublishers }));
  };

  const deleteMasterOwner = (index: number) => {
    const owners = [...localTrack.master_owners];
    const newOwners = owners.filter((_publisher, i) => i !== index);

    setLocalTrack(prevState => ({ ...prevState, master_owners: newOwners }));
  };

  const onTypeChange = (type: string) => setLocalTrack(prevState => ({ ...prevState, type }));

  return (
    <div className={styles.uploadContent}>
      <div className={styles.subcontent}>
        <div className={styles.infoWrapper}>
          <div className={styles.content}>
            <div className={styles.selectContainer}>
              <SelectInternal
                className={styles.select}
                name="upload-select"
                value={localTrack.type! || ''}
                onChange={onTypeChange}
                options={trackTypes}
                placeholder={'Select track type'}
                width={100}
              />
            </div>
            <ItemInput
              type="text"
              width={200}
              value={localTrack.artist_name?.length ? localTrack.artist_name : ''}
              onChange={onArtistNameChange}
              title="Artist Name"
              maxLength={100}
              error={checked && !localTrack.artist_name?.length}
              errorText={'Required field'}
            />

            <ItemInput
              type="text"
              width={200}
              value={localTrack.title!}
              onChange={onInputChange}
              title="Track title"
              maxLength={50}
              error={checked && !localTrack!.title?.length}
              errorText={'Required field'}
            />
            <ItemInput
              type="text"
              width={200}
              value={localTrack.description ? localTrack.description : ''}
              onChange={onDescrChange}
              title="Description"
              maxLength={400}
              error={checked && !localTrack!.description?.length}
              errorText={'Required field'}
            />
            <ItemInput
              type="text"
              width={200}
              value={localTrack.tags ? localTrack.tags : ''}
              onChange={onTagChange}
              title="Tags"
              maxLength={200}
              error={checked && !localTrack!.tags?.length}
              errorText={'Required field'}
            />
          </div>

          <div className={styles.writers}>
            <div className={styles.title}>Writers: </div>
            <div className={styles.wrap}>
              {localTrack.writers.map((writer: ITrackWriter, index: number) => (
                <div key={index} className="d-flex align-center flex-wrap">
                  <ItemInput
                    type="text"
                    title="First Name"
                    width={180}
                    value={writer.first_name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInfoAboutWriter(index, e.target.value, 'first_name')}
                    maxLength={300}
                  />

                  <ItemInput
                    type="text"
                    title="Last Name"
                    width={180}
                    value={writer.last_name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInfoAboutWriter(index, e.target.value, 'last_name')}
                    maxLength={300}
                  />

                  <SelectInternal
                    className={styles.select}
                    name="upload-select"
                    value={writer.pro}
                    onChange={item => setInfoAboutWriter(index, item, 'pro')}
                    options={proInputItems}
                    placeholder={'PRO'}
                    width={100}
                  />

                  <NumberInput
                    title="Percentage"
                    value={writer.percentage}
                    onChange={(value: number) => {
                      setInfoAboutWriter(index, value, 'percentage');
                    }}
                  />
                  <ItemInput
                    type="text"
                    title="IPI"
                    width={180}
                    value={writer.ipi}
                    maxLength={300}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInfoAboutWriter(index, e.target.value, 'ipi')}
                  />
                  <IconRoundButton
                    icon={((<HighlightOffRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
                    tooltipText="Delete one of Writers"
                    onClick={() => deleteWriter(index)}
                  />
                </div>
              ))}
            </div>
            <IconRoundButton
              icon={((<AddCircleRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
              tooltipText="Add Writer"
              onClick={onAddNewWriter}
            />
          </div>

          <div className={styles.publishers}>
            <div className={styles.title}>Publishers: </div>
            <div className={styles.wrap}>
              {localTrack.publishers.map((publisher: ITrackPublisher, index: number) => (
                <div key={index} className="d-flex align-center flex-wrap">
                  <ItemInput
                    type="text"
                    title="Name"
                    width={180}
                    maxLength={300}
                    value={publisher.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInfoAboutPublisher(index, e.target.value, 'name')}
                  />
                  <SelectInternal
                    className={styles.select}
                    name="upload-select"
                    value={publisher.pro}
                    onChange={item => setInfoAboutPublisher(index, item, 'pro')}
                    options={proInputItems}
                    placeholder={'PRO'}
                    width={100}
                  />
                  <NumberInput
                    title="Percentage"
                    value={publisher.percentage}
                    onChange={(value: number) => {
                      setInfoAboutPublisher(index, value, 'percentage');
                    }}
                  />
                  <ItemInput
                    type="text"
                    title="IPI"
                    width={180}
                    maxLength={300}
                    value={publisher.ipi}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInfoAboutPublisher(index, e.target.value, 'ipi')}
                  />
                  <IconRoundButton
                    icon={((<HighlightOffRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
                    tooltipText="Delete one of Publishers"
                    onClick={() => deletePublisher(index)}
                  />
                </div>
              ))}
            </div>
            <IconRoundButton
              icon={((<AddCircleRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
              tooltipText="Add Publisher"
              onClick={onAddNewPublisher}
            />
          </div>
          <div className={styles.publishers}>
            <div className={styles.title}>Master owners: </div>
            <div className={styles.wrap}>
              {localTrack.master_owners.map((masterOwner: IMasterOwner, index: number) => (
                <div key={index} className="d-flex align-center flex-wrap">
                  <ItemInput
                    type="text"
                    title="Name"
                    width={180}
                    maxLength={300}
                    value={masterOwner.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInfoAboutMasterOwner(index, e.target.value, 'name')}
                  />

                  <NumberInput
                    title="Percentage"
                    value={masterOwner.percentage}
                    onChange={(value: number) => {
                      setInfoAboutMasterOwner(index, value, 'percentage');
                    }}
                  />

                  <IconRoundButton
                    icon={((<HighlightOffRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
                    tooltipText="Delete one of Master owner"
                    onClick={() => deleteMasterOwner(index)}
                  />
                </div>
              ))}
            </div>
            <IconRoundButton
              icon={((<AddCircleRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
              tooltipText="Add Master owner"
              onClick={onAddNewOwner}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        <div className="d-flex justify-center flex-wrap">
          <button className="btn-upload-left btn-upload" onClick={handleApply}>
            {'APPLY'}
          </button>
          <button className="btn-upload-right btn-upload" onClick={handleClose}>
            {'CANCEL'}
          </button>
        </div>
      </div>
      <IconRoundButton
        icon={((<CancelRoundedIcon />) as unknown) as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>}
        tooltipText="Close"
        onClick={handleClose}
        className={styles.closebtn}
      />
    </div>
  );
};
