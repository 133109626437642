import './style.scss';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// eslint-disable-next-line
import React, { ReactNode } from 'react';

interface IProps {
  value?: string;
  color?: 'primary' | 'secondary' | 'default';
  label?: ReactNode;
  checked: boolean;
  onChange: () => void;
  size?: 'small' | 'medium';
}
export const SwitchMaterial = ({ value, color = 'primary', label, checked, onChange, size = 'small' }: IProps) => {
  return (
    <FormControlLabel
      labelPlacement="end"
      control={<Switch size={size} checked={checked} onChange={onChange} value={value} color={color} />}
      label={label}
    />
  );
};
