import cx from 'classnames';
// eslint-disable-next-line
import React, { useMemo } from 'react';

import style from './style.module.scss';

const headersForCreators = ['avatar', 'name', 'email', 'access', 'stats', 'actions'];
const headers = ['avatar', 'name', 'email', 'access', 'actions'];

interface IProps {
  title?: string;
  userType: 'creator' | 'manager' | 'admin';
}

export const UserTableHead = ({ title, userType }: IProps) => {
  const items = useMemo(() => {
    return userType === 'creator' ? headersForCreators : headers;
  }, [userType]);

  const RootCX = cx(style.root, {
    [style.rootForCreator]: userType === 'creator',
  });
  return (
    <section className={RootCX}>
      <div className={style.title}>{title}</div>
      <div className={style.headersWrap}>
        {items.map(header => (
          <div key={header} className={style[header]}>
            {header}
          </div>
        ))}
      </div>
    </section>
  );
};
