import * as TeamActions from 'actions/team';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const fetchTeamList = (isMusician?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(TeamActions.setTeamPending());

    const url = isMusician ? Api.GetMusicianTeams : Api.GetTeamList;
    axios
      .post(url)
      .then(response => {
        dispatch(TeamActions.setTeamList(response.data.result));
      })
      .catch(error => {
        dispatch(TeamActions.setTeamError());

        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteTeamById = (id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(TeamActions.setTeamPending());
    axios
      .post(Api.DeleteTeam, { id })
      .then(() => {
        dispatch(TeamActions.deleteTeam(id));
      })
      .catch(error => {
        dispatch(TeamActions.setTeamError());

        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};

export const createTeamByName = (name: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(TeamActions.setTeamPending());
    axios
      .post(Api.CreateTeam, { name })
      .then(response => {
        const newTeam = {
          id: response.data.result.team_id,
          name,
          members: [],
        };
        dispatch(TeamActions.createTeam(newTeam));
        successNotification(`Team ${name} was created successfully`);
      })
      .catch(error => {
        dispatch(TeamActions.setTeamError());

        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};
