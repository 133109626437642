import * as TeamInvCreators from 'creators/teamInvites';
import { ITeamInvite } from 'interfaces/state/TeamInviteState';
import moment from 'moment';
// eslint-disable-next-line
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DATE_TIME_FORMAT } from 'utils';

import style from './invite.module.scss';

interface IStateProps {}
interface IDispatchProps {
  acceptInviteStatus: (id: number) => void;
}
interface OwnProps {
  invite: ITeamInvite;
  isCreator?: boolean;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const InviteComponent = ({ invite, isCreator, acceptInviteStatus }: IProps) => {
  const onClick = () => {
    setDisabled(true);
    acceptInviteStatus(invite.id);
  };

  const [isDisabled, setDisabled] = useState(false);
  return (
    <div className={style.root}>
      <div className={style.name}>{invite.team_name}</div>
      <div className={style.name}>{invite.member_name}</div>
      <div className={style.name}>{invite.email}</div>
      <div className={style.name}>
        {isCreator && invite.status === 'pending' ? (
          <button className={style.acceptBtn} onClick={onClick} disabled={isDisabled}>
            Accept
          </button>
        ) : (
          invite.status
        )}
      </div>
      <div className={style.name}>{moment(invite.when_created).format(DATE_TIME_FORMAT)}</div>
    </div>
  );
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    acceptInviteStatus: dispatchProps.acceptInviteStatus,
    ...props,
  };
};

export const Invite = connect(
  null,
  {
    acceptInviteStatus: TeamInvCreators.acceptInviteStatus,
  },
  mergeProps
)(InviteComponent);
