import { Action } from 'redux';

import * as Const from '../constants';

export const setAffiliateInit = (): Action => {
  return {
    type: Const.AFFILIATE_INIT,
  };
};

export const getSuccessAffiliate = (payload: string) => {
  return {
    type: Const.AFFILIATE_SUCCESS,
    payload,
  };
};

export const setAffiliateFail = (): Action => {
  return {
    type: Const.AFFILIATE_ERROR,
  };
};

export const setAffiliateLoading = (): Action => {
  return {
    type: Const.AFFILIATE_PENDING,
  };
};

export const setSuccessSendAffiliate = (): Action => {
  return {
    type: Const.AFFILIATE_SEND_SUCCESS,
  };
};

export const setErrorSendAffiliate = (): Action => {
  return {
    type: Const.AFFILIATE_SEND_ERROR,
  };
};

export const setLoadingSendAffiliate = (): Action => {
  return {
    type: Const.AFFILIATE_SEND_PENDING,
  };
};
