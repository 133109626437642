import { Action } from 'redux';

import * as Const from '../constants';

export const setSiteEventsInit = (): Action => {
  return {
    type: Const.SITE_EVENTS_INIT,
  };
};

export const setUploadSiteEvent = (payload: string | null) => {
  return {
    type: Const.SITE_EVENT_UPLOAD,
    payload,
  };
};

export const setEventListened = (payload: string | null) => {
  return {
    type: Const.SITE_EVENT_LISTENED,
    payload,
  };
};

export const seSiteEventSendSuccess = () => {
  return {
    type: Const.SITE_EVENT_SEND_SUCCESS,
  };
};

export const setEventListenedForStatistic = (payload: string | null) => {
  return {
    type: Const.SITE_EVENT_LISTENED_FOR_STATISTIC,
    payload,
  };
};

export const setSiteEventsPending = (): Action => {
  return {
    type: Const.SITE_EVENT_LOADING,
  };
};

export const setSiteEventsError = (): Action => {
  return {
    type: Const.SITE_EVENT_ERROR,
  };
};
