import { IMusicianJob } from 'interfaces/IMusicianJob';
import moment from 'moment';
// eslint-disable-next-line
import React, { useMemo, memo } from 'react';
import { DATE_FORMAT } from 'utils';

import { Playlist } from '../playlist';
import style from './jobContent.module.scss';

interface IProps {
  job: IMusicianJob;
  isOpen: boolean;
}

const JobContentComponent = ({ job, isOpen }: IProps) => {
  const memoOpen = useMemo(() => isOpen, [isOpen]);
  return (
    <div className={style.root}>
      <div className={style.textContainer}>
        <div className={style.text}>
          <div className={style.bold}>Buyer type:</div>
          {job.buyer_type}
        </div>
        <div className={style.text}>
          <div className={style.bold}>Created on:</div>
          {moment(job.date_time).format(DATE_FORMAT)}
        </div>

        <div className={style.text}>
          <div className={style.bold}>Type:</div>
          {job.type}
        </div>
        {!!job?.link_1 && (
          <div className={style.text}>
            <div className={style.bold}>Example:</div>
            <a
              href={job.link_1!}
              className={style.link}
              referrerPolicy="no-referrer-when-downgrade"
              target="_blank"
              rel="noopener noreferrer"
            >
              {job?.link_1}
            </a>
          </div>
        )}

        {!!job?.link_2 && (
          <div className={style.text}>
            <div className={style.bold}>Example:</div>
            <a
              href={job.link_2!}
              className={style.link}
              referrerPolicy="no-referrer-when-downgrade"
              target="_blank"
              rel="noopener noreferrer"
            >
              {job?.link_2}
            </a>
          </div>
        )}
        <div className={style.desc}>{job.description}</div>
      </div>
      <div className={style.playlistContent}>
        <Playlist job={job} isOpen={memoOpen} />
      </div>
    </div>
  );
};

export const JobContent = memo(JobContentComponent);
