import Popup from 'components/popup/popup';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { errorText$ } from 'selectors/auth';

import { NotApprovedContent } from './components/notApprovedContent';
import { OnHoldContent } from './components/onHoldContent';
import { WrongEmailOrPassContent } from './components/wrongEmailOrPassContent';
import style from './loginErrorPopup.module.scss';

export const LoginErrorPopup = () => {
  const errorText = useSelector(errorText$);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (errorText === 'not_approved' || errorText === 'on_hold' || errorText === 'wrong_email_or_pass') {
      setIsOpen(true);
    }
  }, [errorText]);

  const toggleOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div className={style.root}>
      <Popup
        isOpen={isOpen}
        isHideMainButton
        onOpen={toggleOpen}
        onDecline={toggleOpen}
        onApply={toggleOpen}
        isHideCancelButton
        btnClassName="mus-track-edit"
        applyBtnText={'Ok'}
        modalContent={
          <div className={style.content}>
            {errorText === 'not_approved' && <NotApprovedContent />}
            {errorText === 'on_hold' && <OnHoldContent />}
            {errorText === 'wrong_email_or_pass' && <WrongEmailOrPassContent />}
          </div>
        }
      />
    </div>
  );
};
