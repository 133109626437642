import { ShareableLink } from 'interfaces/state/shareablePlaylistLinksState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setShareablePlaylistLinksInit = (): Action => {
  return {
    type: Const.SH_PLAYLIST_LINKS_INIT,
  };
};

export const setShareablePlaylistLinksSuccess = (): Action => {
  return {
    type: Const.SH_PLAYLIST_LINKS_SUCCESS,
  };
};

export const setShareablePlaylistLinksReceive = (payload: ShareableLink[]) => {
  return {
    type: Const.SH_PLAYLIST_LINKS_RECEIVE,
    payload,
  };
};

export const setShareablePlaylistLinksError = (): Action => {
  return {
    type: Const.SH_PLAYLIST_LINKS_ERROR,
  };
};

export const setShareablePlaylistLinksPending = (): Action => {
  return {
    type: Const.SH_PLAYLIST_LINKS_PENDING,
  };
};

export const setShareablePlaylistLinksAddNew = (payload: ShareableLink) => {
  return {
    type: Const.SH_PLAYLIST_LINKS_ADD_NEW,
    payload,
  };
};
