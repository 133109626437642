import * as AuthCreators from 'creators/auth';
// eslint-disable-next-line
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { loginBg$ } from 'selectors/auth';

import * as pack from '../../../package.json';
import styles from './noAuthorized.module.scss';
import SignInForm from './signInForm';

interface IStateProps {}

interface IDispatchProps {
  logIn: (login: string, pass: string) => void;
}

interface IProps {}

const NoAuthorized = ({ logIn }: IStateProps & IDispatchProps & IProps) => {
  const isButtonGoogle = false;
  const loginBg = useSelector(loginBg$);

  return (
    <div
      className={styles.root}
      style={
        loginBg
          ? {
              background: `no-repeat url(${loginBg})`,
              backgroundColor: 'rgb(52 59 76',
              backgroundPosition: 'center',
              backgroundSize: '100% 100%',
            }
          : {}
      }
    >
      <div className={styles.wrapper}>
        <SignInForm onSubmit={logIn} isGoogle={isButtonGoogle} />
        <span className={styles.forget}>
          <NavLink to="/help">Forgot password?</NavLink>
        </span>
        <div className={styles.version}>
          <span className={styles.versionWord}>v.</span>
          {pack.version}
        </div>
      </div>
    </div>
  );
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: IProps) => {
  return {
    ...stateProps,
    logIn: dispatchProps.logIn,
    ...props,
  };
};

export default connect(
  null,
  {
    logIn: AuthCreators.logIn,
  },
  mergeProps
)(NoAuthorized);
