import State from 'interfaces/state/State';
import orderBy from 'lodash-es/orderBy';
import { createSelector } from 'reselect';

export const localjobs$ = (state: State) => state.companyJobs.jobs;

export const isError$ = (state: State) => state.companyJobs.isError;
export const isLoading$ = (state: State) => state.companyJobs.isLoading;
export const sortedBy = (state: State) => state.companyJobs.sortedBy;

export const sortedBy$ = createSelector([sortedBy], sorted => sorted);
export const orderOfJobs$ = (state: State) => state.companyJobs.orderOfJobs;
export const sortType$ = (state: State) => state.companyJobs.sortType;
export const selectedJobsType$ = (state: State) => state.companyJobs.selectedJobsType;
export const currentNewId$ = (state: State) => state.companyJobs.currentNewId;
export const filteredJobs$ = createSelector(
  localjobs$,
  sortedBy$,
  sortType$,
  selectedJobsType$,
  (localjobs, sortedBy, sortType, selectedJobsType) => {
    return orderBy(localjobs, [sortedBy, /*'status',*/ 'date_time'], sortType).filter(job => {
      if (selectedJobsType === 'all') {
        return job;
      } else {
        return job.status === selectedJobsType;
      }
    });
  }
);

export const jobs$ = createSelector(localjobs$, sortedBy$, sortType$, (localjobs, sortedBy, sortType) => {
  return orderBy(localjobs, [sortedBy, /*'status',*/ 'date_time'], sortType);
});
