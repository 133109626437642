import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import * as UploadActions from 'actions/uploadTrack';
import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { IconRoundButton } from 'components/iconRoundButton';
import { LinearLoader } from 'components/linearLoader/linearLoader';
import { Loader } from 'components/loader/loader';
import { Popover } from 'components/popover/popover';
import * as NewTrackCreators from 'creators/newTracks';
import * as UploadCreators from 'creators/uploadTrack';
import CurrentSubscriptionState from 'interfaces/state/CurrentSubscriptionState';
import State from 'interfaces/state/State';
import moment from 'moment';
// eslint-disable-next-line
import React, { MouseEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  currentSubscription$,
  current_period_end$,
  current_period_start$,
  isLoading$ as isLoadingSubscriptionInfo$,
  is_active_subscription$,
  storage_info$,
} from 'selectors/currentSubscription';
import { trackId$ } from 'selectors/newTrack';
import { isLoading$, progress$ } from 'selectors/uploadTrack';
import { DATE_FORMAT_SHORT, SUPPORTED_FORMATS } from 'utils';
import { getDuration } from 'utils/audio';
import { formatBytes } from 'utils/formatBytes';

import styles from './uploadNewTrack.module.scss';

// const SUPPORTED_FORMATS = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/flac', 'audio/aac', '.aac', 'audio/vnd.dlna.adts'];
interface IStateProps {
  trackId: null | number;
  isLoading: boolean;
  progress: number;
  storage_info: CurrentSubscriptionState['storage_info'];
  is_active_subscription: boolean;
  current_period_start: string;
  current_period_end: string;
  currentSubscription: string;
  isLoadingSubscriptionInfo: boolean;
}

interface IDispatchProps {
  uploadTrack: (file: File) => void;
  deleteTrackById: (id: number) => void;
  setTrackDuration: (dur: number | null) => void;
  setFileSizeOfTrack: (size: number) => void;
}

interface OwnProps {}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}
const UploadNewTrack = ({
  uploadTrack,
  trackId,
  isLoading,
  progress,
  deleteTrackById,
  setTrackDuration,
  setFileSizeOfTrack,
  storage_info,
  is_active_subscription,
  current_period_end,
  current_period_start,
  currentSubscription,
  isLoadingSubscriptionInfo,
}: IProps) => {
  const [isDelete, setDelete] = useState(false);
  let objectURL = null;

  useEffect(() => {
    if (isDelete) {
      if (trackId) deleteTrackById(trackId);
      setDelete(false);
    }
    // eslint-disable-next-line
  }, [isDelete]);
  const onChange = (file: File) => {
    setTrackDuration(0);

    if (!SUPPORTED_FORMATS.includes(file.type)) {
      errorNotification('Unsupported format');
      return;
    }
    setFileSizeOfTrack(file.size);

    uploadTrack(file);
    if (URL) {
      objectURL = URL.createObjectURL(file);
      getDuration(objectURL, duration => {
        setTrackDuration(Math.floor(duration));
      });
    } else if (webkitURL) {
      objectURL = webkitURL.createObjectURL(file);
      getDuration(objectURL, duration => {
        setTrackDuration(Math.floor(duration));
      });
    } else if ('URL' in window) {
      objectURL = window.URL.createObjectURL(file);
      getDuration(objectURL, duration => {
        setTrackDuration(Math.floor(duration));
      });
    } else {
      errorNotification('The application cannot determine the duration of the track');
    }
  };

  const handleUpload = (file: File[] | File) => {
    onChange(file[0]);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorElInfo, setAnchorElInfo] = useState<HTMLButtonElement | null>(null);
  const openInfo = Boolean(anchorElInfo);
  const idInfo = openInfo ? 'simple-popover-info' : undefined;

  const handleClose = () => setAnchorEl(null);
  const handleCloseInfo = () => setAnchorElInfo(null);

  const handleOpenPopup = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenPopupInfo = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElInfo(event.currentTarget);
  };

  const usedStorageValue = () => formatBytes(storage_info.used_mbytes * 1024 * 1024);
  const allStorageValue = () => {
    if (typeof storage_info.all_mbytes === 'string') {
      return storage_info.all_mbytes;
    } else {
      return formatBytes(storage_info.all_mbytes * 1024 * 1024);
    }
  };

  const usedSpace = () => storage_info.used_mbytes as number;

  const active = () =>
    is_active_subscription ? (
      '(Active)'
    ) : (
      <NavLink to="/pricing" className={styles.notActiveLink}>
        Not active
      </NavLink>
    );

  const formatStart = () => moment(current_period_start).format(DATE_FORMAT_SHORT);
  const formatEnd = () => moment(current_period_end).format(DATE_FORMAT_SHORT);
  const startDate = () => (formatStart() === 'Invalid date' ? null : '(' + formatStart());
  const endDate = () => (formatEnd() === 'Invalid date' ? null : '- ' + formatEnd() + ')');

  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        {!isLoadingSubscriptionInfo && (
          <div className={styles.content}>
            <div className={styles.textContainer}>
              <div className={styles.title}>
                Upload content here.(MP3)
                <IconRoundButton
                  icon={<HelpOutlineRoundedIcon className={styles.faqIcon} />}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => handleOpenPopup(event)}
                  tooltipText="Help"
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  verticalAnchor="bottom"
                  horizontalAnchor="center"
                  verticalTransform="top"
                  horizontalTransform="center"
                  content={
                    <div className={styles.popoverContent}>
                      <div className={styles.item}>1 - Upload your MP3 </div>
                      <div className={styles.item}>2 - Add information, writers, publishers, master owners</div>
                      <div className={styles.item}>3 - Click SEND to complete</div>
                      <div className={styles.item}>4 - Or click 'Clear all' to clear all information and start again</div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className={styles.storageContainer}>
              <div className={styles.head}>Storage capacity</div>
              <div className={styles.storageItem}>
                Now in use
                {<span className={styles.size}>{usedStorageValue()}</span>}
                of
                {<span className={styles.size}>{allStorageValue()} </span>}
              </div>
              <div className={styles.storageItem}>
                {` Need more space? `}
                <NavLink to="/pricing">Purchase extra space</NavLink>
              </div>
              <div className={styles.storageItem}>
                Your subscription is <span className={styles.bold}>{`${currentSubscription}`}</span>{' '}
                <span className={styles.isActive}>{active()}</span>
                {startDate()} {endDate()}
              </div>
            </div>
            {is_active_subscription || (!is_active_subscription && usedSpace() < 200) ? (
              <div className={styles.relContent}>
                {!isLoadingSubscriptionInfo && (
                  <>
                    <Dropzone onAccept={handleUpload} accept={[...SUPPORTED_FORMATS]} />
                    {isLoading && (
                      <div className={styles.loader}>
                        <LinearLoader completed={progress} />
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : null}
            {!is_active_subscription && (
              <div className={styles.notActive}>
                <NavLink to="/pricing" className={styles.linkNotActive}>
                  Your subscription is not active
                </NavLink>
                <IconRoundButton
                  icon={<HelpOutlineRoundedIcon fill="red" className={styles.faqIcon} />}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => handleOpenPopupInfo(event)}
                  tooltipText="Help"
                />
                <Popover
                  id={idInfo}
                  open={openInfo}
                  anchorEl={anchorElInfo}
                  onClose={handleCloseInfo}
                  verticalAnchor="bottom"
                  horizontalAnchor="center"
                  verticalTransform="top"
                  horizontalTransform="center"
                  content={
                    <div className={styles.popoverContentInfo}>
                      {!is_active_subscription && usedSpace() > 200 && (
                        <div>
                          You cannot upload more tracks because your subscription has expired and the 200MB limit has been exceeded for a
                          free subscription. If you want to upload more - you need to buy new subscription.
                        </div>
                      )}

                      {!is_active_subscription && usedSpace() < 200 && (
                        <div>If you want to upload more than 200 Mb - you need to buy new subscription.</div>
                      )}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        )}
        {isLoadingSubscriptionInfo && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    trackId: trackId$(state),
    isLoading: isLoading$(state),
    isLoadingSubscriptionInfo: isLoadingSubscriptionInfo$(state),
    progress: progress$(state),
    storage_info: storage_info$(state),
    is_active_subscription: is_active_subscription$(state),
    current_period_start: current_period_start$(state),
    current_period_end: current_period_end$(state),
    currentSubscription: currentSubscription$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    uploadTrack: dispatchProps.uploadTrack,
    deleteTrackById: dispatchProps.deleteTrackById,
    setTrackDuration: dispatchProps.setTrackDuration,
    setFileSizeOfTrack: dispatchProps.setFileSizeOfTrack,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    uploadTrack: UploadCreators.uploadTrack,
    deleteTrackById: NewTrackCreators.deleteTrackById,
    setTrackDuration: UploadActions.setTrackDuration,
    setFileSizeOfTrack: UploadActions.setFileSizeOfTrack,
  },
  mergeProps
)(UploadNewTrack);
