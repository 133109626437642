import * as CurrentSubscrCreators from 'creators/currentSubscription';
import * as SubscriptionListCreators from 'creators/subscriptionList';
// eslint-disable-next-line
import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { findGetParameter } from 'utils/findGetParameter';

import style from './subscriptionList.module.scss';

interface OwnProps {}
interface IDispatchProps {
  fetchSubscriptionList: (id?: string | null) => void;
  fetchCurrentSubscription: () => void;
}
interface IStateProps {}
interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const SubscriptionList = ({ fetchSubscriptionList, fetchCurrentSubscription }: IProps) => {
  useEffect(() => {
    const id = findGetParameter('id');
    fetchSubscriptionList(id);
  }, [fetchSubscriptionList]);

  useEffect(() => fetchCurrentSubscription(), [fetchCurrentSubscription]);

  return <section className={style.root}></section>;
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchSubscriptionList: dispatchProps.fetchSubscriptionList,
    fetchCurrentSubscription: dispatchProps.fetchCurrentSubscription,
    ...props,
  };
};

export default connect(
  null,
  {
    fetchSubscriptionList: SubscriptionListCreators.fetchSubscriptionList,
    fetchCurrentSubscription: CurrentSubscrCreators.fetchCurrentSubscription,
  },
  mergeProps
)(memo(SubscriptionList));
