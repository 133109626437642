import axios from 'axios';
import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { successNotification } from 'components/successNotification/successNotification';
// eslint-disable-next-line
import React, { useState } from 'react';
import { catchedErrorNotification } from 'utils/error';

import style from './imageUploader.module.scss';

interface IProps {
  title: string;
  subtitle: string;
  company_id: number;
  url: string;
  appendParam: string;
  maxSize: number;
  fetchCompanyInfo: () => void;
}
export const ImageUploader = ({ title, subtitle, company_id, url, fetchCompanyInfo, maxSize, appendParam }: IProps) => {
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const onDropReject = () => {
    errorNotification('Maximum upload size is 5Mb and image must be a .png, .jpg, .jpeg');
  };

  const accept = ['.png', '.jpg', 'jpeg'];
  const loadNewAvatar = async (file: File) => {
    let formData = new FormData();
    formData.append(appendParam, file![0]);
    formData.append('company_id', `${company_id}`);
    setLoading(true);

    await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: p => {
          setPercentage((p.loaded / p.total) * 100);
        },
      })
      .then(() => {
        successNotification('Company logo loaded successfully!');
        fetchCompanyInfo();
      })
      .catch(err => catchedErrorNotification(err));
    setLoading(false);
  };

  const handleAccept = async (file: File[] | File) => {
    loadNewAvatar(file as File);
  };

  return (
    <div className={style.root}>
      <div className={style.text}>{`${title} ${percentage > 0 ? `(${percentage.toFixed(0)}%)` : ''}`}</div>
      <div className={style.text}>{subtitle}</div>
      <Dropzone
        onAccept={handleAccept}
        onDropReject={onDropReject}
        maxSize={maxSize}
        accept={accept}
        disabled={isLoading}
        text={isLoading ? <div>Image processing</div> : undefined}
      />
    </div>
  );
};
