import { Action } from 'redux';

import * as Const from '../constants';

export const setMusicianPlayerInit = (): Action => {
  return {
    type: Const.MUS_PLAYER_INIT,
  };
};

export const setMusicianPlayerTitle = (payload: string) => {
  return {
    type: Const.MUS_PLAYER_TITLE,
    payload,
  };
};

export const setMusicianPlayerDescr = (payload: string) => {
  return {
    type: Const.MUS_PLAYER_DESCR,
    payload,
  };
};

export const setMusicianPlayerNameInBase = (payload: string) => {
  return {
    type: Const.MUS_PLAYER_NAME_IN_BASE,
    payload,
  };
};

export const setMusicianPlayerID = (payload: number | null) => {
  return {
    type: Const.MUS_PLAYER_ID,
    payload,
  };
};

export const setMusicianURL = (payload: string | null) => {
  return {
    type: Const.MUS_PLAYER_URL,
    payload,
  };
};

export const setMusPlayerPlay = (payload: boolean) => {
  return {
    type: Const.MUS_PLAYER_SET_PLAY,
    payload,
  };
};

export const setMusPlayerPause = (payload: boolean) => {
  return {
    type: Const.MUS_PLAYER_SET_PAUSE,
    payload,
  };
};

export const changePlayFromMusicSubmissions = () => {
  return {
    type: Const.SET_PLAY_FROM_MUSIC_SUBMISSIONS,
  };
};

export const changePlayFromPlaylist = () => {
  return {
    type: Const.SET_PLAY_FROM_PLAYLIST,
  };
};
