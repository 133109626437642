import { Action } from 'redux';

import * as Const from '../constants';

export const setReceivePassInit = (): Action => {
  return {
    type: Const.RECEIVE_PASS_INIT,
  };
};

export const setReceivePassSuccess = (): Action => {
  return {
    type: Const.RECEIVE_PASS_SUCCESS,
  };
};

export const setReceivePassFail = (): Action => {
  return {
    type: Const.RECEIVE_PASS_ERROR,
  };
};

export const setReceivePassPending = (): Action => {
  return {
    type: Const.RECEIVE_PASS_PENDING,
  };
};

export const setReceivePassEmail = (payload: string) => {
  return {
    type: Const.RECEIVE_PASS_EMAIL,
    payload,
  };
};

export const setReceivePassToken = (payload: string) => {
  return {
    type: Const.RECEIVE_PASS_TOKEN,
    payload,
  };
};

export const setIsSuccessReceivePass = (payload: boolean) => {
  return {
    type: Const.SEND_RECEIVE_PASS_SUCCESS,
    payload,
  };
};
