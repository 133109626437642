import { Action } from 'redux';

import * as Const from '../constants';

export const setRegisterFromInviteInit = (): Action => {
  return {
    type: Const.REG_FROM_INVITE_INIT,
  };
};

export const setRegisterFromInviteSuccess = (): Action => {
  return {
    type: Const.REG_FROM_INVITE_SUCCESS,
  };
};

export const setRegisterFromInviteLoading = (): Action => {
  return {
    type: Const.REG_FROM_INVITE_PENDING,
  };
};

export const setRegisterFromInviteFail = (): Action => {
  return {
    type: Const.REG_FROM_INVITE_ERROR,
  };
};

export const setRegisterFromInviteSuccessValue = (isSuccess: boolean) => {
  return {
    type: Const.REG_FROM_INVITE_SUCCESS_VALUE,
    isSuccess,
  };
};
