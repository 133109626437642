import { Loader } from 'components/loader/loader';
import { USER_ADMIN } from 'constants/index';
import * as CompaniesCreators from 'creators/companies';
import { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { userName$, userType$ } from 'selectors/auth';
import { isLoading$, orederedCompanies$ } from 'selectors/companies';
import { usePrevious } from 'utils';

import styles from './companyHeader.module.scss';
import { CompanyItem } from './components/companyItem';

interface IStateProps {
  companies: ICompany[];
  userName: string | null;
  userType: string | null;
  isLoading: boolean;
}
interface IDispatchProps {
  fetchCompanyList: () => void;
  fetchAllCompanies: (isAdmin: boolean, loadOnlyCompanies?: boolean) => void;
  createInviteForCompany: (company_id: number, email: string, role: string) => void;
  deleteCompanyById: (company_id: number) => void;
  deleteMemberFromCompany: (company_id: number, member_id: number) => void;
}
interface OwnProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const CompaniesTable = ({
  companies,
  userName,
  isLoading,
  userType,
  createInviteForCompany,
  fetchCompanyList,
  deleteCompanyById,
  deleteMemberFromCompany,
  fetchAllCompanies,
}: IProps) => {
  const [isOpenDelete, setOpenDelete] = useState(false);
  const prevUserType = usePrevious(userType);

  // eslint-disable-next-line
  useEffect(() => {
    if (prevUserType === userType || companies.length || isLoading) return;
    if (userType === USER_ADMIN) {
      fetchAllCompanies(true, true);
    } else {
      fetchCompanyList();
    }
  }, [prevUserType, userType, companies, isLoading]);

  const handleOpenDelete = (val: boolean) => {
    setOpenDelete(val);
  };

  const onDeleteApply = (id: number) => {
    deleteCompanyById(id);
    setOpenDelete(false);
  };

  const [id, setId] = useState(0);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Companies - <span>{companies.length}</span>
      </div>
      <div className={styles.header}>
        <div className={styles.headerName}>Name</div>
        <div className={styles.headerName}></div>
        <div className={styles.headerDescription}>Description</div>
        <div className={styles.headerOwner}>Owner</div>
      </div>
      <Scrollbars className={styles.table}>
        {isLoading && !companies.length && (
          <div className={styles.loading}>
            <Loader />
          </div>
        )}

        {companies.map(company => {
          return (
            <CompanyItem
              key={company.id}
              company={company}
              userName={userName}
              isOpenDelete={isOpenDelete}
              deleteMemberFromCompany={deleteMemberFromCompany}
              handleOpenDelete={handleOpenDelete}
              onDeleteApply={onDeleteApply}
              createInviteForCompany={createInviteForCompany}
              isOpen={id === company.id}
              setId={setId}
            />
          );
        })}
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    companies: orederedCompanies$(state),
    userName: userName$(state),
    isLoading: isLoading$(state),
    userType: userType$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchCompanyList: dispatchProps.fetchCompanyList,
    fetchAllCompanies: dispatchProps.fetchAllCompanies,
    createInviteForCompany: dispatchProps.createInviteForCompany,
    deleteCompanyById: dispatchProps.deleteCompanyById,
    deleteMemberFromCompany: dispatchProps.deleteMemberFromCompany,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCompanyList: CompaniesCreators.fetchCompanyList,
    fetchAllCompanies: CompaniesCreators.fetchAllCompanies,
    createInviteForCompany: CompaniesCreators.createInviteForCompany,
    deleteCompanyById: CompaniesCreators.deleteCompanyById,
    deleteMemberFromCompany: CompaniesCreators.deleteMemberFromCompany,
  },
  mergeProps
)(memo(CompaniesTable));
