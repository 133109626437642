import { TrialErrorNotification, errorNotification } from 'components/errorNotification/errorNotification';

import { firstCapitalLetter } from './firstCapitalLetter';

export const ErrorInternal: any = {
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  200: 'Success',
  201: 'Created',
  202: 'Accepted',
  203: 'Non Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Moved Temporarily',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  419: 'Authentication Timeout',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  449: 'Retry With',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  509: 'Bandwidth Limit Exceeded',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  520: 'Unknown Error',
  521: 'WebServer Is Down',
  522: 'Connection',
  523: 'Origin',
  524: 'A Timeout Occurred',
  525: 'SSL Handshake Failed',
  526: 'Invalid SSL Certificate',
};

export const errorCodeNotification = (error: number): string => {
  if (error) {
    return ErrorInternal[error];
  } else {
    return 'Ooops an error occured';
  }
};

export const catchedErrorNotification = (error: any, timer?: number) => {
  if (!error?.response) {
    errorNotification('Oops, Unknown error');
  } else {
    const errorMessage = JSON.stringify(error?.response?.data?.error);
    if (errorMessage) {
      console.log(error?.response?.data?.error, 'ERRORMESSAGE');
      if (error?.response?.data?.error == 'non_paid') {
        console.log('NONNONN');
        TrialErrorNotification('Trial Expired');
      } else {
        errorNotification(firstCapitalLetter(errorMessage.replace(/_|-|}|{/g, ' ')), timer);
      }
    } else {
      error.response!.status! ? errorNotification(errorCodeNotification(error.response.status)) : errorNotification('Something went wrong');
    }
  }
};
