import 'sweetalert2/src/sweetalert2.scss';

import * as CompanyJobActions from 'actions/companyJobs';
import * as MusicianJobsActions from 'actions/musicianJobs';
import { setNewJobAvatarInit } from 'actions/newJobAvatar';
import * as TrackActions from 'actions/newTracks';
import * as PlaylistActions from 'actions/playlist';
import * as RateActions from 'actions/rating';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { USER_ADMIN, USER_COMPANY } from 'constants/index';
import * as TrackCreators from 'creators/newTracks';
import * as PlaylistCreators from 'creators/playlist';
import { Api } from 'enums/api';
import { ICompanyJob } from 'interfaces/ICompanyJob';
import State from 'interfaces/state/State';
import moment from 'moment';
import { Action, Dispatch } from 'redux';
import { userType$ } from 'selectors/auth';
import { currentCompany$ } from 'selectors/companies';
import { selectedJobsType$ } from 'selectors/companyJob';
import { search$, selectedTypes$, status$ } from 'selectors/musicianJobs';
import { DATE_TIME_FORMAT } from 'utils';
import { catchedErrorNotification } from 'utils/error';

export const fetchMusicianJobs = (status: string, company_id: number, isAdmin: boolean) => {
  return (dispatch: Dispatch, getState: () => State) => {
    dispatch(MusicianJobsActions.setMusicianJobsPending());
    dispatch(TrackActions.setNewTracksInit());
    dispatch(PlaylistActions.setPlaylistInit());
    dispatch(MusicianJobsActions.setCurrJobIndex(null));

    const type = selectedTypes$(getState());

    axios
      .post(`${Api.GetJobs}`, {
        status,
        company_id,
        type,
      })
      .then(response => {
        dispatch(MusicianJobsActions.getSuccessMusicianJobs(response.data.result?.all_jobs));
        dispatch(MusicianJobsActions.setJobCounter(response.data.result?.number_of_jobs));
        dispatch(MusicianJobsActions.setJobTeamsCounter(response.data.result?.number_of_team_jobs));

        dispatch(MusicianJobsActions.setLoadedMusicianJobs());

        if (response.data.result?.all_jobs?.length > 0) {
          if (!isAdmin) {
            if (response.data.result?.all_jobs[0].share_type === 'auto_forwarding') {
              dispatch(MusicianJobsActions.setCurrJobIndex(0));
              dispatch(MusicianJobsActions.setCurrentJobId(response.data.result?.all_jobs[0]!.id));
              dispatch(PlaylistActions.setInitPlaylistItems());
              dispatch(PlaylistActions.setCurrentPlaylistId(null));
              dispatch(TrackActions.setNewTracksInit());
              dispatch(TrackCreators.getAppliedTrack(Number(response.data.result?.all_jobs[0]!.id!), 50, 0) as any);
              dispatch(PlaylistCreators.getPlaylist(Number(response.data.result?.all_jobs[0]!.id!) as any) as any);
              dispatch(TrackActions.setShareType('auto_forwarding'));
            } else if (response.data.result?.all_jobs[0].share_type === 'playlist_sharing') {
              dispatch(MusicianJobsActions.setCurrJobIndex(0));
              dispatch(MusicianJobsActions.setCurrentJobId(response.data.result?.all_jobs[0]!.id));
              dispatch(PlaylistActions.setInitPlaylistItems());
              dispatch(PlaylistActions.setCurrentPlaylistId(null));
              dispatch(TrackActions.setNewTracksInit());
              dispatch(TrackCreators.getSharedPlaylistTracks(response.data.result?.all_jobs[0].id) as any);
              dispatch(TrackActions.setShareType('playlist_sharing'));
              // dispatch(NewTracksCreators.getPlaylistTracks(response.data.result[0]!.id as number) as any);
            }
          } else {
            dispatch(MusicianJobsActions.setCurrJobIndex(0));
            dispatch(MusicianJobsActions.setCurrentJobId(response.data.result?.all_jobs[0]!.id));
            dispatch(PlaylistActions.setInitPlaylistItems());
            dispatch(PlaylistActions.setCurrentPlaylistId(null));
            dispatch(TrackActions.setNewTracksInit());
            dispatch(TrackCreators.getAppliedTrack(Number(response.data.result?.all_jobs[0]!.id!), 50, 0) as any);
            dispatch(PlaylistCreators.getPlaylist(Number(response.data.result?.all_jobs[0]!.id!) as any) as any);
          }
        } else {
          dispatch(MusicianJobsActions.setCurrJobIndex(null));
          dispatch(RateActions.getRatingReceive(0));
          dispatch(MusicianJobsActions.setCurrentJobId(null));
        }
      })
      .catch(error => {
        dispatch(MusicianJobsActions.setMusicianJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchAllMusicianJobs = (status: string, offset?: number, limit?: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(MusicianJobsActions.setMusicianJobsPending());

    const type = selectedTypes$(getState());
    axios
      .post(`${Api.GetAllJobs}`, {
        status,
        limit,
        offset,
        type,
      })
      .then(response => {
        offset === 0
          ? dispatch(MusicianJobsActions.getAllMusicianJobs(response.data.result))
          : dispatch(MusicianJobsActions.getSuccessMusicianJobsPagination(response.data.result));

        dispatch(MusicianJobsActions.setJobCounter(response.data.result?.number_of_jobs));
        dispatch(MusicianJobsActions.setJobTeamsCounter(response.data.result?.number_of_team_jobs));
      })
      .catch(error => {
        dispatch(MusicianJobsActions.setMusicianJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const searchJobs = (offset?: number, limit?: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(MusicianJobsActions.setMusicianJobsPending());

    const title = search$(getState());
    const type = selectedTypes$(getState());
    const status = status$(getState());

    if (!type?.length) {
      dispatch(MusicianJobsActions.setJobTypes(['All']));
    }

    // const type = types.includes('All') && types.length > 1 && types.length !== 4 ? filteredTypes : ['All'];
    axios
      .post(Api.JobsGetLike, {
        title,
        status,
        type: !type.length ? ['All'] : type,
        limit,
        offset,
      })
      .then(response => {
        offset === 0
          ? dispatch(MusicianJobsActions.getAllMusicianJobs(response.data.result))
          : dispatch(MusicianJobsActions.getSuccessMusicianJobsPagination(response.data.result));

        dispatch(MusicianJobsActions.setJobCounter(response.data.result?.number_of_jobs));
        dispatch(MusicianJobsActions.setJobTeamsCounter(response.data.result?.number_of_team_jobs));
        dispatch(MusicianJobsActions.setLoadedMusicianJobs());
      })
      .catch(error => {
        console.error(error);
        catchedErrorNotification(error);
        dispatch(MusicianJobsActions.setMusicianJobsFail());
      });
  };
};

export type BuyerType = 'Direct Licensor / Buyer' | 'Reseller' | 'Hiring agent' | 'Opportunity only';

export const createNewJob = (newJob: Partial<ICompanyJob>, setNewStep: (step: number) => void) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(MusicianJobsActions.setMusicianJobsPending());
    const selectedJobsType = selectedJobsType$(getState());

    const userType = userType$(getState());
    axios
      .post(`${Api.CreateJob}`, {
        ...newJob,
      })
      .then(response => {
        const isAdmin = userType$(getState()) === USER_ADMIN || userType$(getState()) === USER_COMPANY;
        const job = {
          ...newJob,
          avatar_url: null,
          date_time: moment().format(DATE_TIME_FORMAT),
          id: response.data.result.id,
          status: 'open',
          tracks_for_review: 0,
          share_type: 'playlist_sharing' as 'playlist_sharing',
        };
        dispatch(CompanyJobActions.setCompanyJobNewId(response.data.result.id));
        if (currentCompany$(getState())?.id === newJob.company_id || isAdmin) {
          dispatch(CompanyJobActions.setAddedCompSuccess(job as ICompanyJob));
          dispatch(MusicianJobsActions.addnewJobToAllJobs(job));
        }
        dispatch(MusicianJobsActions.setLoadedMusicianJobs());
        successNotification('The Job was successfully! created');

        if (userType === 'admin') {
          dispatch(fetchAllMusicianJobs(selectedJobsType, 0, 100) as any);
        }
      })
      .then(() => {
        dispatch(setNewJobAvatarInit());
        setNewStep(2);
      })
      .catch(error => {
        dispatch(MusicianJobsActions.setMusicianJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updateJobById = (job_id: number, notSetCurrentJob?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(MusicianJobsActions.setMusicianJobsPending());

    axios
      .post(`${Api.GetJobById}`, {
        job_id,
      })
      .then(response => {
        dispatch(MusicianJobsActions.setLoadedMusicianJobs());
        if (response.data.result.length) {
          dispatch(MusicianJobsActions.changeOneJob(response.data.result[0]));
          if (!notSetCurrentJob) dispatch(MusicianJobsActions.setCurrentMusicianJob(response.data.result[0]));
        }
      })
      .catch(error => {
        dispatch(MusicianJobsActions.setMusicianJobsFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
