// eslint-disable-next-line
import React from 'react';

import { Charts } from './components/charts';
import { InfoList } from './components/infoList';
import style from './statistics.module.scss';

export const StatisticsPage = () => {
  return (
    <main className={style.root}>
      <div className={style.content}>
        <h1 className={style.head}>Statistics</h1>
        <InfoList />
        <Charts />
      </div>
    </main>
  );
};
