import * as Const from '../constants';
import State from '../interfaces/state/SongState';

const initState: State = {
  currentSong: '',
  currentTime: 0,
  duration: 0,
  clickedTime: 0,
  isPlay: false,
  currentRating: 0,
  volume: 1,
  name: '',
  artist: '',
  currIndex: null,
  autoPlayAfterSrcChange: false,
  muted: false,
  isNeedToScroollToTrackInRightPanel: false,
};

export default function song(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SONG_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.CURR_SONG:
      return {
        ...state,
        currentSong: action.payload,
      };

    case Const.CURR_SONG_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };

    case Const.SET_SONG_PLAY:
      return {
        ...state,
        isPlay: action.payload,
      };

    case Const.SET_SONG_RATING:
      return {
        ...state,
        currentRating: action.payload,
      };

    case Const.SET_SONG_VOLUME:
      return {
        ...state,
        volume: action.payload,
      };

    case Const.SET_SONG_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case Const.SET_SONG_ARTIST:
      return {
        ...state,
        artist: action.payload,
      };

    case Const.SET_SONG_DURATION:
      return {
        ...state,
        duration: action.payload,
      };

    case Const.SET_SONG_CLICK_TIME:
      return {
        ...state,
        clickedTime: action.payload,
      };

    case Const.CURR_SONG_INDEX:
      return {
        ...state,
        currIndex: action.payload,
      };

    case Const.SET_PLAY_AFTER_SRC_CHANGED:
      return {
        ...state,
        autoPlayAfterSrcChange: action.payload,
      };

    case Const.SET_MUTED_ON_PLAYER:
      return {
        ...state,
        muted: action.payload,
      };

    case Const.SCROLL_TO_TRACK:
      return {
        ...state,
        isNeedToScroollToTrackInRightPanel: action.payload,
      };

    default:
      return state;
  }
}
