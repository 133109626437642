import StatisticsState from 'interfaces/state/StatisticsState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setStatInit = (): Action => {
  return {
    type: Const.STATISTICS_INIT,
  };
};

export const setStatPending = (): Action => {
  return {
    type: Const.STATISTICS_PENDING,
  };
};

export const setStatError = (): Action => {
  return {
    type: Const.STATISTICS_ERROR,
  };
};

export const getStat = (payload: Partial<StatisticsState>) => {
  return {
    type: Const.STATISTICS_SUCCESS,
    payload,
  };
};
