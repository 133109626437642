// eslint-disable-next-line
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './authPick.module.scss';

interface IProps {
  clientHeight: string;
}

const authPick = ({ clientHeight }: IProps) => {
  return (
    <div style={{ height: clientHeight }} className={styles.root}>
      <div className={styles.wrapper}>
        <h3 className={styles.head}>Join SmashHaus today!</h3>
        <p className={styles.subHead}>
          And get access to the world biggest colaborative platform for music labels, productions and producers
        </p>
        <NavLink className={styles.btn} to="/sign-up">
          SIGN UP
        </NavLink>
      </div>
    </div>
  );
};

export default memo(authPick);
