import './style.scss';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import * as TrackInfoActions from 'actions/trackInfo';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { ExpansionPanelInternal } from 'components/ExpansionPanelInternal/ExpansionPanelInternal';
import { LinearLoader } from 'components/linearLoader/linearLoader';
import { SelectInternal } from 'components/select/select';
import * as NewTrackCreators from 'creators/newTracks';
import * as UploadCreators from 'creators/uploadTrack';
import { INewTrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, memo, useState } from 'react';
import { connect } from 'react-redux';
import { trackId$ } from 'selectors/newTrack';
import { description$, isShowExpandPanel$, publisher$, tag$, title$, type$, writer$ } from 'selectors/trackInfo';
import { isLoading$, progress$ } from 'selectors/uploadTrack';
import { formatBytes } from 'utils/formatBytes';

interface IIemInputProps {
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  title: string;
  disabled?: boolean;
  width?: number;
}

const ItemInput = ({ value, onChange, title, disabled, width }: IIemInputProps) => {
  return (
    <div className="d-flex flex-column upload-sub-item justify-center">
      <span className="upload-sub-title">{title}</span>
      <input
        style={width ? { width: width } : {}}
        disabled={disabled}
        className="upload-sub-inp"
        type="text"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

interface IStateProps {
  trackId: null | number;
  isLoading: boolean;
  progress: number;
  title: string;
  type: string;
  description: string;
  tag: string;
  writer: string;
  publisher: string;
  isShowExpandPanel: boolean;
}
interface OwnProps {
  setShowUpload: (b: boolean) => void;
}
interface IDispatchProps {
  uploadTrack: (file: File) => void;
  setInfoAboutTrack: (track_id: number, track: Partial<INewTrackType>) => void;
  setTrackInfoTitle: (item: string) => void;
  setTrackInfoType: (item: string) => void;
  setTrackInfoDescr: (item: string) => void;
  setTrackInfoTag: (item: string) => void;
  setTrackInfoWriter: (item: string) => void;
  setTrackInfoPublisher: (item: string) => void;
  setTarckInfoShowPanel: (is: boolean) => void;
}
interface IProps extends IStateProps, OwnProps, IDispatchProps {}

export const Upload = ({
  setShowUpload,
  uploadTrack,
  setInfoAboutTrack,
  trackId,
  isLoading,
  progress,
  title,
  type,
  description,
  tag,
  writer,
  publisher,
  setTrackInfoTitle,
  setTrackInfoType,
  setTrackInfoDescr,
  setTrackInfoTag,
  setTrackInfoWriter,
  setTrackInfoPublisher,
  isShowExpandPanel,
  setTarckInfoShowPanel,
}: IProps) => {
  const [file, setFile] = useState('' as File | string);
  const [isOpen, setOpen] = useState(true);

  const onChange = (files: FileList) => {
    setFile(files![0]);
    uploadTrack(files![0]);
  };

  const onCancelClick = () => {
    setFile('');
    setShowUpload(false);
    setTarckInfoShowPanel(false);
  };

  const onDescrChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrackInfoDescr(e.target.value);
  };

  const onTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrackInfoTag(e.target.value);
  };

  const onWriterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrackInfoWriter(e.target.value);
  };

  const onPublChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrackInfoPublisher(e.target.value);
  };

  const types = ['music', 'sfx', 'audio'];

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrackInfoTitle(e.target.value);
  };

  const onSubAddClick = () => {
    if (title && type && description && tag && writer && publisher) {
      setInfoAboutTrack(trackId!, {
        title,
        tags: tag,
        type,
        description,
        track_id: trackId,
        writers: [],
        publishers: [],
      } as Partial<INewTrackType>);
      setShowUpload(false);
      setTarckInfoShowPanel(false);
    } else {
      errorNotification('You must fill all the input fields!');
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onChange(e.target.files);
    }
  };

  return (
    <section className="upload-container d-flex justify-center align-center">
      {!isShowExpandPanel && (
        <div className="upload-wrapper d-flex align-start">
          <div className="d-flex flex-column">
            <div className="upload-title">Upload here</div>
            <div className="upload-subtitle">Uploading content here. Don’t upload too much at once for better results</div>
            <div className="upload-info">(Track size - no more than 30MB (.mp3))</div>
            <div className="d-flex align-center" style={{ position: 'relative' }}>
              <input type="file" onChange={handleUpload} placeholder="Choose file to upload" />
              {isLoading && (
                <div className="loader-upl">
                  <LinearLoader completed={progress} />
                </div>
              )}
            </div>
            <div className="d-flex align-center btn-upload-container">
              {/* <button disabled={true} className="btn-upload-left btn-upload">
                NEXT
              </button> */}
              <span className="empty-upload-block" />
              <button onClick={onCancelClick} className="btn-upload-right btn-upload">
                CANCEL
              </button>
            </div>
          </div>
          <button style={{ position: 'relative', bottom: 9, right: 26 }} className="btn-close" onClick={() => setShowUpload(false)}>
            <CloseOutlinedIcon />
          </button>
        </div>
      )}

      {isShowExpandPanel && (
        <div className="d-flex align-center justify-center upload-up-cont">
          <div className="upload-subcontainer">
            <div className="upload-sub-head">Download your materials</div>
            <div className="upload-sub-subhead">Select one or couple tracks for download in your producers storage</div>
            <div style={{ height: 382 }}>
              <ExpansionPanelInternal
                style={{ background: '#F9FAFB' }}
                head={`${file ? (file['name'] as File['name']) : null} (${
                  file ? ((formatBytes(file['size']) as unknown) as File['size']) : null
                })`}
                open={isOpen}
                setOpen={setOpen}
                content={
                  <div className="d-flex flex-column">
                    <div className="d-flex">
                      <ItemInput value={title} onChange={onInputChange} title="Track title" />

                      <div className="d-flex flex-column upload-sub-item justify-center">
                        <SelectInternal
                          name="upload-select"
                          value={type}
                          onChange={setTrackInfoType}
                          options={types}
                          placeholder={'Select track type'}
                          width={272}
                        />
                      </div>
                    </div>

                    <ItemInput width={542} value={description} onChange={onDescrChange} title="Description" />
                    <ItemInput width={542} value={tag} onChange={onTagChange} title="Tags" />

                    <div className="d-flex justify-between" style={{ width: '101.5%' }}>
                      <ItemInput value={writer} onChange={onWriterChange} title="Writers" />
                      <ItemInput value={publisher} onChange={onPublChange} title="Publisher" />
                    </div>
                  </div>
                }
              />
            </div>

            <div className="d-flex align-center justify-center">
              <button onClick={onSubAddClick} className="btn-upload-left btn-upload">
                NEXT
              </button>
              <button onClick={onCancelClick} className="btn-upload-right btn-upload">
                CANCEL
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    trackId: trackId$(state),
    isLoading: isLoading$(state),
    progress: progress$(state),
    title: title$(state),
    type: type$(state),
    description: description$(state),
    tag: tag$(state),
    writer: writer$(state),
    publisher: publisher$(state),
    isShowExpandPanel: isShowExpandPanel$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    uploadTrack: dispatchProps.uploadTrack,
    setInfoAboutTrack: dispatchProps.setInfoAboutTrack,
    setTrackInfoTitle: dispatchProps.setTrackInfoTitle,
    setTrackInfoType: dispatchProps.setTrackInfoType,
    setTrackInfoDescr: dispatchProps.setTrackInfoDescr,
    setTrackInfoTag: dispatchProps.setTrackInfoTag,
    setTrackInfoWriter: dispatchProps.setTrackInfoWriter,
    setTrackInfoPublisher: dispatchProps.setTrackInfoPublisher,
    setTarckInfoShowPanel: dispatchProps.setTarckInfoShowPanel,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    uploadTrack: UploadCreators.uploadTrack,
    setInfoAboutTrack: NewTrackCreators.setInfoAboutTrack,
    setTrackInfoTitle: TrackInfoActions.setTrackInfoTitle,
    setTrackInfoType: TrackInfoActions.setTrackInfoType,
    setTrackInfoDescr: TrackInfoActions.setTrackInfoDescr,
    setTrackInfoTag: TrackInfoActions.setTrackInfoTag,
    setTrackInfoWriter: TrackInfoActions.setTrackInfoWriter,
    setTrackInfoPublisher: TrackInfoActions.setTrackInfoPublisher,
    setTarckInfoShowPanel: TrackInfoActions.setTarckInfoShowPanel,
  },
  mergeProps
)(memo(Upload));
