// eslint-disable-next-line
import React from 'react';

import styles from './musTrackDeletePopup.module.scss';

export const MusTrackDeletePopup = () => {
  return (
    <div className={styles.root}>
      <div>Are you sure you want to delete? If you delete it will be removed forever</div>
    </div>
  );
};
