import { Action } from 'redux';

import * as Const from '../constants';
import ActionHelpValueReceive from '../interfaces/actions/ActionHelpValueReceive';

export const fetchHelpValue = (payload: string): ActionHelpValueReceive => {
  return {
    type: Const.HELP_FIELD_VALUE,
    payload,
  };
};

export const setHelpInit = (): Action => {
  return {
    type: Const.HELP_INIT,
  };
};

export const setHelpPending = (): Action => {
  return {
    type: Const.HELP_PENDING,
  };
};

export const setHelpError = (): Action => {
  return {
    type: Const.HELP_ERROR,
  };
};

export const setHelpSuccess = (): Action => {
  return {
    type: Const.HELP_SUCCESS,
  };
};
