import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { ICompany } from 'interfaces/state/CompaniesState';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';

import styles from './sharePlaylistPopupContent.module.scss';

interface OwnProps {
  shareLabel: string;
  currentCompany: ICompany | null;
  setShareValue: (value: string | number) => void;
  setShareLabel: (value: string) => void;
  onOpenChange: (open: boolean) => void;
  shareValue: string | number;
  isAdmin?: boolean;
}

export const SharePlaylistPopupContent = ({
  currentCompany,
  shareLabel,
  shareValue,
  isAdmin,
  onOpenChange,
  setShareValue,
  setShareLabel,
}: OwnProps) => {
  const membersDefault: ISelectItem[] = [
    {
      label: 'Whole company',
      value: 'Whole company' as string | number,
    },
  ];

  const [members, setMembers] = useState(membersDefault);

  useEffect(() => {
    if (currentCompany && currentCompany.members.length) {
      const newMembers = currentCompany.members.map(member => {
        return { label: member.user_name, value: member.id };
      });
      setMembers([...members, ...newMembers] as ISelectItem[]);
    }
    // eslint-disable-next-line
  }, [currentCompany]);

  const playlistValue = () => {
    return { label: shareLabel, value: shareValue };
  };

  const onChange = (member: ISelectItem) => {
    setShareValue(member.value);
    setShareLabel(member.label);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {isAdmin ? `Share playlist with company: '${currentCompany?.name}' ` : 'Share playlist (with whole company or just one member)'}
      </div>
      {!isAdmin && (
        <MaterialSelect
          onOpenChange={onOpenChange}
          value={playlistValue()}
          placeholder="Select"
          selectItems={members as ISelectItem[]}
          className="pl-drop"
          isAllValueNeed
          onChangeAllValue={onChange}
          disabled={isAdmin}
        />
      )}
    </div>
  );
};
