import Tooltip from '@material-ui/core/Tooltip';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import Popup from 'components/popup/popup';
import * as TeamInviteCreators from 'creators/teamInvites';
import State from 'interfaces/state/State';
import { ITeam } from 'interfaces/state/TeamState';
// eslint-disable-next-line
import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { teams$ } from 'selectors/team';

import style from './trackInvite.module.scss';

interface IStateProps {
  teams: ITeam[];
}
interface IDispatchProps {
  sendInviteFromTrack: (team_id: number, track_id: number) => void;
}
interface OwnProps {
  track_id: number;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}
const TrackInviteComponent = ({ track_id, teams, sendInviteFromTrack }: IProps) => {
  const [name, setName] = useState(null as null | string);
  const [id, setId] = useState(null as null | number);

  const onApply = () => {
    if (id) {
      sendInviteFromTrack(id, track_id);
    }
  };

  const teamList = () => teams.map((team: ITeam) => ({ label: team.name, value: team.id })) as ISelectItem[];
  const teamValue = { value: id, label: name } as ISelectItem;
  const handleTeamChange = (val: ISelectItem) => {
    setId(val.value as number);
    setName(val.label);
  };

  useLayoutEffect(() => {
    if (teams.length) {
      setId(teams[0].id as number);
      setName(teams[0].name);
    }
  }, [teams, setId, setName]);

  return (
    <Popup
      onApply={onApply}
      isVisibleOverflow
      btnClassName={style.root}
      buttonText={
        <Tooltip title="Invite to the team">
          <FavoriteBorderRoundedIcon className="share-option-icon" />
        </Tooltip>
      }
      modalContent={
        <div className={style.content}>
          <h5 className={style.title}>Send invite to this creator</h5>
          <p className={style.text}>Invite into Team:</p>
          <MaterialSelect
            value={teamValue}
            placeholder="Select your team"
            selectItems={teamList()}
            isAllValueNeed
            onChangeAllValue={handleTeamChange}
            className={style.select}
          />
        </div>
      }
      applyBtnText="INVITE"
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    teams: teams$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    sendInviteFromTrack: dispatchProps.sendInviteFromTrack,
    ...props,
  };
};

export const TrackInvite = connect(
  mapStateToProps,
  { sendInviteFromTrack: TeamInviteCreators.sendInviteFromTrack },
  mergeProps
)(TrackInviteComponent);
