import { Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames/bind';
// eslint-disable-next-line
import React, { CSSProperties, ChangeEvent, MouseEvent, RefObject, memo, useEffect, useMemo, useRef } from 'react';

import styles from './input.module.scss';

interface InputInterface {
  type: 'text' | 'number' | 'radio' | 'range' | 'password' | 'button';
  value: string | undefined | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  styleClass?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
  error?: string;
  isError?: boolean;
  isShowPass?: boolean;
  isInputPass?: boolean;
  setShowPass?: () => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  maxLength?: number;
  containerStyle?: CSSProperties;
  isPositive?: boolean;
  focused?: boolean;
  isRequired?: boolean;
  tooltipTitle?: string;
  size?: 'big' | 'small';
  rootClassName?: string;
}

const Input = ({
  type,
  value,
  onChange,
  styleClass,
  placeholder,
  disabled,
  style,
  error,
  isShowPass,
  isInputPass,
  setShowPass,
  onClick,
  maxLength,
  containerStyle,
  isPositive,
  focused,
  isRequired,
  tooltipTitle,
  size,
  rootClassName,
  isError,
}: InputInterface) => {
  const inputClassNames = classNames(`${styles.input} ${styleClass}`, {
    [styles.inputError]: error || isError,
    [styles.disabled]: disabled,
    [styles.big]: size === 'big',
  });

  const ref = useRef(null) as RefObject<HTMLInputElement> | null;
  // eslint-disable-next-line
  useEffect(() => {
    if (focused) {
      if (ref && 'current' in ref! && ref!.current!) {
        ref!.current!.focus();
      }
    }
    // eslint-disable-next-line
  }, [focused, ref]);

  const controlClassNames = classNames({
    [styles.inputControl]: isInputPass,
    [styles.disabledControls]: !isInputPass,
    [styles.big]: size === 'big',
  });

  const rootStyle = useMemo(() => {
    if (rootClassName) {
      return { [rootClassName]: rootClassName };
    } else return {};
  }, [rootClassName]);

  const rootCX = classNames(styles.root, rootStyle);

  return (
    <div style={containerStyle} className={rootCX}>
      <div className={styles.container}>
        {isRequired && (
          <Tooltip title="Required field">
            <div className={styles.required}>*</div>
          </Tooltip>
        )}
        <Tooltip disableFocusListener={!!tooltipTitle} title={tooltipTitle ? tooltipTitle : ''}>
          <input
            ref={ref}
            style={style}
            className={inputClassNames}
            type={type}
            value={value}
            onClick={onClick}
            onChange={onChange}
            min={isPositive ? 0 : undefined}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
          />
        </Tooltip>
        <div className={controlClassNames}>
          {isShowPass && isInputPass ? <VisibilityOffIcon onClick={setShowPass} className={styles.controll} /> : null}
          {!isShowPass && isInputPass ? <VisibilityIcon onClick={setShowPass} className={styles.controll} /> : null}
        </div>
      </div>

      <div className={styles.error}>{error ? error : null}</div>
    </div>
  );
};

export default memo(Input);
