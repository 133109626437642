import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// eslint-disable-next-line
import React, { CSSProperties } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      width: '100%',
    },
  })
);

interface IProps {
  head: string | JSX.Element;
  content: React.ReactNode;
  open: boolean;
  setOpen: (n: boolean) => void;
  style?: CSSProperties;
  summaryStyle?: CSSProperties;
  headClassName?: boolean;
  className?: string;
}

export const ExpansionPanelInternal = ({ head, content, open, setOpen, style, summaryStyle, className }: IProps) => {
  const classes = useStyles();
  const onChange = () => setOpen(!open);

  return (
    <div className={classes.root}>
      <Accordion style={style} expanded={open} onChange={onChange} className={className}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={summaryStyle}>
          <Typography className={classes.heading}>{head}</Typography>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </div>
  );
};
