import { setConfirmEmail, setConfirmToken } from 'actions/confirm';
import { setReceivePassEmail, setReceivePassToken } from 'actions/receivePass';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { findGetParameter } from 'utils/findGetParameter';

export const HistoryHandler = memo(() => {
  const [token, setTokenParam] = useState(null as string | null);
  const [email, setEmailParam] = useState(null as string | null);
  const history = useHistory();

  const dispatch = useDispatch();

  const getConfirmToken = useCallback(() => {
    const tok = findGetParameter('token', history);
    setTokenParam(tok);

    if (tok && !token) {
      dispatch(setConfirmToken(tok));
      dispatch(setReceivePassToken(tok));
    }
    // eslint-disable-next-line
  }, [token, history.location.search]);

  const getConfirmEmail = useCallback(() => {
    const em = findGetParameter('email', history);
    setEmailParam(findGetParameter('email', history));

    if (em && !email) {
      dispatch(setConfirmEmail(em));
      dispatch(setReceivePassEmail(em));
    }
    // eslint-disable-next-line
  }, [email, history.location.search]);

  useEffect(() => {
    getConfirmToken();
    getConfirmEmail();

    return () => {
      getConfirmToken();
      getConfirmEmail();
    };
    // eslint-disable-next-line
  }, []);

  return <div></div>;
});
