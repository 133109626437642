// eslint-disable-next-line
import React from 'react';

import styles from './bigStatisticPanelHead.module.scss';

interface IProps {
  items: string[];
}

export const BigStatisticPanelHead = ({ items }: IProps) => {
  return (
    <div className={styles.root}>
      {items.map(item => (
        <div key={item} className={styles.item}>
          {item}
        </div>
      ))}
    </div>
  );
};
