import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// import orderBy from 'lodash-es/orderBy';
// import reverse from 'lodash-es/reverse';
// import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';

export const tracks$ = (state: State): INewTrack[] => state.newTracks.tracks;

export const isLoading$ = (state: State): boolean => state.newTracks.isLoading;
export const isError$ = (state: State): boolean => state.newTracks.isError;
export const currentTitle$ = (state: State): string => state.newTracks.currentTitle;
export const currentId$ = (state: State): number => state.newTracks.currentId;
export const currentWriter$ = (state: State): string => state.newTracks.currentWriter;
export const currentDuration$ = (state: State): number => state.newTracks.currentDuration;
export const orderBy$ = (state: State) => state.newTracks.orderBy;
export const sortType$ = (state: State) => state.newTracks.sortType;
export const trackId$ = (state: State) => state.newTracks.track_id;
export const selected_tracks$ = (state: State) => state.newTracks.selected_tracks;
export const tracks_for_playlist = (state: State) => state.newTracks.tracks_for_playlist;
export const tracks_for_playlist$ = createSelector([tracks_for_playlist], tracks_for_playlist => tracks_for_playlist);
export const currName$ = (state: State) => state.newTracks.currName;
export const currDescr$ = (state: State) => state.newTracks.currDescr;
export const currTags$ = (state: State) => state.newTracks.currTags;

export const all$ = (state: State) => state.newTracks.all;

export const orderedTracks$ = createSelector(
  tracks$,
  tracks => tracks
  // reverse(
  //   sortBy(
  //     orderBy(tracks, item => item[order], sortType as 'asc' | 'desc'),
  //     'user_rating',
  //     'subscription_num'
  //   )
  // )
  // orderBy(tracks, ['subscription_num', order], sortType as 'asc' | 'desc')
);

export const filterBy$ = (state: State) => state.newTracks.filterBy;

export const allLoadedTracks$ = createSelector(all$, all => all);

export const downloadProgress$ = (state: State) => state.newTracks.downloadProgress;

export const currentPlaylistId$ = (state: State) => state.newTracks.currentPlaylistId;

export const share_type$ = (state: State) => state.newTracks.share_type;
export const amount = (state: State) => state.newTracks.amount;
export const amount$ = createSelector([amount], val => val);
export const isNewTracksAppeared$ = (state: State) => state.newTracks.isNewTracksAppeared;
export const submittedSongs$ = (state: State) => state.newTracks.submittedSongs;
