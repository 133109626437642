import { Action } from 'redux';

import * as Const from '../constants';

export const setTrackInfoInit = (): Action => {
  return {
    type: Const.TRACK_INFO_INIT,
  };
};

export const setTrackInfoTitle = (payload: string) => {
  return {
    type: Const.TRACK_INFO_TITLE,
    payload,
  };
};

export const setTrackInfoType = (payload: string) => {
  return {
    type: Const.TRACK_INFO_TYPE,
    payload,
  };
};

export const setTrackInfoDescr = (payload: string) => {
  return {
    type: Const.TRACK_INFO_DESCR,
    payload,
  };
};

export const setTrackInfoTag = (payload: string) => {
  return {
    type: Const.TRACK_INFO_TAG,
    payload,
  };
};

export const setTrackInfoWriter = (payload: string) => {
  return {
    type: Const.TRACK_INFO_WRITER,
    payload,
  };
};

export const setTrackInfoPublisher = (payload: string) => {
  return {
    type: Const.TRACK_INFO_PUBLISHER,
    payload,
  };
};

export const setTarckInfoShowPanel = (payload: boolean) => {
  return {
    type: Const.TRACK_INFO_SHOW_EXPANDPANEL,
    payload,
  };
};

// type Field = 'name' | 'first_name' | 'last_name' | 'middle_initial' | 'pro' | 'percentage' | 'ipi';

// export const setInfoAboutWritersOrPublishers = (key: 'writers' | 'publishers', index: number, field: Field, payload: string | number) => {
//   return {
//     type: Const.TRACK_INFO_SET_WRITERS_OR_PUBLISHERS,
//     key,
//     index,
//     field,
//     payload,
//   };
// };

export const addNewItemForWritersOrPublishers = (key: 'writers' | 'publishers') => {
  return {
    type: Const.TRACK_INFO_ADD_ITEM_FOR_WRITERS_OR_PUBLISHERS,
    key,
  };
};

export const deleteItemsFromWritersOrPublishers = (key: 'writers' | 'publishers', index: number) => {
  return {
    type: Const.TRACK_INFO_DELETE_ITEM_FROM_WRITERS_OR_PUBLISHERS,
    key,
    index,
  };
};

export const setOpenUploadPopup = (payload: boolean) => {
  return {
    type: Const.SET_OPEN_EDI_NEW_TRACK_POPUP,
    payload,
  };
};
