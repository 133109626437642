import * as Const from '../constants';
import State from '../interfaces/state/UpdateApproveState';

const initState: State = {
  isLoading: false,
  isError: false,
  approve: {},
};

export default function updateApprove(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.UPDATE_APPROVE_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.UPDATE_APPROVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        approve: {
          ...state.approve,
          [action.user_to_approve]: {
            is_approved: action.is_approved,
            isLoading: action.isLoading,
          },
        },
      };

    case Const.UPDATE_APPROVE_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        approve: {
          ...state.approve,
          [action.user_to_approve]: {
            is_approved: action.is_approved,
            isLoading: action.isLoading,
          },
        },
      };

    case Const.UPDATE_APPROVE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        approve: {
          ...state.approve,
          [action.user_to_approve]: {
            is_approved: action.is_approved,
            isLoading: action.isLoading,
          },
        },
      };

    default:
      return state;
  }
}
