import { LogoBottom } from 'components/logoBottom/logoBottom';
import * as AuthCreators from 'creators/auth';
// eslint-disable-next-line
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import styles from './authAdminForm.module.scss';
import SignInForm from './signInForm';

interface IProps {
  clientHeight: string;
}

interface IDispatchProps {
  logIn: (email: string, pass: string) => void;
}

const authAdminForm = ({ clientHeight, logIn }: IDispatchProps & IProps) => {
  const isGoogleButton = true;
  return (
    <div style={{ height: clientHeight }} className={styles.root}>
      <div className={styles.logo}>
        <LogoBottom loader />
      </div>
      <SignInForm onSubmit={logIn} isGoogle={isGoogleButton} />
      <span className={styles.forgot}>
        Forgot the password? <NavLink to="/help"> Get Help</NavLink>
      </span>
    </div>
  );
};

const mergeProps = (stateProps: {}, dispatchProps: IDispatchProps, props: IProps) => {
  return {
    ...stateProps,
    logIn: dispatchProps.logIn,
    ...props,
  };
};

export default connect(
  null,
  {
    logIn: AuthCreators.logIn,
  },
  mergeProps
)(memo(authAdminForm));
