// eslint-disable-next-line
import React from 'react';

import style from './createNewJobPopoverContent.module.scss';

export const CreateNewJobPopoverContent = () => {
  return (
    <div className={style.root}>
      <h4 className={style.title}>Limit users below Quality rating you choose from submitting to this job</h4>
      <p className={style.text}>
        The point of this quality filter is to limit Creators with lessor quality ratings to submit to your job. The higher you go, the less
        submissions you will get but they will be of higher quality.
        <span className={style.bold}>EXAMPLE: </span>
        If you pull the bar to the right to the number 5, you will limit anyone with a 4 out of 10 rating or lower from submitting to your
        job and you will only be able to receive submissions from Creators rating 6 out of 10 or higher. If you pull the bar to 9, you will
        only be able to receive music from users rating 8 out of 10 or lower. This is a tool to help you limit submissions to just the level
        of quality you wish to receive.
      </p>
    </div>
  );
};
