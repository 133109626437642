import Input from 'components/input/input';
import * as AffiliateCreators from 'creators/affiliate';
// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { isLoading$ } from 'selectors/affiliate';
import { userType$ } from 'selectors/auth';
import { emailValidation } from 'utils';

import { Companies } from '../companies';
import style from './paypalEmail.module.scss';

interface IStateProps {}
interface IDispatchProps {
  sendAffiliateEmail: (email: string, company_id?: number) => void;
}
interface OwnProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const PaypalEmailComponent = ({ sendAffiliateEmail }: IProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [company_id, setCompanyId] = useState(undefined as undefined | number);
  const userType = useSelector(userType$);
  const isLoading = useSelector(isLoading$);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const onClick = () => setEmailError('');
  const handleSendEmail = () => {
    if (emailValidation(email)) {
      sendAffiliateEmail(email, company_id);
    } else {
      setEmailError('Email is not valid!');
    }
  };

  return (
    <div className={style.root}>
      <div className={style.container}>
        {userType !== 'creator' && (
          <>
            <span className={style.fakePlaceholder}>{'Choose a company'}</span>
            <Companies setCompanyId={setCompanyId} />
          </>
        )}
        <Input
          focused
          styleClass={style.input}
          type="text"
          value={email}
          onChange={onChange}
          placeholder={'Enter your Paypal email'}
          tooltipTitle={'Enter your Paypal email'}
          error={emailError}
          onClick={onClick}
          maxLength={400}
        />
        <button
          onClick={handleSendEmail}
          disabled={(userType === 'manager' && !email && !company_id) || (userType !== 'manager' && !email) || isLoading}
          className={style.btn}
        >
          Get shareable link
        </button>
      </div>
    </div>
  );
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    sendAffiliateEmail: dispatchProps.sendAffiliateEmail,
    ...props,
  };
};

export const PaypalEmail = connect(
  null,
  {
    sendAffiliateEmail: AffiliateCreators.sendAffiliateEmail,
  },
  mergeProps
)(PaypalEmailComponent);
