import * as CompaniesActions from 'actions/companies';
import * as CompanyActions from 'actions/companyJobs';
import * as MusicianJobsActions from 'actions/musicianJobs';
import * as NewTrackActions from 'actions/newTracks';
import * as TempCompanyJobAction from 'actions/tempEditCompanyJob';
import classNames from 'classnames/bind';
import { Loader } from 'components/loader/loader';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { successNotification } from 'components/successNotification/successNotification';
import * as CompaniesCreators from 'creators/companies';
import * as CompanyCreators from 'creators/companyJob';
import * as MusicianJobsCreators from 'creators/musicianJobs';
import * as NewJobAvatarCreators from 'creators/newJobAvatar';
import * as NewTrackCreators from 'creators/newTracks';
import { ICompanyJob } from 'interfaces/ICompanyJob';
import CompaniesState, { ICompany } from 'interfaces/state/CompaniesState';
import MusicianJobsState from 'interfaces/state/MusicianJobsState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';
import { companies$, currentCompany$ } from 'selectors/companies';
import { isLoading$ as isLoadingCompanies$ } from 'selectors/companies';
import { currentNewId$, selectedJobsType$, sortType$, sortedBy$ } from 'selectors/companyJob';
import { isLoading$ as isLoadingCompanyJobs$ } from 'selectors/companyJob';
import { jobsCounter$, sortType$ as sortTypeManager$, sortedBy$ as sortedByManager$ } from 'selectors/musicianJobs';
import { isLoading$ } from 'selectors/newJobAvatar';

import { CompanyHeader } from './header';
import { JobItem } from './job';
import style from './jobList.module.scss';

interface IProps {
  items: ICompanyJob[];
  isLoadingItems: boolean;
  isAdmin?: boolean;
}

interface IStateProps {
  sortedBy: string;
  sortType: 'asc' | 'desc';
  currentNewId: number | null;
  isLoading: boolean;
  sortTypeManager: string;
  sortedByManager: string;
  companies: ICompany[];
  selectedJobsType: 'all' | 'deleted' | 'closed' | 'archived' | 'open';
  currentCompany: null | CompaniesState['currentCompany'];
  isLoadingCompanies: boolean;
  isLoadingCompanyJobs: boolean;
}

interface IDispatchProps {
  setCompanySortType: (ord: 'asc' | 'desc') => void;
  setCompanyJobsSorting: (sort: string) => void;
  getAppliedTrack: (id: number, from: number, to: number) => void;
  setNewTracksInit: () => void;
  updateCompanyStatus: (isManager: boolean, status: string, id: number) => void;
  fetchCompanyJobs: (status: string, company_id: number, offset?: number, limit?: number) => void;
  setTempCompanyJobInit: () => void;
  setTempCompanyJobItem: (item: 'title' | 'description' | 'budget', value: number | string) => void;
  setNewJobAvatarFileId: (file: File, isEdit?: boolean) => void;
  setCompanyJobNewId: (id: number) => void;
  handleChangeJobOwner: (id: number, company_id: number, company: string) => void;
  fetchAllMusicianJobs: (status: string, offset?: number, limit?: number) => void;
  setMusicianJobsSorting: (j: string) => void;
  setMusicianJobsOrder: (t: MusicianJobsState['sortType']) => void;
  fetchAllCompanies: (isManager: boolean, notLoadOnlyCompanies?: boolean, isloadJobs?: boolean) => void;
  setCurrentCompany: (company: ICompany) => void;
  updateSelectedJobsType: (payload: 'all' | 'deleted' | 'closed' | 'archived' | 'open') => void;
  fetchCompanyList: (isNeedToFetchActiveJobs?: boolean, variant?: 'open', isloadJobs?: boolean) => void;
}

const headers = [
  { title: 'Title', order: 'title' },
  { title: 'Description', order: 'description' },
  { title: 'Budget', order: 'budget' },
  { title: 'Status', order: 'status' },
  { title: 'Buyer type', order: 'buyer_type' },
];

const JobList = ({
  fetchCompanyJobs,
  setCompanySortType,
  setCompanyJobsSorting,
  getAppliedTrack,
  setNewTracksInit,
  updateCompanyStatus,
  setTempCompanyJobInit,
  setTempCompanyJobItem,
  setNewJobAvatarFileId,
  setCompanyJobNewId,
  handleChangeJobOwner,
  fetchAllMusicianJobs,
  updateSelectedJobsType,
  setMusicianJobsSorting,
  setMusicianJobsOrder,
  fetchAllCompanies,
  fetchCompanyList,
  setCurrentCompany,
  items,
  sortedBy,
  sortType,
  currentNewId,
  isLoading,
  isLoadingItems,
  isAdmin,
  companies,
  sortedByManager,
  sortTypeManager,
  selectedJobsType,
  currentCompany,
  isLoadingCompanies,
  isLoadingCompanyJobs,
}: IProps & IStateProps & IDispatchProps) => {
  const [type, setType] = useState('open');
  const countOfJobs = useSelector(jobsCounter$);
  const userType = useSelector(userType$);

  const allbuttonClassNames = classNames({
    [style.btnActive]: selectedJobsType === 'all',
    [style.btnUnActive]: selectedJobsType !== 'all',
  });

  const openbuttonClassNames = classNames({
    [style.btnActive]: selectedJobsType === 'open',
    [style.btnUnActive]: selectedJobsType !== 'open',
  });

  const closebuttonClassNames = classNames({
    [style.btnActive]: selectedJobsType === 'closed',
    [style.btnUnActive]: selectedJobsType !== 'closed',
  });

  const archivedbuttonClassNames = classNames({
    [style.btnActive]: selectedJobsType === 'archived',
    [style.btnUnActive]: selectedJobsType !== 'archived',
  });

  const deletedbuttonClassNames = classNames({
    [style.btnActive]: selectedJobsType === 'deleted',
    [style.btnUnActive]: selectedJobsType !== 'deleted',
  });

  const onButtonClick = async (jobType: string) => {
    if (isLoadingItems) return;

    setType(jobType);
    updateSelectedJobsType(jobType as 'all' | 'deleted' | 'closed' | 'archived' | 'open');
    if (isAdmin) {
      fetchAllMusicianJobs(jobType, 0, 100);
    } else {
      if (!currentCompany) {
        successNotification('First select a Company');
        return;
      } else {
        await fetchCompanyJobs(jobType, currentCompany.id, 0, 100);
      }
    }
  };

  const onAllClick = async () => {
    onButtonClick('all');
  };

  const onOpenClick = async () => {
    onButtonClick('open');
  };

  const onCloseClick = async () => {
    onButtonClick('closed');
  };

  const onArchiveClick = async () => {
    onButtonClick('archived');
  };

  const onDeleteClick = async () => {
    onButtonClick('deleted');
  };

  const handleCompanyChange = (val: ISelectItem) => {
    if (isLoadingCompanyJobs || Number(val.value) === currentCompany!.id) return;
    const companyIndex = companies.findIndex(company => company.id === val.value);
    fetchCompanyJobs(selectedJobsType, val.value as number, 0, 100);
    if (companyIndex >= 0) {
      setCurrentCompany(companies[companyIndex]);
    }
  };

  useEffect(() => {
    if (isLoadingCompanies) return;

    if (isAdmin) {
      fetchAllCompanies(isAdmin, undefined, true);
    } else {
      fetchCompanyList(undefined, undefined, true);
    }
    // eslint-disable-next-line
  }, [isAdmin]);

  const fetchNextJobs = useCallback(() => {
    if (isAdmin) {
      if (items.length >= 20) fetchAllMusicianJobs(type, items.length, 20);
    } else {
      if (items.length >= 20) fetchCompanyJobs(selectedJobsType, currentCompany!.id, items.length, 20);
    }
    // eslint-disable-next-line
  }, [items, isAdmin]);

  const companyList = useCallback(() => companies.map(company => ({ label: company.name, value: company.id })) as ISelectItem[], [
    companies,
  ]);

  const selectCompanyValue = {
    value: String(currentCompany?.id),
    label: currentCompany?.name,
  } as ISelectItem;

  return (
    <section className={style.root}>
      <div className={style.info}>
        <div className={style.count}>
          {`Jobs posted `}
          <span className={style.bold}>
            {' - '}
            {userType === 'admin' ? countOfJobs : items?.length}
          </span>
        </div>

        <div className={style.from}>
          {!isAdmin && (
            <div className="d-flex flex-column">
              <span className={style.fakePlaceholder}>Jobs from a company</span>
              <MaterialSelect
                value={selectCompanyValue}
                placeholder="Choose a company"
                selectItems={companyList()}
                className={style.selectBuyer}
                isAllValueNeed
                onChangeAllValue={handleCompanyChange}
                width={250}
              />
            </div>
          )}
        </div>
        <div className={style.sort}>
          <button onClick={onAllClick} className={allbuttonClassNames}>
            ALL
          </button>
          <button onClick={onOpenClick} className={openbuttonClassNames}>
            OPEN
          </button>
          <button onClick={onCloseClick} className={closebuttonClassNames}>
            CLOSED
          </button>
          <button onClick={onArchiveClick} className={archivedbuttonClassNames}>
            ARCHIVED
          </button>
          <button onClick={onDeleteClick} className={deletedbuttonClassNames}>
            DELETED
          </button>
        </div>
      </div>
      <CompanyHeader
        setMusicianJobsOrder={isAdmin ? setMusicianJobsOrder : setCompanySortType}
        setMusicianJobsSorting={isAdmin ? setMusicianJobsSorting : setCompanyJobsSorting}
        headers={headers}
        sortedBy={isAdmin ? sortedByManager : sortedBy}
        sortType={isAdmin ? sortTypeManager : sortType}
      />
      <div className={style.scroll} id="scrolledJobs">
        <InfiniteScroll
          dataLength={items.length}
          next={fetchNextJobs}
          hasMore={true}
          loader={<div />}
          endMessage={<></>}
          scrollableTarget={'scrolledJobs'}
        >
          {items.map(job => {
            return (
              <JobItem
                key={job?.id}
                handleChangeJobOwner={handleChangeJobOwner}
                isManager={isAdmin}
                item={job}
                isLoading={isLoading}
                currentNewId={currentNewId}
                setCompanyJobNewId={setCompanyJobNewId}
                setNewJobAvatarFile={setNewJobAvatarFileId}
                setTempCompanyJobItem={setTempCompanyJobItem}
                setTempCompanyJobInit={setTempCompanyJobInit}
                updateCompanyStatus={updateCompanyStatus}
                setNewTracksInit={setNewTracksInit}
                getAppliedTrack={getAppliedTrack}
                companies={companies}
              />
            );
          })}
        </InfiniteScroll>

        {!isLoadingItems && !items.length ? (
          <div
            style={{
              width: '100%',
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {selectedJobsType === 'open' ? 'Create your first job on behalf of the company' : 'Nothing was found'}
          </div>
        ) : null}
        {!!isLoadingItems && (
          <div className={style.loadingContainer}>
            <Loader />
          </div>
        )}

        <button onClick={fetchNextJobs} className={style.nextBtn} disabled={isLoadingItems}>
          {isLoadingItems ? 'Loading' : 'Next'}
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    sortedBy: sortedBy$(state),
    sortType: sortType$(state),
    currentNewId: currentNewId$(state),
    isLoading: isLoading$(state),
    sortTypeManager: sortTypeManager$(state),
    sortedByManager: sortedByManager$(state),
    companies: companies$(state),
    selectedJobsType: selectedJobsType$(state),
    currentCompany: currentCompany$(state),
    isLoadingCompanies: isLoadingCompanies$(state),
    isLoadingCompanyJobs: isLoadingCompanyJobs$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: IProps) => {
  return {
    ...stateProps,
    fetchCompanyJobs: dispatchProps.fetchCompanyJobs,
    setCompanyJobsSorting: dispatchProps.setCompanyJobsSorting,
    setCompanySortType: dispatchProps.setCompanySortType,
    fetchAllMusicianJobs: dispatchProps.fetchAllMusicianJobs,
    getAppliedTrack: dispatchProps.getAppliedTrack,
    setNewTracksInit: dispatchProps.setNewTracksInit,
    updateCompanyStatus: dispatchProps.updateCompanyStatus,
    setTempCompanyJobInit: dispatchProps.setTempCompanyJobInit,
    setTempCompanyJobItem: dispatchProps.setTempCompanyJobItem,
    setNewJobAvatarFileId: dispatchProps.setNewJobAvatarFileId,
    setCompanyJobNewId: dispatchProps.setCompanyJobNewId,
    handleChangeJobOwner: dispatchProps.handleChangeJobOwner,
    setMusicianJobsSorting: dispatchProps.setMusicianJobsSorting,
    setMusicianJobsOrder: dispatchProps.setMusicianJobsOrder,
    fetchAllCompanies: dispatchProps.fetchAllCompanies,
    fetchCompanyList: dispatchProps.fetchCompanyList,
    setCurrentCompany: dispatchProps.setCurrentCompany,
    updateSelectedJobsType: dispatchProps.updateSelectedJobsType,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCompanyJobs: CompanyCreators.fetchCompanyJobs,
    setCompanyJobsSorting: CompanyActions.setCompanyJobsSorting,
    fetchAllMusicianJobs: MusicianJobsCreators.fetchAllMusicianJobs,
    setMusicianJobsSorting: MusicianJobsActions.setMusicianJobsSorting,
    setMusicianJobsOrder: MusicianJobsActions.setMusicianJobsOrder,
    setCompanySortType: CompanyActions.setCompanySortType,
    getAppliedTrack: NewTrackCreators.getAppliedTrack,
    setNewTracksInit: NewTrackActions.setNewTracksInit,
    updateCompanyStatus: CompanyCreators.updateCompanyStatus,
    setTempCompanyJobInit: TempCompanyJobAction.setTempCompanyJobInit,
    setTempCompanyJobItem: TempCompanyJobAction.setTempCompanyJobItem,
    setNewJobAvatarFileId: NewJobAvatarCreators.setNewJobAvatarFileId,
    setCompanyJobNewId: CompanyActions.setCompanyJobNewId,
    handleChangeJobOwner: CompanyCreators.handleChangeJobOwner,
    fetchAllCompanies: CompaniesCreators.fetchAllCompanies,
    fetchCompanyList: CompaniesCreators.fetchCompanyList,
    setCurrentCompany: CompaniesActions.setCurrentCompany,
    updateSelectedJobsType: CompanyActions.updateSelectedJobsType,
  },
  mergeProps
)(memo(JobList));
