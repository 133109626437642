// eslint-disable-next-line
import React, { memo } from 'react';

import AuthAdminForm from './authAdminForm';
import AuthPick from './authPick';
import styles from './noAuthAdmin.module.scss';

const noAuthAdmin = () => {
  let clientHeight = '100vh';

  return (
    <section style={{ height: '100vh' }} className={styles.root}>
      <AuthPick clientHeight={clientHeight} />
      <AuthAdminForm clientHeight={clientHeight} />
    </section>
  );
};

export default memo(noAuthAdmin);
