import './style.scss';

import { Tooltip } from '@material-ui/core';
import * as CompaniesActions from 'actions/companies';
import * as MAnagerEventListenerActions from 'actions/managerRatingEventListener';
import * as MusicJobsActions from 'actions/musicianJobs';
import * as NewTracksActions from 'actions/newTracks';
import * as PlaylistActions from 'actions/playlist';
import * as RatingActions from 'actions/rating';
import * as SharedPlaylistActions from 'actions/sharedPlaylist';
import * as UsersActions from 'actions/users';
import cx from 'classnames';
import { FilteredCount } from 'components/filteredCount/filteredCount';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import * as CompaniesCreators from 'creators/companies';
import * as CompanyJobsCreators from 'creators/companyJob';
import * as MusicJobsCreator from 'creators/musicianJobs';
import * as TrackCreators from 'creators/newTracks';
import * as NewTracksCreators from 'creators/newTracks';
import * as PlaylistCreators from 'creators/playlist';
import { IMusicianJob } from 'interfaces/IMusicianJob';
import { INewTrack } from 'interfaces/INewTrack';
import { IPlaylist } from 'interfaces/IPlaylist';
import { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isAuth$, isLoading$ as isAuthLoading$ } from 'selectors/auth';
import { companies$, currentCompany$ } from 'selectors/companies';
import { isLoading$ as isLoadingCompanies$ } from 'selectors/companies';
import { currentJobIndex$, isLoading$ as isLoadingJobs$, jobs$ } from 'selectors/musicianJobs';
import { amount$, submittedSongs$ } from 'selectors/newTrack';
import { playlist_id$, sharedPlaylistId$, sharedPlaylistIndex$ } from 'selectors/playlist';
import { amount$ as playlistAmount$ } from 'selectors/playlist';
import { from$, to$ } from 'selectors/rating';
import { isLoading$ as isLoadingSharedPl$, sharedPlaylists$ } from 'selectors/sharedPlaylist';
import { userName$ } from 'selectors/signUp';
import { isLoading$ as isLoadingUsers$ } from 'selectors/users';
import { usePrevious } from 'utils';
import { firstCapitalLetter } from 'utils/firstCapitalLetter';

interface IStateProps {
  jobs: IMusicianJob[];
  isAuthLoading: boolean;
  isAuth: boolean;
  userName: string;
  from: number;
  to: number;
  currentJobIndex: number | null;
  playlist_id: number | null;
  sharedPlaylistId: number | null;
  isLoadingUsers: boolean;
  isLoadingJobs: boolean;
  companies: ICompany[];
  sharedPlaylists: IPlaylist[];
  isLoadingSharedPl: boolean;
  amount: number;
  currentCompany: ICompany | null;
  isLoadingCompanies: boolean;
}

interface OwnProps {
  isHideCompanySelect?: boolean;
  isAdmin: boolean;
}

interface IDispatchProps {
  getUsersType: (type: 'creator' | 'manager' | 'admin' | null) => void;
  fetchMusicianJobs: (status: string, company_id: number, isAdmin: boolean, type?: string[]) => void;
  getAppliedTrack: (id: number, limit?: number, offset?: number, withoutRating?: boolean, isQualityFilter?: boolean) => void;
  setCurrJobIndex: (n: number) => void;
  setManagerEventListenerOn: () => void;
  setManagerEventListenerInit: () => void;
  fetchCompanyList: (isNeedToFetchActiveJobs?: boolean, variant?: 'open') => void;
  setCurrentJobId: (v: number) => void;
  setAutoForwarding: (value: boolean) => void;
  getSharedPlaylistTracks: (job_id: number, withoutRating?: boolean) => void;
  updateShareType: (job_id: number, share_type: 'auto_forwarding' | 'playlist_sharing') => void;
  getSharedTracks: (
    playlist_id: number,
    from?: number,
    to?: number,
    notNeedToInit?: boolean,
    limit?: number,
    offset?: number,
    needToRatingInit?: boolean
  ) => void;
  setSharedPlaylistInit: () => void;
  setSharedPlaylistId: (index: number | null) => void;
  setCurrentCompany: (company: ICompany) => void;
  setRatingFromTo: (from: number, to: number) => void;
  setSharedType: (type: 'auto_forwarding' | 'playlist_sharing' | null) => void;
  getNewTracksForPlaylist: (v: INewTrack[] | null) => void;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const JobPanel = ({
  getUsersType,
  fetchMusicianJobs,
  getAppliedTrack,
  setManagerEventListenerOn,
  setManagerEventListenerInit,
  setCurrentJobId,
  fetchCompanyList,
  setAutoForwarding,
  getSharedPlaylistTracks,
  updateShareType,
  getSharedTracks,
  setSharedPlaylistInit,
  setCurrJobIndex,
  setSharedPlaylistId,
  setCurrentCompany,
  getNewTracksForPlaylist,
  jobs,
  isAuthLoading,
  isAuth,
  playlist_id,
  currentJobIndex,
  isLoadingUsers,
  isLoadingJobs,
  isHideCompanySelect,
  userName,
  companies,
  isAdmin,
  isLoadingSharedPl,
  sharedPlaylists,
  amount,
  setRatingFromTo,
  setSharedType,
  currentCompany,
  sharedPlaylistId,
  isLoadingCompanies,
}: IProps) => {
  const [selectedCompanyId, setSelectCompanyId] = useState(0);
  const [selectedCompany, setSelectCompany] = useState('');
  const [selectedJobId, setSelectedJobId] = useState(null as number | null);
  const [jobShareType, setShareType] = useState('playlist_sharing' as 'auto_forwarding' | 'playlist_sharing');
  const [sharedPlaylist, setSharedPlaylist] = useState(undefined as ISelectItem | undefined);
  const sharedPlaylistIndex = useSelector(sharedPlaylistIndex$);
  const [sharedPlaylistItems, setSharedPlaylistItems] = useState([] as { label: string; value: string | number }[]);
  const prevJobs = usePrevious(jobs);
  const prevSharedPlaylists = usePrevious(sharedPlaylists);
  const dispatch = useDispatch();
  const [jobType, setJobType] = useState({ label: 'Open', value: 'open' } as ISelectItem);
  const playlistAmount = useSelector(playlistAmount$);
  const submittedSongs = useSelector(submittedSongs$);

  const getCompanies = () => {
    if (isAuth && !isAuthLoading && !isHideCompanySelect) {
      getUsersType('manager');
    } else if (isAuth && !isAuthLoading && isHideCompanySelect) {
      if (!isLoadingCompanies) {
        fetchCompanyList(true, 'open');
      }
    } else {
      return;
    }
  };

  // eslint-disable-next-line
  useEffect(() => getCompanies(), [userName]);

  useEffect(() => {
    if (prevJobs !== jobs && jobs?.length) {
      dispatch(PlaylistActions.setSharedPlaylistIndex(0));
    }
    // eslint-disable-next-line
  }, [jobs, prevJobs]);

  const handlePlaylist = useCallback(() => {
    if (sharedPlaylistIndex !== null) {
      setSharedPlaylist({
        label: sharedPlaylists?.[sharedPlaylistIndex]?.playlist_name,
        value: sharedPlaylists?.[sharedPlaylistIndex]?.playlist_id,
      });
    } else {
      setSharedPlaylist(undefined);
    }
  }, [sharedPlaylistIndex, sharedPlaylists]);

  useEffect(() => {
    if (sharedPlaylists?.length) {
      const result = sharedPlaylists.map(playlist => ({
        label: playlist.playlist_name,
        value: playlist.playlist_id,
      }));
      setSharedPlaylistItems(result);
      if (sharedPlaylist?.value && prevSharedPlaylists !== sharedPlaylists) {
        const index = result?.findIndex(shPl => shPl?.value === sharedPlaylist?.value);
        if (index >= 0) {
          setSharedPlaylist(result[index]);
        } else {
          handlePlaylist();
        }
      } else {
        handlePlaylist();
      }
    } else {
      setSharedPlaylistItems([]);
      setSharedPlaylist(undefined);
    }
    // eslint-disable-next-line
  }, [sharedPlaylists, sharedPlaylistIndex]);

  const setCurrentJob = async (job: string | number) => {
    if (!job || selectedJobId === job) return;
    setSharedPlaylistInit();
    setSharedPlaylistId(null);
    setRatingFromTo(0, 10);

    const index = jobs.findIndex(j => j.id === Number(job));
    setSelectedJobId(Number(job));
    setCurrentJobId(Number(job));
    getNewTracksForPlaylist(null);
    dispatch(PlaylistActions.setSharedPlaylistIndex(0));
    dispatch(PlaylistCreators.getPlaylist(Number(job)));

    if (!isAdmin) {
      if (index >= 0) {
        setCurrJobIndex(index);
        if (jobs[index].share_type === 'auto_forwarding') {
          getAppliedTrack(Number(job), 50, 0, true);

          if (index >= 0) {
            setShareType(jobs[index].share_type);
            setSharedType(jobs[index].share_type);
          }
        } else if (jobs[index].share_type === 'playlist_sharing') {
          getSharedPlaylistTracks(Number(job), true);

          if (index >= 0) {
            setShareType(jobs[index].share_type);
            setSharedType(jobs[index].share_type);
          }
        }
      }
    } else {
      getAppliedTrack(Number(job), 50, 0, true);
      if (index >= 0) {
        setShareType(jobs[index].share_type);
        setSharedType(jobs[index].share_type);
      }
    }
  };

  const setCurrentSharedPlaylist = useCallback(
    (playlist: ISelectItem) => {
      if (playlist.value !== sharedPlaylistId) {
        getSharedTracks(Number(playlist.value), 0, undefined, true, undefined, undefined, true);
      }
    },
    // eslint-disable-next-line
    [sharedPlaylistId]
  );

  const currentPlaylistNum = useMemo(() => {
    return playlist_id ? playlistAmount : 0;
  }, [playlist_id, playlistAmount]);

  const getjobs = () => {
    return jobs.map(job => ({ label: job.title, value: job.id }));
  };

  const jobStatuses = [
    { label: 'Open', value: 'open' },
    { label: 'All', value: 'all' },
    { label: 'Closed', value: 'closed' },
    { label: 'Archived', value: 'archived' },
    { label: 'Deleted', value: 'deleted' },
  ];

  const jobsDropValue = () => {
    if (currentJobIndex === null || !jobs) {
      return;
    } else {
      return {
        label: jobs?.length && jobs[currentJobIndex] && 'title' in jobs[currentJobIndex] ? jobs[currentJobIndex].title : '',
        value: jobs?.length && jobs[currentJobIndex] && 'id' in jobs[currentJobIndex] ? jobs[currentJobIndex].id : '',
      };
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setManagerEventListenerOn();
    } else {
      setManagerEventListenerInit();
    }
  };

  const SelectContainerCX = cx('d-flex job-panel-selectors', {
    'select-only align-center': isHideCompanySelect,
  });

  const SelectCX = cx({
    'select-active-jobs': isHideCompanySelect,
    'pl-drop': !isHideCompanySelect,
  });

  const companyList = () => companies.map(company => ({ label: company.name, value: company.id })) as ISelectItem[];
  const selectCompanyValue = { value: String(selectedCompanyId), label: selectedCompany } as ISelectItem;

  const handleCompanyChange = useCallback(
    (company: ISelectItem, type?: string) => {
      if (Number(company.value) === currentCompany!.id) return;
      // eslint-disable-next-line
      if (!company.value || selectedCompanyId == company?.value) return;

      setSelectedJobId(null);
      setSharedPlaylistInit();
      setSharedPlaylistId(null);
      setRatingFromTo(0, 10);

      setSelectCompanyId(company.value as number);
      setSelectCompany(company.label);
      fetchMusicianJobs(type ? type : (jobType.value as string), company.value as number, !!isAdmin);
      // setShareType('playlist_sharing');
      const index = companies.findIndex(comp => comp.id === Number(company.value));
      if (index >= 0) {
        setCurrentCompany(companies[index]);
      }
    },
    // eslint-disable-next-line
    [companies, jobType.value, selectedCompanyId]
  );

  const handleAutoforwarding = (type: 'auto_forwarding' | 'playlist_sharing') => {
    const autoForwarding = type === 'auto_forwarding' ? 'playlist_sharing' : 'auto_forwarding';

    setAutoForwarding(type === 'auto_forwarding');
    setShareType(autoForwarding);
    updateShareType(selectedJobId!, autoForwarding);
  };

  const onChange = () => {
    if (!selectedJobId) return;
    handleAutoforwarding(jobShareType);
  };

  useEffect(() => {
    if (isLoadingJobs) return;
    if (jobs.length > 0 && currentJobIndex !== null && currentJobIndex >= 0) {
      // if (selectedJobId === jobs[currentJobIndex].id) return;
      setSelectedJobId(jobs[currentJobIndex].id);
      setShareType(jobs[currentJobIndex].share_type);
    } else {
      setShareType('playlist_sharing');
    }
  }, [jobs.length, currentJobIndex, selectedJobId, isLoadingJobs]);

  useEffect(() => {
    if (!selectedCompany && companies.length > 0) {
      setSelectCompanyId(companies[0].id);
      setSelectCompany(companies[0].name);
      setCurrentCompany(companies[0]);
    }
    // eslint-disable-next-line
  }, [selectedCompany, companies, setSelectedJobId, setSelectCompany]);

  const setCurrentJobType = (t: string) => {
    setJobType({ label: firstCapitalLetter(t), value: t });
    handleCompanyChange({ label: currentCompany!.name, value: currentCompany!.id }, t);
    fetchMusicianJobs(t, selectedCompanyId! as number, !!isAdmin);
  };

  const autoforwardingCX = cx('tooltip-autoforwarding', {
    selected: jobShareType === 'auto_forwarding',
    disabled: jobs.length === 0 || !selectedJobId,
  });

  const shareTooltipText = useMemo(() => {
    if (!selectedJobId) {
      return '';
    } else {
      if (jobShareType === 'auto_forwarding') {
        return 'Autoforwarding job share type';
      } else {
        return 'Playlist sharing job share type';
      }
    }
  }, [selectedJobId, jobShareType]);

  return (
    <div className="job-panel-container d-flex align-center">
      <div className={SelectContainerCX}>
        <div style={{ marginRight: 10 }}>
          <span className="fake-placeholder">{'Company'}</span>
          <MaterialSelect
            onOpenChange={onOpenChange}
            value={selectCompanyValue}
            placeholder="Choose a company"
            selectItems={companyList()}
            onChange={() => ''}
            className={SelectCX}
            isAllValueNeed
            isLoading={isLoadingUsers}
            onChangeAllValue={handleCompanyChange}
            disabled={isLoadingUsers}
          />
        </div>

        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <span className="fake-placeholder">{'Job type'}</span>
          <MaterialSelect
            onOpenChange={onOpenChange}
            value={jobType}
            placeholder="Type"
            selectItems={jobStatuses}
            onChange={setCurrentJobType as any}
            className=""
            width={100}
          />
        </div>

        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <span className="fake-placeholder">{'Active job'}</span>
          <MaterialSelect
            onOpenChange={onOpenChange}
            isLoading={isLoadingJobs}
            value={jobsDropValue()}
            placeholder="Select active job"
            selectItems={getjobs()}
            onChange={setCurrentJob}
            className={SelectCX}
          />
        </div>

        {!isAdmin && jobShareType !== 'auto_forwarding' && (
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <span className="fake-placeholder">{'Shared playlist'}</span>
            <MaterialSelect
              onOpenChange={onOpenChange}
              isLoading={isLoadingSharedPl}
              value={sharedPlaylist}
              placeholder="Select shared playlist"
              selectItems={sharedPlaylistItems}
              isAllValueNeed
              className={SelectCX}
              onChangeAllValue={setCurrentSharedPlaylist}
            />
          </div>
        )}
        {isAdmin && (
          <div className="d-flex m-t-20 align-center m-l-10 m-r-10">
            <Tooltip title={shareTooltipText}>
              <span className={autoforwardingCX} onClick={onChange}>
                A
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="count-container m-t-6">
        <FilteredCount count={submittedSongs} title="Songs submitted" />
        <FilteredCount count={amount} title="After Q filter" isBorderOnTheSides />
        <FilteredCount count={currentPlaylistNum || 0} title="Added to playlist" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    jobs: jobs$(state),
    isAuthLoading: isAuthLoading$(state),
    userName: userName$(state),
    isAuth: isAuth$(state),
    from: from$(state),
    to: to$(state),
    currentJobIndex: currentJobIndex$(state),
    playlist_id: playlist_id$(state),
    sharedPlaylistId: sharedPlaylistId$(state),
    isLoadingUsers: isLoadingUsers$(state),
    isLoadingJobs: isLoadingJobs$(state),
    companies: companies$(state),
    sharedPlaylists: sharedPlaylists$(state),
    isLoadingSharedPl: isLoadingSharedPl$(state),
    amount: amount$(state),
    currentCompany: currentCompany$(state),
    isLoadingCompanies: isLoadingCompanies$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    getUsersType: dispatchProps.getUsersType,
    fetchMusicianJobs: dispatchProps.fetchMusicianJobs,
    getAppliedTrack: dispatchProps.getAppliedTrack,
    setCurrJobIndex: dispatchProps.setCurrJobIndex,
    setCurrentJobId: dispatchProps.setCurrentJobId,
    setManagerEventListenerOn: dispatchProps.setManagerEventListenerOn,
    setManagerEventListenerInit: dispatchProps.setManagerEventListenerInit,
    fetchCompanyList: dispatchProps.fetchCompanyList,
    setAutoForwarding: dispatchProps.setAutoForwarding,
    getSharedPlaylistTracks: dispatchProps.getSharedPlaylistTracks,
    updateShareType: dispatchProps.updateShareType,
    getSharedTracks: dispatchProps.getSharedTracks,
    setSharedPlaylistInit: dispatchProps.setSharedPlaylistInit,
    setSharedPlaylistId: dispatchProps.setSharedPlaylistId,
    setCurrentCompany: dispatchProps.setCurrentCompany,
    setRatingFromTo: dispatchProps.setRatingFromTo,
    setSharedType: dispatchProps.setSharedType,
    getNewTracksForPlaylist: dispatchProps.getNewTracksForPlaylist,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    getUsersType: UsersActions.getUsersType,
    fetchMusicianJobs: MusicJobsCreator.fetchMusicianJobs,
    getAppliedTrack: TrackCreators.getAppliedTrack,
    setCurrentJobId: MusicJobsActions.setCurrentJobId,
    setCurrJobIndex: MusicJobsActions.setCurrJobIndex,
    setManagerEventListenerOn: MAnagerEventListenerActions.setManagerEventListenerOn,
    setManagerEventListenerInit: MAnagerEventListenerActions.setManagerEventListenerInit,
    fetchCompanyList: CompaniesCreators.fetchCompanyList,
    setAutoForwarding: PlaylistActions.setAutoForwarding,
    getSharedPlaylistTracks: TrackCreators.getSharedPlaylistTracks,
    updateShareType: CompanyJobsCreators.updateShareType,
    getSharedTracks: NewTracksCreators.getSharedTracks,
    setSharedPlaylistInit: SharedPlaylistActions.setSharedPlaylistInit,
    setSharedPlaylistId: SharedPlaylistActions.setSharedPlaylistId,
    setCurrentCompany: CompaniesActions.setCurrentCompany,
    setRatingFromTo: RatingActions.setRatingFromTo,
    setSharedType: NewTracksActions.setShareType,
    getNewTracksForPlaylist: NewTracksActions.getNewTracksForPlaylist,
  },
  mergeProps
)(memo(JobPanel));
