import './style.scss';

import Input from 'components/input/input';
import * as Const from 'constants/index';
// eslint-disable-next-line
import React, { ChangeEvent, FormEvent, memo, useState } from 'react';
import { NavLink } from 'react-router-dom';

interface IForm {
  className?: string;
  onSubmit: (login: string, pass: string) => void;
}

const Form = ({ className, onSubmit }: IForm) => {
  const [passError, setPassError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isShowPass, setShowPass] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const formSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password) {
      setPassError('The field "Password" is required');
    }

    if (!email) {
      setLoginError('The field "Email" is required');
    }

    if (email && password) {
      onSubmit(email, password);
      setEmail('');
      setPassword('');
    }
  };

  const handleLogin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > Const.MAX_EMAIL_LENGTH) {
      setLoginError(`Email can be max ${Const.MAX_EMAIL_LENGTH} characters long`);
    } else {
      setLoginError('');
      setEmail(e.target.value);
    }
  };

  const handlePass = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === Const.MAX_PASSWORD_LENGTH) {
      setPassError(`Password can be max ${Const.MAX_PASSWORD_LENGTH} characters long`);
    } else {
      setPassError('');
      setPassword(e.target.value);
    }
  };

  const handlePassClick = () => setPassError('');
  const handleLoginClick = () => setLoginError('');
  const handleSetShowPass = () => setShowPass(!isShowPass);

  return (
    <form onSubmit={formSubmit} className={className}>
      <Input
        focused
        styleClass="inp-primary"
        type="text"
        value={email}
        onChange={handleLogin}
        placeholder={Const.EMAIL_PLACEHOLDER}
        onClick={handleLoginClick}
        error={loginError}
        maxLength={Const.MAX_EMAIL_LENGTH}
        rootClassName={'input-for-sign-up'}
        size="big"
      />
      <Input
        styleClass="inp-primary"
        type={isShowPass ? 'text' : 'password'}
        value={password}
        onChange={handlePass}
        placeholder={Const.PASS_PLACEHOLDER}
        error={passError}
        onClick={handlePassClick}
        isShowPass={isShowPass}
        setShowPass={handleSetShowPass}
        isInputPass
        maxLength={Const.MAX_PASSWORD_LENGTH}
        size="big"
        rootClassName={'input-for-sign-up'}
      />
      <button type={'submit'} style={{ marginBottom: 16 }} className="btn-primary btn-log-in">
        LOG IN
      </button>
      <span className="sign-up-link">
        {'Or you can '}
        <NavLink to="/sign-up" className="sign-link">
          {' '}
          Sign Up
        </NavLink>{' '}
      </span>
    </form>
  );
};

export default memo(Form);
