import * as Const from '../constants';
import State from '../interfaces/state/UploadTrackState';

const initState: State = {
  track: null,
  isLoading: false,
  isError: false,
  progress: 0,
  isOpenUploadPopup: false,
  duration: null,
  file_size: 0,
};

export default function uploadTrack(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.UPLOAD_TRACK_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.UPLOAD_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.UPLOAD_TRACK_RECEIVE:
      return {
        ...state,
        track: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.UPLOAD_TRACK_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.UPLOAD_TRACK_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };

    case Const.UPLOAD_TRACK_POPUP:
      return {
        ...state,
        isOpenUploadPopup: action.payload,
      };

    case Const.UPLOAD_DURATION_TRACK: {
      return {
        ...state,
        duration: action.payload,
      };
    }

    case Const.UPLOAD_FILE_SIZE_TRACK: {
      return {
        ...state,
        file_size: action.payload,
      };
    }
    default:
      return state;
  }
}
