import { Action } from 'redux';
import * as Const from '../constants';

export const setDownloadTrackInit = (): Action => {
  return {
    type: Const.DOWNL_INIT,
  };
};

export const getDownloadTrackSuccess = (): Action => {
  return {
    type: Const.DOWNL_SUCCESS,
  };
};

export const setDownloadTrackFail = (): Action => {
  return {
    type: Const.DOWNL_ERROR,
  };
};

export const setDownloadTrackPending = (): Action => {
  return {
    type: Const.DOWNL_PENDING,
  };
};
