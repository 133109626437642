import { Button, Tooltip } from '@material-ui/core';
// import ContactsRoundedIcon from '@material-ui/icons/ContactsRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import { ICompany } from 'interfaces/state/CompaniesState';
// eslint-disable-next-line
import React, { MouseEvent, useState } from 'react';

import styles from './style.module.scss';

interface OwnProps {
  userName: string | null;
  company: ICompany;
  deleteMemberFromCompany: (company_id: number, member_id: number) => void;
}

interface IProps extends OwnProps {}

interface IMemberProps {
  element: JSX.Element;
  value: string;
  width: number | string;
}

const MemberItem = ({ element, value, width }: IMemberProps) => {
  return (
    <Tooltip title={value} arrow placement="left">
      <span style={{ width }}>{element}</span>
    </Tooltip>
  );
};

export const MemberListPopup = ({ company, userName, deleteMemberFromCompany }: IProps) => {
  const userRoleByName = company.members.find(member => member.user_name === userName)?.role_name;

  const [currentMemberId, setMemberId] = useState(null as number | null);

  const [optionsAnchor, setOptionsAnchor] = useState<HTMLButtonElement | null>(null);
  const isOpenOptions = Boolean(optionsAnchor);
  const optionsId = isOpenOptions ? 'simple-popover' : undefined;
  const handleOptionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsAnchor(event.currentTarget);
  };

  const onDelete = () => {
    if (currentMemberId) {
      deleteMemberFromCompany(company.id, currentMemberId!);
      setOptionsAnchor(null);
    }
  };

  const onClick = (id: number) => {
    setMemberId(id);
  };

  const handleCloseOptions = () => {
    setOptionsAnchor(null);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{`Member list of company: "${company.name}"`}</div>
      <div className={styles.header}>
        <div className={styles.name}>Name</div>
        <div className={styles.email}>Email</div>
        <div className={styles.role}>Role</div>
        <div className={styles.actions}>Actions</div>
      </div>
      <div className={styles.table}>
        {company.members.map(memb => {
          return (
            <div className={styles.row} key={memb.id}>
              <MemberItem width={300} element={<div className={styles.name}>{memb.user_name}</div>} value={memb.user_name} />
              <MemberItem width={'calc(100% - 450px)'} element={<div className={styles.email}>{memb.email}</div>} value={memb.email} />
              <MemberItem width={100} element={<div className={styles.role}>{memb.role_name}</div>} value={memb.role_name} />
              {memb.role_name !== 'owner' &&
                (userRoleByName === 'admin' || userRoleByName === 'owner' ? (
                  <MemberItem
                    width={50}
                    element={
                      <div className={styles.actions} onClick={() => onClick(memb.id)}>
                        <Button
                          disableRipple
                          className={styles.action}
                          size="small"
                          aria-describedby={optionsId}
                          color="default"
                          onClick={handleOptionsClick}
                        >
                          <MoreVertRoundedIcon />
                        </Button>
                      </div>
                    }
                    value={'Actions'}
                  />
                ) : null)}
            </div>
          );
        })}
      </div>

      <div className="d-flex align-center justify-around">
        <Popover
          id={optionsId}
          open={isOpenOptions}
          anchorEl={optionsAnchor}
          onClose={handleCloseOptions}
          verticalAnchor="bottom"
          horizontalAnchor="center"
          verticalTransform="top"
          horizontalTransform="left"
          content={
            <div className={styles.popoverContent}>
              <Popup
                disabled={false}
                onApply={onDelete}
                isVisibleOverflow
                btnClassName="btn-del-playlist"
                buttonText={
                  <Tooltip title="Delete member from company">
                    <div className={styles.actionItem}>
                      <DeleteForeverRoundedIcon className="share-option-icon" />
                      <span className={styles.actionItemTitle}>Delete </span>
                    </div>
                  </Tooltip>
                }
                modalContent={<div style={{ marginBottom: 24 }}>Are you sure you want to delete this user from company?</div>}
              />
              {/* <Popup
                disabled={false}
                onApply={() => ''}
                isVisibleOverflow
                btnClassName="btn-del-playlist"
                buttonText={
                  <Tooltip title="Change user role in the company">
                    <div className={styles.actionItem}>
                      <ContactsRoundedIcon className="share-option-icon" />
                      <span className={styles.actionItemTitle}>Change role</span>
                    </div>
                  </Tooltip>
                }
                modalContent={<div>Change user role in the company</div>}
              /> */}
            </div>
          }
        />
      </div>
    </div>
  );
};
