import JobList from 'containers/jobList';
import { ICompanyJob } from 'interfaces/ICompanyJob';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userType$ } from 'selectors/auth';
import { isLoading$ as isLoadingCompanyJobs$, localjobs$ } from 'selectors/companyJob';
import { allJobs$, isLoading$ } from 'selectors/musicianJobs';

import style from './jobList.module.scss';

interface IStateProps {
  jobs: ICompanyJob[];
  allJobs: ICompanyJob[];
  isLoadingJobs: boolean;
  isLoadingCompanyJobs: boolean;
  userType: string | null;
}

interface IDispatchProps {}

interface OwnProps {}
interface IProps extends OwnProps, IDispatchProps, IStateProps {}

class AllJobs extends PureComponent<IProps> {
  jobsByUserType = () => {
    const { userType, jobs, allJobs } = this.props;

    return userType === 'manager' ? jobs : allJobs;
  };

  public render() {
    const { isLoadingJobs, isLoadingCompanyJobs } = this.props;
    return (
      <section className={style.root}>
        <JobList isAdmin={false} items={this.jobsByUserType()} isLoadingItems={isLoadingJobs || isLoadingCompanyJobs} />
      </section>
    );
  }
}

const mapStateToProps = (state: State): IStateProps => {
  return {
    allJobs: allJobs$(state),
    jobs: localjobs$(state),
    isLoadingJobs: isLoading$(state),
    isLoadingCompanyJobs: isLoadingCompanyJobs$(state),
    userType: userType$(state),
  };
};

export default connect(mapStateToProps)(AllJobs);
