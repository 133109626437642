import './style.scss';

import AudioPanel from 'components/audioPanel/audioPanel';
import Playlist from 'components/playlist/playlist';
import TrackPanel from 'components/trackPanel/trackPanel';
import JobPanel from 'containers/jobPanel/jobPanel';
// import StatisticPanel from 'containers/statisticPanel/statisticPanel';
import * as CompaniesCreators from 'creators/companies';
import * as MusicianJobsCreators from 'creators/musicianJobs';
import * as StatsCreators from 'creators/stats';
import State from 'interfaces/state/State';
import { IStatTrack } from 'interfaces/state/StatsState';
// eslint-disable-next-line
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userName$ } from 'selectors/auth';
import { isLoading$ } from 'selectors/companies';
import * as StatsSelectors from 'selectors/stats';

import style from './style.module.scss';

interface IStateProps {
  userName: string | null;
  tracks: IStatTrack[];
  isLoading: boolean;
}

interface IDispatchProps {
  getStats: () => void;
  fetchAllMusicianJobs: (status: string) => void;
  fetchAllCompanies: (isAdmin: boolean, l?: boolean, f?: boolean, isLoadingJobs?: boolean) => void;
  fetchCompanyList: () => void;
}

interface OwnProps {
  isHideCompanySelect?: boolean;
  isAdmin: boolean;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

class ActiveJobList extends PureComponent<IProps> {
  public async componentDidMount() {
    this.handleStep(1);
    this.fetchInitJobs();
  }

  state = {
    isOpen: false,
    step: 1,
  };

  // eslint-disable-next-line
  private fetchInitJobs = () => {
    const { isHideCompanySelect, isLoading } = this.props;
    if (isLoading) return;

    if (isHideCompanySelect) {
      this.props.fetchCompanyList();
      return;
    } else {
      this.props.fetchAllCompanies(true, undefined, undefined, true);
    }
  };

  public componentDidUpdate(prevProps: IStateProps & IDispatchProps) {
    if (prevProps.userName && prevProps.userName !== this.props.userName) {
      this.fetchInitJobs();
    }
  }

  private handleStep = (step: number) =>
    this.setState(() => ({
      step,
    }));

  public render() {
    const { isHideCompanySelect, isAdmin } = this.props;

    return (
      <div className={style.root}>
        <JobPanel isHideCompanySelect={isHideCompanySelect} isAdmin={isAdmin} />
        <AudioPanel className={'manager_audiopanel_container'} isNeedRating isFullWidth />
        <div className={style.panels}>
          <Playlist isAdmin={isAdmin} />
          <TrackPanel isAdmin={isAdmin} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State): IStateProps => {
  return {
    userName: userName$(state),
    tracks: StatsSelectors.statisticTracks$(state),
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchAllMusicianJobs: dispatchProps.fetchAllMusicianJobs,
    getStats: dispatchProps.getStats,
    fetchAllCompanies: dispatchProps.fetchAllCompanies,
    fetchCompanyList: dispatchProps.fetchCompanyList,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchAllMusicianJobs: MusicianJobsCreators.fetchAllMusicianJobs,
    getStats: StatsCreators.getStats,
    fetchAllCompanies: CompaniesCreators.fetchAllCompanies,
    fetchCompanyList: CompaniesCreators.fetchCompanyList,
  },
  mergeProps
)(ActiveJobList);
