import 'react-h5-audio-player/lib/styles.css';

import './style.scss';

import * as SharedPlayerActions from 'actions/sharedPlayer';
import * as SharedPlayerCreators from 'creators/sharedPlayer';
import { INewTrack } from 'interfaces/INewTrack';
import { IPlayerURLById } from 'interfaces/state/SharedPlayerState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { connect } from 'react-redux';
import { currentTrack$, currentURLByID$, isNeedToPlayAll$ } from 'selectors/sharedPlayer';
import { list$ } from 'selectors/sharedPlaylistTracks';

import AudioInformation from '../audioInformation';
import style from './player.module.scss';

interface IStateProps {
  currentURLByID: IPlayerURLById;
  currentTrack: INewTrack | null;
  isNeedToPlayAll: boolean;
  list: INewTrack[];
}
interface OwnProps {}
interface IDispatchProps {
  setSharedPlayerReceive: (track: INewTrack) => void;
  fetchTrackUrl: (track_id: number) => void;
  setSharedPlayerPlay: (is: boolean) => void;
}
interface IProps extends OwnProps, IDispatchProps, IStateProps {}
const Player = ({
  currentURLByID,
  currentTrack,
  isNeedToPlayAll,
  list,
  setSharedPlayerReceive,
  fetchTrackUrl,
  setSharedPlayerPlay,
}: IProps) => {
  const [localSrc, setLocalSrc] = useState('');
  const [index, setPlayTrackIndex] = useState(0);

  useEffect(() => {
    if (
      currentURLByID &&
      currentTrack &&
      currentTrack!.track_id &&
      currentURLByID[currentTrack!.track_id!] &&
      currentURLByID[currentTrack!.track_id!].url
    ) {
      setLocalSrc(currentURLByID[currentTrack!.track_id!].url!);
    }
  }, [currentURLByID, currentTrack]);

  const onEnded = (_e: Event) => {
    setPlayTrackIndex(index + 1);

    if (isNeedToPlayAll) {
      if (list && list[index + 1]) {
        setSharedPlayerReceive(list[index + 1]);
        fetchTrackUrl(list[index + 1].track_id!);
      }
    } else {
      setPlayTrackIndex(0);
    }
  };

  const onPause = () => {
    setSharedPlayerPlay(false);
  };

  const onPlay = () => {
    setSharedPlayerPlay(true);
  };
  return (
    <div className={style.root}>
      <AudioInformation />
      <AudioPlayer
        autoPlay
        onPause={onPause}
        onPlay={onPlay}
        autoPlayAfterSrcChange
        loop={false}
        layout="horizontal-reverse"
        src={localSrc}
        onEnded={onEnded}
        className="shared-player"
        preload="auto"
        volume={0.4}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentURLByID: currentURLByID$(state),
    currentTrack: currentTrack$(state),
    isNeedToPlayAll: isNeedToPlayAll$(state),
    list: list$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setSharedPlayerReceive: dispatchProps.setSharedPlayerReceive,
    fetchTrackUrl: dispatchProps.fetchTrackUrl,
    setSharedPlayerPlay: dispatchProps.setSharedPlayerPlay,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setSharedPlayerReceive: SharedPlayerActions.setSharedPlayerReceive,
    setSharedPlayerPlay: SharedPlayerActions.setSharedPlayerPlay,
    fetchTrackUrl: SharedPlayerCreators.fetchTrackUrl,
  },
  mergeProps
)(Player);
