import * as DownloadFileActions from 'actions/downloadFile';
import * as DownlActions from 'actions/downloadTrack';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
// import * as SiteEventsCreators from 'creators/siteEvents';
import { Api } from 'enums/api';
import moment from 'moment';
import { Action } from 'redux';
import { DATE_TIME_FORMAT } from 'utils';

export const downloadFile = (playlist_id: number, title: string) => {
  return async (dispatch: (action: Action) => void) => {
    dispatch(DownlActions.setDownloadTrackPending());

    const date = moment().format(DATE_TIME_FORMAT);
    const downloadFile = (url: string) => {
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Disposition': `attachment; filename="${encodeURI(title)}.csv"`,
        },
        onDownloadProgress: p => {
          if (p.loaded / p.total === 1) {
            successNotification(`Playlist metadata downloaded!`, 8000);
          }
        },
      })
        .then(response => {
          dispatch(DownloadFileActions.getDownloadFileSuccess());
          // dispatch(SiteEventsCreators.sendEvent('upload') as any);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${title}(${date}).csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          dispatch(DownlActions.setDownloadTrackFail());
        });
    };

    axios
      .post(`${Api.GetPlaylistMetadata}`, {
        playlist_id,
      })
      .then(response => {
        downloadFile(response.data.result);
      })
      .catch(() => {
        dispatch(DownlActions.setDownloadTrackFail());
      });
  };
};
