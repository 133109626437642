import IApp from 'interfaces/IApp';
// eslint-disable-next-line
import React from 'react';

import style from './trackList.module.scss';

interface IProps {
  current_playlist: IApp | null;
  handleSubmit: () => void;
}

export const TrackList = ({ current_playlist, handleSubmit }: IProps) => {
  return (
    <div className={style.root}>
      <div className={style.tracks}>
        {current_playlist
          ? current_playlist?.tracks.map((track, index) => {
              return (
                <div className={style.track} key={track.track_id} title={track.title}>
                  <b>{`${index + 1}.  `}</b>
                  {track.title}
                </div>
              );
            })
          : null}
        <button className={style.btn} onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};
