// eslint-disable-next-line
import React from 'react';

import style from './inviteTitle.module.scss';

const headers = ['Team Name', 'Member Name', 'Email', 'Status', 'Create date'];
export const InviteTitle = () => {
  return (
    <div className={style.root}>
      {headers.map(header => (
        <div className={style.headerIteam} key={header}>
          {header}
        </div>
      ))}
    </div>
  );
};
