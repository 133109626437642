import { ReactComponent as ArrowFWD } from 'assets/img/arrow-fwd.svg';
// eslint-disable-next-line
import React from 'react';
import { useHistory } from 'react-router-dom';

import style from './header.module.scss';

interface IProps {
  name?: string;
}
export const Header = ({ name }: IProps) => {
  const history = useHistory();

  const handleBackClick = () => {
    history.push('/sh_admin/company/list');
  };

  return (
    <header className={style.root}>
      <button className={style.btn} onClick={handleBackClick}>
        <ArrowFWD className={style.icon} />
        <span className={style.btnText}>Back</span>
      </button>

      <div className={style.mainHeaderContent}>
        <h1 className={style.head}>{`Information about company "${name || ''}"`}</h1>
      </div>
    </header>
  );
};
