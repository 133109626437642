import cx from 'classnames';
// eslint-disable-next-line
import React, { ChangeEvent, ClipboardEvent, RefObject, useEffect, useRef, useState } from 'react';

import style from './textarea.module.scss';

interface IProps {
  value: string;
  title: string;
  onChange: (e: string | number) => void;
  maxLength: number;
  className?: string;
  maxRows?: number;
  placeholder?: string;
  isError?: boolean;
}

export const Textarea = ({ value, title, onChange, maxLength, className, maxRows, placeholder, isError }: IProps) => {
  const [error, setError] = useState('');
  const handleInputClick = () => setError('');

  const textareaRef = useRef(null) as RefObject<HTMLTextAreaElement> | null;

  useEffect(() => {
    if (textareaRef?.current?.style) {
      textareaRef!.current!.style!.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [value, textareaRef]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > maxLength) {
      setError(`Can be max ${maxLength} characters long`);
    } else {
      setError('');
      onChange(e.target.value);
    }
  };

  const onPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = e.clipboardData.getData('Text');
    if (pastedText.length + value.length > maxLength) {
      setError(`Can be max ${maxLength} characters long`);
      e.preventDefault();
      return;
    }
  };

  const TextAreaCX = cx(style.textarea, {
    [style.errorText]: error || isError,
    className: className,
  });

  const ErrorCX = cx(style.invisible, {
    [style.error]: error,
  });

  return (
    <div className={style.root}>
      <div className={style.title}>{title}</div>
      <textarea
        ref={textareaRef}
        className={TextAreaCX}
        value={value}
        onChange={handleChange}
        onPaste={onPaste}
        placeholder={placeholder}
        onClick={handleInputClick}
        rows={maxRows}
      />
      <div className={ErrorCX}>{error}</div>
    </div>
  );
};
