import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as AuthActions from 'actions/auth';
import Input from 'components/input/input';
import { Loader } from 'components/loader/loader';
import * as AuthCreators from 'creators/auth';
import * as ReceivePassCretors from 'creators/receivePass';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { email$, isError$, isLoading$, isSuccessSendPass$, token$ } from 'selectors/receivePass';

import * as Const from '../../constants';
import styles from './resetPassword.module.scss';

interface OwnProps {}

interface IStateProps {
  isError: boolean;
  isLoading: boolean;
  token: string;
  email: string;
  isSuccessSendPass: boolean;
}

interface IDispatchProps {
  receivePass: (email: string, token: string, password: string) => void;
  logout: () => void;
  initAllApplication: () => void;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const ResetPassword = (props: IProps) => {
  const { receivePass, email, token, isError, isLoading, isSuccessSendPass, initAllApplication } = props;

  const [pass, setPass] = useState('');
  const [isShowPass, setShowPass] = useState(false);
  const [passError, setPassError] = useState('');

  const handleSetShowPass = () => setShowPass(!isShowPass);
  const handlePassClick = () => setPassError('');
  const handlePass = (e: ChangeEvent<HTMLInputElement>) => setPass(e.target.value);
  const onSubmit = () => {
    if (pass.length > 5) {
      receivePass(email, token, pass);
    } else {
      setPassError('Password must be longer than 5 characters');
    }
  };

  const onSuccessClick = () => {
    initAllApplication();
  };

  return (
    <div className={styles.root}>
      {isLoading && (
        <div className="loading-container">
          <Loader />
        </div>
      )}

      {isSuccessSendPass && (
        <div className={styles.successNoteContainer}>
          <div className={styles.successWrap}>
            <div className={styles.successPharagraph}>You have successfully changed your password!</div>
            <div className={styles.link}>
              Click{' '}
              <NavLink to="/" onClick={onSuccessClick}>
                {' '}
                here
              </NavLink>
            </div>
          </div>

          <CheckCircleIcon className={styles.icon} />
        </div>
      )}
      {isError || !token || !email ? (
        <section className={styles.errorContainer}>
          An error occurred while changing the password.
          <span style={{ marginLeft: 10 }}>
            Click <NavLink to="/help"> here</NavLink>
          </span>
        </section>
      ) : isSuccessSendPass ? null : (
        <section className={styles.passContainer}>
          <p className={styles.pharagraph}>Enter your new password!</p>
          <div className={styles.passInputWrapper}>
            <Input
              styleClass="inp-primary"
              type={isShowPass ? 'text' : 'password'}
              value={pass}
              onChange={handlePass}
              placeholder={Const.PASS_PLACEHOLDER}
              error={passError}
              onClick={handlePassClick}
              isShowPass={isShowPass}
              setShowPass={handleSetShowPass}
              isInputPass
            />
            <button className={styles.btn} onClick={onSubmit}>
              Submit
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: isLoading$(state),
    isError: isError$(state),
    token: token$(state),
    email: email$(state),
    isSuccessSendPass: isSuccessSendPass$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    receivePass: dispatchProps.receivePass,
    logout: dispatchProps.logout,
    initAllApplication: dispatchProps.initAllApplication,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    receivePass: ReceivePassCretors.receivePass,
    logout: AuthCreators.onLogout,
    initAllApplication: AuthActions.initAllApplication,
  },
  mergeProps
)(ResetPassword);
