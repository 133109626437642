// eslint-disable-next-line
import React from 'react';

import style from './musicianTrackItemHeader.module.scss';

const headerItems = ['', 'Track name', 'Description', 'File size', 'Tags', 'More Information'];

export const MusicianTrackItemHeader = () => {
  return (
    <div className={style.root}>
      {headerItems.map((item, key) => {
        return (
          <div key={key} className={style.item}>
            {item}
          </div>
        );
      })}
    </div>
  );
};
