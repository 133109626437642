import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import * as NewTracksActions from 'actions/newTracks';
import cx from 'classnames';
import * as NewTracksCreators from 'creators/newTracks';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';
import { orderBy$, sortType$ } from 'selectors/newTrack';

import style from './managerTrackControllHeader.module.scss';

interface IStateProps {
  orderBy: string;
  sortType: string;
}

interface IDispatchProps {
  setCurrentSortType: (type: string) => void;
  setCurrentOrderBy: (by: string) => void;
}

interface OwnProps {
  isCreator?: boolean;
}

const options: { title: string; order: string; withSort?: boolean }[] = [
  { title: 'Add', order: '' },
  { title: 'Play', order: '' },
  { title: 'Track title', order: 'title', withSort: true },
  { title: 'Duration', order: 'duration', withSort: true },
  { title: 'Artist name', order: 'artist_name', withSort: true },
  { title: 'Rating', order: 'rating', withSort: true },
  { title: 'Download', order: 'creation_date', withSort: true },
];

const optionsForCreators: { title: string; order: string; withSort?: boolean }[] = [
  { title: 'Add', order: '' },
  { title: 'Play', order: '' },
  { title: 'Track title', order: 'title' },
  { title: 'Duration', order: 'duration' },
  { title: 'Artist name', order: 'artist_name' },
  { title: 'Download', order: 'creation_date' },
];

interface IProps extends IDispatchProps, IStateProps, OwnProps {}
const ManagerTrackControllHeader = ({ setCurrentSortType, orderBy, sortType, setCurrentOrderBy, isCreator }: IProps) => {
  const dispatch = useDispatch();
  const userType = useSelector(userType$);

  const changeSortingType = () => {
    if (sortType === 'asc') {
      setCurrentSortType('desc');
    } else {
      setCurrentSortType('asc');
    }
  };

  const setNewSortingOrder = (order: string) => {
    setCurrentOrderBy(order);
    setCurrentSortType('asc');
  };

  const onHeaderClick = ({ order }: { title: string; order: string }) => () => {
    if (isCreator) return;
    if (order === orderBy) {
      changeSortingType();
    } else {
      setNewSortingOrder(order);
    }
    dispatch(NewTracksCreators.fetchTracksBySorting(userType === 'manager' || userType === 'company'));
  };

  const titleCX = (withSort?: boolean) =>
    cx(style.head, {
      [style.sorting]: withSort,
    });

  const memoOptions = useMemo(() => {
    return isCreator ? optionsForCreators : options;
  }, [isCreator]);

  const rootCX = cx(style.manager, { [style.creator]: isCreator });
  return (
    <section className={rootCX}>
      {memoOptions.map((option, index) => {
        return (
          <div onClick={onHeaderClick(option)} key={index} className={`d-flex curs-pointer`}>
            <div className={titleCX(option?.withSort)}>{option.title}</div>
            {sortType === 'desc' && orderBy === option.order && orderBy !== '' ? <ArrowDownwardIcon className="sort-arrow" /> : null}

            {sortType === 'asc' && orderBy === option.order && orderBy !== '' ? <ArrowUpwardIcon className="sort-arrow" /> : null}
          </div>
        );
      })}
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    orderBy: orderBy$(state),
    sortType: sortType$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setCurrentOrderBy: dispatchProps.setCurrentOrderBy,
    setCurrentSortType: dispatchProps.setCurrentSortType,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setCurrentOrderBy: NewTracksActions.setCurrentOrderBy,
    setCurrentSortType: NewTracksActions.setCurrentSortType,
  },
  mergeProps
)(ManagerTrackControllHeader);
