import { LinkMark } from 'components/linkMark';
import { successNotification } from 'components/successNotification/successNotification';
import { IShareableLink } from 'interfaces/state/AppState';
// eslint-disable-next-line
import React from 'react';

import style from './shareableLink.module.scss';

interface ILinkProps {
  link: IShareableLink;
}

export const ShareableLink = ({ link }: ILinkProps) => {
  const handleCopyToClipboard = () => {
    if (navigator) {
      navigator.clipboard.writeText(link.link);
      successNotification(`Link was copied successfully!`);
    }
  };

  return (
    <div className={style.link}>
      <input value={link.link} className={style.input} onClick={handleCopyToClipboard} />

      <LinkMark
        value={link.allowed_all_tracks}
        positiveTitle="Allowed to download all tracks"
        negativeTitle="Not allowed to download all tracks"
      />

      <LinkMark
        value={link.allowed_single_track}
        positiveTitle="Allowed to download separately tracks"
        negativeTitle="Not allowed to download separately tracks"
      />
    </div>
  );
};
