import 'sweetalert2/src/sweetalert2.scss';

import * as AuthActions from 'actions/auth';
import * as SubscriptionListActions from 'actions/subscriptionList';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import moment from 'moment';
import { Action } from 'redux';
import { DATE_TIME_FORMAT } from 'utils';
import { catchedErrorNotification } from 'utils/error';

import * as CurrentSubscrActions from '../actions/currentSubscription';

export const fetchCurrentSubscription = (isCanceled?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(CurrentSubscrActions.setCurrSubscrPending());

    axios
      .post(`${Api.CurrentSubscription}`)
      .then(response => {
        dispatch(
          CurrentSubscrActions.getCurrSubscrSuccess({ ...response.data.result, currentSubscription: response.data.result.product_title })
        );

        if (isCanceled) {
          const cancelDate = moment(response.data.result.current_period_end).format(DATE_TIME_FORMAT);
          successNotification(`Your subscription has been canceled. The current subscription is valid until ${cancelDate}`);
        }

        const interval = response.data.result.interval;
        const intervalPeriod = interval === 'year';
        if (response?.data?.result?.interval) {
          dispatch(SubscriptionListActions.setSubsPeriod(intervalPeriod));
        }

        if (response.data?.result?.is_active_subscription) {
          dispatch(
            CurrentSubscrActions.getCurrSubscrSuccess({ ...response.data.result, currentSubscription: response.data.result.product_title })
          );
          dispatch(AuthActions.getUserSubscription(response.data.result.product_title));
        } else {
          const subscription = { ...response.data.result, product_title: 'Free', currentSubscription: response.data.result.product_title };
          dispatch(AuthActions.getUserSubscription('Free'));
          dispatch(CurrentSubscrActions.getCurrSubscrSuccess(subscription));
        }
      })
      .catch(error => {
        dispatch(CurrentSubscrActions.setCurrSubscrFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
