import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Popup from 'components/popup/popup';
import { ShareableLink } from 'components/shareableLink';
import { SwitchMaterial } from 'components/switch';
import { IShareableLink } from 'interfaces/state/AppState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { shareableLinks$ } from 'selectors/applications';

import style from './shareableLinks.module.scss';

interface IStateProps {
  shareableLinks: IShareableLink[] | null;
}
interface OwnProps {
  onCreateLink: (allowed_all_tracks: boolean, allowed_single_track: boolean) => void;
}
interface IDispatchProps {}
interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const ShareableLinksComponent = ({ shareableLinks, onCreateLink }: IProps) => {
  const [allowed_all_tracks, setAllowAll] = useState(false);
  const [allowed_single_tracks, setAllowSingle] = useState(false);

  const handleAllowDownloadAllTracks = () => {
    setAllowAll(val => !val);
  };

  const handleAllowDownloadTrack = () => {
    setAllowSingle(val => !val);
  };

  const handleCreateLink = () => {
    onCreateLink(allowed_all_tracks, allowed_single_tracks);
  };

  return (
    <div className={style.root}>
      <Popup
        onApply={handleCreateLink}
        btnClassName="btn-del-playlist"
        buttonText={
          <Tooltip title="Create a shareable link">
            <div className={style.createBtn}>Create Link</div>
          </Tooltip>
        }
        modalContent={
          <div style={{ height: 200 }} className="d-flex align-center flex-column">
            <div className="playlist-title-text">New Shareable link</div>
            <SwitchMaterial
              onChange={handleAllowDownloadAllTracks}
              checked={allowed_all_tracks}
              label={<div className="playlist-link-label label-1">Allow downloading all tracks</div>}
            />
            <SwitchMaterial
              onChange={handleAllowDownloadTrack}
              checked={allowed_single_tracks}
              label={<div className="playlist-link-label label-2">Allow downloading tracks separately</div>}
            />
          </div>
        }
      />
      <Scrollbars className={style.links}>
        {shareableLinks?.map(link => {
          return <ShareableLink key={link.link_id} link={link} />;
        })}
      </Scrollbars>
      {!shareableLinks?.length && <p className={style.emptyContent}>Your links to the playlist will be here</p>}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    shareableLinks: shareableLinks$(state),
  };
};

export const ShareableLinks = connect(mapStateToProps)(ShareableLinksComponent);
