// eslint-disable-next-line
import React, { CSSProperties, memo } from 'react';

interface IButton {
  onClick: () => void;
  value: string | JSX.Element;
  styleClass: 'btn' | 'btn-primary' | 'btn-danger' | 'btn-success' | 'btn-sign-admin' | 'button-secondary' | 'btn-header' | string;
  isLoading?: boolean;
  isDisabled?: boolean;
  style?: CSSProperties;
}

const Button = ({ onClick, value, styleClass, isLoading, isDisabled, style }: IButton) => {
  return (
    <button style={style} onClick={onClick} disabled={isDisabled || isLoading} className={styleClass}>
      {value}
    </button>
  );
};

export default memo(Button);
