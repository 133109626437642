import { ICompanyJob } from 'interfaces/ICompanyJob';
import { Action } from 'redux';

import * as Const from '../constants';

export const setCompanyJobsInit = (): Action => {
  return {
    type: Const.COMPANY_JOBS_INIT,
  };
};

export const getSuccessCompanyJobs = (payload: ICompanyJob[], isPagination?: boolean) => {
  return {
    type: Const.COMPANY_JOBS_SUCCESS,
    payload,
    isPagination,
  };
};

export const setCompanyJobsFail = (): Action => {
  return {
    type: Const.COMPANY_JOBS_ERROR,
  };
};

export const setCompanyJobsPending = (): Action => {
  return {
    type: Const.COMPANY_JOBS_PENDING,
  };
};

export const setCompanyJobsSorting = (payload: string) => {
  return {
    type: Const.COMPANY_JOBS_SORTING,
    payload,
  };
};

export const setCompanyJobsOrder = (payload: number) => {
  return {
    type: Const.COMPANY_JOBS_ORDER,
    payload,
  };
};

export const setCompanySortType = (payload: 'asc' | 'desc') => {
  return {
    type: Const.COMPANY_SORT_TYPE,
    payload,
  };
};

export const setAddedCompSuccess = (payload: ICompanyJob) => {
  return {
    type: Const.COMPANY_SUCCESS_ADDED,
    payload,
  };
};

export const setNewCompanyJobStatus = (payload: string, id: number) => {
  return {
    type: Const.COMPANY_CHANGE_STATUS,
    payload,
    id,
  };
};

export const setCompanyJobNewId = (payload: number) => {
  return {
    type: Const.COMPANY_NEW_ID,
    payload,
  };
};

export const setCompanyUpdatedJob = (payload: Partial<ICompanyJob>) => {
  return {
    type: Const.COMPANY_UPDATE_JOB,
    payload,
  };
};

export const setCompanyJobAvatar = (id: number, avatar: string) => {
  return {
    type: Const.COMPANY_UPDATE_JOB_AVATAR,
    id,
    avatar,
  };
};

export const setNewCompanyOwner = (id: number, company: string) => {
  return {
    type: Const.COMPANY_UPDATE_JOB_COMPANY,
    id,
    company,
  };
};

export const setPendindOffCompanyJobs = (): Action => {
  return {
    type: Const.COMPANY_JOB_SET_PEND_OFF,
  };
};

export const updateJobAvatarById = (url: string, job_id: number) => {
  return {
    type: Const.COMPANY_UPDATE_JOB_AVATAR_BY_ID,
    url,
    job_id,
  };
};

export const updateSelectedJobsType = (payload: 'all' | 'deleted' | 'closed' | 'archived' | 'open') => {
  return {
    type: Const.COMPANY_JOB_SELECT_TYPE,
    payload,
  };
};

export const setCompanyJobsInitFromFetch = () => {
  return {
    type: Const.COMPANY_JOB_INIT_FROM_FETCH,
  };
};
