import State from 'interfaces/state/State';

export const isLoading$ = (state: State) => state.shareablePlaylistInfo.isLoading;
export const isError$ = (state: State) => state.shareablePlaylistInfo.isError;
export const allowed_all_tracks$ = (state: State) => state.shareablePlaylistInfo.allowed_all_tracks;
export const allowed_single_track$ = (state: State) => state.shareablePlaylistInfo.allowed_single_track;
export const playlist_id$ = (state: State) => state.shareablePlaylistInfo.playlist_id;
export const link_id$ = (state: State) => state.shareablePlaylistInfo.link_id;
export const background$ = (state: State) => state.shareablePlaylistInfo.background;
export const owner$ = (state: State) => state.shareablePlaylistInfo.owner;
