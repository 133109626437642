import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import cx from 'classnames';
import { fetchStatistics } from 'creators/statistics';
// eslint-disable-next-line
import React from 'react';
import { useDispatch } from 'react-redux';

import style from './chartButtons.module.scss';

const btns = ['Visitors (monthly)', 'Song submitted (monthly)', 'User activity', 'Logins'];

interface IProps {
  month: number | null;
  year: number | null;
  currentBtnId: number;
  setCurrentId: (id: number) => void;
}

export const ChartButtons = ({ currentBtnId, setCurrentId, month, year }: IProps) => {
  const dispatch = useDispatch();

  const handleUpdateStatistics = () => {
    if (year !== null && month !== null) dispatch(fetchStatistics(year, month));
  };

  const BtnCX = (id: number) =>
    cx(style.btn, {
      [style.selected]: currentBtnId === id,
    });

  const handleSetId = (id: number) => () => setCurrentId(id);
  return (
    <nav className={style.root}>
      {btns.map((btn, key) => {
        return (
          <button key={btn} className={BtnCX(key)} onClick={handleSetId(key)}>
            {btn}
          </button>
        );
      })}

      <button className={style.btnUpdate} onClick={handleUpdateStatistics}>
        <AutorenewRoundedIcon className={style.icon} />
      </button>
    </nav>
  );
};
