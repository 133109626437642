import './musicianTrackItem.scss';

import * as NewTrackActions from 'actions/newTracks';
import classNames from 'classnames/bind';
import { TrackTypeTabs } from 'components/trackTypeTabs';
import * as MusicianTracksCreators from 'creators/newTracks';
import { INewTrack, TrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useState } from 'react';
import EventListener from 'react-event-listener';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { amount$, filterBy$ } from 'selectors/newTrack';
import { isLoading$ } from 'selectors/newTrack';

import MusicianTrack from './musicianTrack';
import styles from './musicianTrackItem.module.scss';
import { MusicianTrackItemHeader } from './musicianTrackItemHeader';

interface OwnProps {
  setCurrent: (n: number) => void;
  title: string;
  tracks: INewTrack[];
  subtitle: string;
  current: number;
  ind: number;
}
interface IStateProps {
  filterBy: TrackType;
  amount: number;
  isLoading: boolean;
}

interface IDispatchProps {
  setfilterBy: (value: TrackType) => void;
  fetchTracks: (variant: TrackType, preload?: boolean, limit?: number, offset?: number, loadPlaylistAfterTracks?: boolean) => void;
}

interface IProps extends OwnProps, IDispatchProps, IStateProps {}
const MusicianTrackItem = ({ setfilterBy, title, tracks, subtitle, setCurrent, ind, current, fetchTracks, filterBy, amount }: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetchTracks('all', true, 80, 0);
    setfilterBy('all');
    return () => setfilterBy('all');

    // eslint-disable-next-line
  }, []);

  const sectionClassNames = classNames({
    'mus-track-item-container': true,
  });

  const wrapperClassNames = classNames('d-flex flex-column', {
    'mus-track-item-wrap d-block': true,
  });

  const setArrowDownKeyPress = (e: KeyboardEvent): void => {
    if (e.code === 'ArrowDown' && currentIndex <= tracks.length - 2 && current === ind) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const setArrowUpKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'ArrowUp' && currentIndex <= tracks.length - 1 && currentIndex !== 0 && current === ind) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    setArrowDownKeyPress(e);
    setArrowUpKeyPress(e);
  };

  const handleFilter = (filter: TrackType) => {
    setfilterBy(filter);
    fetchTracks(filter as TrackType, true, 80, 0);
    setCurrentIndex(0);
  };

  const onAllClick = () => {
    handleFilter('all');
  };

  const onMusicClick = () => {
    handleFilter('music');
  };

  const onSFXClick = () => {
    handleFilter('sfx');
  };

  const onAudioClick = () => {
    handleFilter('audio');
  };

  // eslint-disable-next-line
  const onSectionClick = useCallback(() => setCurrent(ind), [ind]);

  const fetchNextTracks = () => {
    fetchTracks(filterBy, false, 80, tracks.length);
  };

  return (
    <section className={sectionClassNames} onClick={onSectionClick}>
      {!!tracks.length && <EventListener target={window} onKeyDown={onKeyDown} />}
      <nav className="mus-track-item-nav d-flex align-center justify-between">
        <div className="d-flex flex-column">
          <span className="mus-track-item-title">{`${title} (${amount} tracks)`}</span>
          <span className="mus-track-item-sub">{subtitle}</span>
        </div>
        <div className={styles.btnContainer}>
          <TrackTypeTabs
            onAllClick={onAllClick}
            onMusicClick={onMusicClick}
            onSFXClick={onSFXClick}
            onAudioClick={onAudioClick}
            type={filterBy}
          />
        </div>
      </nav>
      <MusicianTrackItemHeader />
      <div className={wrapperClassNames} style={{ height: 270 }}>
        <InfiniteScroll
          dataLength={tracks.length}
          next={fetchNextTracks}
          hasMore={true}
          height={'calc(100vh - 350px)'}
          loader={<div />}
          endMessage={
            <>
              {!tracks.length && (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>Nothing was found</b>
                </p>
              )}
              {!!tracks.length && (
                <p style={{ textAlign: 'center', marginTop: 20 }}>
                  <b>---</b>
                </p>
              )}
            </>
          }
          scrollableTarget="scrollableDiv"
        >
          {tracks
            ? tracks!.map((track, key) => {
                return <MusicianTrack track={track} key={key} index={key} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />;
              })
            : null}
        </InfiniteScroll>
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    filterBy: filterBy$(state),
    amount: amount$(state),
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setfilterBy: dispatchProps.setfilterBy,
    fetchTracks: dispatchProps.fetchTracks,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setfilterBy: NewTrackActions.setfilterBy,
    fetchTracks: MusicianTracksCreators.fetchTracks,
  },
  mergeProps
)(memo(MusicianTrackItem));
