import { setCouponPopup } from 'actions/auth';
import * as BillingActions from 'actions/billing';
import * as CurrentSubscrActions from 'actions/currentSubscription';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { IBilling } from 'interfaces/state/BillingState';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { billing$ } from 'selectors/billing';
import { period$ } from 'selectors/subscriptionList';
import { catchedErrorNotification } from 'utils/error';

import { fetchCurrentSubscription } from './currentSubscription';

export const fetchBillingInformation = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(BillingActions.setBillingLoading(true));

    axios
      .post(`${Api.GetBillingInfo}`)
      .then(response => {
        dispatch(BillingActions.getSuccessBilling(response.data.result));
      })
      .catch(error => {
        dispatch(BillingActions.setBillingFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const selectDefaultPayment = (payment_method_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(BillingActions.setBillingLoading(true));

    axios
      .post(`${Api.ChangeDefaultMethod}`, { payment_method_id })
      .then(() => {
        dispatch(BillingActions.selectDefaultMethod(payment_method_id));
        successNotification('Changed succesfully');
      })
      .catch(error => {
        dispatch(BillingActions.setBillingFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const redirectForAddNewPayment = () => {
  return (dispatch: (action: Action) => void) => {
    const redirect_url = window.location.href;
    axios
      .post(`${Api.AddPayment}`, { redirect_url })
      .then(response => {
        window.open(response?.data?.result?.url_to_redirect, '_blank');
      })
      .catch(error => {
        dispatch(BillingActions.setBillingFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updateSubscription = (product_id: string, coupon?: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(BillingActions.setBillingLoading(true));
    const redirect_url = window.location.href;
    const period = period$(getState());

    const periodicity = period ? 'year' : 'month';
    axios
      .post(Api.UpdateSubscription, {
        redirect_url,
        periodicity,
        product_id,
        coupon,
      })
      .then(response => {
        dispatch(CurrentSubscrActions.clearPriceId());
        dispatch(BillingActions.setBillingLoading(false));
        dispatch(setCouponPopup(false));

        if (!response?.data?.result && response?.data?.status === 'ok') {
          successNotification('Subscription was changed successfully!');
        }

        if (response?.data?.result?.url_to_redirect) {
          window.open(response?.data?.result?.url_to_redirect, '_blank');
        }

        if (response?.data?.result?.product_title) {
          dispatch(BillingActions.getSuccessBilling(response?.data?.result));
        }

        dispatch(fetchCurrentSubscription() as any);
      })
      .catch(error => {
        // dispatch(AdminActions.setAdminFail());
        dispatch(BillingActions.setBillingLoading(false));

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deletePaymentMethod = (payment_method_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(BillingActions.setBillingLoading(true));

    const billing = billing$(getState());

    axios
      .post(`${Api.DeletePayment}`, { payment_method_id })
      .then(() => {
        const newPaymentMethods = billing?.payment_methods.filter(plan => plan.payment_method_id !== payment_method_id);
        const newBilling = { ...billing, payment_methods: newPaymentMethods };
        dispatch(BillingActions.getSuccessBilling(newBilling as IBilling));
        successNotification('Deleted succesfully');
      })
      .catch(error => {
        dispatch(BillingActions.setBillingFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
