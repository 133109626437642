import * as PlaylistActions from 'actions/application';
import cx from 'classnames';
import * as PlaylistCreators from 'creators/application';
import IApp from 'interfaces/IApp';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { current_init_playlist$, current_playlist$, isLoading$, numberOfNewAddedTracks$ } from 'selectors/applications';
import { current_job_id$ } from 'selectors/musicianJobs';

import { TrackList } from './trackList';
import style from './trackListTab.module.scss';

interface IStateProps {
  current_playlist: IApp | null;
  isLoading: boolean;
  currentJobId: number | null;
  numberOfNewAddedTracks: number;
}
interface IDispatchProps {
  fetchDefaultPlaylist: (job_id: number) => void;
  submitUpdatePlaylist: () => void;
  updateAddedNumberOfTracks: (n: number) => void;
}
interface OwnProps {}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}
const TrackListTabInternal = ({
  current_playlist,
  isLoading,
  currentJobId,
  fetchDefaultPlaylist,
  submitUpdatePlaylist,
  updateAddedNumberOfTracks,
}: IProps) => {
  useEffect(() => {
    if (currentJobId) {
      fetchDefaultPlaylist(currentJobId);
    }

    return () => updateAddedNumberOfTracks(0);
    // eslint-disable-next-line
  }, [currentJobId, fetchDefaultPlaylist]);

  const isShowEmptyTitle = useMemo(() => {
    return !current_playlist?.tracks.length && !isLoading;
  }, [current_playlist, isLoading]);

  const handleSubmit = useCallback(() => {
    submitUpdatePlaylist();
    // eslint-disable-next-line
  }, []);
  const current_init_playlist = useSelector(current_init_playlist$);

  const hasUnsavedTracks = useMemo(() => {
    if (!current_init_playlist || !current_playlist) return false;
    const currentTracks = current_playlist.tracks.map(track => track.track_id);
    const initTracks = current_init_playlist.tracks.map(track => track.track_id);
    if ((initTracks.length && !currentTracks.length) || initTracks.length !== currentTracks.length) return true;
    const isSomeOldTracks = initTracks.some(trId => !currentTracks.includes(trId));
    const isSomeNewTracks = currentTracks.some(trId => !initTracks.includes(trId));

    return isSomeOldTracks || isSomeNewTracks;
  }, [current_init_playlist, current_playlist]);

  const UnsavedCX = cx(style.unsaved, {
    [style.show]: hasUnsavedTracks,
  });

  return (
    <div className={style.root}>
      {isShowEmptyTitle && (
        <div className={style.emptyTitle}>
          The list of tracks will be here. Here you can add tracks without creating playlists. Just add tracks and click the <b>Submit</b>{' '}
          button
        </div>
      )}

      <div className={UnsavedCX}>unsaved</div>

      {!isShowEmptyTitle && <TrackList current_playlist={current_playlist} handleSubmit={handleSubmit} />}

      {isShowEmptyTitle && (
        <button className={style.btn} onClick={handleSubmit}>
          Submit
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    current_playlist: current_playlist$(state),
    isLoading: isLoading$(state),
    currentJobId: current_job_id$(state),
    numberOfNewAddedTracks: numberOfNewAddedTracks$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchDefaultPlaylist: dispatchProps.fetchDefaultPlaylist,
    submitUpdatePlaylist: dispatchProps.submitUpdatePlaylist,
    updateAddedNumberOfTracks: dispatchProps.updateAddedNumberOfTracks,
    ...props,
  };
};

export const TrackListTab = connect(
  mapStateToProps,
  {
    fetchDefaultPlaylist: PlaylistCreators.fetchDefaultPlaylist,
    submitUpdatePlaylist: PlaylistCreators.submitUpdatePlaylist,
    updateAddedNumberOfTracks: PlaylistActions.updateAddedNumberOfTracks,
  },
  mergeProps
)(TrackListTabInternal);
