import LinearProgress from '@material-ui/core/LinearProgress';
// eslint-disable-next-line
import React from 'react';

import style from './linearLoader.module.scss';
interface IProps {
  completed: number;
}

export const LinearLoader = ({ completed }: IProps) => {
  return (
    <div className={style.root}>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  );
};
