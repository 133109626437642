import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import { IStatTrack } from 'interfaces/state/StatsState';
// eslint-disable-next-line
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { isLoading$ } from 'selectors/stats';

import styles from './bigStatisticPanel.module.scss';
import { BigStatisticPanelHead } from './bigStatisticPanelHead';

interface IProps {
  tracks: IStatTrack[];
}

const headers = ['Track', 'Downloads', 'Plays', 'Shares'];

export const BigStatisticPanel = ({ tracks }: IProps) => {
  const isLoading = useSelector(isLoading$);
  const containerCX = cx(styles.loadingContainer, { [styles.hidden]: !isLoading });

  return (
    <section className={styles.root}>
      <div className={styles.title}>Your track statistics</div>
      <BigStatisticPanelHead items={headers} />

      <Scrollbars className={styles.scroll}>
        {tracks.map((track, index) => (
          <div className={styles.content} key={index}>
            <div className={styles.name}>{track.title}</div>
            <div className={styles.upload}>{track.upload_count}</div>
            <div className={styles.listen}>{track.plays_count}</div>
            <div className={styles.share}>{track.share_count}</div>
          </div>
        ))}
        <div className={containerCX}>{isLoading && <Loader />}</div>
      </Scrollbars>
    </section>
  );
};
