import { IUser } from 'interfaces/IUser';
import { keyBy } from 'lodash-es';

import * as Const from '../constants/index';
import State from '../interfaces/state/AdminState';

const initState: State = {
  users: [],
  managers: [],
  admins: [],
  creators: [],
  manager: {},
  creator: {},
  admin: {},
  isLoading: false,
  isError: false,
  currentUser: null,
};

export default function admin(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.ADMIN_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.ADMIN_SUCCESS:
      return {
        ...state,
        managers: action.managers.map((item: IUser) => item.email),
        admins: action.admins.map((item: IUser) => item.email),
        creators: action.creators.map((item: IUser) => item.email),
        admin: keyBy(action.admins, 'email'),
        creator: keyBy(action.creators, 'email'),
        manager: keyBy(action.managers, 'email'),
        isLoading: false,
        isError: false,
      };

    case Const.ADMIN_SUCCESS_NEXT:
      return {
        ...state,
        managers: [...state.managers, ...action.managers.map((item: IUser) => item.email)],
        admins: [...state.admins, ...action.admins.map((item: IUser) => item.email)],
        creators: [...state.creators, ...action.creators.map((item: IUser) => item.email)],
        admin: { ...state.admin, ...keyBy(action.admins, 'email') },
        creator: { ...state.creator, ...keyBy(action.creators, 'email') },
        manager: { ...state.manager, ...keyBy(action.managers, 'email') },
        isLoading: false,
        isError: false,
      };

    case Const.ADMIN_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.ADMIN_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case Const.ADMIN_DELETE_USER:
      const { userType, email, typeOf } = action;

      return {
        ...state,
        [userType as 'creators' | 'admins' | 'managers']: state[userType as 'creators' | 'admins' | 'managers'].filter(
          user => user !== email
        ),
        [typeOf]: { ...state[typeOf as 'creator' | 'manager' | 'admin'], [action.email]: null },
        isLoading: false,
        isError: false,
      };

    case Const.ADMIN_CHANGE_USER:
      return {
        ...state,
        [action.userType as 'creator' | 'admin' | 'manager']: { ...state[action.userType], [action.user.email]: { ...action.user } },
        isLoading: false,
        isError: false,
      };
    default:
      return state;
  }
}
