import { Action } from 'redux';

import * as Const from '../constants';

export const setConfirmInit = (): Action => {
  return {
    type: Const.CONFIRM_INIT,
  };
};

export const setConfirmSuccess = (): Action => {
  return {
    type: Const.CONFIRM_SUCCESS,
  };
};

export const setConfirmFail = (): Action => {
  return {
    type: Const.CONFIRM_ERROR,
  };
};

export const setConfirmPending = (): Action => {
  return {
    type: Const.CONFIRM_PENDING,
  };
};

export const setConfirmEmail = (payload: string) => {
  return {
    type: Const.CONFIRM_EMAIL,
    payload,
  };
};

export const setConfirmToken = (payload: string) => {
  return {
    type: Const.CONFIRM_TOKEN,
    payload,
  };
};
