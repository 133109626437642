import { ICompany } from 'interfaces/state/CompaniesState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setCompaniesInit = (): Action => {
  return {
    type: Const.COMPANIES_INIT,
  };
};

export const setCompanies = (payload: ICompany[]) => {
  return {
    type: Const.COMPANIES_LOADED,
    payload,
  };
};

export const setAllCompanies = (payload: ICompany[]) => {
  return {
    type: Const.ALL_COMPANIES_LOADED,
    payload,
  };
};

export const addNewCompany = (payload: ICompany) => {
  return {
    type: Const.COMPANIES_ADD_NEW,
    payload,
  };
};

export const setErrorCompany = (): Action => {
  return {
    type: Const.COMPANIES_ERROR,
  };
};

export const setCompanySuccess = (): Action => {
  return {
    type: Const.COMPANIES_SUCCESS,
  };
};

export const setCompaniesLoading = (): Action => {
  return {
    type: Const.COMPANIES_PENDING,
  };
};

export const setCurrentCompany = (payload: ICompany | null) => {
  return {
    type: Const.COMPANIES_CURRENT,
    payload,
  };
};

export const deleteMember = (company_id: number, member_id: number) => {
  return {
    type: Const.COMPANIES_DELETE_MEMBER,
    company_id,
    member_id,
  };
};
