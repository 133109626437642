import React from 'react';

import style from './wrongEmailOrPassContent.module.scss';

export const WrongEmailOrPassContent = () => {
  return (
    <div className={style.root}>
      <h4 className={style.title}>OOPS!</h4>
      <div className={style.subtitle}>Username or Password is invalid, please try again or reset password.</div>
      <div className={style.body}>
        <span>Sorry about that.</span>
        <span>SmashHaus</span>
      </div>
      <footer className={style.footer}>
        <nav className={style.link}>
          <a href="https://www.smashhaus.com/" target="_blank" rel="noopener noreferrer">
            www.smashhaus.com
          </a>
        </nav>
        <div className={style.surge}>
          ***SmashHaus powers tech solutions for leading media companies and creators in Hollywood and around the globe.
        </div>
      </footer>
    </div>
  );
};
