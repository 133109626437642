import CreateCompanyPopup from 'containers/createCompanyPopup';
import JobPopup from 'containers/createNewJobPopup';
import { CreateTeamPopup } from 'containers/createTeamPopup';
// eslint-disable-next-line
import React from 'react';

import style from './createPopoverContent.module.scss';

interface IProps {
  isSHAdmin: boolean;
  onClosePopover: () => void;
}

export const CreatePopoverContent = ({ isSHAdmin, onClosePopover }: IProps) => {
  return (
    <div className={style.root}>
      <JobPopup isSHAdmin={isSHAdmin} onClosePopover={onClosePopover} />
      <CreateCompanyPopup onClosePopover={onClosePopover} />
      <CreateTeamPopup onClosePopover={onClosePopover} />
    </div>
  );
};
