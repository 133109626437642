import { Action } from 'redux';

import * as Const from '../constants';

export const getRatingReceive = (payload: number) => {
  return {
    type: Const.RATING_RECEIVE,
    payload,
  };
};

export const getRateTrackReceive = (payload: number) => {
  return {
    type: Const.RATING_TRACK_RECEIVE_SUCCESS,
    payload,
  };
};

export const setRateInit = (): Action => {
  return {
    type: Const.RATING_INIT,
  };
};

export const setRateSuccess = (): Action => {
  return {
    type: Const.RATING_SUCCESS,
  };
};

export const setRateFail = (): Action => {
  return {
    type: Const.RATING_ERROR,
  };
};

export const setRatePending = (): Action => {
  return {
    type: Const.RATING_PENDING,
  };
};

export const setRatingFromTo = (from: number, to: number) => {
  return {
    type: Const.RATING_FR_TO,
    from,
    to,
  };
};

export const setRatingFromPulled = (payload: number | null) => {
  return {
    type: Const.RATING_PULLED,
    payload,
  };
};
