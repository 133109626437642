import State from 'interfaces/state/State';

export const currentSong$ = (state: State) => state.song.currentSong;
export const currentTime$ = (state: State) => state.song.currentTime;
export const isPlay$ = (state: State) => state.song.isPlay;
export const currentRating$ = (state: State) => state.song.currentRating;
export const volume$ = (state: State) => state.song.volume;
export const name$ = (state: State) => state.song.name;
export const artist$ = (state: State) => state.song.artist;
export const duration$ = (state: State) => state.song.duration;
export const clickedTime$ = (state: State) => state.song.clickedTime;
export const currIndex$ = (state: State) => state.song.currIndex;
export const autoPlayAfterSrcChange$ = (state: State) => state.song.autoPlayAfterSrcChange;
export const muted$ = (state: State) => state.song.muted;
export const isNeedToScroollToTrackInRightPanel$ = (state: State) => state.song.isNeedToScroollToTrackInRightPanel;
