import './style.scss';

import * as MusicianPlayerActions from 'actions/musicianPlayer';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { connect } from 'react-redux';
import { autoPlayAfterSrcChange$, muted$ } from 'selectors/song';

import style from './musicianPlayer.module.scss';

interface IDispatchProps {
  setMusPlayerPause: (value: boolean) => void;
  setMusPlayerPlay: (value: boolean) => void;
}

interface OwnProps {
  currentUrl: string;
}
interface IStateProps {
  muted: boolean;
  autoPlayAfterSrcChange: boolean;
}

interface IProps extends IDispatchProps, OwnProps, IStateProps {}
const MusicianPlayerComponent = ({ currentUrl, setMusPlayerPause, setMusPlayerPlay, muted, autoPlayAfterSrcChange }: IProps) => {
  const onPause = (_e: Event) => {
    setMusPlayerPause(true);
    setMusPlayerPlay(false);
  };

  const onPlay = (_e: Event) => {
    setMusPlayerPause(false);
    setMusPlayerPlay(true);
  };

  return (
    <div className={style.root}>
      <AudioPlayer
        className={style.player}
        style={{ width: 300 }}
        showJumpControls={false}
        autoPlayAfterSrcChange={autoPlayAfterSrcChange}
        loop={false}
        muted={muted}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        src={currentUrl}
        onPause={onPause}
        onPlay={onPlay}
        preload="auto"
        volume={0.4}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    muted: muted$(state),
    autoPlayAfterSrcChange: autoPlayAfterSrcChange$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setMusPlayerPlay: dispatchProps.setMusPlayerPlay,
    setMusPlayerPause: dispatchProps.setMusPlayerPause,
    ...props,
  };
};

export const MusicianPlayer = connect(
  mapStateToProps,
  {
    setMusPlayerPlay: MusicianPlayerActions.setMusPlayerPlay,
    setMusPlayerPause: MusicianPlayerActions.setMusPlayerPause,
  },
  mergeProps
)(MusicianPlayerComponent);
