import * as Const from '../constants/index';
import State from '../interfaces/state/BillingState';

const initState: State = {
  billing: null,
  isLoading: false,
  isError: false,
};

export default function billing(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.BILLING_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.BILLING_SUCCESS:
      return {
        ...state,
        billing: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.BILLING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.BILLING_PENDING:
      return {
        ...state,
        isLoading: action.payload,
        isError: false,
      };

    case Const.BILLING_DEFAULT:
      if (state?.billing?.payment_methods?.length) {
        const payment_methods = [...state.billing.payment_methods];
        const index = payment_methods?.findIndex(pm => pm?.payment_method_id === action.id);
        if (index >= 0) {
          const pm = [...payment_methods].map(pm => ({ ...pm, default: false }));
          pm[index].default = true;
          return {
            ...state,
            isLoading: false,
            isError: false,
            billing: { ...state.billing, payment_methods: pm },
          };
        } else {
          return {
            ...state,
            isLoading: false,
            isError: false,
          };
        }
      } else {
        return {
          ...state,
          isLoading: false,
          isError: false,
        };
      }

    default:
      return state;
  }
}
