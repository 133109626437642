import { Action } from 'redux';

import * as Const from '../constants';

export const setManagerEventListenerOn = (): Action => {
  return {
    type: Const.SWITCH_ON_MANAGER_EVENT_LISTENER,
  };
};

export const setManagerEventListenerInit = (): Action => {
  return {
    type: Const.SWITCH_MANAGER_EVENT_LISTENER_INIT,
  };
};
