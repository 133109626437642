import 'sweetalert2/src/sweetalert2.scss';

import * as CompanyJobActions from 'actions/companyJobs';
import * as MusicianJobActions from 'actions/musicianJobs';
import * as NewJobAvatarActions from 'actions/newJobAvatar';
import axios from 'axios';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { currentNewId$ } from 'selectors/companyJob';
import { fileId$ } from 'selectors/newJobAvatar';
import { catchedErrorNotification } from 'utils/error';

export const getNewJobAvatar = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewJobAvatarActions.setNewJobAvatarPending());

    axios
      .post(`${Api.GetJobAvatar}`, {
        id: currentNewId$(getState()),
      })
      .then(response => {
        dispatch(NewJobAvatarActions.setNewJobAvatar(response.data.result.url));
        dispatch(CompanyJobActions.updateJobAvatarById(response.data.result.url, currentNewId$(getState())!));
      })
      .catch(error => {
        dispatch(NewJobAvatarActions.setNewJobAvatarError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getJobAvatarById = (id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(NewJobAvatarActions.setNewJobAvatarPending());

    axios
      .post(`${Api.GetJobAvatar}`, {
        id,
      })
      .then(response => {
        dispatch(MusicianJobActions.setMusicianJobAvatarUrl(response.data.result.url, id));
        dispatch(CompanyJobActions.setCompanyJobAvatar(id, response.data.result.url));
        dispatch(MusicianJobActions.setMusicianAllJobAvatarUrl(response.data.result.url, id));
      })
      .catch(error => {
        dispatch(NewJobAvatarActions.setNewJobAvatarError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const setNewJobAvatarFileId = (file: File, isEdit?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(NewJobAvatarActions.setNewJobAvatarPending());

    let formData = new FormData();
    formData.append('inputfile', file);

    axios
      .post(`${Api.UploadJobAvatar}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress: p => {
        //   dispatch(AvatarActions.setProgress((p.loaded / p.total) * 100));
        // },
      })
      .then(response => {
        dispatch(NewJobAvatarActions.setFileId(response.data.result.file_id));
        dispatch(setNewJobAvatarId(isEdit) as any);
      })
      .catch(error => {
        dispatch(NewJobAvatarActions.setNewJobAvatarError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const setNewJobAvatarId = (isEdit?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewJobAvatarActions.setNewJobAvatarPending());

    axios
      .post(`${Api.SetAvatarId}`, {
        job_id: currentNewId$(getState()),
        id: fileId$(getState()),
      })
      .then(() => {
        dispatch(NewJobAvatarActions.setNewJobAvatarInit());
        dispatch(getJobAvatarId(isEdit) as any);
        dispatch(NewJobAvatarActions.setFileIdLoaded());
      })
      .catch(error => {
        dispatch(NewJobAvatarActions.setNewJobAvatarError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getJobAvatarId = (isEdit?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewJobAvatarActions.setNewJobAvatarPending());

    axios
      .post(`${Api.GetJobAvatar}`, {
        id: currentNewId$(getState()),
      })
      .then(response => {
        if (isEdit) {
          dispatch(MusicianJobActions.setMusicianJobAvatarUrl(response.data.result.url, currentNewId$(getState())!));
          dispatch(CompanyJobActions.setCompanyJobAvatar(currentNewId$(getState())!, response.data.result.url));
          dispatch(MusicianJobActions.setMusicianAllJobAvatarUrl(response.data.result.url, currentNewId$(getState())!));
        } else {
          dispatch(NewJobAvatarActions.setNewJobAvatar(response.data.result.url));
          dispatch(CompanyJobActions.updateJobAvatarById(response.data.result.url, currentNewId$(getState())!));
          dispatch(MusicianJobActions.setMusicianAllJobAvatarUrl(response.data.result.url, currentNewId$(getState())!));
        }
      })
      .catch(error => {
        dispatch(NewJobAvatarActions.setNewJobAvatarError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
