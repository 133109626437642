import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import Popup from 'components/popup/popup';
import IApp from 'interfaces/IApp';
import { IMusicianJob } from 'interfaces/IMusicianJob';
// eslint-disable-next-line
import React, { useCallback } from 'react';

import { CreatePlaylistPopover } from '../createPlaylistPopover';
import style from './playlistsTab.module.scss';
import { TrackList } from './trackList';

interface IProps {
  selectedPlaylist: ISelectItem;
  selectList: ISelectItem[];
  playlists: IApp[];
  current_playlist: IApp | null;
  EmptyBtnCX: string;
  job: IMusicianJob;
  onChange: (playlist: ISelectItem) => void;
  onDelete: () => void;
  onCreate: (name: string) => void;
  submitUpdatePlaylist: (isNeedToUpgradePlaylist?: boolean) => void;
}

export const PlaylistsTab = ({
  selectedPlaylist,
  selectList,
  playlists,
  current_playlist,
  onDelete,
  onChange,
  onCreate,
  submitUpdatePlaylist,
}: IProps) => {
  const handleSubmit = useCallback(() => {
    if (!selectList?.length) {
      errorNotification('First you need to create a playlist');
    } else if (selectList.length && !current_playlist?.tracks?.length) {
      errorNotification('You need to add tracks to the playlist');
    } else {
      submitUpdatePlaylist(true);
    }
    // eslint-disable-next-line
  }, [selectList, current_playlist]);

  return (
    <div className={style.root}>
      <div className={style.header}>
        <CreatePlaylistPopover onCreate={onCreate} />

        <MaterialSelect
          value={selectedPlaylist}
          placeholder="Select"
          selectItems={selectList as ISelectItem[]}
          className={style.select}
          isAllValueNeed
          onChangeAllValue={onChange}
          disabled={!playlists.length}
        />
        <Popup
          disabled={!current_playlist}
          onApply={onDelete}
          btnClassName={style.btn}
          buttonText={
            <Tooltip title="Delete a playlist">
              <DeleteForeverRoundedIcon className={style.icon} style={{ height: 21 }} />
            </Tooltip>
          }
          modalContent={
            <div style={{ height: 100 }} className="d-flex align-center flex-column">
              <div className="playlist-title-text">Delete this playlist</div>
              <div>Are you sure that you want to delete current playlist?</div>
            </div>
          }
        />
      </div>
      <div className={style.tracks}>
        <div>
          {current_playlist && current_playlist?.tracks.length === 0 && (
            <div className={style.addFirstTrack}>Add first track to playlist</div>
          )}
        </div>
        <TrackList current_playlist={current_playlist} handleSubmit={handleSubmit} />
        {current_playlist && current_playlist?.tracks.length === 0 ? (
          <button className={style.submitBtn} onClick={handleSubmit}>
            Submit
          </button>
        ) : null}
      </div>
    </div>
  );
};
