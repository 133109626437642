import * as AvatarActions from 'actions/avatar';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { userName$ } from 'selectors/auth';
import { catchedErrorNotification } from 'utils/error';

export const sendAvatar = (file: File) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AvatarActions.setAvatarLoading());

    let formData = new FormData();
    formData.append('inputfile', file);
    axios
      .post(`${Api.SetAvatar}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress: p => {
        //   dispatch(AvatarActions.setProgress((p.loaded / p.total) * 100));
        // },
      })
      .then(() => {
        dispatch(AvatarActions.setSuccessLoadAvatar());
        dispatch(getAvatar() as any);
        successNotification('Your avatar has been successfully uploaded');
      })
      .catch(error => {
        console.error(error);
        dispatch(AvatarActions.setAvatarFail());
        catchedErrorNotification(error);
      });
  };
};

export const getAvatar = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(AvatarActions.setAvatarLoading());

    axios
      .post(`${Api.GetAvatar}`, {
        user_name: userName$(getState()),
      })
      .then(response => {
        dispatch(AvatarActions.getSuccessAvatar(response.data.result.url));
      })
      .catch(error => {
        dispatch(AvatarActions.setAvatarFail());

        catchedErrorNotification(error);
      });
  };
};
