import State from 'interfaces/state/State';
import { createSelector } from 'reselect';

export const managers = (state: State) => state.admin.managers;
export const manager = (state: State) => state.admin.manager;
export const managers$ = createSelector([managers], list => list);
export const manager$ = createSelector([manager], list => list);

export const admins = (state: State) => state.admin.admins;
export const admin = (state: State) => state.admin.admin;
export const admins$ = createSelector([admins], list => list);
export const admin$ = createSelector([admin], list => list);

export const creators = (state: State) => state.admin.creators;
export const creator = (state: State) => state.admin.creator;
export const creators$ = createSelector(creators, list => list);
export const creator$ = createSelector([creator], list => list);

export const isLoading$ = (state: State) => state.admin.isLoading;
export const isError$ = (state: State) => state.admin.isError;
export const currentUser$ = (state: State) => state.admin.currentUser;

export const currentCreatorById$ = createSelector(creator$, user => (id: string) => (user ? user[id] : null));

export const currentAdminById$ = createSelector(admin$, user => (id: string) => (user ? user[id] : null));

export const currentManagerById$ = createSelector(manager$, user => (id: string) => (user ? user[id] : null));
