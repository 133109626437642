import * as Const from '../constants';
import State from '../interfaces/state/ReceivePassState';

const initState: State = {
  email: '',
  token: '',
  isLoading: false,
  isError: false,
  isSuccessSendPass: false,
};

export default function receivePass(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.RECEIVE_PASS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.RECEIVE_PASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.RECEIVE_PASS_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.RECEIVE_PASS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.RECEIVE_PASS_TOKEN:
      return {
        ...state,
        token: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.RECEIVE_PASS_EMAIL:
      return {
        ...state,
        email: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SEND_RECEIVE_PASS_SUCCESS:
      return {
        ...state,
        isSuccessSendPass: action.payload,
      };

    default:
      return state;
  }
}
