import { IMusicianJob } from 'interfaces/IMusicianJob';

import * as Const from '../constants/index';
import State from '../interfaces/state/MusicianJobsState';

const initState: State = {
  jobs: [],
  allJobs: [],
  teamJobs: [],
  isError: false,
  isLoading: false,
  sortedBy: 'date_time',
  sortType: 'desc',
  current_job: null,
  currentJobIndex: null,
  job: null,
  search: '',
  selectedTypes: ['All'],
  status: 'open',
  isTeamJob: false,
  jobsCounter: 0,
  jobsTeamCounter: 0,
};

export default function musicianJobs(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.MUSICIAN_JOBS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.MUSICIAN_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        isError: false,
        isLoading: false,
      };

    case Const.ALL_MUSICIAN_JOBS_SUCCESS:
      return {
        ...state,
        allJobs: action.payload.all_jobs,
        teamJobs: action.payload.teams_jobs?.length ? action.payload.teams_jobs : [],
        isError: false,
        isLoading: false,
      };

    case Const.MUSICIAN_JOBS_PAGINATION:
      return {
        ...state,
        allJobs: [...state.allJobs, ...action.payload.all_jobs],
        teamJobs: action.payload?.teams_jobs ? [...state.teamJobs, ...action.payload.teams_jobs] : [],
        isError: false,
        isLoading: false,
      };

    case Const.MUSICIAN_ALL_JOBS_SUCCESS:
      return {
        ...state,
        allJobs: action.payload.all_jobs,
        teamJobs: action.payload.teams_jobs,
        isError: false,
        isLoading: false,
      };

    case Const.MUSICIAN_JOBS_PENDING:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };

    case Const.MUSICIAN_JOBS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case Const.MUSICIAN_JOBS_SORTING:
      return {
        ...state,
        sortedBy: action.payload,
      };

    case Const.MUSICIAN_JOBS_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload,
      };

    case Const.CURR_JOB_ID:
      return {
        ...state,
        current_job: action.payload,
      };

    case Const.MUSICIAN_JOBS_LOADED:
      return {
        ...state,
        isError: false,
        isLoading: false,
      };

    case Const.MUSICIAN_CURR_JOB_INDEX:
      return {
        ...state,
        currentJobIndex: action.payload,
      };

    case Const.MUSICIAN_ALL_JOBS_SET_AVATAR_BY_ID: {
      const allJ = [...state.allJobs];

      const index = allJ.findIndex(job => job.id === action.id);
      if (index < 0) return { ...state };
      allJ[index].avatar_url = action.payload;
      return {
        ...state,
        allJobs: allJ,
      };
    }

    case Const.MUSICIAN_JOBS_SET_AVATAR_BY_ID: {
      const all = [...state.jobs];

      const index = all.findIndex(job => job.id === action.id);
      if (index < 0) return state;
      all[index] = { ...all[index], avatar_url: action.payload };
      return {
        ...state,
        jobs: all,
      };
    }

    case Const.MUSICIAN_CHANGE_STATUS:
      const allJobsCopy = [...state.allJobs];

      const allJCopyIndex = allJobsCopy.findIndex(item => item.id === action.id);
      if (allJCopyIndex < 0) return state;
      allJobsCopy[allJCopyIndex].status = action.payload;
      return {
        ...state,
        allJobs: allJobsCopy,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_UPDATE_JOB:
      const newJobs = [...state.allJobs];

      const ind = newJobs.findIndex(job => job.id === action.payload.id);
      if (ind < 0) return state;

      newJobs[ind] = { ...newJobs[ind], ...action.payload };

      return {
        ...state,
        allJobs: newJobs,
        isLoading: false,
        isError: false,
      };

    case Const.COMPANY_UPDATE_JOB_COMPANY_NAME:
      const allJobs = [...state.allJobs];
      const index = allJobs.findIndex(job => job.id === action.id);
      if (index < 0) return state;
      const { company } = action;
      allJobs[index] = { ...allJobs[index], company };

      return {
        ...state,
        allJobs: allJobs,
        isLoading: false,
        isError: false,
      };

    case Const.MUSICIAN_JOB_CHANGE_SHARE_TYPE:
      const jobList = [...state.jobs];
      const currentJob = jobList.findIndex(job => job.id === action.id);
      if (currentJob < 0) return state;
      const { share_type } = action;
      jobList[currentJob] = { ...jobList[currentJob], share_type };

      return {
        ...state,
        jobs: jobList,
        isLoading: false,
        isError: false,
      };

    case Const.MUSICIAN_ADD_NEW_JOB_TO_ALL_JOBS:
      return {
        ...state,
        allJobs: [action.payload, ...state.allJobs],
      };

    case Const.MUSICIAN_CHANGE_ALLOW_TRACKS_NUM:
      const list = [...state.allJobs];
      const teamJobs = [...state.teamJobs];

      const i = list.findIndex(job => job.id === action.id);

      const newJob = { ...state.job };
      newJob['tracks_allowed_amount'] = action.payload;
      if (i < 0) {
        const j = teamJobs.findIndex(job => job.id === action.id);

        if (j < 0) {
          return { ...state };
        } else {
          teamJobs[j].tracks_allowed_amount = action.payload;
          return {
            ...state,
            teamJobs,
            job: newJob as IMusicianJob,
          };
        }
      } else {
        list[i].tracks_allowed_amount = action.payload;
        return {
          ...state,
          allJobs: list,
          job: newJob as IMusicianJob,
        };
      }

    case Const.CHANGE_ONE_JOB_INFO:
      const { id } = action.payload;

      const jobs = [...state.allJobs];
      const searchedIndex = jobs.findIndex(job => job.id === id);

      if (searchedIndex < 0) return { ...state };
      jobs[searchedIndex] = action.payload;
      return {
        ...state,
        allJobs: jobs,
      };

    case Const.SET_CURRENT_MUSICIAN_JOB:
      return {
        ...state,
        job: action.payload,
      };

    case Const.JOB_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case Const.JOB_TYPES:
      return {
        ...state,
        selectedTypes: action.payload,
      };

    case Const.JOB_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case Const.JOB_TEAM_TYPE:
      return {
        ...state,
        isTeamJob: action.payload,
      };

    case Const.JOB_COUNTER:
      return {
        ...state,
        jobsCounter: action.payload,
      };
    case Const.JOB_TEAM_COUNTER:
      return {
        ...state,
        jobsTeamCounter: action.payload,
      };

    default:
      return state;
  }
}
