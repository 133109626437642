import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import classNames from 'classnames/bind';
import { IconRoundButton } from 'components/iconRoundButton';
import { InfopanelPopoverContent } from 'components/infoPanel/infopanelPopoverContent';
import { Loader } from 'components/loader/loader';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import JobPopup from 'containers/createNewJobPopup';
import { ICompanyJob } from 'interfaces/ICompanyJob';
import { ICompany } from 'interfaces/state/CompaniesState';
import moment from 'moment';
// eslint-disable-next-line
import React, { MouseEvent, useEffect, useState } from 'react';
import { DATE_TIME_FORMAT } from 'utils';

import { ChangeCompanyPopup } from '../changeCompanyPopup';
import style from './style.module.scss';

interface IDispatchProps {}

interface IStateProps {}
interface OwnProps {
  item: ICompanyJob;
  currentNewId: number | null;
  isLoading: boolean;
  isManager?: boolean;
  companies: ICompany[];
  getAppliedTrack: (id: number, from: number, to: number) => void;
  setNewTracksInit: () => void;
  updateCompanyStatus: (isManager: boolean, status: string, id: number) => void;
  setTempCompanyJobInit: () => void;
  setTempCompanyJobItem: (item: 'title' | 'description' | 'budget', value: string | number) => void;
  setNewJobAvatarFile: (file: File, isEdit?: boolean) => void;
  setCompanyJobNewId: (id: number) => void;
  handleChangeJobOwner: (id: number, company_id: number, company: string) => void;
}
interface IProps extends IDispatchProps, IStateProps, OwnProps {}

export const JobItem = ({
  item,
  currentNewId,
  isLoading,
  isManager,
  companies,
  updateCompanyStatus,
  setNewJobAvatarFile,
  setCompanyJobNewId,
  handleChangeJobOwner,
}: IProps) => {
  const [selectedCompanyId, setSelectCompanyId] = useState(0);
  const [selectedCompany, setSelectCompany] = useState('');

  const handleSetDeleteStatus = async () => {
    await updateCompanyStatus(isManager!, 'deleted', item.id);
  };

  const handleSetArchivedStatus = async () => {
    await updateCompanyStatus(isManager!, 'archived', item.id);
  };

  const handleSetClosedStatus = async () => {
    await updateCompanyStatus(isManager!, 'closed', item.id);
  };

  const handleSetOpenStatus = async () => {
    await updateCompanyStatus(isManager!, 'open', item.id);
  };

  const [avatar, setAvatar] = useState(null as string | null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSendAvatareImage = async (file: File | File[]) => {
    await setCompanyJobNewId(item.id);
    await setNewJobAvatarFile(file![0], true);
    handleClose();
  };

  const isBudgetInNumberOrString = (budget: string | number) => {
    if (!parseInt(budget as string)) {
      return budget;
    } else {
      return '$ ' + budget;
    }
  };

  const rootClassNames = classNames({
    [style.root]: !isManager,
    [style.managerRoot]: isManager,
    [style.open]: item.status === 'open',
    [style.closed]: item.status === 'closed',
    [style.deleted]: item.status === 'deleted',
    [style.archived]: item.status === 'archived',
  });

  useEffect(() => {
    setAvatar(item.avatar_url);
  }, [item.avatar_url, setAvatar]);

  return (
    <div className={rootClassNames}>
      <div className={style.avatar}>
        <IconRoundButton
          disableRipple
          className={style.btnAvatar}
          onClick={handleClick}
          tooltipText="Upload new avatar for job"
          icon={
            <div className={style.iconWrapper}>
              {item.id === currentNewId && isLoading ? (
                <div className={style.loader}>
                  <Loader />
                </div>
              ) : null}
              {avatar ? <img src={avatar} alt="job" className={style.image} /> : <CropOriginalRoundedIcon className={style.defaultIcon} />}
            </div>
          }
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          verticalAnchor="bottom"
          horizontalAnchor="center"
          verticalTransform="top"
          horizontalTransform="center"
          content={<InfopanelPopoverContent func={handleSendAvatareImage} />}
        />

        <div className={style.titleWrap}>
          <div className={style.title} title={item.title}>
            {item.title}
          </div>
          <div className={style.date}>
            <div className={style.dateTitle} title={moment(item.date_time).format(DATE_TIME_FORMAT)}>
              {moment(item.date_time).format(DATE_TIME_FORMAT)}
            </div>
          </div>
          {isManager && (
            <div className={style.name}>
              {'Name: '}
              <span className={style.title} title={item.company}>
                {item.company}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={style.description} title={item.description}>
        {item.description}
      </div>
      <div className={style.budget} title={`${isBudgetInNumberOrString(item.budget)}`}>
        {isBudgetInNumberOrString(item.budget)}
      </div>
      <div className={style.status} title={item.status}>
        {item.status}
      </div>
      <div className={style.buyerType} title={item.buyer_type}>
        {item.buyer_type ? item.buyer_type.replace('_', ' ') : '--'}
      </div>
      <div className={style.btns}>
        <JobPopup job={item} isSHAdmin={!!isManager} onClosePopover={() => ''} isEdit />
        {isManager && (
          <Popup
            onApply={() => handleChangeJobOwner(item.id, selectedCompanyId, selectedCompany)}
            btnClassName={style.btnPopup}
            isVisibleOverflow
            buttonText={<IconRoundButton tooltipText="Set job to another company" icon={<AssignmentRoundedIcon />} size="small" />}
            modalContent={
              <ChangeCompanyPopup
                companies={companies}
                selectedCompanyId={selectedCompanyId}
                selectedCompany={selectedCompany}
                setSelectCompanyId={setSelectCompanyId}
                setSelectCompany={setSelectCompany}
              />
            }
          />
        )}
        {item.status !== 'open' && (
          <IconRoundButton
            size="small"
            icon={<LockOpenRoundedIcon />}
            disabled={item.status === 'open'}
            tooltipText="Change status to 'Open'"
            onClick={handleSetOpenStatus}
          />
        )}
        {item.status !== 'deleted' && (
          <IconRoundButton
            size="small"
            icon={<DeleteRoundedIcon />}
            disabled={item.status === 'deleted'}
            tooltipText="Change status to 'Deleted'"
            onClick={handleSetDeleteStatus}
          />
        )}
        {item.status !== 'archived' && (
          <IconRoundButton
            size="small"
            icon={<StorageRoundedIcon />}
            disabled={item.status === 'archived'}
            tooltipText="Change status to 'Archived'"
            onClick={handleSetArchivedStatus}
          />
        )}
        {item.status !== 'closed' && (
          <IconRoundButton
            size="small"
            icon={<HighlightOffRoundedIcon />}
            disabled={item.status === 'closed'}
            tooltipText="Change status to 'Closed'"
            onClick={handleSetClosedStatus}
          />
        )}
      </div>
    </div>
  );
};
