import { Action } from 'redux';

import * as Const from '../constants';

export const getUsersType = (payload: 'creator' | 'manager' | 'admin' | null) => {
  return {
    type: Const.USERS_TYPE_RECEIVE,
    payload,
  };
};

export const setUsersInit = (): Action => {
  return {
    type: Const.USERS_INIT,
  };
};

export const setUsersSuccess = (payload: string) => {
  return {
    type: Const.USERS_RECEIVE,
    payload,
  };
};

export const setUsersReceiveFail = (): Action => {
  return {
    type: Const.USERS_RECEIVE_FAIL,
  };
};

export const setUsersReceivePending = (): Action => {
  return {
    type: Const.USERS_RECEIVE_PENDING,
  };
};

export const setCurrUser = (payload: number) => {
  return {
    type: Const.USERS_CURR_USER,
    payload,
  };
};
