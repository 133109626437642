import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { setCurrentOrderBy } from 'actions/newTracks';
import cx from 'classnames';
import { fetchTracksBySorting } from 'creators/newTracks';
// eslint-disable-next-line
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';
import { current_job_id$ } from 'selectors/musicianJobs';
import { isLoading$, orderBy$ } from 'selectors/newTrack';

import style from './listenedSort.module.scss';

export const ListenedSort = () => {
  const dispatch = useDispatch();
  const orderBy = useSelector(orderBy$);
  const [sortedBefore, setSortedBefore] = useState('');
  const job_id = useSelector(current_job_id$);
  const userType = useSelector(userType$);
  const isLoading = useSelector(isLoading$);

  useEffect(() => {
    if (orderBy !== 'listened') {
      setSortedBefore(orderBy);
    }
  }, [orderBy]);

  const toggleSortBy = () => {
    if (!job_id || isLoading) return;
    if (orderBy !== 'listened') {
      dispatch(setCurrentOrderBy('listened'));
    } else {
      dispatch(setCurrentOrderBy(sortedBefore));
    }
    dispatch(fetchTracksBySorting(userType === 'manager' || userType === 'company'));
  };

  const title = useMemo(() => {
    return orderBy === 'listened' ? `Sort tracks by ${sortedBefore}` : `First tracks that have not yet been listened`;
  }, [orderBy, sortedBefore]);

  const ContentCX = cx(style.content, {
    [style.disabled]: !job_id || isLoading,
  });

  return (
    <div className={style.root}>
      <Tooltip title={title}>
        <div className={ContentCX} onClick={toggleSortBy}>
          {orderBy === 'listened' ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </div>
      </Tooltip>
    </div>
  );
};
