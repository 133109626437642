import { getCurrentTab, setTabInit } from 'actions/tab';
import logo from 'assets/img/Haus_Icon_cleanV3.png';
// eslint-disable-next-line
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyAvatar$ } from 'selectors/auth';

import styles from './logo.module.scss';

const Logo = () => {
  const companyAvatar = useSelector(companyAvatar$);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(getCurrentTab(0));
  };

  // eslint-disable-next-line
  const logoComponent = useMemo(() => {
    return (
      <div onClick={onClick} className={styles.logoContainer}>
        {companyAvatar ? (
          <img className={styles.root} src={companyAvatar} alt="logo" loading="lazy" />
        ) : (
          <img className={styles.root} src={logo} alt="logo" loading="lazy" />
        )}
      </div>
    );
    // eslint-disable-next-line
  }, [setTabInit, companyAvatar]);
  return logoComponent;
};

export default memo(Logo);
