// eslint-disable-next-line
import React from 'react';

import Controlls from '../controlls';
import TrackList from '../trackList';
import style from './playlist.module.scss';

interface IProps {
  isMusicianPlaylist?: boolean;
}
export const Playlist = ({ isMusicianPlaylist }: IProps) => {
  return (
    <div className={style.root}>
      <Controlls isMusicianPlaylist={isMusicianPlaylist} />
      <TrackList />
    </div>
  );
};
