import IApp from 'interfaces/IApp';
import keyBy from 'lodash-es/keyBy';

import * as Const from '../constants/index';
import State from '../interfaces/state/AppState';

const initState: State = {
  app: [],
  job_id: null,
  playlist_id: null,
  isLoading: false,
  isError: false,
  current_playlist: null,
  initial_current_playlist: null,
  shareableLinks: [],
  numberOfNewAddedTracks: 0,
  defaultPlaylistTracks: [],
  appIds: [],
  appById: {},
  hasUnsavedPlaylist: false,
};

export default function application(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.APP_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.APP_SUCCESS:
      return {
        ...state,
        app: action.payload,
        job_id: action.id,
        isLoading: false,
        isError: false,
      };

    case Const.APP_ADD_ONE_SUCCESS:
      return {
        ...state,
        app: [...state.app, action.payload],
        job_id: action.id,
        isLoading: false,
        isError: false,
      };

    case Const.APP_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.APP_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SET_PLAYLIST_ID_APP:
      return {
        ...state,
        playlist_id: action.payload,
      };

    case Const.DELETE_PLAYLIST_APP:
      return {
        ...state,
        app: state.app.filter(playlist => playlist.id !== action.id),
        isLoading: false,
        isError: false,
      };

    case Const.SET_CURRENT_PLAYLIST_APP:
      return {
        ...state,
        current_playlist: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SET_CURRENT_INIT_PLAYLIST_APP:
      return {
        ...state,
        initial_current_playlist: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.UPDATE_PLAYLIST_APP:
      return {
        ...state,
        current_playlist: { ...state.current_playlist, ...action.payload },
        app: action.app,
        isLoading: false,
        isError: false,
      };

    case Const.SHAREABLE_LINKS_APP:
      return {
        ...state,
        shareableLinks: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SHAREABLE_LINKS_ADD_ONE:
      return {
        ...state,
        shareableLinks: [...state.shareableLinks, action.payload],
        isLoading: false,
        isError: false,
      };

    case Const.UPD_ADDED_TRACKS_NUMBER:
      return {
        ...state,
        numberOfNewAddedTracks: action.payload,
      };

    case Const.SET_DEFAULT_PLAYLIST_TRACKS:
      return {
        ...state,
        defaultPlaylistTracks: action.payload,
      };

    case Const.SET_APP_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SET_APP_FOR_CREATOR_PLAYLIST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        appIds: action.payload.map((app: IApp) => app.id),
        appById: keyBy(action.payload, 'id'),
      };

    case Const.DEL_APP_FROM_CREATOR_PLAYLIST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        appIds: state.appIds.filter(id => id !== action.payload),
      };

    case Const.ADD_APP_TO_CREATOR_PLAYLIST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        appIds: [...new Set([...state.appIds, action.id])],
        appById: {
          ...state.appById,
          [action.id]: action.payload,
        },
      };

    case Const.DEL_TRACK_FROM_PLAYLIST_CREATOR:
      return {
        ...state,
        isLoading: false,
        isError: false,
        appById: {
          ...state.appById,
          [action.id]: {
            ...state.appById[action.id],
            // eslint-disable-next-line
            tracks: state.appById[action.id].tracks.filter(tr => tr.track_id != action.track_id),
          },
        },
      };

    case Const.ADD_TRACK_TO_PLAYLIST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        appById: {
          ...state.appById,
          [action.id]: {
            ...state.appById[action.id],
            tracks: state.appById[action.id]?.tracks ? [action.payload, ...state.appById[action.id].tracks] : [action.payload],
          },
        },
      };

    case Const.APPLICATION_SAVE_CHANGES:
      return {
        ...state,
        isLoading: false,
        appById: {
          ...state.appById,
          [action.id]: {
            ...state.appById[action.id],
            tracks: state.appById[action.id]?.tracks ? state.appById[action.id]?.tracks?.map(tr => ({ ...tr, isNew: false })) : [],
          },
        },
      };

    case Const.APP_HAS_UNSAVED:
      return {
        ...state,
        hasUnsavedPlaylist: action.payload,
      };

    default:
      return state;
  }
}
