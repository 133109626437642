import { CheckboxMaterial } from 'components/checkboxMaterial/checkboxMaterial';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
// eslint-disable-next-line
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chart, ReactGoogleChartProps } from 'react-google-charts';

import style from './chart.module.scss';

const options = {
  title: 'Company Performance',
  legend: { position: 'right' },
  vAxis: { minValue: 1 },
  hAxis: { minValue: 1 },
  animation: {
    duration: 300,
    easing: 'in',
    startup: true,
  },
  colors: ['#3f51b5', 'red'],
  backgroundColor: '#fff',
};

const items = [
  { label: 'Line Chart', value: 'LineChart' },
  { label: 'Column Chart', value: 'ColumnChart' },
];

interface IProps {
  data: ReactGoogleChartProps['data'][];
  title: string;
  isShowData: boolean;
  vAxisTitle: string;
  hAxisTitle: string;
  ticks?: number[];
  year?: string;
  month?: string;
}
export const ChartItem = ({ title, data, isShowData, vAxisTitle, hAxisTitle, ticks, year, month }: IProps) => {
  const [chartData, setChartData] = useState([] as ReactGoogleChartProps['data'][]);
  const [curveType, setCurveType] = useState<'function' | ''>('');
  const [chartType, setChartType] = useState<ISelectItem>({ label: 'LineChart', value: 'LineChart' });

  useEffect(() => {
    if (data?.length) {
      setChartData(data);
    } else {
      setChartData([]);
    }

    return () => setChartData([]);
  }, [data]);

  const optionsMemo = useMemo(
    () => ({
      ...options,
      title,
      curveType,
      explorer: {
        actions: ['dragToZoom', 'rightClickToReset'],
        maxZoomIn: 0.05,
      },
      hAxis: {
        ...options.hAxis,
        title: hAxisTitle,
        textStyle: { color: '#0d0d0d', fontSize: 11 },
        viewWindow: { min: 1 },
        baseLine: 1,
        showEveryText: 10,
        format: `${month}-`,
      },
      vAxis: {
        ...options.vAxis,
        title: vAxisTitle,
        textStyle: { color: '#0d0d0d', fontSize: 11 },
        viewWindow: { min: 0 },
        ticks,
        baseLine: 1,
      },
      crosshair: { trigger: 'both' },
    }),
    [title, hAxisTitle, vAxisTitle, ticks, month, curveType]
  );

  const handleCheck = useCallback(() => {
    setCurveType(prev => (!!prev ? '' : 'function'));
  }, []);

  const handleChangeChartType = useCallback((value: ISelectItem) => {
    setChartType(value);
  }, []);

  return (
    <div className={style.root}>
      <div className="d-flex align-center">
        <MaterialSelect
          value={chartType}
          placeholder="Job type"
          selectItems={items}
          onChangeAllValue={handleChangeChartType}
          isAllValueNeed
          className={''}
          width={150}
        />
        {chartType.value === 'LineChart' && (
          <CheckboxMaterial
            value={curveType}
            checked={!!curveType}
            onChange={handleCheck}
            name="curveType"
            label={'Curved'}
            color={'primary'}
            className={style.checkbox}
          />
        )}
      </div>
      {isShowData ? (
        <Chart
          chartType={
            chartType.value as
              | 'LineChart'
              | 'AnnotationChart'
              | 'AreaChart'
              | 'BarChart'
              | 'BubbleChart'
              | 'Calendar'
              | 'CandlestickChart'
              | 'ColumnChart'
              | 'ComboChart'
              | 'DiffChart'
              | 'DonutChart'
              | 'Gantt'
          }
          data={chartData}
          width="100%"
          height="450px"
          options={optionsMemo}
          loader={<div>Loading Chart</div>}
          legendToggle
          formatters={[
            {
              type: 'NumberFormat',
              column: 0,
              options: {
                prefix: month + ' ',
                suffix: ' ' + year,
                negativeColor: 'red',
                negativeParens: true,
                fractionDigits: 0,
              },
            },
          ]}
        />
      ) : (
        'Empty data'
      )}
    </div>
  );
};
