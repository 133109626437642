import { ReactComponent as SearchIcon } from 'assets/img/search.svg';
import * as AdminCreators from 'creators/admin';
// eslint-disable-next-line
import React, { ChangeEvent, memo, useEffect } from 'react';
import { connect } from 'react-redux';

import style from './userSearch.module.scss';

interface IStateProps {}
interface IDispatchProps {
  searchUsers: (user_info: string, user_type: 'creator' | 'admin' | 'manager') => void;
  fetchUsersForAdmin: (limit: number, offset: number, user_type: 'creator' | 'admin' | 'manager', sortby?: 'date' | 'email') => void;
}
interface OwnProps {
  userType: 'creator' | 'admin' | 'manager';
  value: string;
  onChange: (s: string) => void;
  sortby?: 'date' | 'email';
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const UserSearchInternal = ({ searchUsers, userType, fetchUsersForAdmin, value, onChange, sortby }: IProps) => {
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!value) {
        fetchUsersForAdmin(20, 0, userType, sortby);
      } else {
        searchUsers(value, userType);
      }
    }, 600);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [value]);

  return (
    <div className={style.root}>
      <input type="text" value={value} onChange={onChangeValue} placeholder={'Search users...'} className={style.input} />
      <SearchIcon className={style.icon} />
    </div>
  );
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    searchUsers: dispatchProps.searchUsers,
    fetchUsersForAdmin: dispatchProps.fetchUsersForAdmin,
    ...props,
  };
};

export const UserSearch = connect(
  null,
  {
    searchUsers: AdminCreators.searchUsers,
    fetchUsersForAdmin: AdminCreators.fetchUsersForAdmin,
  },
  mergeProps
)(memo(UserSearchInternal));
