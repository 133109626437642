// eslint-disable-next-line
import React from 'react';

import style from './header.module.scss';

export const Header = () => {
  return (
    <header className={style.root}>
      <h3 className={style.title}>Billing information</h3>
    </header>
  );
};
