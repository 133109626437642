import { Action } from 'redux';

import * as Const from '../constants';

export const setNewJobAvatar = (payload: string) => {
  return {
    type: Const.NEW_JOB_AVATAR_SUCCESS,
    payload,
  };
};

export const setNewJobAvatarInit = (): Action => {
  return {
    type: Const.NEW_JOB_AVATAR_INIT,
  };
};

export const setNewJobAvatarPending = (): Action => {
  return {
    type: Const.NEW_JOB_AVATAR_PENDING,
  };
};

export const setNewJobAvatarError = (): Action => {
  return {
    type: Const.NEW_JOB_AVATAR_ERROR,
  };
};

export const setFileId = (payload: string) => {
  return {
    type: Const.NEW_JOB_AVATAR_FILE_ID,
    payload,
  };
};

export const setFileIdLoaded = (): Action => {
  return {
    type: Const.NEW_JOB_AVATAR_SUCCESS_LOADED,
  };
};
