// import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import logoutIcon from 'assets/img/logout.svg';
import * as AuthCreators from 'creators/auth';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isAuth$ } from 'selectors/auth';

import style from './style.module.scss';

interface IStateProps {
  isAuth: boolean;
}
interface IDispatchProps {
  onLogout: () => void;
}

interface OwnProps {}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const ExitButton = ({ onLogout, isAuth }: IProps) => {
  // eslint-disable-next-line
  const onLogOutCallBack = useCallback(() => {
    localStorage.removeItem('lastPopupTime');
    onLogout();
  }, [isAuth, onLogout]);

  return (
    <NavLink to={'/'} onClick={onLogOutCallBack} className={style.root}>
      <img src={logoutIcon} alt="exit" className={style.img} />
    </NavLink>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isAuth: isAuth$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    onLogout: dispatchProps.onLogout,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    onLogout: AuthCreators.onLogout,
  },
  mergeProps
)(ExitButton);
