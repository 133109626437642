import * as MusicianPlayerActions from 'actions/musicianPlayer';
import * as NewTrackActions from 'actions/newTracks';
import * as PlaylistActions from 'actions/playlist';
import * as SongActions from 'actions/song';
import not_sel from 'assets/img/not_sel.svg';
import pause from 'assets/img/pause-button.svg';
import play from 'assets/img/play-button.svg';
import sel from 'assets/img/sel.svg';
import cx from 'classnames';
import { successNotification } from 'components/successNotification/successNotification';
import * as AppCreators from 'creators/application';
import * as MusicianPlayerCreators from 'creators/musicianPlayer';
import IApp from 'interfaces/IApp';
import { IMusicianJob } from 'interfaces/IMusicianJob';
import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
import { ITrackWriter } from 'interfaces/state/TrackInfoState';
// eslint-disable-next-line
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { current_playlist$ } from 'selectors/applications';
import { job$ } from 'selectors/musicianJobs';
import { currentPlayedTrackId$, isPlay$ } from 'selectors/musicianPlayer';
import { currentId$ } from 'selectors/newTrack';
import { shortEnglishHumanizer } from 'utils/shortEngHuminizerTime';

import style from './trackItem.module.scss';

interface OwnProps {
  track: INewTrack;
  index: number;
}

interface IStateProps {
  currentPlayedTrackId: null | number;
  isPlayerPlay: boolean;
  currentId: number;
  current_playlist: IApp | null;
  job: IMusicianJob | null;
}

interface IDispatchProps {
  setPlayAfterSRCChanged: (autoPlayAfterSrcChanged: boolean) => void;
  setMuted: (isMuted: boolean) => void;
  setMusicianURL: (val: string) => void;
  getTrackUrl: (id: number, isNeedToClickPause: boolean, title?: string) => void;
  setCurrSongIndex: (a: number) => void;
  setIsPlayFromTrackPanel: (is: boolean) => void;
  setCurrentTrackInfo: (title: string, id: number, writer: ITrackWriter[], duration: number) => void;
  setID: (id: number | null) => void;
  updatePlaylist: (tracks: IApp['tracks'], id: number, track_id: number) => void;
  deleteTrackFromPlaylist: (tracks: IApp['tracks'], id: number, track_id: number) => void;
  updatePlaylistLocal: (track: { title: string; track_id: number }, action: 'add' | 'delete', isNew?: boolean) => void;
}

interface IProps extends OwnProps, IStateProps, IDispatchProps {}
const TrackItemComponent = ({
  setPlayAfterSRCChanged,
  setMuted,
  setMusicianURL,
  getTrackUrl,
  setCurrSongIndex,
  setIsPlayFromTrackPanel,
  setCurrentTrackInfo,
  setID,
  currentPlayedTrackId,
  track,
  index,
  isPlayerPlay,
  currentId,
  current_playlist,
  job,
  updatePlaylistLocal,
}: IProps) => {
  const deletefromPlaylist = () => {
    if (current_playlist) {
      updatePlaylistLocal({ track_id: track.track_id!, title: track.title! }, 'delete', track?.isNew);
    } else {
      successNotification('Please open job for add new track!');
    }
  };

  const addTrackToPlaylist = () => {
    if (current_playlist) {
      updatePlaylistLocal({ track_id: track.track_id!, title: track.title! }, 'add');
    } else {
      successNotification('Select or create Playlist for adding tracks');
    }
  };

  const setCurrent = async () => {
    let playButton = document.querySelector('.rhap_play-pause-button') as HTMLElement;
    setPlayAfterSRCChanged(true);
    setMuted(true);

    if (track.track_id !== currentPlayedTrackId) {
      setMusicianURL('');
      await getTrackUrl(track.track_id!, false);
    }

    setCurrSongIndex(index);
    setIsPlayFromTrackPanel(true);
    setCurrentTrackInfo(track.title!, track.track_id!, track.writers, track.track_duration);
    await setID(track.track_id);

    if (playButton) {
      playButton.click();
    }
  };

  const memoTracks = useMemo(() => {
    if (current_playlist) {
      return current_playlist!.tracks.map(pl => pl.track_id);
    } else {
      return [];
    }
  }, [current_playlist]);

  const isIncludes = useMemo(() => {
    return memoTracks.includes(track.track_id!);
  }, [memoTracks, track]);

  const bigLimitBtn = useMemo(() => {
    return job?.is_blocked || job?.status !== 'open';
  }, [job]);

  const limitedBtn = useMemo(() => {
    return job?.is_exceeded_by_job_limit || job?.is_exceeded_by_subscription_limit;
  }, [job]);

  const renderAddControl = useMemo(() => {
    return (
      <button className={style.toggleAddBtn} onClick={deletefromPlaylist}>
        <img src={sel} className="icon-track" alt="selected" />
      </button>
    );
  }, [deletefromPlaylist]);

  const renderDeleteBtn = useMemo(() => {
    return (
      <button className={style.toggleAddBtn} onClick={addTrackToPlaylist}>
        <img src={not_sel} className="icon-track-n" alt="not_selected" />
      </button>
    );
  }, [addTrackToPlaylist]);

  const renderTime = useMemo(() => (track.track_duration ? shortEnglishHumanizer(track.track_duration * 1000) : '0 sec'), [track]);

  const renderAddBtn = useCallback(() => {
    if (bigLimitBtn) {
      return null;
    } else {
      return job?.status === 'open' && isIncludes ? renderAddControl : renderDeleteBtn;
    }
    // eslint-disable-next-line
  }, [bigLimitBtn, renderAddControl, renderDeleteBtn, isIncludes, job]);

  const SelectContainerCX = cx(style.selectContainer, {
    [style.disabled]: limitedBtn,
  });

  return (
    <div className={style.root}>
      <div className={SelectContainerCX}>{renderAddBtn()}</div>
      <div className={style.playButton}>
        {isPlayerPlay && currentId === track.track_id ? (
          <img src={pause} alt="play-button" className={style.playBtn} onClick={setCurrent} />
        ) : (
          <img src={play} alt="play-button" className={style.playBtn} onClick={setCurrent} />
        )}
      </div>
      <div className={style.title} title={track.title!}>
        {track.title}
      </div>
      <div className={style.time}>{renderTime}</div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentPlayedTrackId: currentPlayedTrackId$(state),
    isPlayerPlay: isPlay$(state),
    currentId: currentId$(state),
    current_playlist: current_playlist$(state),
    job: job$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setMusicianURL: dispatchProps.setMusicianURL,
    setPlayAfterSRCChanged: dispatchProps.setPlayAfterSRCChanged,
    setMuted: dispatchProps.setMuted,
    getTrackUrl: dispatchProps.getTrackUrl,
    setCurrSongIndex: dispatchProps.setCurrSongIndex,
    setCurrentTrackInfo: dispatchProps.setCurrentTrackInfo,
    setIsPlayFromTrackPanel: dispatchProps.setIsPlayFromTrackPanel,
    setID: dispatchProps.setID,
    updatePlaylist: dispatchProps.updatePlaylist,
    deleteTrackFromPlaylist: dispatchProps.deleteTrackFromPlaylist,
    updatePlaylistLocal: dispatchProps.updatePlaylistLocal,
    ...props,
  };
};

export const TrackItem = connect(
  mapStateToProps,
  {
    setMusicianURL: MusicianPlayerActions.setMusicianURL,
    setPlayAfterSRCChanged: SongActions.setPlayAfterSRCChanged,
    setMuted: SongActions.setMuted,
    getTrackUrl: MusicianPlayerCreators.getTrackUrl,
    setCurrSongIndex: SongActions.setCurrSongIndex,
    setIsPlayFromTrackPanel: PlaylistActions.setIsPlayFromTrackPanel,
    setID: MusicianPlayerActions.setMusicianPlayerID,
    setCurrentTrackInfo: NewTrackActions.setCurrentTrackInfo,
    updatePlaylist: AppCreators.updatePlaylist,
    deleteTrackFromPlaylist: AppCreators.deleteTrackFromPlaylist,
    updatePlaylistLocal: AppCreators.updatePlaylistLocal,
  },
  mergeProps
)(TrackItemComponent);
