import { Loader } from 'components/loader/loader';
import State from 'interfaces/state/State';
import { ITeam } from 'interfaces/state/TeamState';
// eslint-disable-next-line
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { teams$ } from 'selectors/team';
import { isLoading$ } from 'selectors/team';

import { Team } from '../team';
import style from './teamList.module.scss';

interface IStateProps {
  teams: ITeam[];
  isLoading: boolean;
}
interface IDispatchProps {}
interface OwnProps {
  isCreator?: boolean;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const TeamListComponent = ({ teams, isLoading, isCreator }: IProps) => {
  return (
    <div className={style.root}>
      {isLoading && (
        <div className={style.loadingContainer}>
          <Loader />
        </div>
      )}
      <Scrollbars className={style.content}>
        {teams.map(team => (
          <Team key={team.id} team={team} isCreator={isCreator} />
        ))}
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    teams: teams$(state),
    isLoading: isLoading$(state),
  };
};

export const TeamList = connect(mapStateToProps)(TeamListComponent);
