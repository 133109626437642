import State from 'interfaces/state/State';
// eslint-disable-next-line
import React from 'react';
import { connect } from 'react-redux';
import { affiliate_url$ } from 'selectors/affiliate';

import style from './dashboardLink.module.scss';

interface IStateProps {
  affiliate_url: string;
}
interface IProps extends IStateProps {}

const DashboardLinkComponent = ({ affiliate_url }: IProps) => {
  return (
    <div className={style.root}>
      <a href={affiliate_url} className={style.link} target="_blank" rel="noopener noreferrer">
        Your Shareable link
      </a>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    affiliate_url: affiliate_url$(state),
  };
};

export const DashboardLink = connect(mapStateToProps)(DashboardLinkComponent);
