import 'sweetalert2/src/sweetalert2.scss';

import * as MusicJobsActions from 'actions/musicianJobs';
import * as MusicianPlayerActions from 'actions/musicianPlayer';
import * as TrackActions from 'actions/newTracks';
import * as PlaylistActions from 'actions/playlist';
import * as RateActions from 'actions/rating';
import * as RatingActions from 'actions/rating';
import { setShareablePlaylistLinksInit } from 'actions/shareablePlaylistLinks';
import * as SharedPlaylistActions from 'actions/sharedPlaylist';
import * as SongActions from 'actions/song';
import * as TrackInfoActions from 'actions/trackInfo';
import * as TrackInformationActions from 'actions/trackInformation';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { USER_MANAGER } from 'constants/index';
import * as CurrentSubscrCreators from 'creators/currentSubscription';
import * as MusicianPlayerCreators from 'creators/musicianPlayer';
import * as PlaylistCreators from 'creators/playlist';
import { Api } from 'enums/api';
import { INewTrack, INewTrackType, TrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { appIds$, current_playlist$ } from 'selectors/applications';
import { playlist_id$ as application_id$ } from 'selectors/applications';
import { userType$ } from 'selectors/auth';
import { allJobs$, currentJobIndex$, current_job_id$, jobs$ } from 'selectors/musicianJobs';
import { currentPlayedTrackId$, currentURL$ } from 'selectors/musicianPlayer';
import {
  all$,
  allLoadedTracks$,
  amount$,
  currentId$,
  filterBy$,
  isNewTracksAppeared$,
  orderBy$,
  orderedTracks$,
  share_type$,
  sortType$,
  trackId$,
  tracks_for_playlist$,
} from 'selectors/newTrack';
import { playlist_id$, sharedPlaylistId$ } from 'selectors/playlist';
import { fromPulled$, to$ } from 'selectors/rating';
import { sharedPlaylists$ } from 'selectors/sharedPlaylist';
import { file_name$, format$ } from 'selectors/trackInformation';
import { duration$, file_size$ } from 'selectors/uploadTrack';
import { catchedErrorNotification } from 'utils/error';
import { isEmptyArray } from 'utils/isEmptyArray';

import * as NewTracksActions from '../actions/newTracks';
import { fetchTracksToPlaylistById } from './application';
import { getTrackUrl } from './musicianPlayer';
import { fetchSharedPlaylistLinksById } from './shareablePlaylistLinks';

export const fetchTracks = (
  type: TrackType,
  preload?: boolean,
  limit?: number,
  offset?: number,
  loadPlaylistAfterTracks?: boolean,
  loadPlaylist?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewTracksActions.setNewTracksPending());
    if (offset === 0) {
      dispatch(NewTracksActions.getAllTracks([]));
    }
    const currentId = currentId$(getState());
    const userType = userType$(getState());
    const playlist_id = application_id$(getState());

    axios
      .post(`${Api.GetTracks}`, {
        type: type,
        limit: limit ? limit : 80,
        offset: offset ? offset : 0,
      })
      .then(response => {
        const tracks = response.data.result.tracks as INewTrack[];
        const amount = response.data.result.number_of_tracks;
        dispatch(NewTracksActions.setTracksAmount(amount));

        if (offset) {
          dispatch(NewTracksActions.addNewToAllTracks(tracks));
        } else {
          dispatch(NewTracksActions.getAllTracks(tracks));
          if (tracks.length) {
            dispatch(SongActions.setCurrSongIndex(0));
          }
          if (
            (!currentURL$(getState()) && userType$(getState()) === 'creator') ||
            (userType$(getState()) === 'musician' && !currentURL$(getState()))
          ) {
            if (response.data.result?.tracks?.length) {
              const id = response.data.result?.tracks[0]?.track_id;
              dispatch(NewTracksActions.setCurrentTrackId(id));

              if (id! !== currentId) {
                // dispatch(MusicianPlayerCreators.getTrackUrl(id, false) as any);
              }
              dispatch(MusicianPlayerActions.setMusicianPlayerID(id));

              if (userType === 'creator' && playlist_id) {
                dispatch(fetchTracksToPlaylistById(playlist_id, loadPlaylist) as any);
              }
            }
          }
        }

        if (loadPlaylist && userType === 'creator' && playlist_id) {
          dispatch(fetchTracksToPlaylistById(playlist_id, true) as any);
        }
      })
      .then(() => {
        const allLoadedTracks = allLoadedTracks$(getState());
        if (allLoadedTracks.length && preload) {
          const firstTrack = allLoadedTracks[0];
          dispatch(MusicianPlayerActions.setMusicianPlayerID(firstTrack.track_id));
          dispatch(MusicianPlayerActions.setMusicianPlayerNameInBase(firstTrack.name_in_base!));
          dispatch(MusicianPlayerActions.setMusicianPlayerTitle(firstTrack.title!));

          if (firstTrack.track_id! !== currentId) {
            dispatch(NewTracksActions.setCurrentTrackId(firstTrack.track_id!));
            // Make sure that it is not wrong
            dispatch(MusicianPlayerCreators.getTrackUrl(firstTrack.track_id!, false) as any);
          }
          dispatch(SongActions.setPlayAfterSRCChanged(false));
        }
      })
      .then(() => {
        const userType = userType$(getState());
        const current_playlist = current_playlist$(getState());
        const appIds = appIds$(getState());
        if (userType === 'creator' && loadPlaylistAfterTracks && !current_playlist && appIds?.length) {
          const id = appIds[0];
          if (id) {
            dispatch(fetchTracksToPlaylistById(Number(id)) as any);
            dispatch(PlaylistActions.setCurrentPlaylistId(Number(id)));
          }
        }
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());
        dispatch(NewTracksActions.getAllTracks([]));

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const searchTracks = (title: string, type: TrackType) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewTracksActions.setNewTracksPending());
    dispatch(NewTracksActions.getAllTracks([]));

    axios
      .post(`${Api.SearchTracks}`, { title, type })
      .then(response => {
        dispatch(NewTracksActions.getAllTracks(response.data.result));
        if (response.data.result?.length) {
          const id = response.data.result?.[0]?.track_id;
          dispatch(NewTracksActions.setCurrentTrackId(0));

          const currentId = currentId$(getState());
          dispatch(SongActions.setSongPlay(false));

          if (id! !== currentId) {
            dispatch(NewTracksActions.setCurrentTrackId(id));
            dispatch(SongActions.setPlayAfterSRCChanged(false));
            dispatch(MusicianPlayerCreators.getTrackUrl(id, false) as any);
          }
        }
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getAllTracks = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(NewTracksActions.setNewTracksPending());

    axios
      .post(`${Api.GetAllTracks}`, {
        limit: 100,
        offset: 0,
      })
      .then(response => {
        isEmptyArray(response.data.result, dispatch(NewTracksActions.getNewTracksSuccess(response.data.result)), 'Tracks');
        dispatch(NewTracksActions.setNewTracksLoaded());
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const setInfoAboutTrack = (track_id: number, track: Partial<INewTrackType>, isEdit?: boolean) => {
  return (dispatch: (action: Action | any) => void, getState: () => State) => {
    dispatch(NewTracksActions.setNewTracksPending());
    const name = () => 'noname';
    const writers = track!.writers!.map(wr => ({ ...wr, percentage: parseFloat(`${wr.percentage}`).toFixed(2) as any }));
    const publishers = track!.publishers!.map(pb => ({ ...pb, percentage: parseFloat(`${pb.percentage}`).toFixed(2) as any }));
    const master_owners = track!.master_owners!.map(pb => ({ ...pb, percentage: parseFloat(`${pb.percentage}`).toFixed(2) as any }));
    const globalTrackType = filterBy$(getState());
    const amount = amount$(getState());

    axios
      .post(`${Api.SetTrackInfo}`, {
        artist_name: track!.artist_name,
        track_id: track_id,
        title: track!.title!,
        type: track!.type,
        description: track!.description,
        tags: track!.tags,
        writers,
        publishers,
        track_duration: isEdit ? track!.track_duration : duration$(getState()),
        file_size: isEdit ? track!.file_size : file_size$(getState()),
        file_name: file_name$(getState()),
        format: format$(getState())?.format,
        master_owners,
      })
      .then(() => {
        const newTrack = {
          ...track!,
          name_in_base: name(),
          track_duration: isEdit ? track!.track_duration! : duration$(getState())!,
          writers,
          publishers,
          file_size: isEdit ? track!.file_size : file_size$(getState()),
        };
        if (!isEdit) {
          dispatch(TrackActions.setTracksAmount(amount + 1));
        }
        dispatch(TrackActions.setNewTracksLoaded());
        if (globalTrackType === 'all' || globalTrackType === track.track_type) {
          dispatch(NewTracksActions.editNewTrackAndSave(newTrack, isEdit));
        }
        if (globalTrackType !== track.track_type && globalTrackType !== 'all') {
          dispatch(NewTracksActions.deleteTrackFromUsertrackList(track.track_id!));
        }
        dispatch(TrackInformationActions.setTrackInformationInit());
        dispatch(TrackInfoActions.setTrackInfoInit());
        successNotification('Congratulations! You set information about your track successfully!');
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getAppliedTrack = (jobId?: number, limit?: number, offset?: number, withoutRating?: boolean, isQualityFilter?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const job_id = jobId ? jobId : current_job_id$(getState());
    const jobIndex = jobs$(getState()).findIndex(item => item.id === job_id);
    dispatch(NewTracksActions.setNewTracksPending());
    dispatch(setShareablePlaylistLinksInit());
    if (!offset && !isQualityFilter) {
      // dispatch(PlaylistActions.setCurrPlaylistNum(0));
    }

    if (withoutRating) {
      dispatch(RatingActions.setRatingFromTo(0, 10));
      dispatch(RatingActions.setRatingFromPulled(null));
    }
    if (isNewTracksAppeared$(getState())) {
      dispatch(NewTracksActions.updateNewTrackAppeared(false));
    }
    if (offset === 0) {
      dispatch(NewTracksActions.getNewTracksSuccess([]));
      dispatch(NewTracksActions.setNewTracksPending());
      dispatch(RateActions.getRatingReceive(0));
      if (!isQualityFilter) {
        dispatch(PlaylistActions.setCurrentPlaylistId(null));
        dispatch(PlaylistActions.setInitPlaylistItems());
      }
      dispatch(MusicJobsActions.setCurrJobIndex(jobIndex));
      dispatch(MusicJobsActions.setCurrentJobId(job_id));
    }

    const from = fromPulled$(getState());
    const to = to$(getState());
    const sort_by = orderBy$(getState());
    const sort_direction = sortType$(getState()) === 'asc' ? 'up' : 'down';

    const data = {
      job_id,
      limit: limit ? limit : 100,
      offset: offset ? offset : 0,
      to,
      sort_by,
      sort_direction,
    };

    const params = from === null || withoutRating ? data : { ...data, from };
    axios
      .post(`${Api.GetAppliedTracks}`, params)
      .then(response => {
        const tracks = response.data?.result?.tracks;
        const amount = response.data?.result?.number_of_tracks;
        const localAmount = amount$(getState());
        const jobIdFromServer = response.data.result.job_id;
        const submitted_songs = response.data?.result?.submitted_songs;
        const currentJobId = current_job_id$(getState());

        dispatch(NewTracksActions.setSubmittedSongs(submitted_songs || 0));

        if (amount !== localAmount && jobIdFromServer !== job_id) {
          dispatch(NewTracksActions.updateNewTrackAppeared(true));
        }
        dispatch(RateActions.setRatingFromTo(response.data.result.from, 10));
        if (!offset && tracks.length) {
          dispatch(getTrackUrl(tracks[0].track_id! as number, true) as any);
          dispatch(PlaylistActions.setTilteInBase(tracks[0].title!));
          dispatch(NewTracksActions.setCurrentTrackId(tracks[0].track_id));
        }

        if (limit && offset) {
          dispatch(NewTracksActions.getNewTrackWithLimit(tracks));
        } else {
          dispatch(TrackActions.setNewTracksQFilterInit());
          dispatch(NewTracksActions.getNewTracksSuccess(tracks));
        }
        dispatch(NewTracksActions.setNewTracksLoaded());
        if (tracks?.length > 0) {
          dispatch(RateActions.getRatingReceive(orderedTracks$(getState())[0].rating!));
          dispatch(SongActions.setCurrSongIndex(null));
          if (!offset && !isQualityFilter && job_id !== currentJobId) {
            dispatch(PlaylistCreators.getPlaylist(Number(job_id), offset === 0) as any);
          }
        }
        dispatch(NewTracksActions.setTracksAmount(amount));
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getPlaylistTracks = (playlist_id: number, limit: number, offset: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(PlaylistActions.setPlaylistLoadingTracks(true));
    const job_id = current_job_id$(getState());
    axios
      .post(Api.GetPlaylistTracks, {
        playlist_id,
        job_id,
        limit,
        offset,
        from: 0,
      })
      .then(response => {
        const playlisTracks = tracks_for_playlist$(getState());
        const data = offset === 0 ? response.data.result.tracks : [...playlisTracks, ...response.data.result.tracks];
        // const jobs = jobs$(getState());
        // const jobIndex = jobs.findIndex(item => item.id === job_id);
        // const userType = userType$(getState());
        // if ((jobs[jobIndex].share_type !== 'playlist_sharing' && userType === 'manager') || userType === 'admin') {
        dispatch(PlaylistActions.setPlaylistAmount(response.data.result.number_of_tracks));
        // }
        dispatch(PlaylistActions.setPlaylistLoadingTracks(false));
        dispatch(NewTracksActions.getNewTracksForPlaylist(data));

        // dispatch(NewTracksActions.setNewTracksLoaded());
        if (playlist_id$(getState()) !== playlist_id) dispatch(fetchSharedPlaylistLinksById(playlist_id as number) as any);
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getSharedTracks = (
  playlist_id: number,
  from?: number,
  to?: number,
  notNeedToInit?: boolean,
  limit?: number,
  offset?: number,
  needToRatingInit?: boolean,
  withoutChangingPlayliatIndex?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const sharedPlaylists = sharedPlaylists$(getState());
    const index = sharedPlaylists.findIndex(pl => pl.playlist_id === playlist_id);

    dispatch(PlaylistActions.setSharedPlaylistID(playlist_id));
    if (index >= 0) {
      dispatch(SharedPlaylistActions.setSharedPlaylistId(index));
      if (!withoutChangingPlayliatIndex) {
        dispatch(PlaylistActions.setSharedPlaylistIndex(index));
      }
    } else {
      dispatch(SharedPlaylistActions.setSharedPlaylistId(null));
    }

    const job_id = current_job_id$(getState());
    dispatch(TrackActions.setCurrPlaylistId(playlist_id));
    dispatch(NewTracksActions.setNewTracksPending());
    dispatch(RateActions.getRatingReceive(0));
    if (isNewTracksAppeared$(getState())) {
      dispatch(NewTracksActions.updateNewTrackAppeared(false));
    }
    if (!from && !to) {
      if (!notNeedToInit) {
        dispatch(PlaylistActions.setInitPlaylistItems());
        dispatch(TrackActions.setNewTracksInit());
        dispatch(PlaylistActions.setCurrentPlaylistId(null));
      }
    }

    if (needToRatingInit) {
      dispatch(RatingActions.setRatingFromTo(0, 10));
      dispatch(RatingActions.setRatingFromPulled(null));
    }

    const fromPulled = fromPulled$(getState());
    const toPulled = to$(getState());
    const sort_by = orderBy$(getState());
    const sort_direction = sortType$(getState()) === 'asc' ? 'up' : 'down';

    const data = {
      playlist_id,
      to: toPulled,
      job_id,
      limit: limit ? limit : 100,
      offset: offset ? offset : 0,
      sort_by,
      sort_direction,
    };

    const params = fromPulled === null || needToRatingInit ? data : { ...data, from: fromPulled };
    axios
      .post(`${Api.GetPlaylistTracks}`, params)
      .then(response => {
        const tracks = response.data.result.tracks;
        const localAmount = amount$(getState());
        const amount = response.data.result.number_of_tracks;
        const jobIdFromServer = response.data.result.job_id;
        const userType = userType$(getState());
        // dispatch(PlaylistActions.setPlaylistAmount(amount || 0));
        if (userType === USER_MANAGER) {
          dispatch(NewTracksActions.setTracksAmount(amount));
        }
        const submitted_songs = response.data?.result?.submitted_songs;
        dispatch(NewTracksActions.setSubmittedSongs(submitted_songs || 0));
        if (amount !== localAmount && jobIdFromServer !== job_id) {
          dispatch(NewTracksActions.updateNewTrackAppeared(true));
        }

        if (limit && offset) {
          dispatch(NewTracksActions.getNewTrackWithLimit(tracks));
        } else {
          dispatch(TrackActions.setNewTracksQFilterInit());
          dispatch(NewTracksActions.getNewTracksSuccess(tracks));
          dispatch(PlaylistActions.setTilteInBase(tracks[0].title));
          dispatch(NewTracksActions.setCurrentTrackId(tracks[0].track_id));
        }

        dispatch(NewTracksActions.setNewTracksLoaded());
        if (tracks.length > 0) {
          dispatch(SongActions.setCurrSongIndex(null));
          dispatch(RateActions.getRatingReceive(orderedTracks$(getState())[0].rating!));
          dispatch(getTrackUrl(tracks[0].track_id, true) as any);
          // dispatch(PlaylistCreators.getPlaylist(`${playlist_id}`) as any);
        }
        dispatch(RateActions.setRatingFromTo(response.data.result.from, 10));
        // dispatch(RateActions.setRatingFromPulled(response.data.result.from));
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const getSharedPlaylistTracks = (job_id: number, withoutRating?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewTracksActions.setNewTracksPending());
    dispatch(PlaylistActions.setInitPlaylistItems());
    dispatch(PlaylistActions.setCurrentPlaylistId(null));
    dispatch(TrackActions.setNewTracksInit());
    dispatch(RateActions.getRatingReceive(0));
    dispatch(SharedPlaylistActions.setSharedPlaylistInit());
    dispatch(setShareablePlaylistLinksInit());

    if (withoutRating) {
      dispatch(RatingActions.setRatingFromTo(0, 10));
      dispatch(RatingActions.setRatingFromPulled(null));
    }
    axios
      .post(`${Api.GetSharedPlaylist}`, {
        job_id,
      })
      .then(response => {
        if (response.data.result.length > 0) {
          // dispatch(PlaylistActions.setCurrPlaylistNum(0));
          dispatch(
            getSharedTracks(
              response.data.result[0]!.playlist_id as number,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              true
            ) as any
          );

          const playlist_id = response.data.result[0]!.playlist_id;
          dispatch(PlaylistActions.setCurrentPlaylistId(response.data.result[0]!.playlist_id as number));
          if (playlist_id$(getState()) !== playlist_id) dispatch(fetchSharedPlaylistLinksById(playlist_id as number) as any);

          dispatch(fetchSharedPlaylistLinksById(response.data.result[0]!.playlist_id as number) as any);
          // dispatch(PlaylistActions.getPlaylistReceive(response.data.result));
          // dispatch(PlaylistActions.setCurrPlaylistNum(0));
          dispatch(SharedPlaylistActions.setSharedPlaylistReceive(response.data.result));
          dispatch(SharedPlaylistActions.setSharedPlaylistId(0));
          dispatch(PlaylistCreators.getPlaylist(response.data.result[0]!.job_id) as any);
        } else {
          dispatch(SharedPlaylistActions.setSharedPlaylistId(null));
        }
        // dispatch(NewTracksActions.getNewTracksForPlaylist(response.data.result));
        dispatch(NewTracksActions.setNewTracksLoaded());
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteTrackById = (id: number, deleteNotLoaded?: boolean, needToNotChangeAmount?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(NewTracksActions.setNewTracksPending());

    const trId = deleteNotLoaded ? trackId$(getState()) : id;
    const amount = amount$(getState());

    axios
      .post(`${Api.DeleteTrack}`, {
        id: trId,
      })
      .then(() => {
        dispatch(NewTracksActions.deleteTrack(id));
        dispatch(NewTracksActions.setNewTracksLoaded());
        dispatch(CurrentSubscrCreators.fetchCurrentSubscription() as any);
        if (amount && !needToNotChangeAmount) {
          dispatch(NewTracksActions.setTracksAmount(amount - 1));
        }
      })
      .catch(error => {
        dispatch(NewTracksActions.setNewTracksFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const onPlayerPlayClick = (title: string, name: string, id: number | null, rating: number, isCreator?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(SongActions.setPlayAfterSRCChanged(true));
    dispatch(SongActions.setMuted(true));
    dispatch(PlaylistActions.setIsPlayFromTrackPanel(true));
    dispatch(PlaylistActions.setTilteInBase(title));
    dispatch(PlaylistActions.setPlNameInBase(name));
    dispatch(RatingActions.getRatingReceive(rating));
    dispatch(NewTracksActions.setCurrentTrackId(id!));
    dispatch(MusicianPlayerActions.changePlayFromPlaylist());
    dispatch(SongActions.scrollToTrack(true));

    if (isCreator) {
      // eslint-disable-next-line
      const index = all$(getState()).findIndex(tr => tr.track_id == id);
      dispatch(SongActions.setCurrSongIndex(index));
    } else {
      // eslint-disable-next-line
      const index = orderedTracks$(getState()).findIndex(tr => tr.track_id == id);
      dispatch(SongActions.setCurrSongIndex(index));
    }
    if (id !== currentPlayedTrackId$(getState())) {
      dispatch(MusicianPlayerCreators.getTrackUrl(id!, false) as any);
    }

    dispatch(MusicianPlayerActions.setMusicianPlayerID(id));

    let playButton = document.querySelector('.rhap_play-pause-button') as HTMLElement;
    if (playButton) {
      playButton.click();
    }
  };
};

export const highlightTrack = (track_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const currentJobIndex = currentJobIndex$(getState());
    const allJobs = allJobs$(getState());
    const isAdmin = userType$(getState()) === 'admin';
    const jobs = jobs$(getState());
    const isAutoForwardingType =
      typeof currentJobIndex === 'number'
        ? isAdmin
          ? allJobs?.[currentJobIndex]?.share_type !== 'playlist_sharing'
          : jobs?.[currentJobIndex]?.share_type !== 'playlist_sharing'
        : false;
    const url = isAdmin || isAutoForwardingType ? Api.AppHighLight : Api.TrackHighLight;
    const job_id = current_job_id$(getState());
    const playlist_id = playlist_id$(getState());
    const data = isAdmin || isAutoForwardingType ? { job_id } : { playlist_id };
    axios
      .post(url, {
        track_id,
        listened: true,
        ...data,
      })
      .then(() => {
        dispatch(
          NewTracksActions.editTrackById({
            track_id,
            value: true,
            key: 'listened',
          })
        );
      })
      .catch(() => {});
  };
};

export const fetchTracksBySorting = (isCompany?: boolean) => {
  return (dispatch: (action: Action | any) => void, getState: () => State) => {
    const isAdmin = userType$(getState()) === 'admin';
    const currentJobId = current_job_id$(getState());
    const isCreator = userType$(getState()) === 'creator';
    const currentPlaylistId = playlist_id$(getState());
    const type = filterBy$(getState());
    const share_type = share_type$(getState());
    const sharedPlaylistId = sharedPlaylistId$(getState());

    let playButton = document.querySelector('[aria-label="Pause"]') as HTMLElement;
    if (playButton) {
      playButton.click();
    }

    if (!currentJobId) return;
    if (isAdmin) {
      dispatch(getAppliedTrack(currentJobId!, 50, 0, undefined, true));
    } else {
      if (isCreator) {
        dispatch(fetchTracks(type, false, 50, 0));
      } else {
        if (currentPlaylistId) {
          if (share_type === 'playlist_sharing') {
            dispatch(
              getSharedTracks(
                isCompany && sharedPlaylistId ? sharedPlaylistId : currentPlaylistId,
                undefined,
                undefined,
                true,
                50,
                0,
                undefined,
                true
              )
            );
          } else {
            dispatch(getAppliedTrack(currentJobId!, 50, 0, undefined, true));
          }
        } else {
          dispatch(getAppliedTrack(currentJobId!, 50, 0, undefined, true));
        }
      }
    }
  };
};
