import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import ContactMailRoundedIcon from '@material-ui/icons/ContactMailRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
// eslint-disable-next-line
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userType$ } from 'selectors/auth';

import style from './settingsPopover.module.scss';

interface IProps {
  isManager: boolean;
  onClosePopover: () => void;
}

export const SettingsPopover = ({ isManager, onClosePopover }: IProps) => {
  const history = useHistory();
  const userType = useSelector(userType$);

  const onProfileClick = useCallback(() => {
    handleChangeUrl('/profile');
    // eslint-disable-next-line
  }, []);

  const onPricingClick = useCallback(() => {
    handleChangeUrl('/pricing');
    // eslint-disable-next-line
  }, []);

  const onAffiliateClick = useCallback(() => {
    handleChangeUrl('/affiliate');
    // eslint-disable-next-line
  }, []);

  const onBillingClick = useCallback(() => {
    handleChangeUrl('/billing');
    // eslint-disable-next-line
  }, []);

  const handleChangeUrl = useCallback(
    (link: string) => {
      history.push(link);
      onClosePopover();
    },
    // eslint-disable-next-line
    [history]
  );

  return (
    <div className={style.root}>
      <div onClick={onProfileClick} className={style.popoverItem}>
        <AccountBoxRoundedIcon className={style.icon} />
        Profile
      </div>

      {!isManager && (
        <div onClick={onPricingClick} className={style.popoverItem}>
          <CreditCardRoundedIcon className={style.icon} />
          Pricing
        </div>
      )}
      {!isManager && (
        <div onClick={onBillingClick} className={style.popoverItem}>
          <LocalAtmIcon className={style.icon} />
          Billing
        </div>
      )}
      {userType !== 'admin' && (
        <div onClick={onAffiliateClick} className={style.popoverItem}>
          <ContactMailRoundedIcon className={style.icon} />
          Affiliate
        </div>
      )}
    </div>
  );
};
