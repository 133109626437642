import './style.scss';

import Tooltip from '@material-ui/core/Tooltip';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import { setfilterBy } from 'actions/newTracks';
import * as RatingActions from 'actions/rating';
import cx from 'classnames';
import { ListenedSort } from 'components/listenedSort';
import { SliderMaterial } from 'components/sliderMaterial/sliderMaterial';
import { TrackTypeTabs } from 'components/trackTypeTabs';
import * as NewTrackCreators from 'creators/newTracks';
import { TrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, memo, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userType$ } from 'selectors/auth';
import { current_job_id$ } from 'selectors/musicianJobs';
import { currentPlaylistId$, isLoading$, isNewTracksAppeared$, share_type$ } from 'selectors/newTrack';
import { from$, to$ } from 'selectors/rating';

interface IStateProps {
  currentJobId: number | null;
  from: number;
  to: number;
  isLoading: boolean;
  currentPlaylistId: null | number;
  share_type: 'auto_forwarding' | 'playlist_sharing' | null;
  isNewTracksAppeared: boolean;
}

interface IDispatchProps {
  getAppliedTrack: (id?: number, limit?: number, offset?: number, withoutRating?: boolean, isQualityFilter?: boolean) => void;
  setRatingFromTo: (from: number, to: number) => void;
  getSharedTracks: (playlist_id: number, from?: number, to?: number, notNeedToInit?: boolean, limit?: number, offset?: number) => void;
  setRatingFromPulled: (r: number | null) => void;
}
interface OwnProps {
  title: string;
  subtitle: string;
  controlText: string;
  isAdmin: boolean;
  type: TrackType;
  isCreator?: boolean;
  setType: (t: TrackType) => void;
  fetchTracks: (
    type: TrackType,
    preload?: boolean,
    limit?: number,
    offset?: number,
    loadPlaylistAfterTracks?: boolean,
    loadPlaylist?: boolean
  ) => void;
  setSearch?: (search: string) => void;
  setNotNeedToUpdate?: (v: boolean) => void;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const ManagerTrackControlls = ({
  title,
  subtitle,
  controlText,
  currentJobId,
  isLoading,
  from,
  isAdmin,
  currentPlaylistId,
  share_type,
  isNewTracksAppeared,
  isCreator,
  type,
  getAppliedTrack,
  setRatingFromTo,
  getSharedTracks,
  setRatingFromPulled,
  fetchTracks,
  setType,
  setSearch,
  setNotNeedToUpdate,
}: IProps) => {
  const userType = useSelector(userType$);
  const dispatch = useDispatch();
  const handleChange = (_e: ChangeEvent<{}>, rating: number | number[]) => {
    if (currentJobId && !isLoading) {
      setRatingFromTo(rating as number, 10);
      setRatingFromPulled(rating as number);
    }
  };

  const onChangeCommitted = (_e: ChangeEvent<{}>, rating: number | number[]) => {
    if (isAdmin) {
      getAppliedTrack(currentJobId!, 50, 0, undefined, true);
    } else {
      if (isCreator) {
      } else {
        if (share_type === 'auto_forwarding') {
          getAppliedTrack(currentJobId!, 50, 0, undefined, true);
        }

        if (share_type === 'playlist_sharing') {
          getSharedTracks(Number(currentPlaylistId), rating as number, 10);
        }
      }
    }
  };

  const handleLoadNewTrackList = () => {
    if (isLoading) return;
    if (isAdmin) {
      getAppliedTrack(currentJobId!, 50, 0, undefined, true);
    } else {
      if (isCreator) {
        setNotNeedToUpdate && setNotNeedToUpdate(true);
        fetchTracks(type, true, 50, 0, undefined, true);
        setSearch && setSearch('');
      } else {
        if (currentPlaylistId || currentJobId) {
          if (share_type === 'playlist_sharing') {
            getSharedTracks(currentPlaylistId!, undefined, undefined, true, 50, 0);
          } else {
            getAppliedTrack(currentJobId!, 50, 0, undefined, true);
          }
        }
      }
    }
  };

  const roundCX = cx('not-update-round', {
    'update-round': isNewTracksAppeared,
  });

  const btnCX = cx('m-s-btn-wrapper', {
    disabled: (!currentJobId && !isCreator) || isLoading,
    update: isNewTracksAppeared,
  });

  const updateCX = cx('m-s-btn-container', {
    'update-btn-creator': userType === 'creator',
  });

  const onSearch = useCallback(
    (t: TrackType) => {
      if (isLoading) return;
      setType(t);
      dispatch(setfilterBy(t));
      setNotNeedToUpdate && setNotNeedToUpdate(false);
    },
    // eslint-disable-next-line
    [type]
  );

  const onMusicClick = useCallback(() => {
    onSearch('music');
    // eslint-disable-next-line
  }, [type]);

  const onAllClick = useCallback(() => {
    onSearch('all');
    // eslint-disable-next-line
  }, [type]);

  const onSFXClick = useCallback(() => {
    onSearch('sfx');
    // eslint-disable-next-line
  }, [type]);

  const onAudioClick = useCallback(() => {
    onSearch('audio');
    // eslint-disable-next-line
  }, [type]);

  return (
    <div className="m-track-container d-flex align-center justify-between">
      <div className="d-flex flex-column">
        <span className="m-track-title">{title}</span>
        <span className="m-track-subtitle">{subtitle}</span>
        {!isCreator && (
          <div className="d-flex align-center">
            <span className="m-track-cont-text">{controlText}</span>
            <div className="m-tr-slider">
              <SliderMaterial
                disabled={!currentJobId || isLoading}
                from={from}
                to={from}
                onChange={handleChange}
                onChangeCommitted={onChangeCommitted}
                max={10}
                min={0}
                step={1}
              />
            </div>
          </div>
        )}
      </div>
      <div className="d-flex align-baseline m-track-slider justify-around">
        <div className="tabs-container-creator">
          {isCreator && (
            <TrackTypeTabs
              onAllClick={onAllClick}
              onMusicClick={onMusicClick}
              onSFXClick={onSFXClick}
              onAudioClick={onAudioClick}
              type={type}
              disabled={isLoading}
            />
          )}
        </div>

        <div className={updateCX}>
          <Tooltip
            title="A new track was added. Click this button and we can update list of tracks for this job!"
            open={isNewTracksAppeared}
            disableHoverListener
          >
            <div className={btnCX} onClick={handleLoadNewTrackList}>
              <CachedRoundedIcon />
              <span className={roundCX} />
            </div>
          </Tooltip>
        </div>
        {userType !== 'creator' && <ListenedSort />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentJobId: current_job_id$(state),
    isLoading: isLoading$(state),
    from: from$(state),
    to: to$(state),
    currentPlaylistId: currentPlaylistId$(state),
    share_type: share_type$(state),
    isNewTracksAppeared: isNewTracksAppeared$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    getAppliedTrack: dispatchProps.getAppliedTrack,
    setRatingFromTo: dispatchProps.setRatingFromTo,
    getSharedTracks: dispatchProps.getSharedTracks,
    setRatingFromPulled: dispatchProps.setRatingFromPulled,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    getAppliedTrack: NewTrackCreators.getAppliedTrack,
    getSharedTracks: NewTrackCreators.getSharedTracks,
    setRatingFromTo: RatingActions.setRatingFromTo,
    setRatingFromPulled: RatingActions.setRatingFromPulled,
  },
  mergeProps
)(memo(ManagerTrackControlls));
