import './statisticPanel.scss';

import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import downloadIcon from 'assets/img/downloadc.svg';
import headphonesTrack from 'assets/img/headphones.svg';
import uploadTrack from 'assets/img/music-note.svg';
import playTrack from 'assets/img/playTrack.svg';
import shareTrack from 'assets/img/share.svg';
import { IconRoundButton } from 'components/iconRoundButton';
import { IStatTrack } from 'interfaces/state/StatsState';
// eslint-disable-next-line
import React from 'react';

import StatisticDetail from './statisticDetails';

interface IProps {
  activeCount: number;
  tracks: IStatTrack[];
  isManager?: boolean;
  allJobsCount: number;
  getStats: () => void;
}

const StatisticPanel = ({ isManager, getStats, tracks, activeCount, allJobsCount }: IProps) => {
  const playNums = tracks?.length ? tracks.map(item => item.plays_count) : [0];
  const shreNums = tracks?.length ? tracks.map(item => item.share_count) : [0];
  const downloadList = tracks?.length ? tracks.map(item => item.upload_count) : [0];

  const tracksCount = tracks?.length || 0;
  const playCount = playNums?.length ? playNums.reduce((acc, curr) => acc + curr, 0) : 0;
  const shareCount = shreNums?.length ? shreNums.reduce((acc, curr) => acc + curr, 0) : 0;
  const downloads = downloadList.reduce((acc, curr) => acc + curr, 0);
  return (
    <section className="statistic-container d-flex align-center">
      <div className="d-flex align-center statistic-stats justify-around">
        <StatisticDetail image={uploadTrack} title="My Catalog" first={tracksCount} subTitle="" />
        {!isManager && (
          <StatisticDetail image={headphonesTrack} withTwoNums title="Active Jobs" first={activeCount} second={allJobsCount} subTitle="" />
        )}
        <StatisticDetail image={playTrack} title="Plays" first={playCount} subTitle="" />
        <StatisticDetail image={shareTrack} title="Shares" first={shareCount} subTitle="" />
        <StatisticDetail image={downloadIcon} title="Downloads" first={downloads} subTitle="" />
      </div>
      <IconRoundButton
        icon={<CachedRoundedIcon style={{ color: '#333333' }} />}
        tooltipText="Update Statistics"
        onClick={getStats}
        className="ml-auto m-r-10"
      />
    </section>
  );
};

export default StatisticPanel;
