import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import { IMusicianJob } from 'interfaces/IMusicianJob';
// eslint-disable-next-line
import React from 'react';

import style from './jobHead.module.scss';

interface IProps {
  job: IMusicianJob;
}

export const JobHead = ({ job }: IProps) => {
  return (
    <div className={style.root}>
      <div className={style.imgContainer}>
        {job.avatar_url ? (
          <img className={style.img} src={job.avatar_url!} alt="job avatar" />
        ) : (
          <ImageRoundedIcon className={style.defaultIcon} />
        )}
      </div>
      <div className={style.content}>
        <div className={style.top}>
          <h4 className={style.hTitle}>{job.title}</h4>
          {!!job.is_exceeded_by_job_limit && <div className={style.limitBadge}>Job limit reached</div>}
          <p className={style.budget} title={`${job.budget}`}>{`Budget: ${job.budget}`}</p>
        </div>
        <p className={style.company}>{job.company}</p>
        <p className={style.uploadCount}>{`Upload limit: ${
          job?.tracks_allowed_amount && job?.tracks_allowed_amount < 0 ? 0 : job?.tracks_allowed_amount
        }`}</p>
      </div>
      <span className={style.status} title={`${job.status}`}>
        {job.status}
      </span>
    </div>
  );
};
