import React from 'react';

import style from './onHoldContent.module.scss';

export const OnHoldContent = () => {
  return (
    <div className={style.root}>
      <h4 className={style.title}>Hello,</h4>
      <div className={style.subtitle}>
        Thank you so much for submitting to SmashHaus however we have reached out current capacity for our Beta but not to worry, we will be
        letting more talent into the system in the coming months so we will notify you when more spaces open up.
      </div>
      <div className={style.body}>
        <span>Thank you for your patience</span>
        <span>SmashHaus</span>
      </div>
      <footer className={style.footer}>
        <nav className={style.link}>
          <a href="https://www.smashhaus.com/" target="_blank" rel="noopener noreferrer">
            www.smashhaus.com
          </a>
        </nav>
        <div className={style.surge}>
          ***SmashHaus powers tech solutions for leading media companies and creators in Hollywood and around the globe.
        </div>
      </footer>
    </div>
  );
};
