import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import * as SignUpActions from 'actions/signUp';
import powered from 'assets/img/powered.png';
import classNames from 'classnames/bind';
import Button from 'components/button/button';
import Input from 'components/input/input';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import * as Const from 'constants/index';
import * as AuthCreators from 'creators/auth';
import * as SignUpCreators from 'creators/signUp';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, FormEvent, memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { isCheckedEmail$ } from 'selectors/auth';
import { isLoading$, isRedirectToLogin$ } from 'selectors/signUp';
import { emailValidation, isAbsoluteUrl, isValidHttpUrl } from 'utils';

import { Argeement } from './agreement';
import { CodeOfConduct } from './codeOfConduct';
import { Background } from './components/background';
import styles from './signUp.module.scss';
import { Terms } from './terms';

const roles = [
  { label: 'I am a music maker', value: 'creator' },
  { label: 'I am a company seeking music', value: 'manager' },
];

interface OwnProps {}

interface IStateProps {
  isLoading: boolean;
  isRedirectToLogin: boolean;
  isCheckedEmail: boolean | null;
}
interface IDispatchProps {
  signUp: (
    pass: string,
    user_name: string,
    email: string,
    role: 'creator' | 'admin' | 'manager',
    link_1: string,
    link_2?: string,
    company_id?: number
  ) => void;
  setSignUpInit: () => void;
  checkEmail: (email: string) => void;
}

interface IProps extends OwnProps, IStateProps, IDispatchProps {}

const SignUp = (props: IProps) => {
  const history = useHistory();

  const { signUp, isRedirectToLogin, setSignUpInit, isCheckedEmail, checkEmail } = props;

  const [passError, setPassError] = useState('');
  const [secondPassError, setSecondPassError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowSecondPass, setIsShowSecondPass] = useState(false);
  const [timer, setTimer] = useState(5);
  const [type, setType] = useState('creator' as 'creator' | 'admin' | 'manager');
  const [userName, setUserName] = useState('');
  const [pass, setFirstPass] = useState('');
  const [secondPass, setSecondPass] = useState('');
  const [email, setEmail] = useState('');
  const [link_1, setLink_1] = useState('');
  const [link_2, setLink_2] = useState('');
  const [linkError, setLinkError] = useState('');
  const [linkError2, setLinkError2] = useState('');
  const [step, setStep] = useState(0 as number);
  const [userRole, setUserRole] = useState(roles[0] as ISelectItem);
  const [company_id, setCompanyId] = useState(undefined as undefined | number);

  const onSubmit = () => {
    setPassError('');
    setSecondPassError('');
    setEmailError('');
    setLoginError('');

    signUp(pass, userName, email, type, link_1, link_2, company_id);
  };

  useEffect(() => {
    if (isRedirectToLogin) {
      setTimeout(() => {
        history.replace('/');
        setSignUpInit();
      }, 5000);
    }
    // eslint-disable-next-line
  }, [isRedirectToLogin]);

  useEffect(() => {
    if (isRedirectToLogin) {
      if (timer === -1) return;
      const interval = setInterval(() => setTimer(timer - 1), 1000);
      return () => clearInterval(interval);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [timer, isRedirectToLogin]);
  useEffect(() => {
    if (!isCheckedEmail && isCheckedEmail !== null) {
      setEmailError('Your Email is already registered!');
    } else if (isCheckedEmail && step === 0) {
      setStep(1);
    }
  }, [isCheckedEmail, step]);

  const handleLoginClick = () => setLoginError('');
  const handleSetShowPass = () => setIsShowPass(!isShowPass);
  const handleSetShowSecondPass = () => setIsShowSecondPass(!isShowSecondPass);
  const handlePassClick = () => setPassError('');
  const handleSecondPassClick = () => setSecondPassError('');
  const handleEmailClick = () => setEmailError('');
  const handleLinkClick = () => setLinkError('');
  const handleLink2Click = () => setLinkError2('');

  // eslint-disable-next-line
  const handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > Const.MAX_LOGIN_LENGTH) {
      setLoginError(`User name can be max ${Const.MAX_LOGIN_LENGTH} characters long`);
    } else {
      setLoginError('');
      setUserName(e.target.value);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > Const.MAX_EMAIL_LENGTH) {
      setEmailError(`Email can be max ${Const.MAX_EMAIL_LENGTH} characters long`);
    } else {
      setEmailError('');
      setEmail(e.target.value);
    }
  };

  const handleLink1Change = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === Const.MAX_LINK_LENGTH) {
      setLinkError(`Link can be max ${Const.MAX_LINK_LENGTH} characters long`);
    } else {
      if (!isValidHttpUrl(e.target.value) || !isAbsoluteUrl(e.target.value)) {
        setLinkError('This line is not a valid link');
        setLink_1(e.target.value);
      } else {
        setLinkError('');
        setLink_1(e.target.value);
      }
    }
  };

  const handleLink2Change = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === Const.MAX_LINK_LENGTH) {
      setLinkError2(`Link can be max ${Const.MAX_LINK_LENGTH} characters long`);
    } else {
      if (!isValidHttpUrl(e.target.value) || !isAbsoluteUrl(e.target.value)) {
        setLinkError2('This line is not a valid link');
        setLink_2(e.target.value);
      } else {
        setLinkError2('');
        setLink_2(e.target.value);
      }
    }
  };

  const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === Const.MAX_PASSWORD_LENGTH) {
      setPassError(`Password can be max ${Const.MAX_PASSWORD_LENGTH} characters long`);
    } else {
      setPassError('');
      setFirstPass(e.target.value);
    }
  };

  const handleSecPassChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === Const.MAX_PASSWORD_LENGTH) {
      setPassError(`Password can be max ${Const.MAX_PASSWORD_LENGTH} characters long`);
    } else {
      setPassError('');
      setSecondPass(e.target.value);
    }
  };

  const onNext = (st: number) => setStep(st);

  const formApply = () => {
    setPassError('');
    setSecondPassError('');
    setEmailError('');
    setLoginError('');

    if (!userName) {
      setLoginError(Const.REQUIRED_FIELD);
      return;
    }

    if (!email) {
      setEmailError(Const.REQUIRED_FIELD);
      return;
    } else if (email && !emailValidation(email)) {
      setEmailError('Email field must contain "@"');
      return;
    }

    if (!pass) {
      setPassError(Const.REQUIRED_FIELD);
      return;
    }
    if (type === 'creator') {
      if (!link_1) {
        setLinkError(Const.REQUIRED_FIELD);
        return;
      }

      if (!isValidHttpUrl(link_1)) {
        setLinkError('Please check your link');
        return;
      }

      if (link_2 && !isValidHttpUrl(link_2)) {
        setLinkError2('Please check your link');
        return;
      }
    }

    if (pass !== secondPass) {
      setPassError('Must be the same as the second password');
      setSecondPassError('Must be the same as the first password');
      return;
    }

    if (pass.length < 5) {
      setPassError('Password must be longer than 5 characters');
      return;
    }
    if (!type || !userName || !email || !pass || pass.length <= 5) {
      return;
    }
    checkEmail(email);
  };
  const onNextFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formApply();
  };

  const onFormButtonClick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formApply();
  };

  const onBackClick = () => onNext(2);

  const inputCX = classNames(styles.input, 'inp-primary');

  const handleChangeUserType = (option: ISelectItem) => {
    setUserRole(option);
    setType(option.value as 'creator' | 'admin' | 'manager');
  };

  return (
    <main className={styles.main}>
      {isRedirectToLogin ? (
        <div className={styles.redirectPage}>
          <div>We redirect you to Login page after {timer} seconds...</div>
          {timer === 0 ? <Redirect to={'/'} /> : null}
        </div>
      ) : null}
      <Background setCompanyId={setCompanyId}>
        <>
          {step === 0 && <img className={styles.powered} src={powered} alt="powered by" />}
          {step === 0 && (
            <>
              <NavLink className={styles.backButton} to="/">
                <ArrowBackIosRoundedIcon className={styles.icon} />
                <div className={styles.textBack}>Back</div>
              </NavLink>
              <form onSubmit={onNextFormSubmit} className={styles.form}>
                <h1 className={styles.title}>Apply for access to SmashHaus Community</h1>
                <MaterialSelect
                  value={userRole}
                  placeholder="Choose your role"
                  selectItems={roles}
                  onChange={() => ''}
                  className={styles.select}
                  isAllValueNeed
                  onChangeAllValue={handleChangeUserType}
                  width={315}
                />
                <Input
                  styleClass={inputCX}
                  type="text"
                  value={userName}
                  onChange={handleLoginChange}
                  placeholder={'User name'}
                  onClick={handleLoginClick}
                  error={loginError}
                  maxLength={Const.MAX_LOGIN_LENGTH}
                  isRequired
                  size="big"
                  rootClassName={styles.input}
                />
                <Input
                  styleClass={inputCX}
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                  onClick={handleEmailClick}
                  error={emailError}
                  maxLength={Const.MAX_EMAIL_LENGTH}
                  isRequired
                  size="big"
                  rootClassName={styles.input}
                />
                <Input
                  styleClass={inputCX}
                  type={isShowPass ? 'text' : 'password'}
                  value={pass}
                  onChange={handlePassChange}
                  placeholder="Password"
                  error={passError}
                  onClick={handlePassClick}
                  isShowPass={isShowPass}
                  setShowPass={handleSetShowPass}
                  isInputPass
                  maxLength={Const.MAX_PASSWORD_LENGTH}
                  isRequired
                  size="big"
                  rootClassName={styles.input}
                />
                <Input
                  styleClass={inputCX}
                  type={isShowSecondPass ? 'text' : 'password'}
                  value={secondPass}
                  onChange={handleSecPassChange}
                  placeholder="Repeat password"
                  error={secondPassError}
                  onClick={handleSecondPassClick}
                  isShowPass={isShowSecondPass}
                  setShowPass={handleSetShowSecondPass}
                  isInputPass
                  maxLength={Const.MAX_PASSWORD_LENGTH}
                  isRequired
                  size="big"
                  rootClassName={styles.input}
                />
                {type === 'creator' && (
                  <>
                    <div className={styles.text}>Send us links to samples of your music below</div>
                    <Input
                      styleClass={inputCX}
                      type="text"
                      value={link_1}
                      onChange={handleLink1Change}
                      placeholder="Link 1"
                      onClick={handleLinkClick}
                      error={linkError}
                      maxLength={Const.MAX_LINK_LENGTH}
                      isRequired
                      tooltipTitle={type === 'creator' ? 'Link to your music tracks' : 'Link to your social media profile, etc.'}
                      size="big"
                      rootClassName={styles.input}
                    />
                    <Input
                      styleClass={inputCX}
                      type="text"
                      value={link_2}
                      onChange={handleLink2Change}
                      placeholder="Link 2"
                      onClick={handleLink2Click}
                      error={linkError2}
                      maxLength={Const.MAX_LINK_LENGTH}
                      tooltipTitle={'Link to your social media profile, etc.'}
                      size="big"
                      rootClassName={styles.input}
                    />
                  </>
                )}

                <Button styleClass={'btn-primary ' + styles.btn} value="Continue" onClick={() => onFormButtonClick} />
              </form>
            </>
          )}
          {step === 1 && <Argeement onNext={onNext} />}
          {step === 2 && <Terms onNext={onNext} />}
          {step === 3 && <CodeOfConduct onSubmit={onSubmit} onBackClick={onBackClick} />}
        </>
      </Background>
      {/* <Partners /> */}
    </main>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: isLoading$(state),
    isRedirectToLogin: isRedirectToLogin$(state),
    isCheckedEmail: isCheckedEmail$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    signUp: dispatchProps.signUp,
    setSignUpInit: dispatchProps.setSignUpInit,
    checkEmail: dispatchProps.checkEmail,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    signUp: SignUpCreators.signUp,
    setSignUpInit: SignUpActions.setSignUpInit,
    checkEmail: AuthCreators.checkEmail,
  },
  mergeProps
)(memo(SignUp));
