import * as Const from '../constants/index';
import State from '../interfaces/state/CurrentSubscriptionState';

const initState: State = {
  current_period_end: '',
  current_period_start: '',
  interval: 'month',
  is_active_subscription: false,
  product_title: '',
  stripe_customer_id: '',
  stripe_product_id: '',
  stripe_subscription_id: '',
  stripe_month_price_id: '',
  stripe_year_price_id: '',
  storage_info: {
    all_mbytes: 0,
    used_mbytes: 0,
  },
  update_date: '',
  isLoading: false,
  isError: false,
  currentSubscription: '',
  status: null,
};

export default function currentSubscription(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.CURR_SUBSCR_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.CURR_SUBSCR_SUCCESS:
      const {
        current_period_end,
        current_period_start,
        interval,
        is_active_subscription,
        product_title,
        stripe_customer_id,
        stripe_month_price_id,
        stripe_product_id,
        stripe_subscription_id,
        stripe_year_price_id,
        update_date,
        storage_info,
        currentSubscription,
        status,
      } = action.payload;
      return {
        ...state,
        current_period_end,
        current_period_start,
        interval,
        is_active_subscription,
        product_title,
        stripe_customer_id,
        stripe_month_price_id,
        stripe_product_id,
        stripe_subscription_id,
        stripe_year_price_id,
        storage_info,
        update_date,
        currentSubscription,
        status,
        isLoading: false,
        isError: false,
      };

    case Const.CURR_SUBSCR_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.CURR_SUBSCR_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.CURR_SUBSCR_CLEAR_PRICE_ID:
      return {
        ...state,
        stripe_month_price_id: '',
        stripe_year_price_id: '',
        is_active_subscription: false,
      };

    default:
      return state;
  }
}
