import 'sweetalert2/src/sweetalert2.scss';

import * as MusicianPlayerActions from 'actions/musicianPlayer';
import { setMuted, setPlayAfterSRCChanged } from 'actions/song';
import axios from 'axios';
import * as DownloadTrackCreators from 'creators/download';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const getTrackUrl = (track_id: number, isNeedToClickPause: boolean, title?: string) => {
  return (dispatch: (action: Action) => void) => {
    axios
      .post(`${Api.GetTrackURL}`, {
        track_id,
      })
      .then(response => {
        if (title) {
          dispatch(DownloadTrackCreators.downloadTrack(response.data.result.url, title!, track_id) as any);
        } else {
          dispatch(MusicianPlayerActions.setMusicianURL(response.data.result.url));
          let playButton = document.querySelector('.rhap_play-pause-button') as HTMLElement;

          if (playButton && isNeedToClickPause) {
            // playButton.click();
            dispatch(setPlayAfterSRCChanged(false));
            dispatch(setMuted(false));
          }
        }
      })
      .catch(error => {
        console.error(error.response);
        catchedErrorNotification(error);
      });
  };
};
