import 'sweetalert2/src/sweetalert2.scss';

import * as ShareActions from 'actions/sharePlaylist';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const sharePlaylist = (id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ShareActions.setSharePending());

    axios
      .post(`${Api.SharePlaylist}`, {
        playlist_id: id,
        status: true,
      })
      .then(() => {
        dispatch(ShareActions.setShareSuccess());
        successNotification('You share this playlist with company');
      })
      .catch(error => {
        dispatch(ShareActions.setShareFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const sharePlaylistToMember = (playlist_id: number, member_to: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ShareActions.setSharePending());

    axios
      .post(`${Api.SharePlaylistToMember}`, {
        playlist_id,
        member_to,
      })
      .then(() => {
        dispatch(ShareActions.setShareSuccess());
        successNotification('You share this playlist with company');
      })
      .catch(error => {
        dispatch(ShareActions.setShareFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
