import { setTabInit } from 'actions/tab';
import Logo from 'components/logo/logo';
import { LogoBottom } from 'components/logoBottom/logoBottom';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ReactNode } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { userType$ } from 'selectors/auth';

import * as pack from '../../../package.json';
import styles from './aside.module.scss';

interface IProps {
  tabs: ReactNode;
}

interface IStateProps {
  userType: string | null;
}

const Aside = ({ tabs }: IProps & IStateProps) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setTabInit());
  };

  return (
    <aside className={styles.root}>
      <div className={styles.wrapper}>
        <NavLink to={`/`} onClick={onClick}>
          <Logo />
        </NavLink>
        <nav className={styles.navigation}>{tabs}</nav>
        <footer className={styles.footer}>
          <LogoBottom />
          <div className={styles.version}>
            <span className={styles.versionWord}>v.</span>
            {pack.version}
          </div>
          <NavLink to={`/privacy-policy`} className={styles.policy}>
            Privacy Policy
          </NavLink>
        </footer>
      </div>
    </aside>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userType: userType$(state),
  };
};
export default connect(mapStateToProps)(Aside);
