import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import cx from 'classnames';
import { Avatar } from 'components/avatar/avatar';
import { errorNotification } from 'components/errorNotification/errorNotification';
import { IconRoundButton } from 'components/iconRoundButton';
import { InfopanelPopoverContent } from 'components/infoPanel/infopanelPopoverContent';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import { changeUserName, deleteUserAvatar, сhangeUserEmail, сhangeUserPassword } from 'creators/account';
import { sendAvatar } from 'creators/avatar';
// eslint-disable-next-line
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userEmail$, userName$ } from 'selectors/auth';
import { avatar$, isLoading$ } from 'selectors/avatar';
import { emailValidation } from 'utils';

import style from './profile.module.scss';

interface IEditProfile {
  value: string;
  isEmail?: boolean;
  onChange: (v: string) => void;
  onSubmit: () => void;
  initialValue: string;
}

export const EditProfileItem = ({ value, onChange, isEmail, onSubmit, initialValue }: IEditProfile) => {
  const [isEdit, setEdit] = useState(false);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    // eslint-disable-next-line
  }, []);

  const handleEdit = useCallback(() => {
    setEdit(p => !p);
  }, []);

  const handleOk = useCallback(() => {
    if (initialValue === value) {
      setEdit(false);
    } else {
      if (isEmail) {
        if (emailValidation(value)) {
          onSubmit();
          handleEdit();
        } else {
          errorNotification('Email is not valid!');
        }
      } else {
        if (value?.length > 0) {
          onSubmit();
          handleEdit();
        }
      }
    }
    // eslint-disable-next-line
  }, [isEmail, value, onSubmit, initialValue]);

  return (
    <div className={style.nameContainer}>
      {isEdit ? <input value={value} onChange={handleChange} className={style.nameInput} /> : <p className={style.name}>{value}</p>}
      {!isEdit && <EditRoundedIcon className={style.editIcon} onClick={handleEdit} />}
      {isEdit && (
        <button className={style.nameBtn} onClick={handleOk}>
          Ok
        </button>
      )}
    </div>
  );
};

interface IPassInput {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}
export const PasswordInput = ({ value, onChange, placeholder }: IPassInput) => {
  const [hidePass, setHidePass] = useState(true);

  const handlePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    // eslint-disable-next-line
  }, []);

  const handleTogglePass = useCallback(() => {
    setHidePass(p => !p);
  }, []);

  return (
    <div className={style.password}>
      <input
        type={hidePass ? 'password' : 'text'}
        value={value}
        onChange={handlePassword}
        className={style.input}
        placeholder={placeholder}
      />
      {hidePass ? (
        <VisibilityRoundedIcon onClick={handleTogglePass} className={style.icon} />
      ) : (
        <VisibilityOffRoundedIcon onClick={handleTogglePass} className={style.icon} />
      )}
    </div>
  );
};

export const ProfilePage = () => {
  const avatarSrc = useSelector(avatar$);
  const isLoading = useSelector(isLoading$);
  const userName = useSelector(userName$);
  const userEmail = useSelector(userEmail$);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setError] = useState('');

  useEffect(() => {
    setName(userName!);
  }, [userName]);

  useEffect(() => {
    if (userEmail) setEmail(userEmail!);
  }, [userEmail]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSendAvatarImage = async (file: File | File[]) => {
    await dispatch(sendAvatar(file![0]));
    setTimeout(() => handleClose(), 1000);
  };

  const handleClose = () => setAnchorEl(null);

  const handleChangeName = useCallback((value: string) => {
    setName(value);
  }, []);

  const photoCX = cx(style.photoContainer, {
    [style.hidden]: isLoading,
  });

  const handleOkName = () => {
    dispatch(changeUserName(name));
  };

  const handleOkEmail = () => {
    dispatch(сhangeUserEmail(email));
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onOpen = useCallback(() => {
    setOpenPopup(true);
  }, []);

  const handlePassword = useCallback(
    (v: string) => {
      setPassword(v);
      setError('');
    },
    // eslint-disable-next-line
    [errorMessage]
  );

  const handleNewPassword = useCallback(
    (v: string) => {
      setNewPassword(v);
      setError('');
    },
    // eslint-disable-next-line
    [errorMessage]
  );

  const onApply = useCallback(() => {
    if (newPassword && password) {
      dispatch(сhangeUserPassword(password, newPassword));
      setOpenPopup(false);
      setError('');
    } else {
      setError('Wrong data!');
    }
    // eslint-disable-next-line
  }, [newPassword, password, сhangeUserPassword]);

  const onDecline = useCallback(() => {
    setOpenPopup(false);
    setPassword('');
    setNewPassword('');
  }, []);

  const handleDeleteAvatar = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(deleteUserAvatar());
    // eslint-disable-next-line
  }, []);

  const deleteCX = cx(style.deleteContainer, {
    [style.hidden]: !avatarSrc,
  });

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <h2 className={style.head}>Profile</h2>
        <main className={style.main}>
          <IconRoundButton
            icon={
              <div className={style.imageContent}>
                <div
                  className={deleteCX}
                  onClick={handleDeleteAvatar}
                  onMouseEnter={onMouseEnter}
                  onMouseOver={onMouseEnter}
                  onPointerMove={onMouseEnter}
                >
                  <DeleteRoundedIcon className={style.deleteIcon} />
                </div>
                <Avatar src={avatarSrc} isProfile />
                <div className={photoCX}>
                  <AddAPhotoRoundedIcon className={style.icon} />
                </div>
              </div>
            }
            tooltipText="Add new avatar"
            className=""
            onClick={handleClick}
            style={{
              padding: 0,
            }}
          />
          <div className={style.userData}>
            <EditProfileItem value={name} onChange={handleChangeName} onSubmit={handleOkName} initialValue={userName!} />
            <EditProfileItem value={email} onChange={handleChangeEmail} onSubmit={handleOkEmail} initialValue={userEmail!} isEmail />
            <Popup
              isOpen={isOpenPopup}
              onOpen={onOpen}
              onApply={onApply}
              onDecline={onDecline}
              btnClassName="mus-track-edit"
              applyBtnText="Apply"
              buttonText={
                <button className={style.passBtn}>
                  Change password
                  <VpnKeyRoundedIcon className={style.passIcon} />
                </button>
              }
              modalContent={
                <div className={style.popupContent}>
                  <div className={style.popupHeader}>Change password</div>
                  <PasswordInput value={password} onChange={handlePassword} placeholder={'Current password'} />
                  <PasswordInput value={newPassword} onChange={handleNewPassword} placeholder={'New password'} />
                  <div className={style.error}>{errorMessage}</div>
                </div>
              }
            />
          </div>
        </main>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          verticalAnchor="bottom"
          horizontalAnchor="center"
          verticalTransform="top"
          horizontalTransform="center"
          content={<InfopanelPopoverContent func={handleSendAvatarImage} />}
        />
      </div>
    </div>
  );
};
