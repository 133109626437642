import * as ApplicationActions from 'actions/application';
import * as MusicianJobsActions from 'actions/musicianJobs';
import * as MusicJobsCreator from 'creators/musicianJobs';
import IApp from 'interfaces/IApp';
import { IMusicianJob } from 'interfaces/IMusicianJob';
import State from 'interfaces/state/State';
import { SubscriptionName } from 'interfaces/state/SubscriptionList';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useSelector } from 'react-redux';
import { subscription$ } from 'selectors/auth';
import { allJobs$, current_job_id$, isLoading$, jobsCounter$, teamJobs$ } from 'selectors/musicianJobs';
import { usePrevious } from 'utils';

import { JobsHeader } from '../jobsHeader';
import { JobWithPlaylistItem } from '../jobWithPlaylistItem';
import style from './jobsWithPlaylist.module.scss';

interface IDispatchProps {
  fetchAllMusicianJobs: (status: string, o?: number, l?: number) => void;
  setCurrentJobId: (id: number | null) => void;
  setCurrPlaylist: (playlist: IApp | null) => void;
  setCurrentMusicianJob: (payload: IMusicianJob | null) => void;
}
interface IStateProps {
  allJobs: IMusicianJob[];
  teamJobs: IMusicianJob[];
  current_job: number | null;
  subscription: SubscriptionName;
}
interface OwnProps {
  jobStatus: string;
  setJobStatus: (t: string) => void;
}
interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const JobsWithPlaylistComponent = ({
  fetchAllMusicianJobs,
  setCurrentMusicianJob,
  setCurrentJobId,
  setCurrPlaylist,
  allJobs,
  teamJobs,
  current_job,
  subscription,
  jobStatus,
  setJobStatus,
}: IProps) => {
  const prevJobStatus = usePrevious(jobStatus);
  const isLoading = useSelector(isLoading$);
  const jobsCounter = useSelector(jobsCounter$);

  useEffect(() => {
    if (jobStatus !== prevJobStatus) {
      fetchAllMusicianJobs(jobStatus, 0, 20);
    }
    // eslint-disable-next-line
  }, [prevJobStatus, jobStatus]);

  const [isTeamJobs, setTeamJobs] = useState(false);
  const jobs = isTeamJobs ? teamJobs : allJobs;
  const disabled = useMemo(() => isLoading || jobsCounter === jobs.length, [isLoading, jobsCounter, jobs]);
  const fetchNextJobs = useCallback(() => {
    if (jobs?.length >= 20) {
      fetchAllMusicianJobs(jobStatus, jobs.length, 20);
    }
    // eslint-disable-next-line
  }, [jobs, jobStatus]);

  return (
    <div className={style.root}>
      <JobsHeader setTeamJobs={setTeamJobs} isTeamJobs={isTeamJobs} jobStatus={jobStatus} setJobStatus={setJobStatus} />
      <div className={style.jobs}>
        <InfiniteScroll
          dataLength={jobs?.length}
          next={fetchNextJobs}
          hasMore={true}
          height={'calc(100vh - 250px)'}
          loader={<div />}
          endMessage={<></>}
          scrollableTarget="scrollableDiv"
        >
          {jobs?.map(job => {
            return (
              <JobWithPlaylistItem
                job={job}
                key={job.id}
                current_job={current_job}
                setCurrentJobId={setCurrentJobId}
                setCurrPlaylist={setCurrPlaylist}
                setCurrentMusicianJob={setCurrentMusicianJob}
                subscription={subscription}
              />
            );
          })}
        </InfiniteScroll>

        <button onClick={fetchNextJobs} className={style.nextBtn} disabled={disabled}>
          {isLoading ? 'Loading' : 'Next'}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    allJobs: allJobs$(state),
    teamJobs: teamJobs$(state),
    current_job: current_job_id$(state),
    subscription: subscription$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchAllMusicianJobs: dispatchProps.fetchAllMusicianJobs,
    setCurrentJobId: dispatchProps.setCurrentJobId,
    setCurrPlaylist: dispatchProps.setCurrPlaylist,
    setCurrentMusicianJob: dispatchProps.setCurrentMusicianJob,
    ...props,
  };
};

export const JobsWithPlaylist = connect(
  mapStateToProps,
  {
    fetchAllMusicianJobs: MusicJobsCreator.fetchAllMusicianJobs,
    setCurrentJobId: MusicianJobsActions.setCurrentJobId,
    setCurrentMusicianJob: MusicianJobsActions.setCurrentMusicianJob,
    setCurrPlaylist: ApplicationActions.setCurrPlaylist,
  },
  mergeProps
)(memo(JobsWithPlaylistComponent));
