import { IBilling } from 'interfaces/state/BillingState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setBillingInit = (): Action => {
  return {
    type: Const.BILLING_INIT,
  };
};

export const getSuccessBilling = (payload: IBilling) => {
  return {
    type: Const.BILLING_SUCCESS,
    payload,
  };
};

export const setBillingFail = (): Action => {
  return {
    type: Const.BILLING_ERROR,
  };
};

export const setBillingLoading = (payload?: boolean) => {
  return {
    type: Const.BILLING_PENDING,
    payload,
  };
};

export const selectDefaultMethod = (id: number) => {
  return {
    type: Const.BILLING_DEFAULT,
    id,
  };
};
