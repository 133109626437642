import IApp from 'interfaces/IApp';
import { INewTrack } from 'interfaces/INewTrack';
import { IShareableLink } from 'interfaces/state/AppState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setAppInit = (): Action => {
  return {
    type: Const.APP_INIT,
  };
};

export const getAppSuccess = (payload: IApp[], id: number | null) => {
  return {
    type: Const.APP_SUCCESS,
    payload,
    id,
  };
};

export const addAppSuccess = (payload: IApp, id: number | null) => {
  return {
    type: Const.APP_ADD_ONE_SUCCESS,
    payload,
    id,
  };
};

export const deletePlaylist = (id: number | null) => {
  return {
    type: Const.DELETE_PLAYLIST_APP,
    id,
  };
};

export const setAppFail = (): Action => {
  return {
    type: Const.APP_ERROR,
  };
};

export const setAppPending = (): Action => {
  return {
    type: Const.APP_PENDING,
  };
};

export const setPlaylistId = (payload: number | null) => {
  return {
    type: Const.SET_PLAYLIST_ID_APP,
    payload,
  };
};

export const setCurrPlaylist = (payload: IApp | null) => {
  return {
    type: Const.SET_CURRENT_PLAYLIST_APP,
    payload,
  };
};

export const setCurrInitPlaylist = (payload: IApp | null) => {
  return {
    type: Const.SET_CURRENT_INIT_PLAYLIST_APP,
    payload,
  };
};

export const updatePlaylist = (app: IApp[], payload: IApp) => {
  return {
    type: Const.UPDATE_PLAYLIST_APP,
    app,
    payload,
  };
};

export const setShareableLinks = (payload: IShareableLink[]) => {
  return {
    type: Const.SHAREABLE_LINKS_APP,
    payload,
  };
};

export const addShareableLink = (payload: IShareableLink) => {
  return {
    type: Const.SHAREABLE_LINKS_ADD_ONE,
    payload,
  };
};

export const updateAddedNumberOfTracks = (payload: number) => {
  return {
    type: Const.UPD_ADDED_TRACKS_NUMBER,
    payload,
  };
};

export const setDefaultPlaylistTracks = (payload: INewTrack[]) => {
  return {
    type: Const.SET_DEFAULT_PLAYLIST_TRACKS,
    payload,
  };
};

export const setAppLoaded = () => {
  return {
    type: Const.SET_APP_LOADED,
  };
};

export const addCreatorApplications = (payload: IApp[]) => {
  return {
    type: Const.SET_APP_FOR_CREATOR_PLAYLIST,
    payload,
  };
};

export const deleteCreatorApplication = (payload: number) => {
  return {
    type: Const.DEL_APP_FROM_CREATOR_PLAYLIST,
    payload,
  };
};

export const addOneCreatorApplication = (id: number, payload: IApp) => {
  return {
    type: Const.ADD_APP_TO_CREATOR_PLAYLIST,
    payload,
    id,
  };
};

export const delTrackFromCreatorApp = (id: number, track_id: number) => {
  return {
    type: Const.DEL_TRACK_FROM_PLAYLIST_CREATOR,
    track_id,
    id,
  };
};

export const addTrackFromCreatorApp = (id: number, payload: { title: string; track_id: string; isNew?: boolean }) => {
  return {
    type: Const.ADD_TRACK_TO_PLAYLIST,
    payload,
    id,
  };
};

export const saveAppChanges = (id: number) => {
  return {
    type: Const.APPLICATION_SAVE_CHANGES,
    id,
  };
};

export const changeUnsaved = (payload: boolean) => {
  return {
    type: Const.APP_HAS_UNSAVED,
    payload,
  };
};
