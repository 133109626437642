import { Dropzone } from 'components/dropzone/dropzone';
import { errorNotification } from 'components/errorNotification/errorNotification';
// eslint-disable-next-line
import React from 'react';

import styles from './infopanelPopoverContent.module.scss';

interface IProps {
  func: (file: File | File[]) => void;
}

export const InfopanelPopoverContent = ({ func }: IProps) => {
  const onDropReject = () => {
    errorNotification('Maximum upload size is 1Mb and image must be a .png, .jpg, .jpeg');
  };

  const accept = ['.png', '.jpg', 'jpeg'];
  const maxSize = 1070000;
  return (
    <section className={styles.root}>
      <div className={styles.title}>Change avatar</div>
      <Dropzone onAccept={func} onDropReject={onDropReject} accept={accept} maxSize={maxSize} />
    </section>
  );
};
