import State from 'interfaces/state/TeamInviteState';

import * as Const from '../constants';

const initState: State = {
  list: [],
  isLoading: false,
  isError: false,
};

export default function teamInvite(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.TEAM_INVITE_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.TEAM_INVITE_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: true,
      };

    case Const.TEAM_INVITE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.TEAM_INVITE_LIST:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.TEAM_INVITE_DELETE:
      return {
        ...state,
        list: [...state.list].filter(team => team.id !== action.id),
        isLoading: false,
        isError: false,
      };

    case Const.TEAM_INVITE_CREATE:
      return {
        ...state,
        list: [action.payload, ...state.list],
        isLoading: false,
        isError: false,
      };

    case Const.TEAM_INVITE_SENDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.TEAM_INVITE_UPDATE:
      const invitations = [...state.list];
      const index = invitations.findIndex(invite => invite.id === action.id);

      if (index < 0) return { ...state };
      invitations[index].status = 'accepted';
      return {
        ...state,
        isLoading: false,
        isError: false,
        list: invitations,
      };

    default:
      return state;
  }
}
