import State from 'interfaces/state/State';
// import { ITrackPublisher, ITrackWriter } from 'interfaces/state/TrackInfoState';

export const title$ = (state: State) => state.trackInfo.title;
export const type$ = (state: State) => state.trackInfo.type;
export const description$ = (state: State) => state.trackInfo.description;
export const tag$ = (state: State) => state.trackInfo.tag;
export const writer$ = (state: State) => state.trackInfo.writer;
export const publisher$ = (state: State) => state.trackInfo.publisher;
export const isShowExpandPanel$ = (state: State) => state.trackInfo.isShowExpandPanel;
// export const writers$ = (state: State): ITrackWriter[] => state.trackInfo.writers;
// export const publishers$ = (state: State): ITrackPublisher[] => state.trackInfo.publishers;
export const isOpenEditNewTrackPopup$ = (state: State): boolean => state.trackInfo.isOpenEditNewTrackPopup;
