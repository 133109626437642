import sitelogo from 'assets/img/smash-logo.png';
// eslint-disable-next-line
import React from 'react';

export const MobileMessage = () => {
  return (
    <div className={'mobile-window'}>
      <img className={'mobile-img'} alt={'only desktop'} src={sitelogo} />
      <p className={'mobile-text'}>
        SORRY,
        <span className={'mobile-name'}>SMASHHAUS</span> IS NOT A MOBILE APP. IT ONLY WORKS ON DESKTOP FORMATS
      </p>
    </div>
  );
};
