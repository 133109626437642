import axios from 'axios';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import { Api } from 'enums/api';
import { IJobType } from 'pages/musician/playlists/components/search';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useState } from 'react';

interface IProps {
  setType: (val: string | number) => void;
  value: ISelectItem;
}

export const JobTypesSelect = memo(({ value, setType }: IProps) => {
  const [isLoading, setLoading] = useState(false);
  const [jobTypes, setJobTypes] = useState([] as IJobType[]);
  const [selectItems, setSelectItems] = useState([] as ISelectItem[]);

  const fetchJobTypes = async () => {
    setLoading(true);
    const res = await axios
      .post(Api.GetJobTypes)
      .then(res => res.data.result)
      .catch(err => console.log(err));
    setJobTypes(res);
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchJobTypes();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (jobTypes?.length) {
      const newTypes = jobTypes.map(j => ({ value: j.name, label: j.name }));
      setSelectItems(newTypes);
    }

    return () => setSelectItems([]);
  }, [jobTypes]);

  const handleChange = useCallback(
    (val: string | number) => {
      setType(val);
    },
    [setType]
  );

  return (
    <MaterialSelect
      value={value}
      placeholder="Job type"
      selectItems={selectItems}
      onChange={handleChange}
      className={''}
      width={270}
      isLoading={isLoading}
    />
  );
});
