import { Action } from 'redux';

import * as Const from '../constants';

export const setUnsubscrInit = (): Action => {
  return {
    type: Const.UNSUBSCRIBE_INIT,
  };
};

export const setUnsubscrSuccess = () => {
  return {
    type: Const.UNSUBSCRIBE_SUCCESS,
  };
};

export const setUnsubscrLoading = () => {
  return {
    type: Const.UNSUBSCRIBE_PENDING,
  };
};

export const setUnsubscrError = () => {
  return {
    type: Const.UNSUBSCRIBE_ERROR,
  };
};
