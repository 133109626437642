import { AdminModule } from 'modules/userTypes/admin';
import { CreatorModule } from 'modules/userTypes/creator';
import { ManagerModule } from 'modules/userTypes/manager';
// eslint-disable-next-line
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { userType$ } from 'selectors/auth';
import { usePrevious } from 'utils';

export const PrivateHandler = memo(() => {
  const userType = useSelector(userType$);
  const prevUserType = usePrevious(userType);

  const renderPrivateRoutes = useCallback(() => {
    if (userType === prevUserType) return;
    if (userType === 'creator' || userType === 'musician') {
      return (
        <Router>
          <CreatorModule />
        </Router>
      );
    } else if (userType === 'manager' || userType === 'company') {
      return (
        <Router>
          <ManagerModule />
        </Router>
      );
    } else if (userType === 'admin') {
      return (
        <Router>
          <AdminModule />
        </Router>
      );
    } else {
      return <div />;
    }
  }, [userType, prevUserType]);

  return <>{renderPrivateRoutes()}</>;
});
