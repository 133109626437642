import { Action } from 'redux';

import * as Const from '../constants';

export const setSongInit = (): Action => {
  return {
    type: Const.SONG_INIT,
  };
};

export const setCurrSongUrl = (payload: string) => {
  return {
    type: Const.CURR_SONG,
    payload,
  };
};

export const setSongTime = (payload: number) => {
  return {
    type: Const.CURR_SONG_TIME,
    payload,
  };
};

export const setSongPlay = (payload: boolean) => {
  return {
    type: Const.SET_SONG_PLAY,
    payload,
  };
};

export const setSongRating = (payload: number) => {
  return {
    type: Const.SET_SONG_RATING,
    payload,
  };
};

export const setSongVolume = (payload: number) => {
  return {
    type: Const.SET_SONG_VOLUME,
    payload,
  };
};

export const setSongName = (payload: string) => {
  return {
    type: Const.SET_SONG_NAME,
    payload,
  };
};

export const setSongArtist = (payload: string) => {
  return {
    type: Const.SET_SONG_ARTIST,
    payload,
  };
};

export const setSongDuration = (payload: number) => {
  return {
    type: Const.SET_SONG_DURATION,
    payload,
  };
};

export const setSongClickedTime = (payload: number | null) => {
  return {
    type: Const.SET_SONG_CLICK_TIME,
    payload,
  };
};

export const setCurrSongIndex = (payload: number | null) => {
  return {
    type: Const.CURR_SONG_INDEX,
    payload,
  };
};

export const setPlayAfterSRCChanged = (payload: boolean) => {
  return {
    type: Const.SET_PLAY_AFTER_SRC_CHANGED,
    payload,
  };
};

export const setMuted = (payload: boolean) => {
  return {
    type: Const.SET_MUTED_ON_PLAYER,
    payload,
  };
};

export const scrollToTrack = (payload: boolean) => {
  return {
    type: Const.SCROLL_TO_TRACK,
    payload,
  };
};
