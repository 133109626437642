// eslint-disable-next-line
import React, { PureComponent } from 'react';

import Form from './form';
import style from './style.module.scss';

class InvitePage extends PureComponent {
  public render() {
    return (
      <section className={style.root}>
        <Form />
      </section>
    );
  }
}

export default InvitePage;
