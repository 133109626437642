import CompaniesTable from 'containers/companyTable';
import * as CompanyCreators from 'creators/companyJob';
import * as MusicianJobsCreators from 'creators/musicianJobs';
import CompaniesState, { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userName$ } from 'selectors/auth';
import { companies$, currentCompany$ } from 'selectors/companies';
import { selectedJobsType$ } from 'selectors/companyJob';

import style from './style.module.scss';

interface IStateProps {
  userName: string | null;
  companies: ICompany[];
  selectedJobsType: 'all' | 'deleted' | 'closed' | 'archived' | 'open';
  currentCompany: null | CompaniesState['currentCompany'];
}

interface IDispatchProps {
  fetchCompanyJobs: (status: string, company_id: number, o?: number, l?: number) => void;
  fetchAllMusicianJobs: (status: string, o?: number, l?: number) => void;
}

interface IProps {
  isManager?: boolean;
}

class CompanyList extends PureComponent<IStateProps & IDispatchProps & IProps> {
  public async componentDidMount() {
    this.handleStep(1);
    await this.fetchInitJobs();
  }

  // eslint-disable-next-line
  private fetchInitJobs = () => {
    const { fetchAllMusicianJobs, fetchCompanyJobs, isManager, selectedJobsType, companies, currentCompany } = this.props;

    if (isManager) {
      setTimeout(() => fetchAllMusicianJobs(selectedJobsType, 0, 100), 500);
    } else {
      if (this.props.companies.length > 0)
        setTimeout(() => {
          fetchCompanyJobs(selectedJobsType, currentCompany ? currentCompany.id : companies[0].id, 0, 100);
        }, 500);
    }
  };

  public componentDidUpdate(prevProps: IStateProps & IDispatchProps) {
    if (prevProps.userName && prevProps.userName !== this.props.userName) {
      this.fetchInitJobs();
    }
  }

  private handleStep = (step: number) =>
    this.setState(() => ({
      step: step,
    }));

  public render() {
    return (
      <section className={`${style.root} show-07-animation`}>
        <CompaniesTable />
      </section>
    );
  }
}

const mapStateToProps = (state: State): IStateProps => {
  return {
    userName: userName$(state),
    companies: companies$(state),
    selectedJobsType: selectedJobsType$(state),
    currentCompany: currentCompany$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: IProps) => {
  return {
    ...stateProps,
    fetchCompanyJobs: dispatchProps.fetchCompanyJobs,
    fetchAllMusicianJobs: dispatchProps.fetchAllMusicianJobs,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCompanyJobs: CompanyCreators.fetchCompanyJobs,
    fetchAllMusicianJobs: MusicianJobsCreators.fetchAllMusicianJobs,
  },
  mergeProps
)(CompanyList);
