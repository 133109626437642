import classNames from 'classnames/bind';
import Button from 'components/button/button';
import Form from 'components/form/form';
// eslint-disable-next-line
import React, { memo } from 'react';
import { headerText, subHeaderText } from 'utils/headers';

import styles from './signInForm.module.scss';

interface IProps {
  isGoogle: boolean;
  onSubmit: (email: string, pass: string) => void;
}

const signInForm = ({ isGoogle, onSubmit }: IProps) => {
  const formClassNames = classNames(styles.formWrap, {
    [styles.form]: !isGoogle,
  });

  return (
    <div className={styles.root}>
      <h3 className={styles.header}>{headerText(!isGoogle)}</h3>
      <p className={styles.subHead}>{subHeaderText(!isGoogle)}</p>
      <div className={formClassNames}>
        <Form onSubmit={onSubmit} className={styles.adminForm} />
        {isGoogle ? (
          <>
            <span className={styles.or}>or</span>
            <Button styleClass="button-secondary" value="Continue with Google" onClick={() => console.log('google')} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default memo(signInForm);
