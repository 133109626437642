const setFileSize = (size: number): string => {
  if (size < 1000) {
    return size + ' b';
  } else if (size > 1000000) {
    return (size / 1000000).toFixed(2) + ' Mb';
  } else if (size > 1000) {
    return (size / 1000).toFixed(2) + ' Kb';
  } else {
    return '0 b';
  }
};

export default setFileSize;
