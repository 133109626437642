import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as HelpActions from 'actions/help';
import Button from 'components/button/button';
import Input from 'components/input/input';
import { Loader } from 'components/loader/loader';
import * as Const from 'constants/index';
import * as HelpCreators from 'creators/help';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { ChangeEvent, FormEvent, memo, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { email$, isEmailSuccesfullySend$, isLoading$ } from 'selectors/help';

import styles from './help.module.scss';

interface IStateProps {
  email: string;

  isLoading: boolean;
  isEmailSuccesfullySend: boolean;
}

interface IDispatchProps {
  fetchHelpValue: (email: string) => void;
  fetchHelp: () => void;
}

interface OwnProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const Help = ({ email, fetchHelpValue, fetchHelp, isLoading, isEmailSuccesfullySend }: IProps) => {
  const [emailError, setEmailError] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > Const.MAX_EMAIL_LENGTH) {
      setEmailError(`Email can be max ${Const.MAX_PASSWORD_LENGTH} characters long`);
    } else {
      setEmailError('');
      fetchHelpValue(e.target.value);
    }
  };

  const onClick = () => {
    if (!email) {
      setEmailError('This field is required');
      return;
    } else {
      fetchHelp();
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) setEmailError('This field is required');
  };

  const onEmailClick = () => setEmailError('');

  return (
    <>
      {isLoading && (
        <div className={styles.loadingRoot}>
          <Loader />
        </div>
      )}

      {isEmailSuccesfullySend && (
        <div className={styles.successContainer}>
          <div className={styles.successWrap}>
            <div className={styles.successPharagraph}>Confirmation of the request to change the password was sent on your email!</div>
            <div className={styles.link}>
              Click <NavLink to="/"> here</NavLink>
            </div>
          </div>
          <CheckCircleIcon className={styles.icon} />
        </div>
      )}
      <section className={styles.root}>
        <NavLink className={styles.backButton} to="/">
          <ArrowBackIosRoundedIcon className={styles.icon} />
          <div className={styles.text}>Back</div>
        </NavLink>
        <form onSubmit={onSubmit} className={styles.form}>
          <fieldset className={styles.fieldset}>
            <p className={styles.pharagraph}>Enter your email address, and we`ll send you a password reset email</p>
            <legend className={styles.legend}>Reset Password form</legend>
            <span className={styles.wrapper}>
              <Input
                placeholder="Email"
                styleClass="inp-primary"
                type="text"
                value={email}
                onChange={onChange}
                onClick={onEmailClick}
                error={emailError}
                maxLength={Const.MAX_EMAIL_LENGTH}
                size="big"
              />

              <Button onClick={onClick} value="SEND TO EMAIL" styleClass="btn-primary" />
            </span>
          </fieldset>
        </form>
      </section>
    </>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    email: email$(state),
    isLoading: isLoading$(state),
    isEmailSuccesfullySend: isEmailSuccesfullySend$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    fetchHelpValue: dispatchProps.fetchHelpValue,
    fetchHelp: dispatchProps.fetchHelp,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchHelpValue: HelpActions.fetchHelpValue,
    fetchHelp: HelpCreators.fetchHelp,
  },
  mergeProps
)(memo(Help));
