import { Action } from 'redux';

import * as Const from '../constants';

export const setTempCompanyJobInit = (): Action => {
  return {
    type: Const.TEMP_COMPANY_JOBS_INIT,
  };
};

export const setTempCompanyJobItem = (item: 'title' | 'description' | 'budget', payload: string | number) => {
  return {
    type: Const.TEMP_COMPANY_JOBS_EDIT_ITEM,
    item,
    payload,
  };
};
