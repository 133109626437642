import 'sweetalert2/src/sweetalert2.scss';

import * as NewTrackAction from 'actions/newTracks';
import * as RatingActions from 'actions/rating';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { userType$ } from 'selectors/auth';
import { currentJobIndex$, jobs$ } from 'selectors/musicianJobs';
import { playlist_id$, sharedPlaylistId$ } from 'selectors/playlist';
import { catchedErrorNotification } from 'utils/error';

export const setRate = (track_id: number, track_rating: number, job_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(RatingActions.setRatePending());
    const playlist_id = playlist_id$(getState());
    const userType = userType$(getState());
    const sharedPlaylistId = sharedPlaylistId$(getState());

    const dataWithoutPlaylist = {
      track_id,
      track_rating,
      job_id,
    };
    const shared_playlist_id = sharedPlaylistId;
    const moderated_playlist_id = playlist_id;
    const jobs = jobs$(getState());
    const currentJobIndex = currentJobIndex$(getState());
    const currentJob = jobs[currentJobIndex!];
    const playFromData =
      userType === 'manager' || userType === 'company'
        ? shared_playlist_id && currentJob?.share_type === 'playlist_sharing'
          ? { shared_playlist_id, moderated_playlist_id, track_id, track_rating, job_id }
          : { moderated_playlist_id, track_id, track_rating, job_id }
        : shared_playlist_id && currentJob?.share_type === 'playlist_sharing'
        ? { shared_playlist_id, track_id, track_rating, job_id }
        : { track_id, track_rating, job_id };

    const data = playlist_id ? playFromData : dataWithoutPlaylist;

    dispatch(
      NewTrackAction.editTrackById({
        track_id,
        value: track_rating,
        key: 'rating',
      })
    );

    axios
      .post(`${Api.SetRating}`, data)
      .then(() => {
        dispatch(RatingActions.setRateSuccess());
        dispatch(RatingActions.getRatingReceive(track_rating));
        successNotification(`You rate the track in ${track_rating}`);
        dispatch(NewTrackAction.setTrackItemRating(track_id, track_rating));
        dispatch(NewTrackAction.setPlaylistTrackRating(track_id, track_rating));
      })
      .catch(error => {
        dispatch(RatingActions.setRateFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
