// eslint-disable-next-line
import React from 'react';

import Header from './components/header';
import Player from './components/player';
import { Playlist } from './components/playlist';
import TokenHandler from './components/tokenHandler';
import style from './sharedPlaylist.module.scss';

interface IProps {
  isMusicianPlaylist?: boolean;
}

export const SharedPlaylist = ({ isMusicianPlaylist }: IProps) => {
  return (
    <div className={style.root}>
      <Header />
      <Playlist isMusicianPlaylist={isMusicianPlaylist} />
      <TokenHandler isMusicianPlaylist={isMusicianPlaylist} />
      <Player />
    </div>
  );
};
