import './musicianTrack.scss';

import { IconButton } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import * as MusicianPlayerActions from 'actions/musicianPlayer';
import * as SongActions from 'actions/song';
import * as UploadActions from 'actions/uploadTrack';
import classNames from 'classnames/bind';
import { Popover } from 'components/popover/popover';
import Popup from 'components/popup/popup';
import * as MusicianPlayerCreators from 'creators/musicianPlayer';
import * as SetInfoCreators from 'creators/newTracks';
import * as NewTrackCreators from 'creators/newTracks';
import { INewTrackType } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { MouseEvent, memo, useState } from 'react';
import { connect } from 'react-redux';
import { isOpenUploadPopup$ } from 'selectors/uploadTrack';
import setFileSize from 'utils/setFileSize';
import { shortEnglishHumanizer } from 'utils/shortEngHuminizerTime';

import { MusicianEditTrackContent } from './musicianEditTrackContent';
import { MusicianTrackPopoverContent } from './musicianTrackPopoverContent';
import { MusicianTrackWithEmptyInfoInside } from './musicianTrackWithEmptyInfoInside copy';
import { MusTrackDeletePopup } from './musTrackDeletePopup';

interface OwnProps {
  index: number;
  currentIndex: number;
  setCurrentIndex: (n: number) => void;
  size?: string;
  track: INewTrackType;
}

interface IStateProps {
  isOpenUploadPopup: boolean;
}

interface IDispatchProps {
  setInfoAboutTrack: (track_id: number, track: Partial<INewTrackType>, isEdit?: boolean) => void;
  setMusicianPlayerNameInBase: (value: string) => void;
  setMusicianPlayerTitle: (value: string) => void;
  deleteTrackById: (id: number) => void;
  setUploadPopup: (value: boolean) => void;
  getTrackUrl: (id: number, isNeedToClickPause: boolean, title?: string) => void;
  setID: (id: number | null) => void;
  setPlayAfterSRCChanged: (v: boolean) => void;
}

interface IProps extends OwnProps, IDispatchProps, IStateProps {}

const MusicianTrack = ({
  index,
  currentIndex,
  track,
  isOpenUploadPopup,
  setCurrentIndex,
  setInfoAboutTrack,
  setMusicianPlayerNameInBase,
  setMusicianPlayerTitle,
  deleteTrackById,
  setUploadPopup,
  getTrackUrl,
  setID,
  setPlayAfterSRCChanged,
}: IProps) => {
  const [isSelected, setSelected] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const onClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (isOpenUploadPopup) return;
    setUploadPopup(true);
    setSelected(true);
    setCurrentIndex(index);
  };

  const onSectionClick = () => {
    if (isOpenUploadPopup || track.track_duration === null) return;
    setID(track.track_id);
    setSelected(true);
    setCurrentIndex(index);
    setMusicianPlayerNameInBase(track.name_in_base!);
    setMusicianPlayerTitle(track.title!);
    getTrackUrl(track.track_id!, false);
    setPlayAfterSRCChanged(true);
  };

  const deleteTrack = () => {
    deleteTrackById(Number(track.track_id!));
  };

  const sectionClassNames = classNames('mus-track-container d-flex align-center', {
    'mus-track-container-selected': (isSelected && index === currentIndex) || index === currentIndex,
    'mus-track-container-n': !isSelected && index !== currentIndex,
  });

  const wrapperClassNames = classNames('mus-track-wrapper', {
    'mus-track-wrapper-selected': (isSelected && index === currentIndex) || index === currentIndex,
  });

  const onClosePopup = () => {
    setUploadPopup(false);
  };

  const onOp = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const artist_name = track.artist_name ? track.artist_name : 'Artist Name';

  return (
    <>
      <section className={wrapperClassNames} onClick={onSectionClick}>
        {track?.track_duration !== null && (
          <div className={sectionClassNames}>
            <div className="mus-track-tr-type-container">
              <div className="mus-track-tr-type">{track.type}</div>
            </div>
            <div className="d-flex flex-column mus-track-name-c">
              <div className="d-flex align-center mus-track-artist-info" title={`${artist_name} - ${track.title}`}>
                <div className="mus-track-artist-name">{artist_name}</div>
                <div className="m-l-5 m-r-5 ">{' - '}</div>
                <div className="mus-track-name">{track.title!}</div>
              </div>
              <div className="mus-track-duration">
                {track.track_duration ? shortEnglishHumanizer(track.track_duration! * 1000) : '0 sec'}
              </div>
            </div>

            <div className="mus-track-desc">{track.description}</div>
            <div className="mus-track-size d-flex align-center">{track.file_size ? setFileSize(Number(track.file_size)) : null}</div>
            <div className="d-flex align-center mus-track-tags">
              <span className="mus-track-tag">{track.tags}</span>
            </div>
            <div className="mus-track-buttons">
              <div className="mus-track-writer">
                <>
                  <IconButton className="add_btn-play" size="small" aria-describedby={id} color="primary" onClick={e => handleClick(e)}>
                    <MoreHorizRoundedIcon />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    verticalAnchor="bottom"
                    horizontalAnchor="center"
                    verticalTransform="top"
                    horizontalTransform="center"
                    content={<MusicianTrackPopoverContent track={track} />}
                  />
                </>
              </div>
              <Popup
                isOpen={isOpenUploadPopup && index === currentIndex}
                isHideButtons
                btnClassName="mus-track-edit"
                buttonText={<EditRoundedIcon onClick={e => onClick(e)} />}
                modalContent={
                  <>
                    <MusicianEditTrackContent
                      setInfoAboutTrack={setInfoAboutTrack}
                      onClosePopup={onClosePopup}
                      track={track}
                      index={index}
                    />
                  </>
                }
              />
              <Popup
                onApply={deleteTrack}
                onOpen={e => onOp(e)}
                btnClassName="mus-track-edit"
                buttonText={<DeleteRoundedIcon />}
                modalContent={<MusTrackDeletePopup />}
              />
            </div>
          </div>
        )}
        {track?.track_duration === null && (
          <MusicianTrackWithEmptyInfoInside track={track} index={index} setInfoAboutTrack={setInfoAboutTrack} />
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    isOpenUploadPopup: isOpenUploadPopup$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setInfoAboutTrack: dispatchProps.setInfoAboutTrack,
    setMusicianPlayerTitle: dispatchProps.setMusicianPlayerTitle,
    setMusicianPlayerNameInBase: dispatchProps.setMusicianPlayerNameInBase,
    deleteTrackById: dispatchProps.deleteTrackById,
    setUploadPopup: dispatchProps.setUploadPopup,
    getTrackUrl: dispatchProps.getTrackUrl,
    setID: dispatchProps.setID,
    setPlayAfterSRCChanged: dispatchProps.setPlayAfterSRCChanged,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setInfoAboutTrack: SetInfoCreators.setInfoAboutTrack,
    setMusicianPlayerTitle: MusicianPlayerActions.setMusicianPlayerTitle,
    setMusicianPlayerNameInBase: MusicianPlayerActions.setMusicianPlayerNameInBase,
    deleteTrackById: NewTrackCreators.deleteTrackById,
    setUploadPopup: UploadActions.setUploadPopup,
    getTrackUrl: MusicianPlayerCreators.getTrackUrl,
    setID: MusicianPlayerActions.setMusicianPlayerID,
    setPlayAfterSRCChanged: SongActions.setPlayAfterSRCChanged,
  },
  mergeProps
)(memo(MusicianTrack));
