import * as CompaniesActions from 'actions/companies';
import * as CompanyJobActions from 'actions/companyJobs';
import * as NewTracksActions from 'actions/newTracks';
import { setSharedPlaylistId, setSharedPlaylistInit } from 'actions/sharedPlaylist';
import * as SharedPlaylistActions from 'actions/sharedPlaylist';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { USER_ADMIN } from 'constants/index';
import * as CompanyCreators from 'creators/companyJob';
import * as MusicJobsCreator from 'creators/musicianJobs';
import { fetchMusicianJobs } from 'creators/musicianJobs';
import { Api } from 'enums/api';
import { ICompany } from 'interfaces/state/CompaniesState';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { userName$, userType$ } from 'selectors/auth';
import { companies$, currentCompany$ } from 'selectors/companies';
import { selectedJobsType$ } from 'selectors/companyJob';
import { catchedErrorNotification } from 'utils/error';

interface INewCompany {
  name: string;
  description: string;
}
export const createCompany = (newCompany: INewCompany) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompaniesActions.setCompaniesLoading());

    axios
      .post(`${Api.CreateCompany}`, {
        name: newCompany.name,
        description: newCompany.description,
      })
      .then(response => {
        dispatch(
          CompaniesActions.addNewCompany({
            ...newCompany,
            id: response.data.result.company_id as number,
            members: [{ id: 0, role_name: 'owner', user_name: userName$(getState()) }],
          } as ICompany)
        );
        dispatch(
          CompaniesActions.setCurrentCompany({
            ...newCompany,
            id: response.data.result.company_id as number,
            members: [{ id: 0, role_name: 'owner', user_name: userName$(getState())!, email: '' }],
          })
        );
        dispatch(CompanyJobActions.getSuccessCompanyJobs([]));
        successNotification(`The company ${newCompany.name} was successfully created!`);
      })
      .catch(error => {
        dispatch(CompaniesActions.setErrorCompany());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchCompanyList = (isNeedToFetchActiveJobs?: boolean, variant?: 'open', isloadJobs?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompaniesActions.setCompaniesLoading());
    dispatch(NewTracksActions.setNewTracksInit());
    dispatch(NewTracksActions.setCurrPlaylistId(null));
    dispatch(SharedPlaylistActions.setSharedPlaylistInit());
    const selectedJobsType = selectedJobsType$(getState());

    axios
      .post(`${Api.GetCompanies}`)
      .then(response => {
        dispatch(CompaniesActions.setCompanies(response.data.result));

        const currentCompany = currentCompany$(getState());
        if (!currentCompany) {
          if (response.data.result.length > 0) {
            dispatch(CompaniesActions.setCurrentCompany(response.data.result[0]));
            if (isloadJobs) {
              dispatch(CompanyCreators.fetchCompanyJobs(selectedJobsType, response.data.result[0].id, 0, 100) as any);
            }
          } else {
            dispatch(CompaniesActions.setCurrentCompany(null));
          }
        } else {
          if (isloadJobs) {
            dispatch(CompanyCreators.fetchCompanyJobs(selectedJobsType, currentCompany.id, 0, 100) as any);
          }
        }
        if (isNeedToFetchActiveJobs && response.data.result.length > 0) {
          const isAdmin = false;
          dispatch(
            MusicJobsCreator.fetchMusicianJobs(
              variant ? variant : selectedJobsType$(getState()),
              currentCompany ? currentCompany.id : response.data.result[0].id,
              isAdmin
            ) as any
          );
        }
      })
      .catch(error => {
        dispatch(CompaniesActions.setErrorCompany());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchAllCompanies = (isAdmin: boolean, notLoadOnlyCompanies?: boolean, isloadJobs?: boolean, isLoadMusicianJobs?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompaniesActions.setCompaniesLoading());

    const isUserAdmin = userType$(getState()) === 'admin';
    axios
      .post(`${Api.GetAllCompanies}`)
      .then(response => {
        dispatch(CompaniesActions.setCompanies(response.data.result));
        if (response.data.result.length > 0 && !notLoadOnlyCompanies) {
          dispatch(setSharedPlaylistInit());
          // dispatch(setCurrentJobId(null));
          dispatch(setSharedPlaylistId(null));
          // dispatch(setSelectCompanyId(response.data.result[0].id));
          if (!isUserAdmin) {
            dispatch(MusicJobsCreator.fetchMusicianJobs('open', response.data.result[0].id, !!isAdmin) as any);
          }
          dispatch(CompaniesActions.setCurrentCompany(response.data.result[0]));
          const currentCompany = currentCompany$(getState());

          if (isLoadMusicianJobs) {
            dispatch(fetchMusicianJobs('open', response.data.result[0].id, isAdmin) as any);
          }
          if (isloadJobs) {
            if (!currentCompany) {
              dispatch(CompanyCreators.fetchCompanyJobs('open', response.data.result[0].id, 0, 100) as any);
            } else {
              if (!isUserAdmin) {
                dispatch(CompanyCreators.fetchCompanyJobs('open', currentCompany.id, 0, 100) as any);
              }
            }
          }
        }
      })
      .catch(error => {
        dispatch(CompaniesActions.setErrorCompany());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const createInviteForCompany = (company_id: number, email: string, role: string) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompaniesActions.setCompaniesLoading());

    axios
      .post(`${Api.CreateInviteInCompany}`, {
        company_id,
        email,
        role,
      })
      .then(response => {
        console.info(response);
        successNotification(`We sent an invitation to the mail ${email}!`, 8000);
        const userType = userType$(getState());
        if (userType === USER_ADMIN) {
          dispatch(fetchAllCompanies(true) as any);
        } else {
          dispatch(fetchCompanyList() as any);
        }
      })
      .catch(error => {
        dispatch(CompaniesActions.setErrorCompany());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteCompanyById = (company_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(CompaniesActions.setCompaniesLoading());

    axios
      .post(`${Api.DeleteCompanyById}`, {
        company_id,
      })
      .then(response => {
        console.info(response);
        const newCompanies = companies$(getState()).filter(company => company.id !== company_id);
        dispatch(CompaniesActions.setCompanies(newCompanies));
        if (companies$(getState()).length > 0) {
          dispatch(CompaniesActions.setCurrentCompany(companies$(getState())[0]));
        } else {
          dispatch(CompaniesActions.setCurrentCompany(null));
        }
      })
      .catch(error => {
        dispatch(CompaniesActions.setErrorCompany());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteMemberFromCompany = (company_id: number, member_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(CompaniesActions.deleteMember(company_id, member_id));
    dispatch(CompaniesActions.setCompaniesLoading());

    axios
      .post(`${Api.DeleteMemberFromCompany}`, {
        member_id,
        company_id,
      })
      .then(() => {
        dispatch(CompaniesActions.setCompanySuccess());
      })
      .catch(error => {
        dispatch(CompaniesActions.setErrorCompany());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
