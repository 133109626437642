// eslint-disable-next-line
import React from 'react';

import style from './paymentHeader.module.scss';

export const Paymentheader = () => {
  return (
    <div className={style.root}>
      <div className={style.column}>{'System'}</div>
      <div className={style.column}>{'Country'}</div>
      <div className={style.column}>{'Expiration data'}</div>
      <div className={style.column}>{'Last 4 Digits'}</div>
      <div className={style.column}>{'Type'}</div>
    </div>
  );
};
