import 'sweetalert2/src/sweetalert2.scss';

import * as AppActions from 'actions/application';
import * as ApplicationActions from 'actions/application';
import * as MusicianActions from 'actions/musicianJobs';
import * as NewTrackActions from 'actions/newTracks';
// import * as PlaylistActions from 'actions/playlist';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import * as MusicianJobCreators from 'creators/musicianJobs';
import { Api } from 'enums/api';
import IApp, { IAppTrack } from 'interfaces/IApp';
import { INewTrack } from 'interfaces/INewTrack';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { appById$, appIds$, current_playlist$, numberOfNewAddedTracks$, playlist_id$, playlists$ } from 'selectors/applications';
import { current_job_id$, job$ } from 'selectors/musicianJobs';
import { all$, filterBy$ } from 'selectors/newTrack';
import { catchedErrorNotification } from 'utils/error';

import { fetchTracks } from './newTracks';

export const createPlaylist = (tracks_ids: IApp['tracks'], job_id?: number, description?: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ApplicationActions.setAppPending());
    const tracks_id = tracks_ids.map(tr => tr.track_id);
    const data = job_id ? { tracks_id, job_id, description } : { tracks_id, description };

    axios
      .post(`${Api.CreateApplication}`, data)
      .then(response => {
        const id = response.data.result.id;
        const newPlaylist = {
          description: description!,
          tracks: tracks_ids,
          id,
          job_id,
          status: 'on_review',
          user_id: 0,
        };
        dispatch(AppActions.changeUnsaved(false));
        successNotification(`Playlist with name ${description} was successfully saved!`);

        if (job_id) {
          dispatch(ApplicationActions.addAppSuccess(newPlaylist, job_id));
        } else {
          dispatch(ApplicationActions.addOneCreatorApplication(id, newPlaylist));
          dispatch(ApplicationActions.setPlaylistId(id));
        }
        dispatch(ApplicationActions.setCurrPlaylist(newPlaylist));
        dispatch(ApplicationActions.setCurrInitPlaylist(newPlaylist));
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchApplicationsByJobId = (job_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ApplicationActions.setAppPending());

    axios
      .post(`${Api.GetPlaylistsByJobId}`, {
        job_id,
      })
      .then(response => {
        const playlists = response.data.result;
        dispatch(ApplicationActions.getAppSuccess(playlists, job_id));
        if (playlists.length > 0) {
          dispatch(AppActions.setCurrPlaylist(playlists[0]));
          dispatch(AppActions.setCurrInitPlaylist(playlists[0]));
          dispatch(AppActions.setPlaylistId(playlists[0].id));
        } else {
          dispatch(AppActions.setCurrPlaylist(null));
          dispatch(AppActions.setCurrInitPlaylist(null));
          dispatch(AppActions.setPlaylistId(null));
        }
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deletePlaylistById = (id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ApplicationActions.setAppPending());

    axios
      .post(`${Api.DeletePlaylistApp}`, {
        id,
      })
      .then(response => {
        console.log(response);
        dispatch(ApplicationActions.deletePlaylist(id));
      })
      .then(() => {
        const playlists = playlists$(getState());
        const appIds = appIds$(getState());
        successNotification('Playlist was successfully deleted!');

        if (appIds?.length) {
          dispatch(fetchTracksToPlaylistById(Number(appIds[0])) as any);
        } else {
          dispatch(AppActions.setCurrPlaylist(null));
          dispatch(AppActions.setPlaylistId(null));
        }
        if (playlists.length > 0) {
          dispatch(AppActions.setCurrPlaylist(playlists[0]));
        }
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updatePlaylist = (tracks: IApp['tracks'], id: number, track_id: number, isCreator?: boolean, tracks_?: IApp['tracks']) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ApplicationActions.setAppPending());
    const tracks_id = [track_id];
    const playlists = playlists$(getState());
    const index = playlists.findIndex(pl => pl.id === id);
    const job = job$(getState());

    const appById = appById$(getState());
    const playlist_id = playlist_id$(getState());

    const creatorTracks = playlist_id ? appById[playlist_id!]?.tracks?.map(tr => Number(tr.track_id)) : [];
    axios
      .post(`${Api.UpdatePlaylistApp}`, {
        id,
        tracks_id: !isCreator ? tracks_id : creatorTracks!,
      })
      .then(() => {
        if (isCreator) {
          const trackType = filterBy$(getState());

          dispatch(ApplicationActions.saveAppChanges(id));
          dispatch(fetchTracks(trackType, false, 80, 0) as any);
        }
        if (index >= 0) {
          const newPl = [...playlists];
          newPl[index].tracks = !isCreator ? tracks : tracks_!;
          dispatch(MusicianActions.changeAllowedTrackAmount(job!.tracks_allowed_amount! - 1, job!.id));
          dispatch(ApplicationActions.updatePlaylist(newPl, newPl[index]));
        }
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const submitUpdatePlaylist = (isNeedToUpgradePlaylist?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ApplicationActions.setAppPending());

    const curPlaylist = current_playlist$(getState());

    const id = curPlaylist?.id;
    const tracks_id = curPlaylist?.tracks.map(tr => tr.track_id);
    const job_id = current_job_id$(getState());
    axios
      .post(`${Api.UpdatePlaylistApp}`, {
        id,
        tracks_id,
      })
      .then(() => {
        dispatch(ApplicationActions.updateAddedNumberOfTracks(0));
        dispatch(ApplicationActions.setAppLoaded());
        dispatch(ApplicationActions.setCurrInitPlaylist(curPlaylist!));

        dispatch(MusicianJobCreators.updateJobById(job_id!) as any);

        if (isNeedToUpgradePlaylist) {
          dispatch(fetchApplicationsByJobId(job_id!) as any);
        }
        successNotification('You have successfully submitted tracks!');
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const updatePlaylistLocal = (track: { track_id: number; title: string }, action: 'add' | 'delete', isNew?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const currPlaylist = { ...current_playlist$(getState()) } as IApp | null;

    const job = job$(getState());
    const processing = () => {
      if (action === 'add') {
        const addSum = job!.tracks_allowed_amount! - 1;
        if (
          addSum >= 0 &&
          !job?.is_exceeded_by_job_limit &&
          currPlaylist!.tracks?.length! <= job?.tracks_per_user_limit! &&
          !job?.is_exceeded_by_subscription_limit
        ) {
          currPlaylist!.tracks = [...currPlaylist!.tracks, track];

          dispatch(MusicianActions.changeAllowedTrackAmount(addSum, job!.id));

          dispatch(ApplicationActions.updateAddedNumberOfTracks(numberOfNewAddedTracks$(getState()) + 1));

          const tracks = [...all$(getState())];
          const index = tracks.findIndex(tr => tr.track_id === track.track_id);
          if (index >= 0) {
            tracks[index].isNew = true;
            dispatch(NewTrackActions.getAllTracks(tracks));
          }
        } else {
          successNotification('Job limitation!');
          return;
        }
      } else {
        const deleteSum = job!.tracks_allowed_amount! + 1;
        dispatch(MusicianActions.setCurrentMusicianJob({ ...job!, is_exceeded_by_job_limit: false }));
        dispatch(MusicianActions.changeAllowedTrackAmount(deleteSum, job!.id));
        currPlaylist!.tracks = currPlaylist!.tracks.filter(tr => tr.track_id !== track.track_id);
        if (isNew) {
          dispatch(ApplicationActions.updateAddedNumberOfTracks(numberOfNewAddedTracks$(getState()) - 1));
        }
      }
    };

    processing();
    dispatch(ApplicationActions.setCurrPlaylist(currPlaylist));
  };
};

export const deleteTrackFromPlaylist = (tracks: IApp['tracks'], id: number, track_id: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ApplicationActions.setAppPending());

    const playlists = playlists$(getState());
    const index = playlists.findIndex(pl => pl.id === id);

    axios
      .post(`${Api.DeleteTrackPlaylistApp}`, {
        id,
        tracks_id: [track_id],
      })
      .then(() => {
        if (index >= 0) {
          const newPl = [...playlists];
          newPl[index].tracks = tracks;
          const job = job$(getState());
          dispatch(ApplicationActions.updatePlaylist(newPl, newPl[index]));
          dispatch(MusicianActions.changeAllowedTrackAmount(job!.tracks_allowed_amount! + 1, job!.id));
        }
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchShareableLinks = (id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ApplicationActions.setAppPending());

    axios
      .post(`${Api.GetShareablesLinksApp}`, {
        id,
      })
      .then(response => {
        dispatch(ApplicationActions.setShareableLinks(response.data.result));
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const createShareableLink = (id: number, allowed_all_tracks: boolean, allowed_single_track: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ApplicationActions.setAppPending());

    axios
      .post(`${Api.CreateShareableLinkApp}`, {
        id,
        allowed_all_tracks,
        allowed_single_track,
      })
      .then(response => {
        dispatch(ApplicationActions.addShareableLink(response.data.result));
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchDefaultPlaylist = (job_id: number) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ApplicationActions.setAppPending());

    axios
      .post(Api.GetDefaultPLaylist, {
        job_id,
      })
      .then(response => {
        dispatch(ApplicationActions.setCurrPlaylist(response.data.result));
        dispatch(ApplicationActions.setCurrInitPlaylist(response.data.result));
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchCreatorPlaylists = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ApplicationActions.setAppPending());

    axios
      .post(Api.Playlists)
      .then(response => {
        dispatch(ApplicationActions.addCreatorApplications(response.data.result));
      })
      .then(() => {
        const appIds = appIds$(getState());
        const playlist_id = playlist_id$(getState());
        const id = playlist_id ? playlist_id : appIds?.length ? Number(appIds[0]) : null;
        if (playlist_id || id) {
          dispatch(AppActions.setPlaylistId(playlist_id || id));
          dispatch(fetchTracksToPlaylistById((playlist_id || id) as number) as any);
        }
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchTracksToPlaylistById = (pl_id?: number, withoutRefreshTracks?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ApplicationActions.setAppPending());
    dispatch(AppActions.changeUnsaved(false));
    const playlist_id = playlist_id$(getState());
    const id = pl_id ? pl_id : playlist_id!;

    axios
      .post(Api.GetTracksToPlaylist, { id })
      .then(response => {
        const appById = appById$(getState());
        const currPlaylist = id ? appById[id] : ({} as IApp);
        const newPlaylist: IApp = {
          ...currPlaylist,
          tracks: response.data.result,
        };

        const ids = response?.data?.result?.map((tr: INewTrack) => tr.track_id);

        if (newPlaylist?.description) {
          dispatch(ApplicationActions.addOneCreatorApplication(id, newPlaylist));
        } else {
          dispatch(AppActions.setPlaylistId(null));
          if (!withoutRefreshTracks) {
            dispatch(NewTrackActions.setSelectedTracks(ids || []));
            dispatch(fetchCreatorPlaylists() as any);
            dispatch(NewTrackActions.getNewTracksForPlaylist([]));
          }
        }

        if (response.data?.result?.length) {
          const idsOfTracks = response.data.result.map((tr: IAppTrack) => tr.track_id);

          let newTracksForPlaylist = all$(getState()).filter(track => idsOfTracks.includes(track.track_id!));

          if (!withoutRefreshTracks) {
            dispatch(NewTrackActions.getNewTracksForPlaylist(newTracksForPlaylist));
          }
        } else {
          if (!withoutRefreshTracks) {
            dispatch(NewTrackActions.getNewTracksForPlaylist([]));
          }
        }
      })
      .catch(error => {
        dispatch(ApplicationActions.setAppFail());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
