import * as Const from '../constants/index';
import State from '../interfaces/state/MessageState';

const initState: State = {
  messages: [
    {
      message: 'This is simple message',
      isRead: false,
      from: 'Sony',
      time: '14.12.2019',
      id: 0,
    },
    {
      message: 'This is simple message from us to you. We need...',
      isRead: true,
      from: 'Sony',
      time: '15.12.2019',
      id: 1,
    },
    {
      message: 'This is simple message',
      isRead: false,
      from: 'Sony',
      time: '14.12.2019',
      id: 2,
    },
    {
      message: 'This is simple message from us to you. We need...',
      isRead: true,
      from: 'Sony',
      time: '15.12.2019',
      id: 3,
    },
    {
      message: 'This is simple message',
      isRead: false,
      from: 'Sony',
      time: '14.12.2019',
      id: 4,
    },
    {
      message: 'This is simple message from us to you. We need...',
      isRead: true,
      from: 'Sony',
      time: '15.12.2019',
      id: 5,
    },
  ],
  isLoading: false,
  isError: false,
};

export default function message(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.MESSAGES_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.MESSAGES_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.MESSAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.MESSAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        messages: action.payload,
      };

    case Const.MESSAGE_IS_READED:
      const messages = [...state.messages];
      const messageIndex = messages.findIndex(message => message.id === action.id);
      if (messageIndex >= 0) {
        messages[messageIndex].isRead = true;
        return {
          ...state,
          messages: messages,
        };
      } else {
        return { ...state };
      }

    default:
      return state;
  }
}
