import 'react-h5-audio-player/lib/styles.css';

import './style.scss';

import * as MusicianPlayerActions from 'actions/musicianPlayer';
import * as SongActions from 'actions/song';
import classNames from 'classnames/bind';
import AudioInformation from 'components/audioInformation/audioInformation';
import { highlightTrack } from 'creators/newTracks';
import MusicianPlayerState from 'interfaces/state/MusicianPlayerState';
import NewTrackState from 'interfaces/state/NewTracksState';
import PlaylistState from 'interfaces/state/PlaylistState';
import SongState from 'interfaces/state/SongState';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EventListener from 'react-event-listener';
import AudioPlayer from 'react-h5-audio-player';
import { connect, useDispatch } from 'react-redux';
import { currentURL$, isPause$ } from 'selectors/musicianPlayer';
import { all$, isLoading$, orderedTracks$ } from 'selectors/newTrack';
import { isPlayFromTrackPanel$, titleInBase$ } from 'selectors/playlist';
import { autoPlayAfterSrcChange$, currIndex$, isPlay$, muted$ } from 'selectors/song';

import styles from './audioPanel.module.scss';

interface IStateProps {
  isPlay: SongState['isPlay'];
  isPause: MusicianPlayerState['isPause'];
  currIndex: SongState['currIndex'];
  isPlayFromTrackPanel: PlaylistState['isPlayFromTrackPanel'];
  titleInBase: PlaylistState['titleInBase'];
  orderedTracks: NewTrackState['tracks'];
  currentURL: MusicianPlayerState['url'];
  autoPlayAfterSrcChange: SongState['autoPlayAfterSrcChange'];
  muted: SongState['muted'];
  allTracks: NewTrackState['tracks'];
  isLoading: boolean;
}

interface OwnProps {
  className?: string;
  isCreator?: boolean;
  isFullWidth?: boolean;
  isNeedRating: boolean;
}

interface IDispatchProps {
  setSongPlay: (a: boolean) => void;
  setMusPlayerPause: (value: boolean) => void;
  setMusPlayerPlay: (value: boolean) => void;
}
interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const AudioPanel = ({
  isPlay,
  currIndex,
  isPlayFromTrackPanel,
  titleInBase,
  className,
  isNeedRating,
  orderedTracks,
  isFullWidth,
  currentURL,
  isPause,
  autoPlayAfterSrcChange,
  muted,
  isCreator,
  allTracks,
  isLoading,
  setMusPlayerPause,
  setMusPlayerPlay,
  setSongPlay,
}: IProps) => {
  let ref = useRef<AudioPlayer>(null);

  const dispatch = useDispatch();
  const memoUrl = useMemo(() => currentURL, [currentURL]);
  const [src, setSRC] = useState('');
  const handleLeftKeyDown = (e: KeyboardEvent) => {
    const prevButton: HTMLElement = document.querySelector('.rhap_rewind-button') as HTMLElement;

    if (e.key === 'ArrowLeft') {
      prevButton.click();
    }
  };

  const onToggleSongPlay = (e: KeyboardEvent) => {
    if (e.code === 'Space') {
      setSongPlay(!isPlay);
    }
  };

  const handleRightKeyDown = (e: KeyboardEvent) => {
    const nextButton: HTMLElement = document.querySelector('.rhap_forward-button') as HTMLElement;

    if (e.key === 'ArrowRight') {
      nextButton.click();
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    handleLeftKeyDown(e);
    handleRightKeyDown(e);
    onToggleSongPlay(e);
  };

  const layerClassNames = classNames('d-none', {
    'blocked-audio-layer d-flex align-center justify-center':
      (!orderedTracks.length && !isCreator) || (!allTracks.length && isCreator && !isLoading),
  });

  const containerClassNames = classNames('audio-panel d-flex align-center', {
    [styles.fullWidth]: isFullWidth,
  });

  const currentTrackID =
    orderedTracks &&
    orderedTracks.length &&
    currIndex !== null &&
    currIndex in orderedTracks &&
    orderedTracks[currIndex].track_id !== undefined
      ? orderedTracks[currIndex].track_id
      : null;

  const allTracksTrackId =
    allTracks?.length && currIndex !== null && currIndex in allTracks && allTracks[currIndex].track_id !== undefined
      ? allTracks[currIndex].track_id
      : null;

  const currentTitle = isPlayFromTrackPanel
    ? orderedTracks?.length && currIndex !== null && currIndex in orderedTracks && orderedTracks[currIndex].title !== undefined
      ? orderedTracks[currIndex].title
      : titleInBase
    : titleInBase;

  const currentAllTracksTitle = isPlayFromTrackPanel
    ? allTracks?.length && currIndex !== null && currIndex in allTracks && allTracks[currIndex].title !== undefined
      ? allTracks[currIndex].title
      : titleInBase
    : titleInBase;

  const onPause = (_e: Event) => {
    setMusPlayerPause(true);
    setMusPlayerPlay(false);
  };

  const onPlay = (_e: Event) => {
    setMusPlayerPause(false);
    setMusPlayerPlay(true);

    if (!isCreator && trackId) {
      if (currIndex !== null && !orderedTracks?.[currIndex]?.listened) dispatch(highlightTrack(trackId));
    }
    if (currIndex === null && orderedTracks?.length) {
      dispatch(SongActions.setCurrSongIndex(0));
    }
  };

  useEffect(() => {
    if (memoUrl) {
      setSRC(memoUrl!);
    }
  }, [isPlay, isPause, memoUrl, currIndex]);

  const message = useMemo(() => {
    if (isLoading && isCreator) {
      return 'Loading your tracks, please wait';
    } else if (isLoading && !isCreator) {
      return 'Loading tracks, please wait';
    } else if (!isLoading && isCreator && !allTracks.length) {
      return 'For creating an playlist you need to upload tracks';
    } else {
      return 'Sorry, but we can’t find tracks on this job';
    }
  }, [isLoading, isCreator, allTracks.length]);

  const trackId = isCreator ? allTracksTrackId : currentTrackID;
  const title = useMemo(() => (isCreator ? currentAllTracksTitle : currentTitle), [isCreator, currentAllTracksTitle, currentTitle]);
  return (
    <section className={containerClassNames}>
      <div className={layerClassNames}>{message} </div>
      <EventListener target={window} onKeyDown={onKeyDown} />
      <AudioInformation
        currIndex={currIndex ? currIndex : 0}
        tracks={orderedTracks}
        isNeedRating={isNeedRating}
        currtrackId={trackId}
        title={title}
      />
      <div className={styles.player}>
        <AudioPlayer
          autoPlayAfterSrcChange={autoPlayAfterSrcChange}
          ref={ref}
          muted={muted}
          progressUpdateInterval={50}
          className={className ? `${className} player-panel` : ''}
          loop={false}
          layout="horizontal-reverse"
          src={src}
          onPause={onPause}
          onPlay={onPlay}
          preload="auto"
          volume={0.4}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isPlay: isPlay$(state),
    isPause: isPause$(state),
    currIndex: currIndex$(state),
    isPlayFromTrackPanel: isPlayFromTrackPanel$(state),
    titleInBase: titleInBase$(state),
    orderedTracks: orderedTracks$(state),
    currentURL: currentURL$(state),
    autoPlayAfterSrcChange: autoPlayAfterSrcChange$(state),
    muted: muted$(state),
    allTracks: all$(state),
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    setSongPlay: dispatchProps.setSongPlay,
    setMusPlayerPlay: dispatchProps.setMusPlayerPlay,
    setMusPlayerPause: dispatchProps.setMusPlayerPause,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    setSongPlay: SongActions.setSongPlay,
    setMusPlayerPlay: MusicianPlayerActions.setMusPlayerPlay,
    setMusPlayerPause: MusicianPlayerActions.setMusPlayerPause,
  },
  mergeProps
)(AudioPanel);
