import cx from 'classnames';
import { TrackType } from 'interfaces/INewTrack';
// eslint-disable-next-line
import React from 'react';

import style from './trackTypeTabs.module.scss';

interface IProps {
  onAllClick: () => void;
  onMusicClick: () => void;
  onSFXClick: () => void;
  onAudioClick: () => void;
  type: TrackType;
  disabled?: boolean;
}

export const TrackTypeTabs = ({ onAllClick, onMusicClick, onSFXClick, onAudioClick, type, disabled }: IProps) => {
  const musicCX = cx(style.btn, {
    [style.selected]: type === 'music',
    [style.disabled]: disabled,
  });

  const allCX = cx(style.btn, {
    [style.selected]: type === 'all',
    [style.disabled]: disabled,
  });

  const sfxCX = cx(style.btn, {
    [style.selected]: type === 'sfx',
    [style.disabled]: disabled,
  });

  const audioCX = cx(style.btn, {
    [style.selected]: type === 'audio',
    [style.disabled]: disabled,
  });

  return (
    <div className={style.tabs}>
      <button onClick={onAllClick} className={allCX}>
        ALL
      </button>
      <button onClick={onMusicClick} className={musicCX}>
        MUSIC
      </button>
      <button onClick={onSFXClick} className={sfxCX}>
        SFX
      </button>
      <button onClick={onAudioClick} className={audioCX}>
        AUDIO
      </button>
    </div>
  );
};
