import AddRoundedIcon from '@material-ui/icons/AddRounded';
import cx from 'classnames';
import { IconRoundButton } from 'components/iconRoundButton';
import { InputPopup } from 'components/InputPopup';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import * as TeamCreators from 'creators/team';
import * as TeamInviteCreators from 'creators/teamInvites';
import State from 'interfaces/state/State';
import { ITeamInvite } from 'interfaces/state/TeamInviteState';
import { ITeam } from 'interfaces/state/TeamState';
// eslint-disable-next-line
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { isLoading$, teams$ } from 'selectors/team';
import { teamInvites$ } from 'selectors/teamInvite';

import style from './header.module.scss';

interface IStateProps {
  teams: ITeam[];
  teamInvites: ITeamInvite[];
  isLoading: boolean;
}

interface IDispatchProps {
  createTeamByName: (name: string) => void;
  fetchTeamList: (isCreator?: boolean) => void;
  fetchTeamInviteList: (isManager?: boolean) => void;
}

interface OwnProps {
  setHideTeams: (val: boolean) => void;
  isHideTeams: boolean;
  isCreator?: boolean;
}

interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const HeaderComponent = ({
  teams,
  fetchTeamInviteList,
  createTeamByName,
  fetchTeamList,
  setHideTeams,
  isHideTeams,
  teamInvites,
  isCreator,
  isLoading,
}: IProps) => {
  const memoTeams = useMemo(() => teams, [teams]);

  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState('');

  const handleOpen = (value: boolean) => {
    setOpen(value);
  };

  const handleSetTeamName = (value: string | number) => setName(value as string);
  const handleCreate = () => {
    if (!name) {
      successNotification('Fill in the input field Name');
      return;
    }

    createTeamByName(name);
    setOpen(false);
  };

  const onOpen = () => {
    handleOpen(true);
  };

  const onClose = () => {
    handleOpen(false);
  };

  useEffect(() => {
    if (isHideTeams) {
      fetchTeamInviteList(!isCreator);
    } else {
      if (isLoading) return;
      fetchTeamList(isCreator);
    }
  }, [isHideTeams, isCreator]);

  const onTeamClick = () => setHideTeams(false);
  const onInvitationClick = () => setHideTeams(true);

  const TeamsCX = cx(style.actionBtn, {
    [style.selected]: !isHideTeams,
  });

  const InvitationCX = cx(style.actionBtn, {
    [style.selected]: isHideTeams,
  });

  const title = isHideTeams ? 'Invitations' : 'Teams';
  const count = isHideTeams ? teamInvites.length : memoTeams.length;

  return (
    <header className={style.root}>
      <h4 className={style.title}>
        {title} - {count}
      </h4>
      <div className={style.actions}>
        <button className={TeamsCX} onClick={onTeamClick}>
          TEAMS
        </button>
        {!isCreator && (
          <button className={InvitationCX} onClick={onInvitationClick}>
            INVITATIONS
          </button>
        )}
      </div>
      {!isCreator && (
        <Popup
          onOpen={onOpen}
          onDecline={onClose}
          isOpen={isOpen}
          btnClassName={style.btnContainer}
          onApply={handleCreate}
          isVisibleOverflow
          buttonText={
            <IconRoundButton
              icon={
                <div className={style.button}>
                  <span className={style.btnContent}>Create new Team</span>
                  <AddRoundedIcon color="action" className={style.actionIcon} />
                </div>
              }
              tooltipText="Create your new Team"
              className="avatar_icon"
              style={{ padding: 5 }}
            />
          }
          applyBtnText={'CREATE TEAM'}
          modalContent={
            <div className="d-flex flex-column">
              <div className={style.label}>Set new team name</div>
              <InputPopup type="text" title="Name" onChange={handleSetTeamName} value={name} maxLength={400} />
            </div>
          }
        />
      )}
    </header>
  );
};

const mapStateToProps = (state: State) => {
  return {
    teams: teams$(state),
    teamInvites: teamInvites$(state),
    isLoading: isLoading$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    createTeamByName: dispatchProps.createTeamByName,
    fetchTeamList: dispatchProps.fetchTeamList,
    fetchTeamInviteList: dispatchProps.fetchTeamInviteList,
    ...props,
  };
};

export const Header = connect(
  mapStateToProps,
  {
    createTeamByName: TeamCreators.createTeamByName,
    fetchTeamList: TeamCreators.fetchTeamList,
    fetchTeamInviteList: TeamInviteCreators.fetchTeamInviteList,
  },
  mergeProps
)(HeaderComponent);
