import { Action } from 'redux';

import * as Const from '../constants';

export const setShareablePlaylistInfoInit = (): Action => {
  return {
    type: Const.SH_PLAYLIST_INFO_INIT,
  };
};

export const setShareablePlaylistInfoSuccess = (): Action => {
  return {
    type: Const.SH_PLAYLIST_INFO_SUCCESS,
  };
};

export interface IShareablePlaylistInfo {
  allowed_all_tracks: boolean;
  allowed_single_track: boolean;
  playlist_id?: null | number;
  application_id?: null | number;
  link_id: number | null;
  background?: string | null;
  owner?: boolean;
}
export const setShareablePlaylistInfoReceive = (payload: IShareablePlaylistInfo) => {
  return {
    type: Const.SH_PLAYLIST_INFO_RECEIVE,
    payload,
  };
};

export const setShareablePlaylistInfoError = (): Action => {
  return {
    type: Const.SH_PLAYLIST_INFO_ERROR,
  };
};

export const setShareablePlaylistInfoPending = (): Action => {
  return {
    type: Const.SH_PLAYLIST_INFO_PENDING,
  };
};

export const setPlaylistBg = (payload: string | null) => {
  return {
    type: Const.SH_PLAYLIST_BG,
    payload,
  };
};
