// eslint-disable-next-line
import React, { Component, FC } from 'react';

import styles from './routePage.module.scss';

interface IProps {
  component: Component | FC | JSX.Element;
}

export const RoutePage = ({ component }: IProps) => {
  return (
    <section className={styles.root}>
      <div className={styles.content}>{component}</div>
    </section>
  );
};
