import * as Const from '../constants';
import State from '../interfaces/state/SubscriptionList';

const initState: State = {
  list: [],
  isLoading: false,
  isError: false,
  selectedSubscriptionId: null,
  period: false,
};

export default function subscriptionList(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SUBS_LIST_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.SUBS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SUBS_LIST_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SUBS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.SUBS_LIST_ID:
      return {
        ...state,
        selectedSubscriptionId: action.payload,
      };

    case Const.SUBS_LIST_PERIOD:
      return {
        ...state,
        period: action.payload,
      };

    default:
      return state;
  }
}
