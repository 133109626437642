import { IMasterOwner, INewTrack } from 'interfaces/INewTrack';
import { ITrackPublisher, ITrackWriter } from 'interfaces/state/TrackInfoState';
// eslint-disable-next-line
import React, { MouseEvent } from 'react';

import styles from './musicianTrackPopoverContent.module.scss';

interface IProps {
  track: INewTrack;
}

export const MusicianTrackPopoverContent = ({ track }: IProps) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.publisherContainer}>
        <div className={styles.title}>Publishers</div>
        <div className={styles.header}>
          <div className={styles.name}>Name</div>
          <div className={styles.ipi}>IPI</div>
          <div className={styles.percentage}>Percentage</div>
          <div className={styles.pro}>PRO</div>
        </div>
        {track.publishers?.map((publisher: ITrackPublisher, index: number) => (
          <div key={index} className={styles.content}>
            <div className={styles.name} title={publisher.name}>
              {publisher.name}
            </div>
            <div className={styles.ipi} title={publisher.ipi}>
              {publisher.ipi}
            </div>
            <div className={styles.percentage} title={`${publisher.percentage}`}>
              {publisher.percentage}
            </div>
            <div className={styles.pro} title={publisher.pro}>
              {publisher.pro}
            </div>
          </div>
        ))}

        <div className={styles.title}>Master owners</div>
        <div className={styles.header}>
          <div className={styles.name}>Name</div>
          <div className={styles.percentage}>Percentage</div>
        </div>
        {track.master_owners?.map((mOwner: IMasterOwner, index: number) => (
          <div key={index} className={styles.content}>
            <div className={styles.name} title={mOwner.name}>
              {mOwner.name}
            </div>
            <div className={styles.percentage} title={`${mOwner.percentage}`}>
              {mOwner.percentage}
            </div>
          </div>
        ))}
        <div className={styles.title}>Writers</div>
        <div className={styles.header}>
          <div className={styles.name}>First Name</div>
          <div className={styles.name}>Last Name</div>
          <div className={styles.ipi}>IPI</div>
          <div className={styles.percentage}>Percentage</div>
          <div className={styles.pro}>PRO</div>
        </div>
        {track.writers?.map((writer: ITrackWriter, index: number) => (
          <div key={index} className={styles.content}>
            <div className={styles.name} title={writer.first_name}>
              {writer.first_name}
            </div>
            <div className={styles.name} title={writer.last_name}>
              {writer.last_name}
            </div>
            <div className={styles.ipi} title={writer.ipi}>
              {writer.ipi}
            </div>
            <div className={styles.percentage} title={`${writer.percentage}`}>
              {writer.percentage}
            </div>
            <div className={styles.pro} title={writer.pro}>
              {writer.pro}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
