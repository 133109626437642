import 'sweetalert2/src/sweetalert2.scss';

import axios from 'axios';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { email$ } from 'selectors/help';
import { catchedErrorNotification } from 'utils/error';

import * as HelpActions from '../actions/help';

export const fetchHelp = () => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(HelpActions.setHelpPending());

    axios
      .post(`${Api.Help}`, {
        email: email$(getState()),
      })
      .then(() => {
        dispatch(HelpActions.setHelpSuccess());
      })
      .catch(error => {
        dispatch(HelpActions.setHelpError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
