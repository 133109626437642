import { SearchTracks } from 'containers/searchTracks';
import { TrackType } from 'interfaces/INewTrack';
// eslint-disable-next-line
import React from 'react';

import style from './searchTracks.module.scss';

interface IProps {
  type: TrackType;
  search: string;
  setSearch: (search: string) => void;
  isNotNeedToUpdate?: boolean;
  setNotNeedToUpdate: (isNotNeedToUpdate: boolean) => void;
}

export const SearchTracksWrapper = ({ type, search, setSearch, isNotNeedToUpdate, setNotNeedToUpdate }: IProps) => {
  return (
    <div className={style.root}>
      <SearchTracks
        search={search}
        onSearchChange={setSearch}
        type={type}
        isNotNeedToUpdate={isNotNeedToUpdate}
        setNotNeedToUpdate={setNotNeedToUpdate}
      />
    </div>
  );
};
