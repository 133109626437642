import { Tooltip } from '@material-ui/core';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import first500Badge from 'assets/img/badge.png';
import { ReactComponent as ApproveIcon } from 'assets/img/check.svg';
import downloads from 'assets/img/counters/downloads.png';
import plays from 'assets/img/counters/plays.png';
import shares from 'assets/img/counters/shares.png';
import uploads from 'assets/img/counters/uploads.png';
import { ReactComponent as NotApproveIcon } from 'assets/img/do-not-enter.svg';
import { ReactComponent as InfoIcon } from 'assets/img/information.svg';
import cx from 'classnames';
import classNames from 'classnames/bind';
import { IconRoundButton } from 'components/iconRoundButton';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import Popup from 'components/popup/popup';
import * as AdminCreators from 'creators/admin';
import * as UpproveCreators from 'creators/updateApprove';
import { IUser } from 'interfaces/IUser';
import State from 'interfaces/state/State';
import UpdateApproveState from 'interfaces/state/UpdateApproveState';
import moment from 'moment';
// eslint-disable-next-line
import React, { memo, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';
import { admin$, admins$, creator$, creators$, isLoading$ as isLoadingUserCategory$, manager$, managers$ } from 'selectors/admin';
import { isAuth$, isLoading$ as isAuthLoading$, userName$ } from 'selectors/auth';
import { DATE_FORMAT } from 'utils';

import style from './tableItem.module.scss';

interface IStateProps {
  userName: string | null;
}
interface IDispatchProps {
  deleteUserFromAdmin: (user: string, type: string) => void;
  handleBlockUserFromAdmin: (user: string, type: string, isBlock: boolean) => void;
  updateHiddenSubscription: (email: string, subscription: 'Starter-Free' | 'Premium-Free' | 'Free', type: string) => void;
  confirmUserEmail: (email: string, type: string) => void;
  updateApprove: (user_to_approve: string, is_approved: boolean, userType: string) => void;
  udpateUserRating: (email: string, new_rating: number, type: 'creators' | 'admins' | 'managers') => void;
  sendToHold: (email: string, on_hold: boolean, type: string) => void;
}
interface OwnProps {
  item: IUser;
  type: string;
  adminType: 'creator' | 'manager' | 'admin';
  approve?: UpdateApproveState['approve'];
  isOnHold: boolean;
}

interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const UserTableItemComponent = ({
  approve,
  userName,
  item,
  type,
  adminType,
  isOnHold,
  handleBlockUserFromAdmin,
  deleteUserFromAdmin,
  updateHiddenSubscription,
  confirmUserEmail,
  updateApprove,
  udpateUserRating,
  sendToHold,
}: IProps) => {
  const memoItem = useMemo(() => item, [item]);
  const [isOpen, setIsOpen] = useState(false);
  const [tempSubscription, setTempSubscription] = useState(null as null | 'Starter-Free' | 'Premium-Free' | 'Free');

  const disabledClassNames = classNames({
    [style.redBadge]: memoItem.is_disabled,
    [style.greenBadge]: !memoItem.is_disabled,
  });

  const blockBtnClassNames = classNames([style.btn], {
    [style.greenBtn]: memoItem.is_disabled,
    [style.redBtn]: !memoItem.is_disabled,
  });

  const subscriptionValue = { label: memoItem?.subscription, value: memoItem?.subscription } as ISelectItem;

  const handleBlockUser = () => {
    if (memoItem.is_disabled) {
      handleBlockUserFromAdmin(memoItem.email, type, false);
    } else {
      handleBlockUserFromAdmin(memoItem.email, type, true);
    }
  };

  const handleSendOnHold = () => {
    sendToHold(memoItem.email, !isOnHold, type);
  };

  const renderDefaultIcon = () => {
    const icon = {
      creators: <PersonOutlineRoundedIcon className={style.defaultAvatar} />,
      managers: <AccountBoxRoundedIcon className={style.defaultAvatar} />,
      admins: <GroupRoundedIcon className={style.defaultAvatar} />,
    };

    return icon[type];
  };

  const approveIconCX = classNames(style.approveIcon, {
    [style.disabled]: approve?.[memoItem.email] && approve?.[memoItem.email].isLoading,
  });

  const infoIconCX = classNames(style.infoIcon, {
    [style.disabled]: !memoItem.link_1,
  });

  const setStarRating = useCallback(
    (rating: number) => {
      if (udpateUserRating) {
        udpateUserRating(memoItem.email, rating, type as 'creators' | 'admins' | 'managers');
      }
    },
    [memoItem.email, udpateUserRating, type]
  );

  const subscriptionList = [
    { value: 'Free', label: 'Free' },
    { value: 'Starter-Free', label: 'Starter-Free' },
    { value: 'Premium-Free', label: 'Premium-Free' },
  ] as ISelectItem[];

  const handleSubscrChange = (val: string | number) => {
    setTempSubscription(val as 'Starter-Free' | 'Premium-Free' | 'Free');
    if (val !== memoItem.subscription) {
      setIsOpen(true);
    }
  };

  const onDelete = useCallback(() => deleteUserFromAdmin(memoItem.email, type), [deleteUserFromAdmin, memoItem.email, type]);

  // eslint-disable-next-line
  const onUpprove = useCallback(() => updateApprove(memoItem.email, !memoItem.is_approved, type), [
    updateApprove,
    memoItem.email,
    memoItem.is_approved,
    memoItem,
  ]);

  // eslint-disable-next-line
  const onConfirm = useCallback(() => confirmUserEmail(memoItem.email, type), [confirmUserEmail, memoItem.email, type]);

  const setDefaultForSubscription = () => {
    setIsOpen(false);
    setTempSubscription(null);
  };

  const renderStarIcon = () => <span className={style.star}>★</span>;
  const onApplyUpdateSubscr = () => {
    updateHiddenSubscription(memoItem.email, tempSubscription!, type);
    setDefaultForSubscription();
  };

  const holdText = isOnHold
    ? 'Are you sure that you want to remove user from hold list'
    : 'Are you sure that you want to add user to hold list?';

  const actionsCX = cx(style.action, {
    [style.selected]: adminType !== 'creator',
  });

  const rootCX = cx(style.root, {
    [style.rootAllUsers]: adminType !== 'creator',
  });

  return (
    <section className={rootCX}>
      <div className={style.avatarWrap}>
        {!!memoItem.avatar ? <img className={style.image} src={memoItem.avatar} alt="avatar" /> : renderDefaultIcon()}
      </div>
      <div className={style.userName}>
        <div className={style.top}>
          {memoItem?.is_first_500 && (
            <Tooltip title="This user is one of the first 500 who bought a paid subscription">
              <img src={first500Badge} className={style.first500Badge} alt={'User badge'} />
            </Tooltip>
          )}
          {memoItem.user_name}
        </div>
        <div className={style.created}>
          <b>Created: </b>
          {moment(memoItem.created).format(DATE_FORMAT)}
        </div>
        {adminType === 'creator' && (
          <div className={style.bottom}>
            <StarRatingComponent
              name="rate1"
              starCount={10}
              value={memoItem.user_rating ? memoItem.user_rating : 0}
              onStarClick={setStarRating}
              emptyStarColor="#EEF2F4"
              renderStarIcon={renderStarIcon}
            />
          </div>
        )}
      </div>
      <div className={style.email}>{memoItem.email}</div>
      <div className={style.isDisabled}>
        <span className={disabledClassNames}>{memoItem.is_disabled ? 'Locked' : 'Unlocked'}</span>
      </div>
      {adminType === 'creator' && (
        <div className={style.stat}>
          <div className={style.statWrap}>
            <div className={style.title}>
              <img src={plays} className={style.icon} alt="counter" title={'plays'} />
            </div>
            <div className={style.value}>{`${memoItem?.stat?.plays_count}`}</div>
          </div>
          <div className={style.statWrap}>
            <div className={style.title}>
              <img src={shares} className={style.icon} alt="counter" title="shares" />
            </div>
            <div className={style.value}>{`${memoItem?.stat?.share_count}`}</div>
          </div>
          <div className={style.statWrap}>
            <div className={style.title}>
              <img src={uploads} className={style.icon} alt="counter" title="uploads" />
            </div>
            <div className={style.value}>{`${memoItem?.stat?.upload_count}`}</div>
          </div>
          <div className={style.statWrap}>
            <div className={style.title}>
              <img src={downloads} className={style.icon} alt="counter" title="downloads" />
            </div>
            <div className={style.value}>{`${memoItem?.stat?.download_count}`}</div>
          </div>
        </div>
      )}
      <div className={actionsCX}>
        {adminType === 'creator' && (
          <div className="d-flex flex-column">
            <div className={style.label}>Subscription</div>
            <MaterialSelect
              value={subscriptionValue}
              placeholder="Subscription"
              selectItems={subscriptionList}
              onChange={handleSubscrChange}
              className={style.selectBuyer}
              width={140}
            />
          </div>
        )}

        <Popup
          onApply={handleSendOnHold}
          btnClassName={style.btn}
          buttonText={
            <Tooltip title={isOnHold ? 'Remove from hold list' : 'Add to hold list'}>
              <span>
                {!isOnHold ? (
                  <PauseCircleFilledRoundedIcon style={{ color: 'red' }} />
                ) : (
                  <PlayCircleOutlineRoundedIcon style={{ color: 'red' }} />
                )}
              </span>
            </Tooltip>
          }
          modalContent={<div className={style.popup}>{holdText}</div>}
        />

        <Popup
          disabled={memoItem.is_confirmed_email}
          onApply={onConfirm}
          btnClassName={style.btn}
          buttonText={
            <Tooltip title={memoItem.is_confirmed_email ? 'User was confirmed' : 'Confirm user authorization'}>
              <span>
                {memoItem.is_confirmed_email ? <DoneAllRoundedIcon style={{ color: 'green' }} /> : <SyncProblemIcon color="secondary" />}
              </span>
            </Tooltip>
          }
          modalContent={<div className={style.popup}>Are you sure you want to confirm the email of this user?</div>}
        />

        <Popup
          onApply={onApplyUpdateSubscr}
          btnClassName="btn-del-playlist"
          isHideMainButton
          onDecline={setDefaultForSubscription}
          isOpen={isOpen}
          buttonText={''}
          modalContent={
            <div style={{ height: 100 }} className="d-flex align-center flex-column">
              <div className="playlist-title-text">Change subscription for this user</div>
              <div>Are you sure that you want to change current subscription for this user?</div>
            </div>
          }
        />

        <Popup
          disabled={approve?.[memoItem.email] && approve?.[memoItem.email].isLoading}
          onApply={onUpprove}
          btnClassName={style.btn}
          buttonText={
            <Tooltip title={memoItem.is_approved ? `User ${memoItem.user_name} is approved` : `User ${memoItem.user_name} is not approved`}>
              <span>{item.is_approved ? <ApproveIcon className={approveIconCX} /> : <NotApproveIcon className={approveIconCX} />}</span>
            </Tooltip>
          }
          modalContent={
            <div className={style.popup}>{`Are you sure you ${
              !memoItem.is_approved ? 'want to approve' : 'dont want to approve'
            } this user`}</div>
          }
        />
        <Popup
          disabled={!memoItem.link_1}
          onApply={() => ''}
          isHideCancelButton
          applyBtnText={'Ok'}
          btnClassName={style.btn}
          buttonText={
            <Tooltip title={'User information'}>
              <span>{<InfoIcon className={infoIconCX} />}</span>
            </Tooltip>
          }
          modalContent={
            <div className={style.popupLinks}>
              <div className={style.links}>{`${memoItem.user_name} links`}</div>
              <div className={style.linkContainer}>
                <div className={style.linkName}>Link 1:</div>
                <div className={style.linkValue}>
                  <a
                    href={memoItem.link_1!}
                    className={style.link}
                    referrerPolicy="no-referrer-when-downgrade"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {memoItem.link_1}
                  </a>
                </div>
              </div>
              {memoItem.link_2 ? (
                <div className={style.linkContainer}>
                  <div className={style.linkName}>Link 2:</div>
                  <div className={style.linkValue}>
                    <a
                      href={memoItem.link_2!}
                      className={style.link}
                      referrerPolicy="no-referrer-when-downgrade"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {memoItem.link_2}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          }
        />
        <IconRoundButton
          icon={<PowerSettingsNewRoundedIcon />}
          tooltipText={memoItem.is_disabled ? 'Turn off User Blocking' : 'Turn on User Blocking'}
          onClick={handleBlockUser}
          size="small"
          className={blockBtnClassNames}
        />
        <Popup
          disabled={userName === memoItem.user_name}
          onApply={onDelete}
          btnClassName={style.btn}
          buttonText={
            <Tooltip title="Delete this User forever">
              <span>
                <DeleteForeverRoundedIcon color="primary" />
              </span>
            </Tooltip>
          }
          modalContent={
            <div className={style.popup}>
              Are you sure you want to delete this User forever? Doing so will delete any content, stats, metadata and all traces of this
              user
            </div>
          }
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isAuthLoading: isAuthLoading$(state),
    isAuth: isAuth$(state),
    admins: admins$(state),
    creators: creators$(state),
    managers: managers$(state),
    isLoading: isLoadingUserCategory$(state),
    userName: userName$(state),
    creator: creator$(state),
    manager: manager$(state),
    admin: admin$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    deleteUserFromAdmin: dispatchProps.deleteUserFromAdmin,
    handleBlockUserFromAdmin: dispatchProps.handleBlockUserFromAdmin,
    updateHiddenSubscription: dispatchProps.updateHiddenSubscription,
    confirmUserEmail: dispatchProps.confirmUserEmail,
    updateApprove: dispatchProps.updateApprove,
    udpateUserRating: dispatchProps.udpateUserRating,
    sendToHold: dispatchProps.sendToHold,
    ...props,
  };
};

export const UserTableItem = connect(
  mapStateToProps,
  {
    deleteUserFromAdmin: AdminCreators.deleteUserFromAdmin,
    handleBlockUserFromAdmin: AdminCreators.handleBlockUserFromAdmin,
    updateHiddenSubscription: AdminCreators.updateHiddenSubscription,
    confirmUserEmail: AdminCreators.confirmUserEmail,
    updateApprove: UpproveCreators.updateApprove,
    udpateUserRating: AdminCreators.udpateUserRating,
    sendToHold: AdminCreators.sendToHold,
  },
  mergeProps
)(memo(UserTableItemComponent));
