import { setTrackInformationFormat } from 'actions/trackInformation';
import axios from 'axios';
import Popup from 'components/popup/popup';
import { Api } from 'enums/api';
import { INewTrackType } from 'interfaces/INewTrack';
import * as musicMetadata from 'music-metadata-browser';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MusicianEditTrackContent } from './musicianEditTrackContent';
import style from './musicianTrackWithEmptyInfoInside.module.scss';

interface IProps {
  track: Partial<INewTrackType>;
  setInfoAboutTrack: (track_id: number, track: Partial<INewTrackType>, isEdit?: boolean) => void;
  index: number;
}

export const MusicianTrackWithEmptyInfoInside = ({ track, index, setInfoAboutTrack }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [duration, setDuration] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post(`${Api.GetTrackURL}`, {
        track_id: track.track_id,
      })
      .then(response => {
        const trackUrl = response.data.result.url;
        musicMetadata.fetchFromUrl(trackUrl).then(res => {
          dispatch(setTrackInformationFormat(res));
          setDuration(Number(res.format.duration?.toFixed(0)));
        });
      });
    // eslint-disable-next-line
  }, []);

  const toggleOpen = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={style.root}>
      <div className={style.label} onClick={handleOpen}>
        There is no information for this track. Click on this track to add information
      </div>

      <Popup
        isOpen={isOpen}
        isHideButtons
        isHideMainButton
        onOpen={toggleOpen}
        onDecline={toggleOpen}
        btnClassName="mus-track-edit"
        modalContent={
          <MusicianEditTrackContent
            setInfoAboutTrack={setInfoAboutTrack}
            onClosePopup={toggleOpen}
            track={track}
            index={index}
            track_duration={duration}
          />
        }
      />
    </div>
  );
};
