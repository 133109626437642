import 'react-h5-audio-player/lib/styles.css';

import './style.scss';

import { setMusicianPlayerInit } from 'actions/musicianPlayer';
import { setNewTracksInit } from 'actions/newTracks';
import { setSongInit } from 'actions/song';
// eslint-disable-next-line
import React, { useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { useDispatch } from 'react-redux';

import styles from './musicianPlayer.module.scss';

interface IProps {
  title: string;
  currentURL: string | null;
  autoPlayAfterSrcChange: boolean;
}

export const MusicianPlayer = ({ title, currentURL, autoPlayAfterSrcChange }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSongInit());
      dispatch(setNewTracksInit());
      dispatch(setMusicianPlayerInit());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.infoContainer}>
        <div className={styles.title} title={title}>
          {title}
        </div>
      </div>
      <AudioPlayer
        className={'musician_player'}
        showJumpControls={false}
        loop={false}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        src={currentURL ? currentURL : ''}
        preload="auto"
        autoPlayAfterSrcChange={autoPlayAfterSrcChange}
        volume={0.4}
      />
    </div>
  );
};
