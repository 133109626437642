import { IconButton, SvgIconTypeMap, Tooltip } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
// eslint-disable-next-line
import React, { MouseEvent, useState } from 'react';

interface IProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | Element | JSX.Element;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  tooltipText: string;
  className?: string;
  disableRipple?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  style?: object;
}

export const IconRoundButton = ({ size, disabled, disableRipple, icon, onClick, tooltipText, className, style }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (onClick) onClick(event);
  };

  const styles = style ? style : {};
  return (
    <Tooltip title={tooltipText}>
      <span className={className}>
        <IconButton
          disabled={disabled}
          disableRipple={disableRipple}
          className="add_btn-play"
          style={{ borderRadius: 6, ...styles }}
          size={size}
          aria-describedby={id}
          color="primary"
          onClick={handleClick}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};
