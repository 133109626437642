import './style.scss';

import { Loader } from 'components/loader/loader';
import { confirmChangeEmail, confirmNewEmail } from 'creators/account';
import * as AuthCreators from 'creators/auth';
import * as ConfirmCreators from 'creators/confrirm';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { isLoading$ as isLoadingAcc$ } from 'selectors/account';
import { isAuth$ } from 'selectors/auth';
import { email$, isConfirmed$, isError$, isLoading$, token$ } from 'selectors/confirm';
import { usePrevious } from 'utils';

import style from './confirmEmail.module.scss';

interface IDispatchProps {
  confirm: (email: string, token: string) => void;
  onLogout: () => void;
}

interface IStateProps {
  isError: boolean;
  isLoading: boolean;
  token: string;
  email: string;
  isConfirmed: null | boolean;
}

interface OwnProps {
  isChange?: boolean;
  isNew?: boolean;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}
const ConfirmEmail = ({ email, token, confirm, isConfirmed, isError, isLoading, onLogout, isChange, isNew }: IProps) => {
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuth$);
  const history = useHistory();
  const isLoadingAccount = useSelector(isLoadingAcc$);
  const isPrevLoadingAccount = usePrevious(isLoadingAccount);
  const isPrevConfirmed = usePrevious(isConfirmed);

  useEffect(() => {
    if (!isChange && !isNew) {
      if (email && token && !isConfirmed && count === 0) {
        confirm(email, token);
        setCount(1);
      } else {
        return;
      }
    }
  }, [email, token, confirm, count, isConfirmed, isChange, isNew]);

  useEffect(() => {
    if (isChange && !isNew && !isLoadingAccount && isPrevLoadingAccount === false && isConfirmed === null && isPrevConfirmed === null) {
      if (token) {
        dispatch(confirmChangeEmail(token));
      }
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (!isChange && isNew) {
      if (token) {
        dispatch(confirmNewEmail(token));
      }
    }
    // eslint-disable-next-line
  }, [token, isNew, isChange]);

  const handleGo = useCallback(() => {
    if (isAuth) {
      history.push('/profile');
    } else {
      history.push('/');
    }
  }, [isAuth, history]);

  return (
    <>
      {isLoading && isConfirmed === null ? (
        <div className="loading-container">
          <Loader />
        </div>
      ) : null}
      {isError || !token || !email || isConfirmed === false ? (
        <section className="error-confirm-container d-flex flex-column justify-center align-center text-center">
          An error occurred while confirming the email!
          <span style={{ margin: 10 }}>
            Click{' '}
            <NavLink to="/" onClick={() => onLogout()}>
              {' '}
              here
            </NavLink>
          </span>
        </section>
      ) : null}
      {isConfirmed && !isError && (
        <section className="email-confirm-container d-flex justify-center align-center">
          <div className="email-confirm">
            <div className="email-confirm-title">Congratulations!! :)</div>
            <div className="email-confirm-text d-flex flex-column">
              Your Email has been successfully confirmed!
              <span>
                Click{' '}
                <div onClick={handleGo} className={style.link}>
                  {' '}
                  here
                </div>
              </span>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state: State): IStateProps => {
  return {
    isLoading: isLoading$(state),
    isError: isError$(state),
    token: token$(state),
    email: email$(state),
    isConfirmed: isConfirmed$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    confirm: dispatchProps.confirm,
    onLogout: dispatchProps.onLogout,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    confirm: ConfirmCreators.confirmEmail,
    onLogout: AuthCreators.onLogout,
  },
  mergeProps
)(memo(ConfirmEmail));
