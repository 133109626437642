import classNames from 'classnames/bind';
// eslint-disable-next-line
import React from 'react';

import styles from './filteredCount.module.scss';

interface IProps {
  count: number;
  title: string;
  isBorderOnTheSides?: boolean;
}

export const FilteredCount = ({ count, title, isBorderOnTheSides }: IProps) => {
  const sectionClassNames = classNames(styles.root, {
    [styles.border]: isBorderOnTheSides,
  });

  return (
    <div className={sectionClassNames}>
      <div className={styles.title}>{`${title}: `}</div>
      <div className={styles.count}>{isNaN(count) ? 'Error' : Number(count)}</div>
    </div>
  );
};
