import 'sweetalert2/src/sweetalert2.scss';

import * as AuthActions from 'actions/auth';
import * as AvatarActions from 'actions/avatar';
import * as ConfirmActions from 'actions/confirm';
import axios from 'axios';
import { successNotification } from 'components/successNotification/successNotification';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

import * as AccountActions from '../actions/account';

export const changeUserName = (name: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AccountActions.setAccountLoading());

    axios
      .post(`${Api.AccountChangeName}`, {
        name,
      })
      .then(() => {
        dispatch(AccountActions.setAccount());
        dispatch(AuthActions.getUserName(name));
      })
      .catch(error => {
        dispatch(AccountActions.setAccountError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const confirmChangeEmail = (token: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AccountActions.setAccountLoading());

    axios
      .post(`${Api.AccountConfirmChangeEmail}`, {
        token,
      })
      .then(response => {
        dispatch(AccountActions.setAccount());
        successNotification('Email change completed successfully!');
        dispatch(ConfirmActions.setConfirmEmail(response.data.result?.new_email));
        dispatch(ConfirmActions.setConfirmToken(token));
        dispatch(ConfirmActions.setConfirmSuccess());
        dispatch(AuthActions.getUserEmail(response.data.result?.new_email));
      })
      .catch(error => {
        dispatch(AccountActions.setAccountError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const confirmNewEmail = (token: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AccountActions.setAccountLoading());

    axios
      .post(`${Api.AccountConfirmNewEmail}`, {
        token,
      })
      .then(() => {
        dispatch(AccountActions.setAccount());
        successNotification('Email verified successfully!');
        dispatch(ConfirmActions.setConfirmEmail('email-confirmed'));
        dispatch(ConfirmActions.setConfirmToken(token));
        dispatch(ConfirmActions.setConfirmSuccess());
      })
      .catch(error => {
        dispatch(AccountActions.setAccountError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const сhangeUserEmail = (email: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AccountActions.setAccountLoading());

    const redirect_url = `${window.location.origin}/confirm-change-email/?token={token}`;

    axios
      .post(`${Api.AccountChangeEmail}`, {
        email,
        redirect_url,
      })
      .then(() => {
        dispatch(AccountActions.setAccount());
        successNotification('Check your email for confirmation!');
      })
      .catch(error => {
        dispatch(AccountActions.setAccountError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const сhangeUserPassword = (password: string, new_password: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AccountActions.setAccountLoading());

    axios
      .post(Api.AccountChangePassword, {
        password,
        new_password,
      })
      .then(() => {
        dispatch(AccountActions.setAccount());
        successNotification('Your password has been successfully changed');
      })
      .catch(error => {
        dispatch(AccountActions.setAccountError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const deleteUserAvatar = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(AccountActions.setAccountLoading());

    axios
      .post(Api.AccountDeleteAvatar)
      .then(() => {
        dispatch(AccountActions.setAccount());
        dispatch(AvatarActions.getSuccessAvatar(''));
      })
      .catch(error => {
        dispatch(AccountActions.setAccountError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
