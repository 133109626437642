import { Tooltip } from '@material-ui/core';
import { Badge, withStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import cx from 'classnames';
import { InputPopup } from 'components/InputPopup';
import { ISelectItem, MaterialSelect } from 'components/materialSelect/materialSelect';
import Popup from 'components/popup/popup';
import { successNotification } from 'components/successNotification/successNotification';
import { MemberListPopup } from 'containers/memberListPopup';
import { ICompany } from 'interfaces/state/CompaniesState';
// eslint-disable-next-line
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userType$ } from 'selectors/auth';

// import { CompanyInfo } from '../companyInfo';
import style from './companyItem.module.scss';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 0,
    top: 17,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 8,
  },
}))(Badge);

interface OwnProps {
  company: ICompany;
  userName: string | null;
  isOpenDelete: boolean;
  deleteMemberFromCompany: (company_id: number, member_id: number) => void;
  handleOpenDelete: (v: boolean) => void;
  onDeleteApply: (id: number) => void;
  createInviteForCompany: (company_id: number, email: string, role: string) => void;
  isOpen: boolean;
  setId: (id: number) => void;
}
const roles = ['member', 'admin'];

interface IProps extends OwnProps {}

export const CompanyItem = ({
  company,
  userName,
  isOpenDelete,
  deleteMemberFromCompany,
  handleOpenDelete,
  onDeleteApply,
  createInviteForCompany,
  setId,
  isOpen,
}: IProps) => {
  const inviteObj = {
    company_id: null,
    email: '',
    role: '',
  };

  const memoMembers = useMemo(() => company.members, [company]);
  const companyOwnerIndex = memoMembers.findIndex(com => com.role_name === 'owner');
  const roleList = useMemo(() => roles.map((role: string) => ({ label: role, value: role })), []) as ISelectItem[];
  const [isOp, setOpen] = useState(false);
  const [invite, setInvite] = useState(inviteObj);
  // const [isOpenAffiliate, setOpenAffiliate] = useState(false);
  const userType = useSelector(userType$);
  const history = useHistory();

  const selectRoleValue = { value: invite.role, label: invite.role };

  const handleOpen = (val: boolean) => {
    if (!val) {
      setInvite(inviteObj);
    }
    setOpen(val);
  };

  const handleApply = () => {
    if (!invite.email) {
      successNotification('Fill in the empty field - "Email"');
      return;
    }
    if (!invite.role) {
      successNotification('Fill in the empty field - "Role"');
      return;
    }
    createInviteForCompany(company.id, invite.email, invite.role);
    setInvite(inviteObj);
    setOpen(false);
  };

  const onOpenInvite = () => {
    handleOpen(true);
  };

  // const handleOpenAffiliate = () => {
  //   setOpenAffiliate(true);
  // };

  const onCloseInvite = () => {
    handleOpen(false);
  };

  // const handleCloseAffiliate = () => {
  //   setOpenAffiliate(false);
  // };

  const owner = companyOwnerIndex === -1 ? null : company.members[companyOwnerIndex]?.user_name;
  const isOwner = userName === owner;

  const adminIndex = company.members.findIndex(member => member?.role_name === 'admin' && member?.user_name === userName);
  const [isOpenMembers, setOpenMembers] = useState(false);

  const onOpen = useCallback(() => {
    setOpenMembers(true);
  }, [setOpenMembers]);

  const onDecline = useCallback(() => {
    setOpenMembers(false);
  }, [setOpenMembers]);

  const onOpenDelete = useCallback(() => {
    handleOpenDelete(true);
  }, [handleOpenDelete]);

  const onDeclineDelete = useCallback(() => {
    handleOpenDelete(false);
  }, [handleOpenDelete]);

  const handleDeleteCompany = useCallback(() => {
    onDeleteApply(company.id);
  }, [company, onDeleteApply]);

  const handleSetInvite = useCallback((key: string, value: string) => setInvite({ ...invite, [key]: value }), [setInvite, invite]);
  const handleRoleChange = useCallback((val: string | number) => setInvite({ ...invite, role: val as string }), [invite, setInvite]);

  const handleSetCurrentId = useCallback(() => {
    setId(company.id);
  }, [setId, company]);

  const onCompanyInfoClick = () => {
    history.push(`/sh_admin/company-info?id=${company.id}`);
  };

  const AvatarCX = cx(style.avatarContainer, {
    [style.iconInside]: company?.avatar_url,
  });

  return (
    <div className={style.company} onClick={handleSetCurrentId}>
      <div className={AvatarCX}>
        {company?.avatar_url ? (
          <img src={company.avatar_url} className={style.avatar} alt={'company avatar'} />
        ) : (
          <GroupRoundedIcon className={style.avatarIcon} />
        )}

        {company?.verified && userType === 'admin' && (
          <Tooltip title="Verified">
            <VerifiedUserRoundedIcon className={style.verified} />
          </Tooltip>
        )}
      </div>
      <div className={style.name}>{company.name}</div>
      <div className={style.description}>{company.description}</div>
      <div className={style.owner}>
        <Tooltip title={owner ? owner : ''}>
          <span>
            <PersonRoundedIcon className={style.icon} />
          </span>
        </Tooltip>
      </div>
      <div className={style.buttons}>
        {userType === 'admin' && (
          <Tooltip title="Information about company">
            <div className={style.button} onClick={onCompanyInfoClick}>
              <InfoRoundedIcon className={style.icon} />
            </div>
          </Tooltip>
        )}
        {/* {userType === 'admin' && (
          <Popup
            onOpen={handleOpenAffiliate}
            onDecline={handleCloseAffiliate}
            isOpen={isOpenAffiliate}
            btnClassName={style.btn}
            onApply={handleCloseAffiliate}
            isVisibleOverflow
            buttonText={
              <Tooltip title="Information about company">
                <div className={style.button}>
                  <InfoRoundedIcon className={style.icon} />
                </div>
              </Tooltip>
            }
            isHideCancelButton
            applyBtnText={'OK'}
            modalContent={<CompanyInfo id={company.id} isOpen={isOpenAffiliate} />}
          />
        )} */}
        <Popup
          key={company.id}
          onOpen={onOpen}
          onDecline={onDecline}
          isOpen={isOpenMembers && isOpen}
          btnClassName={style.countBtn}
          onApply={onDecline}
          isVisibleOverflow
          disabled={!isOwner && adminIndex < 0}
          isHideCancelButton
          buttonText={
            <StyledBadge badgeContent={company.members.length} color="primary">
              <PersonRoundedIcon className={style.icon} />
            </StyledBadge>
          }
          applyBtnText={'OK'}
          modalContent={<MemberListPopup company={company} userName={userName} deleteMemberFromCompany={deleteMemberFromCompany} />}
        />

        <Popup
          onOpen={onOpenInvite}
          onDecline={onCloseInvite}
          isOpen={isOp && isOpen}
          btnClassName={style.btn}
          onApply={handleApply}
          isVisibleOverflow
          disabled={!isOwner && adminIndex < 0}
          buttonText={
            <Tooltip title="Invite new member or admin">
              <div className={style.button}>
                <PersonAddIcon className={style.icon} />
              </div>
            </Tooltip>
          }
          applyBtnText={'SEND INVITE'}
          modalContent={
            <div className="d-flex flex-column">
              <div className={style.label}>{`Invite member or admin to '${company.name}'`}</div>
              <InputPopup
                type="text"
                title="Email"
                onChange={value => handleSetInvite('email', value as string)}
                value={invite.email}
                maxLength={100}
              />
              <div className="d-flex align-center">
                <div className="d-flex flex-column">
                  <span className={style.fakePlaceholder}>Select role</span>
                  <MaterialSelect
                    value={selectRoleValue}
                    placeholder="Select role"
                    selectItems={roleList}
                    onChange={handleRoleChange}
                    className={style.select}
                    width={200}
                  />
                </div>
              </div>
            </div>
          }
        />

        <Popup
          onOpen={onOpenDelete}
          onDecline={onDeclineDelete}
          isOpen={isOpenDelete && isOpen}
          btnClassName={style.btn}
          onApply={handleDeleteCompany}
          isVisibleOverflow
          disabled={!isOwner && adminIndex < 0}
          buttonText={
            <Tooltip title="Delete Company">
              <div className={style.button}>
                <DeleteForeverIcon className={style.icon} />
              </div>
            </Tooltip>
          }
          applyBtnText={'DELETE'}
          modalContent={
            <div className="d-flex flex-column">
              <div className={style.label}>{`Are you sure that you want to delete '${company.name}'`}</div>
            </div>
          }
        />
      </div>
    </div>
  );
};
