import { ReactNode } from 'react';

const ALL_USERS_HEADER = 'Login to SmashHaus';
const MANAGER_HEADER = 'SmashHaus Dashboard';
const ALL_USERS_SUBHEADER = 'The place, where you can get all needed things about music, audioproduction etc.';
const MANAGER_SUBHEADER = 'Log in to with your special credentials and get manager access to the platform.';

export const headerText = (isManager: boolean): ReactNode => (isManager ? MANAGER_HEADER : ALL_USERS_HEADER);

export const subHeaderText = (isManager: boolean): ReactNode => (isManager ? MANAGER_SUBHEADER : ALL_USERS_SUBHEADER);
