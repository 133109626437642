import { getCurrentTab } from 'actions/tab';
import cx from 'classnames';
import Aside from 'components/aside/aside';
import InfoPanel from 'components/infoPanel/infoPanel';
import { SwitchTabs } from 'containers/switchTabs/switchTabs';
import { WebsocketHandler } from 'containers/websocketHandler';
import SignUp from 'modules/auth/signUp';
import ActiveJobList from 'pages/admin/activeJobList';
import CompanyList from 'pages/admin/companyList';
import AdminJobList from 'pages/admin/jobList';
import { StatisticsPage } from 'pages/admin/statistics';
import UserList from 'pages/admin/userList';
import { CompanyInfoPage } from 'pages/companyInfo';
import ConfirmEmail from 'pages/confirmEmail/confirmEmail';
import InvitePage from 'pages/invitePage/index';
import { NotFound } from 'pages/notFound/notFound';
import { PrivacyPolicy } from 'pages/privacyPolicy';
import { ProfilePage } from 'pages/profile';
import ResetPassword from 'pages/resetPassword/resetPassword';
import { RoutePage } from 'pages/routePage/routePage';
import { SharedPlaylist } from 'pages/sharedPlaylist';
import { TeamPage } from 'pages/team';
import { TechnicalWorks } from 'pages/technicalWorks';
// eslint-disable-next-line
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { currentTab$ } from 'selectors/tab';
import { isNeedToRedirectOnUnknownRoute } from 'utils';
import { CustomRoute } from 'utils/addRoute';
import { privateRoute, publicRoute } from 'utils/routes';

import style from './admin.module.scss';

const allRoutes = [
  publicRoute.confirm,
  privateRoute.adminPrefix + privateRoute.admin.activeJobs,
  privateRoute.adminPrefix + privateRoute.admin.jobList,
  privateRoute.adminPrefix + privateRoute.admin.companyList,
  privateRoute.adminPrefix + privateRoute.admin.admin,
  privateRoute.adminPrefix + privateRoute.admin.statistics,
  privateRoute.adminPrefix + privateRoute.admin.companyInfo,
  publicRoute.registerFromCompanyInvite,
  publicRoute.shareablePlaylist,
  publicRoute.shareableApplication,
  publicRoute.signUp,
  privateRoute.team,
  publicRoute.privacyPolicy,
  publicRoute.confirmChangeEmail,
  publicRoute.confirmNewEmail,
  privateRoute.profile,
  privateRoute.main,
  publicRoute.resetPass,
];
const AdminModuleComponent = () => {
  const currentTab = useSelector(currentTab$);
  const dispatch = useDispatch();
  const history = useHistory();
  const isShowAdminInfo = useCallback(() => {
    const routes = [
      '/',
      '/sh_admin/jobs/active',
      '/sh_admin/job/list',
      '/sh_admin/admin',
      '/sh_admin/company/list',
      '/sh_admin/statistics',
      '/privacy-policy',
      '/team',
      '/profile',
      'technical-works',
    ];

    return routes.includes(history.location.pathname);

    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    if (isNeedToRedirectOnUnknownRoute(allRoutes, history.location.pathname)) {
      history.push('/404');
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const handleChangeTab = useCallback(() => {
    if (history.location.pathname === '/sh_admin/statistics' || history.location.pathname === '/') {
      dispatch(getCurrentTab(0));
    } else if (history.location.pathname === '/sh_admin/jobs/active') {
      dispatch(getCurrentTab(1));
    } else if (history.location.pathname === '/sh_admin/job/list') {
      dispatch(getCurrentTab(2));
    } else if (history.location.pathname === '/sh_admin/admin') {
      dispatch(getCurrentTab(3));
    } else if (history.location.pathname === '/team') {
      dispatch(getCurrentTab(4));
    } else if (history.location.pathname === '/sh_admin/company/list') {
      dispatch(getCurrentTab(5));
    }

    // eslint-disable-next-line
  }, [getCurrentTab, history.location.pathname]);

  // eslint-disable-next-line
  useEffect(() => handleChangeTab(), [getCurrentTab, history.location.pathname]);

  const mainCXAdmin = cx({
    [style.main]: isShowAdminInfo() === true,
    [style.mainFullWidth]: isShowAdminInfo() === false,
  });

  return (
    <WebsocketHandler>
      <div className={style.appContent}>
        {isShowAdminInfo() && <Aside tabs={<SwitchTabs />} />}

        <main className={mainCXAdmin}>
          {isShowAdminInfo() && <InfoPanel isShowPopupsButtons={currentTab === 2 || currentTab === 5} />}

          <Switch>
            <CustomRoute path={publicRoute.confirm} component={<ConfirmEmail />} />
            <CustomRoute
              path={privateRoute.adminPrefix + privateRoute.admin.activeJobs}
              component={<RoutePage component={<ActiveJobList isAdmin />} />}
            />
            <CustomRoute
              path={privateRoute.adminPrefix + privateRoute.admin.jobList}
              component={<RoutePage component={<AdminJobList isAdmin />} />}
            />
            <CustomRoute
              path={privateRoute.adminPrefix + privateRoute.admin.companyList}
              component={<RoutePage component={<CompanyList />} />}
            />
            <CustomRoute
              path={privateRoute.adminPrefix + privateRoute.admin.companyInfo}
              component={<RoutePage component={<CompanyInfoPage />} />}
            />
            <CustomRoute path={privateRoute.adminPrefix + privateRoute.admin.admin} component={<RoutePage component={<UserList />} />} />
            <CustomRoute path={privateRoute.adminPrefix + privateRoute.admin.statistics} component={<StatisticsPage />} />
            <CustomRoute path={publicRoute.registerFromCompanyInvite} component={<InvitePage />} />
            <CustomRoute path={publicRoute.shareablePlaylist} component={<SharedPlaylist />} />
            <CustomRoute path={publicRoute.shareableApplication} component={<SharedPlaylist isMusicianPlaylist />} />
            <CustomRoute path={publicRoute.signUp} component={<SignUp />} />
            <CustomRoute path={privateRoute.team} component={<TeamPage />} />
            <CustomRoute path={publicRoute.privacyPolicy} component={<PrivacyPolicy />} />
            <CustomRoute path={publicRoute.confirmChangeEmail} component={<ConfirmEmail isChange />} />
            <CustomRoute path={publicRoute.confirmNewEmail} component={<ConfirmEmail isNew />} />
            <CustomRoute path={privateRoute.profile} component={<ProfilePage />} />
            <CustomRoute path={publicRoute.resetPass} component={<ResetPassword />} />
            <CustomRoute exact path={publicRoute.technicalWork} component={<TechnicalWorks />} />
            <CustomRoute exact path={privateRoute.main} component={<RoutePage component={<StatisticsPage />} />} />

            <Route path="/404">
              <NotFound />
            </Route>
          </Switch>
        </main>
      </div>
    </WebsocketHandler>
  );
};

export const AdminModule = memo(AdminModuleComponent);
