import { setCheckedEmail } from 'actions/auth';
// eslint-disable-next-line
import React from 'react';
import { useDispatch } from 'react-redux';

import style from './agreement.module.scss';

interface IProps {
  onNext: (step: number) => void;
}
export const Argeement = ({ onNext }: IProps) => {
  const dispatch = useDispatch();

  const handleNext = () => onNext(2);
  const handlePrev = () => {
    onNext(0);
    dispatch(setCheckedEmail(null));
  };
  return (
    <section className={style.root}>
      <h1 className={style.head}>
        <a href={'https://www.smashhaus.com/'} referrerPolicy="no-referrer-when-downgrade" target="_blank" rel="noopener noreferrer">
          SmashHaus.com
        </a>{' '}
        - Agreement to Terms and Conditions
      </h1>
      <p className={style.text}>
        By continuing, you acknowledge, represent, and warrant that you have read, understand, and agree to the SmashHaus Terms and
        Conditions. You also acknowledge that the site is an unsupported beta version and, due to the nature of testing, may include
        features and functions in various states of performance and reliability. You may experience technical issues with some features, and
        others may from time to time be unavailable. Additionally, some features and/or functions may be added or removed from time to time.
        The{' '}
        <a href={'https://www.smash.haus'} referrerPolicy="no-referrer-when-downgrade" target="_blank" rel="noopener noreferrer">
          smash.haus
        </a>{' '}
        site is provided “as is,” with all faults, and no expressed or implied representations or warranties of any kind are made related to
        the Site or the materials contained on the Site.
      </p>
      <div className={style.btns}>
        <button className={style.btnBack} onClick={handlePrev}>
          Back
        </button>
        <button className={style.btn} onClick={handleNext}>
          Continue
        </button>
      </div>
    </section>
  );
};
