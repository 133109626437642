import * as SubscriptionLimitActions from 'actions/subscriptionLimit';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';

export const fetchSubscriptionLimit = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SubscriptionLimitActions.setSubscrLimitPending());

    axios
      .post(`${Api.SubscriptionLimit}`)
      .then(response => {
        dispatch(SubscriptionLimitActions.setSubscrLimit(response.data.result));
      })
      .catch(error => {
        dispatch(SubscriptionLimitActions.setSubscrLimitError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
