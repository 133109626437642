import 'sweetalert2/src/sweetalert2.scss';

import * as ShareablePlaylistInfoActions from 'actions/shareablePlaylistInfo';
import axios from 'axios';
import { Api } from 'enums/api';
import State from 'interfaces/state/State';
import { Action } from 'redux';
import { playlist_id$ } from 'selectors/shareablePlaylistInfo';
import { catchedErrorNotification } from 'utils/error';

import { fetchSharedPlaylistTracks } from './sharedPlaylistTracks';

export const fetchSheablePlaylistInfo = (token: string, isMusicianPlaylist?: boolean) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ShareablePlaylistInfoActions.setShareablePlaylistInfoPending());

    const url = isMusicianPlaylist ? Api.ShareableMusicianPlaylist : Api.ShareablePlaylistInfo;
    axios
      .post(url, {
        token,
      })
      .then(res => {
        dispatch(ShareablePlaylistInfoActions.setShareablePlaylistInfoReceive(res.data.result));

        const playlistId = res?.data?.result?.application_id || res?.data?.result?.playlist_id;
        if (playlistId) {
          dispatch(fetchSharedPlaylistTracks(playlistId as number, isMusicianPlaylist) as any);
        }
      })
      .catch(error => {
        dispatch(ShareablePlaylistInfoActions.setShareablePlaylistInfoError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};

export const fetchPlaylistBg = (id?: number) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    const application_id = id ? id : playlist_id$(getState());

    dispatch(ShareablePlaylistInfoActions.setShareablePlaylistInfoPending());

    const url = Api.GetPlaylistBackground;
    axios
      .post(url, {
        application_id,
      })
      .then(res => {
        const background = res?.data?.result;
        if (background) {
          dispatch(ShareablePlaylistInfoActions.setPlaylistBg(background));
        }
      })
      .catch(error => {
        dispatch(ShareablePlaylistInfoActions.setShareablePlaylistInfoError());

        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
