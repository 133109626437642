import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import cx from 'classnames';
// eslint-disable-next-line
import React from 'react';

import styles from './avatar.module.scss';

interface IProps {
  src?: string;
  isProfile?: boolean;
  isLoading?: boolean;
}

export const Avatar = ({ src, isProfile, isLoading }: IProps) => {
  const rootCX = cx(styles.image, {
    [styles.avatar]: isProfile,
    [styles.hidden]: isLoading,
  });

  const defaultCX = cx({
    [styles.isProfile]: isProfile,
    [styles.show]: isLoading,
  });

  const defImage = cx(styles.defaultImage, {
    [styles.isProfile]: isProfile,
    [styles.default]: !isProfile,
  });

  return src ? (
    <img src={src} alt="Avatar" className={rootCX} loading="lazy" />
  ) : (
    <div className={defaultCX}>
      <AccountBoxRoundedIcon className={defImage} />
    </div>
  );
};
