import { ITeam } from 'interfaces/state/TeamState';
import moment from 'moment';
// eslint-disable-next-line
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { DATE_FORMAT } from 'utils';

import style from './teamMembersItemPopup.module.scss';

interface OwnProps {
  members: ITeam['members'];
}

interface IProps extends OwnProps {}
export const TeamMembersItemPopup = ({ members }: IProps) => {
  return (
    <div className={style.root}>
      {!members.length && (
        <div className={style.emptyMembers}>
          There is no one on the team yet. You can invite the creator (musician) using the Invite button on the track
        </div>
      )}
      {members.length && <h5>All team members</h5>}
      <div className={style.wrapper}>
        <Scrollbars className={style.content}>
          {members.map(member => {
            return (
              <div className={style.member}>
                <div className={style.name}>{member.member_name}</div>
                <div className={style.date}>{moment(member.when_added).format(DATE_FORMAT)}</div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};
