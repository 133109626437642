import State from 'interfaces/state/State';

export const isLoading$ = (state: State) => state.applications.isLoading;
export const isError$ = (state: State) => state.applications.isError;
export const playlists$ = (state: State) => state.applications.app;
export const job_id$ = (state: State) => state.applications.job_id;
export const playlist_id$ = (state: State) => state.applications.playlist_id;
export const current_playlist$ = (state: State) => state.applications.current_playlist;
export const current_init_playlist$ = (state: State) => state.applications.initial_current_playlist;
export const shareableLinks$ = (state: State) => state.applications.shareableLinks;
export const numberOfNewAddedTracks$ = (state: State) => state.applications.numberOfNewAddedTracks;
export const appIds$ = (state: State) => state.applications.appIds;
export const appById$ = (state: State) => state.applications.appById;
export const hasUnsavedPlaylist$ = (state: State) => state.applications.hasUnsavedPlaylist;
