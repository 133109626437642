import { InputPopup } from 'components/InputPopup';
// eslint-disable-next-line
import React, { PureComponent } from 'react';

import styles from './playlistPopoverContent.module.scss';

interface IProps {
  value: string;
  onChange: (value: string | number) => void;
}

export class PlaylistPopoverContent extends PureComponent<IProps> {
  render() {
    const { value, onChange } = this.props;

    return (
      <div className={styles.root}>
        <span className={styles.title}>{'Add new playlist'}</span>
        <div className={styles.content}>
          <InputPopup type="text" title="Playlist name" onChange={onChange} value={value} maxLength={300} className={styles.input} />
        </div>
      </div>
    );
  }
}
