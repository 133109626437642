import * as Const from '../constants/index';
import State from '../interfaces/state/InviteState';

const initState: State = {
  created: [],
  received: [],
  isLoading: false,
  isError: false,
};

export default function invite(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.INVITE_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.INVITE_CREATED_LOADED:
      return {
        ...state,
        created: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.INVITE_RECEIVED_LOADED:
      return {
        ...state,
        received: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.INVITE_CREATED_ADD_NEW:
      const createdInviteList = [...state.created];
      const newCreatedInviteList = [...createdInviteList, action.payload];
      return {
        ...state,
        created: newCreatedInviteList,
        isLoading: false,
        isError: false,
      };

    case Const.INVITE_RECEIVED_ADD_NEW:
      const receivedInviteList = [...state.received];
      const newReceivedInviteList = [...receivedInviteList, action.payload];
      return {
        ...state,
        received: newReceivedInviteList,
        isLoading: false,
        isError: false,
      };

    case Const.INVITE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.INVITE_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.UPDATE_INVITE_STATUS:
      const inviteList = [...state.received];
      const inviteIndex = inviteList.findIndex(invite => invite.id === action.id);
      if (inviteIndex < 0) return state;
      inviteList[inviteIndex].status = action.status;

      return {
        ...state,
        received: [...inviteList].filter(inv => inv.id !== action.id),
        isLoading: false,
        isError: false,
      };

    case Const.INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.DELETE_RECEIVED_INVITE:
      return {
        ...state,
        received: [...state.received].filter(invite => invite.id !== action.id),
        isLoading: false,
        isError: false,
      };

    case Const.DELETE_CREATED_INVITE:
      return {
        ...state,
        created: [...state.created].filter(invite => invite.id !== action.id),
        isLoading: false,
        isError: false,
      };

    default:
      return state;
  }
}
