import logoBottomImage from 'assets/img/SmashHaus_LOGO_cmyk.png';
import classNames from 'classnames/bind';
// eslint-disable-next-line
import React from 'react';

import styles from './logoBottom.module.scss';

interface IProps {
  loader?: boolean;
}

export const LogoBottom = ({ loader }: IProps) => {
  const imageClassNames = classNames(styles.root, {
    [styles.loader]: loader,
  });
  return <img className={imageClassNames} src={logoBottomImage} alt="logo" loading="lazy" />;
};
