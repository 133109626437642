import * as Const from '../constants/index';
import State from '../interfaces/state/AffiliateState';

const initState: State = {
  affiliate_url: '',
  isLoading: false,
  isError: false,
  isLoadingSendAffiliate: false,
  isErrorSendAffiliate: false,
};

export default function affiliate(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.AFFILIATE_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.AFFILIATE_SUCCESS:
      return {
        ...state,
        affiliate_url: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.AFFILIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.AFFILIATE_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.AFFILIATE_SEND_SUCCESS:
      return {
        ...state,
        isLoadingSendAffiliate: false,
        isErrorSendAffiliate: false,
      };

    case Const.AFFILIATE_SEND_ERROR:
      return {
        ...state,
        isLoadingSendAffiliate: false,
        isErrorSendAffiliate: true,
      };

    case Const.AFFILIATE_SEND_PENDING:
      return {
        ...state,
        isLoadingSendAffiliate: true,
        isErrorSendAffiliate: false,
      };
    default:
      return state;
  }
}
