import { Action } from 'redux';

import * as Const from '../constants';

export const getUploadTrack = (payload: File) => {
  return {
    type: Const.UPLOAD_TRACK_RECEIVE,
    payload,
  };
};

export const setUploadTrackInit = (): Action => {
  return {
    type: Const.UPLOAD_TRACK_INIT,
  };
};

export const setUploadTrackSuccess = (): Action => {
  return {
    type: Const.UPLOAD_TRACK_SUCCESS,
  };
};

export const setUploadTrackFail = (): Action => {
  return {
    type: Const.UPLOAD_TRACK_ERROR,
  };
};

export const setUploadTrackPending = (): Action => {
  return {
    type: Const.UPLOAD_TRACK_PENDING,
  };
};

export const setProgress = (payload: number) => {
  return {
    type: Const.SET_PROGRESS,
    payload,
  };
};

export const setUploadPopup = (payload: boolean) => {
  return {
    type: Const.UPLOAD_TRACK_POPUP,
    payload,
  };
};

export const setTrackDuration = (payload: number | null) => {
  return {
    type: Const.UPLOAD_DURATION_TRACK,
    payload,
  };
};

export const setFileSizeOfTrack = (payload: number) => {
  return {
    type: Const.UPLOAD_FILE_SIZE_TRACK,
    payload,
  };
};
