import { ITeamInvite } from 'interfaces/state/TeamInviteState';
import { Action } from 'redux';

import * as Const from '../constants';

export const setTeamInviteInit = (): Action => ({
  type: Const.TEAM_INVITE_INIT,
});

export const setTeamInvitePending = (): Action => ({
  type: Const.TEAM_INVITE_PENDING,
});

export const setTeamInviteError = (): Action => ({
  type: Const.TEAM_INVITE_ERROR,
});

export const setTeamInviteList = (payload: ITeamInvite[]) => ({
  type: Const.TEAM_INVITE_LIST,
  payload,
});

export const updateTeamInvite = (id: number) => ({
  type: Const.TEAM_INVITE_UPDATE,
  id,
});

export const createInviteTeam = (payload: ITeamInvite) => ({
  type: Const.TEAM_INVITE_CREATE,
  payload,
});

export const deleteInviteTeam = (id: number) => ({
  type: Const.TEAM_INVITE_DELETE,
  id,
});

export const sendedInviteSuccessfully = () => ({
  type: Const.TEAM_INVITE_SENDED,
});
