import * as Const from '../constants';
import State from '../interfaces/state/PlaylistState';

const initState: State = {
  playlist: [],
  isLoading: false,
  isError: false,
  current_name: '',
  playlist_id: null,
  isPlayFromTrackPanel: true,
  nameInBase: '',
  titleInBase: '',
  writerInBase: '',
  currentPlaylist: null,
  autoforwarding: false,
  sharedPlaylistId: null,
  sharedPlaylistIndex: null,
  amount: null,
  isLoadingTracks: false,
};

export default function playlist(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.PLAYLIST_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
        autoforwarding: state.autoforwarding,
      };

    case Const.PLAYLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.PLAYLIST_RECEIVE:
      return {
        ...state,
        playlist: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.PLAYLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.PLAYLIST_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.CURR_PLAYLIST_NAME:
      return {
        ...state,
        current_name: action.payload,
      };

    case Const.SET_PLAYLIST_ID:
      return {
        ...state,
        playlist_id: action.payload,
      };

    case Const.PLAYLIST_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.PLAY_FROM_TRACK_PANEL:
      return {
        ...state,
        isPlayFromTrackPanel: action.payload,
      };

    case Const.PLAYLIST_NAME_IN_BASE:
      return {
        ...state,
        nameInBase: action.payload,
      };

    case Const.PLAYLIST_TITLE_IN_BASE:
      return {
        ...state,
        titleInBase: action.payload,
      };

    case Const.PLAYLIST_WRITER_IN_BASE:
      return {
        ...state,
        writerInBase: action.payload,
      };

    case Const.CURRENT_PLAYLIST_NUM:
      return {
        ...state,
        currentPlaylist: action.payload,
      };

    case Const.INIT_PLAYLIST:
      return {
        ...state,
        playlist: [],
      };

    case Const.DEL_PLAYLIST:
      return {
        ...state,
        playlist: [...state.playlist].filter(pl => pl.playlist_id !== action.payload),
      };

    case Const.PLAYLIST_AUTOFORWARDING:
      return {
        ...state,
        autoforwarding: action.payload,
      };

    case Const.PLAYLIST_ADD_NEW_ITEM:
      return {
        ...state,
        playlist: [action.payload, ...state.playlist],
      };

    case Const.SHARED_ID:
      return {
        ...state,
        sharedPlaylistId: action.payload,
      };

    case Const.SHARED_INDEX:
      return {
        ...state,
        sharedPlaylistIndex: action.payload,
      };

    case Const.PLAYLIST_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };

    case Const.PLAYLIST_LOADING_TRACKS:
      return {
        ...state,
        isLoadingTracks: action.payload,
      };

    default:
      return state;
  }
}
