import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as ArrowFwdBig } from 'assets/img/arrow-fwd-big.svg';
import checkIcon from 'assets/img/check_big.png';
import premiumFree from 'assets/img/diamond.svg';
import { ReactComponent as DevIcon } from 'assets/img/exchange.svg';
import { ReactComponent as MusicNoteIcon } from 'assets/img/musicNote.svg';
import starterFree from 'assets/img/pie-chart.svg';
import { ReactComponent as PremiumIcon } from 'assets/img/premiumSubscr.svg';
import { ReactComponent as StraterIcon } from 'assets/img/proSubscr.svg';
import { ReactComponent as StorageIcon } from 'assets/img/storage.svg';
import { ReactComponent as UnlimIcon } from 'assets/img/unlimSubsc.svg';
import cx from 'classnames';
import { Loader } from 'components/loader/loader';
import { SubscriptionListItem } from 'interfaces/state/SubscriptionList';
// eslint-disable-next-line
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { interval$, isLoading$, is_active_subscription$, product_title$ } from 'selectors/currentSubscription';
import { period$ } from 'selectors/subscriptionList';

import style from './plan.module.scss';

interface IDispatchProps {}
interface IStateProps {}
interface OwnProps {
  plan: SubscriptionListItem;
  handleSelectNewPlan: (id: string, title: string) => void;
  isAnnually: boolean;
}

interface IProps extends IDispatchProps, IStateProps, OwnProps {}

const createdFeatures = [
  'Access to pro jobs',
  'Pro and premium jobs',
  'Verification badges',
  'Customer support',
  'Custom creator playlist',
  'Bulk download',
];
export const Plan = ({ plan, handleSelectNewPlan, isAnnually }: IProps) => {
  const interval = useSelector(interval$);
  const product_title = useSelector(product_title$);
  const is_active_subscription = useSelector(is_active_subscription$);
  const period = useSelector(period$);
  const isLoading = useSelector(isLoading$);

  const renderIcon = () => {
    const icon = {
      starter: <StraterIcon className={style.icon} />,
      premium: <PremiumIcon className={style.icon} />,
      unlimited: <UnlimIcon className={style.icon} />,
      'starter-free': <img src={starterFree} alt="subscription" className="track-subscription-icon" loading="lazy" />,
      'premium-free': <img src={premiumFree} alt="subscription" className="track-subscription-icon" loading="lazy" />,
    };

    const unnececarySubscriptionTypes = ['free'];

    if (unnececarySubscriptionTypes.includes(plan.title.toLowerCase())) return null;

    return icon[plan.title.toLowerCase()];
  };

  const renderPrice = () => {
    if (isAnnually) {
      return plan.title?.toLowerCase() === 'unlimited' ? plan.price_amount * 12 : (plan.price_amount - plan.price_amount / 10) * 12;
    } else {
      return plan.price_amount;
    }
  };

  const handleSelectPlan = () => {
    handleSelectNewPlan(plan.product_id, plan.title);
  };

  const isPeriod = period && isAnnually ? 'year' : 'month';
  const isTheSamePeriod = isPeriod === interval;
  const isPlanSelected =
    (plan.title === product_title && is_active_subscription && isTheSamePeriod) ||
    (plan.title === product_title && is_active_subscription && isTheSamePeriod);

  const btnCX = cx(style.btn, {
    [style.selected]: isPlanSelected,
  });

  const featureInDev = (feature: string) => {
    if (createdFeatures.includes(feature)) {
      return <img src={checkIcon} className={style.checkIcon} alt={'check'} />;
    } else {
      return (
        <Tooltip title={'This option is under development'}>
          <span>
            <DevIcon className={style.checkIcon} />
          </span>
        </Tooltip>
      );
    }
  };

  const renderPeriod = useMemo(() => {
    return isAnnually ? 'Annually' : 'Monthly';
  }, [isAnnually]);

  return (
    <section className={style.root}>
      <header className={style.header}>
        {renderIcon()}
        <div className={style.topContainer}>
          <h3 className={style.title}>{plan.title}</h3>
          <div className={style.priceContainer}>
            <span className={style.dollar}>$</span>
            <span className={style.price}>{renderPrice()}</span>
            <span className={style.period}>{renderPeriod}</span>
          </div>
        </div>
      </header>
      <div className={style.songsContainer}>
        <div className={style.musConatiner}>
          <MusicNoteIcon className={style.mNoteIcon} />
          <span className={style.songsCount}>--</span>
        </div>
        <div className={style.storageContainer}>
          <StorageIcon className={style.storageIcon} />
          <span className={style.storageCount}>{plan.storage_amount}</span>
          <div className={style.gb}>Mb</div>
        </div>
      </div>
      <div className={style.line}></div>
      <div className={style.options}>
        {plan.feachers?.map((option, index) => {
          return (
            <div className={style.optionContainer} key={index}>
              {featureInDev(option)}
              <span className={style.option}>{option}</span>
            </div>
          );
        })}
      </div>
      <button className={btnCX} onClick={handleSelectPlan} disabled={isPlanSelected}>
        {isPlanSelected && !isLoading ? 'Current plan' : null}
        {!isPlanSelected && !isLoading ? (
          <>
            Choose plan
            <ArrowFwdBig className={style.fwdIcon} />
          </>
        ) : null}
        {isLoading && <Loader />}
      </button>
    </section>
  );
};
