import { Action } from 'redux';

import * as Const from '../constants';

export const setDownloadFileInit = (): Action => {
  return {
    type: Const.DOWNLOAD_FILE_INIT,
  };
};

export const getDownloadFileSuccess = () => {
  return {
    type: Const.DOWNLOAD_FILE_SUCCESS,
  };
};

export const setDownloadFileFail = (): Action => {
  return {
    type: Const.DOWNLOAD_FILE_ERROR,
  };
};

export const setDownloadFilePending = (): Action => {
  return {
    type: Const.DOWNLOAD_FILE_PENDING,
  };
};
