import { Loader } from 'components/loader/loader';
import State from 'interfaces/state/State';
import { ITeamInvite } from 'interfaces/state/TeamInviteState';
// eslint-disable-next-line
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { isLoading$, teamInvites$ } from 'selectors/teamInvite';

import { Invite } from '../invite';
import style from './inviteList.module.scss';

interface IStateProps {
  teamInvites: ITeamInvite[];
  isLoading: boolean;
}
interface IDispatchProps {}
interface OwnProps {
  isCreator?: boolean;
}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const InviteListComponent = ({ teamInvites, isLoading, isCreator }: IProps) => {
  return (
    <div className={style.root}>
      {isLoading && (
        <div className={style.loadingContainer}>
          <Loader />
        </div>
      )}
      <Scrollbars className={style.content}>
        {teamInvites.map(invite => (
          <Invite key={invite.id} invite={invite} isCreator={isCreator} />
        ))}
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    teamInvites: teamInvites$(state),
    isLoading: isLoading$(state),
  };
};

export const InviteList = connect(mapStateToProps)(InviteListComponent);
