import { Loader } from 'components/loader/loader';
import { LogoBottom } from 'components/logoBottom/logoBottom';
import PromotionPopup from 'components/promotionPopup/promotionPopup';
import ServiceWorkerWrapper from 'components/serviceWorkerWrapper';
import { DeviceHandler } from 'containers/deviceHandler';
import { HistoryHandler } from 'containers/historyHandler';
import { createBrowserHistory } from 'history';
// eslint-disable-next-line
import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import styles from './App.module.scss';
import State from './interfaces/state/State';
import { isAuth$, isLoading$, subscription$, userType$ } from './selectors/auth';

export const history = createBrowserHistory();
interface IStateProps {
  isLoading: boolean;
}

interface IDispatchProps {}
interface OwnProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const App = (props: IProps) => {
  const { isLoading } = props;

  const renderLoading = useMemo(() => {
    return (
      isLoading && (
        <div className={styles.container}>
          <Loader />
          <div className={styles.loader}>
            <LogoBottom loader />
          </div>
        </div>
      )
    );
  }, [isLoading]);
  const [showPopup, setShowPopup] = useState(false);
  const subscription = useSelector(subscription$);
  const isAuth = useSelector(isAuth$);
  const userType = useSelector(userType$);

  useEffect(() => {
    const now = Date.now();
    const lastPopupTime = localStorage.getItem('lastPopupTime');
    const shouldShowPopup = !lastPopupTime || now - parseInt(lastPopupTime) >= 3600000;

    if (isAuth && shouldShowPopup) {
      setShowPopup(true);
      localStorage.setItem('lastPopupTime', now.toString());
    }

    let interval: NodeJS.Timeout;
    if (subscription.toLowerCase() === 'free' && isAuth) {
      interval = setInterval(() => {
        setShowPopup(true);
        localStorage.setItem('lastPopupTime', Date.now().toString());
      }, 3600000); // 3600000 milliseconds = 1 hour
    }

    // Cleanup function
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [subscription, isAuth]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const renderApp = useMemo(() => {
    return (
      <>
        <ServiceWorkerWrapper />
        <Router>
          <HistoryHandler />
          <DeviceHandler />
        </Router>
      </>
    );
  }, []);

  return (
    <div className={styles.root}>
      {userType === 'creator' && subscription.toLowerCase() === 'free' && isAuth && showPopup && (
        <PromotionPopup onClose={handleClosePopup} />
      )}
      {renderApp}
      {renderLoading}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: isLoading$(state),
  };
};

export default connect(mapStateToProps)(
  memo(App, (_prevProps: IProps, _props: IProps) => {
    return false;
  })
);
