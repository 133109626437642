import * as Const from '../constants/index';
import State from '../interfaces/state/TempEditCompanyJobState';

const initState: State = {
  title: '',
  description: '',
  budget: 0,
};

export default function tempEditCompanyJob(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.TEMP_COMPANY_JOBS_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.TEMP_COMPANY_JOBS_EDIT_ITEM:
      return {
        ...state,
        [action.item as 'title' | 'description' | 'budget']: action.payload,
      };
    default:
      return state;
  }
}
