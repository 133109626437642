import * as Const from '../constants';
import State from '../interfaces/state/SharedPlayerState';

const initState: State = {
  currentTrack: null,
  currentURLByID: {},
  isError: false,
  isLoading: false,
  downloadProgressByID: {},
  isNeedToPlayAll: false,
  isPlayingTrack: false,
};

export default function sharedPlayer(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.SHARED_PLAYER_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.SHARED_PLAYER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case Const.SHARED_PLAYER_RECEIVE:
      return {
        ...state,
        currentTrack: action.payload,
        isLoading: false,
        isError: false,
      };

    case Const.SHARED_PLAYER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case Const.SHARED_PLAYER_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.SHARED_PLAYER_URL:
      return {
        ...state,
        currentURLByID: {
          [action.id]: {
            id: action.id,
            isPlay: action.isPlay,
            url: action.url,
          },
        },
      };

    case Const.SHARED_PLAYER_PROGRESS:
      return {
        ...state,
        downloadProgressByID: { ...state.downloadProgressByID, [action.id]: action.value },
      };

    case Const.SHARED_PLAYER_PLAY_ALL:
      return {
        ...state,
        isNeedToPlayAll: action.payload,
      };

    case Const.SHARED_PLAYER_PLAY_TRACK:
      return {
        ...state,
        isPlayingTrack: action.payload,
      };

    default:
      return state;
  }
}
