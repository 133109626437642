import { IPlaylist } from 'interfaces/IPlaylist';
import { Action } from 'redux';

import * as Const from '../constants';

export const setSharedPlaylistInit = (): Action => {
  return {
    type: Const.SHARED_PLAYLIST_INIT,
  };
};

export const setSharedPlaylistSuccess = (): Action => {
  return {
    type: Const.SHARED_PLAYLIST_SUCCESS,
  };
};

export const setSharedPlaylistReceive = (payload: IPlaylist[]) => {
  return {
    type: Const.SHARED_PLAYLIST_RECEIVE,
    payload,
  };
};

export const setSharedPlaylistError = (): Action => {
  return {
    type: Const.SHARED_PLAYLIST_ERROR,
  };
};

export const setSharedPlaylistId = (payload: number | null) => {
  return {
    type: Const.SHARED_PLAYLIST_ID,
    payload,
  };
};

export const filterSharedPlById = (payload: number) => {
  return {
    type: Const.FILTER_SHARED_PLAYLIST_BY_ID,
    payload,
  };
};
