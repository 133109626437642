import './style.scss';

import Upload from 'components/upload/upload';
import { BigStatisticPanel } from 'containers/musicianAvailableJobs/bigStatisticPanel';
import StatisticPanel from 'containers/statisticPanel/statisticPanel';
import * as CurrentSubscriptionCreators from 'creators/currentSubscription';
import * as StatsCreators from 'creators/stats';
import State from 'interfaces/state/State';
import { IStatTrack } from 'interfaces/state/StatsState';
// eslint-disable-next-line
import React, { memo, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { isAuth$ } from 'selectors/auth';
import * as StatsSelectors from 'selectors/stats';

interface IProps {}

interface IStateProps {
  activeJobsCount: number;
  active_jobs_all: number;
  tracks: IStatTrack[];
}

interface IDispatchProps {
  getStats: () => void;
  fetchCurrentSubscription: () => void;
}

const MusicianStatistics = ({
  activeJobsCount,
  active_jobs_all,
  tracks,
  getStats,
  fetchCurrentSubscription,
}: IProps & IStateProps & IDispatchProps) => {
  const [isShowUpload, setShowUpload] = useState(false);
  const isAuth = useSelector(isAuth$);

  // eslint-disable-next-line
  useEffect(() => {
    if (isAuth) {
      getStats();
    }
    // eslint-disable-next-line
  }, [isAuth]);

  // eslint-disable-next-line
  useEffect(() => {
    if (isAuth) fetchCurrentSubscription();
  }, [isAuth, fetchCurrentSubscription]);

  return (
    <section className="musician-content d-flex flex-column align-center show-animation">
      <div className="info-container-mus">
        <StatisticPanel getStats={getStats} tracks={tracks} allJobsCount={active_jobs_all} activeCount={activeJobsCount} />
      </div>
      <BigStatisticPanel tracks={tracks} />
      {isShowUpload && <Upload setShowUpload={setShowUpload} />}
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    active_jobs_all: StatsSelectors.active_jobs_all$(state),
    activeJobsCount: StatsSelectors.active_jobs$(state),
    tracks: StatsSelectors.statisticTracks$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: IProps) => {
  return {
    ...stateProps,
    getStats: dispatchProps.getStats,
    fetchCurrentSubscription: dispatchProps.fetchCurrentSubscription,
    ...props,
  };
};

export default connect(
  mapStateToProps,
  {
    getStats: StatsCreators.getStats,
    fetchCurrentSubscription: CurrentSubscriptionCreators.fetchCurrentSubscription,
  },
  mergeProps
)(memo(MusicianStatistics));
