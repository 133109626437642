import { IPlaylist } from 'interfaces/IPlaylist';
import State from 'interfaces/state/State';
import { createSelector } from 'reselect';

export const pl$ = (state: State) => state.playlist.playlist;
export const playlist$ = createSelector(pl$, (pl): IPlaylist[] => {
  return pl;
});
export const current_name$ = (state: State) => state.playlist.current_name;
export const playlist_id$ = (state: State) => state.playlist.playlist_id;
export const isPlayFromTrackPanel$ = (state: State) => state.playlist.isPlayFromTrackPanel;
export const nameInBase$ = (state: State) => state.playlist.nameInBase;
export const titleInBase$ = (state: State) => state.playlist.titleInBase;
export const writerInBase$ = (state: State) => state.playlist.writerInBase;
export const currentPlaylist$ = (state: State) => state.playlist.currentPlaylist;
export const autoforwarding$ = (state: State) => state.playlist.autoforwarding;
export const sharedPlaylistId$ = (state: State) => state.playlist.sharedPlaylistId;
export const sharedPlaylistIndex$ = (state: State) => state.playlist.sharedPlaylistIndex;
export const amount$ = (state: State) => state.playlist.amount;
export const isLoading$ = (state: State) => state.playlist.isLoading;
export const isLoadingTracks$ = (state: State) => state.playlist.isLoadingTracks;
