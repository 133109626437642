import './style.scss';

import cx from 'classnames';
// eslint-disable-next-line
import React, { useState } from 'react';
import Select from 'react-dropdown-select';

export interface ISelectItem {
  label: string;
  value: string | number;
}

interface IProps {
  placeholder: string;
  selectItems: ISelectItem[];
  onChange?: (item: string | number) => void;
  onChangeAllValue?: (val: ISelectItem) => void;
  onOpenChange?: (open: boolean) => void;
  className: string;
  width?: number | string;
  value?: ISelectItem;
  isLoading?: boolean;
  isAllValueNeed?: boolean;
  disabled?: boolean;
  search?: string;
  withoutSearch?: boolean;
  multi?: boolean;
  isError?: boolean;
}

export const MaterialSelect = ({
  onOpenChange,
  onChange,
  onChangeAllValue,
  placeholder,
  selectItems,
  className,
  width,
  value,
  isLoading,
  isAllValueNeed,
  multi,
  disabled,
  isError,
}: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const RootCX = cx({
    error: isError,
    [className ? className : '']: className,
  });

  const handleSelectChange = (selectedItems: ISelectItem[]) => {
    if (!isOpen) return;
    if (isAllValueNeed) {
      if (!multi) {
        if (onChangeAllValue) onChangeAllValue(selectedItems[0] || { value: '', label: '' });
      }
    } else {
      if (!multi) {
        if (onChange) onChange(selectedItems[0]?.value || '');
      }
    }
  };

  const handleOpenChange = () => {
    setOpen(true);
    if (onOpenChange) onOpenChange(true);
  };

  const handleCloseChange = () => {
    setOpen(false);
    if (onOpenChange) onOpenChange(false);
  };

  return (
    <Select
      multi={multi}
      options={selectItems}
      onChange={handleSelectChange}
      values={value ? [value] : []}
      placeholder={placeholder}
      loading={isLoading}
      dropdownPosition="auto"
      onDropdownOpen={handleOpenChange}
      onDropdownClose={handleCloseChange}
      style={width ? { width } : {}}
      className={RootCX}
      disabled={disabled}
    />
  );
};
