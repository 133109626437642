import './style.scss';

import { Loader } from 'components/loader/loader';
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isLoading$ } from 'selectors/auth';
import { publicRoute } from 'utils/routes';

export const NotFound = () => {
  const isLoading = useSelector(isLoading$);
  const [isShowNotFoundPage, setIsShowNotFoundPage] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setIsShowNotFoundPage(true), 500);
    } else {
      setIsShowNotFoundPage(false);
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && <Loader />}
      {isShowNotFoundPage && (
        <section className="not-found-container d-flex align-center justify-center">
          <div className="not-found">
            <div className="not-found-title">{'SORRY! We couldn`t find that page'}</div>
            <div className="not-found-text">
              Try go to
              <NavLink to={publicRoute.main}> SmashHaus Home page</NavLink>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
