// eslint-disable-next-line
import React from 'react';
import { Route } from 'react-router-dom';

interface IProps {
  path: string;
  component: JSX.Element;
  exact?: boolean;
}

export const CustomRoute = ({ path, component, exact = true }: IProps) => {
  return <Route exact={exact} path={path} component={() => component} />;
};
