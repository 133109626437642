// eslint-disable-next-line
import React from 'react';

import style from './terms.module.scss';

interface IProps {
  onNext: (step: number) => void;
}

export const Terms = ({ onNext }: IProps) => {
  const onClick = () => onNext(3);
  const onBackClick = () => onNext(1);
  return (
    <section className={style.root}>
      <div className={style.wrapper}>
        <h1 className={style.head}>
          <a href={'https://www.smashhaus.com/'} referrerPolicy="no-referrer-when-downgrade" target="_blank" rel="noopener noreferrer">
            SMASHHAUS.COM
          </a>
          {`(the "Site") TERMS & CONDITIONS`}
        </h1>
        <p className={style.text}>
          This page (together with the documents referred to herein) contain the terms and conditions of use of{' '}
          <a href={'https://www.smashhaus.com/'} referrerPolicy="no-referrer-when-downgrade" target="_blank" rel="noopener noreferrer">
            SmashHaus.com
          </a>{' '}
          which must be accepted before any use or account creation. It is necessary that you read these terms and conditions carefully
          before using the site or registering an account. By proceeding, and by using the{' '}
          <a href={'https://www.smashhaus.com/'} referrerPolicy="no-referrer-when-downgrade" target="_blank" rel="noopener noreferrer">
            SmashHaus.com
          </a>
          , you expressly agree to be bound by these terms and conditions. These terms and conditions constitute a legal and enforceable
          contract between you and SmashHaus, LLC (“SmashHaus“ or “we”). If you do not expressly agree to any terms and/or conditions,
          abandon the registration process now and cease any further use of the site.
        </p>
        <h2 className={style.subHead}>
          <div className={style.number}>1.</div>User Terms and Conditions
        </h2>
        <p className={style.subtextContainer}>
          <div className={style.bold}>1.1</div>
          <div className={style.subText}>
            Your use of the Site is governed by these Terms and Conditions which you are required to read and agree to before using or
            completing registration on the Site.
          </div>
        </p>
        <p className={style.subtextContainer}>
          <div className={style.bold}>1.2</div>
          <div className={style.subText}>
            SmashHaus may update or amend the Terms and Conditions at any time. You will be provided notice of any updates or amendments via
            the Site.
          </div>
        </p>
        <p className={style.subtextContainer}>
          <div className={style.bold}>1.3</div>
          <div className={style.subText}>
            These Terms and Conditions apply to you regardless of how you have accessed the Site and regardless of the territory in which
            you live or conduct business.
          </div>
        </p>

        <h2 className={style.subHead}>
          <div className={style.number}>2.</div>No Warranty and No Liability
        </h2>
        <p className={style.subtextContainer}>
          <div className={style.bold}>2.1</div>
          <div className={style.subText}>
            The information, software, products, and services included in or available through the site may include inaccuracies or
            typographical errors. Changes are periodically added to the information herein. Smashhaus may make improvements and/or changes
            in or to the Site at any time.
          </div>
        </p>
        <p className={style.subtextContainer}>
          <div className={style.bold}>2.2</div>
          <div className={style.subText}>
            Smashhaus makes no representations about the suitability, reliability, availability, timeliness, or accuracy of the information,
            software, products, services, or any content contained in, on, or via the Site for any purpose. To the maximum extent permitted
            by applicable law, all such information, software, products, services and related artwork are provided "as is" without warranty
            or condition of any kind. Smashhaus hereby disclaims all warranties and conditions with regard to the information, software,
            products, services, and content, including all implied warranties or conditions of merchantability, fitness for a particular
            purpose, title, and/or non-infringement.
          </div>
        </p>
        <p className={style.subtextContainer}>
          <div className={style.bold}>2.3</div>
          <div className={style.subText}>
            To the maximum extent permitted by applicable law, in no event shall smashhaus, llc be liable for any direct, indirect,
            punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss
            of use, data or profits, arising out of or in any way connected with the use or performance of the site, with the delay or
            inability to use the site or related services, the provision of or failure to provide services, or for any information,
            software, products, services and related graphics obtained through the site, or otherwise arising out of the use of the site,
            whether based on contract, tort, negligence, strict liability or otherwise, even if smashhaus has been advised of the
            possibility of damages. Because some states/jurisdictions do not allow the exclusion or limitation of liability for
            consequential or incidental damages, the above limitation may not apply to you. If you are dissatisfied with any portion of the
            site, or with any of these terms of use, your sole and exclusive remedy is to discontinue using the site.
          </div>
        </p>
        <p className={style.subtextContainer}>
          <div className={style.bold}>2.4</div>
          <div className={style.subText}>
            Class action waiver. The parties agree that a party may bring claims against the other only in each's individual capacity, and
            not as a plaintiff or class member in any putative class, collective and/ or representative proceeding, such as in the form of a
            private attorney general action against the other.
          </div>
        </p>

        <h2 className={style.subHead}>
          <div className={style.number}>3.</div>No Technical Support
        </h2>
        <div className={style.subText}>
          SmashHaus will not provide any technical support, maintenance or any other services for the Site.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>4.</div>External Content
        </h2>
        <div className={style.subText}>
          From time to time, the Site may include links to other websites. These links are provided for user convenience and to provide
          further information. All provided external links are available to use at your discretion, and do not constitute or represent any
          endorsement by SmashHaus of the content in such website(s). SmashHaus shall not be responsible for the content of any linked
          website(s). Any products, services, or information available through this website that you explore will be at your own risk.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>5.</div>Reproduction
        </h2>
        <div className={style.subText}>
          The Site contains material which is owned by and/or licensed to SmashHaus. This material includes, but is not limited to, the
          design, layout, look, appearance and graphics. Any use or reproduction other than as authorized by SmashHaus in writing is
          strictly prohibited.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>6.</div>Privacy Policy
        </h2>
        <div className={style.subText}>
          SmashHaus recognizes the importance of maintaining the privacy of personal data which it collects and processes. The following
          outlines SmashHaus’ Privacy Policy: The information we collect may include your personal data, such as your name, contact
          information, IP addresses, product and service selections and other data that may identify you. We may collect personal data about
          you at several different points, including but not limited to when we correspond with you, when you visit the Site, when you
          register as a user, and when you provide content. Additionally, we may collect certain information automatically when you visit
          the Site. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile
          carrier, mobile advertising and other unique identifiers, details about your browser, operating system or device, location
          information, Internet service provider, pages that you visit, information about the links you click, and other information about
          how you use The Site. Information we collect may be associated with accounts and other devices. SmashHaus services, such as those
          related to location data management, may collect precise geo-location information in accordance with applicable law. Any personal
          information that SmashHaus collects will used only for and by SmashHaus, and will not be shared, or sold, to anyone. SmashHaus
          will comply with all applicable laws and regulations respecting data collected, and to protect your privacy. As noted elsewhere in
          these Terms and Conditions, SmashHaus is not responsible for any websites that you may use or visit by clicking a link on our
          website, as these are links to third-parties websites not controlled or created by SmashHaus. SmashHaus may use the information
          that we collect about you to allow you to register, provide you with access, communicate with you, verify or maintain the quality
          or safety of a service, process your financial information and other payment methods for products or services purchased, manage
          our business, and analyze and improve our services pursuant to our legitimate interest (including protecting against malicious,
          deceptive, fraudulent or illegal activity, performing research and analysis, improving, upgrading or enhancing our services,
          verifying your identity and preventing fraud, and enforcing our terms and conditions.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>7.</div>Representations, Warranties, and Indemnity
        </h2>

        <div className={style.container}>
          <div className={style.bold}>7.1</div>
          <div className={style.subText}>
            Smashhaus does not claim, represent, or warranty that it owns or controls any musical materials or other intellectual property
            content posted on or via the Site by any user of the Site. You understand, acknowledge, and agree that SmashHaus does not own or
            control, or make any representation respecting the ownership or control, respecting any rights, in, to, or arising from any
            materials or information uploaded, input, and/or posted to the Site by any customer, client or user of the Site (collectively
            the "Materials").
          </div>
        </div>

        <div className={style.container}>
          <div className={style.bold}>7.2</div>
          <div className={style.subText}>
            By posting, uploading, inputting, providing, or submitting Materials, you represent, warrant, and agree to ALL of the following:
          </div>
        </div>
        <div className={style.subContainer}>
          <div className={style.bold}>(a)</div>
          <div className={style.boldText}>
            That the Materials are or will be wholly original to You, and not copied in whole or in part from, or based on, any other work;
            that the Materials do not and will not violate the right of privacy of nor constitute a libel or slander against any person,
            firm or corporation; that the Material does not and will not infringe upon the copyright, or otherwise violate any right, of any
            person, firm or corporation and that you own or otherwise control all of the rights to your Materials including, without
            limitation, all the rights necessary for you to provide, post, upload, input or submit the Materials.
          </div>
        </div>
        <div className={style.subContainer}>
          <div className={style.bold}>(b)</div>
          <div className={style.boldText}>That all of the information provided by you is complete and accurate.</div>
        </div>
        <div className={style.subContainer}>
          <div className={style.bold}>(c)</div>
          <div className={style.boldText}>
            That you authorize and agree to allow Smashhaus to use the Materials in connection with the operation of the Site, including but
            not limited to the right to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and
            reformat the Materials, and the right to use your name in connection therewith.
          </div>
        </div>
        <div className={style.subContainer}>
          <div className={style.bold}>(d)</div>
          <div className={style.boldText}>
            That You agree to indemnify, defend and hold harmless Smashhaus, its officers, directors, employees, agents and third parties,
            for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of
            or inability to use the Site or services, your violation of any terms of this agreement, your violation of any rights of a third
            party, and/or your violation of any applicable laws, rules or regulations. Smashhaus reserves the right, at its own cost, to
            assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will
            fully cooperate with Smashhaus in asserting any available defenses.
          </div>
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>8.</div>Governing Law and Jurisdictions
        </h2>
        <div className={style.subText}>
          These Terms and Conditions and the relationship between the parties to this agreement shall be governed by the laws of the State
          of California and the United States, without regard to conflict of laws principles. Any action arising from these User Conditions
          can be brought and maintained only in the federal or state courts located in Los Angeles County, California, and you hereby
          expressly submit to the jurisdiction of said courts.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>9.</div>International Users
        </h2>
        <div className={style.subText}>
          The Site is controlled, operated and administered by Smashhaus from its offices within the USA. If you access the Site from a
          location outside the USA, you are responsible for compliance with all local laws. You agree that you will not access the Site in
          any country or in any manner prohibited by any applicable laws, restrictions or regulations.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>10.</div>Entire Agreement
        </h2>
        <div className={style.subText}>
          These Terms and Conditions (together with any documents to which they refer) contain the entire agreement between you and
          SmashHaus relating to the use of the Site. You acknowledge that you have not relied on any representation, warranty, or other
          assurance except those set forth herein.
        </div>

        <h2 className={style.subHead}>
          <div className={style.number}>11.</div>DMCA Notice and Takedown Policy
        </h2>
        <div className={style.subText}>
          SmashHaus maintains the following DMCA Notice and Takedown Policy. SmashHaus respects the intellectual property rights of third
          parties, and expects others to do the same. As part of our effort to recognize the copyrights of third parties, SmashHaus complies
          with the U.S. Digital Millennium Copyright Act ("DMCA") and is therefore protected by the limitations on liability recognized by
          17 U.S.C. § 512; commonly known as the "safe harbor" provisions of the DMCA. If you believe that your work has been copied,
          reproduced, altered, or published in a way that constitutes copyright infringement under federal law, or your copyrights have been
          otherwise violated, please click the “DMCA Notice and Takedown” link and fill out all the required information. This will notify
          our Designated Agent. SmashHaus will then proceed in a timely manner to notify the Contributor of the alleged infringement, and
          take steps to disable or remove the material in question. Upon receipt of any notification of claimed copyright infringement,
          SmashHaus will act expeditiously to notify its customer of the alleged infringement, and take steps to disable or remove the
          subject material. DMCA Notices are generally processed within two (2) business days from receipt, absent extenuating
          circumstances. SmashHaus reserves the right at any time to disable access to, or remove any material or expressive work accessible
          on or from its servers or services, that is claimed to be infringing via a valid DMCA Notice, or based on facts or circumstances
          from which infringing activity is apparent. It is the firm policy of SmashHaus to terminate the account of repeat copyright
          infringers, when appropriate, and SmashHaus will act expeditiously to remove access to all material that infringes on another's
          copyright, according to the procedure set forth in 17 U.S.C. §512 of the DMCA. If the DMCA notice does not comply with §512 of the
          DMCA, but does substantially comply with the (3) three requirements for identifying infringing works according to §512 of the
          DMCA, SmashHaus shall attempt to contact or take other reasonable steps to reach the complaining party to assist that party comply
          with sending a compliant DMCA Notice. As noted above, when SmashHaus' Designated Agent receives a valid notice, SmashHaus will act
          expeditiously to remove and/or disable access to the infringing material and shall notify the affected customer or subscriber.
          Then, the affected customer or subscriber may submit a counter-notification to the Designated Agent, using the
          counter-notification procedures set forth below. SmashHaus reserves the right to modify, alter or add to this policy, and all
          affected persons should regularly check back to this page to stay current on any modifications.
        </div>
        <div className={style.message}>
          By clicking the “accept” button, you acknowledge that: (1) you are 18 years of age or older, and (2) you have read, understood,
          and accepted the terms and conditions of this agreement
        </div>
        <div className={style.buttons}>
          <button className={style.btnBack} onClick={onBackClick}>
            {'Back'}
          </button>
          <button className={style.btn} onClick={onClick}>
            {'Agree & Accept'}
          </button>
        </div>
      </div>
    </section>
  );
};
