import { Action } from 'redux';

import * as Const from '../constants';
import ActionEmailReceive from '../interfaces/actions/ActionEmailReceive';
import ActionUserNameReceive from '../interfaces/actions/ActionUserNameReceive';

export const fetchSignUpSuccess = (): Action => {
  return {
    type: Const.SIGNUP_SUCCESS,
  };
};

export const setSignUpInit = (): Action => {
  return {
    type: Const.SIGNUP_INIT,
  };
};

export const setEmail = (payload: string): ActionEmailReceive => {
  return {
    type: Const.SIGNUP_EMAIL,
    payload,
  };
};

export const setUserName = (payload: string): ActionUserNameReceive => {
  return {
    type: Const.SIGNUP_USER_NAME,
    payload,
  };
};

export const setSignUpPending = (): Action => {
  return {
    type: Const.SIGNUP_PENDING,
  };
};

export const setSignUpError = (): Action => {
  return {
    type: Const.SIGNUP_ERROR,
  };
};

export const redirectToLogin = (): Action => {
  return {
    type: Const.SIGNUP_SUCCESS_RED_TO_LOG_IN,
  };
};

export const redirectSetDefaultVal = (): Action => {
  return {
    type: Const.SIGNUP_REDIRECT_DEFAULT,
  };
};
