import * as SuccessPayActions from 'actions/successPayment';
import axios from 'axios';
import { Api } from 'enums/api';
import { Action } from 'redux';
import { catchedErrorNotification } from 'utils/error';
import { firstCapitalLetter } from 'utils/firstCapitalLetter';

export const checkForSuccessPayment = (id: string) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SuccessPayActions.setPaymentPending());
    axios
      .post(Api.CheckSuccessPayment, {
        id,
      })
      .then(response => {
        dispatch(SuccessPayActions.setPayment(response.data.status));
      })
      .catch(error => {
        dispatch(SuccessPayActions.setPaymentError());
        dispatch(SuccessPayActions.setPaymentErrText(firstCapitalLetter(error.response.data.error.replace(/_|-|}|{/g, ' '))));
        console.error(error.response.data.error);
        catchedErrorNotification(error);
      });
  };
};

export const createPortalSession = () => {
  return (dispatch: (action: Action) => void) => {
    dispatch(SuccessPayActions.setPaymentPending());
    axios
      .post(Api.CreatePortalSession)
      .then(response => {
        dispatch(SuccessPayActions.setPayment(response.data.result.portal_session_id));
      })
      .catch(error => {
        dispatch(SuccessPayActions.setPaymentError());
        console.error(error);
        catchedErrorNotification(error);
      });
  };
};
