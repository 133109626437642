import { ReactComponent as LaunchIcon } from 'assets/img/product-lauch.svg';
import { ReactComponent as WarningIcon } from 'assets/img/warning.svg';
import * as SuccessPaymentCreators from 'creators/successPayment';
import State from 'interfaces/state/State';
// eslint-disable-next-line
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { errorText$, isError$, portal_session_id$ } from 'selectors/successPayment';

import style from './answerPayment.module.scss';

interface IStateProps {
  portal_session_id: string | null;
  isError: boolean;
  errorText: null | string;
}
interface IDispatchProps {
  checkForSuccessPayment: (id: string) => void;
}
interface OwnProps {}
interface IProps extends IStateProps, OwnProps, IDispatchProps {}

const AnswerPaymentComponent = ({ checkForSuccessPayment, portal_session_id, isError, errorText }: IProps) => {
  const [id, setId] = useState(null as null | string);
  const [url, setUrl] = useState(null as null | string);

  const setU = useCallback(() => setUrl(window.location.pathname), []);
  useEffect(() => {
    setU();
    if (url) {
      const parsedId = url?.split('/');
      setId(parsedId[parsedId.length - 1]);
    }
  }, [url, setId, id, setU]);
  useEffect(() => {
    if (id && id !== 'success-checkout') {
      checkForSuccessPayment(id);
    }
  }, [id, checkForSuccessPayment]);

  return (
    <section className={style.root}>
      {!!portal_session_id && (
        <div className={style.box}>
          <div className={style.iconContainer}>
            <LaunchIcon className={style.icon} />
          </div>
          <div className={style.head}>Thank you!</div>
          <div className={style.text}>The subscription plan was successfully paid!</div>
          <button className={style.btn}>
            <a href={'/'} className={style.link}>
              Go to Main
            </a>
          </button>
        </div>
      )}

      {isError && (
        <div className={style.box}>
          <div className={style.iconContainer}>
            <WarningIcon className={style.icon} />
          </div>
          <div className={style.head}>Error!</div>
          <div className={style.text}>{errorText}</div>
          <button className={style.btn}>
            <a href={'/'} className={style.link}>
              Go to Main
            </a>
          </button>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state: State) => {
  return {
    portal_session_id: portal_session_id$(state),
    isError: isError$(state),
    errorText: errorText$(state),
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, props: OwnProps) => {
  return {
    ...stateProps,
    checkForSuccessPayment: dispatchProps.checkForSuccessPayment,
    ...props,
  };
};

export const AnswerPayment = connect(
  mapStateToProps,
  {
    checkForSuccessPayment: SuccessPaymentCreators.checkForSuccessPayment,
  },
  mergeProps
)(AnswerPaymentComponent);
